import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { get } from 'lodash-es';
import UserProfile from './UserProfileContainer';
import { CustomButton, CustomDashboardInput } from '../../../containers';
import { validate } from '../../../utils/validation';
import {
  userChangePassword,
  userChangePhoneNumber,
  userProfileForgotPassword,
  clearUserErrorMessage,
  userValidateCurrentPassword,
  userDeleteAccount,
} from '../../../redux/actions/userProfileActions';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { AvatarDisplayAndChange } from '../../../components/AvatarDisplayAndChange';
import { ROUTE_TO_USER_SUPPORT_PAGE } from '../../../constants/routes';
import { userLogout } from '../../../redux/actions/userAuthActions';

export const UserProfileAccount = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const changePasswordError = useSelector(state => state.userProfile.changePassword.errorMessage);
  const changePasswordSuccess = useSelector(state => state.userProfile.changePassword.success);
  const changePasswordInProgress = useSelector(state => state.userProfile.changePassword.inProgress);
  const changePhoneNumberInProgress = useSelector(state => state.userProfile.changePhoneNumberInProgress);

  const validateCurrentPasswordError = useSelector(state => state.userProfile.validateCurrentPassword.errorMessage);
  const validateCurrentPasswordSuccess = useSelector(state => state.userProfile.validateCurrentPassword.success);
  const validateCurrentPasswordInProgress = useSelector(state => state.userProfile.validateCurrentPassword.inProgress);

  const deleteMyAccountError = useSelector(state => state.userProfile.deleteMyAccount.errorMessage);
  const deleteMyAccountSuccess = useSelector(state => state.userProfile.deleteMyAccount.success);
  const deleteMyAccountInProgress = useSelector(state => state.userProfile.deleteMyAccount.inProgress);

  const [phoneNumber, setPhoneNumber] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {
      required: true,
    },
  });
  const [password, setPassword] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.password" />,
    validation: {
      required: true,
    },
  });
  const [newPassword, setNewPassword] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.newPassword" />,
    validation: {
      required: true,
      minLength: 8,
    },
  });
  const [repeatNewPassword, setRepeatNewPassword] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.repeatNewPassword" />,
    validation: {
      required: true,
      matches: '',
    },
  });
  const [deletePassword, setDeletePassword] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.accountDeletePassword" />,
    validation: {
      required: true,
    },
  });

  const [deleteAccountCondition, setDeleteAccountCondition] = useState(false);
  const [deleteAccountStep, setDeleteAccountStep] = useState(1);

  useEffect(() => {
    setPhoneNumber(phoneNumber => ({ ...phoneNumber, value: user.phoneNumber }));
  }, [user]);

  useEffect(() => {
    if (changePasswordError !== null && changePasswordError !== password.error) {
      setPassword({ ...password, error: changePasswordError, invalid: true });
    }
  }, [password, changePasswordError]);

  useEffect(() => {
    return () => changePasswordError && dispatch(clearUserErrorMessage('changePassword'));
  }, [changePasswordError, dispatch]);

  useEffect(() => {
    setPhoneNumber(phoneNumber => ({ ...phoneNumber, value: user.phoneNumber }));
  }, [user]);

  useEffect(() => {
    if (validateCurrentPasswordError !== null && validateCurrentPasswordError !== deletePassword.error) {
      setDeletePassword({ ...deletePassword, error: validateCurrentPasswordError, invalid: true });
    }
  }, [deletePassword, validateCurrentPasswordError]);

  useEffect(() => {
    if (validateCurrentPasswordError !== null) {
      dispatch(clearUserErrorMessage('validateCurrentPassword'));
    }
  }, [deletePassword, validateCurrentPasswordError, dispatch]);

  useEffect(() => {
    if (validateCurrentPasswordSuccess) {
      dispatch(clearUserErrorMessage('validateCurrentPassword'));
      setDeleteAccountStep(3);
    }
  }, [validateCurrentPasswordSuccess, dispatch]);

  useEffect(() => {
    if (deleteMyAccountError !== null && deleteMyAccountError !== deletePassword.error) {
      setDeletePassword({ ...deletePassword, error: deleteMyAccountError, invalid: true });
    }
  }, [deletePassword, deleteMyAccountError]);

  useEffect(() => {
    setDeletePassword({ ...deletePassword, value: '', error: null, invalid: false });
    setDeleteAccountCondition(false);
    setDeleteAccountStep(1);
    dispatch(clearUserErrorMessage('deleteMyAccount'));
    return () => deleteMyAccountError && dispatch(clearUserErrorMessage('deleteMyAccount'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMyAccountError, dispatch]);

  useEffect(() => {
    if (validateCurrentPasswordSuccess) {
      dispatch(userDeleteAccount({ id: user.id }));
    }
  }, [validateCurrentPasswordSuccess, dispatch, user]);

  const handlePasswordChangeSubmit = event => {
    event.preventDefault();
    const passwordValidation = validate(password);
    const newPasswordValidation = validate(newPassword);
    const repeatNewPasswordValidation = validate(repeatNewPassword);

    setPassword({
      ...password,
      invalid: passwordValidation.invalid,
      error: passwordValidation.errors[0],
    });
    setNewPassword({
      ...newPassword,
      invalid: newPasswordValidation.invalid,
      error: newPasswordValidation.errors[0],
    });
    setRepeatNewPassword({
      ...repeatNewPassword,
      invalid: repeatNewPasswordValidation.invalid,
      error: repeatNewPasswordValidation.errors[0],
    });

    if (!passwordValidation.invalid && !newPasswordValidation.invalid && !repeatNewPasswordValidation.invalid) {
      dispatch(userChangePassword({ oldPassword: password.value, newPassword: newPassword.value }));
    }
  };

  const handlePhoneNumberChangeSubmit = event => {
    event.preventDefault();
    const phoneNumberValidation = validate(phoneNumber);

    setPhoneNumber({
      ...phoneNumber,
      invalid: phoneNumberValidation.invalid,
      error: phoneNumberValidation.errors[0],
    });

    if (!phoneNumberValidation.invalid) {
      dispatch(userChangePhoneNumber({ phoneNumber: phoneNumber.value.trim() }));
    }
  };

  const closeModal = () => {
    setPassword({ ...password, value: '' });
    setNewPassword({ ...newPassword, value: '' });
    setRepeatNewPassword({ ...repeatNewPassword, value: '' });
    dispatch(clearUserErrorMessage('changePassword'));
  };

  const confirmInitDeleteAccount = () => {
    setDeleteAccountStep(2);
  };

  const handleValidateCurrentPasswordSubmit = event => {
    event.preventDefault();
    const passwordValidation = validate(deletePassword);

    setDeletePassword({
      ...deletePassword,
      invalid: passwordValidation.invalid,
      error: passwordValidation.errors[0],
    });

    if (!passwordValidation.invalid) {
      dispatch(userValidateCurrentPassword({ password: deletePassword.value }));
    }
  };

  const cancelDeleteAccountModal = () => {
    setDeleteAccountCondition(false);

    setTimeout(() => {
      setDeleteAccountStep(1);
      setDeletePassword({ ...deletePassword, value: '', error: null, invalid: false });
      dispatch(clearUserErrorMessage('validateCurrentPassword'));
    }, 1000);

    dispatch(clearUserErrorMessage('deleteMyAccount'));
  };

  const closeDeleteAccountModal = () => {
    setDeleteAccountCondition(false);

    setTimeout(() => {
      if (deleteMyAccountSuccess) {
        dispatch(clearUserErrorMessage('deleteMyAccount'));
        dispatch(userLogout());
      }
    }, 1000);
  };

  const deleteAccountModalBodySteps = step => {
    switch (step) {
      case 1:
        return <FormattedHTMLMessage id="userProfilePage.deleteAccountModalContent" />;
      case 2:
        return (
          <>
            <Row>
              <Col xs="12" className="mt-3 mb-5">
                <FormattedHTMLMessage id="userProfilePage.deleteAccountConfirmPasswordModalContent" />
              </Col>

              <Col xs="12">
                <h6 style={{ color: '#ed1d24', marginLeft: '21px' }} className="text-left">
                  <FormattedMessage id="inputs.accountDeletePassword" />
                </h6>
              </Col>

              <Col xs="12">
                <InputGroup>
                  <CustomDashboardInput
                    id="deletePassword"
                    type="password"
                    fieldState={deletePassword}
                    className={validateCurrentPasswordError ? 'is-invalid' : ''}
                    onChange={event => {
                      changePasswordError !== null && dispatch(clearUserErrorMessage('validateCurrentPassword'));
                      setDeletePassword({ ...deletePassword, invalid: false, value: event.target.value });
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col xs="12" className="mt-3 mb-3">
                <FormattedHTMLMessage id="successMessages.userAccountDeleted" />
              </Col>
            </Row>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <UserProfile>
      <CardGroup>
        <Card className="hok-card">
          <CardBody>
            <AvatarDisplayAndChange className="my-4" avatar={user.avatar} />
            <Row className="mt-5 mb-4">
              <Col xs="12">
                <h3>
                  <FormattedMessage id="userProfilePage.mobilePhone" />
                </h3>
              </Col>
              <Col xs="12" className="d-flex align-items-end mt-3">
                <Form className="w-100" onSubmit={handlePhoneNumberChangeSubmit}>
                  <FormGroup row>
                    <Col xs="12" sm="6" xl="5">
                      <Label>
                        <FormattedMessage id="inputs.phoneNumber" />
                      </Label>
                      <CustomDashboardInput
                        id="phoneNumber"
                        name="mobile phone number"
                        type="tel"
                        // If lenght is greater than 21 formatPhoneNumberIntl() function from
                        // "react-phone-number-input" goes wild and resets the value to an empty string
                        maxLength={21}
                        tag={PhoneInput}
                        addInternationalOption={false}
                        fieldState={phoneNumber}
                        className={phoneNumber.invalid === true ? 'is-invalid' : ''}
                        onChange={phone =>
                          setPhoneNumber({ ...phoneNumber, invalid: false, value: formatPhoneNumber(phone) })
                        }
                      />
                    </Col>
                    <Col xs="12" sm="6" xl="7" className="no-label-align-helper mt-4 mt-sm-0">
                      <CustomButton
                        className="hok-outline-btn"
                        block={false}
                        outline
                        translationId="changeNumber"
                        loaderProp={changePhoneNumberInProgress}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="12">
                <h3>
                  <FormattedMessage id="inputs.email" />
                </h3>
              </Col>
              <Col xs="12" sm="6" xl="5" className="mt-3">
                <Label>
                  <FormattedMessage id="inputs.email" />
                </Label>
                <InputGroup>
                  <Input disabled defaultValue={get(user, 'email', '')} />
                </InputGroup>
              </Col>
              <Col xs="12" sm="6" className="d-flex align-items-end">
                <p>
                  <FormattedMessage id="userProfilePage.emailText" />{' '}
                  <Link className="font-weight-bold" to={ROUTE_TO_USER_SUPPORT_PAGE}>
                    <FormattedMessage id="userProfilePage.support" />
                  </Link>
                  .
                </p>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs="12">
                <h3>
                  <FormattedMessage id="userProfilePage.password" />
                </h3>
              </Col>
              <Col>
                <Form onSubmit={handlePasswordChangeSubmit}>
                  <Row>
                    <Col xs="12" sm="6" xl="5" className="mt-3">
                      <Label for="password">
                        <FormattedMessage id="userProfilePage.currentPassword" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          id="password"
                          type="password"
                          fieldState={password}
                          onChange={event => {
                            changePasswordError !== null && dispatch(clearUserErrorMessage('changePassword'));
                            setPassword({ ...password, invalid: false, value: event.target.value });
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col xs="12" sm="6" xl="7" className="d-flex align-items-end">
                      <p
                        className="hok-action-text font-weight-bold"
                        onClick={() => dispatch(userProfileForgotPassword({ email: get(user, 'email', '') }))}
                      >
                        <FormattedMessage id="userProfilePage.forgotPassword" />
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" xl="5">
                      <Label for="newPassword">
                        <FormattedMessage id="inputs.newPassword" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          id="newPassword"
                          type="password"
                          fieldState={newPassword}
                          onChange={event => {
                            setNewPassword({ ...newPassword, invalid: false, value: event.target.value });
                            setRepeatNewPassword({
                              ...repeatNewPassword,
                              validation: { ...repeatNewPassword.validation, matches: event.target.value },
                            });
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" xl="5">
                      <Label for="repeatNewPassword">
                        <FormattedMessage id="inputs.repeatNewPassword" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          id="repeatNewPassword"
                          type="password"
                          fieldState={repeatNewPassword}
                          onChange={event => {
                            setRepeatNewPassword({ ...repeatNewPassword, invalid: false, value: event.target.value });
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col xs="12" sm="6" xl="7" className="no-label-align-helper">
                      <CustomButton
                        className="hok-outline-btn"
                        block={false}
                        outline
                        translationId="changePassword"
                        loaderProp={changePasswordInProgress}
                      />
                    </Col>
                  </Row>
                </Form>
                <Modal
                  className="hok-modal dashboard-modal"
                  centered
                  backdrop="static"
                  returnFocusAfterClose={false}
                  isOpen={changePasswordSuccess}
                  toggle={closeModal}
                >
                  <ModalHeader>
                    <FormattedMessage id="userProfilePage.passwordModalTitle" />
                  </ModalHeader>
                  <ModalBody>
                    <p className="text-center">
                      <FormattedHTMLMessage id="userProfilePage.passwordModalContent" />
                    </p>
                  </ModalBody>
                  <ModalFooter className="justify-content-center">
                    <CustomButton
                      className="hok-dashboard-btn"
                      block={false}
                      translationId="close"
                      onClick={closeModal}
                    />
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CardGroup>
      <Row className="no-gutters mt-5">
        <Col xs="12">
          <div className="user-account-actions" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <CustomButton
              className="hok-outline-btn-wide"
              block={false}
              outline
              translationId="deleteAccount"
              style={{ justifySelf: 'start' }}
              onClick={() => setDeleteAccountCondition(true)}
            />
            <CustomButton
              className="hok-outline-btn"
              block={false}
              translationId="save"
              disabled
              style={{ justifySelf: 'end' }}
              // loaderProp={changeAdvanedDataInProgress}
            />
          </div>
        </Col>
      </Row>
      <Modal
        className="hok-modal dashboard-modal"
        centered
        backdrop="static"
        returnFocusAfterClose={false}
        isOpen={deleteAccountCondition}
        toggle={deleteAccountStep === 3 ? closeDeleteAccountModal : cancelDeleteAccountModal}
      >
        <ModalHeader>
          <FormattedMessage id="userProfilePage.deleteAccountModalTitle" />
        </ModalHeader>
        <ModalBody style={{ margin: '0 50px 0 50px' }}>
          <div className="text-center">{deleteAccountModalBodySteps(deleteAccountStep)}</div>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          {(() => {
            if (deleteAccountStep === 1) {
              return (
                <>
                  <CustomButton
                    className="hok-dashboard-btn"
                    outline
                    block={false}
                    translationId={'giveUp'}
                    onClick={cancelDeleteAccountModal}
                  />
                  <CustomButton
                    className="hok-dashboard-btn"
                    block={false}
                    translationId="continue"
                    onClick={confirmInitDeleteAccount}
                  />
                </>
              );
            } else if (deleteAccountStep === 2) {
              return (
                <>
                  <CustomButton
                    className="hok-dashboard-btn"
                    outline
                    block={false}
                    translationId={'giveUp'}
                    onClick={cancelDeleteAccountModal}
                  />
                  <CustomButton
                    className="hok-dashboard-btn"
                    block={false}
                    translationId="delete"
                    loaderProp={validateCurrentPasswordInProgress || deleteMyAccountInProgress}
                    onClick={handleValidateCurrentPasswordSubmit}
                  />
                </>
              );
            } else if (deleteAccountStep === 3) {
              return (
                <>
                  <CustomButton
                    className="hok-dashboard-btn"
                    outline
                    block={false}
                    translationId={'close'}
                    onClick={closeDeleteAccountModal}
                  />
                </>
              );
            }
          })()}
        </ModalFooter>
      </Modal>
    </UserProfile>
  );
};
