import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, CardGroup, Card, CardHeader, CardBody } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { BackgroundAnimation } from '../../../../components/BackgroundAnimation';
import { DescriptionText, CustomButton, ArrowLinkButton } from '../../../../containers';
import { CLAIM_CAR_LIABILITY } from '../../../../constants/claimDefs';
import {
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT,
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT,
} from '../../../../constants/routes';
import { GetClaimDocumentationQuery } from '../../../../operations/queries/GetClaimDocumentationQuery';
import { handleRequestError } from '../../../../constants/errorCodes';
import { DocumentLink } from '../../../../components/DocumentLink';
import { SupportInfoLink } from '../../SupportInfo/SupportInfoLink';
import { useGoogleAnalyticsPageview } from '../../../../utils/customHooks';

export const QuickClaimSubmit = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useGoogleAnalyticsPageview('/BrzaPrijavaŠtete');

  const { data } = useQuery(GetClaimDocumentationQuery, {
    variables: {
      filter: {
        active: true,
        required: true,
        type: 'MAIN_ATTACHMENT',
        claimGroup: CLAIM_CAR_LIABILITY,
      },
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const documents = get(data, 'getReportClaimDocuments.documents', []).filter(doc => !!doc.link);

  return (
    <div className="d-flex align-items-center quick-report">
      <BackgroundAnimation />
      <Container fluid>
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <CardGroup className="quick-report-card-group">
              <Card className="w-100">
                <CardHeader className="py-1">
                  <h2 className="d-block">
                    <FormattedMessage id="quickSubmitClaim.title" />: <FormattedMessage id="routes.carLiability" />
                  </h2>
                </CardHeader>
                <CardBody className="hok-user-dashboard py-5">
                  <Row className="no-gutters hok-description-text">
                    <Col md={{ size: 10, offset: 1 }}>
                      <Row className="no-gutters mb-5">
                        <ArrowLinkButton
                          row={false}
                          mdSize="6"
                          colOffset="0"
                          text="back"
                          linkTo={ROUTE_TO_USER_QUICK_DAMAGE_REPORT}
                        />
                        <Col xs="6" className="text-right">
                          <SupportInfoLink unwrapped />
                        </Col>
                      </Row>
                      <Row className="no-gutters">
                        <Col>
                          <DescriptionText value={CLAIM_CAR_LIABILITY} />
                          {documents.length > 0 && (
                            <>
                              <p>
                                Preuzmite obrazac, ispunite ga ručno ukoliko je pdf datoteka ili preko računala ukoliko
                                je excel datoteka, prijavite štetu unutar aplikacije pritiskom na gumb "prijavi štetu"
                                te kada Vas aplikacija zatraži prenesite ispunjeni obrazac.
                              </p>
                              <p>
                                <span className="font-weight-bold text-uppercase">NAPOMENA:</span> šteta koju
                                prijavljujete se prijavljuje po polici Autoodgovnornosti štetnika ugovorenoj unutar HOK
                                osiguranja.
                              </p>
                              <Row className="no-gutters mt-4">
                                {documents.map(document => (
                                  <Col className="d-flex align-items-center mb-3" xs="12" key={document.documentId}>
                                    <DocumentLink
                                      content={document.content + (document.mimeType ? `.${document.mimeType}` : '')}
                                      link={document.link}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </>
                          )}
                          <CustomButton
                            className="hok-dashboard-btn-wide mt-4"
                            block={false}
                            translationId="reportDamage"
                            onClick={() => history.push(`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step1`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
