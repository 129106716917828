import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, CardGroup, Card, CardHeader, CardBody, Form, FormGroup, Alert, CustomInput } from 'reactstrap';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-number-input';
import { CustomInputField, CustomButton } from '../../../containers';
import { validateFormSubmission } from '../../../utils/validation';
import { Mutation } from 'react-apollo';
import { debounce } from 'lodash-es';
import { UserUpdateBusinessUserMutation } from '../../../operations/mutations/UserUpdateProfileMutation';
import {
  getErrorObject,
  isAuthTokenInvalid,
  isMaintenanceInProgress,
  INVALID_OIB_FORMAT,
} from '../../../constants/errorCodes';
import {
  redirectToMaintenancePage,
  redirectToRegistrationCompletePage,
} from '../../../redux/actions/navigationActions';
import { userLoginFail, userUpdateDataInStore } from '../../../redux/actions/userAuthActions';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { v4 } from 'uuid';

export const BusinessUserForm = injectIntl(({ countries, loadingCountries, places, loadingPlaces, user, ...props }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [submitted, setSubmitted] = useState(false);
  const [mbo, setMbo] = useState({
    value: user.mbo || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.MBO" />,
    validation: {},
  });
  const [OIB, setOIB] = useState({
    value: user.oib || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: true,
      maxLength: 11,
      minLength: 11,
      oib: true,
    },
  });
  const [name, setName] = useState({
    value: user.businessName || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.name" />,
    validation: {
      required: true,
    },
  });
  const [VATpayer, setTaxPayer] = useState({
    value: user.VATpayer || false,
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.VATpayer" />,
    validation: {
      required: true,
    },
  });
  const [address, setAddress] = useState({
    value: user.address || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });
  const [zip, setZip] = useState({
    value: user.zip || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
      enum: places.map(place => place.pttNumber),
    },
  });
  const [city, setCity] = useState({
    value: user.city || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.place" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [country, setCountry] = useState({
    value: user.country || 'HR',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.country" />,
    validation: {
      required: true,
      enum: countries.map(country => country.codeLegacy),
    },
  });
  const [businessFirstName, setBusinessFirstName] = useState({
    value: user.businessFirstName || '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {},
  });
  const [businessLastName, setBusinessLastName] = useState({
    value: user.businessLastName || '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {},
  });
  const [businessTelephone, setBusinessTelephone] = useState({
    value: user.businessTelephone || '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.businessTelephone" />,
    validation: {},
  });
  const [webAddress, setWebAddress] = useState({
    value: user.webAddress || '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.webAddress" />,
    validation: {},
  });

  const [filteredPlaces, setFilteredPlaces] = useState([...places]);

  useEffect(() => {
    if (places.length > 0) {
      setFilteredPlaces([...places]);
    }
  }, [places]);

  const debouncedPlacesFilter = debounce(value => {
    const matchingPlaces = places.filter(place => place.pttNumber.startsWith(value));
    setFilteredPlaces(value ? matchingPlaces : [...places]);
    if (matchingPlaces.length === 1 && matchingPlaces[0].pttNumber === value) {
      setCity(city => ({ ...city, invalid: false, value: matchingPlaces[0].name }));
    } else {
      setCity(city => ({ ...city, invalid: false, value: '' }));
    }
  }, 500);

  useEffect(() => {
    if (places.length > 0 && city.validation.enum.length < 1) {
      setCity(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
    }
    if (places.length > 0 && zip.validation.enum.length < 1) {
      setZip(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
    }

    if (countries.length > 0 && country.validation.enum.length < 1) {
      setCountry(o => ({
        ...o,
        validation: { ...o.validation, enum: countries.map(country => country.codeLegacy) },
      }));
    }
  }, [places, city, countries, country, zip]);

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false }));
  };

  const checkIfInvalidOibError = error => {
    const errorObject = getErrorObject(error);
    if (errorObject.key === INVALID_OIB_FORMAT) {
      OIB.error !== errorObject.message && setOIB(o => ({ ...o, invalid: true, error: errorObject.message }));
    } else {
      return errorObject.message;
    }
  };

  return (
    <Row className="no-gutters">
      <Col xs="12" md={{ offset: 2, size: 8 }}>
        <Mutation
          mutation={UserUpdateBusinessUserMutation}
          onCompleted={data => {
            dispatch(userUpdateDataInStore({ id: data.updateProfile.id, ...data.updateProfile.profile }));
            dispatch(redirectToRegistrationCompletePage({ sector: USER_ACCOUNT_BUSINESS }));
          }}
          onError={error => {
            const errorObject = getErrorObject(error);
            if (isAuthTokenInvalid(errorObject)) {
              dispatch(userLoginFail({ errorMessage: errorObject.message }));
            } else if (isMaintenanceInProgress(errorObject)) {
              dispatch(redirectToMaintenancePage());
            }
          }}
        >
          {(updateProfile, { loading, error }) => {
            const handleUserDataSubmit = async event => {
              event.preventDefault();

              const fieldsArray = [
                setOIB,
                setMbo,
                setName,
                setTaxPayer,
                setAddress,
                setZip,
                setCity,
                setCountry,
                setBusinessFirstName,
                setBusinessLastName,
                setBusinessTelephone,
                setWebAddress,
              ];

              setSubmitted(true);
              if (await validateFormSubmission(fieldsArray)) {
                updateProfile({
                  variables: {
                    mbo: mbo.value,
                    oib: OIB.value,
                    businessName: name.value,
                    VATpayer: VATpayer.value,
                    address: address.value,
                    city: city.value,
                    zip: zip.value,
                    country: country.value,
                    sector: USER_ACCOUNT_BUSINESS,
                    businessFirstName: businessFirstName.value,
                    businessLastName: businessLastName.value,
                    businessTelephone: businessTelephone.value,
                    webAddress: webAddress.value,
                  },
                });
              }
            };

            let displayError = null;
            if (error) {
              displayError = checkIfInvalidOibError(error);
            }

            return (
              <Form onSubmit={handleUserDataSubmit}>
                <CardGroup>
                  <Card className="content-card">
                    <CardHeader className="border-0">
                      <h2 className="mx-auto">
                        <FormattedMessage id="userTypeSelectPage.businessUser.title" />
                      </h2>
                    </CardHeader>
                    <CardBody>
                      <p className="text-left">
                        <FormattedMessage id="userTypeSelectPage.privateUser.paragraph1" />
                      </p>
                      <Row className="mt-4">
                        <Col>
                          {displayError && (
                            <Alert className="mb-0" color="danger" fade={false}>
                              {displayError}
                            </Alert>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xs="12" sm={{ offset: 2, size: 8 }}>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="MBO"
                                value={mbo.value}
                                fieldState={mbo}
                                formSubmitted={submitted}
                                onChange={event => inputHandler(setMbo, event.target.value)}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="OIB"
                                name="OIB"
                                value={OIB.value}
                                fieldState={OIB}
                                formSubmitted={submitted}
                                pattern="[0-9]*"
                                onChange={event => {
                                  if (event.target.validity.valid) {
                                    inputHandler(setOIB, event.target.value);
                                  }
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="name"
                                value={name.value}
                                fieldState={name}
                                formSubmitted={submitted}
                                onChange={event => inputHandler(setName, event.target.value)}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mt-4">
                            <Col xs="12">
                              <CustomInput
                                className="hok-reg-checkbox"
                                id="VATpayer"
                                type="checkbox"
                                checked={VATpayer.value}
                                onChange={event => inputHandler(setTaxPayer, event.target.checked)}
                                label={props.intl.formatMessage({ id: 'inputs.VATpayer' })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="address"
                                name="address"
                                value={address.value}
                                fieldState={address}
                                formSubmitted={submitted}
                                onChange={event => inputHandler(setAddress, event.target.value)}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="zipCode"
                                name="zip code"
                                value={zip.value}
                                fieldState={zip}
                                formSubmitted={submitted}
                                pattern="[0-9]*"
                                onChange={event => {
                                  event.persist();
                                  debouncedPlacesFilter(event.target.value);
                                  setZip(zip => ({ ...zip, invalid: false, value: event.target.value }));
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="city"
                                name="city"
                                type="select"
                                value={city.value}
                                fieldState={city}
                                formSubmitted={submitted}
                                onChange={event => {
                                  const place = places.find(place => place.name === event.target.value);
                                  inputHandler(setCity, event.target.value);
                                  place && inputHandler(setZip, place.pttNumber);
                                }}
                              >
                                <option disabled value="">
                                  {formatMessage({
                                    id: 'inputs.labels.selectPlace',
                                  })}
                                </option>
                                {loadingPlaces ? (
                                  <option disabled value={city.value}>
                                    {city.value}
                                  </option>
                                ) : (
                                  filteredPlaces.map(place => (
                                    <option key={v4()} value={place.name}>
                                      {place.name}
                                    </option>
                                  ))
                                )}
                              </CustomInputField>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="country"
                                name="country"
                                type="select"
                                value={country.value}
                                fieldState={country}
                                formSubmitted={submitted}
                                disabled
                                // onChange={event => inputHandler(setCountry, event.target.value)}
                              >
                                <option disabled value="">
                                  {props.intl.formatMessage({
                                    id: 'inputs.country',
                                  })}
                                </option>
                                {loadingCountries ? (
                                  <option disabled value="">
                                    {props.intl.formatMessage({
                                      id: loadingCountries ? 'loading' : 'inputs.country',
                                    })}
                                  </option>
                                ) : (
                                  countries.map(country => (
                                    <option key={country.codeLegacy} value={country.codeLegacy}>
                                      {country.nameCro}
                                    </option>
                                  ))
                                )}
                              </CustomInputField>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="webAddress"
                                name="web address"
                                value={webAddress.value}
                                fieldState={webAddress}
                                formSubmitted={submitted}
                                onChange={event => inputHandler(setWebAddress, event.target.value)}
                              />
                            </Col>
                          </FormGroup>
                          <Row className="no-gutters mt-5">
                            <Col>
                              <h5>
                                <FormattedMessage id="userTypeSelectPage.businessUser.contactPerson" />:
                              </h5>
                            </Col>
                          </Row>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="firstName"
                                name="first name"
                                value={businessFirstName.value}
                                fieldState={businessFirstName}
                                formSubmitted={submitted}
                                onChange={event => inputHandler(setBusinessFirstName, event.target.value)}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="lastName"
                                name="last name"
                                value={businessLastName.value}
                                fieldState={businessLastName}
                                formSubmitted={submitted}
                                onChange={event => inputHandler(setBusinessLastName, event.target.value)}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs="12">
                              <CustomInputField
                                id="businessTelephone"
                                name="business telephone"
                                fieldState={businessTelephone}
                                formSubmitted={submitted}
                                tag={PhoneInput}
                                numberInputProps={
                                  'form-control' + (submitted && businessTelephone.invalid ? ' is-invalid' : '')
                                }
                                type="tel"
                                // If lenght is greater than 21 formatPhoneNumberIntl() function from
                                // "react-phone-number-input" goes wild and resets the value to an empty string
                                maxLength={21}
                                defaultCountry="HR"
                                addInternationalOption={false}
                                onChange={phone => inputHandler(setBusinessTelephone, formatPhoneNumber(phone))}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
                <Row className="no-gutters mt-5">
                  <Col sm={{ offset: 2, size: 8 }} className="px-3">
                    <CustomButton translationId="check" loaderProp={loading} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Mutation>
      </Col>
    </Row>
  );
});
