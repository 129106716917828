import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, InputGroup, Input } from 'reactstrap';
import { get } from 'lodash-es';
import { CollapsibleContainer, CustomDashboardInput } from '../../containers';
import { useIntl, FormattedMessage } from 'react-intl';
import { formatDate } from '../../constants/dateFormatting';
import { USER_ACCOUNT_BUSINESS, USER_ACCOUNT_PRIVATE } from '../../constants/userDefs';

export const PolicyContractingPersonInfo = React.memo(
  ({
    user,
    labelId,
    label = false,
    showHzzo = false,
    editHzzo = false,
    children,
    hzzoOnChange,
    hzzoFieldState,
    invalid = false,
    expanded = false,
  }) => {
    const { formatMessage } = useIntl();

    const [HZZONumber] = useState({
      value: get(user, 'HZZONumber', ''),
      error: null,
      invalid: false,
      displayName: <FormattedMessage id="inputs.HZZONumber" />,
      validation: {
        required: true,
      },
    });

    const title =
      user.sector === USER_ACCOUNT_BUSINESS ? user.businessName : `${user.firstName} ${user.lastName}`.trim();

    return (
      <>
        {label ? (
          <Label>
            <FormattedMessage id={labelId} />:
          </Label>
        ) : (
          <h3 className="subtitle pl-4">
            <FormattedMessage id={labelId} />:
          </h3>
        )}
        <Row className="no-gutters">
          <Col xs="12">
            <CollapsibleContainer expanded={expanded} title={title || formatMessage({ id: labelId })} invalid={invalid}>
              {children}
              <FormGroup row>
                {user.sector === USER_ACCOUNT_BUSINESS ? (
                  <Col sm="6" md="4">
                    <Label for="name">
                      <FormattedMessage id="inputs.name" />
                    </Label>
                    <InputGroup>
                      <Input id="name" defaultValue={user.businessName} readOnly />
                    </InputGroup>
                  </Col>
                ) : (
                  <>
                    <Col sm="6" md="4">
                      <Label for="firstName">
                        <FormattedMessage id="inputs.firstName" />
                      </Label>
                      <InputGroup>
                        <Input id="firstName" defaultValue={user.firstName} readOnly />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="lastName">
                        <FormattedMessage id="inputs.lastName" />
                      </Label>
                      <InputGroup>
                        <Input id="lastName" defaultValue={user.lastName} readOnly />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="dateOfBirth">
                        <FormattedMessage id="inputs.dateOfBirth" />
                      </Label>
                      <InputGroup>
                        <Input id="dateOfBirth" defaultValue={formatDate(user.dateOfBirth)} readOnly />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="sex">
                        <FormattedMessage id="inputs.sex" />
                      </Label>
                      <InputGroup>
                        <Input id="sex" defaultValue={formatMessage({ id: user.sex })} readOnly />
                      </InputGroup>
                    </Col>
                  </>
                )}
                <Col sm="6" md="4">
                  <Label for="OIB">
                    <FormattedMessage id="inputs.OIB" />
                  </Label>
                  <InputGroup>
                    <Input id="OIB" defaultValue={user.oib} readOnly />
                  </InputGroup>
                </Col>
                {showHzzo && user.sector === USER_ACCOUNT_PRIVATE && (
                  <Col sm="6" md="4">
                    <Label for="HZZONumber">
                      <FormattedMessage id="inputs.HZZONumber" />
                    </Label>
                    <InputGroup>
                      <CustomDashboardInput
                        id="HZZONumber"
                        fieldState={editHzzo ? hzzoFieldState : HZZONumber}
                        readOnly={!editHzzo}
                        onChange={event => {
                          if (editHzzo && typeof hzzoOnChange === 'function') {
                            hzzoOnChange(event.target.value);
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                )}
              </FormGroup>
              <FormGroup row>
                <Col sm="6" md="4">
                  <Label for="address">
                    <FormattedMessage id="inputs.address" />
                  </Label>
                  <InputGroup>
                    <Input id="address" defaultValue={user.address} readOnly />
                  </InputGroup>
                </Col>
                <Col sm="6" md="4">
                  <Label for="zip">
                    <FormattedMessage id="inputs.zipCode" />
                  </Label>
                  <InputGroup>
                    <Input id="zip" defaultValue={user.zip} readOnly />
                  </InputGroup>
                </Col>
                <Col sm="6" md="4">
                  <Label for="place">
                    <FormattedMessage id="inputs.place" />
                  </Label>
                  <InputGroup>
                    <Input id="place" defaultValue={user.city} readOnly />
                  </InputGroup>
                </Col>
                <Col sm="6" md="4">
                  <Label for="phoneNumber">
                    <FormattedMessage id="inputs.phoneNumberShort" />
                  </Label>
                  <InputGroup>
                    <Input id="phoneNumber" defaultValue={user.phoneNumber} readOnly />
                  </InputGroup>
                </Col>
                <Col sm="6" md="4">
                  <Label for="email">
                    <FormattedMessage id="inputs.email" />
                  </Label>
                  <InputGroup>
                    <Input id="email" defaultValue={user.email} readOnly />
                  </InputGroup>
                </Col>
              </FormGroup>
            </CollapsibleContainer>
          </Col>
        </Row>
      </>
    );
  }
);
