export const BUILDING_TYPE_HOUSE = 'Kuća';
export const BUILDING_TYPE_FLAT = 'Stan';
export const BUILDING_TYPE_APARTMENT = 'Apartman';

export const MOJ_DOM_BUILDING_TYPES = [
  { id: BUILDING_TYPE_HOUSE, name: `productEnquiry.${BUILDING_TYPE_HOUSE}` },
  { id: BUILDING_TYPE_FLAT, name: `productEnquiry.${BUILDING_TYPE_FLAT}` },
];

export const HOK_APARTMAN_BUILDING_TYPES = [
  { id: BUILDING_TYPE_HOUSE, name: `productEnquiry.${BUILDING_TYPE_HOUSE}` },
  { id: BUILDING_TYPE_APARTMENT, name: `productEnquiry.${BUILDING_TYPE_APARTMENT}` },
];

export const INSURED_ITEM_BUILDING = 'Objekt';
export const INSURED_ITEM_MOVABLE = 'Pokretnine';
export const INSURED_ITEM_BUILDING_AND_MOVABLE = 'Objekt i pokretnine';

export const INSURED_ITEM_TYPES = [
  { id: INSURED_ITEM_BUILDING, name: `productEnquiry.${INSURED_ITEM_BUILDING}` },
  { id: INSURED_ITEM_MOVABLE, name: `productEnquiry.${INSURED_ITEM_MOVABLE}` },
  { id: INSURED_ITEM_BUILDING_AND_MOVABLE, name: `productEnquiry.${INSURED_ITEM_BUILDING_AND_MOVABLE}` },
];

export const CONSTRUCTION_METHOD_MASSIVE = 'Masivna (zidana)';
export const CONSTRUCTION_METHOD_MIXED = 'Mješovita (montažne kuće)';
export const CONSTRUCTION_METHOD_WOODEN = 'Drvena';

export const CONSTRUCTION_METHOD_TYPES = [
  { id: CONSTRUCTION_METHOD_MASSIVE, name: `productEnquiry.${CONSTRUCTION_METHOD_MASSIVE}` },
  { id: CONSTRUCTION_METHOD_MIXED, name: `productEnquiry.${CONSTRUCTION_METHOD_MIXED}` },
  { id: CONSTRUCTION_METHOD_WOODEN, name: `productEnquiry.${CONSTRUCTION_METHOD_WOODEN}` },
];

export const POPULATION_CATEGORIZATION_INHABITED = 'Stalno nastanjen';
export const POPULATION_CATEGORIZATION_UNINHABITED = 'Nenastanjen';

export const POPULATION_CATEGORIZATION_TYPES = [
  { id: POPULATION_CATEGORIZATION_INHABITED, name: `productEnquiry.${POPULATION_CATEGORIZATION_INHABITED}` },
  { id: POPULATION_CATEGORIZATION_UNINHABITED, name: `productEnquiry.${POPULATION_CATEGORIZATION_UNINHABITED}` },
];

export const INSURANCE_YEAR = 'INSURANCE_YEAR';

export const INSURANCE_PERIOD_TYPES = [{ id: INSURANCE_YEAR, name: `productEnquiry.${INSURANCE_YEAR}` }];
