import gql from 'graphql-tag';

export const GetStatusQuery = gql`
  query GetStatus {
    status {
      version
      insuranceSystemAPIVersion
      insuranceDocumentManagmentServiceAPIVersion
    }
  }
`;
