import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  ROUTE_TO_USER_LOGIN_PAGE,
  ROUTE_TO_ADMIN_DASHBOARD_PAGE,
  ROUTE_TO_ACTIVATE_ACCOUNT_PAGE,
  ROUTE_TO_USER_SELECTION_PAGE,
  ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE,
  ROUTE_TO_USER_DASHBOARD_PAGE,
  ROUTE_TO_USER_LOGOUT_PAGE,
} from '../constants/routes';
import { getLocalStorageToken, removeLocalStorageToken } from '../constants/LocalStorageKeys';
import jwt_decode from 'jwt-decode';
import { get } from 'lodash-es';
import { Spinner, ContentContainer } from '../containers';
import { Row, Col } from 'reactstrap';
import { USER_ACCOUNT_BUSINESS } from '../constants/userDefs';

export const isUserLoggedIn = () => {
  const token = getLocalStorageToken('AUTH_TOKEN_KEY');
  if (typeof token !== 'undefined' && token !== null) {
    try {
      const decodedToken = jwt_decode(token);
      return !!decodedToken.userId;
    } catch (e) {
      removeLocalStorageToken('AUTH_TOKEN_KEY');
      return false;
    }
  }
  return false;
};

export const isAdminLoggedIn = () => {
  const token = getLocalStorageToken('AUTH_TOKEN_KEY');
  if (typeof token !== 'undefined' && token !== null) {
    try {
      const decodedToken = jwt_decode(token);
      return !!decodedToken.employeeId;
    } catch (e) {
      removeLocalStorageToken('AUTH_TOKEN_KEY');
      return false;
    }
  }
  return false;
};

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
});

/**
 * Higher order component (HOC) to require auth
 *
 * usage:
 *   import requireAuth from './RequireAuth';
 *
 *   <Route component={requireAuth(Todos, checkRoles)} name="todos"/>
 *
 * @export
 * @param {any} WrappedComponent
 * @param {any} checkRoles
 * @param {string} redirectTo
 *            checkRoles:   function that accepts an object containing user.role
 *                          returns true or false whether user has permission to access the route
 *            redirectTo:   string that represents the route to redirect the user to if it doesn't
 *                          have access to the requested route
 * @returns WrappedComponent when authenticated, null when not authenticated
 *          A redirect should occur before nothing is rendered
 */
export function adminRequireAuth(
  WrappedComponent,
  checkRoles = false,
  redirectTo = ROUTE_TO_ADMIN_DASHBOARD_PAGE,
  componentProps
) {
  class AuthenticatedComponent extends React.Component {
    render() {
      return this._adminDataLoaded() ? (
        this._checkRoles() ? (
          <WrappedComponent {...componentProps} />
        ) : (
          <Redirect to={{ pathname: redirectTo }} />
        )
      ) : (
        <Spinner className="spinner-positioning" />
      );
    }

    _adminDataLoaded() {
      return !!get(this.props, 'auth.user.id', '');
    }

    _checkRoles() {
      if (typeof checkRoles !== 'function') {
        return false;
      }
      return checkRoles(this.props.auth.user);
    }
  }

  return connect(mapStateToProps)(AuthenticatedComponent);
}

export function userRequireAuth(
  WrappedComponent,
  checkRoles = false,
  redirectTo = ROUTE_TO_USER_LOGIN_PAGE,
  componentProps
) {
  class AuthenticatedComponent extends React.Component {
    registrationStepsRoutes = [
      ROUTE_TO_USER_SELECTION_PAGE,
      `${ROUTE_TO_USER_SELECTION_PAGE}/private`,
      `${ROUTE_TO_USER_SELECTION_PAGE}/business`,
    ];

    render() {
      return this._userDataLoaded() ? (
        this._checkRoles() ? (
          this._forceRedirectToRegistrationSteps()
        ) : (
          <Redirect to={{ pathname: redirectTo }} />
        )
      ) : [
          ...this.registrationStepsRoutes,
          ROUTE_TO_ACTIVATE_ACCOUNT_PAGE,
          ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE,
        ].includes(this.props.router.location.pathname) ? (
        <ContentContainer titleTranslationId="contentContainerTitle.loading">
          <Row className="main-content">
            <Col xs="12" className="text-center">
              <Spinner className="spinner-positioning" />
            </Col>
          </Row>
        </ContentContainer>
      ) : (
        <Spinner className="spinner-positioning" />
      );
    }

    _userDataLoaded() {
      return !!get(this.props, 'auth.user.id', '');
    }

    _checkRoles() {
      if (typeof checkRoles !== 'function') {
        return false;
      }
      return checkRoles(this.props.auth.user);
    }

    _forceRedirectToRegistrationSteps() {
      const path = this.props.router.location.pathname;

      if (path === ROUTE_TO_USER_LOGOUT_PAGE) {
        return <WrappedComponent {...componentProps} />;
      }
      if (!get(this.props, 'auth.user.activated', false)) {
        return path !== ROUTE_TO_ACTIVATE_ACCOUNT_PAGE ? (
          <Redirect to={{ pathname: ROUTE_TO_ACTIVATE_ACCOUNT_PAGE }} />
        ) : (
          <WrappedComponent {...componentProps} />
        );
      }

      if (
        get(this.props, 'auth.user.sector', null) === USER_ACCOUNT_BUSINESS &&
        get(this.props, 'auth.user.mbo', null)
      ) {
        if (get(this.props, 'auth.user.partnerId', null)) {
          if (
            [
              ...this.registrationStepsRoutes,
              ROUTE_TO_ACTIVATE_ACCOUNT_PAGE,
              ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE,
            ].includes(path)
          ) {
            return <Redirect to={{ pathname: ROUTE_TO_USER_DASHBOARD_PAGE }} />;
          }
          return <WrappedComponent {...componentProps} />;
        }
        if (path === ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE) {
          return <WrappedComponent {...componentProps} />;
        }
        return (
          <Redirect
            to={{
              pathname: ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE,
              state: { completed: true, sector: get(this.props, 'auth.user.sector', null) },
            }}
          />
        );
      }

      if (!get(this.props, 'auth.user.sector', null) || !get(this.props, 'auth.user.verified', null)) {
        return !this.registrationStepsRoutes.includes(path) ? (
          get(this.props, 'auth.user.sector', null) ? (
            <Redirect
              to={{ pathname: `${ROUTE_TO_USER_SELECTION_PAGE}/${get(this.props, 'auth.user.sector', null)}` }}
            />
          ) : (
            <Redirect to={{ pathname: ROUTE_TO_USER_SELECTION_PAGE }} />
          )
        ) : (
          <WrappedComponent {...componentProps} />
        );
      }
      if (
        get(this.props, 'auth.user.verified', null) &&
        [...this.registrationStepsRoutes, ROUTE_TO_ACTIVATE_ACCOUNT_PAGE].includes(path)
      ) {
        return <Redirect to={{ pathname: ROUTE_TO_USER_DASHBOARD_PAGE }} />;
      }
      return <WrappedComponent {...componentProps} />;
    }
  }

  return connect(mapStateToProps)(AuthenticatedComponent);
}
