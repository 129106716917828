// Change password
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const userChangePassword = payload => ({
  type: USER_CHANGE_PASSWORD,
  payload: payload,
});

export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const userChangePasswordSuccess = () => ({
  type: USER_CHANGE_PASSWORD_SUCCESS,
});

export const USER_CHANGE_PASSWORD_FAIL = 'USER_CHANGE_PASSWORD_FAIL';
export const userChangePasswordFail = payload => ({
  type: USER_CHANGE_PASSWORD_FAIL,
  payload: payload,
});

export const USER_CHANGE_AVATAR = 'USER_CHANGE_AVATAR';
export const userChangeAvatar = image => ({
  type: USER_CHANGE_AVATAR,
  image,
});

export const USER_CHANGE_AVATAR_SUCCESS = 'USER_CHANGE_AVATAR_SUCCESS';
export const userChangeAvatarSuccess = payload => ({
  type: USER_CHANGE_AVATAR_SUCCESS,
  payload: payload,
});

// Change phone number
export const USER_CHANGE_PHONE_NUMBER = 'USER_CHANGE_PHONE_NUMBER';
export const userChangePhoneNumber = payload => ({
  type: USER_CHANGE_PHONE_NUMBER,
  payload: payload,
});

export const USER_CHANGE_PHONE_NUMBER_FEEDBACK = 'USER_CHANGE_PHONE_NUMBER_FEEDBACK';
export const userChangePhoneNumberFeedback = () => ({
  type: USER_CHANGE_PHONE_NUMBER_FEEDBACK,
});

// Update user profile advanced data
export const USER_UPDATE_PROFILE_ADVANCED_DATA = 'USER_UPDATE_PROFILE_ADVANCED_DATA';
export const userUpdateProfileAdvancedData = payload => ({
  type: USER_UPDATE_PROFILE_ADVANCED_DATA,
  payload: payload,
});

export const USER_UPDATE_PROFILE_ADVANCED_DATA_FEEDBACK = 'USER_UPDATE_PROFILE_ADVANCED_DATA_FEEDBACK';
export const userUpdateProfileAdvancedDataFeedback = payload => ({
  type: USER_UPDATE_PROFILE_ADVANCED_DATA_FEEDBACK,
  payload: payload,
});

// Forgot password
export const USER_PROFILE_FORGOT_PASSWORD = 'USER_PROFILE_FORGOT_PASSWORD';
export const userProfileForgotPassword = email => ({
  type: USER_PROFILE_FORGOT_PASSWORD,
  payload: email,
});

export const USER_PROFILE_FORGOT_PASSWORD_SUCCESS = 'USER_PROFILE_FORGOT_PASSWORD_SUCCESS';
export const userProfileForgotPasswordSuccess = () => ({
  type: USER_PROFILE_FORGOT_PASSWORD_SUCCESS,
});

export const USER_PROFILE_FORGOT_PASSWORD_FAIL = 'USER_PROFILE_FORGOT_PASSWORD_FAIL';
export const userProfileForgotPasswordFail = () => ({
  type: USER_PROFILE_FORGOT_PASSWORD_FAIL,
});

// Validate current user password
export const USER_VALIDATE_CURRENT_PASSWORD = 'USER_VALIDATE_CURRENT_PASSWORD';
export const userValidateCurrentPassword = payload => ({
  type: USER_VALIDATE_CURRENT_PASSWORD,
  payload: payload,
});

export const USER_VALIDATE_CURRENT_PASSWORD_SUCCESS = 'USER_VALIDATE_CURRENT_PASSWORD_SUCCESS';
export const userValidateCurrentPasswordSuccess = () => ({
  type: USER_VALIDATE_CURRENT_PASSWORD_SUCCESS,
});

export const USER_VALIDATE_CURRENT_PASSWORD_FAIL = 'USER_VALIDATE_CURRENT_PASSWORD_FAIL';
export const userValidateCurrentPasswordFail = payload => ({
  type: USER_VALIDATE_CURRENT_PASSWORD_FAIL,
  payload: payload,
});

// Delete account
export const USER_DELETE_ACCOUNT = 'USER_DELETE_ACCOUNT';
export const userDeleteAccount = payload => ({
  type: USER_DELETE_ACCOUNT,
  payload: payload,
});

export const USER_DELETE_ACCOUNT_SUCCESS = 'USER_DELETE_ACCOUNT_SUCCESS';
export const userDeleteAccountSuccess = () => ({
  type: USER_DELETE_ACCOUNT_SUCCESS,
});

export const USER_DELETE_ACCOUNT_FAIL = 'USER_DELETE_ACCOUNT_FAIL';
export const userDeleteAccountFail = payload => ({
  type: USER_DELETE_ACCOUNT_FAIL,
  payload: payload,
});

// Clear actions errors for user profile page
export const USER_PROFILE_CLEAR_ERROR_MESSAGE = 'USER_PROFILE_CLEAR_ERROR_MESSAGE';
export const clearUserErrorMessage = objectName => ({
  type: USER_PROFILE_CLEAR_ERROR_MESSAGE,
  payload: objectName,
});
