import gql from 'graphql-tag';

export const GetUserVehicleDataQuery = gql`
  query GetUserVehicleData($vinNumber: String) {
    vehicleData(vinNumber: $vinNumber) {
      brojSasije
      regOznaka
      vrstaVozilaOznaka
      vrstaVozilaNaziv
      markaVozilaOznaka
      markaVozilaNaziv
      tipVozilaNaziv
      model
      godinaProizvodnje
      proizvodjacVozilaNaziv
      snagaMotora
      obujamMotora
      brojMjestaSjedenje
      vrstaEnumCode
      vrstaCode
    }
  }
`;
