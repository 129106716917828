import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, FormGroup, Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { LoginAndRegisterSelector, CustomInputField, CustomButton } from '../../../containers';
import {
  clearUserErrorMessage,
  clearUserSuccessMessage,
  userPasswordReset,
  userPasswordResetTokenCheck,
} from '../../../redux/actions/userAuthActions';
import { redirectToUserLoginPage } from '../../../redux/actions/navigationActions';
import { validate } from '../../../utils/validation';

const PasswordRecovery = () => {
  const passwordRecovery = useSelector(state => state.auth.passwordRecovery);
  const dispatch = useDispatch();
  const params = useParams();

  const passwordResetToken = get(params, 'passwordResetToken', null);

  const [submitted, setSubmitted] = useState(false);

  const [password, setPassword] = useState({
    value: '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.password" />,
    validation: {
      required: true,
      minLength: 8,
    },
  });
  const [repeatPassword, setRepeatPassword] = useState({
    value: '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.repeatPassword" />,
    validation: {
      required: true,
      matches: '',
    },
  });

  // componentDidMount
  useEffect(() => {
    dispatch(userPasswordResetTokenCheck(passwordResetToken));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submitPasswordRecovery = event => {
    event.preventDefault();
    const passwordValidation = validate(password);
    const repeatPasswordValidation = validate(repeatPassword);
    setPassword({
      ...password,
      invalid: passwordValidation.invalid,
      error: passwordValidation.errors[0],
    });
    setRepeatPassword({
      ...repeatPassword,
      invalid: repeatPasswordValidation.invalid,
      error: repeatPasswordValidation.errors[0],
    });

    setSubmitted(true);
    if (!passwordValidation.invalid && !repeatPasswordValidation.invalid) {
      dispatch(userPasswordReset({ passwordResetToken, newPassword: password.value }));
      setSubmitted(false);
    }
  };

  const clearFeedbackOnValueChange = () => {
    if (get(passwordRecovery, 'errorMessage', null)) {
      dispatch(clearUserErrorMessage('passwordRecovery'));
    }
    if (get(passwordRecovery, 'success', false)) {
      dispatch(clearUserSuccessMessage('passwordRecovery'));
    }
  };

  return (
    <LoginAndRegisterSelector titleTranslationId="passwordRecoveryPage.title" showContactSupportLink={true}>
      <Row className="no-gutters px-4 my-5 my-md-4">
        <Col xs={12} sm={{ offset: 2, size: 8 }} md={{ offset: 1, size: 10 }} lg={{ offset: 2, size: 8 }}>
          <Row className="no-gutters mb-4">
            <Col xs="12">
              <div className="hok-text">
                <FormattedMessage id="passwordRecoveryPage.paragraph1" />
              </div>
            </Col>
          </Row>
          {get(passwordRecovery, 'done', null) && (
            <Row className="no-gutters mt-4">
              <Col xs="12">
                {get(passwordRecovery, 'errorMessage', null) && (
                  <Alert color="danger" role="alert">
                    {passwordRecovery.errorMessage}
                  </Alert>
                )}
                {get(passwordRecovery, 'success', false) && (
                  <Alert color="success" role="alert">
                    <FormattedMessage id="passwordRecoveryPage.successMessage" />
                  </Alert>
                )}
              </Col>
            </Row>
          )}
          {get(passwordRecovery, 'done', null) && get(passwordRecovery, 'success', false) ? (
            <Row className="no-gutters mt-4">
              <Col xs="12">
                <CustomButton
                  onClick={() => {
                    clearFeedbackOnValueChange();
                    dispatch(redirectToUserLoginPage());
                  }}
                  translationId="linkToLogin"
                />
              </Col>
            </Row>
          ) : (
            <Row className="no-gutters">
              <Col xs="12">
                <Form onSubmit={submitPasswordRecovery}>
                  <FormGroup>
                    <CustomInputField
                      id="password"
                      name="password"
                      fieldState={password}
                      formSubmitted={submitted}
                      type="password"
                      icon="lock"
                      maxLength={255}
                      onChange={event => {
                        clearFeedbackOnValueChange();
                        setPassword({ ...password, value: event.target.value });
                        setRepeatPassword({
                          ...repeatPassword,
                          validation: { ...repeatPassword.validation, matches: event.target.value },
                        });
                      }}
                      onFocus={() => setPassword({ ...password, focused: true })}
                      onBlur={() => setPassword({ ...password, focused: false })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <CustomInputField
                      id="repeatPassword"
                      name="repeat password"
                      fieldState={repeatPassword}
                      formSubmitted={submitted}
                      type="password"
                      icon="lock"
                      maxLength={255}
                      onChange={event => {
                        clearFeedbackOnValueChange();
                        setRepeatPassword({ ...repeatPassword, value: event.target.value });
                      }}
                      onFocus={() => setRepeatPassword({ ...repeatPassword, focused: true })}
                      onBlur={() => setRepeatPassword({ ...repeatPassword, focused: false })}
                    />
                  </FormGroup>
                  <FormGroup className="mt-5">
                    <Col xs="12">
                      <CustomButton
                        loaderProp={get(passwordRecovery, 'inProgress', false)}
                        translationId="setNewPassword"
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </LoginAndRegisterSelector>
  );
};

export default PasswordRecovery;
