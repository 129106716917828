import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Fade } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { Spinner, CustomButton } from '../../containers';
import { CustomPagination } from '../CustomPagination';
import { TicketComments } from './TicketComments';
import { TicketWriteComment } from './TicketWriteComment';
import {
  NewUserTicketMessageSubscription,
  NewAdminTicketMessageSubscription,
} from '../../operations/subscriptions/NewTicketMessageSubscription';
import { handleRequestError } from '../../constants/errorCodes';
import { getLocalStorageToken } from '../../constants/LocalStorageKeys';
import { useLocation } from 'react-router-dom';
import { ROUTE_TO_ADMIN_TICKETING_PAGE } from '../../constants/routes';

export const TicketCommentsBox = React.memo(
  ({ data, loading, error, fetchMore, subscribeToMore, userId, userAvatar, userDisplayName, ticketNumber }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const me = useSelector(state => state.auth.user);
    const [commentBoxOpen, setCommentBoxOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
      const isAdminDashboard = location.pathname.includes(ROUTE_TO_ADMIN_TICKETING_PAGE);

      const token = getLocalStorageToken();

      const unsubscribe = subscribeToMore({
        document: isAdminDashboard ? NewAdminTicketMessageSubscription : NewUserTicketMessageSubscription,
        variables: { userId: isAdminDashboard ? `${me.id}` : userId, token },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newMessageData = isAdminDashboard
            ? subscriptionData.data.userAdminTicketUpdate
            : subscriptionData.data.userTicketUpdate;
          if (ticketNumber === newMessageData.ticketNumber) {
            if (currentPage === 1) {
              const messagesToInsert = [];
              newMessageData.ticketMessages.forEach(message => {
                if (!prev.getTicketMessages.ticketMessages.some(prevMessage => prevMessage.id === message.id)) {
                  messagesToInsert.push(message);
                }
              });
              return {
                getTicketMessages: {
                  ...prev.getTicketMessages,
                  totalCount: prev.getTicketMessages.totalCount + newMessageData.countNew,
                  queryCount: prev.getTicketMessages.queryCount + newMessageData.countNew,
                  ticketMessages: [...messagesToInsert, ...prev.getTicketMessages.ticketMessages],
                },
              };
            } else {
              fetchMore({
                variables: { skip: (currentPage - 1) * 5 },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    getTicketMessages: {
                      ...fetchMoreResult.getTicketMessages,
                    },
                  };
                },
              });
            }
          }
          return prev;
        },
        onError: error => handleRequestError(error, dispatch, { silent: true }),
      });

      return () => {
        typeof unsubscribe === 'function' && unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paginate = useCallback(
      page => {
        fetchMore({
          variables: { skip: (page - 1) * 5 },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              getTicketMessages: {
                ...fetchMoreResult.getTicketMessages,
              },
            };
          },
        });
        setCurrentPage(page);
      },
      [fetchMore]
    );

    if (!data && !error) {
      return (
        <Row className="no-gutters px-0 px-md-5">
          <Col>
            <Spinner className="spinner-positioning" />
          </Col>
        </Row>
      );
    }

    const comments = get(data, 'getTicketMessages.ticketMessages', []);
    const queryCount = get(data, 'getTicketMessages.queryCount', 0);

    const pages = Math.ceil(queryCount / 5);

    return (
      <Row className="no-gutters px-0 px-md-5">
        <Col>
          {comments.length > 0 ? (
            <>
              <div className="hok-comments-spinner-container">
                {loading && <Spinner className="hok-comments-spinner" />}
                <TicketComments
                  loading={loading}
                  comments={comments.slice(0, 5)}
                  userId={userId}
                  userAvatar={userAvatar}
                  userDisplayName={userDisplayName}
                />
              </div>
              {queryCount > 5 && (
                <CustomPagination loading={loading} pages={pages} paginate={paginate} currentPage={currentPage} />
              )}
            </>
          ) : (
            <Row className="no-gutters">
              <Col className="text-center">
                <h5>
                  <FormattedMessage id="userMyTickets.noTicketMessages" />
                </h5>
              </Col>
            </Row>
          )}
          <Row className="no-gutters my-4">
            <Col>
              <Fade in={!commentBoxOpen}>
                <Row className="no-gutters">
                  <Col xs="12">
                    <CustomButton
                      className="hok-btn float-right"
                      block={false}
                      translationId="comment"
                      onClick={() => setCommentBoxOpen(true)}
                    />
                  </Col>
                </Row>
              </Fade>
              <TicketWriteComment
                isOpen={commentBoxOpen}
                close={() => setCommentBoxOpen(false)}
                ticketNumber={ticketNumber}
                paginate={paginate}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
);
