import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ArrowLinkButton, ScreenTitleContainer } from '../../../containers';
import { FormattedMessage } from 'react-intl';

const col1ListItems = [
  'Pregled specijaliste interniste + EKG s očitanjem',
  'Pregled specijaliste kardiologa.',
  'Ultrazvuk srca',
  'Holter EKG',
  'Holter tlaka',
  'Ergometrija',
  'Pregled specijaliste gastroenterologa',
  'Pregled specijaliste urologa + PSA',
  'Gastroskopija',
  'Rektosigmoidoskopija',
  'Kolonoskopija',
  'Uzimanje patohistološkog materijala [PHD]- biopsija probavnog trakta',
  'Brzi urea za test',
  'RTG Snimke kostiju',
  'RTG Specijalne snimke kostiju',
  'RTG Snimke srca/pluća',
  'UZV trbušnih organa',
  'Denzitometrija ',
  'UZV dojki',
  'UZV štitnjače + TSH',
  'Pregled specijaliste fizijatra',
  'UZV mišića i tetiva',
  'UZV zglobova',
  'UZV ramena',
  'UZV axile',
  'UZV testisa',
  'UZV mekih česti vrata',
  'Color doppler karotidnih i vertebralnih arterija [krvnih žila vrata]',
  'Color doppler arterija i vena ruku',
  'Color doppler arterija i vena nogu',
  'Mamografija',
  'Ginekološki pregled',
];

const col2ListItems = [
  'PAPA test',
  'Transvaginalni UZV / Color doppler',
  'Trudnički pregled',
  'Trudnički UZV',
  'Pregled oftalmologa [očni tlak, dioptrija oka, očna pozadina]',
  'Pregled neurologa',
  'EMNG ruku i nogu',
  'EEG',
  'Screening test ateroskleroza',
  'Spirometrija',
  'MR pretraga',
];

const labTestsListItems = [
  'Hematološke pretrage',
  'Analiza mokraće',
  'Metaboliti i supstrati',
  'Enzimi',
  'Lipidi i lipoproteini',
  'Elektroliti',
  'Pokazatelji anemije',
  'Hormon štitnjače TSH',
  'PSA prostate',
  'Bris nosa',
  'Bris ždrijela',
  'Tumorski markeri (CA 19-9, CA 15-3, CA 125, CEA)',
];

export const HealthInsuranceMedical = () => {
  return (
    <Container fluid className="policies">
      <ArrowLinkButton linkTo={null} xlColSize={10} />
      <ScreenTitleContainer
        row
        className="mt-4"
        titleId="userMyPoliciesHealthInsurance.medicalTitle"
        xlColSize="10"
        xlColOffset="1"
      />
      <Row className="mt-4 hok-health-info">
        <Col xs="12" md="6" xl={{ offset: 1, size: 5 }}>
          <ol className="mb-0 mb-md-3">
            {col1ListItems.map(item => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        </Col>
        <Col xs="12" md="6" xl={{ size: 5 }}>
          <ol className="pl-md-0" style={{ counterReset: `list ${col1ListItems.length}` }}>
            {col2ListItems.map(item => (
              <li key={item}>{item}</li>
            ))}
          </ol>

          <h5 className="mt-5 pl-4 pl-md-0">
            <FormattedMessage id="userMyPoliciesHealthInsurance.lab" />
          </h5>
          <ol className="pl-md-0" style={{ counterReset: `list ${col1ListItems.length + col2ListItems.length}` }}>
            {labTestsListItems.map(item => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        </Col>
      </Row>
    </Container>
  );
};
