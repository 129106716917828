import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import { ReactComponent as HokInfoIcon } from '../../img/icons/hok-error.svg';
import { ReactComponent as HokCloseIcon } from '../../img/icons/hok-close.svg';
import accident1 from '../../img/sketches/hok-accident1.png';
import accident2 from '../../img/sketches/hok-accident2.png';
import accident3 from '../../img/sketches/hok-accident3.png';

export const ClaimDocumentation = ({ documentation, isOpen, toggle }) => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slides = [accident1, accident2, accident3];

  return documentation.length > 0 ? (
    <>
      <Row className="mt-4">
        <Col>
          <h5 className="color-red font-weight-bold mb-3">Potrebna dokumentacija za prijavu štete:</h5>
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col className="hok-text-dark">
          <ul className="pl-4">
            {documentation.map(doc => (
              <li key={doc.documentId}>
                {doc.content}
                {doc.content === 'Skica nezgode' && (
                  <span className="color-red text-underline cursor-pointer" onClick={toggle}>
                    <HokInfoIcon className="svg-red svg-pad-bottom ml-3 mr-1" width="20px" height="20px" />
                    <FormattedMessage id="showExample" />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Modal className="hok-modal-wide hok-modal-policy" isOpen={isOpen} toggle={toggle}>
        <ModalHeader className="hok-sketch-header">
          <span>Primjeri skica nezgode</span>
          <HokCloseIcon className="cursor-pointer" onClick={toggle} />
        </ModalHeader>
        <ModalBody className="text-center pb-5">
          <Row className="mb-5">
            <Col xs={{ size: 10, offset: 1 }}>
              <Slider className="hok-sketch-slider" {...settings}>
                {slides.map((slide, index) => (
                  <div key={index}>
                    <img className="img-fluid mx-auto w-75" src={slide} alt="Skica" />
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  ) : null;
};
