import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {
  ROUTE_TO_USER_POLICIES_PAGE,
  ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
  ROUTE_TO_USER_SUPPORT_PAGE,
} from '../../../constants/routes';
import { ArrowLinkButton, DescriptionText, CollapsiblePackageInfo, CustomButton } from '../../../containers';
import { HEALTH_INSURANCE_PACKAGES, POLICY_CODE_EU } from '../../../constants/business';
import {
  setSessionStorageHealthInsurancePackage,
  setSessionStoragePolicyEligibility,
} from '../../../constants/LocalStorageKeys';
import { UserInteractionModal } from '../../../components/UserInteractionModal';
import {
  SELF_INSURANCE_CONTRACTING,
  CONTINUE_PREVIOUS_CONTRACTING,
  CONTRACTING_AGE_ELIGIBILITY,
} from '../../../constants/modalsContent';
import { PreContractualDocumentation } from '../../../components/PreContractualDocumentation';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../constants/support';
import { useServerStorageFromProfileKey } from '../../../utils/customHooks';
import { googleAnalyticsEvent } from '../../../utils/browser';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserPoliciesHealthInsurance = () => {
  const history = useHistory();
  const sector = useSelector(state => state.auth.user.sector);
  const dateOfBirth = useSelector(state => state.auth.user.dateOfBirth);

  const [isOpen, setIsOpen] = useState(false);
  const packageId = useRef(null);

  const [isOpenContinue, setIsOpenContinue] = useState(false);
  const [isAgeEligibilityOpen, setIsAgeEligibilityOpen] = useState(false);

  const { serverStorageData, stateKey } = useServerStorageFromProfileKey();

  const startContracting = useCallback(() => {
    setSessionStorageHealthInsurancePackage(packageId.current);
    setSessionStoragePolicyEligibility(POLICY_CODE_EU);
    history.push(ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE);
  }, [history]);

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const toggleContinueModal = () => {
    setIsOpenContinue(prevState => !prevState);
  };

  const toggleAgeEligibilityModal = () => {
    setIsAgeEligibilityOpen(prevState => !prevState);
  };

  return (
    <Container fluid className="policies">
      <AdFormIFrame title="Proizvodi - Zdravstveno" />
      <ArrowLinkButton xlColSize={10} linkTo={ROUTE_TO_USER_POLICIES_PAGE} />
      <Row className="mt-4">
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <h3>
            <FormattedMessage id="routes.healthInsurance" />
          </h3>
          <Row className="no-gutters mt-5 mx-3">
            <Col xs={12}>
              <p>
                Zdravstveno osiguranje pružiti će Vam pokrića sudjelovanja u troškovima zdravstvene zaštite iz obveznog
                zdravstvenog osiguranja zajedno sa dodatnim pokrićima ovisno o paketu osiguranja. unutar HOK-ove postoje
                3 [tri] paketa zdravstvenog osiguranja:
              </p>
            </Col>
          </Row>
          <Row className="m-3">
            {HEALTH_INSURANCE_PACKAGES.map(healthPackage => (
              <Col md="6" xl="4" className="mb-4 mb-xl-0" key={healthPackage.id}>
                <CollapsiblePackageInfo
                  placeholder={healthPackage.name}
                  buttonTranslationId={sector === USER_ACCOUNT_BUSINESS ? 'requestOffer' : 'new'}
                  showPrice={healthPackage.price}
                  price={healthPackage.price}
                  priceString="yearlyPremium"
                  submitFn={() => {
                    if (sector === USER_ACCOUNT_BUSINESS) {
                      googleAnalyticsEvent({
                        category: 'Proizvodi',
                        action: 'Proizvodi_Zdravstveno_Zatrazi_Ponudu',
                      });
                      history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                        subject: ENQUIRY_SUBJECT_NEW_POLICY,
                        title: 'Kupnja nove police "Zdravstveno osiguranje"',
                      });
                    } else {
                      packageId.current = healthPackage.id;
                      setIsOpen(true);
                    }
                  }}>
                  <div className="hok-package-data">
                    <DescriptionText value={healthPackage.id} />
                  </div>
                </CollapsiblePackageInfo>
              </Col>
            ))}
          </Row>
          <PreContractualDocumentation productCode={POLICY_CODE_EU} />
        </Col>
      </Row>
      <UserInteractionModal
        isOpen={isOpen}
        toggle={toggle}
        titleId="modalHeaders.warning"
        textId={SELF_INSURANCE_CONTRACTING}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="forSomeoneElse"
          onClick={() => {
            googleAnalyticsEvent({
              category: 'Proizvodi',
              action: 'Proizvodi_Zdravstveno_Zatrazi_Ponudu',
            });
            history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
              subject: ENQUIRY_SUBJECT_NEW_POLICY,
              title: 'Kupnja nove police "Zdravstveno osiguranje"',
            });
          }}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="forMyself"
          onClick={() => {
            toggle();
            const userAge = moment().diff(moment(dateOfBirth), 'years');
            if (userAge > 65 || userAge < 18) {
              toggleAgeEligibilityModal();
            } else {
              if (serverStorageData && serverStorageData.type === POLICY_CODE_EU) {
                toggleContinueModal();
              } else {
                startContracting();
              }
            }
          }}
        />
      </UserInteractionModal>
      <UserInteractionModal
        isOpen={isOpenContinue}
        toggle={toggleContinueModal}
        titleId="modalHeaders.warning"
        textId={CONTINUE_PREVIOUS_CONTRACTING}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="no"
          onClick={startContracting}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="yes"
          onClick={() => {
            toggleContinueModal();
            history.push(`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE}/${stateKey}`);
          }}
        />
      </UserInteractionModal>
      <UserInteractionModal
        isOpen={isAgeEligibilityOpen}
        toggle={toggleAgeEligibilityModal}
        titleId="modalHeaders.warning"
        textId={CONTRACTING_AGE_ELIGIBILITY}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="customerSupport"
          onClick={() => {
            setSessionStoragePolicyEligibility(POLICY_CODE_EU);
            toggleAgeEligibilityModal();
            history.push(ROUTE_TO_USER_SUPPORT_PAGE);
          }}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="giveUp"
          onClick={toggleAgeEligibilityModal}
        />
      </UserInteractionModal>
    </Container>
  );
};
