import React from 'react';
import { Container, Row, Col, InputGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { POLICY_HEALTH, POLICY_HEALTH_TRAVEL } from '../../constants/business';
import { CollapsibleTextInfo } from '../../containers';
import { USER_ACCOUNT_BUSINESS } from '../../constants/userDefs';
import { formatDate } from '../../constants/dateFormatting';

export const PolicyInsuredPeople = ({ data, policyType, personType = 'policyContractor' }) => {
  if ((policyType === POLICY_HEALTH || policyType === POLICY_HEALTH_TRAVEL) && data.length > 1) {
    return (
      <Col sm="6">
        <Label for={personType}>
          <FormattedMessage id="userMyPolicies.policyData.insuredPeople" />
        </Label>
        <InputGroup>
          <CollapsibleTextInfo pushdown uppercase placeholder="userMyPolicies.policyData.insuredList" id={personType}>
            <Container className="expanded-container">
              {data.map((person, index) => {
                const { firstName, lastName, name, sector, dateOfBirth } = person;
                const fullName = `${firstName || ''} ${lastName || ''}`.trim();
                return (
                  <React.Fragment key={index}>
                    <Row className="no-gutters">
                      <Col>
                        <p>
                          <span className="text float-left text-uppercase font-weight-500">
                            {sector === USER_ACCOUNT_BUSINESS ? `${name}` : `${fullName || name}`}
                          </span>
                          {dateOfBirth && (
                            <span className="text float-right text-uppercase">{formatDate(dateOfBirth)}</span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    {index < data.length - 1 && <hr className="" />}
                  </React.Fragment>
                );
              })}
            </Container>
          </CollapsibleTextInfo>
        </InputGroup>
      </Col>
    );
  }
  if (data[0]) {
    const {
      firstName,
      lastName,
      dateOfBirth,
      name,
      businessName,
      sector,
      oib,
      address,
      city,
      phoneNumber,
      email,
    } = data[0];
    return (
      <Col key={oib} sm="6">
        <Label for={personType}>
          <FormattedMessage id={`userMyPolicies.policyData.${personType}`} />
        </Label>
        <InputGroup>
          <CollapsibleTextInfo
            pushdown
            uppercase
            placeholder={
              sector === USER_ACCOUNT_BUSINESS
                ? `${name || businessName}`
                : `${firstName || ''} ${lastName || ''}`.trim()
            }
            rawPlaceholder
            id={personType}>
            <Container className="expanded-container">
              <Row className="no-gutters">
                <Col xs={12}>
                  <p>
                    <span className="placeholder float-left text-uppercase">
                      <FormattedMessage id={sector === USER_ACCOUNT_BUSINESS ? 'inputs.name' : 'inputs.firstName'} />:
                    </span>
                    <span className="text float-left text-uppercase">
                      {sector === USER_ACCOUNT_BUSINESS
                        ? `${name || businessName}`
                        : `${firstName || ''} ${lastName || ''}`.trim()}
                    </span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="pt-3" xs={12}>
                  <p>
                    <span className="placeholder float-left text-uppercase">
                      <FormattedMessage id="inputs.OIB" />:
                    </span>
                    <span className="text float-left">{oib}</span>
                  </p>
                </Col>
              </Row>
              {dateOfBirth && sector !== USER_ACCOUNT_BUSINESS && (
                <Row>
                  <Col className="pt-3" xs={12}>
                    <p>
                      <span className="placeholder float-left text-uppercase">
                        <FormattedMessage id="inputs.dateOfBirth" />:
                      </span>
                      <span className="text float-left">{formatDate(dateOfBirth)}</span>
                    </p>
                  </Col>
                </Row>
              )}
              {address && (
                <Row>
                  <Col className="pt-3" xs={12}>
                    <p>
                      <span className="placeholder float-left text-uppercase">
                        <FormattedMessage id="inputs.address" />:
                      </span>
                      <span className="text float-left text-uppercase">{address}</span>
                    </p>
                  </Col>
                </Row>
              )}
              {city && (
                <Row>
                  <Col className="pt-3" xs={12}>
                    <p>
                      <span className="placeholder float-left text-uppercase">
                        <FormattedMessage id="inputs.city" />:
                      </span>
                      <span className="text float-left">{city}</span>
                    </p>
                  </Col>
                </Row>
              )}
              {phoneNumber && (
                <Row>
                  <Col className="pt-3" xs={12}>
                    <p>
                      <span className="placeholder float-left text-uppercase">
                        <FormattedMessage id="inputs.telNumber" />:
                      </span>
                      <span className="text float-left">{phoneNumber}</span>
                    </p>
                  </Col>
                </Row>
              )}
              {email && (
                <Row>
                  <Col className="pt-3" xs={12}>
                    <p>
                      <span className="placeholder float-left text-uppercase">
                        <FormattedMessage id="inputs.email" />:
                      </span>
                      <span className="text float-left">{email}</span>
                    </p>
                  </Col>
                </Row>
              )}
            </Container>
          </CollapsibleTextInfo>
        </InputGroup>
      </Col>
    );
  }
  return null;
};
