import React, { Component } from 'react';
import { FormGroup, Row, Col, Form, Alert, CustomInput, FormFeedback } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import PhoneInput from 'react-phone-number-input';
import { LoginAndRegisterSelector, CustomInputField, CustomButton } from '../../../containers';
import { validate } from '../../../utils/validation';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { ROUTE_TO_USER_LOGIN_PAGE, ROUTE_TO_USER_REGISTER_PAGE } from '../../../constants/routes';
import { userRegistration, clearUserErrorMessage } from '../../../redux/actions/userAuthActions';
import logoShort from '../../../img/logo-short.png';
import { RegisterTacModal } from './RegisterTacModal';
import { ReactComponent as HokPasswordIcon } from '../../../img/icons/hok-password.svg';
import { ReactComponent as HokEmailIcon } from '../../../img/icons/hok-email.svg';
import { ReactComponent as HokMobileIcon } from '../../../img/icons/hok-mobile.svg';
import { Query } from 'react-apollo';
import { GetLegalDocumentsQuery } from '../../../operations/queries/GetLegalDocumentsQuery';
import { UVJETI_KORISTENJA } from '../../../constants/documentTypes';
import { handleRequestError } from '../../../constants/errorCodes';
import { AdFormIFrame } from '../../../components/AdFormIframe';

const mapStateToProps = state => ({
  intl: state.intl,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  userRegistration: newUserData => dispatch(userRegistration(newUserData)),
  clearUserErrorMessage: payload => dispatch(clearUserErrorMessage(payload)),
});

class Register extends Component {
  state = {
    isFormValid: false,
    submitted: false,
    isTacModalOpen: false,
    formData: {
      email: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.email" />,
        validation: {
          required: true,
          email: true,
        },
      },
      password: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.password" />,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatPassword: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.repeatPassword" />,
        validation: {
          required: true,
          matches: '',
        },
      },
      phoneNumber: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.phoneNumber" />,
        validation: {
          required: true,
          phoneNumber: true,
        },
      },
      tac: {
        value: false,
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.tac" />,
        validation: {
          isTrue: true,
        },
      },
    },
  };

  componentWillUnmount() {
    this.clearErrorOnValueChange();
  }

  removeFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: false } },
    });
  };

  addFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: true } },
    });
  };

  toggleTacModal = () => {
    this.setState({ ...this.state, isTacModalOpen: !this.state.isTacModalOpen });
  };

  inputHandler = (field, value) => {
    this.clearErrorOnValueChange();
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: {
          ...this.state.formData[field],
          value: value,
        },
        ...(field === 'password' && {
          repeatPassword: {
            ...this.state.formData.repeatPassword,
            validation: {
              ...this.state.formData.repeatPassword.validation,
              matches: value,
            },
          },
        }),
      },
    });
  };

  registerNewUser = event => {
    event.preventDefault();
    let formDataObject = { ...this.state.formData };
    let isFormValid = true;
    /* eslint-disable no-unused-vars */
    for (let field in formDataObject) {
      const validation = validate(formDataObject[field]);
      formDataObject[field] = {
        ...formDataObject[field],
        value:
          typeof formDataObject[field] === 'string' ? formDataObject[field].value.trim() : formDataObject[field].value,
        invalid: validation.invalid,
        error: validation.errors[0],
      };
      isFormValid = isFormValid && !validation.invalid;
    }
    /* eslint-enable no-unused-vars */

    this.setState({ isFormValid, submitted: true, formData: formDataObject }, () => {
      if (isFormValid) {
        const payloadObject = {};
        /* eslint-disable no-unused-vars */
        for (let inputObject in this.state.formData) {
          if (inputObject === 'repeatPassword' || inputObject === 'tac') continue;
          if (this.state.formData[inputObject].value) {
            payloadObject[inputObject] = this.state.formData[inputObject].value;
          }
        }
        /* eslint-enable no-unused-vars */

        // Register action
        this.props.userRegistration(payloadObject);
        this.setState({ submitted: false });
      }
    });
  };

  clearErrorOnValueChange = () => {
    if (get(this.props, 'auth.register.errorMessage', null)) {
      this.props.clearUserErrorMessage('register');
    }
  };

  render() {
    const navlinksArray = [
      { path: ROUTE_TO_USER_LOGIN_PAGE, translationId: 'login', exact: true },
      { path: ROUTE_TO_USER_REGISTER_PAGE, translationId: 'register', exact: true },
    ];

    return (
      <LoginAndRegisterSelector navLinks={navlinksArray} showContactSupportLink={true}>
        <AdFormIFrame title="Registration" />
        <Row className="no-gutters px-4 my-5 my-md-0">
          <Col xs={12} sm={{ offset: 2, size: 8 }} md={{ offset: 1, size: 10 }} lg={{ offset: 2, size: 8 }}>
            <Row className="mb-4 mb-md-0 mb-lg-4">
              <Col className="text-center">
                <img className="img-fluid logo-short" src={logoShort} alt="HOK logo" />
              </Col>
            </Row>
            {get(this.props, 'auth.register.errorMessage', null) && (
              <Alert color="danger" role="alert">
                {this.props.auth.register.errorMessage}
              </Alert>
            )}
            <Form onSubmit={this.registerNewUser}>
              <FormGroup row>
                <Col xs="12">
                  <CustomInputField
                    id="email"
                    name="email"
                    fieldState={this.state.formData.email}
                    Icon={HokEmailIcon}
                    formSubmitted={this.state.submitted}
                    type="text"
                    maxLength={255}
                    onChange={event => this.inputHandler('email', event.target.value)}
                    onFocus={() => this.addFocus('email')}
                    onBlur={() => this.removeFocus('email')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs="12">
                  <CustomInputField
                    id="password"
                    name="password"
                    fieldState={this.state.formData.password}
                    Icon={HokPasswordIcon}
                    formSubmitted={this.state.submitted}
                    type="password"
                    maxLength={55}
                    onChange={event => this.inputHandler('password', event.target.value)}
                    onFocus={() => this.addFocus('password')}
                    onBlur={() => this.removeFocus('password')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs="12">
                  <CustomInputField
                    id="repeatPassword"
                    name="repeat password"
                    fieldState={this.state.formData.repeatPassword}
                    Icon={HokPasswordIcon}
                    formSubmitted={this.state.submitted}
                    type="password"
                    maxLength={55}
                    onChange={event => this.inputHandler('repeatPassword', event.target.value)}
                    onFocus={() => this.addFocus('repeatPassword')}
                    onBlur={() => this.removeFocus('repeatPassword')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs="12">
                  <CustomInputField
                    id="phoneNumber"
                    name="mobile phone number"
                    fieldState={this.state.formData.phoneNumber}
                    Icon={HokMobileIcon}
                    formSubmitted={this.state.submitted}
                    tag={PhoneInput}
                    numberInputProps={
                      'form-control' +
                      (this.state.submitted && this.state.formData.phoneNumber.invalid ? ' is-invalid' : '')
                    }
                    type="tel"
                    // If lenght is greater than 21 formatPhoneNumberIntl() function from
                    // "react-phone-number-input" goes wild and resets the value to an empty string
                    maxLength={21}
                    defaultCountry="HR"
                    addInternationalOption={false}
                    onChange={phone => this.inputHandler('phoneNumber', formatPhoneNumber(phone))}
                    onFocus={() => this.addFocus('phoneNumber')}
                    onBlur={() => this.removeFocus('phoneNumber')}
                  />
                </Col>
              </FormGroup>
              <Query
                query={GetLegalDocumentsQuery}
                variables={{ filter: { documentId: UVJETI_KORISTENJA, active: true } }}
                onError={error => handleRequestError(error, this.props.dispatch, { silent: true })}>
                {({ data }) => {
                  const legalDocuments = get(data, 'getLegalDocuments.documents', []);

                  return (
                    <FormGroup row className="mt-4">
                      <RegisterTacModal
                        isOpen={this.state.isTacModalOpen && !!get(legalDocuments, '[0].content', false)}
                        toggle={this.toggleTacModal}
                        setTacAccepted={() => {
                          this.toggleTacModal();
                          this.inputHandler('tac', true);
                        }}
                        content={legalDocuments[0] ? legalDocuments[0].content : null}
                      />
                      <Col className="d-flex flex-row" xs="12">
                        <CustomInput
                          className="hok-tac-checkbox"
                          id="tac"
                          type="checkbox"
                          checked={this.state.formData.tac.value}
                          invalid={this.state.submitted && this.state.formData.tac.invalid}
                          onChange={event => this.inputHandler('tac', event.target.checked)}>
                          <span className="accept-text-register">
                            Prihvaćam
                            <span className="terms-and-conditions-text-register ml-1" onClick={this.toggleTacModal}>
                              uvjete korištenja
                            </span>
                          </span>
                        </CustomInput>
                      </Col>
                      {this.state.submitted && this.state.formData.tac.invalid && (
                        <Col>
                          <FormFeedback className="d-block">
                            <FormattedMessage id="errors.invalidTac" />
                          </FormFeedback>
                        </Col>
                      )}
                    </FormGroup>
                  );
                }}
              </Query>
              <FormGroup row className="mt-5">
                <Col xs="12">
                  <CustomButton
                    loaderProp={get(this.props, 'auth.register.inProgress', false)}
                    translationId="register"
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </LoginAndRegisterSelector>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
