import gql from 'graphql-tag';

export const GetUserTicketsQuery = gql`
  query GetUserTickets(
    $filter: getTicketsFilterInput
    $order: getTicketsSortFilterInput
    $skip: Int
    $take: Int
    $userId: String
    $anonymous: Boolean
  ) {
    getUserTickets(filter: $filter, order: $order, skip: $skip, take: $take, userId: $userId, anonymous: $anonymous) {
      totalCount
      queryCount
      tickets {
        id
        number
        type
        status
        actionStatus
        subject
      }
    }
  }
`;

export const GetSingleUserTicketQuery = gql`
  query GetSingleUserTicket($number: String) {
    getSingleTicket: getUserTickets(filter: { number: $number }, take: 1) {
      tickets {
        id
        number
        type
        typeUserFriendly
        status
        actionStatus
        email
        phoneNumber
        userId
        user {
          avatar
        }
        firstName
        lastName
        businessName
        subject
        content
        attachments
        startedAt
        closedAt
        createdAt
        updatedAt
      }
    }
  }
`;
