import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import ReactResizeDetector from 'react-resize-detector';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { PolicyCard, Spinner } from '../../containers';
import { ROUTE_TO_USER_REPORT_DAMAGE } from '../../constants/routes';
import { GetUserClaimsGroupsQuery } from '../../operations/queries/GetUserClaimsDefinitionsQuery';
import { claimGroupDefs } from '../../constants/claimDefs';
import { handleRequestError } from '../../constants/errorCodes';

export const ClaimsCardList = ({ smOnly = false, resizableContainer = false, children = null }) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetUserClaimsGroupsQuery, {
    variables: { order: { groupOrder: 'ASC' } },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const claimGroups = get(data, 'getClaimsDefinition.claimsGroupedByClaimsGroup', []);

  if ((!data && !error) || loading) {
    return <Spinner className="mx-auto" />;
  }

  const renderCards = () => {
    if (claimGroups.length > 0) {
      return (
        <>
          {claimGroups.map(claimGroup => {
            const { type, countActive } = claimGroup;
            const groupDef = claimGroupDefs[type];
            return (
              <PolicyCard
                key={groupDef.cardName}
                text={groupDef.cardName}
                Pic={groupDef.icon}
                counter={countActive}
                cardName={groupDef.cardName}
                route={`${ROUTE_TO_USER_REPORT_DAMAGE}/${groupDef.route}`}
                smOnly={smOnly}
                translationBase="userReportDamagePage"
              />
            );
          })}
        </>
      );
    }
    return children;
  };

  const renderInResizableContainer = () => (
    <ReactResizeDetector handleWidth>
      {({ width }) => (
        <Col className="text-left">
          <div
            className="mx-auto"
            style={
              claimGroups.length > 0
                ? {
                    width:
                      claimGroups.length * 160 > width
                        ? `${Math.min(Math.floor(width / 160), Math.ceil(claimGroups.length / 2)) * 160}px`
                        : '100%',
                  }
                : {}
            }>
            {renderCards()}
          </div>
        </Col>
      )}
    </ReactResizeDetector>
  );

  return (
    <>
      <Row className="no-gutters">
        <Col>
          <p className="hok-text-dark mb-0">
            <FormattedMessage id="userReportDamagePage.paragraph1" />
          </p>
        </Col>
      </Row>
      <Row className="no-gutters mt-3">{resizableContainer ? renderInResizableContainer() : renderCards()}</Row>
    </>
  );
};
