import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { AppSidebarToggler } from '@coreui/react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import {
  ROUTE_TO_USER_LOGOUT_PAGE,
  ROUTE_TO_USER_PROFILE_PAGE,
  ROUTE_TO_USER_DASHBOARD_PAGE,
} from '../../constants/routes';
import logoHeader from '../../img/logo-header.png';
import { ReactComponent as LogoMini } from '../../img/logo-mini.svg';
import { Notifications } from '../../components/Notifications';
import { ReactComponent as HokProfileIcon } from '../../img/icons/hok-profile.svg';
import { ReactComponent as HokLogoutIcon } from '../../img/icons/hok-logout.svg';
import { ReactComponent as DefaultPartnerAvatar } from '../../img/hok-partner-avatar.svg';

const UserHeader = () => {
  const user = useSelector(store => store.auth.user);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropDownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dropdownMenuItems = [
    {
      type: 'item',
      icon: <HokProfileIcon className="svg-header-menu" />,
      name: <FormattedMessage id="routes.myProfile" />,
      route: ROUTE_TO_USER_PROFILE_PAGE,
    },
    {
      type: 'divider',
    },
    {
      type: 'item',
      icon: <HokLogoutIcon className="svg-header-menu" />,
      name: <FormattedMessage id="routes.logout" />,
      route: ROUTE_TO_USER_LOGOUT_PAGE,
    },
  ];

  const businessName = get(user, 'businessName', '');
  const firstName = get(user, 'firstName', '');
  const lastName = get(user, 'lastName', '');
  const displayFullName = firstName && lastName ? firstName + ' ' + lastName : <FormattedMessage id="header.user" />;
  const avatar = get(user, 'avatar', null);

  const renderDropdownMenuItems = () => {
    return (
      <DropdownMenu className="hok-profile-dropdown-menu" right>
        {dropdownMenuItems.map((item, index) =>
          item.type === 'item' ? (
            <DropdownItem key={index} tag={Link} to={item.route}>
              {item.icon} {item.name}
            </DropdownItem>
          ) : (
            <DropdownItem key={index} divider />
          )
        )}
      </DropdownMenu>
    );
  };

  return (
    <>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <div className="hok-brand-center navbar-brand user-navbar-brand d-none d-sm-inline-flex">
        <Link to={ROUTE_TO_USER_DASHBOARD_PAGE}>
          <img src={logoHeader} width="100%" height="auto" alt="Logo" />
        </Link>
      </div>

      <div className="hok-brand-mini navbar-brand d-inline-flex d-sm-none">
        <Link to={ROUTE_TO_USER_DASHBOARD_PAGE}>
          <LogoMini width="35" height="35" />
        </Link>
      </div>

      <div className="navbar-nav ml-lg-auto">
        <Dropdown
          className="nav-item hok-profile-dropdown"
          direction="down"
          isOpen={dropdownOpen}
          toggle={dropDownToggle}
        >
          <DropdownToggle nav className="hok-dropdown-toggle">
            <span className="hok-dropdown-toggle-name d-none d-md-block mr-3">{businessName || displayFullName}</span>
            {avatar ? (
              <div style={{ backgroundImage: `url("${avatar}")` }} src={avatar} className="hok-img-avatar" />
            ) : (
              <div>
                <DefaultPartnerAvatar className="hok-img-avatar" />
              </div>
            )}
          </DropdownToggle>
          {renderDropdownMenuItems()}
        </Dropdown>
        <Notifications />
      </div>
    </>
  );
};

export default UserHeader;
