import { get } from 'lodash-es';
import moment from 'moment';
import React, { useCallback, useState, useRef, useMemo } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardGroup, Col, CustomInput, Input, InputGroup, Label, Row } from 'reactstrap';
import { formatDateTime, FORMAT_STANDARD_DATE } from '../../constants/dateFormatting';
import { handleRequestError } from '../../constants/errorCodes';
import { isHOKEmployee } from '../../constants/roles';
import { ROUTE_TO_ADMIN_TICKETING_PAGE } from '../../constants/routes';
import { TICKET_STATUS } from '../../constants/ticketing';
import { renderToaster, SUCCESS_TOASTER } from '../../constants/toaster';
import { CustomButton, CustomDashboardInput } from '../../containers';
import {
  AdminTicketDateNoteMutation,
  AdminTicketStatusMutation,
} from '../../operations/mutations/AdminUpdateTicketMutation';
import { GetTicketMessagesQuery } from '../../operations/queries/GetTicketMessages';
import { TicketCommentsBox } from './TicketCommentsBox';

const hoursArray = [];
const minutesArray = [];
for (let i = 0; i <= 24; i++) {
  hoursArray.push(i);
}
for (let i = 0; i < 60; i += 15) {
  minutesArray.push(i);
}

const selectStyle = {
  width: 50,
  backgroundColor: 'white',
  marginLeft: 10,
  border: '1px solid #cbccce',
  borderRadius: '0.25rem',
};

export const TicketInfo = ({
  ticket,
  dispatch,
  yearList = [
    moment().get('year'),
    moment()
      .add(1, 'y')
      .get('year'),
  ],
}) => {
  const user = useSelector(state => state.auth.user);
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [dueDateFocused, setDueDateFocused] = useState(false);

  const {
    id,
    number,
    firstName,
    lastName,
    businessName,
    userId,
    user: ticketUser,
    typeUserFriendly,
    content,
    dueDate: ticketDueDate,
    note: ticketNote,
  } = ticket;
  const displayName = businessName || `${firstName || ''} ${lastName || ''}`.trim();
  const userAvatar = get(ticket, 'user.avatar', null);

  const [status, setStatus] = useState({
    value: ticket.status || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.ticketStatus" />,
    validation: {
      required: true,
      enum: [...TICKET_STATUS, null],
    },
  });

  const [notSilent, setNotSilent] = useState(true);
  const [dueDate, setDueDate] = useState(ticketDueDate ? moment(ticketDueDate) : ticketDueDate);
  const [note, setNote] = useState(ticketNote || '');
  const dueDateRef = useRef(ticketDueDate ? moment(ticketDueDate) : ticketDueDate);
  const noteRef = useRef(ticketNote || '');

  const [updateTicket, { loading }] = useMutation(AdminTicketStatusMutation, {
    onError: error => handleRequestError(error, dispatch),
    onCompleted: () => {
      renderToaster(`successMessages.ticketStatusUpdated`, SUCCESS_TOASTER, { intlKey: true });
    },
  });

  const [updateTicketAdmin, { loading: loadingAdmin }] = useMutation(AdminTicketDateNoteMutation, {
    onError: error => handleRequestError(error, dispatch),
    onCompleted: () => {
      renderToaster(`successMessages.ticketDueDateUpdated`, SUCCESS_TOASTER, { intlKey: true });
    },
  });

  const { data, loading: loadingMessages, error, fetchMore, subscribeToMore } = useQuery(GetTicketMessagesQuery, {
    variables: { ticketNumber: number, order: { createdAt: 'DESC' }, take: 5 },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    notifyOnNetworkStatusChange: true,
  });

  const updateStatus = useCallback(() => {
    updateTicket({
      variables: {
        id,
        status: status.value,
        silent: !notSilent,
      },
    });
  }, [updateTicket, id, status.value, notSilent]);

  const updateDueAndNote = useCallback(() => {
    updateTicketAdmin({
      variables: {
        id,
        ...(dueDateRef.current !== dueDate ? { dueDate } : null),
        ...(noteRef.current !== note ? { note } : null),
      },
    });
    dueDateRef.current = dueDate;
    noteRef.current = note;
  }, [updateTicketAdmin, id, dueDate, note]);

  const isUserEmployee = isHOKEmployee(user);
  const isAdminDashboard = location.pathname.includes(ROUTE_TO_ADMIN_TICKETING_PAGE);

  const routeToUser = useMemo(
    () =>
      ticketUser
        ? get(ticketUser, 'role')
          ? `admin/employees/edit/${get(ticketUser, 'id')}`
          : `admin/users/edit/${get(ticketUser, 'id')}`
        : null,
    [ticketUser]
  );

  const redirectToUser = useCallback(() => window.open(`${window.location.origin}/${routeToUser}`, '_blank'), [
    routeToUser,
  ]);

  return (
    <CardGroup>
      <Card className="hok-card">
        <CardBody>
          <Row className="mt-4">
            <Col xs="12" sm="6">
              <Label for="ticketId">
                <FormattedMessage id="inputs.ticketId" />
              </Label>
              <InputGroup>
                <Input className="input-uppercase" id="ticketId" readOnly defaultValue={number} />
              </InputGroup>
            </Col>
            <Col xs="12" sm="6">
              <Label for="ticketUser">
                <FormattedMessage id="inputs.user" />
              </Label>
              <InputGroup>
                <Input
                  className="input-uppercase"
                  id="ticketUser"
                  style={{ cursor: 'pointer' }}
                  readOnly
                  defaultValue={displayName}
                />
                {routeToUser ? (
                  <CustomButton
                    className="hok-dashboard-btn d-none d-xl-inline-block ml-0 ml-sm-3"
                    block={false}
                    translationId="openUser"
                    onClick={redirectToUser}
                  />
                ) : null}
              </InputGroup>
              {routeToUser ? (
                <CustomButton
                  className="hok-dashboard-btn d-block w-xs-100 d-sm-inline-block d-xl-none mb-2 mb-sm-3"
                  block={false}
                  translationId="openUser"
                  disabled={!user.id}
                  onClick={redirectToUser}
                />
              ) : null}
            </Col>
          </Row>
          <Row className="mb-3 mb-sm-0">
            <Col xs="12" sm="6">
              <Label for="ticketType">
                <FormattedMessage id="inputs.ticketType" />
              </Label>
              <InputGroup>
                <Input className="input-uppercase" id="ticketType" readOnly defaultValue={typeUserFriendly} />
              </InputGroup>
            </Col>
            <Col xs="12" sm="6">
              <Label for="ticketCreatedAt">
                <FormattedMessage id="inputs.ticketCreationTimestamp" />
              </Label>
              <InputGroup>
                <Input
                  className="input-uppercase"
                  id="ticketCreatedAt"
                  readOnly
                  defaultValue={formatDateTime(ticket.createdAt, false)}
                />
              </InputGroup>
            </Col>
            <Col xs="12" sm="6">
              <Label for="status">
                <FormattedMessage id="inputs.ticketStatus" />
              </Label>
              <InputGroup>
                {isUserEmployee && isAdminDashboard ? (
                  <div className="hok-select">
                    <CustomDashboardInput
                      id="enquiryType"
                      type="select"
                      fieldState={status}
                      onChange={event => {
                        const value = event.target.value === 'null' ? null : event.target.value;
                        setStatus({ ...status, invalid: false, value: value });
                      }}>
                      {TICKET_STATUS.map(status => (
                        <option key={status} value={status}>
                          {formatMessage({ id: `ticketStatus.${status}` })}
                        </option>
                      ))}
                    </CustomDashboardInput>
                  </div>
                ) : (
                  <Input
                    className="input-uppercase"
                    id="ticketCreatedAt"
                    readOnly
                    defaultValue={formatMessage({ id: `ticketStatus.${ticket.status}` })}
                  />
                )}
              </InputGroup>
            </Col>
            {isUserEmployee && isAdminDashboard && (
              <Col xs="12" sm="6" className="d-flex align-items-end">
                <CustomButton
                  className="hok-button hok-dashboard-btn mt-3 mt-sm-0 mb-3"
                  block={false}
                  translationId="saveChange"
                  onClick={updateStatus}
                  loaderProp={loading}
                />
              </Col>
            )}
          </Row>
          {isUserEmployee && isAdminDashboard && (
            <Row className="">
              <Col className="d-flex flex-row" xs="12">
                <InputGroup>
                  <CustomInput
                    className="hok-tac-checkbox"
                    id="infoAccuracyAcceptance"
                    type="checkbox"
                    checked={notSilent}
                    onChange={event => setNotSilent(event.target.checked)}
                    label={
                      <span className="font-weight-bold text-dark text-left">Pošalji izmjenu statusa korisniku</span>
                    }
                  />
                </InputGroup>
              </Col>
            </Row>
          )}
          <Row className="">
            <Col xs="12" xl="6">
              <Label>
                <FormattedMessage id="inputs.enquiry" />
              </Label>
              <InputGroup>
                <Input className="hok-enquiry-textarea" type="textarea" readOnly defaultValue={content} />
              </InputGroup>
            </Col>
          </Row>
          {isUserEmployee && isAdminDashboard && (
            <>
              <Row className="">
                <Col xs="12" xl="6">
                  <Label>
                    <FormattedMessage id="inputs.note" />
                  </Label>
                  <InputGroup>
                    <CustomDashboardInput
                      id="enquiry"
                      className="hok-enquiry-textarea"
                      type="textarea"
                      placeholder
                      placeholderId="noteDescription"
                      fieldState={note ? { value: note } : ''}
                      onChange={event => setNote(event.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="">
                <Col xs="12" xl="6">
                  <Label>
                    <FormattedMessage id="inputs.dueDate" />
                  </Label>
                  <InputGroup>
                    <SingleDatePicker
                      id="dueDate"
                      readOnly
                      hideKeyboardShortcutsPanel
                      date={dueDate}
                      onDateChange={date => setDueDate(date)}
                      focused={dueDateFocused}
                      onFocusChange={({ focused }) => setDueDateFocused(focused)}
                      showDefaultInputIcon
                      firstDayOfWeek={1}
                      numberOfMonths={1}
                      small
                      displayFormat={FORMAT_STANDARD_DATE}
                      placeholder={formatMessage({ id: 'inputs.dueDate' })}
                      initialVisibleMonth={() => dueDate || moment()}
                      renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                        <div className="d-flex justify-content-center">
                          <div className="mr-1">
                            <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                              {moment.months().map((label, value) => (
                                <option value={value} key={label}>
                                  {label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="ml-1">
                            <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                              {yearList.map(year => (
                                <option value={year} key={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                      <div>
                        <span>Sati</span>
                        <select
                          style={selectStyle}
                          value={dueDate ? moment(dueDate).format('HH') : ''}
                          onChange={event => {
                            setDueDate(moment(dueDate).set('hours', event.target.value));
                          }}>
                          {hoursArray.map(h => (
                            <option key={'hours' + h} value={h}>
                              {String(h).length === 1 ? '0' + h : h}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <span>Minute</span>
                        <select
                          style={selectStyle}
                          value={dueDate ? moment(dueDate).format('mm') : ''}
                          onChange={event => {
                            setDueDate(moment(dueDate).set('minutes', event.target.value));
                          }}>
                          {minutesArray.map(m => (
                            <option key={'minutes' + m} value={m}>
                              {String(m).length === 1 ? '0' + m : m}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </InputGroup>
                </Col>

                <Col xs="12" sm="6" className="d-flex align-items-end">
                  <CustomButton
                    className="hok-button hok-dashboard-btn mt-3 mt-sm-0 mb-3"
                    block={false}
                    disabled={
                      moment(dueDate).format('DD.MM.YYYY HH:mm') ===
                        moment(dueDateRef.current).format('DD.MM.YYYY HH:mm') && note === noteRef.current
                    }
                    translationId="saveChange"
                    onClick={updateDueAndNote}
                    loaderProp={loadingAdmin}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row className="no-gutters mt-5 mb-4">
            <Col xs="12">
              <h3>
                <FormattedMessage id="userMyTickets.ticketComments" />
              </h3>
            </Col>
          </Row>
          <TicketCommentsBox
            data={data}
            userId={userId}
            userAvatar={userAvatar}
            userDisplayName={displayName}
            loading={loadingMessages}
            error={error}
            fetchMore={fetchMore}
            subscribeToMore={subscribeToMore}
            ticketNumber={number}
          />
        </CardBody>
      </Card>
    </CardGroup>
  );
};
