import React, { useState, useLayoutEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Input } from 'reactstrap';
import { useIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { get } from 'lodash-es';
import moment from 'moment';
import ProductContainer from '../../../containers/ProductContainer';
import PolicyForm from '../../../components/PolicyForm';
import { ScreenTitleContainer } from '../../../containers/ScreenTitleContainer';
import { formatDate } from '../../../constants/dateFormatting';
import { policiesGroup } from '../../../utils/policyGroupFromPath';
import { useQuery } from 'react-apollo';
import { GetUserPolicyGroupsQuery } from '../../../operations/queries/GetUserPoliciesQuery';
import { handleRequestError } from '../../../constants/errorCodes';
import { Spinner, ArrowLinkButton, CustomButton } from '../../../containers';
import {
  ROUTE_TO_USER_MY_POLICIES_PAGE,
  ROUTE_TO_USER_SUPPORT_PAGE,
  // ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE,
  ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE,
  ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE,
  ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
  ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE,
  ROUTE_TO_USER_POLICIES_KASKO_INSURANCE_PAGE,
} from '../../../constants/routes';
import {
  POLICY_STATUS_EXPIRED,
  POLICY_STATUS_ACTIVE,
  POLICY_STATUS_RUNNING_OUT,
  MY_POLICIES_TRAVEL_INSURANCE_PAGE,
  POLICY_CAR_LIABILITY,
  POLICY_KASKO,
  POLICY_PROPERTY,
  // POLICY_HEALTH,
  POLICY_HEALTH_TRAVEL,
  POLICY_ASSISTANCE,
  POLICY_ATTORNEY,
  POLICY_ACCIDENT,
  POLICY_VESSELS,
  POLICY_TRANSPORT,
  POLICY_LIABILITY,
  POLICY_DRIVER_LIABILITY,
  POLICY_OTHER,
} from '../../../constants/business';
import { GetUserClaimsDefinitionsQuery } from '../../../operations/queries/GetUserClaimsDefinitionsQuery';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../constants/support';

const policyList = {
  [POLICY_CAR_LIABILITY]: { route: ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE },
  [POLICY_KASKO]: { route: ROUTE_TO_USER_POLICIES_KASKO_INSURANCE_PAGE },
  [POLICY_PROPERTY]: { route: ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE },
  // [POLICY_HEALTH]: { route: ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE },
  [POLICY_HEALTH_TRAVEL]: { route: ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE },
  [POLICY_ASSISTANCE]: { route: ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE },
  [POLICY_ATTORNEY]: {
    route: {
      pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Odvjetnička odgovornost"' },
    },
  },
  [POLICY_ACCIDENT]: {
    route: {
      pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Osiguranje nezgode"' },
    },
  },
  [POLICY_VESSELS]: {
    route: {
      pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Osiguranja plovila"' },
    },
  },
  [POLICY_TRANSPORT]: {
    route: {
      pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Transportno osiguranje"' },
    },
  },
  [POLICY_LIABILITY]: {
    route: {
      pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Osiguranje od odgovornosti"' },
    },
  },
  [POLICY_DRIVER_LIABILITY]: {
    route: {
      pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Osiguranje vozara"' },
    },
  },
  [POLICY_OTHER]: {
    route: {
      pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Ostala osiguranja"' },
    },
  },
};

export const GenericMyPolicies = () => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const scrollToElement = useRef(true);
  const { formatMessage } = useIntl();
  const [groupName, pageName] = policiesGroup(params.insuranceType);
  const policyNumber = get(params, 'policyNumber', null);
  const [filterValue, setFilterValue] = useState(policyNumber ? '' : POLICY_STATUS_ACTIVE);
  const { data, loading, error } = useQuery(GetUserPolicyGroupsQuery, {
    variables: { order: { date: 'DESC', number: 'ASC' } },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const { data: claimTypesData } = useQuery(GetUserClaimsDefinitionsQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const claimType = get(claimTypesData, 'getClaimsDefinition.claims', []).find(claimGroup => {
    return claimGroup.group === groupName;
  });

  const policiesInGroup = get(data, 'getInsurancePolicy.insurancePoliciesGroupedByProductGroup', []).find(
    group => group.type === groupName
  );

  let policies = get(policiesInGroup, 'insurancePolicies', []).sort((a, b) =>
    moment(a.insuranceEnd).isBefore(moment(b.insuranceEnd))
      ? 1
      : moment(a.insuranceEnd).isSame(moment(b.insuranceEnd))
      ? a.number < b.number
        ? 1
        : -1
      : -1
  );

  if (
    policyNumber &&
    policiesInGroup &&
    !policiesInGroup.insurancePolicies.some(policy => policy.number === policyNumber)
  ) {
    history.push(`${ROUTE_TO_USER_MY_POLICIES_PAGE}/${params.insuranceType}`);
  }

  if (filterValue) {
    policies = get(policiesInGroup, 'insurancePolicies', []).filter(
      policy => policy.status === filterValue.toLowerCase()
    );
    if (filterValue === POLICY_STATUS_EXPIRED) {
      policies.sort((a, b) => (moment(a.insuranceEnd).isBefore(moment(b.insuranceEnd)) ? 1 : -1));
    } else {
      policies.sort((a, b) => (moment(a.insuranceEnd).isBefore(moment(b.insuranceEnd)) ? -1 : 1));
    }
  }

  useLayoutEffect(() => {
    if (policyNumber && policies && scrollToElement.current) {
      const element = document.querySelector(`[data-policy-number="${policyNumber}"]`);
      const position = element && element.getBoundingClientRect();
      position && window.scrollTo(0, position.top + window.scrollY - 55 - 20);
    }
  }, [policyNumber, policies, scrollToElement]);

  const activeStatus = (insuranceEnd, insuranceType) => {
    const timeToExpiration = Math.ceil(
      moment(insuranceEnd)
        .endOf('day')
        .diff(moment(), 'days', true)
    );

    if (timeToExpiration < 0) return POLICY_STATUS_EXPIRED;
    if (timeToExpiration <= 1 || (timeToExpiration <= 30 && insuranceType !== MY_POLICIES_TRAVEL_INSURANCE_PAGE))
      return POLICY_STATUS_RUNNING_OUT;
    return POLICY_STATUS_ACTIVE;
  };

  const renderFeedbackMessage = () => {
    /* eslint-disable no-alert, no-console, no-unused-vars */
    const policyGroupRoute = policyList[groupName] ? policyList[groupName].route : policyList[POLICY_OTHER].route;
    /* eslint-enable no-alert, no-unused-vars */
    switch (filterValue) {
      case POLICY_STATUS_ACTIVE:
        return (
          <>
            <p className="hok-text-dark pr-1">
              <FormattedMessage id="userMyPolicies.noActivePoliciesFound" />
            </p>
            {/* <Row className="no-gutters mt-4">
              <Col>
                <CustomButton
                  className="hok-dashboard-btn-wide"
                  translationId="newPolicy"
                  onClick={() => history.push(ROUTE_TO_USER_POLICIES_PAGE)}
                />
              </Col>
            </Row> */}
          </>
        );
      case POLICY_STATUS_EXPIRED:
        return (
          <>
            <p className="hok-text-dark pr-1">
              <FormattedMessage id="userMyPolicies.noExpiredPoliciesFound" />
            </p>
            {/* <Row className="no-gutters mt-4">
              <Col>
                <CustomButton
                  className="hok-dashboard-btn-wide"
                  translationId="newPolicy"
                  onClick={() => history.push(ROUTE_TO_USER_POLICIES_PAGE)}
                />
              </Col>
            </Row> */}
          </>
        );
      case '':
      default:
        return (
          <>
            <p className="hok-text-dark pr-1">
              <FormattedHTMLMessage id="userMyPolicies.noPoliciesContactSupportError" />
            </p>
            <Row className="no-gutters mt-4">
              <Col>
                <CustomButton
                  className="hok-dashboard-btn-wide"
                  block={false}
                  translationId="customerSupport"
                  onClick={() => history.push(ROUTE_TO_USER_SUPPORT_PAGE)}
                />
              </Col>
            </Row>
          </>
        );
    }
  };

  return (
    <Container className="hok-container" fluid>
      <Row className="no-gutters mt-3">
        <Col>
          <ArrowLinkButton className="mb-4" linkTo={ROUTE_TO_USER_MY_POLICIES_PAGE} xlColSize={12} maxWidthSet />
          <Row className="no-gutters mb-5">
            <Col>
              <div className="mx-auto title-text w-100 d-flex align-items-center flex-wrap">
                <ScreenTitleContainer
                  titleId={`${pageName}.title`}
                  childClassName="hok-inline-title title-text"
                  maxWidthSet
                />
                {(filterValue || (!filterValue && policies.length > 0)) && (
                  <div className="hok-inline-title-filter ml-0 ml-sm-auto mt-3 mt-sm-0">
                    <Input
                      className="hok-inline-title-filter-select"
                      type="select"
                      id="invoiceFilter"
                      value={filterValue}
                      onChange={(event) => setFilterValue(event.target.value)}>
                      <option value="">{formatMessage({ id: 'all' }).toUpperCase()}</option>
                      <option value={POLICY_STATUS_ACTIVE}>
                        {formatMessage({ id: 'insurancePolicyContainer.filter.active' }).toUpperCase()}
                      </option>
                      <option value={POLICY_STATUS_EXPIRED}>
                        {formatMessage({ id: 'insurancePolicyContainer.filter.expired' }).toUpperCase()}
                      </option>
                    </Input>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="no-gutters">
                <Col>
                  {!data || loading ? (
                    <Spinner className="spinner-positioning" />
                  ) : policies.length < 1 || error ? (
                    <div className="mx-auto title-text">{renderFeedbackMessage()}</div>
                  ) : (
                    policies.map(policy => {
                      const vehileMakeAndModel = `${get(policy, 'insuredItems[0].vehicleMake', '')} ${get(
                        policy,
                        'insuredItems[0].vehicleModel',
                        ''
                      )}`.trim();
                      const item =
                        get(policy, 'insuredItems[0].vehicleRegistrationPlate', '') ||
                        vehileMakeAndModel ||
                        (groupName === POLICY_PROPERTY ? get(policy, 'insuredItems[0].insuredLocation', '') : null);
                      return (
                        <ProductContainer
                          data-policy-number={policy.number}
                          mainTitle={policy.number}
                          key={policy.number}
                          subtitle={`${formatMessage({ id: 'userMyPolicies.policyData.policyValidTo' })}: ${formatDate(
                            policy.insuranceEnd
                          )}`}
                          item={item}
                          activeStatus={activeStatus(policy.insuranceEnd, params.insuranceType)}
                          isCardOpen={policy.number === policyNumber}
                          toggleCallback={isOpen => {
                            scrollToElement.current = false;
                            if (isOpen) {
                              history.push(`${ROUTE_TO_USER_MY_POLICIES_PAGE}/${params.insuranceType}`);
                            } else {
                              history.push(
                                `${ROUTE_TO_USER_MY_POLICIES_PAGE}/${params.insuranceType}/${policy.number}`
                              );
                            }
                          }}>
                          <PolicyForm user={user} policyData={policy} policyClaimType={claimType} />
                        </ProductContainer>
                      );
                    })
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
