import React from 'react';
import { Input, FormFeedback } from 'reactstrap';
import { useIntl } from 'react-intl';

export default React.memo(
  ({
    type = 'text',
    id: fieldId,
    fieldName = fieldId,
    placeholder = false,
    placeholderId = fieldId,
    customComponent,
    fieldState,
    formSubmitted = true,
    ...rest
  }) => {
    const Component = customComponent || Input;
    const { formatMessage } = useIntl();

    return (
      <div className="w-100">
        <Component
          type={type}
          id={fieldId}
          name={fieldName}
          aria-label={fieldName}
          invalid={formSubmitted && fieldState.invalid}
          value={fieldState.value}
          placeholder={placeholder ? formatMessage({ id: `inputs.${placeholderId}` }) : ''}
          {...rest}
        />
        <FormFeedback>{fieldState.error}</FormFeedback>
      </div>
    );
  }
);
