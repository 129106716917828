import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { history } from './historyConfig';
import rootSaga from '../redux/sagas';
import createRootReducer from '../redux/reducers';

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  createRootReducer(history), // root reducer with router state
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
    // other store enhancers if any
  )
);

sagaMiddleware.run(rootSaga);
