import React, { useState } from 'react';
import { Card, CardHeader, Collapse, CardBody } from 'reactstrap';
import keyboardUpIcon from '../../../img/icons/hok-chevron-up.svg';
import keyboardDownIcon from '../../../img/icons/hok-chevron-down.svg';

export const FAQCard = ({ children, mainTitle }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!isOpen);
  };

  return (
    <Card className="hok-faq-container mb-0">
      <CardHeader className="cursor-pointer" onClick={toggle}>
        <div className="d-flex align-items-center">
          <h4 className="hok-faq-group-title mr-sm-4">{mainTitle}</h4>

          <img
            alt="collapse-toggler"
            src={isOpen ? keyboardUpIcon : keyboardDownIcon}
            className={'collapse-toggler ml-auto'}
          />
        </div>
      </CardHeader>
      <Collapse className="hok-faq-collapse" isOpen={isOpen}>
        <CardBody className="p-0">{children}</CardBody>
      </Collapse>
    </Card>
  );
};
