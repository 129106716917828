import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import { get } from 'lodash-es';
import { GetPlacesQuery } from '../operations/queries/GetLocationsQuery';
import { handleRequestError, getErrorObject, getSpecificError } from '../constants/errorCodes';
import { GetContactFormSubjectsQuery } from '../operations/queries/GetContactFormSubjectsQuery';
import {
  GetProductDocumentsQuery,
  GetAllTypeProductDocumentsQuery,
} from '../operations/queries/GetProductDocumentsQuery';
import { DOC_TYPE_ALL, HOK_AUTONEZGODA, AUTOODGOVORNOST } from '../constants/business';
import { GetLegalDocumentsQuery } from '../operations/queries/GetLegalDocumentsQuery';
import { GetProductRisksQuery, GetAORisksDefinitionsQuery } from '../operations/queries/GetProductRisksQuery';
import { GetProductRiskSumsQuery } from '../operations/queries/GetProductRiskSumsQuery';
import { GetProductPackagesQuery } from '../operations/queries/GetProductPackagesQuery';
import { GetServerStorageQuery } from '../operations/queries/GetServerStorageQuery';
import { GetServerStorageKeyFromProfileQuery } from '../operations/queries/GetServerStorageKeyFromProfileQuery';
import { moveArrayItemToLastIndex } from './arrayOperations';
import { GetWSPayLinkMutation } from '../operations/mutations/GetWSPayLinkMutation';
import { GetCustomContactFormSchemaQuery } from '../operations/queries/GetCustomContactFormSchemaQuery';
import { isDoNotTrackEnabled } from './browser';
import { getLocalStorageGdprConsent } from '../constants/LocalStorageKeys';
import { analytics } from '../config/firebaseConfig';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const usePlacesQuery = () => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetPlacesQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const places = get(data, 'places.addresses', []);

  return { places, loading, error };
};

export const useContactFormSubjects = (config = { variables: {}, silentError: true }) => {
  const dispatch = useDispatch();

  const { variables, silentError } = config;

  const { data, loading, error } = useQuery(GetContactFormSubjectsQuery, {
    variables: { ...variables, order: { order: 'ASC', ...get(variables, 'order', {}) } },
    onError: error => handleRequestError(error, dispatch, { silent: silentError || true }),
  });

  const subjects = get(data, 'getContactFormSubjects.contactFormSubjects', []);

  return { subjects, loading, error };
};

export const useProductDocumentsQuery = ({ docType, productCode }) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(
    docType === DOC_TYPE_ALL ? GetAllTypeProductDocumentsQuery : GetProductDocumentsQuery,
    {
      variables: docType === DOC_TYPE_ALL ? { productCode } : { filter: { productCode, type: docType, active: true } },
      onError: error => handleRequestError(error, dispatch, { silent: true }),
    }
  );

  const productDocuments = get(data, 'getProductDocuments.documents', []);
  const allTypeDocuments = {
    PREDUGOVORNA_DOKUMENTACIJA: get(data, 'PREDUGOVORNA_DOKUMENTACIJA.documents', []),
    UVJETI_OSIGURANJA: get(data, 'UVJETI_OSIGURANJA.documents', []),
  };

  return { productDocuments, allTypeDocuments, loading, error };
};

export const useLegalDocumentsQuery = documentId => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetLegalDocumentsQuery, {
    variables: { filter: { documentId, active: true } },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const legalDocuments = get(data, 'getLegalDocuments.documents', []);

  return { legalDocuments, loading, error };
};

export const useAORisksQuery = vehicleTypeCode => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetProductRisksQuery, {
    variables: { productGroup: AUTOODGOVORNOST },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  let risks = get(data, 'getProductRisks', []).filter(risk =>
    risk.vehicles.some(vehicle => vehicle.vehicleTypeCode === vehicleTypeCode)
  );
  if (risks.length > 0) {
    risks = moveArrayItemToLastIndex(risks, risk => risk.id === HOK_AUTONEZGODA);
  }

  return { risks, risksLoading: loading, risksError: error };
};

export const useAORisksDefinitionsQuery = ({ skip = false }) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetAORisksDefinitionsQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    skip,
  });

  const risks = get(data, 'getProductRisks', []);

  return { risks, risksLoading: loading, risksError: error };
};

export const useAORiskSumsQuery = ({ vehicleTypeCode, classCode }) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetProductRiskSumsQuery, {
    variables: {
      riskCode: 'HOK_AUTONEZGODA',
      vehicleTypeCode,
      classCode,
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  return { riskSums: get(data, 'getProductRisksSums', []), riskSumsLoading: loading, riskSumsError: error };
};

export const useAOProductPackagesQuery = () => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetProductPackagesQuery, {
    variables: { productGroup: AUTOODGOVORNOST },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const packages = get(data, 'getProductPackages', []);
  if (packages.length > 0) {
    packages.sort((a, b) => (parseFloat(a.price) > parseFloat(b.price) ? 1 : -1));
  }

  return { packages, packagesLoading: loading, packagesError: error };
};

export const useServerStorageFromProfileKey = () => {
  const dispatch = useDispatch();

  const [getServerStorageData, { data }] = useLazyQuery(GetServerStorageQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    fetchPolicy: 'network-only',
  });

  useQuery(GetServerStorageKeyFromProfileQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    onCompleted: data => {
      if (get(data, 'profile.serverStorageKey', null)) {
        getServerStorageData({ variables: { stateKey: get(data, 'profile.serverStorageKey', null) } });
      }
    },
    fetchPolicy: 'network-only',
  });

  return { serverStorageData: get(data, 'serverStorage.state'), stateKey: get(data, 'serverStorage.stateKey') };
};

export const useGetWSPayLink = confirmationCode => {
  const dispatch = useDispatch();

  const [getWSPayLink, { data, loading, error }] = useMutation(GetWSPayLinkMutation, {
    variables: { confirmationCode },
    onError: error =>
      handleRequestError(error, dispatch, { silent: true }, () => {
        window.USER_WANTS_TO_CLOSE_THIS_WINDOW = true;
        window.APP_CAN_CLOSE_THIS_WINDOW = true;
      }),
  });

  useEffect(() => {
    getWSPayLink();
  }, [getWSPayLink]);

  const WSPayData = get(data, 'getWSPayLink', {});
  const paymentError = error ? getSpecificError(getErrorObject(error).key) : null;

  return { data: WSPayData, loading, error: get(paymentError, 'message', '') };
};

export const useGetCustomFormSchema = (formTypeProperty, type) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetCustomContactFormSchemaQuery, {
    variables: { type },
    skip: !formTypeProperty,
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const formSchema = get(data, 'getCustomContactFormSchema.customContactFormSchemaFields', null);
  const meta = get(data, 'getCustomContactFormSchema.meta', null);

  return { formSchema, meta, loading, error };
};

export const useGoogleAnalyticsPageview = (page, options = {}) => {
  useEffect(() => {
    if (analytics && !isDoNotTrackEnabled() && getLocalStorageGdprConsent()) {
      analytics.setCurrentScreen(page, page);
      analytics.logEvent('page_view', { page_title: page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
