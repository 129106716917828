import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Spinner, ScreenTitleContainer } from '../../../containers';
import { TicketSearch } from './TicketSearch';
import { TicketCard } from '../../../components/TicketCard';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserTicketingPage = () => {
  return (
    <Container className="hok-container" fluid>
      <AdFormIFrame title="Moji Zahtjevi" />
      <Row className="no-gutters mt-3">
        <Col>
          <ScreenTitleContainer row titleId="userMyTickets.title" maxWidthSet />
          <TicketSearch>
            {({ data: tickets, error, loadingTickets }) => {
              if ((!tickets && !error) || loadingTickets) {
                return <Spinner className="spinner-positioning" />;
              }

              if (tickets.length < 1 || error) {
                return (
                  <div className="mx-auto title-text">
                    <p className="pr-1 hok-text-dark">
                      <FormattedMessage id="userMyTickets.noTicketsFound" />
                    </p>
                  </div>
                );
              }

              return tickets.map(ticket => <TicketCard ticket={ticket} key={ticket.id} />);
            }}
          </TicketSearch>
        </Col>
      </Row>
    </Container>
  );
};
