import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, CustomInput } from 'reactstrap';
import { UserInteractionModal } from '../../../components/UserInteractionModal';
import { CustomButton } from '../../../containers';
import { ROUTE_TO_USER_PRODUCT_ENQUIRY } from '../../../constants/routes';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../constants/support';
import { googleAnalyticsEvent } from '../../../utils/browser';

export const CarInsuranceEligibilityModal = ({ isOpen, toggle, contractingFn }) => {
  const history = useHistory();

  const [selfContracting, setSelfContracting] = useState(false);
  const [race, setRace] = useState(false);
  const [utility, setUtility] = useState(false);
  const [old, setOld] = useState(false);
  const [disability, setDisability] = useState(false);

  const [acceptance, setAcceptance] = useState(false);

  const disableContracting = !(selfContracting && race && utility && old && disability && acceptance);

  const switches = [
    { id: 'selfContracting', value: selfContracting, setter: setSelfContracting, label: 'Ugovaram policu za sebe.' },
    { id: 'racing', value: race, setter: setRace, label: 'Vozilo se neće koristiti za vožnju utrka.' },
    {
      id: 'utility',
      value: utility,
      setter: setUtility,
      label:
        'Vozilo se neće koristiti za namjene rent a car, taxi službe, vatrogasne potrebe, prijevoz opasnih i eksplozivnih tvari, medicinske i sanitarne potrebe.',
    },
    {
      id: 'oldTimer',
      value: old,
      setter: setOld,
      label: 'Vozilo nije starije od 30 godina (vozilo nije starodobno).',
    },
    { id: 'disability', value: disability, setter: setDisability, label: 'Nisam osoba sa invaliditetom.' },
  ];

  const textComponent = (
    <Row className="hok-car-insurance-modal no-gutters">
      <Col lg={{ size: 10, offset: 1 }} className="text-left font-weight-bold">
        <p className="mb-0">
          Prije nego što krenete ugovarati policu Autoodgovornosti, molimo Vas da odgovorite na par pitanja vezana uz
          ugovaranje police kako bi Vas mogli usmjeriti na odgovarajući process ugovaranja police.*
        </p>
      </Col>
      <Col lg={{ size: 10, offset: 1 }} className="text-left">
        <p className="small-text">
          *kako bi nastavili na samostalno ugovaranje police, potrebno je na sva pitanja odgovoriti pozitivno.
        </p>
      </Col>
      <Col lg={{ size: 9, offset: 1 }} className="mt-3">
        {switches.map((switchInput, index) => (
          <Row className="no-gutters mb-3" key={switchInput.id}>
            <Col xs={12} className="text-left d-flex">
              <div className="d-inline-flex h-100 font-weight-bold color-red mr-2">{index + 1}.</div>
              <div className="d-inline-flex">{switchInput.label}</div>
              <div className="ml-auto">
                <CustomInput
                  id={switchInput.id}
                  type="switch"
                  checked={switchInput.value}
                  onChange={event => switchInput.setter(event.target.checked)}
                />
              </div>
            </Col>
          </Row>
        ))}
        <Row className="no-gutters mt-4">
          <Col className="text-right">
            <CustomButton
              className="hok-button hok-sm-outline-btn"
              outline
              block={false}
              translationId="selectAll"
              onClick={() => switches.forEach(slider => slider.setter(true))}
            />
          </Col>
        </Row>
        <Row className="no-gutters mt-5 mb-3">
          <Col className="d-flex flex-row" xs="12">
            <CustomInput
              className="hok-tac-checkbox"
              id="infoAccuracyAcceptance"
              type="checkbox"
              checked={acceptance}
              onChange={event => setAcceptance(event.target.checked)}
              label={
                <span className="font-weight-bold text-dark text-left">
                  Svjestan sam kako korištenjem vozila u navedene namjene postoji mogućnost nevaženja ugovorene police.
                </span>
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <UserInteractionModal
      wide
      isOpen={isOpen}
      toggle={toggle}
      titleId={`modalHeaders.carInsuranceQuestionnaire`}
      textComponent={textComponent}>
      <CustomButton
        type="button"
        outline
        className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
        block={false}
        translationId="requestOffer"
        onClick={() => {
          googleAnalyticsEvent({ category: 'Proizvodi', action: 'Proizvodi_Autoodgovornost_Zatrazi_Ponudu' });
          history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
            subject: ENQUIRY_SUBJECT_NEW_POLICY,
            title: 'Kupnja nove police "Autoodgovornost"',
          });
        }}
      />
      <CustomButton
        className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
        block={false}
        disabled={disableContracting}
        translationId="newPolicyShort"
        onClick={disableContracting ? null : contractingFn}
      />
    </UserInteractionModal>
  );
};
