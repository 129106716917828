import gql from 'graphql-tag';

export const GetOfficesQuery = gql`
  query GetOffices($order: getOfficesSortFilterInput) {
    getOffices(order: $order) {
      offices {
        name
        address
        content
        telephone
        fax
        workingHours
        icon
        city
        lat
        lng
        categoryId
        categoryName
        officeOrder
        officeGroupOrder
      }
    }
  }
`;
