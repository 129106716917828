import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Button } from 'reactstrap';
import { useQuery, useMutation } from 'react-apollo';
import { FormattedMessage, useIntl } from 'react-intl';
import { get, sumBy } from 'lodash-es';
import { GetUserNotificationsQuery } from '../../operations/queries/GetUserNotificationsQuery';
import { handleRequestError } from '../../constants/errorCodes';
import { UserNotificationSeenMutation } from '../../operations/mutations/UserNotificationSeenMutation';
import { NotificationsSubscription } from '../../operations/subscriptions/NotificationsSubscription';
import { getLocalStorageToken } from '../../constants/LocalStorageKeys';
import { ReactComponent as HocNotificationIcon } from '../../img/icons/hok-notification.svg';
import { formatDateTime } from '../../constants/dateFormatting';
import { Scrollbars } from 'react-custom-scrollbars';
import { formatParamsFromLink } from '../../utils/internalParsing';
import {
  ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE,
  ROUTE_TO_USER_MY_POLICIES_PAGE,
  ROUTE_TO_USER_TICKETING_PAGE,
} from '../../constants/routes';
import { policyGroupDefs } from '../../constants/business';

export const Notifications = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { loading, data, subscribeToMore } = useQuery(GetUserNotificationsQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    fetchPolicy: 'no-cache',
  });

  const [setSeen] = useMutation(UserNotificationSeenMutation, {
    onError: error => handleRequestError(error, dispatch),
  });

  const userId = useSelector(store => store.auth.user.id);

  const getNotifData = get(data, 'notifications.notifications', []);
  const [notifications, setNotifications] = useState([]);

  useMemo(() => {
    if (getNotifData.length > 0) {
      setNotifications(getNotifData);
    }
  }, [getNotifData]);

  useEffect(() => {
    const token = getLocalStorageToken();
    if (userId && token) {
      subscribeToMore({
        document: NotificationsSubscription,
        variables: { userId: `${userId}`, token },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          setNotifications(get(subscriptionData, 'data.notifications.notifications', []));
          return subscriptionData.data;
        },
        onError: error => handleRequestError(error, dispatch, { silent: true }),
      });
    }
  }, [userId, subscribeToMore, dispatch]);

  const unseenCount = sumBy(notifications, notif => (notif.seen ? 0 : 1));

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const notificationsToggle = () => {
    setNotificationsOpen(isOpen => !isOpen);
  };

  const onNotificationClick = notification => {
    if (!notification.seen) {
      setSeen({ variables: { id: notification.id, seen: !notification.seen } });
      const notifIdx = notifications.findIndex(notif => notif.id === notification.id);
      const newNotifications = [...notifications];
      newNotifications[notifIdx].seen = !notification.seen;
      setNotifications(newNotifications);
    }
  };

  const constructNotificationLink = link => {
    const {
      policyGroup,
      policyNumber,
      claimClass,
      claimNo,
      claimConfirmationCode,
      ticketNumber,
      file,
    } = formatParamsFromLink(link);
    if (claimConfirmationCode) return `${ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE}/${claimConfirmationCode}`;
    if (claimClass && claimNo)
      return `${ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE}/${claimClass}-${claimNo}${file ? '/documentation' : ''}`;
    if (ticketNumber) return `${ROUTE_TO_USER_TICKETING_PAGE}/${ticketNumber}${file ? '/documentation' : ''}`;
    if (policyGroup && policyNumber) {
      const groupName = policyGroupDefs[policyGroup];
      return groupName ? `${ROUTE_TO_USER_MY_POLICIES_PAGE}/${groupName.route}/${policyNumber}` : null;
    }
    return null;
  };

  const singleNotification = notification => (
    <div className="hok-notifications-item dropdown-item">
      <div className="d-flex justify-content-between">
        <h5 style={{ whiteSpace: 'normal' }} className="hok-notifications-title">
          {notification.subject}
        </h5>
        <Button
          className={'hok-notifications-close mx-0 ' + (notification.seen ? 'seen' : 'unseen')}
          aria-label="Close"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            setSeen({ variables: { id: notification.id, seen: !notification.seen } });
            const notifIdx = notifications.findIndex(notif => notif.id === notification.id);
            const newNotifications = [...notifications];
            newNotifications[notifIdx].seen = !notification.seen;
            setNotifications(newNotifications);
          }}
        />
      </div>
      <div className="hok-notifications-content">
        <span>
          {notification.content &&
            notification.content.split('\n').map((item, key) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
        </span>
        <span className="d-block text-right mt-2">{formatDateTime(notification.createdAt, false)}</span>
      </div>
    </div>
  );

  const renderNotifications = () => {
    return (
      <DropdownMenu className="hok-notifications-dropdown-menu" right>
        <Scrollbars autoHeight autoHeightMax={500}>
          {notifications.length > 0 ? (
            notifications.map((notification, idx) => (
              <React.Fragment key={notification.id}>
                {constructNotificationLink(notification.link) ? (
                  <Link
                    className="hok-notification-link"
                    to={constructNotificationLink(notification.link)}
                    onClick={event => {
                      event.stopPropagation();
                      onNotificationClick(notification);
                    }}>
                    {singleNotification(notification)}
                  </Link>
                ) : (
                  singleNotification(notification)
                )}
                {idx < notifications.length - 1 && <DropdownItem divider />}
              </React.Fragment>
            ))
          ) : (
            <div className="hok-notifications-item dropdown-item disabled py-5">
              <div className="hok-notifications-content">
                <span className="hok-text-dark-500">
                  <FormattedMessage id="noNewNotifications" />
                </span>
              </div>
            </div>
          )}
        </Scrollbars>
      </DropdownMenu>
    );
  };

  return (
    <Dropdown
      className="nav-item hok-notifications"
      direction="down"
      isOpen={notificationsOpen}
      toggle={notificationsToggle}>
      <DropdownToggle
        aria-label={formatMessage({ id: 'notifications' })}
        nav
        className="hok-notifications-toggle"
        disabled={!notifications || loading}>
        <div className="hok-notifications-link">
          <HocNotificationIcon className="svg-white" height="20" />
          {unseenCount > 0 && !loading && <Badge className="hok-notifications-badge">{unseenCount}</Badge>}
        </div>
      </DropdownToggle>
      {renderNotifications()}
    </Dropdown>
  );
};
