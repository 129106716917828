import { GENERAL_ERROR } from '../actions/errorActions';

const initialState = {
  title: null,
  key: null,
  code: null,
  message: null,
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_ERROR:
      return action.payload;

    default:
      return state;
  }
};

export default errorReducer;
