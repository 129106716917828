import gql from 'graphql-tag';

export const GetEurekaPriceQuery = gql`
  query GetEurekaPrice($productRelatedData: HokEurekaProductRelatedDataInput!) {
    calculatePriceHokEureka(productRelatedData: $productRelatedData) {
      totalPremium
      eligibleForProduct
    }
  }
`;

export const GetExpressPriceQuery = gql`
  query GetExpressPrice($productRelatedData: HokExpressProductRelatedDataInput!) {
    calculatePriceHokExpress(productRelatedData: $productRelatedData) {
      eligibleForProduct
      calculationsForVin
      totalPremium
      eligibleError {
        message
        key
        code
      }
    }
  }
`;

export const GetAOPriceQuery = gql`
  query GetAOPrice($productRelatedData: HokAOProductRelatedDataInput!) {
    calculatePriceHokAO(productRelatedData: $productRelatedData) {
      totalPremium
      eligibleForProduct
      eligibleError {
        message
        key
      }
      eligibleErrors {
        message
        key
      }
      isRenewal
      isTheSameOwner
      isStartDayForced
      coversOnPreviousInsurancePolicy
      coversOnPreviousInsurancePolicyAdditionalData {
        HOK_AUTONEZGODA_OSIGURANA_SVOTA
        HOK_AUTONEZGODA_TIP_VOZACA
        HOK_AUTONEZGODA_BROJ_PUTNIKA
      }
      registrationPlateToUseInContract
      calculationsForRegistrationPlate
      calculationsForVin
      calculationsForNumberOfPreviousInsurancePolicy
      calculationsForPackage
      calculationsForCovers
      calculationItems {
        name
        riskEnumCode
        value
      }
      insuranceStartDate
      insuranceEndDate
    }
  }
`;
