import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormGroup, Label, Input, InputGroup, FormFeedback, CustomInput } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash-es';
import { CustomButtonArrowRight } from '../../../containers';
import { CustomButton } from '../../../containers';
import {
  TRAVEL_INSURANCE_PACKAGES,
  TRAVEL_INSURANCE_COVERS,
  TRAVEL_PURPOSE_PLEASURE,
  TRAVEL_TYPE_SINGLE,
  POLICY_COVER_NO_INCIDENT,
  POLICY_CODE_PZ,
} from '../../../constants/business';

import { yearListRender, FORMAT_STANDARD_DATE } from '../../../constants/dateFormatting';
import { SearchResultCard } from '../../../components/SearchResultCard';
import { ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_2 } from '../../../constants/routes';
import { validateFormSubmission } from '../../../utils/validation';
import {
  removeSessionStorageTravelInsurancePackage,
  getSessionStorageTravelInsurancePackage,
  setSessionStorageTravelInsurancePackage,
  getSessionStorageTravelInsuranceCover,
  removeSessionStorageTravelInsuranceCover,
  setSessionStorageTravelInsuranceCover,
} from '../../../constants/LocalStorageKeys';
import { ModalWithCardSelection } from '../../../components/ModalWithCardSelection';
import { ModalInsuredPerson } from './ModalInsuredPerson';
import { useMutation, useLazyQuery } from 'react-apollo';
import { handleRequestError } from '../../../constants/errorCodes';
import { GetPzoPriceQuery } from '../../../operations/queries/GetPzoPriceQuery';
import { usePrevious } from '../../../utils/customHooks';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { displayTotalPremium } from '../../../utils/currencyDisplayFormat';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';

export const Step1Travel = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const user = useSelector(state => state.auth.user);

  const [submitForm, { loading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data => history.push(`${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_2}/${data.serverStorage.stateKey}`),
    onError: error => handleRequestError(error, dispatch),
  });

  const [getPzoPrice, { data: priceData, loading: priceLoading }] = useLazyQuery(GetPzoPriceQuery, {
    onError: error => handleRequestError(error, dispatch),
  });

  const [insurancePackage, setInsurancePackage] = useState({
    value:
      (TRAVEL_INSURANCE_PACKAGES.some(travelPackage => travelPackage.id === get(data, 'productPackage', null)) &&
        get(data, 'productPackage', null)) ||
      getSessionStorageTravelInsurancePackage() ||
      null,
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.insurancePackage" />,
    validation: {
      required: true,
      enum: TRAVEL_INSURANCE_PACKAGES.map(travelPackage => travelPackage.id),
    },
  });

  const [additionalCover, setAdditionalCover] = useState({
    value:
      ([POLICY_COVER_NO_INCIDENT, ...TRAVEL_INSURANCE_COVERS.map(cover => cover.id)].includes(
        get(data, 'additionalProductPackage', null)
      ) &&
        get(data, 'additionalProductPackage', null)) ||
      getSessionStorageTravelInsuranceCover() ||
      POLICY_COVER_NO_INCIDENT,
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.additionalCover" />,
    validation: {
      required: true,
      enum: [POLICY_COVER_NO_INCIDENT, ...TRAVEL_INSURANCE_COVERS.map(cover => cover.id)],
    },
  });

  const [policyValidFrom, setPolicyValidFrom] = useState({
    value:
      get(data, 'insuranceStartDate', null) && moment(data.insuranceStartDate).isAfter(moment(), 'day')
        ? moment(data.insuranceStartDate)
        : null,
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.validFrom" />,
    validation: {
      required: true,
      date: {
        valid: true,
      },
    },
  });

  const [policyValidTo, setPolicyValidTo] = useState({
    value:
      get(data, 'insuranceEndDate', null) &&
      moment(data.insuranceEndDate).isAfter(moment(), 'day') &&
      get(data, 'insuranceStartDate', null) &&
      moment(data.insuranceStartDate).isAfter(moment(), 'day') &&
      moment(data.insuranceStartDate).isSameOrAfter(moment(data.insuranceStartDate), 'day')
        ? moment(data.insuranceEndDate)
        : null,
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.validTo" />,
    validation: {
      required: true,
      date: {
        valid: true,
      },
    },
  });

  const [tripPurpose, setTripPurpose] = useState({
    value: get(data, 'travelType', TRAVEL_PURPOSE_PLEASURE),
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.tripPurpose" />,
    validation: {
      required: true,
    },
  });

  const [acceptedDataAccuracy, setAcceptedDataAccuracy] = useState({
    value: get(data, 'acceptedDataAccuracy', false),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.acceptedDataAccuracy" />,
    validation: {
      isTrue: true,
    },
  });

  const [validFromFocused, setValidFromFocused] = useState(false);
  const [validToFocused, setValidToFocused] = useState(false);

  const [insuredPeople, setInsuredPeople] = useState(
    get(data, 'insuredPeople', null)
      ? data.insuredPeople.map(person => {
          return {
            firstName: person.name,
            lastName: person.surename,
            dateOfBirth: person.dateOfBirth,
            oib: person.oib,
            sex: person.sex,
            address: person.address,
            zip: person.zip,
            city: person.city,
            // additionalDocumentNumber: person.additionalDocumentNumber,
          };
        })
      : [
          {
            firstName: user.firstName,
            lastName: user.lastName,
            dateOfBirth: user.dateOfBirth,
            sex: user.sex,
            oib: user.oib,
            // HZZONumber: get(data, 'insuredPeople[0].additionalDocumentNumber', user.HZZONumber),
            address: user.address,
            city: user.city,
            zip: user.zip,
            phoneNumber: user.phoneNumber,
            email: user.email,
          },
        ]
  );

  const previousPackage = usePrevious(insurancePackage.value);
  const previousAdditionalCover = usePrevious(additionalCover.value);

  const [insurancePackagesModal, setInsurancePackagesModal] = useState(false);
  const [additionalCoversModal, setAdditionalCoversModal] = useState(false);
  const [insuredPersonModal, setInsuredPersonModal] = useState(false);
  const [insuredPersonEditModal, setInsuredPersonEditModal] = useState(false);

  const [selectedPersonIndex, setSelectedPersonIndex] = useState(null);

  const calculateDuration = Math.ceil(
    moment(policyValidTo.value, FORMAT_STANDARD_DATE)
      .endOf('day')
      .diff(moment(policyValidFrom.value, FORMAT_STANDARD_DATE).startOf('day'), 'days', true)
  );
  const durationRef = useRef();
  if (durationRef.current) {
    durationRef.current.defaultValue = calculateDuration
      ? `${calculateDuration} ${formatMessage({ id: calculateDuration === 1 ? 'day' : 'days' })}`
      : '';
  }

  useEffect(() => {
    return () => {
      removeSessionStorageTravelInsurancePackage();
      removeSessionStorageTravelInsuranceCover();
    };
  }, []);

  useEffect(() => {
    if (insurancePackage.value && additionalCover.value && policyValidFrom.value && policyValidTo.value) {
      getPzoPrice({
        variables: {
          travelType: tripPurpose.value,
          productType: TRAVEL_TYPE_SINGLE,
          productPackage: insurancePackage.value,
          additionalProductPackage: additionalCover.value,
          insuranceStartDate: policyValidFrom.value.toISOString(),
          insuranceEndDate: policyValidTo.value.toISOString(),
          insuredPeople: insuredPeople.map(person => {
            return {
              name: person.firstName,
              surename: person.lastName,
              dateOfBirth: person.dateOfBirth,
              oib: person.oib,
              sex: person.sex,
              address: person.address,
              zip: person.zip,
              city: person.city,
              // additionalDocumentNumber: person.HZZONumber,
            };
          }),
        },
      });
    }
  }, [
    getPzoPrice,
    user,
    insurancePackage.value,
    previousPackage,
    additionalCover.value,
    previousAdditionalCover,
    policyValidFrom.value,
    policyValidTo.value,
    insuredPeople,
    tripPurpose.value,
  ]);

  const togglePackagesModal = useCallback(() => {
    setInsurancePackagesModal(isOpen => !isOpen);
  }, []);

  const toggleAdditionalCoversModal = useCallback(() => {
    setAdditionalCoversModal(isOpen => !isOpen);
  }, []);

  const modalRef = useRef(Math.random());

  const toggleInsuredPersonModal = useCallback(() => {
    if (!insuredPersonModal) {
      modalRef.current = Math.random();
    }
    setInsuredPersonModal(isOpen => !isOpen);
  }, [insuredPersonModal]);

  const toggleInsuredPersonEditModal = useCallback(() => {
    setInsuredPersonEditModal(isOpen => !isOpen);
  }, []);

  const removePerson = useCallback(index => {
    if (index > 0) {
      setInsuredPeople(ip => [...ip.filter((_, i) => i !== index)]);
    }
  }, []);

  const editPerson = useCallback(
    i => {
      return person => {
        setInsuredPeople(
          insuredPeople.map((el, index) => {
            if (index === i) {
              return {
                ...el,
                ...person,
              };
            }
            return el;
          })
        );
      };
    },
    [insuredPeople]
  );

  const addPerson = useCallback(
    person => {
      setInsuredPeople([...insuredPeople, person]);
    },
    [insuredPeople]
  );

  const openEditModal = useCallback(
    index => {
      setSelectedPersonIndex(index);
      toggleInsuredPersonEditModal();
    },
    [toggleInsuredPersonEditModal]
  );

  const handleStep1Submit = async event => {
    event.preventDefault();

    const fieldsArray = [
      setTripPurpose,
      setInsurancePackage,
      setAdditionalCover,
      setPolicyValidFrom,
      setPolicyValidTo,
      setAcceptedDataAccuracy,
    ];

    if (
      (await validateFormSubmission(fieldsArray)) &&
      get(priceData, 'calculatePriceHokPzo.eligibleForProduct', false)
    ) {
      submitForm({
        variables: {
          attachToUser: true,
          state: {
            ...data,
            type: POLICY_CODE_PZ,
            step: 'STEP_2',
            travelType: tripPurpose.value,
            productType: TRAVEL_TYPE_SINGLE,
            productPackage: insurancePackage.value,
            additionalProductPackage: additionalCover.value,
            acceptedDataAccuracy: acceptedDataAccuracy.value,
            insuranceStartDate: policyValidFrom.value.toISOString(),
            insuranceEndDate: policyValidTo.value.toISOString(),
            insuredPeople: insuredPeople.map(person => {
              return {
                name: person.firstName,
                surename: person.lastName,
                dateOfBirth: person.dateOfBirth,
                oib: person.oib,
                sex: person.sex,
                address: person.address,
                zip: person.zip,
                city: person.city,
                // additionalDocumentNumber: person.HZZONumber,
              };
            }),
            totalPremium: get(priceData, 'calculatePriceHokPzo.totalPremium', 0),
          },
        },
      });
    }
  };

  const renderInsuredPeople = () => {
    return insuredPeople.map((elem, index) => (
      <Col xs="12" key={index}>
        <SearchResultCard
          firstName={elem.firstName}
          lastName={elem.lastName}
          dateOfBirth={elem.dateOfBirth}
          disabled={index === 0}
          removePerson={() => removePerson(index)}
          openEditModal={() => openEditModal(index)}
        />
      </Col>
    ));
  };

  const setCoverSubmitFunction = useCallback(
    cardId => {
      setAdditionalCover(o => ({ ...o, value: cardId, invalid: false, error: null }));
      setSessionStorageTravelInsuranceCover(cardId);
      toggleAdditionalCoversModal();
    },
    [toggleAdditionalCoversModal]
  );

  const setInsurancePackageSubmitFunction = useCallback(
    cardId => {
      setInsurancePackage(o => ({ ...o, value: cardId, invalid: false, error: null }));
      setSessionStorageTravelInsurancePackage(cardId);
      togglePackagesModal();
    },
    [togglePackagesModal]
  );


  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  return (
    <>
      <Form onSubmit={handleStep1Submit}>
        <FormGroup row>
          <Col md="6" lg="5">
            <Label>
              <FormattedMessage id="inputs.tripPurpose" />
            </Label>
            <InputGroup>
              <div className="hok-select">
                <Input
                  className="hok-custom-select"
                  type="select"
                  disabled={true}
                  value={tripPurpose.value}
                  // onChange={event => inputHandler(setTripPurpose, event.target.value)}
                >
                  <option value={TRAVEL_PURPOSE_PLEASURE}>
                    {formatMessage({ id: `userMyPoliciesTravelInsurance.${TRAVEL_PURPOSE_PLEASURE}` })}
                  </option>
                </Input>
              </div>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm="6" lg="5">
            <Label for="insurancePackage">
              <FormattedMessage id="userMyPolicies.policyData.insurancePackage" />
            </Label>
            <InputGroup>
              <CustomButtonArrowRight
                id="insurancePackage"
                placeholder={
                  insurancePackage.value ? `userMyPoliciesTravelInsurance.${insurancePackage.value}` : 'notSelected'
                }
                className="custom-header-text"
                onClick={togglePackagesModal}
              />
              {insurancePackage.invalid && insurancePackage.error && (
                <FormFeedback className="d-block">{insurancePackage.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
          <Col sm="6" lg="5">
            <Label for="additionalCovers">
              <FormattedMessage id="userMyPolicies.policyData.additionalCovers" />
            </Label>
            <InputGroup>
              <CustomButtonArrowRight
                id="additionalCovers"
                placeholder={`userMyPoliciesTravelInsurance.${additionalCover.value}`}
                className="custom-header-text"
                onClick={toggleAdditionalCoversModal}
              />
              {additionalCover.invalid && additionalCover.error && (
                <FormFeedback className="d-block">{additionalCover.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row className="mt-5 mb-4">
          <Col className="d-flex flex-row" xs="12">
            <InputGroup>
              <CustomInput
                className="hok-tac-checkbox"
                id="infoAccuracyAcceptance"
                type="checkbox"
                checked={acceptedDataAccuracy.value}
                onChange={event =>
                  setAcceptedDataAccuracy({
                    ...acceptedDataAccuracy,
                    value: event.target.checked,
                    invalid: false,
                    error: '',
                  })
                }
                label={
                  <span className="font-weight-bold text-dark text-left">
                    Prihvaćam pravnu odgovornost o točnosti podataka te kako se u trenutku ugovaranja police nalazim
                    unutar teritorija Republike Hrvatske.
                  </span>
                }
              />
              {acceptedDataAccuracy.invalid && acceptedDataAccuracy.error && (
                <FormFeedback className="d-block">{acceptedDataAccuracy.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup className="mb-5" row>
          <Col xs="12" sm="6" lg="4">
            <Label for="validFrom">
              <FormattedMessage id="inputs.validFrom" />
            </Label>
            <InputGroup className={policyValidFrom.invalid ? 'invalid-date' : ''}>
              <SingleDatePicker
                id="validFrom"
                readOnly
                hideKeyboardShortcutsPanel
                date={policyValidFrom.value}
                onDateChange={date => setPolicyValidFrom({ ...policyValidFrom, invalid: false, value: date })}
                showDefaultInputIcon
                firstDayOfWeek={1}
                numberOfMonths={1}
                small
                focused={validFromFocused}
                onFocusChange={({ focused }) => setValidFromFocused(focused)}
                displayFormat={FORMAT_STANDARD_DATE}
                placeholder={formatMessage({ id: 'inputs.validFrom' })}
                initialVisibleMonth={() => policyValidFrom.value || moment()}
                isOutsideRange={date =>
                  date.isAfter(policyValidTo.value, 'day') ||
                  date.isSameOrBefore(moment(), 'day') ||
                  date.isAfter(moment().add(30, 'days'), 'day') ||
                  (policyValidTo.value && date.isBefore(policyValidTo.value.clone().subtract(1, 'year'), 'day'))
                }
                renderMonthElement={({ month, onMonthSelect }) => (
                  <div className="d-flex justify-content-center">
                    <div className="mr-1">
                      <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                        {moment.months().map((label, value) => (
                          <option value={value} key={label}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              />
              {policyValidFrom.invalid && policyValidFrom.error && (
                <FormFeedback className="d-block">{policyValidFrom.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Label for="validTo">
              <FormattedMessage id="inputs.validTo" />
            </Label>
            <InputGroup className={policyValidTo.invalid ? 'invalid-date' : ''}>
              <SingleDatePicker
                id="validTo"
                readOnly
                hideKeyboardShortcutsPanel
                date={policyValidTo.value}
                onDateChange={date => setPolicyValidTo({ ...policyValidTo, invalid: false, value: date })}
                showDefaultInputIcon
                firstDayOfWeek={1}
                numberOfMonths={1}
                small
                focused={validToFocused}
                onFocusChange={({ focused }) => setValidToFocused(focused)}
                displayFormat={FORMAT_STANDARD_DATE}
                placeholder={formatMessage({ id: 'inputs.validTo' })}
                initialVisibleMonth={() => policyValidTo.value || moment()}
                isOutsideRange={date =>
                  date.isBefore(policyValidFrom.value, 'day') ||
                  date.isBefore(moment(), 'day') ||
                  (policyValidFrom.value
                    ? date.isAfter(policyValidFrom.value.clone().add(1, 'year'), 'day')
                    : date.isAfter(moment().add(1, 'year'), 'day'))
                }
                renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                  <div className="d-flex justify-content-center">
                    <div className="mr-1">
                      <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                        {moment.months().map((label, value) => (
                          <option value={value} key={label}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="ml-1">
                      <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                        {yearListRender(
                          moment().format('YYYY'),
                          (policyValidFrom.value ? policyValidFrom.value.clone() : moment())
                            .add(1, 'year')
                            .format('YYYY')
                        )}
                      </select>
                    </div>
                  </div>
                )}
              />
              {policyValidTo.invalid && policyValidTo.error && (
                <FormFeedback className="d-block">{policyValidTo.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Label for="input">
              <FormattedMessage id="inputs.duration" />
            </Label>
            <InputGroup>
              <Input innerRef={durationRef} disabled />
            </InputGroup>
          </Col>
        </FormGroup>
        <Row className="no-gutters mb-3">
          <Col>
            <h3>
              <FormattedMessage id="userMyPolicies.policyData.insuredPeople" />
            </h3>
          </Col>
        </Row>
        <Row>
          <div className="insured-people">{renderInsuredPeople()}</div>
        </Row>
        <Row>
          <Col>
            <CustomButton
              type="button"
              disabled={insuredPeople.length >= 9}
              onClick={toggleInsuredPersonModal}
              className={insuredPeople.length < 9 ? 'hok-outline-btn-wide' : 'hok-dashboard-btn-wide'}
              block={false}
              outline={insuredPeople.length < 9}
              translationId="addInsuredPerson"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <span className="primary">
              <FormattedMessage id="userMyPolicies.policyData.premium" />:
            </span>

          {currencyDataConfig.dualCurrencyDisplay === true && (
            <>
              <span className="secondary ml-3">
              {displayTotalPremium(
                get(priceData, 'calculatePriceHokPzo.totalPremium', undefined),
                get(data, 'totalPremium', undefined)
              )}
              {' '}|{' '}
               {currencyConversionEuro(
                get(priceData, 'calculatePriceHokPzo.totalPremium', 0),
                get(data, 'totalPremium', 0)
              )}
              </span>
            </>)}

            {currencyDataConfig.dualCurrencyDisplay === false && (
            <>
              <span className="secondary ml-3">
             {displayTotalPremium(
                get(priceData, 'calculatePriceHokPzo.totalPremium', undefined),
                get(data, 'totalPremium', undefined)
              )}
              </span>
            </>
            )}

            {/* <span className="secondary ml-3">

              {displayTotalPremium(
                get(priceData, 'calculatePriceHokPzo.totalPremium', undefined),
                get(data, 'totalPremium', undefined)
              )}{' '}
              |{' '}
              {currencyConversionEuro(
                get(priceData, 'calculatePriceHokPzo.totalPremium', 0),
                get(data, 'totalPremium', 0)
              )}

            </span> */}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CustomButton
              className="hok-dashboard-btn-wide float-sm-right"
              translationId="continue"
              disabled={!get(priceData, 'calculatePriceHokPzo.eligibleForProduct', false) || loading || priceLoading}
              loaderProp={loading || priceLoading}
            />
          </Col>
        </Row>
      </Form>
      <ModalWithCardSelection
        cardsList={[
          ...TRAVEL_INSURANCE_COVERS,
          { id: POLICY_COVER_NO_INCIDENT, name: `userMyPoliciesTravelInsurance.${POLICY_COVER_NO_INCIDENT}` },
        ]}
        setFunction={setCoverSubmitFunction}
        toggle={toggleAdditionalCoversModal}
        isOpen={additionalCoversModal}
      />
      <ModalWithCardSelection
        cardsList={TRAVEL_INSURANCE_PACKAGES}
        setFunction={setInsurancePackageSubmitFunction}
        toggle={togglePackagesModal}
        isOpen={insurancePackagesModal}
      />
      <ModalInsuredPerson
        key={modalRef.current}
        toggle={toggleInsuredPersonModal}
        isOpen={insuredPersonModal}
        submitFn={addPerson}
      />
      {insuredPersonEditModal && (
        <ModalInsuredPerson
          action="EDIT"
          toggle={toggleInsuredPersonEditModal}
          isOpen={insuredPersonEditModal}
          submitFn={editPerson(selectedPersonIndex)}
          insuredPerson={insuredPeople[selectedPersonIndex]}
        />
      )}
    </>
  );
};
