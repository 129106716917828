import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Label, Form, InputGroup } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { get } from 'lodash-es';
import { useQuery } from 'react-apollo';
import { handleRequestError } from '../../../constants/errorCodes';
import { CustomDashboardInput, CustomButton } from '../../../containers';
import { Search } from '../../../components/Search';
import { yearListRender, FORMAT_STANDARD_DATE } from '../../../constants/dateFormatting';
import { validateFormSubmission } from '../../../utils/validation';
import { GetUserTicketsQuery } from '../../../operations/queries/GetUserTicketsQuery';
import { TICKET_STATUS } from '../../../constants/ticketing';
import { useContactFormSubjects } from '../../../utils/customHooks';

export const TicketSearch = ({ children, userId = undefined }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { subjects, loading } = useContactFormSubjects();

  const [ticketType, setTicketType] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.ticketType" />,
    validation: {
      required: false,
    },
  });

  const [ticketId, setTicketId] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.ticketId" />,
    validation: {
      required: false,
    },
  });

  const [ticketStatus, setTicketStatus] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.userTicketStatus" />,
    validation: {
      required: false,
    },
  });

  const [fromDate, setFromDate] = useState({
    value: null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.fromDate" />,
    validation: {
      required: false,
    },
  });

  const [fromDateFocused, setFromDateFocused] = useState(false);

  const { data: ticketsData, loading: loadingTickets, error, fetchMore } = useQuery(GetUserTicketsQuery, {
    variables: { ...(userId && { userId: `${userId}` }), order: { createdAt: 'DESC' } },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const tickets = get(ticketsData, 'getUserTickets.tickets', []);
  const ticketsTotalCount = get(ticketsData, 'getUserTickets.totalCount', 0);

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false, error: null }));
  };

  const appliyTicketFilter = async event => {
    event.preventDefault();

    const fieldsArray = [setTicketType, setTicketId, setTicketStatus, setFromDate];
    if (await validateFormSubmission(fieldsArray)) {
      // Workaround for refetch() not updating retured data when initial query has
      // variables and onError props.
      // Setting fetchPolicy to "network-only" or "cache-and-network" causes 2 request
      // being fired where the latter has the initial query variables and overrides the refetch()
      // https://github.com/apollographql/react-apollo/issues/1929
      // https://github.com/apollographql/apollo-client/issues/3573
      fetchMore({
        variables: {
          ...(userId && { userId: `${userId}` }),
          filter: {
            ...(ticketType.value && { type: ticketType.value }),
            ...(ticketId.value && { number: ticketId.value }),
            ...(ticketStatus.value && {
              status: ticketStatus.value,
            }),
            ...(fromDate.value && { createdAtLessThanOrEqual: fromDate.value }),
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getUserTickets: {
              ...fetchMoreResult.getUserTickets,
            },
          };
        },
      });
    }
  };

  return (
    <>
      {ticketsTotalCount > 0 && (
        <Search>
          <Form onSubmit={appliyTicketFilter}>
            <Row>
              <Col md="4">
                <InputGroup>
                  <Label for="ticketType">
                    <FormattedMessage id="inputs.ticketType" />
                  </Label>
                  <div className="hok-select">
                    <CustomDashboardInput
                      id="enquiryType"
                      type="select"
                      fieldState={ticketType}
                      onChange={event => inputHandler(setTicketType, event.target.value)}>
                      {loading ? (
                        <option disabled value="">
                          {formatMessage({ id: 'loading' }).toUpperCase()}
                        </option>
                      ) : (
                        <option value="">
                          {formatMessage({
                            id: loading ? 'loading' : 'userMyTickets.filter.ticketTypes.all',
                          })}
                        </option>
                      )}
                      {subjects.map(subject => (
                        <option key={subject.id} value={subject.id}>
                          {subject.subject}
                        </option>
                      ))}
                    </CustomDashboardInput>
                  </div>
                </InputGroup>
              </Col>
              <Col md="4">
                <InputGroup>
                  <Label for="ticketId">
                    <FormattedMessage id="inputs.ticketId" />
                  </Label>
                  <CustomDashboardInput
                    id="ticketId"
                    placeholder={true}
                    fieldState={ticketId}
                    onChange={event => inputHandler(setTicketId, event.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col md="4">
                <InputGroup>
                  <Label for="ticketStatus">
                    <FormattedMessage id="inputs.userTicketStatus" />
                  </Label>
                  <div className="hok-select">
                    <CustomDashboardInput
                      id="ticketStatus"
                      type="select"
                      fieldState={ticketStatus}
                      onChange={event => inputHandler(setTicketStatus, event.target.value)}>
                      <option value="">{formatMessage({ id: 'userMyTickets.filter.ticketStatuses.all' })}</option>
                      {TICKET_STATUS.map(status => (
                        <option key={status} value={status}>
                          {formatMessage({ id: `userMyTickets.filter.ticketStatuses.${status}` })}
                        </option>
                      ))}
                    </CustomDashboardInput>
                  </div>
                </InputGroup>
              </Col>
              <Col className="mb-3 mb-md-0" md="4">
                <InputGroup>
                  <Label for="fromDate">
                    <FormattedMessage id="inputs.fromDate" />
                  </Label>
                  <SingleDatePicker
                    id="fromDate"
                    readOnly
                    hideKeyboardShortcutsPanel
                    date={fromDate.value}
                    onDateChange={date => setFromDate({ ...fromDate, invalid: false, value: date })}
                    showDefaultInputIcon
                    firstDayOfWeek={1}
                    numberOfMonths={1}
                    small
                    focused={fromDateFocused}
                    onFocusChange={({ focused }) => setFromDateFocused(focused)}
                    displayFormat={FORMAT_STANDARD_DATE}
                    placeholder={formatMessage({ id: 'inputs.validFrom' })}
                    isOutsideRange={date => date.isAfter(moment(), 'day')}
                    renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                      <div className="d-flex justify-content-center">
                        <div className="mr-1">
                          <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                            {moment.months().map((label, value) => (
                              <option value={value} key={label}>
                                {label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="ml-1">
                          <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                            {yearListRender(
                              moment().format('YYYY'),
                              moment()
                                .add(2, 'year')
                                .format('YYYY')
                            )}
                          </select>
                        </div>
                      </div>
                    )}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-md-3">
              <Col md={{ size: 4, offset: 8 }}>
                <CustomButton block className="hok-dashboard-btn w-100" translationId="search" />
              </Col>
            </Row>
          </Form>
        </Search>
      )}
      <Row className="no-gutters">
        <Col>{children({ data: tickets, error, loadingTickets })}</Col>
      </Row>
    </>
  );
};
