import gql from 'graphql-tag';

export const GetContactFormSubjectsQuery = gql`
  query GetContactFormSubjects(
    $filter: getContactFormSubjectsFilterInput
    $order: getContactFormSubjectsSortFilterInput
    $skip: Int
    $take: Int
  ) {
    getContactFormSubjects(filter: $filter, order: $order, skip: $skip, take: $take) {
      totalCount
      queryCount
      contactFormSubjects {
        id
        subject
        active
      }
    }
  }
`;
