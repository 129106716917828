import gql from 'graphql-tag';

export const GetUserPoliciesQuery = gql`
  query GetUserPolicies(
    $filter: getInsurancePolicyFilterInput
    $order: getInsurancePolicySortFilterInput
    $userId: String
  ) {
    getInsurancePolicy(filter: $filter, order: $order, userId: $userId) {
      totalCount
      queryCount
      insurancePolicies {
        basicType
        product
        name
        productDefinition {
          group
        }
        status
        number
        date
        insuranceEnd
        insuranceStart
        assistance
        role
        item
        insuredItems
        insuredPeople
        premium
        totalDebt
        debtDue
      }
    }
  }
`;

export const GetUserPolicyGroupsQuery = gql`
  query GetUserPolicyGroups($filter: getInsurancePolicyFilterInput, $order: getInsurancePolicySortFilterInput) {
    getInsurancePolicy(filter: $filter, order: $order) {
      insurancePoliciesGroupedByProductGroup {
        type
        count
        countActive
        groupOrder
        insurancePolicies {
          basicType
          product
          name
          productDefinition {
            group
            code
            product
          }
          status
          number
          date
          insuranceStart
          insuranceEnd
          assistance
          role
          item
          insuredItems
          insuredPeople
          premium
          totalDebt
        }
      }
    }
  }
`;

export const GetUserAssistanceQuery = gql`
  query GetUserAssistance($filter: getInsurancePolicyFilterInput, $order: getInsurancePolicySortFilterInput) {
    getInsurancePolicy(filter: $filter, order: $order) {
      insurancePoliciesGroupedByAssistance {
        type
        count
        countActive
        groupOrder
        insurancePolicies {
          basicType
          product
          name
          status
          number
          date
          insuranceStart
          insuranceEnd
          role
          item
          insuredItems
          insuredPeople
          premium
          totalDebt
        }
      }
    }
  }
`;
