import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from '../../containers';
import { displayCurrencyOptions } from '../../utils/currencyDisplayFormat';
import { WSPayCards } from '../WSPayCards';
//import { currencyConversionEuro } from '../../utils/currencyConversion';
import { useSelector } from 'react-redux';
import { currencyDisplayUpdate } from '../CurrencyDisplay';

export const PaymentStep = ({ premiumAmount, policyNumber, nextStep, children, onClick, loaderProp }) => {
  const history = useHistory();
  const { formatNumber } = useIntl();

  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  return (
    <Row className="step3">
      <Col xs="12">
        {children}
        <Row>
          <Col xs="12">
            <div className="mt-4">
              <span className="text-nowrap">
                <span className="primary">
                  <FormattedMessage id="userMyPolicies.policyData.premium" />:
                </span>

              {currencyDataConfig.dualCurrencyDisplay === true && (
              <>
              <span className="secondary ml-3">
              {formatNumber(premiumAmount, displayCurrencyOptions)}
              {/* {' '}|{' '}
               {formatNumber((premiumAmount*7.5345), {style: 'currency',
                  currency: 'HRK',
                  currencySign: 'standard',
                  currencyDisplay: 'narrowSymbol'})} */}
              </span>
              </>)}

            {currencyDataConfig.dualCurrencyDisplay === false && (
            <>
              <span className="secondary ml-3">
              {formatNumber(premiumAmount, displayCurrencyOptions)}
              </span>
            </>
            )}

                {/* <span className="secondary ml-3">
                  {formatNumber(premiumAmount, displayCurrencyOptions)} | {currencyConversionEuro(premiumAmount*100)}
                </span> */}
              </span>
            </div>
          </Col>
        </Row>
        {/* <Row className="mt-4">
            <Col xs="12">
              <h2>Odaberite način plaćanja</h2>
            </Col>
          </Row> */}
        <Row className="d-flex flex-row justify-content-around mt-4">
          <Col className="text-center">
            <div className="hok-payment-container">
              <CustomButton
                className="hok-dashboard-btn-wide mx-auto d-inline-block"
                translationId="paymentMethodOnline"
                onClick={() => (typeof onClick === 'function' ? onClick() : history.push(nextStep))}
                loaderProp={loaderProp}
              />
              <WSPayCards />
            </div>
          </Col>
          {/* <Col>
              <CustomButton
                className="hok-dashboard-btn-wide mx-auto mt-4"
                translationId="paymentMethodProforma"
                onClick={() => history.push(ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_4)}
              />
            </Col> */}
        </Row>
      </Col>
    </Row>
  );
};
