import { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

const mapStateToProps = state => ({
  user: state.auth.user,
});

class ErrorLogging extends Component {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (this.props.user.id) {
        scope.setUser({ id: this.props.user.id, email: this.props.user.email });
      }
      Sentry.captureException(error);
    });
  }

  render() {
    return this.props.children;
  }
}

export default connect(mapStateToProps)(ErrorLogging);
