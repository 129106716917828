import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, InputGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import PhoneInput from 'react-phone-number-input';
import moment, { ISO_8601 } from 'moment';
import { get, debounce } from 'lodash-es';
import { CollapsibleContainer, CustomDashboardInput } from '../../containers';
import { USER_GENDER, USER_ACCOUNT_BUSINESS, USER_ACCOUNT_PRIVATE } from '../../constants/userDefs';
import { yearListRender, FORMAT_STANDARD_DATE } from '../../constants/dateFormatting';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { v4 } from 'uuid';

export const PolicyInsuredPersonInfo = ({
  additional = false,
  labelId,
  user,
  places,
  loadingPlaces,
  sameContracting = {},
  setSameContracting,
  sector = { value: USER_ACCOUNT_PRIVATE },
  setSector,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  businessName,
  setBusinessName,
  dateOfBirth,
  setDateOfBirth,
  sex,
  setSex,
  oib,
  setOIB,
  HZZONumber,
  setHZZONumber,
  showHzzo = true,
  address,
  setAddress,
  city,
  setCity,
  zip,
  setZip,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  invalid = false,
  yearList = yearListRender(),
  dateOutsideRange = date => date.isAfter(moment().subtract(18, 'year'), 'day'),
}) => {
  const { formatMessage } = useIntl();

  const [filteredPlaces, setFilteredPlaces] = useState([...places]);

  useEffect(() => {
    if (places.length > 0) {
      setFilteredPlaces([...places]);
    }
  }, [places]);

  const debouncedPlacesFilter = debounce(value => {
    const matchingPlaces = places.filter(place => place.pttNumber.startsWith(value));
    setFilteredPlaces(value ? matchingPlaces : [...places]);
    if (matchingPlaces.length === 1 && matchingPlaces[0].pttNumber === value) {
      setCity(city => ({ ...city, invalid: false, value: matchingPlaces[0].name }));
    } else {
      setCity(city => ({ ...city, invalid: false, value: '' }));
    }
  }, 500);

  const [dateOfBirthFocused, setDateOfBirthFocused] = useState(false);
  const userDOB = moment(user.dateOfBirth, ISO_8601, true).isValid ? moment(user.dateOfBirth, ISO_8601) : null;

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false }));
  };

  const renderHeader = () => {
    if (sameContracting.value) {
      return user.sector === USER_ACCOUNT_BUSINESS ? user.businessName : `${user.firstName} ${user.lastName}`;
    } else {
      return get(sector, 'value', USER_ACCOUNT_PRIVATE) === USER_ACCOUNT_BUSINESS
        ? businessName.value
        : `${firstName.value} ${lastName.value}`.trim() || formatMessage({ id: labelId });
    }
  };

  const fieldDisabled = !additional && sameContracting.value; /*&& user.sector !== USER_ACCOUNT_BUSINESS*/

  return (
    <>
      <h3 className="subtitle pl-4">
        <FormattedMessage id={labelId} />:
      </h3>
      <Row className="mt-2">
        <Col xs="12">
          <CollapsibleContainer title={renderHeader()} invalid={invalid}>
            {/* {!additional && (
              <FormGroup row className="mt-4">
                <Col className="d-flex flex-row" xs="12">
                  <CustomInput
                    className="hok-tac-checkbox"
                    id="same"
                    type="checkbox"
                    disabled={user.sector === USER_ACCOUNT_BUSINESS}
                    checked={user.sector !== USER_ACCOUNT_BUSINESS && sameContracting.value}
                    invalid={sameContracting.invalid}
                    onChange={event => inputHandler(setSameContracting, event.target.checked)}
                    label={'Osiguranik je isti ugovaratelju'}
                  />
                </Col>
                {sameContracting.invalid && (
                  <FormFeedback className="d-block pl-3">{sameContracting.error}</FormFeedback>
                )}
              </FormGroup>
            )} */}
            <FormGroup row>
              <Col sm="6" md="4">
                <Label for={additional ? 'additionalInsuredSector' : 'insuredSector'}>
                  <FormattedMessage id="inputs.sector" />
                </Label>
                <InputGroup>
                  <div className="hok-select">
                    <Input
                      type="select"
                      defaultValue={user.sector}
                      id={additional ? 'additionalInsuredSector' : 'insuredSector'}
                      disabled
                    >
                      <option value={USER_ACCOUNT_PRIVATE}>
                        {formatMessage({ id: `accountType.${USER_ACCOUNT_PRIVATE}` })}
                      </option>
                      <option value={USER_ACCOUNT_BUSINESS}>
                        {formatMessage({ id: `accountType.${USER_ACCOUNT_BUSINESS}` })}
                      </option>
                    </Input>
                  </div>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              {(sameContracting.value && user.sector === USER_ACCOUNT_BUSINESS) ||
              (!sameContracting.value && sector.value === USER_ACCOUNT_BUSINESS) ? (
                <Col sm="6" md="4">
                  <Label for={additional ? 'additionalInsuredBusinessName' : 'insuredBusinessName'}>
                    <FormattedMessage id="inputs.name" />
                  </Label>
                  <InputGroup>
                    <CustomDashboardInput
                      id={additional ? 'additionalInsuredBusinessName' : 'insuredBusinessName'}
                      value={!additional && sameContracting.value ? user.businessName : businessName.value}
                      fieldState={businessName}
                      readOnly={fieldDisabled}
                      onChange={event => inputHandler(setBusinessName, event.target.value)}
                    />
                  </InputGroup>
                </Col>
              ) : (
                <>
                  <Col sm="6" md="4">
                    <Label for={additional ? 'additionalInsuredFirstName' : 'insuredFirstName'}>
                      <FormattedMessage id="inputs.firstName" />
                    </Label>
                    <InputGroup>
                      <CustomDashboardInput
                        id={additional ? 'additionalInsuredFirstName' : 'insuredFirstName'}
                        value={!additional && sameContracting.value ? user.firstName : firstName.value}
                        fieldState={firstName}
                        readOnly={fieldDisabled}
                        onChange={event => inputHandler(setFirstName, event.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col sm="6" md="4">
                    <Label for={additional ? 'additionalInsuredLastName' : 'insuredLastName'}>
                      <FormattedMessage id="inputs.lastName" />
                    </Label>
                    <InputGroup>
                      <CustomDashboardInput
                        id={additional ? 'additionalInsuredLastName' : 'insuredLastName'}
                        value={!additional && sameContracting.value ? user.lastName : lastName.value}
                        fieldState={lastName}
                        readOnly={fieldDisabled}
                        onChange={event => inputHandler(setLastName, event.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col sm="6" md="4">
                    <Label for={additional ? 'additionalInsuredDateOfBirth' : 'insuredDateOfBirth'}>
                      <FormattedMessage id="inputs.dateOfBirth" />
                    </Label>
                    <InputGroup className={dateOfBirth.invalid ? 'invalid-date' : ''}>
                      <SingleDatePicker
                        hideKeyboardShortcutsPanel
                        date={!additional && sameContracting.value ? userDOB : dateOfBirth.value}
                        disabled={fieldDisabled}
                        onDateChange={date => inputHandler(setDateOfBirth, date)}
                        focused={dateOfBirthFocused}
                        onFocusChange={({ focused }) => setDateOfBirthFocused(focused)}
                        id={additional ? 'additionalInsuredDateOfBirth' : 'insuredDateOfBirth'}
                        showDefaultInputIcon
                        firstDayOfWeek={1}
                        numberOfMonths={1}
                        small
                        displayFormat={FORMAT_STANDARD_DATE}
                        placeholder={''}
                        isOutsideRange={dateOutsideRange}
                        initialVisibleMonth={() => dateOfBirth.value || moment().subtract(18, 'year')}
                        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                          <div className="d-flex justify-content-center">
                            <div className="mr-1">
                              <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                                {moment.months().map((label, value) => (
                                  <option value={value} key={label}>
                                    {label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="ml-1">
                              <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                                {yearList}
                              </select>
                            </div>
                          </div>
                        )}
                      />
                      {dateOfBirth.invalid && <FormFeedback className="d-block">{dateOfBirth.error}</FormFeedback>}
                    </InputGroup>
                  </Col>
                  <Col sm="6" md="4">
                    <Label for={additional ? 'additionalInsuredSex' : 'insuredSex'}>
                      <FormattedMessage id="inputs.sex" />
                    </Label>
                    <InputGroup>
                      <div className="hok-select">
                        <CustomDashboardInput
                          id={additional ? 'additionalInsuredSex' : 'insuredSex'}
                          type="select"
                          fieldState={sex}
                          disabled={fieldDisabled}
                          value={!additional && sameContracting.value ? user.sex : sex.value}
                          onChange={event => inputHandler(setSex, event.target.value)}
                        >
                          <option disabled value="">
                            {formatMessage({ id: 'inputs.sex' })}
                          </option>
                          {USER_GENDER.map(sex => (
                            <option key={sex.value} value={sex.value}>
                              {formatMessage({ id: sex.name })}
                            </option>
                          ))}
                        </CustomDashboardInput>
                      </div>
                    </InputGroup>
                  </Col>
                </>
              )}
              <Col sm="6" md="4">
                <Label for={additional ? 'additionalInsuredOib' : 'insuredOib'}>
                  <FormattedMessage id="inputs.OIB" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id={additional ? 'additionalInsuredOib' : 'insuredOib'}
                    value={!additional && sameContracting.value ? user.oib : oib.value}
                    fieldState={oib}
                    readOnly={fieldDisabled}
                    onChange={event => inputHandler(setOIB, event.target.value)}
                  />
                </InputGroup>
              </Col>
              {showHzzo && (
                <Col sm="6" md="4">
                  <Label for={additional ? 'additionalInsuredHZZONumber' : 'insuredHZZONumber'}>
                    <FormattedMessage id="inputs.HZZONumber" />
                  </Label>
                  <InputGroup>
                    <CustomDashboardInput
                      id={additional ? 'additionalInsuredHZZONumber' : 'insuredHZZONumber'}
                      value={HZZONumber.value}
                      fieldState={HZZONumber}
                      onChange={event => inputHandler(setHZZONumber, event.target.value)}
                    />
                  </InputGroup>
                </Col>
              )}
            </FormGroup>
            <FormGroup row>
              <Col sm="6" md="4">
                <Label for={additional ? 'additionalInsuredAddress' : 'insuredAddress'}>
                  <FormattedMessage id="inputs.address" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id={additional ? 'additionalInsuredAddress' : 'insuredAddress'}
                    value={!additional && sameContracting.value ? user.address : address.value}
                    fieldState={address}
                    readOnly={fieldDisabled}
                    onChange={event => inputHandler(setAddress, event.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for="zipCode">
                  <FormattedMessage id="inputs.zipCode" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id={additional ? 'additionalInsuredZip' : 'insuredZip'}
                    value={!additional && sameContracting.value ? user.zip : zip.value}
                    fieldState={zip}
                    readOnly={fieldDisabled}
                    onChange={event => {
                      event.persist();
                      debouncedPlacesFilter(event.target.value);
                      setZip(zip => ({ ...zip, invalid: false, value: event.target.value }));
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for={additional ? 'additionalInsuredCity' : 'insuredCity'}>
                  <FormattedMessage id="inputs.place" />
                </Label>
                <InputGroup>
                  <div className="hok-select">
                    <CustomDashboardInput
                      id={additional ? 'additionalInsuredCity' : 'insuredCity'}
                      type="select"
                      value={!additional && sameContracting.value ? user.city : city.value}
                      fieldState={city}
                      disabled={fieldDisabled}
                      onChange={event => {
                        const place = places.find(place => place.name === event.target.value);
                        setCity({ ...city, invalid: false, value: event.target.value });
                        place && setZip({ ...zip, invalid: false, value: place.pttNumber });
                      }}
                    >
                      <option disabled value="">
                        {formatMessage({ id: 'inputs.labels.selectPlace' })}
                      </option>
                      {loadingPlaces ? (
                        <option disabled value={city.value}>
                          {city.value}
                        </option>
                      ) : (
                        filteredPlaces.map(place => (
                          <option key={v4()} value={place.name}>
                            {place.name}
                          </option>
                        ))
                      )}
                    </CustomDashboardInput>
                  </div>
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for={additional ? 'additionalInsuredPhoneNumber' : 'insuredPhoneNumber'}>
                  <FormattedMessage id="inputs.phoneNumberShort" />
                </Label>
                <InputGroup>
                  {fieldDisabled ? (
                    <CustomDashboardInput
                      id={additional ? 'additionalInsuredPhoneNumber' : 'insuredPhoneNumber'}
                      value={!additional && sameContracting.value ? user.phoneNumber : phoneNumber.value}
                      fieldState={phoneNumber}
                      readOnly={fieldDisabled}
                      onChange={event => inputHandler(setPhoneNumber, event.target.value)}
                    />
                  ) : (
                    <CustomDashboardInput
                      id={additional ? 'additionalInsuredPhoneNumber' : 'insuredPhoneNumber'}
                      name="mobile phone number"
                      type="tel"
                      // If lenght is greater than 21 formatPhoneNumberIntl() function from
                      // "react-phone-number-input" goes wild and resets the value to an empty string
                      maxLength={21}
                      tag={PhoneInput}
                      //international={false}
                      defaultCountry="HR"
                      value={!additional && sameContracting.value ? user.phoneNumber : phoneNumber.value}
                      fieldState={phoneNumber}
                      numberInputProps={phoneNumber.invalid ? 'is-invalid' : ''}
                      onChange={phone => inputHandler(setPhoneNumber, formatPhoneNumber(phone))}
                    />
                  )}
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for={additional ? 'additionalInsuredEmail' : 'insuredEmail'}>
                  <FormattedMessage id="inputs.email" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id={additional ? 'additionalInsuredEmail' : 'insuredEmail'}
                    value={!additional && sameContracting.value ? user.email : email.value}
                    fieldState={email}
                    readOnly={fieldDisabled}
                    onChange={event => inputHandler(setEmail, event.target.value)}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
          </CollapsibleContainer>
        </Col>
      </Row>
    </>
  );
};
