import gql from 'graphql-tag';

export const UserRecoveryTokenCheckMutation = gql`
  mutation UserRecoveryTokenCheck($passwordResetToken: String!) {
    userPasswordRecoveryCheck(passwordResetToken: $passwordResetToken) {
      tokenValid
    }
  }
`;

export default UserRecoveryTokenCheckMutation;
