import { getLocalStorageToken } from '../constants/LocalStorageKeys';
import fetch from 'unfetch';
import { renderToaster, ERROR_TOASTER } from '../constants/toaster';
import { UPLOAD_FILE_SIZE_LIMIT } from '../constants/business';
export const imageUpload = image => {
  const formData = new FormData();

  formData.append('avatar', image);

  const token = getLocalStorageToken();

  return fetch(
    `${process.env.REACT_APP_SERVER_URL || 'https://dev.digitalna-poslovnica.hok-osiguranje.hr'}/upload/avatar`,
    {
      mode: 'cors',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  );
};

export const postFeaturedImageUpload = image => {
  const formData = new FormData();

  formData.append('featured-image', image);

  const token = getLocalStorageToken();

  return fetch(
    `${process.env.REACT_APP_SERVER_URL ||
      'https://dev.digitalna-poslovnica.hok-osiguranje.hr'}/upload/CMS/post/featured-image`,
    {
      mode: 'cors',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  );
};

export const claimDocumentUpload = async ({
  document,
  documentType,
  signedUrlsData,
  progressFunction,
  linkFunction,
  linkVars,
  onError,
  silentError = false,
}) => {
  const formData = getFormData(document, signedUrlsData);

  const xhr = new XMLHttpRequest();
  if (typeof progressFunction === 'function') {
    xhr.upload.onprogress = progressFunction;
  }
  xhr.open('POST', signedUrlsData.subdomainUrl);

  xhr.setRequestHeader('Accept', 'application/json');

  xhr.send(formData);
  if (typeof onError === 'function') {
    xhr.onerror = () => {
      if (!silentError) {
        renderToaster('errors.errorWhileUploadingFile', ERROR_TOASTER, { intlKey: true });
      }
      onError([]);
    };
  }
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 204) {
      linkFunction({
        variables: {
          ...linkVars,
          comment: '',
          files: [
            {
              name: document.name,
              key: signedUrlsData.resourceUrl,
              ...(documentType && { fileCategory: documentType }),
            },
          ],
        },
      });
    }
  };
};

export const getFormData = (fileToUpload, signedResource) => {
  const data = new FormData();

  data.append('key', signedResource.fields.key);
  data.append('x-amz-meta-tag', 'this-is-a-tag');
  data.append('Content-Type', fileToUpload.type);
  data.append('x-amz-server-side-encryption', 'AES256');
  data.append('X-Amz-Credential', signedResource.fields['X-Amz-Credential']);
  data.append('X-Amz-Algorithm', signedResource.fields['X-Amz-Algorithm']);
  data.append('X-Amz-Date', signedResource.fields['X-Amz-Date']);
  data.append('Policy', signedResource.fields.Policy);
  data.append('X-Amz-Signature', signedResource.fields['X-Amz-Signature']);
  data.append('success_action_status', '');
  data.append('file', fileToUpload);

  return data;
};

export const uploadFileToS3bucket = (subdomainUrl, data) => {
  return fetch(subdomainUrl, {
    mode: 'cors',
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: data,
  });
};

export const checkFileSize = size => {
  const maxSize = parseInt(process.env.REACT_APP_UPLOAD_FILE_SIZE_LIMIT, 10) || UPLOAD_FILE_SIZE_LIMIT;
  if (size > maxSize) {
    renderToaster('Prenesena datoteka je prevelika.', ERROR_TOASTER, { intlKey: true });
    return false;
  }
  return true;
};
