import gql from 'graphql-tag';

export const GetNewsQuery = gql`
  query GetNews($type: PostTypeEnum) {
    news(type: $type) {
      id
      title
      subtitle
      publishedAt
      featuredImageUrl
    }
  }
`;

export const GetNewsForPreviewQuery = gql`
  query GetNewsForPreview($type: PostTypeEnum, $id: Int) {
    news(type: $type, filter: { id: $id }) {
      id
      title
      subtitle
      publishedAt
      featuredImageUrl
      content
    }
  }
`;
