import { take, call, put, takeLatest, select } from 'redux-saga/effects';
import { client } from '../../config/apolloConfig';
import { get } from 'lodash-es';
import {
  USER_REGISTRATION,
  userRegistrationSuccess,
  userRegistrationFail,
  AUTH_TOKEN_LOADED,
  authTokenLoadedSuccess,
  USER_LOGIN,
  userLoginSuccess,
  userLoginFail,
  USER_RESEND_ACTIVATION_EMAIL,
  userResendActivationEmailSuccess,
  userResendActivationEmailFail,
  USER_ACCOUNT_ACTIVATION,
  userAccountActivationSuccess,
  userAccountActivationFail,
  USER_FORGOT_PASSWORD,
  userForgotPasswordSuccess,
  userForgotPasswordFail,
  USER_PASSWORD_RESET,
  userPasswordResetSuccess,
  userPasswordResetFail,
  USER_PASSWORD_RESET_TOKEN_CHECK,
  userPasswordResetTokenCheckFail,
} from '../actions/userAuthActions';
import { redirectToMaintenancePage } from '../actions/navigationActions';
import { RegisterUserMutation } from '../../operations/mutations/RegisterUserMutation';
import {
  getErrorObject,
  isMaintenanceInProgress,
  getSpecificError,
  UNKNOWN_REGISTRATION_FAIL,
  UNKNOWN_LOGIN_FAIL,
  UNKNOWN_ACCOUNT_ACTIVATION_FAIL,
  UNKNOWN_RESEND_ACTIVATION_EMAIL_FAIL,
  UNKNOWN_FORGOT_PASSWORD_FAIL,
  UNKNOWN_PASSWORD_RESET_FAIL,
  isAuthTokenInvalid,
  DUPLICATE_PHONE_NUMBER_DETECTED,
  DUPLICATE_EMAIL_DETECTED,
} from '../../constants/errorCodes';
import { setLocalStorageToken } from '../../constants/LocalStorageKeys';
import MeQuery from '../../operations/queries/MeQuery';
import LoginMutation from '../../operations/mutations/LoginMutation';
import ResendActivationEmailMutation from '../../operations/mutations/ResendActivationEmailMutation';
import ActivateUserAccountMutation from '../../operations/mutations/ActivateUserAccountMutation';
import UserPasswordRecoveryRequestMutation from '../../operations/mutations/UserPasswordRecoveryRequestMutation';
import UserPasswordResetMutation from '../../operations/mutations/UserPasswordResetMutation';
import UserRecoveryTokenCheckMutation from '../../operations/mutations/UserRecoveryTokenCheckMutation';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { adminLoginFailure } from '../actions/adminAuthActions';
import { googleAnalyticsEvent } from '../../utils/browser';

/**
 * User Registration
 */
function* userRegistrationWorker(payload) {
  try {
    const registerResult = yield call(() =>
      client.mutate({
        mutation: RegisterUserMutation,
        variables: payload,
      })
    );

    const userData = get(registerResult, 'data.registration', null);
    if (userData.token) {
      setLocalStorageToken(userData.token, 'setAuthToken');

      const profileResult = yield call(() =>
        client.query({
          query: MeQuery,
        })
      );

      const profile = get(profileResult, 'data.myData', {});
      profile.phoneNumber = formatPhoneNumber(profile.phoneNumber);

      googleAnalyticsEvent({ category: 'Korisnik', action: 'Registracija' });

      yield put(userRegistrationSuccess({ user: profile }));
    } else {
      yield put(userRegistrationFail({ errorMessage: getSpecificError(UNKNOWN_REGISTRATION_FAIL).message }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      if (errorObject.key === DUPLICATE_EMAIL_DETECTED || errorObject.key === DUPLICATE_PHONE_NUMBER_DETECTED) {
        yield put(userRegistrationFail({}));
        yield put(userLoginFail({ errorMessage: errorObject.message }));
      } else {
        yield put(userRegistrationFail({ errorMessage: errorObject.message }));
      }
    }
  }
}

export function* userRegistrationWatcher() {
  while (true) {
    const { payload } = yield take(USER_REGISTRATION);
    yield call(userRegistrationWorker, payload);
  }
}

/**
 * User Login
 */
function* userLoginUsernamePasswordWorker(payload) {
  try {
    const loginResult = yield call(() =>
      client.mutate({
        mutation: LoginMutation,
        variables: { username: payload.email, password: payload.password, doors: 'USER' },
      })
    );

    const token = get(loginResult, 'data.login.token');
    if (token) {
      setLocalStorageToken(token, 'attemptAdminLoginUsernamePassword');

      const profileResult = yield call(() =>
        client.query({
          query: MeQuery,
        })
      );

      const profile = get(profileResult, 'data.myData', {});
      profile.phoneNumber = formatPhoneNumber(profile.phoneNumber);

      googleAnalyticsEvent({ category: 'Korisnik', action: 'Uspjesna_Prijava' });
      yield put(userLoginSuccess({ user: profile, from: payload.from }));
    } else {
      yield put(userLoginFail({ errorMessage: getSpecificError(UNKNOWN_LOGIN_FAIL).message }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      yield put(userLoginFail({ errorMessage: errorObject.message }));
    }
  }
}

export function* userLoginUsernamePasswordWatcher() {
  /*
    ***THIS IS A BLOCKING CALL***
    It means that watchCheckout will ignore any ADMIN_LOGIN event until
    the current attemptAdminLoginUsernamePasswordWorker completes, either by success or by Error
  */
  while (true) {
    const { payload } = yield take(USER_LOGIN);
    yield call(userLoginUsernamePasswordWorker, payload);
  }
}

/**
 * Activate user account
 */
function* activateUserAccountWorker(action) {
  try {
    const activationResult = yield call(() =>
      client.mutate({
        mutation: ActivateUserAccountMutation,
        variables: action.payload,
      })
    );

    const activated = get(activationResult, 'data.activation.activated', false);
    if (activated) {
      yield put(userAccountActivationSuccess());
    } else {
      yield put(userAccountActivationFail({ errorMessage: getSpecificError(UNKNOWN_ACCOUNT_ACTIVATION_FAIL).message }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(userLoginFail({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      yield put(userAccountActivationFail({ errorMessage: errorObject.message }));
    }
  }
}

export function* activateUserAccountWatcher() {
  yield takeLatest(USER_ACCOUNT_ACTIVATION, activateUserAccountWorker);
}

/**
 * Resend user account activation email
 */
function* resendAccountActivationEmailWorker(action) {
  try {
    const resendResult = yield call(() =>
      client.mutate({
        mutation: ResendActivationEmailMutation,
        variables: { email: action.payload },
      })
    );

    const resendDone = get(resendResult, 'data.resendActivationCode.done', false);
    if (resendDone) {
      yield put(userResendActivationEmailSuccess());
    } else {
      yield put(
        userResendActivationEmailFail({ errorMessage: getSpecificError(UNKNOWN_RESEND_ACTIVATION_EMAIL_FAIL).message })
      );
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      yield put(userResendActivationEmailFail({ errorMessage: errorObject.message }));
    }
  }
}

export function* resendAccountActivationEmailWatcher() {
  yield takeLatest(USER_RESEND_ACTIVATION_EMAIL, resendAccountActivationEmailWorker);
}

/**
 * Send the user an email with a password reset link
 */
function* userForgotPasswordWorker(action) {
  try {
    const recoverRequestResult = yield call(() =>
      client.mutate({
        mutation: UserPasswordRecoveryRequestMutation,
        variables: { username: action.payload.email },
      })
    );

    const result = get(recoverRequestResult, 'data.userPasswordRecoveryRequest.requestCompleted', false);

    if (result) {
      yield put(userForgotPasswordSuccess());
    } else {
      yield put(userForgotPasswordFail({ errorMessage: getSpecificError(UNKNOWN_FORGOT_PASSWORD_FAIL).message }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      yield put(userForgotPasswordFail({ errorMessage: errorObject.message }));
    }
  }
}

export function* userForgotPasswordWatcher() {
  yield takeLatest(USER_FORGOT_PASSWORD, userForgotPasswordWorker);
}

/**
 * Check if user password reset token is valid and based on it
 *  allow access to the password reset form
 */
function* userPasswordResetTokenCheckWorker(action) {
  try {
    const resetTokenCheckResult = yield call(() =>
      client.mutate({
        mutation: UserRecoveryTokenCheckMutation,
        variables: { passwordResetToken: action.payload },
      })
    );

    const result = get(resetTokenCheckResult, 'data.userPasswordRecoveryCheck.tokenValid', false);

    if (result) {
      yield;
    } else {
      yield put(userForgotPasswordFail());
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      yield put(userPasswordResetTokenCheckFail());
    }
  }
}

export function* userPasswordResetTokenCheckWatcher() {
  yield takeLatest(USER_PASSWORD_RESET_TOKEN_CHECK, userPasswordResetTokenCheckWorker);
}

/**
 * Set new user password
 */
function* userPasswordResetWorker(action) {
  try {
    const passwordResetResult = yield call(() =>
      client.mutate({
        mutation: UserPasswordResetMutation,
        variables: action.payload,
      })
    );

    const result = get(passwordResetResult, 'data.userPasswordRecovery.passwordUpdated', false);
    if (result) {
      yield put(userPasswordResetSuccess());
    } else {
      yield put(userPasswordResetFail({ errorMessage: getSpecificError(UNKNOWN_PASSWORD_RESET_FAIL).message }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      yield put(userPasswordResetFail({ errorMessage: errorObject.message }));
    }
  }
}

export function* userPasswordResetWatcher() {
  yield takeLatest(USER_PASSWORD_RESET, userPasswordResetWorker);
}

/**
 * Requesting user profile data with JWT token
 * To be executed after every page refresh
 */
function* loadUserProfileFromTokenWorker() {
  try {
    const profileResult = yield call(() =>
      client.query({
        query: MeQuery,
      })
    );

    const profile = get(profileResult, 'data.myData', {});
    profile.phoneNumber = formatPhoneNumber(profile.phoneNumber);

    yield put(authTokenLoadedSuccess({ user: profile }));
  } catch (e) {
    const errorObject = getErrorObject(e);
    const path = yield select(state => state.router.location.pathname);
    const regex = /^\/admin/;
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
      // If token expired/error fetching user profile while location is in admin dashboard redirect to admin login
      // Otherwise its assumed location is in user dashboard and the user is redirected to home/login page
    } else {
      if (regex.test(path)) {
        yield put(adminLoginFailure({ errorMessage: errorObject.message }));
      } else {
        yield put(userLoginFail({ errorMessage: errorObject.message }));
      }
    }
  }
}

export function* loadUserProfileFromTokenWatcher() {
  yield takeLatest(AUTH_TOKEN_LOADED, loadUserProfileFromTokenWorker);
}
