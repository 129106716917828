import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HokMapIcon } from '../../../img/icons/hok-map.svg';

export const OfficeCards = ({ offices, titleId }) => {
  return (
    <>
      <Row className="no-gutters mt-4 mb-2">
        <Col>
          <h5 className="color-red font-weight-bold">
            <FormattedMessage id={titleId} />
          </h5>
        </Col>
      </Row>
      <Row x-ms-format-detection="none">
        {offices.length < 1 && (
          <Col>
            <p className="hok-text-dark-500">
              <FormattedMessage id="generalInfoPage.noOffices" />
            </p>
          </Col>
        )}
        {offices.map(office => (
          <div key={office.name} className="hok-office-card-container">
            <Card className="hok-office-card">
              <CardBody>
                <div className="d-flex justify-content-brtween">
                  <div className="hok-office-card-title">
                    <h5>{office.name}</h5>
                    <p>{office.address}</p>
                  </div>
                  <a
                    className="hok-google-maps-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${office.lat},${office.lng}`}>
                    <HokMapIcon className="svg-red" width="24" />
                  </a>
                </div>
                {(office.telephone || office.fax) && (
                  <p>
                    {office.telephone && (
                      <>
                        <span className="font-weight-500">tel: </span>
                        <span className="hok-phone-number">{office.telephone}</span>
                        <br />
                      </>
                    )}
                    {office.fax && (
                      <>
                        <span className="font-weight-500">fax: </span>
                        <span className="hok-phone-number">{office.fax}</span>
                      </>
                    )}
                  </p>
                )}
                <p className={!office.workingHours ? 'invisible' : ''}>{office.workingHours}</p>
                {!office.telephone && !office.fax && <div className="invisible" />}
              </CardBody>
            </Card>
          </div>
        ))}
      </Row>
    </>
  );
};
