import gql from 'graphql-tag';

export const GetLegalDocumentsQuery = gql`
  query GetLegalDocuments($filter: getLegalDocumentsFilterInput, $order: getLegalDocumentsSortFilterInput) {
    getLegalDocuments(filter: $filter, order: $order) {
      documents {
        documentId
        documentName
        required
        type
        link
        content
        active
      }
    }
  }
`;
