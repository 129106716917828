import React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../Spinner';

export const CustomButton = ({
  type = 'submit',
  color = 'primary',
  block = true,
  loaderProp = false,
  translationId = 'submit',
  className = null,
  ...rest
}) => (
  <Button
    className={'hok-button' + (className ? ' ' + className : '')}
    type={type}
    color={color}
    block={block}
    disabled={loaderProp || (rest.disabled && true)}
    onClick={event => (loaderProp || rest.disabled || typeof rest.onClick !== 'function' ? null : rest.onClick(event))}
    {...rest}>
    {loaderProp ? <Spinner size="sm" /> : <FormattedMessage id={`buttons.${translationId}`} />}
  </Button>
);
