import React from 'react';
import { FormattedMessage } from 'react-intl';

import LoginPage from '../views/client/Login';
import { LogoutPage } from '../views/client/Logout';
import RegisterPage from '../views/client/Register';
import PasswordRecoveryPage from '../views/client/PasswordRecovery';
import PasswordExpiredPage from '../views/client/PasswordExpired';
import ActivateAccountPage from '../views/client/ActivateAccount';
import UserTypeSelection from '../views/client/UserTypeSelection';
import UserAccountInfo from '../views/client/UserAccountInfo';
import RegistrationComplete from '../views/client/RegistrationComplete';
import DashboardPage from '../views/client/Dashboard';
import { TravelStepper } from '../views/client/UserNewTravelPolicy';
import { Step3InsuredPeople } from '../views/client/UserNewTravelPolicy/Step3InsuredPeople';
import { HealthStepper } from '../views/client/UserNewHealthPolicy';
import { ExpressStepper } from '../views/client/UserNewExpressInsurance';
import { AOStepper } from '../views/client/UserNewCarInsurance';
import { UserProfileInfo } from '../views/client/UserProfile/UserProfileInfo';
import { UserProfileAccount } from '../views/client/UserProfile/UserProfileAccount';
// import { UserProfileNotifications } from '../views/client/UserProfile/UserProfileNotifications';
import { UserProfileAdvanced } from '../views/client/UserProfile/UserProfileAdvanced';
import { UserPolicies } from '../views/client/UserPolicies';
import { UserPoliciesHealthInsurance } from '../views/client/UserPoliciesHealthInsurance';
import { HealthInsuranceExaminations } from '../views/client/UserPoliciesHealthInsurance/HealthInsuranceExaminations';
import { HealthInsuranceMedical } from '../views/client/UserPoliciesHealthInsurance/HealthInsuranceMedical';
import { UserPoliciesTravelInsurance } from '../views/client/UserPoliciesTravelInsurance';
import { UserPoliciesCarInsurance } from '../views/client/UserPoliciesCarInsurance';
import { UserPoliciesAssistance } from '../views/client/UserPoliciesAssistance';
import { ExpressAssistance } from '../views/client/UserPoliciesAssistance/ExpressAssistance';
import { UserPoliciesKaskoInsurance } from '../views/client/UserPoliciesKaskoInsurance';
import { UserPoliciesPropertyInsurance } from '../views/client/UserPoliciesPropertyInsurance';
import { MojDomInsurance } from '../views/client/UserPoliciesPropertyInsurance/MojDomInsurance';
import { ApartmentInsurance } from '../views/client/UserPoliciesPropertyInsurance/ApartmentInsurance';
import { OtherPropertyInsurance } from '../views/client/UserPoliciesPropertyInsurance/OtherPropertyInsurance';
import { UserMyPolicies } from '../views/client/UserMyPolicies';
import { GenericMyPolicies } from '../views/client/UserMyPolicies/GenericMyPolicies';
import { UserNewsListPage } from '../views/client/UserNewsList';
import { UserNewsPreviewPage } from '../views/client/UserNewsPreview';
import { UserMyInvoices } from '../views/client/UserMyInvoices';
import { UserMyDamageClaims } from '../views/client/UserMyDamageClaims';
import { SingleDamageClaim } from '../views/client/UserMyDamageClaims/SingleDamageClaim';
import { ProductEnquiry } from '../views/client/ProductEnquiry';
import { AssistancePage } from '../views/client/Assistance';
import { GenericAssistancePage } from '../views/client/Assistance/GenericAssistance';
import { FAQPage } from '../views/client/FAQ';
import { SingleFAQPage } from '../views/client/FAQ/SingleFAQPage';
import { CustomerSupportFormPage } from '../views/client/CustomerSupport';
import { GeneralInfoPage } from '../views/client/GeneralInfo';
import { SubmitDamageClaimPage } from '../views/client/SubmitDamageClaim';
import { GenericDamageClaimReportPage } from '../views/client/SubmitDamageClaim/GenericDamageClaimReport';
import { ReportDamageForm } from '../views/client/SubmitDamageClaim/ReportDamageForm';
import { QuickDamageReport } from '../views/client/QuickDamageReport';
import { QuickClaimPreview } from '../views/client/QuickDamageReport/QuickClaimPreview';
import { QuickClaimPreviewData } from '../views/client/QuickDamageReport/QuickClaimPreviewData';
import { QuickClaimSubmit } from '../views/client/QuickDamageReport/QuickClaimSubmit';
import { QuickClaimSubmitForm } from '../views/client/QuickDamageReport/QuickClaimSubmitForm';
import { SupportInfoPage } from '../views/client/SupportInfo';
import { UserTicketingPage } from '../views/client/UserMyTickets';
import { SingleTicket } from '../views/client/UserMyTickets/SingleTicket';
import { PaymentProcessing } from '../views/client/PaymentProcessing';
import { TermsOfServicePrivatePage } from '../views/client/TermsOfService/TermsOfServicePrivatePage';
import { TermsOfServicePublicPage } from '../views/client/TermsOfService/TermsOfServicePublicPage';
import { PaymentCompleteStatus } from '../views/client/PaymentCompleteStatus';
import { MobileNewsPostPreview } from '../views/mobile/MobileNewsPostPreview';
import { TermsOfService } from '../views/mobile/TermsOfService';
import MaintenancePage from '../views/Maintenance';

import { adminRequireAuth, userRequireAuth } from '../utils/auth';
import { isAdmin, anyValidRole } from '../constants/roles';

import { STEP_1, STEP_2, STEP_3, STEP_4 } from './business';

export const ROUTE_TO_MAINTENANCE_PAGE = '/maintenance';

export const ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE = '/payment/WSPay';
export const ROUTE_TO_USER_PAYMENT_PROCESSING_STATUS_PAGE = `${ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE}/status`;

export const ROUTE_TO_USER_LOGIN_PAGE = '/';
export const ROUTE_TO_USER_LOGOUT_PAGE = '/logout';
export const ROUTE_TO_USER_REGISTER_PAGE = '/register';
export const ROUTE_TO_GUEST_CONTACT = '/contact';
export const ROUTE_TO_GUEST_TERMS_OF_SERVICE = '/tos';
export const ROUTE_TO_USER_QUICK_DAMAGE_REPORT = '/quick-damage-claim';
export const ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT = `${ROUTE_TO_USER_QUICK_DAMAGE_REPORT}/submit`;
export const ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW = `${ROUTE_TO_USER_QUICK_DAMAGE_REPORT}/preview`;
export const ROUTE_TO_USER_DASHBOARD_PAGE = '/dashboard';
// Helper Route - only used for creating subroutes, /info is default
export const ROUTE_TO_USER_PROFILE_PAGE = '/user-profile';
export const ROUTE_TO_USER_PROFILE_ACCOUNT_PAGE = `${ROUTE_TO_USER_PROFILE_PAGE}/account`;
export const ROUTE_TO_USER_PROFILE_ADDITIONAL_DATA_PAGE = `${ROUTE_TO_USER_PROFILE_PAGE}/advanced`;
// export const ROUTE_TO_USER_PROFILE_NOTIF_SETTINGS_PAGE = `${ROUTE_TO_USER_PROFILE_PAGE}/notifications`;

export const ROUTE_TO_USER_PASSWORD_RECOVERY = '/password-recovery/:passwordResetToken';
export const ROUTE_TO_USER_PASSWORD_EXPIRED = '/password-expired';
export const ROUTE_TO_ACTIVATE_ACCOUNT_PAGE = '/activate';
export const ROUTE_TO_ACTIVATE_ACCOUNT_WITH_LINK_PAGE =
  '/activate/:userId([0-9]+)/:activationCode([0-9]{6})/:activationToken([a-zA-Z0-9]+)';
export const ROUTE_TO_USER_SELECTION_PAGE = '/user-info';
export const ROUTE_TO_USER_ACCOUNT_INFO_PAGE = `${ROUTE_TO_USER_SELECTION_PAGE}/:accountType(private|business)`;
export const ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE = '/registration-complete';
export const ROUTE_TO_USER_POLICIES_PAGE = '/products';
export const ROUTE_TO_USER_REPORT_DAMAGE = '/report-damage';
export const ROUTE_TO_USER_PRODUCT_ENQUIRY = `${ROUTE_TO_USER_POLICIES_PAGE}/enquiry-form`;
export const ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE = `${ROUTE_TO_USER_POLICIES_PAGE}/health-insurance`;
export const ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_MEDICAL_PAGE = `${ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE}/medical`;
export const ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_EXAMINATIONS_PAGE = `${ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE}/examinations`;
export const ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE = `${ROUTE_TO_USER_POLICIES_PAGE}/travel-insurance`;
export const ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE = `${ROUTE_TO_USER_POLICIES_PAGE}/assistance`;
export const ROUTE_TO_USER_POLICIES_EXPRESS_ASSISTANCE_PAGE = `${ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE}/hok-express`;
export const ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE = `${ROUTE_TO_USER_POLICIES_PAGE}/car-insurance`;
export const ROUTE_TO_USER_POLICIES_KASKO_INSURANCE_PAGE = `${ROUTE_TO_USER_POLICIES_PAGE}/kasko`;
export const ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE = `${ROUTE_TO_USER_POLICIES_PAGE}/property-insurance`;
export const ROUTE_TO_USER_POLICIES_PROPERTY_MOJ_DOM_PAGE = `${ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE}/moj-dom`;
export const ROUTE_TO_USER_POLICIES_PROPERTY_APARTMENT_PAGE = `${ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE}/apartment`;
export const ROUTE_TO_USER_POLICIES_PROPERTY_OTHER_PAGE = `${ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE}/other`;
export const ROUTE_TO_USER_MY_POLICIES_PAGE = '/my-policies';
export const ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE = `${ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE}/new`;
export const ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_2 = `${ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE}/step2`;
export const ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3 = `${ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE}/step3`;
export const ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_4 = `${ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE}/step4`;

export const ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE = `${ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE}/new`;
export const ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_2 = `${ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE}/step2`;
export const ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_3 = `${ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE}/step3`;
export const ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_4 = `${ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE}/step4`;

export const ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE = `${ROUTE_TO_USER_POLICIES_EXPRESS_ASSISTANCE_PAGE}/new`;
export const ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_2 = `${ROUTE_TO_USER_POLICIES_EXPRESS_ASSISTANCE_PAGE}/step2`;
export const ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_3 = `${ROUTE_TO_USER_POLICIES_EXPRESS_ASSISTANCE_PAGE}/step3`;
export const ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_4 = `${ROUTE_TO_USER_POLICIES_EXPRESS_ASSISTANCE_PAGE}/step4`;

export const ROUTE_TO_USER_NEW_AO_POLICY_PAGE = `${ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE}/new`;
export const ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_2 = `${ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE}/step2`;
export const ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_3 = `${ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE}/step3`;
export const ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_4 = `${ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE}/step4`;

export const ROUTE_TO_USER_NEWS_PAGE = '/news';
export const ROUTE_TO_USER_NEWS_PREVIEW_PAGE = `${ROUTE_TO_USER_NEWS_PAGE}/:postId([0-9]+)/:postTitle?`;
export const ROUTE_TO_USER_MY_INVOICES_PAGE = '/my-invoices';
export const ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE = '/my-damage-claims';
const ROUTE_TO_USER_SPECIFIC_DAMAGE_CLAIM_PAGE = `${ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE}/:claimNumber`;
const ROUTE_TO_USER_SPECIFIC_DAMAGE_CLAIM_DOCUMENTS_PAGE = `${ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE}/:claimNumber/documentation`;

export const ROUTE_TO_USER_ASSISTANCE_PAGE = '/assistance';
export const ROUTE_TO_USER_HOME_ASSISTANCE_PAGE = `${ROUTE_TO_USER_ASSISTANCE_PAGE}/home`;
export const ROUTE_TO_USER_TRAVEL_ASSISTANCE_PAGE = `${ROUTE_TO_USER_ASSISTANCE_PAGE}/travel`;
export const ROUTE_TO_USER_HR_ASSISTANCE_PAGE = `${ROUTE_TO_USER_ASSISTANCE_PAGE}/hr`;
export const ROUTE_TO_USER_RENTACAR_ASSISTANCE_PAGE = `${ROUTE_TO_USER_ASSISTANCE_PAGE}/rent-a-car`;
export const ROUTE_TO_USER_EXPRESS_ASSISTANCE_PAGE = `${ROUTE_TO_USER_ASSISTANCE_PAGE}/express`;

export const ROUTE_TO_USER_TICKETING_PAGE = '/ticketing';

export const ROUTE_TO_USER_FAQS_PAGE = '/faq';
export const ROUTE_TO_USER_SINGLE_FAQ_PAGE = `${ROUTE_TO_USER_FAQS_PAGE}/:faqId/:faqTitle?`;
export const ROUTE_TO_USER_SUPPORT_PAGE = '/support';
export const ROUTE_TO_USER_TERMS_OF_SERVICE_PAGE = '/terms-of-service';
export const ROUTE_TO_GENERAL_INFO_PAGE = '/info';

export const ROUTE_TO_ADMIN_DASHBOARD_PAGE = '/admin/dashboard';
export const ROUTE_TO_ADMIN_LOGIN_PAGE = '/admin';
export const ROUTE_TO_ADMIN_LOGOUT_PAGE = '/admin/logout';
export const ROUTE_TO_ADMIN_ERROR_PAGE = '/admin/error';
export const ROUTE_TO_ADMIN_USERS_LIST_PAGE = '/admin/users';
export const ROUTE_TO_ADMIN_CREATE_USER_PAGE = `${ROUTE_TO_ADMIN_USERS_LIST_PAGE}/create`;
export const ROUTE_TO_EMPLOYEE_PROFILE_PAGE = '/admin/user-profile';
export const ROUTE_TO_EMPLOYEE_PROFILE_ACCOUNT_PAGE = `${ROUTE_TO_EMPLOYEE_PROFILE_PAGE}/account`;
export const ROUTE_TO_EMPLOYEE_PROFILE_ADDITIONAL_DATA_PAGE = `${ROUTE_TO_EMPLOYEE_PROFILE_PAGE}/advanced`;
// export const ROUTE_TO_EMPLOYEE_PROFILE_NOTIF_SETTINGS_PAGE = `${ROUTE_TO_EMPLOYEE_PROFILE_PAGE}/notifications`;

export const ROUTE_TO_ADMIN_EDIT_USER_PAGE = `${ROUTE_TO_ADMIN_USERS_LIST_PAGE}/edit`;
const ROUTE_TO_ADMIN_EDIT_USER_ACCOUNT_PAGE = `${ROUTE_TO_ADMIN_EDIT_USER_PAGE}/:userId([0-9]+)/:pageName?`;
const ROUTE_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_PAGE = `${ROUTE_TO_ADMIN_EDIT_USER_PAGE}/:userId([0-9]+)/damage-claims/:claimNumber`;
const ROUTE_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_DOCS_PAGE = `${ROUTE_TO_ADMIN_EDIT_USER_PAGE}/:userId([0-9]+)/damage-claims/:claimNumber/documentation`;

export const ROUTE_TO_ADMIN_EMPLOYEES_LIST_PAGE = '/admin/employees';
export const ROUTE_TO_ADMIN_EMPLOYEE_CREATE_PAGE = `${ROUTE_TO_ADMIN_EMPLOYEES_LIST_PAGE}/create`;
export const ROUTE_TO_ADMIN_EDIT_EMPLOYEE_PAGE = `${ROUTE_TO_ADMIN_EMPLOYEES_LIST_PAGE}/edit`;
const ROUTE_TO_ADMIN_EDIT_EMPLOYEE_ACCOUNT_PAGE = `${ROUTE_TO_ADMIN_EDIT_EMPLOYEE_PAGE}/:userId([0-9]+)/:pageName?`;
const ROUTE_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_PAGE = `${ROUTE_TO_ADMIN_EDIT_EMPLOYEE_PAGE}/:userId([0-9]+)/damage-claims/:claimNumber`;
const ROUTE_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_DOCS_PAGE = `${ROUTE_TO_ADMIN_EDIT_EMPLOYEE_PAGE}/:userId([0-9]+)/damage-claims/:claimNumber/documentation`;

export const ROUTE_TO_ADMIN_TICKETING_PAGE = '/admin/ticketing';
const ROUTE_TO_ADMIN_SINGLE_TICKET_PAGE = `${ROUTE_TO_ADMIN_TICKETING_PAGE}/:ticketId`;
const ROUTE_TO_ADMIN_SINGLE_TICKET_DOCUMENTS_PAGE = `${ROUTE_TO_ADMIN_TICKETING_PAGE}/:ticketId/documentation`;

export const ROUTE_TO_ADMIN_NEWS_PAGE = '/admin/news';
export const ROUTE_TO_ADMIN_NEWS_PREVIEW_PAGE = `${ROUTE_TO_ADMIN_NEWS_PAGE}/:postId([0-9]+)`;
export const ROUTE_TO_ADMIN_EDIT_POST = `${ROUTE_TO_ADMIN_NEWS_PAGE}/edit`;
export const ROUTE_TO_ADMIN_CREATE_POST = `${ROUTE_TO_ADMIN_NEWS_PAGE}/create`;

export const ROUTE_TO_ADMIN_TRANSACTIONS_PAGE = '/admin/user-transactions';
const ROUTE_TO_ADMIN_TRANSACTION_PREVIEW_PAGE = `${ROUTE_TO_ADMIN_TRANSACTIONS_PAGE}/:transactionId`;

// Mobile routes
export const ROUTE_TO_MOBILE_TOS_PAGE = '/tos';
export const ROUTE_TO_MOBILE_POST_PREVIEW_PAGE = '/post';

const Admin = (Component, props = {}) => adminRequireAuth(Component, isAdmin, ROUTE_TO_ADMIN_DASHBOARD_PAGE, props);
const User = (Component, props = {}) => userRequireAuth(Component, anyValidRole, ROUTE_TO_USER_LOGIN_PAGE, props);

// const DashboardPage = React.lazy(() => retry(() => import('../views/client/Dashboard')));
const AdminLogin = import('../views/admin/AdminLogin');
const AdminLoginPage = React.lazy(() => AdminLogin);

const AdminLogout = import('../views/admin/AdminLogout');
const AdminLogoutPage = React.lazy(() => AdminLogout);

const AdminDashboard = import('../views/admin/AdminDashboard');
const AdminDashboardPage = React.lazy(() => AdminDashboard);

const AdminError = import('../views/admin/AdminError');
const AdminErrorPage = React.lazy(() => AdminError);

const AdminUsersList = import('../views/admin/AdminUserList');
const AdminUsersListPage = React.lazy(() => AdminUsersList.then(module => ({ default: module.AdminUsersListPage })));

const AdminCreateUser = import('../views/admin/AdminUserCreate');
const AdminCreateUserPage = React.lazy(() => AdminCreateUser.then(module => ({ default: module.AdminCreateUserPage })));

const AdminEditUser = import('../views/admin/AdminUserEdit');
const AdminEditUserPage = React.lazy(() => AdminEditUser.then(module => ({ default: module.AdminEditUserPage })));

const AdminNewsList = import('../views/admin/AdminPostList');
const AdminNewsListPage = React.lazy(() => AdminNewsList.then(module => ({ default: module.AdminNewsListPage })));

const AdminNewsPreview = import('../views/admin/AdminPostPreview');
const AdminNewsPreviewPage = React.lazy(() =>
  AdminNewsPreview.then(module => ({ default: module.AdminNewsPreviewPage }))
);

const AdminEditPost = import('../views/admin/AdminPostEdit');
const AdminEditPostPage = React.lazy(() => AdminEditPost.then(module => ({ default: module.AdminEditPostPage })));

const AdminCreatePost = import('../views/admin/AdminPostCreate');
const AdminCreatePostPage = React.lazy(() => AdminCreatePost.then(module => ({ default: module.AdminCreatePostPage })));

const AdminUserTransactions = import('../views/admin/AdminUserTransactionList');
const AdminUserTransactionsPage = React.lazy(() =>
  AdminUserTransactions.then(module => ({ default: module.AdminUserTransactionsPage }))
);

const AdminUserTransactionPreview = import('../views/admin/AdminUserTransactionPreview');
const AdminUserTransactionPreviewPage = React.lazy(() =>
  AdminUserTransactionPreview.then(module => ({ default: module.AdminUserTransactionPreviewPage }))
);

const AdminEmployeesList = import('../views/admin/AdminEmployeesList');
const AdminEmployeesListPage = React.lazy(() =>
  AdminEmployeesList.then(module => ({ default: module.AdminEmployeesListPage }))
);

const AdminEmployeeCreate = import('../views/admin/AdminEmployeeCreate');
const AdminEmployeeCreatePage = React.lazy(() =>
  AdminEmployeeCreate.then(module => ({ default: module.AdminEmployeeCreatePage }))
);

const AdminEditEmployee = import('../views/admin/AdminEmployeeEdit');
const AdminEditEmployeePage = React.lazy(() =>
  AdminEditEmployee.then(module => ({ default: module.AdminEditEmployeePage }))
);

const AdminTicketing = import('../views/admin/AdminTicketList');
const AdminTicketingPage = React.lazy(() => AdminTicketing.then(module => ({ default: module.AdminTicketingPage })));

const AdminSingleTicket = import('../views/admin/AdminTicketEdit');
const AdminSingleTicketPage = React.lazy(() =>
  AdminSingleTicket.then(module => ({ default: module.AdminSingleTicketPage }))
);

const AdminEditSingleClaim = import('../components/AdminEditUserTabs/AdminEditSingleClaim');
const AdminEditSingleClaimPage = React.lazy(() =>
  AdminEditSingleClaim.then(module => ({ default: module.AdminEditSingleClaim }))
);

// const LoginPage = React.lazy(() => retry(() => import('../views/client/Login')));
// const LogoutPage = React.lazy(() => retry(() => import('../views/client/Logout').then(module => ({ default: module.LogoutPage })));
// const RegisterPage = React.lazy(() => retry(() => import('../views/client/Register')));
// const PasswordRecoveryPage = React.lazy(() => retry(() => import('../views/client/PasswordRecovery')));
// const PasswordExpiredPage = React.lazy(() => retry(() => import('../views/client/PasswordExpired')));
// const ActivateAccountPage = React.lazy(() => retry(() => import('../views/client/ActivateAccount')));
// const UserTypeSelection = React.lazy(() => retry(() => import('../views/client/UserTypeSelection')));
// const UserAccountInfo = React.lazy(() => retry(() => import('../views/client/UserAccountInfo')));
// const RegistrationComplete = React.lazy(() => retry(() => import('../views/client/RegistrationComplete')));
// const TravelStepper = React.lazy(() =>
//   retry(() => import('../views/client/UserNewTravelPolicy').then(module => ({ default: module.TravelStepper })))
// );
// const Step3InsuredPeople = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserNewTravelPolicy/Step3InsuredPeople').then(module => ({
//       default: module.Step3InsuredPeople,
//     }))
//   )
// );
// const HealthStepper = React.lazy(() =>
//   retry(() => import('../views/client/UserNewHealthPolicy').then(module => ({ default: module.HealthStepper })))
// );
// const ExpressStepper = React.lazy(() =>
//   retry(() => import('../views/client/UserNewExpressInsurance').then(module => ({ default: module.ExpressStepper })))
// );
// const AOStepper = React.lazy(() =>
//   retry(() => import('../views/client/UserNewCarInsurance').then(module => ({ default: module.AOStepper })))
// );
// const UserProfileInfo = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserProfile/UserProfileInfo').then(module => ({ default: module.UserProfileInfo }))
//   )
// );
// const UserProfileAccount = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserProfile/UserProfileAccount').then(module => ({ default: module.UserProfileAccount }))
//   )
// );
// const UserProfileAdvanced = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserProfile/UserProfileAdvanced').then(module => ({ default: module.UserProfileAdvanced }))
//   )
// );

// const UserPolicies = React.lazy(() =>
//   retry(() => import('../views/client/UserPolicies').then(module => ({ default: module.UserPolicies })))
// );
// const UserPoliciesHealthInsurance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesHealthInsurance').then(module => ({
//       default: module.UserPoliciesHealthInsurance,
//     }))
//   )
// );
// const HealthInsuranceExaminations = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesHealthInsurance/HealthInsuranceExaminations').then(module => ({
//       default: module.HealthInsuranceExaminations,
//     }))
//   )
// );
// const HealthInsuranceMedical = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesHealthInsurance/HealthInsuranceMedical').then(module => ({
//       default: module.HealthInsuranceMedical,
//     }))
//   )
// );
// const UserPoliciesTravelInsurance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesTravelInsurance').then(module => ({
//       default: module.UserPoliciesTravelInsurance,
//     }))
//   )
// );
// const UserPoliciesCarInsurance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesCarInsurance').then(module => ({ default: module.UserPoliciesCarInsurance }))
//   )
// );
// const UserPoliciesAssistance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesAssistance').then(module => ({ default: module.UserPoliciesAssistance }))
//   )
// );
// const ExpressAssistance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesAssistance/ExpressAssistance').then(module => ({
//       default: module.ExpressAssistance,
//     }))
//   )
// );
// const UserPoliciesKaskoInsurance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesKaskoInsurance').then(module => ({
//       default: module.UserPoliciesKaskoInsurance,
//     }))
//   )
// );
// const UserPoliciesPropertyInsurance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesPropertyInsurance').then(module => ({
//       default: module.UserPoliciesPropertyInsurance,
//     }))
//   )
// );
// const MojDomInsurance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesPropertyInsurance/MojDomInsurance').then(module => ({
//       default: module.MojDomInsurance,
//     }))
//   )
// );
// const ApartmentInsurance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesPropertyInsurance/ApartmentInsurance').then(module => ({
//       default: module.ApartmentInsurance,
//     }))
//   )
// );
// const OtherPropertyInsurance = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserPoliciesPropertyInsurance/OtherPropertyInsurance').then(module => ({
//       default: module.OtherPropertyInsurance,
//     }))
//   )
// );
// const UserMyPolicies = React.lazy(() =>
//   retry(() => import('../views/client/UserMyPolicies').then(module => ({ default: module.UserMyPolicies })))
// );
// const GenericMyPolicies = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserMyPolicies/GenericMyPolicies').then(module => ({ default: module.GenericMyPolicies }))
//   )
// );
// const UserNewsListPage = React.lazy(() =>
//   retry(() => import('../views/client/UserNewsList').then(module => ({ default: module.UserNewsListPage })))
// );
// const UserNewsPreviewPage = React.lazy(() =>
//   retry(() => import('../views/client/UserNewsPreview').then(module => ({ default: module.UserNewsPreviewPage })))
// );
// const UserMyInvoices = React.lazy(() =>
//   retry(() => import('../views/client/UserMyInvoices').then(module => ({ default: module.UserMyInvoices })))
// );
// const UserMyDamageClaims = React.lazy(() =>
//   retry(() => import('../views/client/UserMyDamageClaims').then(module => ({ default: module.UserMyDamageClaims })))
// );
// const SingleDamageClaim = React.lazy(() =>
//   retry(() =>
//     import('../views/client/UserMyDamageClaims/SingleDamageClaim').then(module => ({
//       default: module.SingleDamageClaim,
//     }))
//   )
// );
// const ProductEnquiry = React.lazy(() =>
//   retry(() => import('../views/client/ProductEnquiry').then(module => ({ default: module.ProductEnquiry })))
// );
// const AssistancePage = React.lazy(() =>
//   retry(() => import('../views/client/Assistance').then(module => ({ default: module.AssistancePage })))
// );
// const TicketingPage = React.lazy(() =>
//   retry(() => import('../views/client/UserMyTickets').then(module => ({ default: module.UserTicketingPage })))
// );
// const SingleTicketPage = React.lazy(() =>
//   retry(() => import('../views/client/UserMyTickets/SingleTicket').then(module => ({ default: module.SingleTicket })))
// );
// const GenericAssistancePage = React.lazy(() =>
//   retry(() =>
//     import('../views/client/Assistance/GenericAssistance').then(module => ({ default: module.GenericAssistancePage }))
//   )
// );
// const FAQPage = React.lazy(() =>
//   retry(() => import('../views/client/FAQ').then(module => ({ default: module.FAQPage })))
// );
// const SingleFAQPage = React.lazy(() =>
//   retry(() => import('../views/client/FAQ/SingleFAQPage').then(module => ({ default: module.SingleFAQPage })))
// );
// const CustomerSupportFormPage = React.lazy(() =>
//   retry(() =>
//     import('../views/client/CustomerSupport').then(module => ({
//       default: module.CustomerSupportFormPage,
//     }))
//   )
// );
// const GeneralInfoPage = React.lazy(() =>
//   retry(() => import('../views/client/GeneralInfo').then(module => ({ default: module.GeneralInfoPage })))
// );
// const SubmitDamageClaimPage = React.lazy(() =>
//   retry(() => import('../views/client/SubmitDamageClaim').then(module => ({ default: module.SubmitDamageClaimPage })))
// );
// const GenericDamageClaimReportPage = React.lazy(() =>
//   retry(() =>
//     import('../views/client/SubmitDamageClaim/GenericDamageClaimReport').then(module => ({
//       default: module.GenericDamageClaimReportPage,
//     }))
//   )
// );
// const ReportDamageForm = React.lazy(() =>
//   retry(() =>
//     import('../views/client/SubmitDamageClaim/ReportDamageForm').then(module => ({ default: module.ReportDamageForm }))
//   )
// );
// // const QuickDamageReport = React.lazy(() =>
// //   import('../views/client/QuickDamageReport').then(module => ({ default: module.QuickDamageReport }))
// // );
// // const QuickClaimPreview = React.lazy(() =>
// //   import('../views/client/QuickDamageReport/QuickClaimPreview').then(module => ({ default: module.QuickClaimPreview }))
// // );
// // const QuickClaimPreviewData = React.lazy(() =>
// //   import('../views/client/QuickDamageReport/QuickClaimPreviewData').then(module => ({
// //     default: module.QuickClaimPreviewData,
// //   }))
// // );
// // const QuickClaimSubmit = React.lazy(() =>
// //   import('../views/client/QuickDamageReport/QuickClaimSubmit').then(module => ({ default: module.QuickClaimSubmit }))
// // );
// // const SupportInfoPage = React.lazy(() =>
// //   import('../views/client/SupportInfo').then(module => ({ default: module.SupportInfoPage }))
// // );
// // const QuickClaimSubmitForm = React.lazy(() =>
// //   import('../views/client/QuickDamageReport/QuickClaimSubmitForm').then(module => ({
// //     default: module.QuickClaimSubmitForm,
// //   }))
// // );

// const MaintenancePage = React.lazy(() => retry(() => import('../views/Maintenance')));

// const MobileNewsPostPreview = React.lazy(() =>
//   retry(() =>
//     import('../views/mobile/MobileNewsPostPreview').then(module => ({
//       default: module.MobileNewsPostPreview,
//     }))
//   )
// );

// const TermsOfService = React.lazy(() =>
//   retry(() =>
//     import('../views/mobile/TermsOfService').then(module => ({
//       default: module.TermsOfService,
//     }))
//   )
// );

// const PaymentProcessing = React.lazy(() =>
//   retry(() =>
//     import('../views/client/PaymentProcessing').then(module => ({
//       default: module.PaymentProcessing,
//     }))
//   )
// );

// const PaymentCompleteStatus = React.lazy(() =>
//   retry(() =>
//     import('../views/client/PaymentCompleteStatus').then(module => ({
//       default: module.PaymentCompleteStatus,
//     }))
//   )
// );

export const mobileRoutes = [
  {
    type: 'public',
    path: ROUTE_TO_MOBILE_TOS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.tos" />,
    component: <TermsOfService />,
  },
  {
    type: 'public',
    path: `${ROUTE_TO_MOBILE_POST_PREVIEW_PAGE}/:postId([0-9]+)/:postTitle?`,
    exact: true,
    name: <FormattedMessage id="routes.news" />,
    component: <MobileNewsPostPreview />,
  },
];

export const routes = [
  {
    type: 'public',
    path: ROUTE_TO_MAINTENANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.maintenance" />,
    component: <MaintenancePage />,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_DASHBOARD_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.home" />,
    isRouteInDashboard: true,
    component: User(DashboardPage),
    gaPageviewName: '/Naslovnica',
  },
  {
    type: 'public',
    path: `${ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE}/:confirmationCode`,
    exact: true,
    name: <FormattedMessage id="routes.paymentProcessing" />,
    component: <PaymentProcessing />,
  },
  {
    type: 'public',
    path: `${ROUTE_TO_USER_PAYMENT_PROCESSING_STATUS_PAGE}/:status(success|error|cancel)`,
    exact: true,
    name: <FormattedMessage id="routes.paymentProcessing" />,
    component: <PaymentCompleteStatus />,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_PROFILE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myProfile" />,
    isRouteInDashboard: true,
    component: User(UserProfileInfo),
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_PROFILE_ACCOUNT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.accountInfo" />,
    isRouteInDashboard: true,
    component: User(UserProfileAccount),
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_PROFILE_ADDITIONAL_DATA_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.additionalData" />,
    isRouteInDashboard: true,
    component: User(UserProfileAdvanced),
  },
  // {
  //   type: 'private',
  //   path: ROUTE_TO_USER_PROFILE_NOTIF_SETTINGS_PAGE,
  //   exact: true,
  //   name: <FormattedMessage id="routes.notificationSettings" />,
  //   isRouteInDashboard: true,
  //   component: User(UserProfileNotifications),
  // },
  {
    type: 'auth',
    path: ROUTE_TO_USER_LOGIN_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.login" />,
    component: LoginPage,
    gaPageviewName: '/Prijava',
  },
  {
    type: 'public',
    path: ROUTE_TO_GUEST_CONTACT,
    exact: true,
    name: <FormattedMessage id="routes.customerSupport" />,
    component: <SupportInfoPage />,
  },
  {
    type: 'public',
    path: ROUTE_TO_GUEST_TERMS_OF_SERVICE,
    exact: true,
    name: <FormattedMessage id="routes.termsOfService" />,
    component: <TermsOfServicePublicPage />,
  },
  {
    type: 'public',
    path: ROUTE_TO_USER_QUICK_DAMAGE_REPORT,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: <QuickDamageReport />,
  },
  {
    type: 'public',
    path: ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: <QuickClaimSubmit />,
  },
  {
    type: 'public',
    path: `${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/:damageReportStep/:damageReportId?`,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: <QuickClaimSubmitForm />,
  },
  {
    type: 'public',
    path: ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: <QuickClaimPreview />,
  },
  {
    type: 'public',
    path: `${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW}/:damageClaimCode`,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: <QuickClaimPreviewData />,
  },
  {
    type: 'public',
    path: `${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW}/:damageClaimCode/documentation`,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: <QuickClaimPreviewData />,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_LOGOUT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.logout" />,
    component: User(LogoutPage),
  },
  {
    type: 'private',
    path: ROUTE_TO_ACTIVATE_ACCOUNT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.activateAccount" />,
    component: User(ActivateAccountPage),
  },
  {
    type: 'public',
    path: ROUTE_TO_ACTIVATE_ACCOUNT_WITH_LINK_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.activateAccount" />,
    component: <ActivateAccountPage />,
  },
  {
    type: 'public',
    path: ROUTE_TO_USER_PASSWORD_RECOVERY,
    exact: true,
    name: <FormattedMessage id="routes.passwordRecovery" />,
    component: <PasswordRecoveryPage />,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_PASSWORD_EXPIRED,
    exact: true,
    name: <FormattedMessage id="routes.passwordRecovery" />,
    component: User(PasswordExpiredPage),
  },
  {
    type: 'auth',
    path: ROUTE_TO_USER_REGISTER_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.register" />,
    component: RegisterPage,
    gaPageviewName: '/Registracija',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_SELECTION_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.userSelection" />,
    component: User(UserTypeSelection),
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_ACCOUNT_INFO_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.userAccountInfo" />,
    component: User(UserAccountInfo),
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.registrationComplete" />,
    component: User(RegistrationComplete),
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.policies" />,
    component: User(UserPolicies),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_PRODUCT_ENQUIRY,
    exact: true,
    name: <FormattedMessage id="routes.policies" />,
    component: User(ProductEnquiry),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.healthInsurance" />,
    component: User(UserPoliciesHealthInsurance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Zdravstveno',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_MEDICAL_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.healthInsurance" />,
    component: User(HealthInsuranceMedical),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_EXAMINATIONS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.healthInsurance" />,
    component: User(HealthInsuranceExaminations),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.travelInsurance" />,
    component: User(UserPoliciesTravelInsurance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Putno',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.assistance" />,
    component: User(UserPoliciesAssistance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Asistencije',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_EXPRESS_ASSISTANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.expressAssistance" />,
    component: User(ExpressAssistance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Asistencije_HokExpress',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.carLiability" />,
    component: User(UserPoliciesCarInsurance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Autoodgovornost',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_KASKO_INSURANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.kaskoInsurance" />,
    component: User(UserPoliciesKaskoInsurance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Kasko',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.propertyInsurance" />,
    component: User(UserPoliciesPropertyInsurance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Imovina',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_PROPERTY_MOJ_DOM_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.propertyInsurance" />,
    component: User(MojDomInsurance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Imovina_MojDom',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_PROPERTY_APARTMENT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.propertyInsurance" />,
    component: User(ApartmentInsurance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Imovina_HokApartman',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_POLICIES_PROPERTY_OTHER_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.propertyInsurance" />,
    component: User(OtherPropertyInsurance),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Imovina_OstalaOsiguranja',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_REPORT_DAMAGE,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: User(SubmitDamageClaimPage),
    isRouteInDashboard: true,
    gaPageviewName: '/PrijavaŠtete',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_REPORT_DAMAGE}/:damageClaimType`,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: User(GenericDamageClaimReportPage),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_REPORT_DAMAGE}/:damageClaimType/:damageReportStep/:damageReportId?`,
    exact: true,
    name: <FormattedMessage id="routes.reportDamage" />,
    component: User(ReportDamageForm),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_MY_POLICIES_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myPolicies" />,
    component: User(UserMyPolicies),
    isRouteInDashboard: true,
    gaPageviewName: '/MojePolice',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE}/:contractingId?`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(TravelStepper, { step: STEP_1, title: 'inputs.offer' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Putno_Step1',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_2}/:contractingId`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(TravelStepper, { step: STEP_2, title: 'userMyPolicies.insurerData' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Putno_Step2',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3}/:contractingId`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(TravelStepper, { step: STEP_3, title: 'misc.paymentMethod' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Putno_Step3',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3}/:contractingId/insured-people`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(Step3InsuredPeople, { step: STEP_3, title: 'misc.paymentMethod' }),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_4}/:confirmationCode`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(TravelStepper, { step: STEP_4, title: 'misc.theEnd', cancelButton: false }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Putno_Step4',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE}/:contractingId?`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(HealthStepper, { step: STEP_1, title: 'inputs.offer' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Zdravstveno_Step1',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_2}/:contractingId`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(HealthStepper, { step: STEP_2, title: 'userMyPolicies.insurerData' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Zdravstveno_Step2',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_3}/:contractingId`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(HealthStepper, { step: STEP_3, title: 'misc.paymentMethod' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Zdravstveno_Step3',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_4}/:confirmationCode`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(HealthStepper, { step: STEP_4, title: 'misc.theEnd', cancelButton: false }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Zdravstveno_Step4',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE}/:contractingId?`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(ExpressStepper, { step: STEP_1, title: 'inputs.offer' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Asistencije_HokExpress_Step1',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_2}/:contractingId`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(ExpressStepper, { step: STEP_2, title: 'userMyPolicies.insurerData' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Asistencije_HokExpress_Step2',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_3}/:contractingId`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(ExpressStepper, { step: STEP_3, title: 'misc.paymentMethod' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Asistencije_HokExpress_Step3',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_4}/:confirmationCode`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(ExpressStepper, { step: STEP_4, title: 'misc.theEnd', cancelButton: false }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Asistencije_HokExpress_Step4',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_AO_POLICY_PAGE}/:contractingId?`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(AOStepper, { step: STEP_1, title: 'userMyPolicies.offer' }),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_2}/:contractingId`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(AOStepper, { step: STEP_2, title: 'userMyPolicies.additionalRisksTitle' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Autoodgovornost_UgovoriPolicu_Step2',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_3}/:contractingId`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(AOStepper, { step: STEP_3, title: 'misc.paymentMethod' }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Autoodgovornost_UgovoriPolicu_Step3',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_4}/:confirmationCode`,
    exact: true,
    name: <FormattedMessage id="routes.myPoliciesNewPolicy" />,
    component: User(AOStepper, { step: STEP_4, title: 'misc.theEnd', cancelButton: false }),
    isRouteInDashboard: true,
    gaPageviewName: '/Proizvodi_Autoodgovornost_UgovoriPolicu_Step4',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_MY_POLICIES_PAGE}/:insuranceType/:policyNumber?`,
    exact: true,
    name: <FormattedMessage id="routes.myPolicies" />,
    component: User(GenericMyPolicies),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_MY_INVOICES_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myInvoices" />,
    component: User(UserMyInvoices),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myDamages" />,
    component: User(UserMyDamageClaims),
    isRouteInDashboard: true,
    gaPageviewName: '/MojeŠtete',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_SPECIFIC_DAMAGE_CLAIM_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myDamages" />,
    component: User(SingleDamageClaim),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_SPECIFIC_DAMAGE_CLAIM_DOCUMENTS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myDamages" />,
    component: User(SingleDamageClaim),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_ASSISTANCE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.assistance" />,
    component: User(AssistancePage),
    isRouteInDashboard: true,
    gaPageviewName: '/Asistencija',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_ASSISTANCE_PAGE}/:assistanceType/:assistancePolicyId?`,
    exact: true,
    name: <FormattedMessage id="routes.assistance" />,
    component: User(GenericAssistancePage),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_FAQS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.FAQ" />,
    component: User(FAQPage),
    isRouteInDashboard: true,
    gaPageviewName: '/FAQ',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_SINGLE_FAQ_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.FAQ" />,
    component: User(SingleFAQPage),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_SUPPORT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.customerSupport" />,
    component: User(CustomerSupportFormPage),
    isRouteInDashboard: true,
    gaPageviewName: '/KorisničkaPodrška_PošaljiUpit',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_TERMS_OF_SERVICE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.termsOfService" />,
    component: User(TermsOfServicePrivatePage),
    isRouteInDashboard: true,
    gaPageviewName: '/UvjetiKorištenja',
  },
  {
    type: 'private',
    path: ROUTE_TO_GENERAL_INFO_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.generalInfo" />,
    component: User(GeneralInfoPage),
    isRouteInDashboard: true,
    gaPageviewName: '/Info',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_NEWS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.newsList" />,
    component: User(UserNewsListPage),
    isRouteInDashboard: true,
    gaPageviewName: '/Novosti',
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_NEWS_PREVIEW_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.postPreview" />,
    component: User(UserNewsPreviewPage),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: ROUTE_TO_USER_TICKETING_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.ticketing" />,
    component: User(UserTicketingPage),
    isRouteInDashboard: true,
    gaPageviewName: '/MojiZahtjevi',
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_TICKETING_PAGE}/:ticketId`,
    exact: true,
    name: <FormattedMessage id="routes.ticketing" />,
    component: User(SingleTicket),
    isRouteInDashboard: true,
  },
  {
    type: 'private',
    path: `${ROUTE_TO_USER_TICKETING_PAGE}/:ticketId/documentation`,
    exact: true,
    name: <FormattedMessage id="routes.ticketing" />,
    component: User(SingleTicket),
    isRouteInDashboard: true,
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_DASHBOARD_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminDashboard" />,
    component: Admin(AdminDashboardPage),
  },
  {
    type: 'adminAuth',
    path: ROUTE_TO_ADMIN_LOGIN_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminLogin" />,
    component: AdminLoginPage,
    isRouteInDashboard: false,
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_LOGOUT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminLogout" />,
    component: AdminLogoutPage,
    isRouteInDashboard: false,
  },
  {
    type: 'public',
    path: ROUTE_TO_ADMIN_ERROR_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminError" />,
    component: <AdminErrorPage />,
  },
  {
    type: 'admin',
    path: ROUTE_TO_EMPLOYEE_PROFILE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myProfile" />,
    component: Admin(UserProfileInfo),
  },
  {
    type: 'admin',
    path: ROUTE_TO_EMPLOYEE_PROFILE_ACCOUNT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.accountInfo" />,
    isRouteInDashboard: true,
    component: Admin(UserProfileAccount),
  },
  {
    type: 'admin',
    path: ROUTE_TO_EMPLOYEE_PROFILE_ADDITIONAL_DATA_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.additionalData" />,
    isRouteInDashboard: true,
    component: Admin(UserProfileAdvanced),
  },
  // {
  //   type: 'admin',
  //   path: ROUTE_TO_EMPLOYEE_PROFILE_NOTIF_SETTINGS_PAGE,
  //   exact: true,
  //   name: <FormattedMessage id="routes.notificationSettings" />,
  //   isRouteInDashboard: true,
  //   component: Admin(UserProfileNotifications),
  // },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_USERS_LIST_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminUsersList" />,
    component: Admin(AdminUsersListPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_CREATE_USER_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminCreateUser" />,
    component: Admin(AdminCreateUserPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_EDIT_USER_ACCOUNT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminEditUser" />,
    component: Admin(AdminEditUserPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myDamages" />,
    component: Admin(AdminEditSingleClaimPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_DOCS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myDamages" />,
    component: Admin(AdminEditSingleClaimPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_EMPLOYEES_LIST_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminEmployeesList" />,
    component: Admin(AdminEmployeesListPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_EMPLOYEE_CREATE_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminEmployeeCreate" />,
    component: Admin(AdminEmployeeCreatePage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_EDIT_EMPLOYEE_ACCOUNT_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminEditUser" />,
    component: Admin(AdminEditEmployeePage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myDamages" />,
    component: Admin(AdminEditSingleClaimPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_DOCS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.myDamages" />,
    component: Admin(AdminEditSingleClaimPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_TICKETING_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.ticketing" />,
    component: Admin(AdminTicketingPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_SINGLE_TICKET_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.ticketing" />,
    component: Admin(AdminSingleTicketPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_SINGLE_TICKET_DOCUMENTS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.ticketing" />,
    component: Admin(AdminSingleTicketPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_NEWS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.adminNewsList" />,
    component: Admin(AdminNewsListPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_NEWS_PREVIEW_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.postPreview" />,
    component: Admin(AdminNewsPreviewPage),
  },
  {
    type: 'admin',
    path: `${ROUTE_TO_ADMIN_EDIT_POST}/:postId([0-9]+)`,
    exact: true,
    name: <FormattedMessage id="routes.adminEditPost" />,
    component: Admin(AdminEditPostPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_CREATE_POST,
    exact: true,
    name: <FormattedMessage id="routes.adminCreatePost" />,
    component: Admin(AdminCreatePostPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_TRANSACTIONS_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.userTransactions" />,
    component: Admin(AdminUserTransactionsPage),
  },
  {
    type: 'admin',
    path: ROUTE_TO_ADMIN_TRANSACTION_PREVIEW_PAGE,
    exact: true,
    name: <FormattedMessage id="routes.userTransactions" />,
    component: Admin(AdminUserTransactionPreviewPage),
  },
  ...mobileRoutes,
];
