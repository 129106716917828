import React from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Stepper } from 'react-form-stepper';
import { ROUTE_TO_USER_QUICK_DAMAGE_REPORT } from '../../../../constants/routes';
import { ArrowLinkButton } from '../../../../containers';
import { SupportInfoLink } from '../../SupportInfo/SupportInfoLink';

export const StepperWrapper = ({
  cancelLink = ROUTE_TO_USER_QUICK_DAMAGE_REPORT,
  title = 'locale',
  translationBase,
  children,
  steps = [{}, {}],
  activeStep = 0,
}) => {
  return (
    <Row className="no-gutters">
      <Col>
        <Row className="no-gutters mb-5">
          <ArrowLinkButton row={false} mdSize="6" colOffset="0" text="buttons.giveUp" linkTo={cancelLink} />
          <Col xs="6" className="text-right">
            <SupportInfoLink unwrapped />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col sm={{ size: 6, offset: 3 }}>
                    <Stepper steps={steps} activeStep={activeStep} styleConfig={{ size: '36px' }} hideConnectors />
                  </Col>
                </Row>
                {title && (
                  <Row className="mt-4 mb-2 ml-1">
                    <h3>
                      <FormattedMessage id={`userReportDamagePage.${title}`} />:
                    </h3>
                  </Row>
                )}
              </CardHeader>
              <CardBody>{children}</CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
