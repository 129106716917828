import React, { Component } from 'react';
import { FormGroup, Row, Col, Form, Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash-es';
import { LoginAndRegisterSelector, CustomInputField, CustomButton } from '../../../containers';
import { validate } from '../../../utils/validation';
import {
  ROUTE_TO_USER_LOGIN_PAGE,
  ROUTE_TO_USER_REGISTER_PAGE,
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT,
} from '../../../constants/routes';
import ForgotPasswordModal from '../ForgotPasswordModal';
import { userLogin, clearUserErrorMessage } from '../../../redux/actions/userAuthActions';
import logoShort from '../../../img/logo-short.png';
import { ReactComponent as HokPasswordIcon } from '../../../img/icons/hok-password.svg';
import { ReactComponent as HokEmailIcon } from '../../../img/icons/hok-email.svg';
import { AdFormIFrame } from '../../../components/AdFormIframe';

const mapStateToProps = state => ({
  intl: state.intl,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  userLogin: loginData => dispatch(userLogin(loginData)),
  clearUserErrorMessage: payload => dispatch(clearUserErrorMessage(payload)),
});

class Login extends Component {
  state = {
    isOpen: false,
    isFormValid: false,
    submitted: false,
    formData: {
      email: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.email" />,
        validation: {
          required: true,
        },
      },
      password: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.password" />,
        validation: {
          required: true,
        },
      },
    },
  };

  componentWillUnmount() {
    this.clearErrorOnValueChange();
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  removeFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: false } },
    });
  };

  addFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: true } },
    });
  };

  inputHandler = (field, value) => {
    this.clearErrorOnValueChange();
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: {
          ...this.state.formData[field],
          value: value,
        },
      },
    });
  };

  loginUser = event => {
    event.preventDefault();
    let formDataObject = { ...this.state.formData };
    let isFormValid = true;
    /* eslint-disable no-unused-vars */
    for (let field in formDataObject) {
      const validation = validate(formDataObject[field]);
      formDataObject[field] = {
        ...formDataObject[field],
        value:
          typeof formDataObject[field] === 'string' ? formDataObject[field].value.trim() : formDataObject[field].value,
        invalid: validation.invalid,
        error: validation.errors[0],
      };
      isFormValid = isFormValid && !validation.invalid;
    }
    /* eslint-enable no-unused-vars */

    this.setState({ isFormValid, submitted: true, formData: formDataObject }, () => {
      if (isFormValid) {
        const payloadObject = {};

        for (let inputObject in this.state.formData) {
          if (inputObject === 'repeatPassword') continue;
          if (this.state.formData[inputObject].value) {
            payloadObject[inputObject] = this.state.formData[inputObject].value;
          }
        }

        if (get(this.props, 'location.state.from', null)) {
          payloadObject.from = this.props.location.state.from;
        }

        // Login action
        this.props.userLogin(payloadObject);
        this.setState({ submitted: false });
      }
    });
  };

  clearErrorOnValueChange = () => {
    if (get(this.props, 'auth.login.errorMessage', null)) {
      this.props.clearUserErrorMessage('login');
    }
  };

  render() {
    const navlinksArray = [
      { path: ROUTE_TO_USER_LOGIN_PAGE, translationId: 'login', exact: true },
      { path: ROUTE_TO_USER_REGISTER_PAGE, translationId: 'register', exact: true },
    ];

    return (
      <>
        <LoginAndRegisterSelector navLinks={navlinksArray} showContactSupportLink donwloadAppLinks negativeBotMargin>
          <AdFormIFrame title="Login" />
          <Row className="no-gutters px-4 my-5 my-md-4">
            <Col xs={12} sm={{ offset: 2, size: 8 }} md={{ offset: 1, size: 10 }} lg={{ offset: 2, size: 8 }}>
              <Row className="mb-4 mb-md-0 mb-lg-4">
                <Col className="text-center">
                  <img className="img-fluid logo-short" src={logoShort} alt="HOK logo" />
                </Col>
              </Row>
              {get(this.props, 'auth.login.errorMessage', null) && (
                <Alert color="danger" role="alert">
                  {this.props.auth.login.errorMessage}
                </Alert>
              )}
              <Form onSubmit={this.loginUser}>
                <FormGroup row>
                  <Col xs="12">
                    <CustomInputField
                      id="email"
                      name="email"
                      placeholderId="emailOrphoneNumber"
                      fieldState={this.state.formData.email}
                      Icon={HokEmailIcon}
                      formSubmitted={this.state.submitted}
                      type="text"
                      maxLength={255}
                      onChange={event => this.inputHandler('email', event.target.value)}
                      onFocus={() => this.addFocus('email')}
                      onBlur={() => this.removeFocus('email')}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12">
                    <CustomInputField
                      id="password"
                      name="password"
                      fieldState={this.state.formData.password}
                      Icon={HokPasswordIcon}
                      formSubmitted={this.state.submitted}
                      type="password"
                      maxLength={55}
                      onChange={event => this.inputHandler('password', event.target.value)}
                      onFocus={() => this.addFocus('password')}
                      onBlur={() => this.removeFocus('password')}
                    />
                  </Col>
                </FormGroup>
                <Row className="mt-4">
                  <Col xs={12} className="text-center">
                    <p className="hok-modal-toggler" onClick={this.toggle}>
                      <FormattedMessage id="loginPage.forgottenPassword" />
                    </p>
                  </Col>
                </Row>
                <FormGroup row className="no-gutters mt-4 mb-0">
                  <Col xs="12">
                    <CustomButton loaderProp={get(this.props, 'auth.login.inProgress', false)} translationId="login" />
                  </Col>
                </FormGroup>
                <Row className="no-gutters">
                  <Col xs={{ size: 10, offset: 1 }}>
                    <hr />
                  </Col>
                </Row>
                <FormGroup row className="no-gutters mt-0">
                  <Col xs="12">
                    <CustomButton
                      type="button"
                      outline
                      translationId="quickDamageReport"
                      onClick={() => this.props.history.push(ROUTE_TO_USER_QUICK_DAMAGE_REPORT)}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </LoginAndRegisterSelector>
        <ForgotPasswordModal toggle={this.toggle} isOpen={this.state.isOpen} />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
