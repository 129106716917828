import { ReactComponent as HokAoIcon } from '../img/products/hok-ao.svg';
import { ReactComponent as HokKaskoIcon } from '../img/products/hok-kasko.svg';
import { ReactComponent as HokMdIcon } from '../img/products/hok-md.svg';
import { ReactComponent as HokZoIcon } from '../img/products/hok-zo.svg';
import { ReactComponent as HokOoIcon } from '../img/products/hok-oo.svg';
import { ReactComponent as HokPzoIcon } from '../img/products/hok-pzo.svg';
import { ReactComponent as HokAssistanceIcon } from '../img/products/hok-assistance.svg';
import { ReactComponent as HokNezgodaIcon } from '../img/products/hok-nezgoda.svg';
import { ReactComponent as HokOdgovornostIcon } from '../img/products/hok-odgovornost.svg';
import { ReactComponent as HokOtherIcon } from '../img/products/hok-other.svg';
import { ReactComponent as HokOVozaraIcon } from '../img/products/hok-ovozara.svg';
import { ReactComponent as HokVesselsIcon } from '../img/products/hok-plovila.svg';
import { ReactComponent as HokTransportIcon } from '../img/products/hok-transport.svg';

import { ReactComponent as HokAutoAsisstIcon } from '../img/assistance/hok-auto-assistance.svg';
import { ReactComponent as HokHomeAssistIcon } from '../img/assistance/hok-home-assistance.svg';
import { ReactComponent as HokTravelAssistIcon } from '../img/assistance/hok-travel-assistance.svg';
import { ReactComponent as HokExpressAssistIcon } from '../img/products/hok-express.svg';
import { ReactComponent as HokRentACarAsisstIcon } from '../img/assistance/hok-rent-a-car.svg';

export const POST_TYPE_NEWS = 'NEWS';

export const DOC_TYPE_PRE_CONTRACT = 'PREDUGOVORNA_DOKUMENTACIJA';
export const DOC_TYPE_INSURAMCE_TERMS = 'UVJETI_OSIGURANJA';
export const DOC_TYPE_ALL = 'DOC_TYPE_ALL';

//Steps
export const STEP_1 = 'STEP_1';
export const STEP_2 = 'STEP_2';
export const STEP_3 = 'STEP_3';
export const STEP_4 = 'STEP_4';
export const STEP_5 = 'STEP_5';

export const VEHICLE_DATA = 'VEHICLE_DATA';
export const VEHICLE_DATA_AO = 'VEHICLE_DATA_AO';
export const VEHICLE_DATA_AO_SHORT = 'VEHICLE_DATA_AO_SHORT';
export const INSURED_PERSON_DATA = 'INSURED_PERSON_DATA';

//Terms and conditions
export const TAC = 'TAC';
export const TOS = 'TOS';

//Policy types
export const POLICY_KASKO = 'Auto Kasko';
export const POLICY_HEALTH = 'Zdravstveno';
export const POLICY_ATTORNEY = 'Odvjetnička odgovornost';
export const POLICY_LIABILITY = 'Odgovornost';
export const POLICY_DRIVER_LIABILITY = 'Odgovornost vozara';
export const POLICY_PROPERTY = 'Imovina';
export const POLICY_ASSISTANCE = 'Asistencija';
export const POLICY_HEALTH_TRAVEL = 'Putno osiguranje';
export const POLICY_CAR_LIABILITY = 'Autoodgovornost';
export const POLICY_VESSELS = 'Plovila';
export const POLICY_ACCIDENT = 'Nezgoda';
export const POLICY_TRANSPORT = 'Transport';
export const POLICY_OTHER = 'Ostalo';

export const POLICY_HOK_EXPRESS = 'HOK Express';

//Policy status types
export const POLICY_STATUS_EXPIRED = 'expired';
export const POLICY_STATUS_RUNNING_OUT = 'RUNNING_OUT';
export const POLICY_STATUS_ACTIVE = 'active';

// Assistance types
export const ASSISTANCE_TRAVEL = 'PZ';
export const ASSISTANCE_HOME = 'KA';
export const ASSISTANCE_HR = 'HR';
export const ASSISTANCE_RENTACAR = 'RC';
export const ASSISTANCE_EXPRESS = 'HE';

// Assistance pages
export const MY_ASSISTANCE_TRAVEL_PAGE = 'travel';
export const MY_ASSISTANCE_HOME_PAGE = 'home';
export const MY_ASSISTANCE_AUTO_ASSIST_PAGE = 'hr';
export const MY_ASSISTANCE_RENTACAR_PAGE = 'rent-a-car';
export const MY_ASSISTANCE_EXPRESS_PAGE = 'express';

// Policy codes
export const POLICY_CODE_MD = 'MD';
export const POLICY_CODE_HOK_APARTMAN = 'AP';
export const POLICY_CODE_EU = 'EU';
export const POLICY_CODE_HE = 'HE';
export const POLICY_CODE_PZ = 'PZ';
export const POLICY_CODE_AO = 'AO';
export const POLICY_CODE_AK = 'AK';
export const POLICY_CODE_KA = 'KA';
export const POLICY_CODE_PROPERTY_OTHER = '09';

// Policy pages
export const MY_POLICIES_HEALTH_INSURANCE_PAGE = `health-insurance`;
export const MY_POLICIES_ATTORNEY_PAGE = `attorney-liability`;
export const MY_POLICIES_LIABILITY_PAGE = `liability`;
export const MY_POLICIES_DRIVER_LIABILITY_PAGE = `driver-liability`;
export const MY_POLICIES_KASKO_PAGE = `kasko`;
export const MY_POLICIES_PROPERTY_PAGE = `property-insurance`;
export const MY_POLICIES_ASSISTANCE_PAGE = `assistance`;
export const MY_POLICIES_TRAVEL_INSURANCE_PAGE = `travel-insurance`;
export const MY_POLICIES_CAR_LIABILITY_PAGE = `car-insurance`;
export const MY_POLICIES_VESSELS_PAGE = `vessels-insurance`;
export const MY_POLICIES_ACCIDENT_PAGE = `accident-insurance`;
export const MY_POLICIES_TRANSPORT_PAGE = `transport-insurance`;
export const MY_POLICIES_OTHER_PAGE = `other`;

export const policyGroupDefs = {
  [POLICY_KASKO]: { icon: HokKaskoIcon, cardName: 'kasko', route: MY_POLICIES_KASKO_PAGE },
  [POLICY_HEALTH]: { icon: HokZoIcon, cardName: 'health', route: MY_POLICIES_HEALTH_INSURANCE_PAGE },
  [POLICY_ATTORNEY]: { icon: HokOoIcon, cardName: 'judicial', route: MY_POLICIES_ATTORNEY_PAGE },
  [POLICY_LIABILITY]: { icon: HokOdgovornostIcon, cardName: 'liability', route: MY_POLICIES_LIABILITY_PAGE },
  [POLICY_DRIVER_LIABILITY]: {
    icon: HokOVozaraIcon,
    cardName: 'driverLiability',
    route: MY_POLICIES_DRIVER_LIABILITY_PAGE,
  },
  [POLICY_PROPERTY]: { icon: HokMdIcon, cardName: 'realEstate', route: MY_POLICIES_PROPERTY_PAGE },
  [POLICY_ASSISTANCE]: { icon: HokAssistanceIcon, cardName: 'assistance', route: MY_POLICIES_ASSISTANCE_PAGE },
  [POLICY_HEALTH_TRAVEL]: { icon: HokPzoIcon, cardName: 'travel', route: MY_POLICIES_TRAVEL_INSURANCE_PAGE },
  [POLICY_CAR_LIABILITY]: { icon: HokAoIcon, cardName: 'car', route: MY_POLICIES_CAR_LIABILITY_PAGE },
  [POLICY_VESSELS]: { icon: HokVesselsIcon, cardName: 'vessel', route: MY_POLICIES_VESSELS_PAGE },
  [POLICY_ACCIDENT]: { icon: HokNezgodaIcon, cardName: 'accident', route: MY_POLICIES_ACCIDENT_PAGE },
  [POLICY_TRANSPORT]: { icon: HokTransportIcon, cardName: 'transport', route: MY_POLICIES_TRANSPORT_PAGE },
  [POLICY_OTHER]: { icon: HokOtherIcon, cardName: 'otherInsurance', route: MY_POLICIES_OTHER_PAGE },
};

// Assistance group definitions
export const assistanceGroupDefs = {
  [ASSISTANCE_TRAVEL]: { cardName: 'travel', icon: HokTravelAssistIcon, route: MY_ASSISTANCE_TRAVEL_PAGE },
  [ASSISTANCE_HOME]: { cardName: 'home', icon: HokHomeAssistIcon, route: MY_ASSISTANCE_HOME_PAGE },
  [ASSISTANCE_HR]: { cardName: 'hr', icon: HokAutoAsisstIcon, route: MY_ASSISTANCE_AUTO_ASSIST_PAGE },
  [ASSISTANCE_RENTACAR]: {
    cardName: 'rentACar',
    icon: HokRentACarAsisstIcon,
    route: MY_ASSISTANCE_RENTACAR_PAGE,
  },
  [ASSISTANCE_EXPRESS]: { cardName: 'express', icon: HokExpressAssistIcon, route: MY_ASSISTANCE_EXPRESS_PAGE },
};

// AO policy product
export const AUTOODGOVORNOST = 'AUTOODGOVORNOST';

export const VOZAC_AMATER = 'VOZAC_AMATER';
export const MOTOCIKL_AM = 'MOTOCIKL_AM';
export const VOZAC_PRO = 'VOZAC_PRO';

export const DRIVER_TYPES = [
  { id: VOZAC_AMATER, name: `userMyPoliciesCarLiability.${VOZAC_AMATER}` },
  { id: MOTOCIKL_AM, name: `userMyPoliciesCarLiability.${MOTOCIKL_AM}` },
  // { id: VOZAC_PRO, name: `userMyPoliciesCarLiability.${VOZAC_PRO}` },
];

// AO policy covers
export const HOK_BONUS_GARANT = 'HOK_BONUS_GARANT';
export const HOK_AUTONEZGODA = 'HOK_AUTONEZGODA';
export const AO_PLUS = 'AO_PLUS';
export const CAR_LIABILITY_COVER_PRAVNA = 'CAR_LIABILITY_COVER_PRAVNA';
export const HR_ASISTENCIJA = 'HR_ASISTENCIJA';
export const HOK_RENT_A_CAR = 'HOK_RENT_A_CAR';
export const HOK_EXPRESS_ASISTENCIJA = 'HOK_EXPRESS_ASISTENCIJA';
export const CAR_LIABILITY_COVER_KASKO_START = 'CAR_LIABILITY_COVER_KASKO_START';
export const CAR_LIABILITY_COVER_KASKO_PRO = 'CAR_LIABILITY_COVER_KASKO_PRO';
export const CAR_LIABILITY_COVER_KASKO_FORTE = 'CAR_LIABILITY_COVER_KASKO_FORTE';

export const CAR_LIABILITY_COVERS = [
  HOK_BONUS_GARANT,
  AO_PLUS,
  HOK_RENT_A_CAR,
  HR_ASISTENCIJA,
  HOK_EXPRESS_ASISTENCIJA,
  HOK_AUTONEZGODA,
  // CAR_LIABILITY_COVER_PRAVNA,
  // CAR_LIABILITY_COVER_KASKO_START,
  // CAR_LIABILITY_COVER_KASKO_PRO,
  // CAR_LIABILITY_COVER_KASKO_FORTE,
];

export const OSOBNO_VOZILO = 'OSOBNO_VOZILO';
export const MOTOCIKL_MOTORKOTAC = 'MOTOCIKL_MOTORKOTAC';
export const TERETNO_VOZILO = 'TERETNO_VOZILO';
export const AUTOBUS = 'AUTOBUS';
export const TRAKTOR = 'TRAKTOR';
export const RADNI_STROJ = 'RADNI_STROJ';
export const PRIKLJUCNO_VOZILO = 'PRIKLJUCNO_VOZILO';

export const VEHICLE_CATEGORIES = [
  { id: OSOBNO_VOZILO, name: `userMyPoliciesCarLiability.${OSOBNO_VOZILO}` },
  { id: MOTOCIKL_MOTORKOTAC, name: `userMyPoliciesCarLiability.${MOTOCIKL_MOTORKOTAC}` },
  // { id: TERETNO_VOZILO, name: `userMyPoliciesCarLiability.${TERETNO_VOZILO}` },
  // { id: AUTOBUS, name: `userMyPoliciesCarLiability.${AUTOBUS}` },
  // { id: TRAKTOR, name: `userMyPoliciesCarLiability.${TRAKTOR}` },
  // { id: RADNI_STROJ, name: `userMyPoliciesCarLiability.${RADNI_STROJ}` },
  // { id: PRIKLJUCNO_VOZILO, name: `userMyPoliciesCarLiability.${PRIKLJUCNO_VOZILO}` },
];

// AO policy risk packages
export const NO_RISK_PACKAGE = 'NO_RISK_PACKAGE';
export const OSNOVNI_PAKET = 'OSNOVNI_PAKET';
export const STANDARDNI_PAKET = 'STANDARDNI_PAKET';
export const OPTIMALNI_PAKET = 'OPTIMALNI_PAKET';

export const RISK_AO_PLUS = { id: 'AO_PLUS', name: 'AO Plus' };
export const RISK_HOK_BONUS_GARANT = { id: 'HOK_BONUS_GARANT', name: 'HOK Bonus garant' };
export const RISK_HR_ASISTENCIJA = { id: 'HR_ASISTENCIJA', name: 'HR Asistencija' };
export const RISK_HOK_RENT_A_CAR = { id: 'HOK_RENT_A_CAR', name: 'HR Rent-a-car' };
export const RISK_HOK_EXPRESS = { id: 'HOK_EXPRESS', name: 'HOK Express' };
export const RISK_HOK_AUTONEZGODA = { id: 'HOK_AUTONEZGODA', name: 'Autonezgoda' };

export const HOK_AO_RISKS = [
  RISK_AO_PLUS,
  RISK_HOK_BONUS_GARANT,
  RISK_HR_ASISTENCIJA,
  RISK_HOK_RENT_A_CAR,
  RISK_HOK_EXPRESS,
  RISK_HOK_AUTONEZGODA,
];

export const AO_RISK_PACKAGES = [
  {
    id: OSNOVNI_PAKET,
    name: `userMyPoliciesCarLiability.${OSNOVNI_PAKET}`,
    risks: [RISK_HOK_AUTONEZGODA, RISK_AO_PLUS, RISK_HOK_BONUS_GARANT],
  },
  {
    id: STANDARDNI_PAKET,
    name: `userMyPoliciesCarLiability.${STANDARDNI_PAKET}`,
    risks: [RISK_HOK_AUTONEZGODA, RISK_AO_PLUS, RISK_HOK_BONUS_GARANT, RISK_HR_ASISTENCIJA],
  },
  {
    id: OPTIMALNI_PAKET,
    name: `userMyPoliciesCarLiability.${OPTIMALNI_PAKET}`,
    risks: [RISK_HOK_AUTONEZGODA, RISK_AO_PLUS, RISK_HOK_BONUS_GARANT, RISK_HOK_RENT_A_CAR],
  },
];

// Health policy packages
export const POLICY_PACKAGE_HEALTH_1 = 'EUREKA_1';
export const POLICY_PACKAGE_HEALTH_2 = 'EUREKA_2';
export const POLICY_PACKAGE_HEALTH_3 = 'EUREKA_3';

export const HEALTH_INSURANCE_PACKAGES = [
  {
    id: POLICY_PACKAGE_HEALTH_1,
    name: `userMyPoliciesHealthInsurance.${POLICY_PACKAGE_HEALTH_1}`,
    price: `1.560,00 kn`,
  },
  {
    id: POLICY_PACKAGE_HEALTH_2,
    name: `userMyPoliciesHealthInsurance.${POLICY_PACKAGE_HEALTH_2}`,
    price: `2.400,00 kn`,
  },
  {
    id: POLICY_PACKAGE_HEALTH_3,
    name: `userMyPoliciesHealthInsurance.${POLICY_PACKAGE_HEALTH_3}`,
    price: `4.200,00 kn`,
  },
];

// Travel policy packages
export const POLICY_PACKAGE_TRAVEL_A = 'PAKET_POKRICA_A';
export const POLICY_PACKAGE_TRAVEL_B = 'PAKET_POKRICA_B';
export const POLICY_PACKAGE_TRAVEL_C = 'PAKET_POKRICA_C';

export const TRAVEL_INSURANCE_PACKAGES = [
  { id: POLICY_PACKAGE_TRAVEL_A, name: `userMyPoliciesTravelInsurance.${POLICY_PACKAGE_TRAVEL_A}` },
  { id: POLICY_PACKAGE_TRAVEL_B, name: `userMyPoliciesTravelInsurance.${POLICY_PACKAGE_TRAVEL_B}` },
  { id: POLICY_PACKAGE_TRAVEL_C, name: `userMyPoliciesTravelInsurance.${POLICY_PACKAGE_TRAVEL_C}` },
];

// Travel Policy covers
export const POLICY_COVER_NO_INCIDENT = 'BEZ_NEZGODE';
export const POLICY_COVER_INCIDENT_A = 'NEZGODA_A';
export const POLICY_COVER_INCIDENT_B = 'NEZGODA_B';
export const POLICY_COVER_INCIDENT_C = 'NEZGODA_C';

export const TRAVEL_INSURANCE_COVERS = [
  { id: POLICY_COVER_INCIDENT_A, name: `userMyPoliciesTravelInsurance.${POLICY_COVER_INCIDENT_A}` },
  { id: POLICY_COVER_INCIDENT_B, name: `userMyPoliciesTravelInsurance.${POLICY_COVER_INCIDENT_B}` },
  { id: POLICY_COVER_INCIDENT_C, name: `userMyPoliciesTravelInsurance.${POLICY_COVER_INCIDENT_C}` },
];

// Travel policy purpose
export const TRAVEL_PURPOSE_PLEASURE = 'TURISTICKI';
export const TRAVEL_PURPOSE_BUSINESS = 'POSLOVNO';
export const TRAVEL_PURPOSE_SPORT = 'SPORT';
export const TRAVEL_PURPOSE_OTHER = 'OSTALO';
export const TRAVEL_INSURANCE_PURPOSE = [
  { id: TRAVEL_PURPOSE_PLEASURE, name: `userMyPoliciesTravelInsurance.${TRAVEL_PURPOSE_PLEASURE}` },
  { id: TRAVEL_PURPOSE_BUSINESS, name: `userMyPoliciesTravelInsurance.${TRAVEL_PURPOSE_BUSINESS}` },
  { id: TRAVEL_PURPOSE_SPORT, name: `userMyPoliciesTravelInsurance.${TRAVEL_PURPOSE_SPORT}` },
  { id: TRAVEL_PURPOSE_OTHER, name: `userMyPoliciesTravelInsurance.${TRAVEL_PURPOSE_OTHER}` },
];

// Travel policy group type
export const TRAVEL_TYPE_SINGLE = 'POJEDINACNO';
export const TRAVEL_TYPE_FAMILY = 'OBITELJSKO';
export const TRAVEL_TYPE_GROUP = 'GRUPNO';

export const TRAVEL_INSURANCE_TYPE = [
  { id: TRAVEL_TYPE_SINGLE, name: `userMyPoliciesTravelInsurance.${TRAVEL_TYPE_SINGLE}` },
  { id: TRAVEL_TYPE_FAMILY, name: `userMyPoliciesTravelInsurance.${TRAVEL_TYPE_FAMILY}` },
  { id: TRAVEL_TYPE_GROUP, name: `userMyPoliciesTravelInsurance.${TRAVEL_TYPE_GROUP}` },
];

// Moj Dom Policy packages
export const MOJ_DOM = 'MOJ_DOM';
export const MOJ_DOM_KUCANSTVO = 'MOJ_DOM_KUCANSTVO';

export const HOK_KUCNA_ASISTENCIJA = 'HOK_KUCNA_ASISTENCIJA';

// HOK Apartman covers
export const HOK_APARTMAN_IMOVINA = 'HOK_APARTMAN_IMOVINA';
export const HOK_APARTMAN_GUBITAK = 'HOK_APARTMAN_GUBITAK';
export const HOK_APARTMAN_ODGOVORNOST = 'HOK_APARTMAN_ODGOVORNOST';
export const HOK_APARTMAN_NEZGODA = 'HOK_APARTMAN_GUBITAK_NEZGODA';
export const HOK_APARTMAN_COVERS = [
  HOK_APARTMAN_IMOVINA,
  HOK_APARTMAN_GUBITAK,
  HOK_APARTMAN_ODGOVORNOST,
  HOK_APARTMAN_NEZGODA,
];

// HOK Imovina covers
export const HOK_IMOVINA_POTRES = 'HOK_IMOVINA_POTRES';
export const HOK_IMOVINA_PROVALA = 'HOK_IMOVINA_PROVALA';
export const HOK_IMOVINA_STAKLO = 'HOK_IMOVINA_STAKLO';
export const HOK_IMOVINA_STROJ = 'HOK_IMOVINA_STROJ';
export const HOK_IMOVINA_IZGRADNJA = 'HOK_IMOVINA_IZGRADNJA';
export const HOK_IMOVINA_MONTAZA = 'HOK_IMOVINA_MONTAZA';

export const HOK_IMOVINA_COVERS = [
  HOK_IMOVINA_POTRES,
  HOK_IMOVINA_PROVALA,
  HOK_IMOVINA_STAKLO,
  HOK_IMOVINA_STROJ,
  HOK_IMOVINA_IZGRADNJA,
  HOK_IMOVINA_MONTAZA,
];

// Invoice statuses
export const INVOICE_STATUS_HALF_PAID = 'HALF_PAID';
export const INVOICE_STATUS_NOT_PAID = 'NOT_PAID';
export const INVOICE_STATUS_PAID = 'PAID';
export const INVOICE_STATUS_UNKNOWN = 'UNKNOWN';

// Payment statuses
export const PAYMENT_STATUS_INITIATED = 'INITIATED';
export const PAYMENT_STATUS_PAID = 'PAID';
export const PAYMENT_STATUS_CANCELED = 'CANCELED';
export const PAYMENT_STATUS_ERROR = 'ERROR';

// Payment types
export const INVOICE_PAYMENT = 'INVOICE_PAYMENT';
export const INSURANCE_PAYMENT = 'NEW_INSURANCE_PAYMENT';

// Office types
export const OFFICES = {
  id: '1',
  name: 'offices',
};
export const MAIN_OFFICES = {
  id: '2',
  name: 'mainOffices',
};
export const OFFICE_CLAIMS = {
  id: '8',
  name: 'damageClaims',
};
export const OFFICE_TYPES = [OFFICES, MAIN_OFFICES, OFFICE_CLAIMS];

export const UPLOAD_FILE_SIZE_LIMIT = 10000000;
