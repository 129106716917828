import React, { useState, useCallback } from 'react';
import { CardGroup, Card, CardBody, Row, Col, Label, InputGroup, Input, Fade } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { formatDate } from '../../constants/dateFormatting';
import { CustomButton, Spinner } from '../../containers';
import { DamageClaimsComments } from './DamageClaimComments';
import { DamageClaimsCommentsPagination } from './DamageClaimsCommentsPagination';
import { DamageClaimWriteComment } from './DamageClaimWriteComment';
import { GetUserClaimCommentsQuery } from '../../operations/queries/GetUserClaimsQuery';
import { handleRequestError } from '../../constants/errorCodes';

const ClaimInfoCommentsBox = ({ data, loading, error, fetchMore, user, claimParams }) => {
  const [commentBoxOpen, setCommentBoxOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const paginate = useCallback(
    page => {
      fetchMore({
        variables: { skip: (page - 1) * 5 },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getClaimComments: {
              ...fetchMoreResult.getClaimComments,
            },
          };
        },
      });
      setCurrentPage(page);
    },
    [fetchMore]
  );

  if (!data && !error) {
    return (
      <Row className="no-gutters px-0 px-md-5">
        <Col>
          <Spinner className="spinner-positioning" />
        </Col>
      </Row>
    );
  }

  const comments = get(data, 'getClaimComments.claimComments', []);
  const queryCount = get(data, 'getClaimComments.queryCount', 0);

  return (
    <Row className="no-gutters px-0 px-md-5">
      <Col>
        {comments.length > 0 ? (
          <>
            <div className="hok-comments-spinner-container">
              {loading && <Spinner className="hok-comments-spinner" />}
              <DamageClaimsComments loading={loading} comments={comments} user={user} />
            </div>
            {queryCount > 5 && (
              <DamageClaimsCommentsPagination queryCount={queryCount} paginate={paginate} currentPage={currentPage} />
            )}
          </>
        ) : (
          <Row className="no-gutters">
            <Col className="text-center">
              <h5>
                <FormattedMessage id="userMyDamageClaims.noClaimComments" />
              </h5>
            </Col>
          </Row>
        )}
        {typeof user === 'undefined' && (
          <Row className="no-gutters my-4">
            <Col>
              <Fade in={!commentBoxOpen}>
                <Row className="no-gutters">
                  <Col xs="12">
                    <CustomButton
                      className="hok-btn float-right"
                      block={false}
                      translationId="comment"
                      onClick={() => setCommentBoxOpen(true)}
                    />
                  </Col>
                </Row>
              </Fade>
              <DamageClaimWriteComment
                isOpen={commentBoxOpen}
                close={() => setCommentBoxOpen(false)}
                claimParams={claimParams}
                paginate={paginate}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export const DamageClaimInfo = ({ damageClaim, userId, user, dispatch, anonymous = false }) => {
  const { claimClass, claimNo, claimConfirmationCode } = damageClaim;

  const { data, loading, error, fetchMore } = useQuery(GetUserClaimCommentsQuery, {
    variables: {
      anonymous,
      filter: { ...(claimNo && !anonymous ? { claimNo: claimNo, claimClass: claimClass } : { claimConfirmationCode }) },
      order: { createdAt: 'DESC' },
      ...(userId && { userId: `${userId}` }),
      take: 5,
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    notifyOnNetworkStatusChange: true,
  });

  return (
    <CardGroup>
      <Card className="hok-card">
        <CardBody>
          <Row className="mt-4">
            <Col xs="12" sm="6">
              <Label for="damageClaimNumber">
                <FormattedMessage id="inputs.damageClaimNumber" />
              </Label>
              <InputGroup>
                <Input className="input-uppercase" id="damageClaimNumber" readOnly defaultValue={damageClaim.claimNo} />
              </InputGroup>
            </Col>
            <Col xs="12" sm="6">
              <Label for="damageClaimClass">
                <FormattedMessage id="inputs.damageClaimClass" />
              </Label>
              <InputGroup>
                <Input
                  className="input-uppercase"
                  id="damageClaimClass"
                  readOnly
                  defaultValue={get(damageClaim, 'claimDefinition.book', '')}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="12" sm="6">
              <Label for="damageClaimStatus">
                <FormattedMessage id="inputs.damageClaimStatus" />
              </Label>
              <InputGroup>
                <Input
                  className="input-uppercase"
                  id="damageClaimStatus"
                  readOnly
                  defaultValue={damageClaim.userFriendlyStatus}
                />
              </InputGroup>
            </Col>
            {!anonymous && (
              <Col xs="12" sm="6">
                <Label for="partnerRole">
                  <FormattedMessage id="inputs.partnerRole" />
                </Label>
                <InputGroup>
                  <Input
                    className="input-uppercase"
                    id="partnerRole"
                    readOnly
                    defaultValue={damageClaim.userFriendlyRole}
                  />
                </InputGroup>
              </Col>
            )}
            <Col xs="12" sm="6">
              <Label for="dateOfReport">
                <FormattedMessage id="inputs.dateOfReport" />
              </Label>
              <InputGroup>
                <Input
                  className="input-uppercase"
                  id="dateOfReport"
                  readOnly
                  defaultValue={formatDate(damageClaim.dateOfReport)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="no-gutters mt-5 mb-4">
            <Col xs="12">
              <h3>
                <FormattedMessage id="userMyDamageClaims.claimComments" />
              </h3>
            </Col>
          </Row>
          <ClaimInfoCommentsBox
            data={data}
            loading={loading}
            error={error}
            fetchMore={fetchMore}
            user={user}
            claimParams={
              claimNo && !anonymous ? { claimNo, claimClass, anonymous } : { claimConfirmationCode, anonymous }
            }
            claimClass={claimClass}
            claimNo={claimNo}
          />
        </CardBody>
      </Card>
    </CardGroup>
  );
};
