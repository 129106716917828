import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { ROUTE_TO_ADMIN_LOGOUT_PAGE, ROUTE_TO_EMPLOYEE_PROFILE_PAGE } from '../../constants/routes';
import logoHeader from '../../img/logo-header.png';
import { Notifications } from '../../components/Notifications';
import { ReactComponent as HokProfileIcon } from '../../img/icons/hok-profile.svg';
import { ReactComponent as HokLogoutIcon } from '../../img/icons/hok-logout.svg';
import { ReactComponent as DefaultPartnerAvatar } from '../../img/hok-partner-avatar.svg';

const mapStateToProps = state => ({
  user: state.auth.user,
});

class Header extends Component {
  state = {
    dropdownOpen: false,
  };

  dropDownToggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  dropdownMenuItems = [
    {
      type: 'item',
      icon: <HokProfileIcon className="svg-header-menu" />,
      name: <FormattedMessage id="routes.myProfile" />,
      route: ROUTE_TO_EMPLOYEE_PROFILE_PAGE,
    },
    {
      type: 'divider',
    },
    {
      type: 'item',
      icon: <HokLogoutIcon className="svg-header-menu" />,
      name: <FormattedMessage id="routes.logout" />,
      route: ROUTE_TO_ADMIN_LOGOUT_PAGE,
    },
  ];

  render() {
    const firstName = get(this.props, 'user.firstName', '');
    const lastName = get(this.props, 'user.lastName', '');
    const displayFullName = firstName && lastName ? firstName + ' ' + lastName : <FormattedMessage id="header.user" />;
    const avatar = get(this.props, 'user.avatar', null);

    const renderDropdownMenuItems = () => {
      return (
        <DropdownMenu className="hok-profile-dropdown-menu" right>
          {this.dropdownMenuItems.map((item, index) =>
            item.type === 'item' ? (
              <DropdownItem key={index} tag={Link} to={item.route}>
                {item.icon} {item.name}
              </DropdownItem>
            ) : (
              <DropdownItem key={index} divider />
            )
          )}
        </DropdownMenu>
      );
    };

    return (
      <>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          className="hok-brand-center d-none d-lg-inline-flex"
          brand={{ src: logoHeader, width: 'auto', height: 55, alt: 'Logo' }}
        />
        <div className="hok-admin-db-title d-none d-sm-block">
          <FormattedMessage id="adminDashboard.title" />
        </div>
        <div className="navbar-nav">
          <Dropdown
            className="nav-item hok-profile-dropdown"
            direction="down"
            isOpen={this.state.dropdownOpen}
            toggle={this.dropDownToggle}>
            <DropdownToggle nav className="hok-dropdown-toggle">
              <span className="hok-dropdown-toggle-name d-none d-md-block mr-3">{displayFullName}</span>
              {avatar ? (
                <div style={{ backgroundImage: `url("${avatar}")` }} src={avatar} className="hok-img-avatar" />
              ) : (
                <div>
                  <DefaultPartnerAvatar className="hok-img-avatar" />
                </div>
              )}
            </DropdownToggle>
            {renderDropdownMenuItems()}
          </Dropdown>
          <Notifications />
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(Header);
