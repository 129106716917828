import { STEP_1 } from '../constants/business';

export const stepToNumber = stepId => {
  try {
    const matchedStep = stepId.match(/^STEP_?([0-9])$/i);
    return parseInt(matchedStep[1], 10);
  } catch (e) {
    return 1;
  }
};

export const getStepNumberAndId = (data, error, step, numberOfSteps, allowLastStep = false) => {
  if (error || !data || (!allowLastStep && stepToNumber(data) === numberOfSteps)) {
    return { activeStep: 0, stepId: STEP_1 };
  }

  const selectedStep = stepToNumber(step);
  const serverStep = stepToNumber(data);

  if (selectedStep <= serverStep) {
    return { activeStep: selectedStep - 1, stepId: step };
  }

  return { activeStep: serverStep - 1, stepId: data };
};
