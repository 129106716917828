import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Label, InputGroup, Input } from 'reactstrap';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash-es';
import { PaymentStep } from '../../../components/PolicyContractingSteps/PaymentStep';
import { formatDate } from '../../../constants/dateFormatting';
import {
  ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_4,
  ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE,
} from '../../../constants/routes';
import { DescriptionText, CollapsibleTextInfo } from '../../../containers';
import {
  VEHICLE_DATA_AO,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_CANCELED,
  PAYMENT_STATUS_ERROR,
  INSURED_PERSON_DATA,
  VOZAC_AMATER,
  HOK_AUTONEZGODA,
  OSOBNO_VOZILO,
  POLICY_CODE_AO,
  NO_RISK_PACKAGE,
  MOTOCIKL_AM,
} from '../../../constants/business';
import { handleRequestError } from '../../../constants/errorCodes';
import { GetPaymentStatusQuery } from '../../../operations/queries/GetPaymentStatusQuery';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { PaymentStatusSubscription } from '../../../operations/subscriptions/PaymentStatusSubscription';
import { GetUserVehicleDataQuery } from '../../../operations/queries/GetUserVehicleDataQuery';
import { BuyHokAOProductMutation } from '../../../operations/mutations/BuyProductMutation';
import { useAORiskSumsQuery, useAOProductPackagesQuery } from '../../../utils/customHooks';
import { displayCurrencyOptions } from '../../../utils/currencyDisplayFormat';
import { PackageDropdown } from './PackageDropdown';
import { RiskList } from './RiskList';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';

export const Step3AO = ({ data, stateKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage, formatNumber } = useIntl();
  const newWindowTitle = formatMessage({ id: 'loading' });

  const win = useRef(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const getInsuranceStartDate = () => {
    return moment(data.insuranceStartDate).isSameOrAfter(moment(), 'day') &&
      moment(data.insuranceStartDate).isBefore(moment().add(1, 'hour'))
      ? moment().add(1, 'hour')
      : moment(data.insuranceStartDate);
  };

  const insuranceStartDate = getInsuranceStartDate();
  const insuranceEndDate = moment(insuranceStartDate).add(1, 'year');

  const [getPaymentStatus, { data: paymentData, subscribeToMore }] = useLazyQuery(GetPaymentStatusQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });
  const [deleteServerStorageRecord] = useMutation(SetServerStorageMutation, {
    variables: { stateKey, shouldDelete: true },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const { packages } = useAOProductPackagesQuery();

  const driverType = get(data, 'vehicle.vehicleType', OSOBNO_VOZILO) === OSOBNO_VOZILO ? VOZAC_AMATER : MOTOCIKL_AM;

  const { riskSums } = useAORiskSumsQuery({
    vehicleTypeCode: get(data, 'vehicle.vehicleType', OSOBNO_VOZILO),
    classCode: driverType,
  });

  const queryVariables = {
    productRelatedData: {
      registrationPlate: data.registrationPlate,
      vin: data.vin,
      numberOfPreviousInsurancePolicy: data.numberOfPreviousInsurancePolicy,
      insuranceStartDate: getInsuranceStartDate(),
      covers: data.covers,
      ...(data.covers.includes(HOK_AUTONEZGODA) && {
        coverAdditionalData_HOK_AUTONEZGODA_OSIGURANA_SVOTA: data.coverAdditionalData_HOK_AUTONEZGODA_OSIGURANA_SVOTA,
        coverAdditionalData_HOK_AUTONEZGODA_BROJ_PUTNIKA: data.coverAdditionalData_HOK_AUTONEZGODA_BROJ_PUTNIKA,
        coverAdditionalData_HOK_AUTONEZGODA_TIP_VOZACA: data.coverAdditionalData_HOK_AUTONEZGODA_TIP_VOZACA,
      }),
    },
  };

  const { loading: vehicleDataLoading, data: vData } = useQuery(GetUserVehicleDataQuery, {
    variables: {
      vinNumber: data.vin,
    },
    fetchPolicy: 'cache-first',
  });

  const vehicleData = get(vData, 'vehicleData', null);

  const [buyHokAO] = useMutation(BuyHokAOProductMutation, {
    variables: queryVariables,
    onError: error => {
      win.current.close();
      setButtonDisabled(false);
      handleRequestError(error, dispatch);
    },
    onCompleted: data => {
      const confirmationCode = get(data, 'buyInsurancePolicyHokAO.confirmationCode', null);
      if (confirmationCode) {
        getPaymentStatus({ variables: { confirmationCode } });
        // Workaround to not trigger popup blocker due to window.open() not being called
        // immediately after user action (onClick event) but after an async function
        win.current.location = `${ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE}/${confirmationCode}`;
      }
    },
  });

  useEffect(() => {
    window.addEventListener('storage', () => {
      let _tabToggleState = localStorage.getItem('tabStateStorage');
      if(_tabToggleState == "true"){
        history.push('/dashboard');
      }
    })
  }, [history])

  const onClick = useCallback(() => {
    win.current = window.open();
    win.current.document.title = newWindowTitle;
    setButtonDisabled(true);
    buyHokAO();
  }, [buyHokAO, newWindowTitle]);

  useEffect(() => {
    if (get(paymentData, 'getPaymentData.confirmationCode', '')) {
      subscribeToMore({
        document: PaymentStatusSubscription,
        variables: { confirmationCode: paymentData.getPaymentData.confirmationCode },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return { getPaymentData: subscriptionData.data.payments };
        },
        onError: error => handleRequestError(error, dispatch, { silent: true }),
      });
    }

    if (get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_PAID) {
      deleteServerStorageRecord();
      history.push(`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_4}/${paymentData.getPaymentData.confirmationCode}`);
    }
    if (
      get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_CANCELED ||
      get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_ERROR
    ) {
      setButtonDisabled(false);
    }
  }, [dispatch, history, paymentData, subscribeToMore, deleteServerStorageRecord]);

  const insuredPerson = get(data, 'insuredPerson', {});

  const getSelectedRiskSum = () => {
    if (riskSums.length > 0) {
      const selectedSum = riskSums.find(
        risk => risk.sumEnumCode === get(data, 'coverAdditionalData_HOK_AUTONEZGODA_OSIGURANA_SVOTA', 0)
      );

      if (selectedSum) {
        return selectedSum.sumDesc;
      }
    }
    return '';
  };

  const getBaseProductPrice = () => {
    const items = get(data, 'productItems', []);

    const baseItem = items.find(item => item.name === POLICY_CODE_AO);
    if (baseItem) {
      //return formatNumber(baseItem.value, displayCurrencyOptions);
      return baseItem.value;
    }
    return formatNumber(0, displayCurrencyOptions);
  };

  const coversContainsAutoNezgoda = get(data, 'covers', []).includes(HOK_AUTONEZGODA);
  const currentPackage =
    data.productPackage !== NO_RISK_PACKAGE
      ? packages.find(risksPackage => risksPackage.id === data.productPackage)
      : null;

  const insuredPersonName =
    insuredPerson.sector === USER_ACCOUNT_BUSINESS
      ? insuredPerson.businessName
      : `${insuredPerson.name} ${insuredPerson.surename}`;

  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  return (
    <PaymentStep
      policyTranslationBase="userMyPoliciesCarLiability"
      premiumAmount={get(data, 'totalPremium', 0)}
      nextStep={ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_4}
      data={data}
      onClick={onClick}
      loaderProp={vehicleDataLoading || buttonDisabled}>
      <Row className="mt-4 mb-3 no-gutters">
        <Col>
          <h3>
            <FormattedMessage id="userMyPolicies.policyData.title" />:
          </h3>
        </Col>
      </Row>
      <Row>
        <Col sm="6" lg="4">
          <Label for="validFrom">
            <FormattedMessage id="inputs.validFrom" />
          </Label>
          <InputGroup>
            <Input id="validFrom" disabled defaultValue={formatDate(insuranceStartDate)} />
          </InputGroup>
        </Col>
        <Col sm="6" lg="4">
          <Label for="validTo">
            <FormattedMessage id="inputs.validTo" />
          </Label>
          <InputGroup>
            <Input id="validTo" disabled defaultValue={formatDate(insuranceEndDate)} />
          </InputGroup>
        </Col>
        <Col sm="12" md="6" lg="4">
          <Label>
            <FormattedMessage id="userMyPolicies.policyData.policyPersonInsured" />
          </Label>
          <CollapsibleTextInfo rawPlaceholder uppercase placeholder={insuredPersonName}>
            <Container>
              <Row>
                <Col xs={12}>
                  <DescriptionText value={INSURED_PERSON_DATA} data={insuredPerson} />
                </Col>
              </Row>
            </Container>
          </CollapsibleTextInfo>
        </Col>
        <Col sm="12" md="6" lg="4">
          <Label for="vehicle">
            <FormattedMessage id="inputs.vehicle" />
          </Label>
          <CollapsibleTextInfo rawPlaceholder uppercase placeholder={data.registrationPlate}>
            {vehicleData && (
              <Container>
                <Row>
                  <Col xs={12}>
                    <DescriptionText value={VEHICLE_DATA_AO} data={vehicleData} />
                  </Col>
                </Row>
              </Container>
            )}
          </CollapsibleTextInfo>
        </Col>
      </Row>
      <Row className="mt-4 mb-3 no-gutters">
        <Col>
          <h3>
            <FormattedMessage id="userMyPoliciesCarLiability.product" />:
          </h3>
        </Col>
      </Row>
      <Row>
        <Col sm="6" lg="4">
          <Label for="productType">
            <FormattedMessage id="inputs.productType" />
          </Label>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="9" md="8" lg="6">
          <Row>
            <Col sm="8" md="9" lg="8">
              <InputGroup>
                <Input
                  id="productType"
                  className="text-uppercase"
                  disabled
                  defaultValue={formatMessage({ id: 'userMyPoliciesCarLiability.title' })}
                />
              </InputGroup>
            </Col>
            <Col sm="4" md="3" lg="4" className="d-flex align-items-center justify-content-end pl-0">
              <p className="hok-text-dark hok-price-box">{formatNumber(getBaseProductPrice(), {style: 'currency',
                      currency: 'EUR',
                      currencySign: 'standard',
                      currencyDisplay: 'narrowSymbol',
                    })} 
              {/* <br/> {currencyConversionEuro(getBaseProductPrice())} */}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      {data.covers.length > 0 && (
        <>
          <Row>
            <Col sm="6" lg="4">
              <Label for="productType">
                <FormattedMessage id="userMyPoliciesCarLiability.risks" />
              </Label>
            </Col>
          </Row>
          {currentPackage && (
            <Row className="hok-text-dark">
              <PackageDropdown currentPackage={currentPackage} productPackageId={data.productPackage} />
            </Row>
          )}
          <Row className="no-gutters mb-5">
            <Col xs="12">
              <RiskList
                covers={data.covers}
                currentPackage={currentPackage}
                productItems={data.productItems}
                vehicleTypeCode={get(data, 'vehicle.vehicleTypeCode', '01')}
              />
            </Col>
          </Row>
        </>
      )}
      {coversContainsAutoNezgoda && (
        <Row>
          <Col sm="6" lg="4" className="d-flex flex-column justify-content-end">
            <Label for="driverType">
              <FormattedMessage id="inputs.driverType" />
            </Label>
            <InputGroup>
              <Input
                id="driverType"
                disabled
                defaultValue={formatMessage({
                  id: `userMyPoliciesCarLiability.${get(
                    data,
                    'coverAdditionalData_HOK_AUTONEZGODA_TIP_VOZACA',
                    VOZAC_AMATER
                  )}`,
                })}
              />
            </InputGroup>
          </Col>
          <Col sm="6" lg="4" className="d-flex flex-column justify-content-end">
            <Label for="passengerNumber">
              <FormattedMessage id="inputs.passengerNumber" />
            </Label>
            <InputGroup>
              <Input id="passengerNumber" disabled defaultValue={get(data, 'vehicle.passengerNumber', 0)} />
            </InputGroup>
          </Col>
          <Col sm="6" lg="4">
            <Label for="risksSum" className="text-break">
              <FormattedMessage id="inputs.risksSum" />
            </Label>
            <InputGroup>
              <Input id="risksSum" disabled defaultValue={getSelectedRiskSum()+' €'} />
            </InputGroup>
          </Col>
        </Row>
      )}
    </PaymentStep>
  );
};
