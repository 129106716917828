import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { LoginAndRegisterSelector } from '../../../containers';
import { ReactComponent as HokCloseIcon } from '../../../img/icons/hok-close.svg';
import logoShort from '../../../img/logo-short.png';
import { INFORMACIJE_O_OBRADI_PODATAKA, UVJETI_KORISTENJA } from '../../../constants/documentTypes';
import { ReactComponent as HokDownloadIcon } from '../../../img/icons/hok-download.svg';
import { get } from 'lodash-es';
import { TermsOfServiceModal } from './TermsOfServiceModal';
import { useQuery } from 'react-apollo';
import { GetLegalDocumentsQuery } from '../../../operations/queries/GetLegalDocumentsQuery';
import { IOPModal } from './IOPModal';

export const TermsOfServicePublicPage = () => {
  const history = useHistory();
  const [isTosModalOpen, setIsTosModalOpen] = useState(false);
  const [isIopModalOpen, setIsIopModalOpen] = useState(false);
  const LegalDocumentsQuery1 = useQuery(GetLegalDocumentsQuery, {
    variables: { filter: { documentId: UVJETI_KORISTENJA, active: true } },
  });
  const LegalDocumentsQuery2 = useQuery(GetLegalDocumentsQuery, {
    variables: { filter: { documentId: INFORMACIJE_O_OBRADI_PODATAKA, active: true } },
  });
  const legalDocument1 = get(LegalDocumentsQuery1, 'data.getLegalDocuments.documents[0]', null);
  const legalDocument2 = get(LegalDocumentsQuery2, 'data.getLegalDocuments.documents[0]', null);

  if (LegalDocumentsQuery1.loading || (!LegalDocumentsQuery1.data && !LegalDocumentsQuery1.error)) {
    // return <Spinner className="spinner-positioning" />;
  }

  if (LegalDocumentsQuery1.error || !legalDocument1) {
    return null;
  }

  const toggleTosModal = event => {
    setIsTosModalOpen(!isTosModalOpen);
  };

  const toggleIopModal = event => {
    setIsIopModalOpen(!isIopModalOpen);
  };

  return (
    <div className="hok-client">
      <LoginAndRegisterSelector
        negativeBotMargin
        hideHeaderBorder={false}
        customHeader={
          <Col xs={12}>
            <h2 className="d-flex align-items-center justify-content-center position-relative">
              <FormattedMessage id="routes.termsOfService" />
            </h2>
            <HokCloseIcon className="hok-back-icon" onClick={() => history.goBack()} />
          </Col>
        }
      >
        {(() => {
          if (legalDocument1 && legalDocument2) {
            return (
              <Row className="no-gutters px-4 my-5 my-md-4">
                <Col xs={12} sm={{ offset: 2, size: 8 }} md={{ offset: 1, size: 10 }} lg={{ offset: 2, size: 8 }}>
                  <Row className="mb-4 mb-md-0 mb-lg-4">
                    <Col className="text-center">
                      <img className="img-fluid logo-short" src={logoShort} alt="HOK logo" />
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col className="d-flex align-items-center mb-3" key={1}>
                      <p
                        className="hok-document-link-content"
                        style={{ cursor: 'pointer', color: '#ed1d24' }}
                        onClick={toggleTosModal}
                      >
                        <HokDownloadIcon className="svg-red ml-auto" height="24" width="24" /> &nbsp;{' '}
                        <strong>Uvjeti korištenja</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex align-items-center mb-3" key={2}>
                      <p
                        className="hok-document-link-content"
                        style={{ cursor: 'pointer', color: '#ed1d24' }}
                        onClick={toggleIopModal}
                      >
                        <HokDownloadIcon height="24" width="24" /> &nbsp;&nbsp;
                        <strong>Informacije o obradi podataka</strong>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          }
        })()}
        <TermsOfServiceModal
          isOpen={isTosModalOpen}
          toggle={toggleTosModal}
          setTacAccepted={() => {
            toggleTosModal();
          }}
          content={legalDocument1 ? legalDocument1.content : null}
        />
        <IOPModal
          isOpen={isIopModalOpen}
          toggle={toggleIopModal}
          setTacAccepted={() => {
            toggleIopModal();
          }}
          content={legalDocument2 ? legalDocument2.link : null}
        />
      </LoginAndRegisterSelector>
    </div>
  );
};
