import { ReactComponent as HokAutoClaimIcon } from '../img/claims/hok-claim-auto.svg';
import { ReactComponent as HokKaskoClaimIcon } from '../img/claims/hok-claim-kasko.svg';
import { ReactComponent as HokPropertyClaimIcon } from '../img/claims/hok-claim-property.svg';
import { ReactComponent as HokHealthClaimIcon } from '../img/claims/hok-claim-health.svg';
import { ReactComponent as HokAttorneyClaimIcon } from '../img/claims/hok-claim-lawyer.svg';
import { ReactComponent as HokAccidentClaimIcon } from '../img/claims/hok-claim-accident.svg';
import { ReactComponent as HokVesselClaimIcon } from '../img/claims/hok-claim-vassel.svg';
import { ReactComponent as HokLiabilityClaimIcon } from '../img/claims/hok-claim-liability.svg';
import { ReactComponent as HokAssistanceClaimIcon } from '../img/products/hok-assistance.svg';

// Claim types
export const CLAIM_CAR_LIABILITY = 'Autoodgovornost';
export const CLAIM_KASKO = 'Auto Kasko';
export const CLAIM_PROPERTY = 'Imovina';
export const CLAIM_HEALTH = 'Zdravstveno';
export const CLAIM_ATTORNEY = 'Odvjetnička odgovornost';
export const CLAIM_ACCIDENT = 'Nezgoda';
export const CLAIM_VESSELS = 'Plovila';
export const CLAIM_LIABILITY = 'Odgovornost';
export const CLAIM_ASSISTANCE = 'Asistencija';

// Policy pages
export const CLAIM_CAR_LIABILITY_PAGE = `car-liability`;
export const CLAIM_KASKO_PAGE = `kasko`;
export const CLAIM_PROPERTY_PAGE = `property-insurance`;
export const CLAIM_HEALTH_INSURANCE_PAGE = `health-insurance`;
export const CLAIM_ATTORNEY_PAGE = `attorney-liability`;
export const CLAIM_ACCIDENT_PAGE = `accident-insurance`;
export const CLAIM_VESSELS_PAGE = `vessels-insurance`;
export const CLAIM_LIABILITY_PAGE = `liability`;
export const CLAIM_ASSISTANCE_PAGE = 'assistance';

export const claimGroupDefs = {
  [CLAIM_CAR_LIABILITY]: { icon: HokAutoClaimIcon, cardName: 'car', route: CLAIM_CAR_LIABILITY_PAGE },
  [CLAIM_KASKO]: { icon: HokKaskoClaimIcon, cardName: 'kasko', route: CLAIM_KASKO_PAGE },
  [CLAIM_PROPERTY]: { icon: HokPropertyClaimIcon, cardName: 'property', route: CLAIM_PROPERTY_PAGE },
  [CLAIM_HEALTH]: { icon: HokHealthClaimIcon, cardName: 'health', route: CLAIM_HEALTH_INSURANCE_PAGE },
  [CLAIM_ATTORNEY]: { icon: HokAttorneyClaimIcon, cardName: 'attorney', route: CLAIM_ATTORNEY_PAGE },
  [CLAIM_ACCIDENT]: { icon: HokAccidentClaimIcon, cardName: 'accident', route: CLAIM_ACCIDENT_PAGE },
  [CLAIM_VESSELS]: { icon: HokVesselClaimIcon, cardName: 'vessel', route: CLAIM_VESSELS_PAGE },
  [CLAIM_LIABILITY]: { icon: HokLiabilityClaimIcon, cardName: 'liability', route: CLAIM_LIABILITY_PAGE },
  [CLAIM_ASSISTANCE]: { icon: HokAssistanceClaimIcon, cardName: 'assistance', route: CLAIM_ASSISTANCE_PAGE },
};

export const damageClaimGroup = path => {
  switch (path) {
    case CLAIM_CAR_LIABILITY_PAGE:
      return [CLAIM_CAR_LIABILITY, 'carLiabilityClaimPage', '/PrijavaŠtete_Autoodgovornost'];
    case CLAIM_KASKO_PAGE:
      return [CLAIM_KASKO, 'kaskoClaimPage', '/PrijavaŠtete_Kasko'];
    case CLAIM_PROPERTY_PAGE:
      return [CLAIM_PROPERTY, 'propertyClaimPage', '/PrijavaŠtete_Imovina'];
    case CLAIM_HEALTH_INSURANCE_PAGE:
      return [CLAIM_HEALTH, 'healthClaimPage', '/PrijavaŠtete_Zdravstveno'];
    case CLAIM_ATTORNEY_PAGE:
      return [CLAIM_ATTORNEY, 'attorneyClaimPage', '/PrijavaŠtete_Odvjetnicka_Odgovornost'];
    case CLAIM_ACCIDENT_PAGE:
      return [CLAIM_ACCIDENT, 'accidentClaimPage', '/PrijavaŠtete_Nezgoda'];
    case CLAIM_VESSELS_PAGE:
      return [CLAIM_VESSELS, 'vesselsClaimPage', '/PrijavaŠtete_OsiguranjePlovila'];
    case CLAIM_LIABILITY_PAGE:
      return [CLAIM_LIABILITY, 'liabilityClaimPage', '/PrijavaŠtete_Odgovornost'];
    case CLAIM_ASSISTANCE_PAGE:
      return [CLAIM_ASSISTANCE, 'assistanceClaimPage'];
    default:
      return [undefined, undefined];
  }
};

export const REPORT_NESRETNI_SLUCAJ = 'REPORT_NESRETNI_SLUCAJ';
export const REPORT_SMRTNI_SLUCAJ = 'REPORT_SMRTNI_SLUCAJ';
