import gql from 'graphql-tag';

export const GetCountriesQuery = gql`
  query GetCountries {
    countries: getCountry {
      countries {
        codeLegacy
        nameCro
      }
    }
  }
`;

export const GetPlacesQuery = gql`
  query GetPlaces {
    places: getAddresses(order: { name: ASC }, filter: { country: "HRVATSKA" }) {
      addresses {
        pttNumber
        name
      }
    }
  }
`;
