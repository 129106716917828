import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTE_TO_USER_MY_POLICIES_PAGE } from '../../constants/routes';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.pathname.includes(ROUTE_TO_USER_MY_POLICIES_PAGE)
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
