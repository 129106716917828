import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { handleRequestError } from '../../../../constants/errorCodes';
import { Spinner } from '../../../../containers';

export const QuickClaimFormData = ({ query, children }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const stateKey = get(params, 'damageReportId', null);
  const { data, loading, error } = useQuery(query, {
    variables: { stateKey },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    skip: !stateKey,
  });

  if ((stateKey && !data && !error) || loading) {
    return <Spinner className="spinner-positioning" />;
  }

  return children({
    data: get(data, 'serverStorage', null),
    error: !!error || (stateKey && !get(data, 'serverStorage.stateKey', null)),
    stateKey: get(data, 'serverStorage.stateKey', null) ? stateKey : '',
  });
};
