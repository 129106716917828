import { setContext } from 'apollo-link-context';
import jwt_decode from 'jwt-decode';
import { store } from './storeConfig';

import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';

import fetch from 'unfetch';

import { authTokenRefresh } from '../redux/actions/adminAuthActions';
import { getLocalStorageToken, /*removeLocalStorageToken,*/ setLocalStorageToken } from '../constants/LocalStorageKeys';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const link = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL ? `${process.env.REACT_APP_SERVER_URL}/ql/graphql` : '/ql/graphql',
  fetch,
});

const wsLink = new WebSocketLink(
  new SubscriptionClient(process.env.REACT_APP_SUBSCRIPTIONS_URI, {reconnect : true, lazy: true, timeout: 45000 })
 /*  {
  uri: process.env.REACT_APP_SUBSCRIPTIONS_URI || 'wss://dev.digitalna-poslovnica.hok-osiguranje.hr/ql/subscriptions',
  options: {
    reconnect: true,
    lazy: true,
    timeout: 45000,
  }, */
//}
);

const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists
  const token = getLocalStorageToken('authLink');
  // Check if token didn't expired.
  // const sessionTimeout = 60 * 30; // 30 min
  if (typeof token !== 'undefined' && token !== null) {
    // let decoded;
    try {
      /*decoded = */ jwt_decode(token);
      // console.log('DECODED', decoded);
    } catch (e) {
      // removeLocalStorageToken('authLink 1');
    }

    // if (typeof decoded.iat === 'number') {
    //   const currentMs = Math.floor(Date.now() / 1000);
    //   if (currentMs > decoded.iat + sessionTimeout) {
    //     removeLocalStorageToken('authLink 2');
    //   }
    // }
  }
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const getNewTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get('newtoken');
      if (refreshToken) {
        // Refresh/Set new token
        setLocalStorageToken(refreshToken, 'getNewTokenLink');
        store.dispatch(authTokenRefresh(refreshToken));
      }
    }

    return response;
  });
});

const defaultOptions = {
  watchQuery: {
    //fetchPolicy: '',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
};

export const cache = new InMemoryCache();
export const client = new ApolloClient({
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    getNewTokenLink.concat(authLink.concat(link)) // httpLink
  ),
  cache,
  defaultOptions,
});
