import gql from 'graphql-tag';

export const UserSubmitDamageClaimMutation = gql`
  mutation UserSubmitDamageClaim(
    $reporterEmail: String!
    $insurancePolicyNumber: String!
    $insurancePolicyOib: String!
    $claimClass: String
    $insured: reportClaimInsuredInput
    $peril: reportClaimPerilInput
    $note: String
    $vehicleLocation: vehicleLocationInput
  ) {
    initNewClaim(
      reporterEmail: $reporterEmail
      insurancePolicyNumber: $insurancePolicyNumber
      insurancePolicyOib: $insurancePolicyOib
      claimClass: $claimClass
      insured: $insured
      peril: $peril
      note: $note
      vehicleLocation: $vehicleLocation
    ) {
      confirmationCode
    }
  }
`;
