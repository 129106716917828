import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { handleRequestError } from '../../constants/errorCodes';
import { Spinner } from '..';
import { GetPaymentStatusQuery } from '../../operations/queries/GetPaymentStatusQuery';

export const PolicyContracting = ({ query, children }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const stateKey = get(params, 'contractingId', undefined);
  const confirmationCode = get(params, 'confirmationCode', undefined);

  const { data, loading, error } = useQuery(query, {
    variables: { stateKey },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    skip: !stateKey,
  });

  const { data: paymentData, error: paymentError, loading: paymentLoading } = useQuery(GetPaymentStatusQuery, {
    variables: { confirmationCode },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    skip: !confirmationCode,
  });

  if (
    (stateKey && !data && !error) ||
    loading ||
    (confirmationCode && !paymentData && !paymentError) ||
    paymentLoading
  ) {
    return <Spinner className="spinner-positioning" />;
  }

  return children({
    data: get(data, 'serverStorage.state', null) ? data.serverStorage : {},
    error: !!error || (stateKey && !get(data, 'serverStorage.stateKey', null)),
    stateKey: get(data, 'serverStorage.stateKey', null) ? stateKey : '',
    confirmationCode: get(paymentData, 'getPaymentData.confirmationCode', ''),
    paymentData: get(paymentData, 'getPaymentData', null),
    paymentError: !!paymentError,
  });
};
