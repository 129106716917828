import React, { useState } from 'react';
import { Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { formatDate } from '../../constants/dateFormatting';

export const SearchResultCard = React.memo(
  ({
    firstName,
    lastName,
    dateOfBirth,
    disabled,
    removePerson,
    openEditModal,
    fixedWidth = true,
    fixedDoBWidth = true,
  }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dropDownToggle = () => {
      setDropdownOpen(!dropdownOpen);
    };

    return (
      <Card className={'hok-search-result-card' + (!fixedWidth ? ' w-100' : '')}>
        <CardBody className="d-flex align-items-center justify-content-between">
          <div className="hok-search-card-container">
            <p>{firstName + ' ' + lastName}</p>
            <span className={fixedDoBWidth ? '' : 'w-auto'}>{formatDate(dateOfBirth)}</span>
          </div>
          {disabled ? (
            <div className="invisible" style={{ width: '40px' }}></div>
          ) : (
            <Dropdown direction="down" isOpen={dropdownOpen} toggle={dropDownToggle}>
              <DropdownToggle nav className="hok-rounded-icon-btn">
                <FontAwesomeIcon className="d-inline" icon="ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={openEditModal}>
                  <FormattedMessage id="buttons.change" />
                </DropdownItem>
                <DropdownItem onClick={removePerson}>
                  <FormattedMessage id="buttons.delete" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </CardBody>
      </Card>
    );
  }
);
