import React from 'react';
import { MY_POLICIES_HEALTH_INSURANCE_PAGE } from '../../../constants/business';
import { EndStep } from '../../../components/PolicyContractingSteps/EndStep';

export const Step4Health = () => {
  return (
    <EndStep
      policyTranslationBase="userMyPoliciesHealthInsurance"
      policyPage={MY_POLICIES_HEALTH_INSURANCE_PAGE}
      messageId="congratsMessage"
    />
  );
};
