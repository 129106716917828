import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { FormattedHTMLMessage } from 'react-intl';
import { googleAnalyticsEvent } from '../../utils/browser';

export const PolicyCard = ({
  text,
  Pic,
  counter,
  cardName,
  route,
  smOnly = false,
  translationBase = 'userPolicies',
  gaEventName,
}) => {
  const gaEventCallback = useCallback(() => {
    if (gaEventName) {
      googleAnalyticsEvent({ category: 'Proizvodi', action: gaEventName });
    }
  }, [gaEventName]);

  return (
    <div className="hok-card-policy-wrapper">
      {!smOnly && (
        <Link className="hok-card-policy-link" to={route} onClick={gaEventCallback}>
          <Card className="hok-card-policy">
            <CardBody>
              <Row className="mt-4 no-gutters">
                <Col xs={5} className="d-flex align-items-center justify-content-center pr-2">
                  <Pic height="72px" width="72px" />
                </Col>
                <Col xs={7} className="pl-2">
                  <div
                    className={
                      'h-100' + (counter ? '' : ' d-flex align-items-center justify-content-center flex-column')
                    }>
                    {!!counter && <p className="mt-2 mb-3 hok-card-counter text-center">{counter}</p>}
                    <p className="m-0 text-center">
                      <FormattedHTMLMessage id={`${translationBase}.cards.${cardName}`} />
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Link>
      )}
      <Link className="hok-card-policy-link-sm" to={route} onClick={gaEventCallback}>
        <Card className="hok-card-policy-sm">
          <Pic className="mt-2 hok-img-policy-sm" />
          <CardBody className="py-2 px-0">
            <p className={'hok-card-counter text-center m-0' + (!!counter ? '' : ' invisible')}>{counter}</p>
            <p className="text-center m-0 d-flex align-items-center h-100 justify-content-center">
              <FormattedHTMLMessage id={`${translationBase}.cards.${cardName}`} />
            </p>
          </CardBody>
        </Card>
      </Link>
    </div>
  );
};
