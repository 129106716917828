import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ScreenTitleContainer } from '../../../containers';
import { ClaimsCardList } from '../../../components/ClaimsCardList';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const SubmitDamageClaimPage = () => {
  return (
    <Container className="hok-container" fluid>
      <Row className="no-gutters">
        <Col>
          <ScreenTitleContainer row titleId="userReportDamagePage.title" />
          <ClaimsCardList />
        </Col>
      </Row>
      <AdFormIFrame title="Prijava Šteta" />
    </Container>
  );
};
