import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import UserAuthContainer from '../UserAuthContainer';
import { buildVersion } from '../../config/buildVersion';
import containerBgImg from '../../img/container-bg.png';
import { SupportInfoLink } from '../../views/client/SupportInfo/SupportInfoLink';

const LoginAndRegisterSelector = ({
  navLinks = [],
  titleTranslationId = '',
  showContactSupportLink = false,
  donwloadAppLinks = false,
  children,
  showSideImage = true,
  titleImage = null,
  minHeight = null,
  hideHeaderBorder = true,
  customHeader = null,
}) => {
  const renderHeading = () => {
    if (customHeader) {
      return <Row className={`no-gutters auth-nav-links ${hideBorder()}`}>{customHeader}</Row>;
    } else {
      if (navLinks.length > 0) {
        return (
          <Row className={`no-gutters auth-nav-links ${hideBorder()}`}>
            {navLinks.map(link => (
              <Col key={link.translationId} xs={Math.floor(12 / navLinks.length)}>
                <NavLink className="d-flex align-items-center justify-content-center" exact={link.exact} to={link.path}>
                  <FormattedMessage id={`routes.${link.translationId}`} />
                </NavLink>
              </Col>
            ))}
          </Row>
        );
      }
      return (
        <Row className={`no-gutters auth-nav-links ${hideBorder()}`}>
          <Col xs={12}>
            <h2 className="d-flex align-items-center justify-content-center">
              {titleTranslationId && <FormattedMessage id={titleTranslationId} />}
            </h2>
          </Col>
          {titleImage && (
            <Col xs={12}>
              <img className="img-fluid" src={titleImage} alt="heading" />
            </Col>
          )}
        </Row>
      );
    }
  };

  const hideBorder = () => {
    if (hideHeaderBorder && navLinks.length === 0) {
      return 'no-border';
    }
  };

  return (
    <UserAuthContainer>
      {showSideImage && (
        <div className="container-image">
          <img className="img-fluid invisible" src={containerBgImg} alt="HOK" />
        </div>
      )}
      <div className="container-form align-self-stretch">
        <div className="container-form-aux pb-2" style={{ minHeight: `${minHeight}px` }}>
          <header>{renderHeading()}</header>
          <main>{children}</main>
          <div>
            {donwloadAppLinks && (
              <Row className="no-gutters mb-4 mb-md-3">
                <Col xs={12} className="text-center">
                  <p>
                    <FormattedMessage id="downloadApp" />:
                  </p>
                </Col>
                <Col xs={12} className="hok-download-app-img text-center">
                  {process.env.REACT_APP_APPLE_STORE_LINK && (
                    <a
                      href={process.env.REACT_APP_APPLE_STORE_LINK}
                      aria-label="Apple App Store"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="img-app-download app-store"></i>
                    </a>
                  )}
                  {process.env.REACT_APP_GOOGLE_PLAY_LINK && (
                    <a
                      href={process.env.REACT_APP_GOOGLE_PLAY_LINK}
                      aria-label="Google Play Store"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="img-app-download google-play"></i>
                    </a>
                  )}
                </Col>
              </Row>
            )}
            {showContactSupportLink && <SupportInfoLink />}
            <Row className="no-gutters px-4 d-flex align-self-end w-100">
              <Col className="text-center" xs={12}>
                <span className="hok-version">{buildVersion}</span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </UserAuthContainer>
  );
};

export default LoginAndRegisterSelector;
