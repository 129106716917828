import gql from 'graphql-tag';

export const GetWSPayLinkMutation = gql`
  mutation GetWSPayLink($confirmationCode: String!) {
    getWSPayLink(confirmationCode: $confirmationCode) {
      FormURL
      ShopID
      ShoppingCartID
      TotalAmount
      Signature
      ReturnURL
      ReturnErrorURL
      CancelURL
      Version
      CustomerFirstName
      CustomerLastName
      CustomerAddress
      CustomerCity
      CustomerZIP
      CustomerCountry
      CustomerPhone
      CustomerEmail
      TotalAmount
      IntCurrency
      IntAmount
    }
  }
`;
