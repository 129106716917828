import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Label, InputGroup, Input } from 'reactstrap';
import { useMutation, useLazyQuery } from 'react-apollo';
import moment, { ISO_8601 } from 'moment';
import { get } from 'lodash-es';
import { PaymentStep } from '../../../components/PolicyContractingSteps/PaymentStep';
import {
  ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_4,
  ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3,
  ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE,
} from '../../../constants/routes';
import { handleRequestError } from '../../../constants/errorCodes';
import { GetPaymentStatusQuery } from '../../../operations/queries/GetPaymentStatusQuery';
import { BuyPzoProductMutation } from '../../../operations/mutations/BuyProductMutation';
import { PaymentStatusSubscription } from '../../../operations/subscriptions/PaymentStatusSubscription';
import { PAYMENT_STATUS_CANCELED, PAYMENT_STATUS_PAID, PAYMENT_STATUS_ERROR } from '../../../constants/business';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButtonArrowRight } from '../../../containers';
import { formatDate } from '../../../constants/dateFormatting';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';

export const Step3Travel = ({ data, stateKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const win = useRef(null);
  const newWindowTitle = formatMessage({ id: 'loading' });

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const insuranceStartDate = moment(data.insuranceStartDate).isSameOrAfter(moment(), 'day')
    ? moment(data.insuranceStartDate, ISO_8601)
    : moment();

  const [getPaymentStatus, { data: paymentData, subscribeToMore }] = useLazyQuery(GetPaymentStatusQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });
  const [deleteServerStorageRecord] = useMutation(SetServerStorageMutation, {
    variables: { stateKey, shouldDelete: true },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  useEffect(() => {
    if (get(paymentData, 'getPaymentData.confirmationCode', '')) {
      subscribeToMore({
        document: PaymentStatusSubscription,
        variables: { confirmationCode: paymentData.getPaymentData.confirmationCode },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return { getPaymentData: subscriptionData.data.payments };
        },
        onError: error => handleRequestError(error, dispatch, { silent: true }),
      });
    }

    if (get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_PAID) {
      deleteServerStorageRecord();
      history.push(`${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_4}/${paymentData.getPaymentData.confirmationCode}`);
    }
    if (
      get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_CANCELED ||
      get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_ERROR
    ) {
      setButtonDisabled(false);
    }
  }, [dispatch, history, paymentData, subscribeToMore, deleteServerStorageRecord]);


  useEffect(() => {
    window.addEventListener('storage', () => {
      let _tabToggleState = localStorage.getItem('tabStateStorage');
      if(_tabToggleState == "true"){
        history.push('/dashboard');
      }
    })
  }, [history])

  const [buyPzo] = useMutation(BuyPzoProductMutation, {
    variables: {
      productRelatedData: {
        productPackage: data.productPackage,
        travelType: data.travelType,
        productType: data.productType,
        additionalProductPackage: data.additionalProductPackage,
        insuranceStartDate: insuranceStartDate,
        insuranceEndDate: data.insuranceEndDate,
        insuredPeople: data.insuredPeople,
      },
    },
    onError: error => {
      win.current.close();
      setButtonDisabled(false);
      handleRequestError(error, dispatch);
    },
    onCompleted: data => {
      const confirmationCode = get(data, 'buyInsurancePolicyHokPzo.confirmationCode', null);
      if (confirmationCode) {
        getPaymentStatus({ variables: { confirmationCode } });
        // Workaround to not trigger popup blocker due to window.open() not being called
        // immediately after user action (onClick event) but after an async function
        win.current.location = `${ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE}/${confirmationCode}`;
      }
    },
  });

  const onClick = useCallback(() => {
    win.current = window.open();
    win.current.document.title = newWindowTitle;
    setButtonDisabled(true);
    buyPzo();
  }, [buyPzo, newWindowTitle]);

  const duration = Math.ceil(
    moment(data.insuranceEndDate, ISO_8601)
      .endOf('day')
      .diff(insuranceStartDate.startOf('day'), 'days', true)
  );

  return (
    <PaymentStep
      policyTranslationBase="userMyPoliciesTravelInsurance"
      premiumAmount={data.totalPremium}
      nextStep={ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_4}
      data={data}
      onClick={onClick}
      loaderProp={buttonDisabled}>
      <Row className="mt-4 mb-3">
        <Col>
          <h3>
            <FormattedMessage id="userMyPolicies.policyData.title" />:
          </h3>
        </Col>
      </Row>
      <Row>
        <Col sm="6" lg="4">
          <Label for="validFrom">
            <FormattedMessage id="inputs.validFrom" />
          </Label>
          <InputGroup>
            <Input id="validFrom" disabled defaultValue={formatDate(insuranceStartDate)} />
          </InputGroup>
        </Col>
        <Col sm="6" lg="4">
          <Label for="validTo">
            <FormattedMessage id="inputs.validTo" />
          </Label>
          <InputGroup>
            <Input id="validTo" disabled defaultValue={formatDate(data.insuranceEndDate)} />
          </InputGroup>
        </Col>
        <Col sm="6" lg="4">
          <Label for="input">
            <FormattedMessage id="inputs.duration" />
          </Label>
          <InputGroup>
            <Input disabled defaultValue={duration ? `${duration} ${formatMessage({ id: 'days' })}` : ''} />
          </InputGroup>
        </Col>
        <Col sm="6" lg="4">
          <Label for="input">
            <FormattedMessage id="inputs.insuredPeopleAmount" />
          </Label>
          <InputGroup>
            <Input disabled defaultValue={data.insuredPeople.length} />
          </InputGroup>
        </Col>
        <Col sm="6" lg="4">
          <Label for="insurancePackage">
            <FormattedMessage id="inputs.insuredPeople" />
          </Label>
          <InputGroup>
            <CustomButtonArrowRight
              placeholder="userTravelInsurancePage.insuredPeoplePage"
              className="custom-header-text text-uppercase"
              onClick={() => history.push(`${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3}/${stateKey}/insured-people`)}
            />
          </InputGroup>
        </Col>
      </Row>
    </PaymentStep>
  );
};
