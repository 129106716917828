import React from 'react';
import { InputGroup, Input, InputGroupAddon, FormFeedback } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  intl: state.intl,
});

export default injectIntl(
  connect(mapStateToProps)(
    ({
      type = 'text',
      id: fieldId,
      fieldName = fieldId,
      placeholder = true,
      placeholderId = fieldId,
      customComponent,
      fieldState,
      formSubmitted,
      Icon,
      reactIntl,
      intl,
      dispatch,
      ...rest
    }) => {
      const Component = customComponent || Input;

      const inputIconStateHandler = () => {
        return (
          (fieldState.focused ? 'focused' : '') + (formSubmitted && fieldState.invalid ? ' is-invalid' : '')
        ).trim();
      };

      return (
        <InputGroup>
          <Component
            type={type}
            id={fieldId}
            name={fieldName}
            aria-label={fieldName}
            invalid={formSubmitted && fieldState.invalid}
            placeholder={placeholder ? reactIntl.formatMessage({ id: `inputs.${placeholderId}` }) : ''}
            {...rest}
          />
          {Icon && (
            <InputGroupAddon addonType="append" className={inputIconStateHandler(fieldId)}>
              <Icon
                className={
                  'svg-input-icon' +
                  (fieldState.focused ? ' svg-red ' : '') +
                  (formSubmitted && fieldState.invalid ? ' invalid ' : '')
                }
              />
            </InputGroupAddon>
          )}
          <FormFeedback>{fieldState.error}</FormFeedback>
        </InputGroup>
      );
    }
  ),
  { intlPropName: 'reactIntl' }
);
