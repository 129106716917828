import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { PolicyCard } from '../../../containers/PolicyCard';
import {
  // ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE,
  ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE,
  ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE,
  ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
  ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE,
  ROUTE_TO_USER_POLICIES_KASKO_INSURANCE_PAGE,
} from '../../../constants/routes';
import { ScreenTitleContainer } from '../../../containers';

//Images
import { ReactComponent as HokAoIcon } from '../../../img/products/hok-ao.svg';
import { ReactComponent as HokKaskoIcon } from '../../../img/products/hok-kasko.svg';
import { ReactComponent as HokMdIcon } from '../../../img/products/hok-md.svg';
// import { ReactComponent as HokZoIcon } from '../../../img/products/hok-zo.svg';
import { ReactComponent as HokPzoIcon } from '../../../img/products/hok-pzo.svg';
import { ReactComponent as HokAssistanceIcon } from '../../../img/products/hok-assistance.svg';
import { ReactComponent as HokOoIcon } from '../../../img/products/hok-oo.svg';
import { ReactComponent as HokAccidentIcon } from '../../../img/products/hok-nezgoda.svg';
import { ReactComponent as HokVesselsIcon } from '../../../img/products/hok-plovila.svg';
import { ReactComponent as HokTransportIcon } from '../../../img/products/hok-transport.svg';
import { ReactComponent as HokLiabilityIcon } from '../../../img/products/hok-odgovornost.svg';
import { ReactComponent as HokDriverLiabilityIcon } from '../../../img/products/hok-ovozara.svg';
import { ReactComponent as HokOtherIcon } from '../../../img/products/hok-other.svg';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../constants/support';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserPolicies = () => {
  const policyList = {
    cards: [
      { id: 1, cardName: 'car', Pic: HokAoIcon, counter: 3, route: ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE },
      { id: 2, cardName: 'kasko', Pic: HokKaskoIcon, counter: 3, route: ROUTE_TO_USER_POLICIES_KASKO_INSURANCE_PAGE },
      {
        id: 3,
        cardName: 'realEstate',
        Pic: HokMdIcon,
        counter: 7,
        route: ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE,
      },
      // { id: 4, cardName: 'health', Pic: HokZoIcon, counter: 6, route: ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE },
      { id: 5, cardName: 'travel', Pic: HokPzoIcon, counter: 1, route: ROUTE_TO_USER_POLICIES_TRAVEL_INSURANCE_PAGE },
      {
        id: 6,
        cardName: 'assistance',
        Pic: HokAssistanceIcon,
        counter: 1,
        route: ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE,
      },
      {
        id: 7,
        cardName: 'judicial',
        Pic: HokOoIcon,
        counter: 2,
        route: {
          pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
          state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Odvjetnička odgovornost"' },
        },
        gaEventName: 'Proizvodi_Odvjetnicka_Odgovornost',
      },
      {
        id: 8,
        cardName: 'accident',
        Pic: HokAccidentIcon,
        counter: 2,
        route: {
          pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
          state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Osiguranje nezgode"' },
        },
        gaEventName: 'Proizvodi_Osiguranje_Nezgode',
      },
      {
        id: 9,
        cardName: 'vessel',
        Pic: HokVesselsIcon,
        counter: 2,
        route: {
          pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
          state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Osiguranja plovila"' },
        },
        gaEventName: 'Proizvodi_Osiguranje_Plovila',
      },
      {
        id: 10,
        cardName: 'transport',
        Pic: HokTransportIcon,
        counter: 2,
        route: {
          pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
          state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Transportno osiguranje"' },
        },
        gaEventName: 'Proizvodi_Transportno_Osiguranje',
      },
      {
        id: 11,
        cardName: 'liability',
        Pic: HokLiabilityIcon,
        counter: 2,
        route: {
          pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
          state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Osiguranje od odgovornosti"' },
        },
        gaEventName: 'Proizvodi_Odgovornost',
      },
      {
        id: 12,
        cardName: 'driverLiability',
        Pic: HokDriverLiabilityIcon,
        counter: 2,
        route: {
          pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
          state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Osiguranje vozara"' },
        },
        gaEventName: 'Proizvodi_Osiguranje_Vozara',
      },
      {
        id: 13,
        cardName: 'other',
        Pic: HokOtherIcon,
        counter: 2,
        route: {
          pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
          state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "Ostala osiguranja"' },
        },
        gaEventName: 'Proizvodi_Ostala_Osiguranja',
      },
    ],
  };

  const renderPolicyCards = () => {
    const cards = policyList.cards.map(card => {
      const { id, Pic, cardName, text, route, gaEventName } = card;
      return <PolicyCard route={route} key={id} text={text} Pic={Pic} cardName={cardName} gaEventName={gaEventName} />;
    });
    return cards;
  };

  return (
    <Container className="hok-container" fluid>
      <Row>
        <Col className="mb-4">
          <Row className="mb-3">
            <Col className="page-container" xs="12">
              <ScreenTitleContainer row titleId="userPolicies.title" />
              <Row className="d-flex cards-container no-gutters mt-5">{renderPolicyCards()}</Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <AdFormIFrame title="Proizvodi" />
    </Container>
  );
};
