import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

export const DEFAULT_TOASTER = toast.TYPE.DEFAULT;
export const INFO_TOASTER = toast.TYPE.INFO;
export const WARNING_TOASTER = toast.TYPE.WARNING;
export const SUCCESS_TOASTER = toast.TYPE.SUCCESS;
export const ERROR_TOASTER = toast.TYPE.ERROR;

const options = {
  hideProgressBar: true,
  draggable: false,
  autoClose: 7000,
};

export const renderToaster = (message, type, toastConfig = { intlKey: false, key: null }) => {
  toast(toastConfig.intlKey ? <FormattedMessage id={message} /> : message, { type, key: toastConfig.key, ...options });
};
