import FileSaver from 'file-saver';

const b64TransformData = (rawData, sliceSize = 512) => {
  const byteCharacters = atob(rawData);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return byteArrays;
};

const APPLICATION_OCTET_STREAM = 'application/octet-stream';

export const saveFileToDevice = (fileName, base64Data) => {
  const formattedData = b64TransformData(base64Data);
  const file = new Blob(formattedData, { type: APPLICATION_OCTET_STREAM });

  FileSaver.saveAs(file, fileName);
};
