import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ROUTE_TO_USER_LOGOUT_PAGE } from '../../constants/routes';
import { ReactComponent as HokLogoutIcon } from '../../img/icons/hok-logout.svg';

export const LogoutLink = ({ className }) => (
  <Link className={'link-logout' + (className ? ' ' + className : '')} to={ROUTE_TO_USER_LOGOUT_PAGE}>
    <span className="mr-2">
      <FormattedMessage id="logout" />
    </span>
    <HokLogoutIcon className="svg-red" style={{ width: 16, height: 16 }} />
  </Link>
);

export const LogoutLinkRow = () => {
  return (
    <Row className="no-gutters mt-3">
      <Col xs={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} className="text-right">
        <LogoutLink />
      </Col>
    </Row>
  );
};
