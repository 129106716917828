import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { CustomInputField, CustomButton } from '../../../containers';
import { validate } from '../../../utils/validation';
import { userForgotPassword, clearUserSuccessMessage } from '../../../redux/actions/userAuthActions';
import { SupportInfoLink } from '../SupportInfo/SupportInfoLink';

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(userForgotPassword(email)),
  clearUserSuccessMessage: payload => dispatch(clearUserSuccessMessage(payload)),
});

class ForgotPassword extends Component {
  state = {
    isFormValid: false,
    submitted: false,
    formData: {
      email: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.email" />,
        validation: {
          required: true,
          email: true,
        },
      },
    },
  };

  removeFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: false } },
    });
  };

  addFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: true } },
    });
  };

  inputHandler = (field, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: {
          ...this.state.formData[field],
          value: value,
        },
      },
    });
  };

  runForgotPassword = event => {
    event.preventDefault();
    const validation = validate(this.state.formData.email);
    const email = {
      ...this.state.formData.email,
      invalid: validation.invalid,
      error: validation.errors[0],
    };

    this.setState({ isFormValid: !email.invalid, submitted: true, formData: { ...this.state.formData, email } }, () => {
      if (!email.invalid) {
        // Forgot password action
        this.props.forgotPassword({ email: email.value });
        this.setState({ submitted: false });
      }
    });
  };

  resetSendForgotPasswordEmailState = () => {
    if (get(this.props, 'auth.forgotPassword.done', false)) {
      this.props.clearUserSuccessMessage('forgotPassword');
    }
  };

  render() {
    const submitRecoverRequestForm = (
      <Col xs={12} sm={{ offset: 1, size: 10 }}>
        <Row className="no-gutters mt-4">
          <Col>
            <p>
              <FormattedMessage id="forgotPasswordPage.message" />
            </p>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col xs={12} sm={{ offset: 1, size: 10 }}>
            {get(this.props, 'auth.forgotPassword.errorMessage', null) && (
              <Alert color="danger" role="alert">
                {this.props.auth.forgotPassword.errorMessage}
              </Alert>
            )}
            <Form onSubmit={this.runForgotPassword}>
              <FormGroup row>
                <Col>
                  <CustomInputField
                    id="email"
                    name="email"
                    fieldState={this.state.formData.email}
                    formSubmitted={this.state.submitted}
                    type="text"
                    maxLength={255}
                    onChange={event => this.inputHandler('email', event.target.value)}
                    onFocus={this.addFocus}
                    onBlur={this.removeFocus}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-5">
                <Col xs="12">
                  <CustomButton loaderProp={get(this.props, 'auth.forgotPassword.inProgress', false)} />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Col>
    );

    const recoverRequestSuccess = (
      <Col xs={12} sm={{ offset: 1, size: 10 }}>
        <Row className="no-gutters mt-4">
          <Col>
            <p>
              <FormattedMessage id="forgotPasswordPage.successTitle" />
            </p>
          </Col>
        </Row>
        <Row className="no-gutters mt-2">
          <Col>
            <p className="font-weight-normal">
              <FormattedMessage id="forgotPasswordPage.successMessage" />
            </p>
          </Col>
        </Row>
        <Row className="no-gutters mt-5">
          <Col xs={12} sm={{ size: 10, offset: 1 }}>
            <CustomButton
              translationId="continue"
              onClick={() => {
                this.resetSendForgotPasswordEmailState();
                this.props.toggle();
              }}
            />
          </Col>
        </Row>
      </Col>
    );

    const forgotPasswordDone = get(this.props, 'auth.forgotPassword.done');
    const forgotPasswordSuccess = get(this.props, 'auth.forgotPassword.success');

    return (
      <Modal modalClassName="hok-modal" centered toggle={this.props.toggle} isOpen={this.props.isOpen}>
        <ModalHeader>
          <Row className="no-gutters auth-nav-links">
            <Col xs={12}>
              <h2 className="d-flex align-items-center justify-content-center">
                <FormattedMessage id="forgotPasswordPage.title" />
              </h2>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody className="client">
          <Row className="no-gutters px-4">
            {forgotPasswordDone && forgotPasswordSuccess ? recoverRequestSuccess : submitRecoverRequestForm}
          </Row>
        </ModalBody>
        <ModalFooter>
          <SupportInfoLink />
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
