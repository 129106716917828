import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ScreenTitleContainer, Spinner, ArrowLinkButton, CustomButton } from '../../../containers';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { GetSingleFAQQuery } from '../../../operations/queries/GetFAQsQuery';
import { handleRequestError } from '../../../constants/errorCodes';
import { ROUTE_TO_USER_FAQS_PAGE, ROUTE_TO_USER_SUPPORT_PAGE } from '../../../constants/routes';
import { SupportInfo } from '../../../components/SupportInfo';

export const SingleFAQPage = () => {
  const dispatch = useDispatch();
  const { faqId } = useParams();
  const history = useHistory();

  const { data, loading, error } = useQuery(GetSingleFAQQuery, {
    variables: { filter: { id: parseInt(faqId, 10) } },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  if (loading || (!data && !error)) {
    return <Spinner className="spinner-positioning" />;
  }

  if (error) {
    return <Redirect to={ROUTE_TO_USER_FAQS_PAGE} />;
  }

  const question = get(data, 'FAQs.FAQs[0]', {});

  return (
    <Container fluid className="hok-container">
      <ArrowLinkButton className="mb-4" linkTo={ROUTE_TO_USER_FAQS_PAGE} />
      <ScreenTitleContainer row titleId={question.subject} raw />
      <Row className="no-gutters">
        <Col>
          <p className="hok-text-dark">{question.description}</p>
        </Col>
      </Row>
      <SupportInfo className="my-4" showEmail={false} showPhoneNumber={false} />
      <Row className="no-gutters">
        <Col>
          <CustomButton
            type="button"
            className="hok-dashboard-btn-wide"
            block={false}
            translationId="customerSupport"
            onClick={() => history.push(ROUTE_TO_USER_SUPPORT_PAGE)}
          />
        </Col>
      </Row>
    </Container>
  );
};
