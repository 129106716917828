import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash-es';
import {
  TAC,
  TOS,
  VEHICLE_DATA,
  AO_PLUS,
  POLICY_PACKAGE_HEALTH_1,
  POLICY_PACKAGE_HEALTH_2,
  POLICY_PACKAGE_HEALTH_3,
  POLICY_PACKAGE_TRAVEL_A,
  POLICY_PACKAGE_TRAVEL_B,
  POLICY_PACKAGE_TRAVEL_C,
  POLICY_COVER_INCIDENT_A,
  POLICY_COVER_INCIDENT_B,
  POLICY_COVER_INCIDENT_C,
  ASSISTANCE_EXPRESS,
  ASSISTANCE_TRAVEL,
  ASSISTANCE_HR,
  ASSISTANCE_RENTACAR,
  ASSISTANCE_HOME,
  INSURED_PERSON_DATA,
  MOJ_DOM,
  MOJ_DOM_KUCANSTVO,
  HOK_IMOVINA_POTRES,
  HOK_IMOVINA_PROVALA,
  HOK_IMOVINA_STAKLO,
  HOK_IMOVINA_STROJ,
  HOK_IMOVINA_IZGRADNJA,
  HOK_IMOVINA_MONTAZA,
  HOK_BONUS_GARANT,
  HOK_AUTONEZGODA,
  CAR_LIABILITY_COVER_PRAVNA,
  HR_ASISTENCIJA,
  HOK_RENT_A_CAR,
  HOK_EXPRESS_ASISTENCIJA,
  CAR_LIABILITY_COVER_KASKO_START,
  CAR_LIABILITY_COVER_KASKO_PRO,
  CAR_LIABILITY_COVER_KASKO_FORTE,
  VEHICLE_DATA_AO_SHORT,
  VEHICLE_DATA_AO,
  OSNOVNI_PAKET,
  RISK_HOK_AUTONEZGODA,
  STANDARDNI_PAKET,
  OPTIMALNI_PAKET,
  HOK_APARTMAN_IMOVINA,
  HOK_APARTMAN_GUBITAK,
  HOK_APARTMAN_ODGOVORNOST,
  HOK_APARTMAN_NEZGODA,
  HOK_KUCNA_ASISTENCIJA,
} from '../../constants/business';
import {
  ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_EXAMINATIONS_PAGE,
  ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_MEDICAL_PAGE,
} from '../../constants/routes';
import {
  CLAIM_CAR_LIABILITY,
  CLAIM_KASKO,
  CLAIM_PROPERTY,
  CLAIM_ATTORNEY,
  CLAIM_VESSELS,
  CLAIM_ACCIDENT,
  CLAIM_HEALTH,
  CLAIM_LIABILITY,
} from '../../constants/claimDefs';
import {
  CANCEL_CONTRACTING,
  SELF_INSURANCE_CONTRACTING,
  TRAVEL_GROUP_TYPE_MODAL,
  TRAVEL_PURPOSE_MODAL,
  EXPRESS_ELIGIBILITY_MODAL,
  VEHICLE_INSURANCE_INELIGIBILITY,
  VEHICLE_PURPOSE_RACING,
  VEHICLE_PURPOSE,
  VEHICLE_AGE_CHECK,
  VEHICLE_OWNER_DISABILITY,
  SAVE_CHANGES,
  VEHICLE_INFO_ENTRY,
  NOT_ELIGIBLE_AO_VEHICLE_TYPE,
  TRAVEL_PARTICIPATING,
  CONTINUE_PREVIOUS_CONTRACTING,
  CONTRACTING_AGE_ELIGIBILITY,
  DAMAGE_CLAIM_SUBMIT_SUCCESS,
  DAMAGE_CLAIM_SUBMIT_FAIL,
} from '../../constants/modalsContent';
import { formatDate } from '../../constants/dateFormatting';
import { USER_ACCOUNT_BUSINESS } from '../../constants/userDefs';
import { ContactNumber } from '../../views/client/GeneralInfo';
import { ReactComponent as HokEmailIcon } from '../../img/icons/hok-email.svg';
import { currencyConversionEuro } from '../../utils/currencyConversion';

const DescriptionText = ({ value, data = {}, showHzzo, spaced = false }) => {
  const { formatMessage } = useIntl();

  const vehicleTechSpecs = get(data, 'vrstaVozilaNaziv', '')
    ? get(data, 'vrstaVozilaNaziv', '').includes('L')
      ? `${get(data, 'obujamMotora', '')} ccm`
      : `${get(data, 'snagaMotora', '')} kW`
    : '';

  switch (value) {
    case TAC:
      return (
        <div className="tac-text">
          <h3 className="mb-3">INFORMACIJE O UGOVARATELJU</h3>
          <p>
            Ovime potvrđujem da sam upoznat s Informacijama o ugovaratelju osiguranja koje prihvaćam sukladno odredbama
            Zakona o osiguranju, Zakona o zaštiti potrošača i Zakona o elektroničkoj trgovini.{' '}
          </p>
          <p>
            Suglasan/suglasna sam da HOK osiguranje d.d. prikuplja i obrađuje moje osobne podatke u svrhu sklapanja
            ugovora o osiguranju i provedbe prava i obveza iz ugovora o osiguranju, te ih obrađuje u marketinške svrhe.
          </p>
          <p>
            Suglasan/suglasna sam da se osobni podaci obrađuju i dostavljaju nadležnim državnim tijelima sukladno
            pozitivnim zakonskim propisima.{' '}
          </p>
          <p>
            Suglasan/suglasna sam da HOK osiguranje d.d. od mene osobno i od trećih osoba, kao što su liječnici, sudovi,
            druga nadzorna i regulatorna državna tijela, pravne osobe, drugi osiguratelji i sl., prikupi podatke vezane
            uz ugovor o osiguranju, a koji su neophodno potrebni za procjenu osnovanosti i ispunjavanje potraživanja
            koja proizlaze iz ugovora o osiguranju.{' '}
          </p>
          <p>
            Ovime razrješavam treće osobe obveze čuvanja poslovne tajne u odnosu na naprijed navedene podatke neophodne
            za provedbu prava i obveza iz ugovora o osiguranju.{' '}
          </p>
          <p>HOK osiguranje d.d. će čuvati osobne podatke kao profesionalnu tajnu.</p>
          <p>
            Sukladno odredbi članka 927. Zakona o obveznim odnosima ugovorni odnos po ovom ugovoru o osiguranju (polici)
            nastaje uplatom premije osiguranja u cijelosti.{' '}
          </p>
          <p>
            Informacije ugovaratelju osiguranje i ponuda osiguranja čine sastavni dio elektronskog ugovora o osiguranje.
            Ovisno o ugovorenoj polici sastavni dio elektronskog ugovora o osiguranje čine i za:
          </p>
          <p>Putno zdravstveno osiguranje </p>
          <p>UVJETI ZA PUTNO ZDRAVSTVENO OSIGURANJE [link na pdf]</p>
          <p>
            Poznato mi je i prihvaćam da odustati od ovog elektronskog ugovora o osiguranju mogu isključivo temeljem
            pismenog zahtjeva upućenog na HOK osiguranje d.d. u roku ne kasnijem od početka ugovora o osiguranju
            navedenog na elektronski izdanoj polici.{' '}
          </p>
          <p>Potvrđujem da sam upoznat/a s uvjetima i načinom podnošenja pritužbi. </p>
          <p>Za sve informacije klijenti se mogu obratiti na besplatni info telefon 0800 5080.</p>
        </div>
      );
    case TOS:
      return (
        <div>
          Osnovni podaci o Društvu Osiguravajuće društvo, HRVATSKA OSIGURAVAJUĆA KUĆA – dioničko društvo za osiguranje,
          10 000 Zagreb, Capraška ulica 6, skraćena tvrtka HOK-OSIGURANJE d.d, upisan u registar Trgovačkog suda u
          Zagrebu, matični broj subjekta 080401248, OIB: 00432869176 (u daljnjem tekstu Društvo). Osiguratelj je
          ovlašten obavljati djelatnost sklapanja i ispunjavanja ugovora o neživotnom osiguranju temeljem dozvole,
          Klasa: UP/I-453-02/07-30/32, Ur.broj: 326-112-07-3, koju je izdala Hrvatska agencija za nadzor financijskih
          usluga, Miramarska 24b, Zagreb. Općenito Moj Hok/HOK digital/HOK DP (u daljnjem tekstu: Moj HOK) mobilna
          aplikacija može se koristiti za privatnu upotrebu bez ikakvih naknada za korištenje, a prema navedenim
          uvjetima i pravilima. Korištenjem Moj HOK mobilne aplikacije, smatra se da je korisnik u cijelosti pročitao,
          razumio i prihvatio navedene Uvjete korištenja, Opće uvjete, Uvjete osiguranja i Informacije o obradi osobnih
          podataka. Također, korištenjem Moj HOK mobilne aplikacije, korisnici daju svoju suglasnost da im
          HOK-OSIGURANJE d.d. povremeno šalje informacije o uslugama. Korisnici nas mogu obavijestiti ako ne žele
          primati daljnje informacije na adresu: info@hok-osiguranje.hr Putem Moj HOK besplatne usluge Društvo
          klijentima omogućuje pregled vlastitih polica, sklapanje novih ugovora o osiguranju (polica) i obnovu
          postojećih, plaćanje premije i prijavu štete. Namijenjeno je fizičkim osobama i obrtima, ugovarateljima
          HOK-OSIGURANJA d.d., ali i onima koji su samo oštećenici. Korištenjem usluge Moj Hok dobivate: -Ažurni pregled
          svih svojih polica osiguranja -Financijski status polica -Uvid u izdane uplatnice/račune -Mogućnost prijave
          štete i zahtjeva za isplatom -Pregled statusa prijavljenih šteta i isplata Moj HOK pruža Vam mogućnosti
          sklapanja ugovora o osiguranju, tj. uslugu ugovaranja polica osiguranja i omogućava kupovinu sljedećih
          proizvoda osiguranja: -Obvezno osiguranje od automobilske odgovornosti -Osiguranje automobilskog kaska
          -Dopunsko i dodatno zdravstveno osiguranje -Putno zdravstveno osiguranje -Osiguranje imovine (MOJ DOM) Ugovor
          je sklopljen plaćanjem premije. Kako bi Vaša polica bila valjana, uplata mora biti podnesena prije označenog
          datuma važenja police. Osiguratelj ima pravo na odbijanje prihvata ponude, a time i izdavanja police, ukoliko
          se prilikom pregleda vozila ustanovi da vozilo ne odgovara prijavljenim podacima prilikom izrade ponude radi
          sklapanja ugovora o osiguranju, odnosno ukoliko je vozilo oštećeno. Opći uvjeti Ukoliko korisnik mobilne
          aplikacije Moj HOK sklopi ugovor o osiguranju, a nije osiguranik/vlasnik vozila, ovime potvrđuje da ima
          ovlaštenje osiguranika/vlasnika vozila za sklapanje tog ugovora, te za plaćanje premije osiguranja, kao i
          suglasnost za korištenje osobnih podataka koji se odnose na osiguranika/vlasnika vozila za vrijeme trajanja
          ugovora o osiguranju. HOK-OSIGURANJE d.d. u obvezi je čuvati te podatke kao poslovnu tajnu. Korisnik ovime
          potvrđuje da je upoznat sa svim relevantnim informacijama o HOK-OSIGURANJU d.d. i proizvodima koje propisuje
          Zakon o zaštiti potrošača i Zakon o osiguranju. Korisnik ovime daje izričitu privolu HOK-OSIGURANJU d.d. da
          prikuplja i dalje obrađuje njegove osobne podatke, kao i sve druge podatke za koje sazna pružanjem
          osigurateljnih usluga sukladno zakonskim propisima, sve u svrhu obavljanja svog predmeta poslovanja. Detaljne
          informacije o obradi osobnih podataka, korisnik može pročitati i preuzeti ovdje: Informacije o obradi podataka
          Ovime korisnik potvrđuje da je upoznat sa svim relevantnim odredbama Zakona o obveznim odnosima koje uređuju
          ponudu i prihvat ponude, osobito sa značenjem ponude pri sklapanju osiguranja, kao i da je upoznat sa
          sljedećim uvjetima u skladu s kojima se ugovaraju ova osiguranja te da ih prihvaća. -Uvjeti za Autoodgovornost
          -Uvjeti za Auto Kasko -Uvjeti za Zdravstveno -Uvjeti za Moj Dom Korisnik također potvrđuje i da je upoznat sa
          svim relevantnim odredbama Zakona o zaštiti potrošača koje uređuju potrošački ugovor o financijskim uslugama
          sklopljenim na daljinu, u skladu s čime ovime daje suglasnost da se ugovor o osiguranju sklopi uporabom usluge
          mobilne aplikacije kao sredstvom daljinske komunikacije te potvrđuje da je obaviješten o HOK-OSIGURANJU d.d.
          kao davatelju usluge, o osnovnom obilježju te financijske usluge, o pojedinostima ugovora o osiguranju te o
          načinu rješavanja sporova. Korisnik potvrđuje i daje suglasnost da osiguratelj počinje s ispunjenjem svoje
          obveze tj. da pokriće nastaje prije isteka roka za jednostrani raskid. Za dodatne informacije korisnik se može
          obratiti Odjelu za klijente na broj: *** ***** ***
        </div>
      );
    case POLICY_PACKAGE_HEALTH_1:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPoliciesHealthInsurance.elementaryPackage" />:
          </h3>
          <p>pokriće sudjelovanja u troškovima zdravstvene zaštite iz obveznog zdravstvenog osiguranja</p>
          <hr />
          <p>
            <span>
              5 [pet]{' '}
              <Link to={ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_MEDICAL_PAGE}>medicinski indiciranih usluga</Link>
            </span>
          </p>
          <hr />
          <p>5 [pet] dana godišnje korištenja fizikalne terapije</p>
          <hr />
          <p>
            popust od 50% na ostale usluge koje pruža ugovorna privatna zdravstvena ustanova, a koje nisu obuhvaćene
            pokrićem po ugovorenoj polici osiguranja
          </p>
        </div>
      );
    case POLICY_PACKAGE_HEALTH_2:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPoliciesHealthInsurance.extendedPackage" />:
          </h3>
          <p>pokriće sudjelovanja u troškovima zdravstvene zaštite iz obveznog zdravstvenog osiguranja</p>
          <hr />
          <p>
            <span>
              5 [pet]{' '}
              <Link to={ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_MEDICAL_PAGE}>medicinski indiciranih usluga</Link>
            </span>
          </p>
          <hr />
          <p>5 [pet] dana godišnje korištenja fizikalne terapije</p>
          <hr />
          <p>
            <Link to={ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_EXAMINATIONS_PAGE}>sistematski pregled</Link>
          </p>
          <hr />
          <p>
            popust od 50% na ostale usluge koje pruža ugovorna privatna zdravstvena ustanova, a koje nisu obuhvaćene
            pokrićem po ugovorenoj polici osiguranja
          </p>
        </div>
      );
    case POLICY_PACKAGE_HEALTH_3:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPoliciesHealthInsurance.unlimitedPackage" />:
          </h3>
          <p>
            pokriće sudjelovanja u troškovima zdravstvene zaštite iz obveznog zdravstvenog osiguranja uključujući i B
            listu lijekova za akutna stanja
          </p>
          <hr />
          <p>
            <span>
              neograničen broj{' '}
              <Link to={ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_MEDICAL_PAGE}>medicinski indiciranih usluga</Link>
            </span>
          </p>
          <hr />
          <p>15 [petnaest] dana godišnje korištenja fizikalne terapije</p>
          <hr />
          <p>
            <Link to={ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_EXAMINATIONS_PAGE}>sistematski pregled</Link>
          </p>
          <hr />
          <p>
            popust od 50% na ostale uslugekoje pruža ugovorna privatna zdravstvena ustanova, a koje nisu obuhvaćene
            pokrićem po ugovorenoj polici osiguranja
          </p>
          <hr />
          <p>dodatni osiguranik – prava iz police može korisiti i osoba navedena kao dodatan osiguranik na polici</p>
        </div>
      );
    case POLICY_PACKAGE_TRAVEL_A:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPolicies.policyData.coverageScope" />:
          </h3>
          <p>Upućivanje liječniku</p>
          <hr />
          <p>Prijenos hitnih poruka</p>
          <hr />
          <p>
            <span className="pr-2">Troškovi liječenja:</span>
            <span className="placeholder">15.000 EUR</span>
          </p>
          <hr />
          <p>
            <span className="pr-2">Troškovi stomatoloških usluga:</span>
            <span className="placeholder">100 EUR</span>
          </p>
          <hr />
          <p>Prijevoz bolesnika</p>
          <hr />
          <p>Povratak nakon liječenja</p>
          <hr />
          <p>Povratak posmrtnih ostataka</p>
        </div>
      );
    case POLICY_PACKAGE_TRAVEL_B:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPolicies.policyData.coverageScope" />:
          </h3>
          <p>Upućivanje liječniku</p>
          <hr />
          <p>Prijenos hitnih poruka</p>
          <hr />
          <p>
            <span className="pr-2">Troškovi liječenja:</span>
            <span className="placeholder">20.000 EUR</span>
          </p>
          <hr />
          <p>
            <span className="pr-2">Troškovi stomatoloških usluga:</span>
            <span className="placeholder">200 EUR</span>
          </p>
          <hr />
          <p>Prijevoz bolesnika</p>
          <hr />
          <p>Povratak nakon liječenja</p>
          <hr />
          <p>Povratak posmrtnih ostataka</p>
          <hr />
          <p>Hitni posjet</p>
          <hr />
          <p>Neočekivani povratak</p>
          <hr />
          <p>Dostava lijekova</p>
          <hr />
          <p>Povratak prtljage</p>
          <hr />
          <p>
            <span className="pr-2">Posudba jamčevine:</span>
            <span className="text-nowrap placeholder">
              <span className="font-weight-normal">Maksimalni polog</span>
              <br />
              5.000 EUR
            </span>
          </p>
          <hr />
        </div>
      );
    case POLICY_PACKAGE_TRAVEL_C:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPolicies.policyData.coverageScope" />:
          </h3>
          <p>Upućivanje liječniku</p>
          <hr />
          <p>Prijenos hitnih poruka</p>
          <hr />
          <p>
            <span className="pr-2">Troškovi liječenja:</span>
            <span className="placeholder">50.000 EUR</span>
          </p>
          <hr />
          <p>
            <span className="pr-2">Troškovi stomatoloških usluga:</span>
            <span className="placeholder">300 EUR</span>
          </p>
          <hr />
          <p>Prijevoz bolesnika</p>
          <hr />
          <p>Povratak nakon liječenja</p>
          <hr />
          <p>Povratak posmrtnih ostataka</p>
          <hr />
          <p>Hitni posjet</p>
          <hr />
          <p>Neočekivani povratak</p>
          <hr />
          <p>Povratak djece</p>
          <hr />
          <p>
            <span className="pr-2">Pravna pomoć:</span>
            <span className="placeholder">
              <span className="font-weight-normal">
                Honorar pravnika/
                <br />
                odvjetnika do
              </span>
              <br />
              1.000 EUR
            </span>
          </p>
          <hr />
          <p>Dostava lijekova</p>
          <hr />
          <p>Povratak prtljage</p>
          <hr />
          <p>
            <span className="pr-2">Odgoda leta:</span>
            <span className="placeholder">
              <span className="font-weight-normal">Maksimalno</span>
              <br />
              160 EUR
              <br />
              <span className="font-weight-normal">po dostavljenim računima</span>
            </span>
          </p>
          <hr />
          <p>
            <span className="pr-2">Gubitak prijavljene prtljage:</span>
            <span className="text-nowrap placeholder">
              20 EUR/kg
              <br />
              <span className="font-weight-normal">
                maksimalno
                <br />
                400 EUR
                <br />
              </span>
            </span>
          </p>
          <hr />
          <p>
            <span className="pr-2">Posudba jamčevine:</span>
            <span className="text-nowrap placeholder">
              <span className="font-weight-normal">Maksimalni polog</span>
              <br />
              5.000 EUR
            </span>
          </p>
        </div>
      );
    case POLICY_COVER_INCIDENT_A:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPolicies.policyData.incidentInsurance" />:
          </h3>
          <p>
            <span className="pr-2">Smrt uslijed nezgode:</span>
            <span className="placeholder">5.000 EUR</span>
          </p>
          <hr />
          <p>
            <span className="pr-2">Trajna invalidnost:</span>
            <span className="placeholder">10.000 EUR</span>
          </p>
        </div>
      );
    case POLICY_COVER_INCIDENT_B:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPolicies.policyData.incidentInsurance" />:
          </h3>
          <p>
            <span className="pr-2">Smrt uslijed nezgode:</span>
            <span className="placeholder">10.000 EUR</span>
          </p>
          <hr />
          <p>
            <span className="pr-2">Trajna invalidnost:</span>
            <span className="placeholder">20.000 EUR</span>
          </p>
        </div>
      );
    case POLICY_COVER_INCIDENT_C:
      return (
        <div className="insurance-package">
          <h3 className="text-dark mb-4">
            <FormattedMessage id="userMyPolicies.policyData.incidentInsurance" />:
          </h3>
          <p>
            <span className="pr-2">Smrt uslijed nezgode:</span>
            <span className="placeholder">20.000 EUR</span>
          </p>
          <hr />
          <p>
            <span className="pr-2">Trajna invalidnost:</span>
            <span className="placeholder">40.000 EUR</span>
          </p>
        </div>
      );
    case OSNOVNI_PAKET:
    case STANDARDNI_PAKET:
    case OPTIMALNI_PAKET:
      return (
        <>
          <div className="insurance-package">
            <h3 className="text-dark mb-4">
              <FormattedMessage id="userMyPoliciesCarLiability.risksInPackage" />:
            </h3>
            {get(data, 'risksDefinitions', []).map((riskDefinition, index) => (
              <Fragment key={riskDefinition.risk}>
                <p className="d-block text-center">
                  <FormattedMessage id={`userMyPoliciesCarLiability.${riskDefinition.risk}`} />
                  <br />
                  {riskDefinition.risk === RISK_HOK_AUTONEZGODA.id &&
                    `(${riskDefinition.sum} kn, vozač i ${riskDefinition.numberOfTravelers} putnika)`}
                </p>
                {index < data.risksDefinitions.length - 1 && <hr />}
              </Fragment>
            ))}
          </div>
        </>
      );
    case MOJ_DOM:
      return (
        <div className="insurance-package">
          <h4 className="my-4">Objekt</h4>
          <h3 className="text-dark mb-4">Građevinski dio stana/kuće</h3>
          <p>
            Svi dijelovi građevinskog objekta, zidovi i zidne obloge, temelji, podrumski zidovi, sve ugrađene
            instalacije i sva ugrađena oprema poput vrata, prozora, bojlera, klimatizacijskih uređaja i drugo.
          </p>
          <p>Osiguranje se ugovara na novu vrijednost, a odabrati možemo jedan od sljedećih oblika pokrića:</p>
          <h5 className="text-dark">Osnovni program</h5>
          <p>
            osiguranje građevinskog dijela stana ili kuće od osnovnih požarnih i nekih drugih rizika te izljeva vode.
          </p>
          <hr />
          <h5 className="text-dark">Program plus</h5>
          <p>
            osiguranje građevinskog dijela stana ili kuće od rizika pokrivenih paketom Osnovni program uz proširenje
            dodatnih rizika (poplava, oborinske vode, vandalizam i sl.)
          </p>
          <hr />
          <h5 className="text-dark">Program extra</h5>
          <p>
            osiguranje građevinskog dijela stana ili kuće od rizika pokrivenih paketom Program plus uz proširenje
            dodatnih rizika (posredni udar groma, lom stakla, odgovornost prema trećima i sl.)
          </p>
          {data && (
            <a className="text-red" href={data} target="_blank" rel="noopener noreferrer">
              Tablica rizika/pokrića
            </a>
          )}
        </div>
      );
    case MOJ_DOM_KUCANSTVO:
      return (
        <div className="insurance-package">
          <h4 className="my-4">Pokretnine</h4>
          <p>
            Dom je za većinu nas najveća životna investicija, kao i najveća materijalna vrijednost koju posjedujemo i
            upravo zbog toga osiguranje našeg doma bi nam trebalo biti na vrhu liste prioriteta. Naša je odluka želimo
            li prepustiti slučajnim događajima da upravljaju našim domom i nadati se da će sve biti u redu, ili možemo
            odlučiti zaštiti ga odabirom pokrića koji najbolje odgovara našim potrebama i našem prostoru.
          </p>
          <p>
            Odabirom nekog od ponuđenih oblika pokrića, biramo zaštitu od nepredviđenih gubitaka koji mogu nastati zbog
            šteta nastalim na:
          </p>
          <h5 className="text-dark">Stvarima u kućanstvu</h5>
          <p>stvari koje služe za uređenje obiteljske kuće ili stana te za osobnu uporabu ili potrošnju</p>
          <hr />
          <h5 className="text-dark">Stvarima posebne vrijednosti</h5>
          <p>gotov novac, nakit, umjetnine i sl.</p>
          <hr />
          <h5 className="text-dark">Stambenoj opremi</h5>
          <p>
            raznim oblogama na podu, zidu i stropu, podovima, uređajima za grijanje i klimatizaciju, opremi za
            kupaonicu, antenama, vrtnom namještaju i sl.
          </p>
          <hr />
          <h5 className="text-dark">Staklenim površinama</h5>
          <p>staklima na vratima i prozorima stana, staklenim pregradnim stijenama</p>
          <p>
            Osiguranjem je obuhvaćena i odgovornost u svojstvu privatne osobe za štete učinjene trećim osobamai njihovim
            stvarima. Osiguranje se ugovara na novu vrijednost.
          </p>
          <p>
            Odabrati možemo jedan od sljedećih oblika zaštite koji se međusobno razlikuju prema broju osiguranih rizika
            i visini pokrića:
          </p>
          <ul className="dashed font-weight-bold">
            <li>Osnovni program</li>
            <li>Standardni program</li>
            <li>Optimalni program</li>
          </ul>
          {data && (
            <a className="text-red" href={data} target="_blank" rel="noopener noreferrer">
              Tablica rizika/pokrića
            </a>
          )}
        </div>
      );
    case HOK_IMOVINA_POTRES:
      return (
        <div className="insurance-package">
          <p>
            Uz ugovoreno osiguranje od požara i nekih drugih rizika ili osiguranja kućanstva moguće je dodatno ugovoriti
            i osiguranje od rizika potresa.Osiguranjem od potresa nadoknađuju se materijalne štete uzrokovane izravnim
            djelovanjem potresa jačine 5 ili više stupnjeva Mercalli–Cancani–Siebergove ljestvice. Osiguranje se ugovara
            uz odbitnu franšizu [udio osiguranika u šteti] od 2% od iznosa osiguranja. Doplatom premije moguće je
            otkupiti franšizu.
          </p>
        </div>
      );
    case HOK_IMOVINA_PROVALA:
      return (
        <div className="insurance-package">
          <p>
            Osiguranjem od rizika provalne krađe i razbojstva pruža se osigurateljna zaštita od rizika izvršenja ili
            pokušaja provalne krađe ili razbojstva, te od obijesti [vandalizma] u tijeku izvršenja provalne
            krađe.Osiguranjem je pokrivena šteta od odnošenja, uništenja ili oštećenja osiguranih stvari te šteta
            uzrokovana oštećenjem građevinskih dijelova prostorija, instalacija i opreme [oštećenje zidova, stropova,
            vrata, stakala, brava i sl.] u kojima su osigurane stvari pri izvršenju ili pokušaju izvršenja provalne
            krađe ili razbojstva.Što se sve može osigurati?- pokretne stvari u zgradama ili prostorijama, namještaj i
            uređaji u uredima, ugostiteljskim objektima, ustanovama itd.- novac, dragocjenosti, vrijednosni papiri,
            zbirke i sl.- zalihe robe, sirovina, poluproizvode, gotove proizvode, pomoćni i potrošni materijal, sitni
            inventar i oprema- novac i druge vrijednosti za vrijeme prijenosa i prijevoza - stvari u muzejima, na
            izložbama, u crkvama i javnim knjižnicama.Način osiguranjaOsiguranje se može sklopiti na:- ukupnu vrijednost
            stvari - na „prvi rizik“ [kada osiguranik ne želi iznos osiguranja odrediti prema ukupnoj vrijednosti svih
            stvari, već samo prema tome koliko se pri nastajanju jednog osiguranog slučaja – krađe ili razbojstva, može,
            s obzirom na prirodu stvari odnijeti odnosno oštetiti.- taksiranu vrijednost [sporazumno utvrđenu vrijednost
            za svaku stvar posebno].
          </p>
        </div>
      );
    case HOK_IMOVINA_STAKLO:
      return (
        <div className="insurance-package">
          <p>
            Osiguranjem stakla od loma pruža se osigurateljna zaštita od uništenja ili oštećenja osiguranih stvari zbog
            bilo kojeg rizika kojem su izložene osigurane stvari osim za štetu uzrokovanu:
          </p>
          <ul className="dashed">
            <li>ratnim operacijama ili pobunama</li>
            <li>premještanjem osiguranih stvari u prostorijama koje nisu naznačene kao mjesto osiguranja</li>
            <li>ogrebotinama i sl. površinskim oštećenjima</li>
            <li>klizanjem i slijeganjem tla</li>
            <li>kod nadgrobnih spomenika pri otvaranju ili zatvaranju grobnice.</li>
          </ul>
          <h5 className="text-dark">Što se sve može osigurati?</h5>
          <ul className="dashed">
            <li>sve vrste stakala- svjetleći natpisi i reklame</li>
            <li>mramorne ili ploče od umjetnog kamena na stolovima, pultovima i sl.</li>
            <li>porculanski umivaonici i zahodske školjke</li>
            <li>spomenici od kamena, betona i kovine</li>
            <li>neonske i ostale svjetleće cijevi s pripadajućim uređajima ili bez njih.</li>
          </ul>
        </div>
      );
    case HOK_IMOVINA_STROJ:
      return (
        <div className="insurance-package">
          <p>
            Osiguranjem strojeva od loma pruža se osigurateljna zaštita od oštećenja ili uništenja osigurane stvari
            zbog:
          </p>
          <ul className="dashed">
            <li>
              nezgode u pogonu [događaji koji nastaju nepredviđeno i iznenada u svezi s uporabom osigurane stvari]
            </li>
            <li>nespretnosti, nemara ili zle namjere radnika ili neke druge osobe.</li>
          </ul>
          <h5 className="text-dark">Što se može osigurati?</h5>
          <ul className="dashed">
            <li>
              strojevi, strojni i električni uređaji i instalacije, zajedno s punjenjem, postoljem, ležištem i temeljem,
              ako je njihova vrijednost sadržana u vrijednosti osigurane stvari. Uz posebno ugovaranje u osiguranje se
              mogu uključiti i:
            </li>
            <li>dalekovodi i kabelski vodovi visokog napona</li>
            <li>cjevovodi i mreže [plinovodi, toplovodi, vodovodna i kanalizacijska mreža i sl.]</li>
            <li>instalacije centralnog grijanja- krupni alat, trake, lanci i užad i sl.</li>
            <li>elektronička računala</li>
            <li>ostali predmeti predviđeni Uvjetima.</li>
          </ul>
          <p>
            Bonus/malus temeljem ostvarenog tehničkog rezultata [odnosa likvidiranih šteta i naplaćene tehničke premije]
            u određenom osigurateljnom razdoblju odobrava se bonus u obliku sniženja premije odnosno malus u obliku
            doplatka na premiju. Maksimalan popust/malus iznosi 30%. FranšizaOsiguranje se ugovara uz odbitnu standardnu
            franšizu [udio osiguranika u šteti] od 10% od iznosa štete. Doplatom premije moguće je otkupiti franšizu.
            Osiguranje se, osim uz standardnu franšizu, može ugovoriti i uz primjenu veće franšize, što rezultira
            smanjenjem premije osiguranja.
          </p>
        </div>
      );
    case HOK_IMOVINA_IZGRADNJA:
      return (
        <div className="insurance-package">
          <h5 className="text-dark">Što se može osigurati?</h5>
          <p>Osiguranjem objekata u izgradnji može se ugovoriti osigurateljna zaštita za:</p>
          <ul className="dashed">
            <li>građevinski objekt u izgradnji [novogradnje, rekonstrukcije, dogradnje, adaptacije i sl.]</li>
            <li>
              građevinske i obrtničke radove, instalacijski materijal, te opremu namijenjenu za ugradnju u osigurani
              objekt.
            </li>
            <li>
              građevinsku opremu i pomoćne objekte obuhvaćene u predračunskoj vrijednosti objekta u izgradnji i služe za
              izgradnju.
            </li>
          </ul>
          <h5 className="text-dark">Koje rizike pokriva?</h5>
          <p>Osiguranjem su pokrivene štete koje nastanu zbog djelovanja sljedećih osnovnih rizika:</p>
          <ul className="dashed">
            <li>požara i udara groma</li>
            <li>eksplozije</li>
            <li>oluje</li>
            <li>tuče</li>
            <li>manifestacija i demonstracija</li>
            <li>izlijevanja vode</li>
            <li>mraza</li>
            <li>snijega i leda</li>
            <li>snježne lavine</li>
            <li>kiše</li>
            <li>odronjavanja, urušavanja i slijeganja tla</li>
            <li>nepredviđene građevinske nezgode</li>
            <li>nespretnosti, nemara i zle namjere radnika ili neke druge osobe</li>
            <li>provalne krađe i razbojstva</li>
            <li>
              udara vlastitog motornog vozila, vlastitog pokretnog radnog stroja i nepoznatog motornog vozila u
              osigurani objekt
            </li>
            <li>pada i udara zračne letjelice.</li>
          </ul>
          <p>Uz posebno ugovaranje osiguravajuća zaštita može se proširiti i na dopunske rizike:</p>
          <ul className="dashed">
            <li>poplave i bujice, visoke i podzemne vode</li>
            <li>klizanja tla</li>
            <li>
              odgovornosti izvoditelja građevinskih radova za štete prouzročene trećim osobama i prema djelatnicima.
            </li>
            <li>tuče</li>
          </ul>
          <p>
            U svakom ostvarenom osiguranom slučaju osiguranik sudjeluje u svakoj šteti sa standardnom franšizom od 10%.
            Doplatom premije moguće je otkupiti franšizu. Osiguranje se, osim uz standardnu franšizu, može ugovoriti i
            uz primjenu veće franšize što rezultira smanjenjem premije osiguranja.
          </p>
        </div>
      );
    case HOK_IMOVINA_MONTAZA:
      return (
        <div className="insurance-package">
          <h5 className="text-dark">Što se može osigurati?</h5>
          <p>Osiguranjem objekata u montaži može se ugovoriti osigurateljna zaštita za:</p>
          <ul className="dashed">
            <li>metalne konstrukcije sa strojnom i elektroopremom ili bez nje</li>
            <li>
              strojeve i strojnu elektroopremu, instalacije, aparate, kao i postolja i temelje, ako je njihova
              vrijednost sadržana u vrijednosti objekta u montaži
            </li>
            <li>
              montažnu opremu i pomoćne objekte koji su obuhvaćeni u predračunskoj vrijednosti objekta u montaži i služe
              za montažu.
            </li>
          </ul>
          <h5 className="text-dark">Koje rizike pokriva?</h5>
          <p>Osiguranjem su pokrivene štete koje nastanu zbog djelovanja sljedećih osnovnih rizika:</p>
          <ul className="dashed">
            <li>požara i udara groma</li>
            <li>eksplozije</li>
            <li>oluje</li>
            <li>tuče</li>
            <li>manifestacija i demonstracija</li>
            <li>izlijevanja vode</li>
            <li>mraza</li>
            <li>snijega i leda</li>
            <li>snježne lavine</li>
            <li>kiše</li>
            <li>odronjavanja, urušavanja i slijeganja tla</li>
            <li>nezgode prilikom montaže</li>
            <li>nespretnosti, nemara i zle namjere radnika ili neke druge osobe</li>
            <li>
              provalne krađe i razbojstva. Uz posebno ugovaranje, osiguravajuća zaštita može se proširiti i na dopunske
              rizike od:
              <ul className="dashed mt-0">
                <li>poplave i bujice, visoke i podzemne vode</li>
                <li>klizanja tla</li>
                <li>
                  odgovornosti izvoditelja građevinskih radova za štete prouzročene trećim osobama i prema djelatnicima.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            U svakom ostvarenom osiguranom slučaju osiguranik sudjeluje u svakoj šteti sa standardnom franšizom od 10%.
            Doplatom premije moguće je otkupiti franšizu. Osiguranje se, osim uz standardnu franšizu, može ugovoriti i
            uz primjenu veće franšize, što rezultira smanjenjem premije osiguranja.
          </p>
        </div>
      );
    case HOK_APARTMAN_IMOVINA:
      return (
        <div className="insurance-package">
          <h3 className="my-3 text-uppercase">Objekt</h3>
          <ul>
            <li>Požar i neki drugi rizici</li>
            <li>Šteta koju počini gost</li>
          </ul>
          <hr />
          <h3 className="my-3 text-uppercase">
            Pokretnine <span className="text-lowercase">(stvari kućanstva)</span>
          </h3>
          <p className="font-weight-bold">Kombinirano osiguranje stvari kućanstva:</p>
          <ul>
            <li>Požarni rizici</li>
            <li>Provalna krađa i razbojstvo</li>
            <li>Lom stakla</li>
            <li>Šteta koju počini gost</li>
          </ul>
        </div>
      );
    case HOK_APARTMAN_GUBITAK:
      return (
        <div className="insurance-package">
          <h3 className="my-3 text-uppercase">Gubitak najamnine iznajmljivača</h3>
          <p>
            Izmakla dobit iznajmljivača zbog nemogućnosti obavljanja djelatnosti pružanja usluga smještaja uslijed
            uništenja ili oštećenja imovine od osnovnih požarnih rizika i poplave na imovini obuhvaćenoj ugovorom o
            osiguranju.
          </p>
        </div>
      );
    case HOK_APARTMAN_ODGOVORNOST:
      return (
        <div className="insurance-package">
          <h3 className="my-3 text-uppercase">Osiguranje odgovornosti prema trećima</h3>
          <p>
            Građanskopravna izvanugovorna odgovornost osiguranika i članova njegovog kućanstva prema trećim osobama i
            gostima za štete koje proizlaze iz korištenja, odnosno posjedovanja nekretnine te djelatnosti pružanja
            smještaja.
          </p>
        </div>
      );
    case HOK_APARTMAN_NEZGODA:
      return (
        <div className="insurance-package">
          <h3 className="my-3 text-uppercase">Osiguranje gostiju od posljedica nesretnog slučaja</h3>
          <ul>
            <li>Smrt uslijed nezgode </li>
            <li>Trajna invalidnost</li>
          </ul>
        </div>
      );
    case HOK_KUCNA_ASISTENCIJA:
      return (
        <div className="insurance-package">
          <h3 className={'text-uppercase' + (spaced ? ' my-4' : ' my-3')}>
            <FormattedMessage id="routes.houseAssistance" />
          </h3>
          <p>
            Kako biste sačuvali svoj dom u slučaju veće nezgode u kući zbog nepredviđenih hitnih događaja ugovorite
            uslugu HOK kućna asistencija.
          </p>
          <p>U okviru HOK kućne asistencije pokrivene su usluge:</p>
          <ul>
            <li>vodoinstalatera</li>
            <li>električara</li>
            <li>bravara</li>
            <li>stolara</li>
            <li>staklara</li>
          </ul>
          <p>HOK kućnu asistenciju možete ugovoriti uz policu osiguranja MOJ DOM ili HOK APARTMAN.</p>
          {data.KA_ASSISTANCE_phoneNumber_from_hr && (
            <>
              <p>
                U slučaju nastanka štete, na raspolaganju vam je centar za pomoć 24 sata na dan, 365 dana u godini i to
                na broju telefona
              </p>
              <ContactNumber className="my-4 color-red" phoneNumber={data.KA_ASSISTANCE_phoneNumber_from_hr} />
            </>
          )}
          <h5 className="text-dark">Teritorijalno pokriće:</h5>
          <p>
            Republika Hrvatska Za više informacija slobodno se obratite u Vama najbližu poslovnicu HOK-osiguranja ili na
            e-mail adresu:
          </p>
          {data.KA_ASSISTANCE_email && (
            <Row className="no-gutters mt-4 mb-3">
              <Col className="d-flex align-items-center">
                <HokEmailIcon className="svg-red" />
                <a className="hok-support-icon-label" href={`mailto://${data.KA_ASSISTANCE_email}`}>
                  {data.KA_ASSISTANCE_email}
                </a>
              </Col>
            </Row>
          )}
        </div>
      );
    case HOK_BONUS_GARANT:
      return (
        <>
          <p>
            Zaštitita stečenog bonus na polici osiguranja od automobilske odgovornosti, a prilikom obnove police u HOK
            osiguranju za slijedeće osigurateljno razdoblje.
          </p>
        </>
      );
    case HOK_AUTONEZGODA:
      return (
        <>
          <p>
            Ugovaranjem dodatnog pokrića Autonezgode na polici osiguranja od automobilske odgovornosti osiguravate
            naknadu za vozača i putnike za slučaj smrti ili trajnog invaliditeta nastalog kao posljedica nesretnog
            slučaja za vrijeme upravljanja motornim vozilom. Iznos naknade ovisi o odabranim osiguranim svotama.
          </p>
        </>
      );
    case AO_PLUS:
      return (
        <>
          <p>Ugovaranjem ovog proizvoda štitite sebe kao vozača u slučaju krivice za prometnu nezgodu.</p>
          <p>
            Maksimalni iznos naknade za pretrpljenu štetu u slučaju ozljede vozača je 25.000 EUR-a po jednom osiguranom
            slučaju. U slučaju smrti vozača, osiguratelj se obvezuje podmiriti uobičajene troškove pogreba maksimalno do
            iznosa 3.000 EUR-a (u okviru ukupne obveze od 25.000 EUR-a).
          </p>
        </>
      );
    case CAR_LIABILITY_COVER_PRAVNA:
      return (
        <>
          <p>
            Ugovaranjem ovog proizvoda osiguravate si naknadu troškova zastupanja osiguranika u kaznenom, prekršajnom
            ili građanskopravnom postupku nastalom kao posljedica prometne nezgode nastale upotrebom motornog vozila
            navedenog u polici osiguranja.
          </p>
          <p>
            Maksimalni iznos osigurateljne obveze predstavlja ukupnu vrijednost 15 odvjetničkih radnji, obračunatih
            sukladno važećoj Tarifi Hrvatske odvjetničke komore a maksimalno do iznosa 70.000,00 kn |{' '}
            {currencyConversionEuro('70.000,00')}.
          </p>
        </>
      );
    case HR_ASISTENCIJA:
      return (
        <>
          <p className="mb-0">Ugovaranjem ovog proizvoda osiguravate:</p>
          <ul>
            <li>Pomoć na cesti u slučaju kvara i/ili prometne nezgode</li>
            <li>
              Odvoženje (vuča) vozila u slučaju kvara I/ili nezgode ili u slučaju korištenja neodgovarajućeg goriva
            </li>
          </ul>
        </>
      );
    case HOK_RENT_A_CAR:
      return (
        <>
          <p>
            Ugovaranjem ovog proizvoda osiguravate si korištenje odnosno naknadu troškova najma rent a car zamjenskog
            vozila u slučaju kvara ili prometne nezgode.
          </p>
          <p>
            Naknada se odnosi na troškove najma vozila uz vremensko ograničenje od 72 sata nakon prometne nezgode ili
            kvara vozila.
          </p>
          <p>Ova se usluga može koristiti 2 puta tijekom osiguranog razdoblja.</p>
        </>
      );
    case HOK_EXPRESS_ASISTENCIJA:
      return (
        <>
          <p className="mb-0">Ugovaranjem ovog proizvoda osiguravate:</p>
          <ul>
            <li>popravak ili vuču vozila u slučaju kvara vozila (do 16 godina starosti) ili prometne nezgode</li>
            <li>zamjensko vozilo bez obrzira na krivnju za prometnu nezgodu</li>
            <li>
              Otključavanje ili odvoženje vozila u slučaju gubitka, puknuća ključeva ili zaključavanja ključeva u vozilu
            </li>
            <li>dostavu goriva u slučaju nestanka u tijeku vožnje</li>
            <li>vuču vozila u slučaju punjenja spremnika goriva neodgovarajućim gorivom</li>
          </ul>
          <p>
            Osiguranje vrijedi dok se vozilo nalazi na području Hrvatske i izvan nje, u zemljama koje obuhvaća sustav
            zelene karte.
          </p>
        </>
      );
    case CAR_LIABILITY_COVER_KASKO_START:
      return (
        <>
          <p>
            Kasko START nudi za vaš automobil pokriće od sudara s poznatim vozilom i pokriće od krađe (krađa, provalna
            krađa i razbojstvo). Moguće ga je ugovoriti za osobna vozila starosti od 4 do 15 godina i snage do 250 kW.
          </p>
          <p>
            Teritorijalno pokriće uključuje zemlje članice sustava Zelene karte, odnosno pokriće je prošireno u odnosu
            na puni kasko na Srbiju, Crnu Goru i Kosovo. Premija osiguranja ovisi o marki vozila, snazi vozila i dobi
            osiguranika.
          </p>
          <p>
            Na krađu se primjenjuje franšiza od 10% od iznosa štete. Možete odabrati opseg pokrića između triju
            ponuđenih opcija:
          </p>
          <p>Godišnji limit za sudar 10.000,00 kn i limit za krađu 30.000,00 kn (3×10.000,00 kn).</p>
          <hr />
          <p>Godišnji limit za sudar 20.000,00 kn i limit za krađu 60.000,00 kn (3×20.000,00 kn).</p>
          <hr />
          <p>Godišnji limit za sudar 20.000,00 kn i limit za krađu 60.000,00 kn (3×20.000,00 kn).</p>
        </>
      );
    case CAR_LIABILITY_COVER_KASKO_PRO:
      return (
        <>
          <p>
            Kasko PRO namijenjeno je svima koji vjeruju da najveću opasnost za njihov automobil predstavljaju rizici na
            koje ne mogu utjecati. Stoga ovim pokrićem vozilu osiguravaju adekvatnu zaštitu od nepredvidivih rizika, kao
            što su:
          </p>
          <ul>
            <li>Krađa, provalna krađa i razbojstvo</li>
            <li>požar, oluja, tuča, poplava, bujica, visoka voda iudar groma</li>
            <li>eksplozija (osim eksplozije nuklearne energije)</li>
            <li>pad snijega ili leda</li>
            <li>pad zračnih letjelica svih vrsta</li>
            <li>štete nastale nakon ostvarenja rizika iz krađe, provalne krađe i razbojstva</li>
            <li>štete koje uzrokuju životinje na vozilu u mirovanju</li>
            <li>krađa guma ili naplataka (felgi)</li>
            <li>krađa ključeva vozila iz zaključane prostorije, uz obveznu prijavu policiji</li>
            <li>
              oštećenja tapeciranih dijelova vozila prilikom pružanja pomoći osobama koje su stradale u prometnoj
              nezgodi
            </li>
          </ul>
          <p>Kasko Pro je moguće ugovoriti za osobna vozila do 15 godina starosti i snage do 250 kW.</p>
          <p>
            Teritorijalno pokriće uključuje zemlje članice sustava zelene karte, odnosno pokriće je prošireno u odnosu
            na puni kasko na Srbiju, Crnu Goru i Kosovo.
          </p>
          <p>
            Premija osiguranja ovisi o marki vozila, snazi vozila i dobi osiguranika. Na krađu se primjenjuje franšiza
            od 10% od iznosa štete.
          </p>
        </>
      );
    case CAR_LIABILITY_COVER_KASKO_FORTE:
      return (
        <>
          <p>
            Kasko FORTE namijenjen je onima koji su investirali značajna novčana sredstva (primjerice, kredit) u svoje
            vozilo te bi htjeli zaštiti investiciju od velikih (kapitalnih) i totalnih šteta, dok im manje štete ne
            predstavljaju problem, odnosno ne ugrožavanju njihovu investiciju.
          </p>
          <p>
            Proizvod je pogodan za starija vozila jer premija i pokrića punog kaska nisu prilagođeni potrebama starijih
            vozila.
          </p>
          <p>Kasko Forte nudi pokriće punog kaska uz primjenu viših odbitnih franšiza.</p>
          <p>Proizvod je moguće ugovoriti za osobna vozila starosti od 4 do 15 godina i snage do 250 kW.</p>
          <p>
            Teritorijalno pokriće uključuje zemlje članice sustava zelene karte, odnosno pokriće je prošireno u odnosu
            na puni kasko na Srbiju, Crnu Goru i Kosovo.
          </p>
          <p>Premija osiguranja ovisi o marki vozila, snazi vozila, dobi osiguranika i starosti vozila.</p>
          <h5>Tablica franšiza</h5>
          <Table>
            <tbody>
              <tr>
                <td>do 70 kW</td>
                <td>10.000,00 kn</td>
              </tr>
              <tr>
                <td>70 kW - 110 kW</td>
                <td>15.000,00 kn</td>
              </tr>
              <tr>
                <td>110 kW - 150 kW</td>
                <td>20.000,00 kn</td>
              </tr>
              <tr>
                <td>150 kW - 200 kW</td>
                <td>25.000,00 kn</td>
              </tr>
              <tr>
                <td>200 kW - 250 kW</td>
                <td>30.000,00 kn</td>
              </tr>
            </tbody>
          </Table>
        </>
      );
    case VEHICLE_DATA:
      return (
        <div className="insurance-package">
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.licensePlate" />:
            </span>
            <span className="placeholder font-weight-normal">{data.regOznaka}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vin" />:
            </span>
            <span className="placeholder font-weight-normal">{data.brojSasije}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleType" />:
            </span>
            <span className="placeholder font-weight-normal text-uppercase">
              {data.vrstaEnumCode ? formatMessage({ id: `userMyPoliciesCarLiability.${data.vrstaEnumCode}` }) : ''}
            </span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleMake" />:
            </span>
            <span className="placeholder font-weight-normal">{data.markaVozilaNaziv}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleModel" />:
            </span>
            <span className="placeholder font-weight-normal">{data.tipVozilaNaziv}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleYear" />:
            </span>
            <span className="placeholder font-weight-normal">{data.godinaProizvodnje}</span>
          </p>
        </div>
      );
    case VEHICLE_DATA_AO_SHORT:
      return (
        <div className="insurance-package">
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleType" />:
            </span>
            <span className="placeholder font-weight-normal text-uppercase">
              <FormattedMessage id={`userMyPoliciesCarLiability.${data.vrstaEnumCode}`} />
            </span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleMake" />:
            </span>
            <span className="placeholder font-weight-normal">{data.markaVozilaNaziv}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleModel" />:
            </span>
            <span className="placeholder font-weight-normal">{data.tipVozilaNaziv}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.techSpecs" />:
            </span>
            <span className="placeholder font-weight-normal">{vehicleTechSpecs}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleYear" />:
            </span>
            <span className="placeholder font-weight-normal">{data.godinaProizvodnje}</span>
          </p>
        </div>
      );
    case VEHICLE_DATA_AO:
      return (
        <div className="insurance-package">
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.licensePlate" />:
            </span>
            <span className="placeholder font-weight-normal">{data.regOznaka}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vin" />:
            </span>
            <span className="placeholder font-weight-normal">{data.brojSasije}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleType" />:
            </span>
            <span className="placeholder font-weight-normal text-uppercase">
              <FormattedMessage id={`userMyPoliciesCarLiability.${data.vrstaEnumCode}`} />
            </span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleMake" />:
            </span>
            <span className="placeholder font-weight-normal">{data.markaVozilaNaziv}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleModel" />:
            </span>
            <span className="placeholder font-weight-normal">{data.tipVozilaNaziv}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.techSpecs" />:
            </span>
            <span className="placeholder font-weight-normal">{vehicleTechSpecs}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.vehicleYear" />:
            </span>
            <span className="placeholder font-weight-normal">{data.godinaProizvodnje}</span>
          </p>
        </div>
      );
    case INSURED_PERSON_DATA:
      return (
        <div className="insurance-package">
          {data.sector === USER_ACCOUNT_BUSINESS ? (
            <p>
              <span className="pr-2 text-uppercase font-weight-bold">
                <FormattedMessage id="inputs.name" />:
              </span>
              <span className="placeholder font-weight-normal">{data.businessName}</span>
            </p>
          ) : (
            <>
              <p>
                <span className="pr-2 text-uppercase font-weight-bold">
                  <FormattedMessage id="inputs.firstName" />:
                </span>
                <span className="placeholder font-weight-normal">{data.firstName || data.name}</span>
              </p>
              <p>
                <span className="pr-2 text-uppercase font-weight-bold">
                  <FormattedMessage id="inputs.lastName" />:
                </span>
                <span className="placeholder font-weight-normal">{data.lastName || data.surename}</span>
              </p>
              <p>
                <span className="pr-2 text-uppercase font-weight-bold">
                  <FormattedMessage id="inputs.dateOfBirth" />:
                </span>
                <span className="placeholder font-weight-normal">{formatDate(data.dateOfBirth)}</span>
              </p>
              <p>
                <span className="pr-2 text-uppercase font-weight-bold">
                  <FormattedMessage id="inputs.sex" />:
                </span>
                <span className="placeholder font-weight-normal">
                  <FormattedMessage id={data.sex} />
                </span>
              </p>
            </>
          )}
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.OIB" />:
            </span>
            <span className="placeholder font-weight-normal">{data.oib}</span>
          </p>
          {data.sector !== USER_ACCOUNT_BUSINESS && showHzzo && (
            <p>
              <span className="pr-2 text-uppercase font-weight-bold">
                <FormattedMessage id="inputs.HZZONumber" />:
              </span>
              <span className="placeholder font-weight-normal">{data.mbHzzo}</span>
            </p>
          )}
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.zipCode" />:
            </span>
            <span className="placeholder font-weight-normal">{data.zip}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.place" />:
            </span>
            <span className="placeholder font-weight-normal">{data.city}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.address" />:
            </span>
            <span className="placeholder font-weight-normal">{data.address}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.phoneNumber" />:
            </span>
            <span className="placeholder font-weight-normal">{data.phoneNumber}</span>
          </p>
          <p>
            <span className="pr-2 text-uppercase font-weight-bold">
              <FormattedMessage id="inputs.email" />:
            </span>
            <span className="placeholder font-weight-normal">{data.email}</span>
          </p>
        </div>
      );
    case ASSISTANCE_TRAVEL:
      return (
        <Col className="hok-description-text">
          <p>Asistencija u inozemstvu</p>
          <p>
            Uslugu možete zatražiti pozivom na broj telefona:{' '}
            <a className="color-red text-underline" href={`tel://${data.PZO_ASSISTANCE_phoneNumber}`}>
              {data.PZO_ASSISTANCE_phoneNumber}
            </a>
          </p>
          <h5 className="color-red">Potrebni podaci</h5>
          <p>
            Odaberite policu osiguranja preko koje želite prijaviti štetu kako bi Vam se prikazali potrebni podaci za
            što uspješniju uslugu.
          </p>
          <p>
            <span className="font-weight-bold">Prilikom poziva molimo navesti:</span>
            <br />
            Podatke o osiguraniku, broj police i podatke o trajanju, adresu i telefonski broj na kojem ste dostupni i
            ukratko opisati problem kojeg imate i kakvu vrstu pomoći trebate.
          </p>
        </Col>
      );
    case ASSISTANCE_EXPRESS:
      return (
        <Col className="hok-description-text">
          <p>Pomoć na cesti u RH i u inozemstvu</p>
          <p className="mb-0">Uslugu možete zatražiti pozivom na broj telefona:</p>
          <ul className="pl-5">
            <li>
              za pozive iz Hrvatske:{' '}
              {data.HE_ASSISTANCE_phoneNumber_from_hr && (
                <a className="color-red text-underline" href={`tel://${data.HE_ASSISTANCE_phoneNumber_from_hr}`}>
                  {data.HE_ASSISTANCE_phoneNumber_from_hr}
                </a>
              )}
            </li>
            <li>
              za pozive iz inozemstva:{' '}
              <a className="color-red text-underline" href={`tel://${data.HE_ASSISTANCE_phoneNumber}`}>
                {data.HE_ASSISTANCE_phoneNumber}
              </a>
            </li>
          </ul>
          <h5 className="color-red">Potrebni podaci</h5>
          <p>
            Odaberite policu osiguranja preko koje želite prijaviti štetu kako bi Vam se prikazali potrebni podaci za
            što uspješniju uslugu.
          </p>
          <p>
            <span className="font-weight-bold">Prilikom poziva molimo navesti:</span>
            <br />
            Podatke o osiguraniku, broj police, podatke o vozilu, podatke o mjestu gdje se nalazite, telefonski broj na
            kojem ste dostupni te podatke o nezgodi ili opis štete ili kvara.
          </p>
        </Col>
      );
    case ASSISTANCE_HR:
      return (
        <Col className="hok-description-text">
          <p>Pomoć na cesti u Republici Hrvatskoj</p>
          <p className="mb-0">Uslugu možete zatražiti pozivom na broj telefona:</p>
          <ul className="pl-5">
            <li>
              za pozive iz Hrvatske:{' '}
              <a className="color-red text-underline" href={`tel://${data.HR_ASSISTANCE_phoneNumber_from_hr}`}>
                {data.HR_ASSISTANCE_phoneNumber_from_hr}
              </a>
            </li>
            <li>
              za pozive iz inozemstva:{' '}
              <a className="color-red text-underline" href={`tel://${data.HR_ASSISTANCE_phoneNumber}`}>
                {data.HR_ASSISTANCE_phoneNumber}
              </a>
            </li>
          </ul>
          <h5 className="color-red">Potrebni podaci</h5>
          <p>
            Odaberite policu osiguranja preko koje želite zatražiti asistenciju kako bi Vam se prikazali potrebni podaci
            za što uspješniju uslugu.
          </p>
          <p>
            <span className="font-weight-bold">Prilikom poziva molimo navesti:</span>
            <br />
            Podatke o osiguraniku, broj police, podatke o vozilu, podatke o mjestu gdje se nalazite, telefonski broj na
            kojem ste dostupni te podatke o nezgodi ili opis štete ili kvara.
          </p>
        </Col>
      );
    case ASSISTANCE_RENTACAR:
      return (
        <Col className="hok-description-text">
          <p>Zamjensko vozilo u Republici Hrvatskoj</p>
          <p>
            Uslugu možete zatražiti pozivom na broj telefona:{' '}
            <a className="color-red text-underline" href={`tel://${data.HR_RENT_A_CAR_ASSISTANCE_phoneNumber}`}>
              {data.HR_RENT_A_CAR_ASSISTANCE_phoneNumber}
            </a>
          </p>
          <h5 className="color-red">Potrebni podaci</h5>
          <p>
            Odaberite policu osiguranja preko koje želite zatražiti asistenciju kako bi Vam se prikazali potrebni podaci
            za što uspješniju uslugu.
          </p>
          <p>
            <span className="font-weight-bold">Prilikom poziva molimo navesti:</span>
            <br />
            Podatke o osiguraniku, broj police, podatke o vozilu, podatke o mjestu gdje se nalazite, telefonski broj na
            kojem ste dostupni te podatke o nezgodi ili opis štete ili kvara.
          </p>
        </Col>
      );
    case ASSISTANCE_HOME:
      return (
        <Col className="hok-description-text">
          <p>Pomoć u kući</p>
          <p>
            Uslugu možete zatražiti pozivom na broj telefona:{' '}
            <a className="color-red text-underline" href={`tel://${data.KA_ASSISTANCE_phoneNumber}`}>
              {data.KA_ASSISTANCE_phoneNumber}
            </a>
          </p>
          <h5 className="color-red">Potrebni podaci</h5>
          <p>
            Odaberite policu osiguranja preko koje želite zatražiti asistenciju kako bi Vam se prikazali potrebni podaci
            za što uspješniju uslugu.
          </p>
          <p>
            <span className="font-weight-bold">Prilikom poziva molimo navesti:</span>
            <br />
            Podatke o osiguraniku, broj police i podatke o trajanju, mjestu intervencije (adresa I opis objekta),
            telefonski broj na kojem ste dostupni te ukratko opisati problem kojeg imate i kakvu vrstu pomoći trebate.
          </p>
        </Col>
      );
    case CLAIM_CAR_LIABILITY:
      return (
        <>
          <p>Prije same prijave štete molimo Vas da pročitate i postupite prema navedenim uputama ako je potrebno.</p>
          <h5 className="color-red">Što učiniti u slučaju prometne nesreće?</h5>
          <ul>
            <li>U slučaju veće štete i ozlijeđenih osoba pozvati policiju (+385 192)</li>
            <li>Popuniti obrazac Europskog izvješća o nezgodi</li>
            <li>Razmijeniti osobne podatke svih sudionika u nezgodi (police osiguranja, kontakt-podaci)</li>
            <li>Fotografirati vozila i mjesta događaja</li>
            <li>Odštetni zahtjev podnijeti društvu za osiguranje kod kojeg je vozilo krivca osigurano.</li>
          </ul>
        </>
      );
    case CLAIM_KASKO:
      return (
        <>
          <p>Prije same prijave štete molimo Vas da pročitate i postupite prema navedenim uputama ako je potrebno.</p>
          <h5 className="color-red">Što učiniti u slučaju prometne nesreće?</h5>
          <ul>
            <li>
              U slučaju krađe, razbojstva, požara, eksplozije i teže prometne nezgode štetu prijaviti policiji (+385
              192)
            </li>
            <li>U slučaju sudara s drugim vozilom popuniti obrazac Europskog izvješća o nezgodi</li>
            <li>Razmijeniti osobne podatke svih sudionika u nezgodi (police osiguranja, kontakt-podaci)</li>
            <li>Fotografirati vozila i mjesta događaja</li>
            <li>Prijaviti štetu.</li>
          </ul>
        </>
      );
    case CLAIM_PROPERTY:
      return (
        <>
          <p>
            Prije same prijave štete molimo Vas da pročitate i postupite prema navedenim uputama ukoliko je potrebno.
          </p>
          <h5 className="color-red">Što učiniti u slučaju štete na osiguranoj imovini?</h5>
          <p>
            U slučaju nastanka osiguranog slučaja potrebno je poduzeti sve mjere radi smanjenja opasnosti koje prijete
            objektu ili stvarima.
          </p>
          <p>
            Ako je šteta nastala zbog provalne krađe, razbojstva, eksplozije ili požara, potrebno je nastanak slučaja
            prijaviti policiji. Oštećenja na imovini trebaju ostati nepromijenjena do prijave štete osiguratelju i
            dolaska predstavnika osiguratelja radi procjene štete, osim radi smanjenja opsega oštećenja.
          </p>
          <p>
            Ako se zbog prirode oštećenih stvari pristupilo popravku, treba sačuvati oštećene dijelove za potrebe
            procjene štete.
          </p>
          <p>Fotografirati mjesto događaja čim dozvoljavaju prilike.</p>
        </>
      );
    case CLAIM_ATTORNEY:
      return (
        <>
          <p>Prije same prijave štete molimo Vas da pročitate i postupite prema navedenim uputama ako je potrebno.</p>
          <p>
            Predmet osiguranja je odgovornost odvjetnika za štetu koju obavljanjem odvjetništva prouzroči trećim
            osobama. Osiguranim slučajem smatra se svaki pojedinačno određeni propušteni oblik pravne zaštite ili
            učinjena pogreška kojima se trećim osobama prouzroči šteta.
          </p>
          <p>
            Obrazac prijave osiguranog slučaja je potrebno popuniti sa svim traženim podacima i vlastoručno ga potpisati
            te ga uz dokumentaciju potrebnu za odlučivanje o osnovanosti zahtjeva prenijeti unutar prijave štete.
          </p>
        </>
      );
    case CLAIM_VESSELS:
      return (
        <>
          <p>Prije same prijave štete molimo Vas da pročitate i postupite prema navedenim uputama ako je potrebno.</p>
          <h5 className="color-red">Što učiniti u slučaju prometne nesreće?</h5>
          <ul>
            <li>Poduzeti sve razumne mjere potrebne da se smanji daljnja šteta.</li>
            <li>
              Prijaviti nesreću lučkoj kapetaniji, a u slučaju sudara s drugim plovilom prikupiti imena sudionika i
              svjedoka.
            </li>
            <li>
              Ako do štete dođe uslijed požara, eksplozije, odnosno uslijed počinjenja kaznenog djela, podnijeti prijavu
              nadležnoj policijskoj postaji.
            </li>
          </ul>
          <p>Obavijestiti osiguravatelja o šteti čim je to moguće, a najkasnije u roku od tri dana.</p>
        </>
      );
    case CLAIM_ACCIDENT:
      return (
        <>
          <p>Prije same prijave štete molimo Vas da pročitate i postupite prema navedenim uputama ako je potrebno.</p>
          <h5 className="color-red">Što učiniti u slučaju prometne nesreće?</h5>
          <p>Osiguranik koji je zbog nesretnog slučaja ozlijeđen dužan je:</p>
          <ul>
            <li>Javiti se liječniku radi pružanja liječničke pomoći i pregleda i postupajte prema uputama liječnika</li>
            <li>
              Po završetku liječenja prijavite nesretni slučaj osiguratelju i dostavite svu potrebnu dokumentaciju -
              medicinsku dokumentaciju i ostale podatke koje zahtijeva osiguratelj radi utvrđivanja obveze.
            </li>
          </ul>
        </>
      );
    case SAVE_CHANGES:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">Želite li spremiti izmjene prije zatvaranja novosti?</p>
          </Col>
        </Row>
      );
    case VEHICLE_INFO_ENTRY:
      return (
        <Row className="no-gutters">
          <Col className="text-left hok-text-dark" sm={{ offset: 1, size: 10 }}>
            <p>
              Unosom registracijske oznake, broj šasije zajedno sa brojem prethodne police će se povući iz sustava te će
              se to polje predispuniti.
            </p>
            <p>
              Unosom broja šasije, registracijska oznaka zajedno sa brojem prethodne police će se povući iz sustava te
              će se odgovarajuća polja predispuniti ovisno radi li se o postojećoj polici ili ne.
            </p>
            <p>
              Ukoliko Vam se ne popune polja automatski nakon unosa podataka u jedno od ovih polja, a potrebni su za
              daljnju obradu i izračun Vaše premije, ručno unesite podatak u traženo polje.
            </p>
            <p>
              <span className="font-weight-bold">Napomena:</span> Prije prelaska na idući korak provjerite točnost
              podataka.
            </p>
          </Col>
        </Row>
      );
    case CANCEL_CONTRACTING:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">Želite li odustati od ugovaranja police?</p>
          </Col>
        </Row>
      );
    case SELF_INSURANCE_CONTRACTING:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">Ugovarate li policu za sebe ili za neku drugu osobu?</p>
          </Col>
        </Row>
      );
    case CONTRACTING_AGE_ELIGIBILITY:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">
              Niste u mogućnosti ugovoriti novu policu zdravstvenog osiguranja. Policu zdravstvenog osiguranja je moguće
              ugovoriti samo za osobe između 18 (osamnaest) i 65 (šezdeset i pet) godina starosti.
            </p>
            <p className="mb-0">
              Možete prekinuti ugovaranje nove police ili u slučaju greške kontaktirati korisničku službu/zatražiti
              ponudu.
            </p>
          </Col>
        </Row>
      );
    case CONTINUE_PREVIOUS_CONTRACTING:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">Želite li nastaviti s ugovaranjem koje ste prethodno započeli?</p>
          </Col>
        </Row>
      );
    case TRAVEL_PARTICIPATING:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">Sudjelujete li u putovanju osobno ili ugovarate za druge osobe?</p>
          </Col>
        </Row>
      );
    case TRAVEL_GROUP_TYPE_MODAL:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">
              Za ovu vrstu proizvoda nije moguće ugovoriti policu Putnog Zdravstvenog Osiguranja. Možete zatražiti
              ponudu preko obrasca ili se vratiti na pregled opisa proizvoda.
            </p>
          </Col>
        </Row>
      );
    case TRAVEL_PURPOSE_MODAL:
      return (
        <Row className="no-gutters">
          <Col sm={{ size: 10, offset: 1 }} className="text-center">
            <p className="mb-0">
              Za ovu svrhu putovanja nije moguće ugovoriti policu Putnog Zdravstvenog Osiguranja. Možete zatražiti
              ponudu preko obrasca ili se vratiti na pregled opisa proizvoda.
            </p>
          </Col>
        </Row>
      );
    case EXPRESS_ELIGIBILITY_MODAL:
      return (
        <Row className="no-gutters">
          <Col className="text-center px-4">
            <p className="mb-0">Ugovaranje police nije moguće:</p>
            <p className="font-weight-normal mb-0">
              Ne posjedujete zaključenu policu obveznog osiguranja automobilske odgovornosti ili policu osiguranja
              automobilskog kaska s HOK osiguranjem d.d.
            </p>
          </Col>
        </Row>
      );
    case VEHICLE_PURPOSE_RACING:
      return (
        <Row className="no-gutters">
          <Col sm={{ size: 10, offset: 1 }} className="text-center">
            <p className="mb-0">Koristi li se vozilo za vožnju utrka?</p>
          </Col>
        </Row>
      );
    case VEHICLE_PURPOSE:
      return (
        <Row className="no-gutters">
          <Col sm={{ size: 10, offset: 1 }} className="text-center">
            <p className="mb-0">
              Koristi li se vozilo za potencijalne namjene kao što su: rent a car, taxi, vatrogasne potrebe, prijevoz
              opasnih i eksplozivnih tvari, medicinske i sanitarne potrebe?
            </p>
          </Col>
        </Row>
      );
    case VEHICLE_AGE_CHECK:
      return (
        <Row className="no-gutters">
          <Col sm={{ size: 10, offset: 1 }} className="text-center">
            <p className="mb-0">
              Radi li se o starodobnom vozilu?
              <br />
              Starodobno vozilo je vozilo starije od 30 godina.
            </p>
          </Col>
        </Row>
      );
    case VEHICLE_OWNER_DISABILITY:
      return (
        <Row className="no-gutters">
          <Col sm={{ size: 10, offset: 1 }} className="text-center">
            <p className="mb-0">Jeste li osoba sa invaliditetom?</p>
          </Col>
        </Row>
      );
    case VEHICLE_INSURANCE_INELIGIBILITY:
      return (
        <Row className="no-gutters">
          <Col sm={{ size: 10, offset: 1 }} className="text-center">
            <p className="mb-0">
              Za ovu vrstu vozila nije moguće ugovoriti policu obveznog osiguranja od Autoodgovornosti. Možete zatražiti
              ponudu preko obrasca ili se vratiti na pregled opisa proizvoda.
            </p>
          </Col>
        </Row>
      );
    case NOT_ELIGIBLE_AO_VEHICLE_TYPE:
      return (
        <Row className="no-gutters">
          <Col sm={{ size: 10, offset: 1 }} className="text-center">
            <p className="mb-0">
              Za vrstu vozila{' '}
              {data && (
                <>
                  "<FormattedMessage id={`userMyPoliciesCarLiability.${data}`} />"{' '}
                </>
              )}
              nije moguće ugovoriti policu unutar aplikacije HOK digital. Želite li odustati od ugovaranja police ili
              zatražiti ponudu?
            </p>
          </Col>
        </Row>
      );
    case DAMAGE_CLAIM_SUBMIT_SUCCESS:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">Prijava je uspješno poslana.</p>
          </Col>
        </Row>
      );
    case DAMAGE_CLAIM_SUBMIT_FAIL:
      return (
        <Row className="no-gutters">
          <Col className="text-center">
            <p className="mb-0">
              Došlo je do greške prilikom slanja prijave štete. Možete probati poslati prijavu ponovno ili pokušati malo
              kasnije.
            </p>
          </Col>
        </Row>
      );
    case CLAIM_HEALTH:
    case CLAIM_LIABILITY:
    default:
      return null;
  }
};

export default DescriptionText;
