import gql from 'graphql-tag';

export const GetCurrencyConfigQuery = gql`
  query getCurrencyConfig ($configId: Int!) {
    getCurrencyConfig(configId: $configId){
        defaultCurrency
        dualCurrencyDisplay
    }
  }
`;
