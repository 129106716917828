import {
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAIL,
  USER_PROFILE_CLEAR_ERROR_MESSAGE,
  USER_UPDATE_PROFILE_ADVANCED_DATA,
  USER_UPDATE_PROFILE_ADVANCED_DATA_FEEDBACK,
  USER_DELETE_ACCOUNT,
  USER_DELETE_ACCOUNT_SUCCESS,
  USER_DELETE_ACCOUNT_FAIL,
  USER_VALIDATE_CURRENT_PASSWORD,
  USER_VALIDATE_CURRENT_PASSWORD_SUCCESS,
  USER_VALIDATE_CURRENT_PASSWORD_FAIL,
} from '../actions/userProfileActions';

const initialState = {
  changePassword: {
    inProgress: false,
    errorMessage: null,
    success: false,
  },
  changePhoneNumberInProgress: false,
  changeAdvanedDataInProgress: false,
  validateCurrentPassword: {
    inProgress: false,
    errorMessage: null,
    success: false,
  },
  deleteMyAccount: {
    inProgress: false,
    errorMessage: null,
    success: false,
  },
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_CHANGE_PASSWORD:
      return { ...state, changePassword: { errorMessage: null, inProgress: true, success: false } };

    case USER_CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePassword: { errorMessage: null, inProgress: false, success: true } };

    case USER_CHANGE_PASSWORD_FAIL:
      return { ...state, changePassword: { ...action.payload, inProgress: false, success: false } };

    case USER_PROFILE_CLEAR_ERROR_MESSAGE:
      return { ...state, [action.payload]: { ...initialState[action.payload] } };

    case USER_UPDATE_PROFILE_ADVANCED_DATA:
      return { ...state, changeAdvanedDataInProgress: true };

    case USER_UPDATE_PROFILE_ADVANCED_DATA_FEEDBACK:
      return { ...state, changeAdvanedDataInProgress: false };

    case USER_VALIDATE_CURRENT_PASSWORD:
      return { ...state, validateCurrentPassword: { errorMessage: null, inProgress: true, success: false } };

    case USER_VALIDATE_CURRENT_PASSWORD_SUCCESS:
      return { ...state, validateCurrentPassword: { errorMessage: null, inProgress: false, success: true } };

    case USER_VALIDATE_CURRENT_PASSWORD_FAIL:
      return { ...state, validateCurrentPassword: { ...action.payload, inProgress: false, success: false } };

    case USER_DELETE_ACCOUNT:
      return { ...state, deleteMyAccount: { errorMessage: null, inProgress: true, success: false } };

    case USER_DELETE_ACCOUNT_SUCCESS:
      return { ...state, deleteMyAccount: { errorMessage: null, inProgress: false, success: true } };

    case USER_DELETE_ACCOUNT_FAIL:
      return { ...state, deleteMyAccount: { ...action.payload, inProgress: false, success: false } };

    default:
      return state;
  }
};

export default userProfileReducer;
