import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { POLICY_PACKAGE_HEALTH_3, STEP_3, DOC_TYPE_ALL } from '../../../constants/business';
import { Row, Col, Form } from 'reactstrap';
import { CustomButton } from '../../../containers';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash-es';
import { ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_3 } from '../../../constants/routes';
import { TermsAndConditionsCheckbox } from '../../../components/TermsAndConditionsCheckbox';
import { PolicyContractingPersonInfo } from '../../../components/PolicyContractingPersonInfo';
import { useMutation } from 'react-apollo';
import { USER_ACCOUNT_TYPES, USER_GENDER } from '../../../constants/userDefs';
import { validateFormSubmission } from '../../../utils/validation';
import { PolicyInsuredPersonInfo } from '../../../components/PolicyInsuredPersonInfo';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { usePlacesQuery, useProductDocumentsQuery } from '../../../utils/customHooks';
import { displayCurrencyOptions } from '../../../utils/currencyDisplayFormat';
import { yearListRender } from '../../../constants/dateFormatting';
import { currencyConversionEuro } from '../../../utils/currencyConversion';

export const Step2Health = ({ data, user }) => {
  const history = useHistory();
  const { formatNumber } = useIntl();

  const { allTypeDocuments } = useProductDocumentsQuery({ docType: DOC_TYPE_ALL, productCode: data.type });

  const [submitForm, { loading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data => history.push(`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_3}/${data.serverStorage.stateKey}`),
  });

  const { places, loading: loadingPlaces } = usePlacesQuery();

  const [oib, setOIB] = useState({
    value: get(data, 'insuredPeople[0].oib', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: true,
      maxLength: 11,
      minLength: 11,
      oib: true,
    },
  });
  const [firstName, setFirstName] = useState({
    value: get(data, 'insuredPeople[0].name', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {
      required: true,
    },
  });
  const [lastName, setLastName] = useState({
    value: get(data, 'insuredPeople[0].surename', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {
      required: true,
    },
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value: get(data, 'insuredPeople[0].dateOfBirth', null) ? moment(data.insuredPeople[0].dateOfBirth) : null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.dateOfBirth" />,
    validation: {
      required: true,
    },
  });
  const [sex, setSex] = useState({
    value: get(data, 'insuredPeople[0].sex', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sex" />,
    validation: {
      required: true,
      enum: USER_GENDER.map(sex => sex.value),
    },
  });
  const [HZZONumber, setHZZONumber] = useState({
    value: get(data, 'insuredPeople[0].mbHzzo', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.HZZONumber" />,
    validation: {
      required: true,
      minLength: 9,
    },
  });
  const [address, setAddress] = useState({
    value: get(data, 'insuredPeople[0].address', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });
  const [zip, setZip] = useState({
    value: get(data, 'insuredPeople[0].zip', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
      enum: places.map(place => place.pttNumber),
    },
  });
  const [city, setCity] = useState({
    value: get(data, 'insuredPeople[0].city', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [email, setEmail] = useState({
    value: get(data, 'insuredPeople[0].email', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.email" />,
    validation: {
      required: true,
      email: true,
    },
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: get(data, 'insuredPeople[0].phoneNumber', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {
      required: true,
    },
  });
  const [sameContracting, setSameContracting] = useState({
    value: get(data, 'sameContracting', true),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sector" />,
    validation: {
      required: true,
      enum: USER_ACCOUNT_TYPES.map(type => type.name),
    },
  });

  const [oibAdditional, setOIBAdditional] = useState({
    value: get(data, 'insuredPeople[1].oib', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: true,
      maxLength: 11,
      minLength: 11,
      oib: true,
    },
  });
  const [firstNameAdditional, setFirstNameAdditional] = useState({
    value: get(data, 'insuredPeople[1].name', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {
      required: true,
    },
  });
  const [lastNameAdditional, setLastNameAdditional] = useState({
    value: get(data, 'insuredPeople[1].surename', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {
      required: true,
    },
  });
  const [dateOfBirthAdditional, setDateOfBirthAdditional] = useState({
    value: get(data, 'insuredPeople[1].dateOfBirth', null) ? moment(data.insuredPeople[1].dateOfBirth) : null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.dateOfBirth" />,
    validation: {
      required: true,
      date: {
        valid: true,
        maxValue: moment().subtract(18, 'year'),
      },
    },
  });
  const [sexAdditional, setSexAdditional] = useState({
    value: get(data, 'insuredPeople[1].sex', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sex" />,
    validation: {
      required: true,
      enum: USER_GENDER.map(sex => sex.value),
    },
  });
  const [HZZONumberAdditional, setHZZONumberAdditional] = useState({
    value: get(data, 'insuredPeople[1].mbHzzo', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.HZZONumber" />,
    validation: {
      required: true,
    },
  });
  const [addressAdditional, setAddressAdditional] = useState({
    value: get(data, 'insuredPeople[1].address', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });
  const [zipAdditional, setZipAdditional] = useState({
    value: get(data, 'insuredPeople[1].zip', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
    },
  });
  const [cityAdditional, setCityAdditional] = useState({
    value: get(data, 'insuredPeople[1].city', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [emailAdditional, setEmailAdditional] = useState({
    value: get(data, 'insuredPeople[1].email', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.email" />,
    validation: {
      required: true,
      email: true,
    },
  });
  const [phoneNumberAdditional, setPhoneNumberAdditional] = useState({
    value: get(data, 'insuredPeople[1].phoneNumber', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {
      required: true,
    },
  });

  const [tac, setTac] = useState({
    value: data.acceptedTerms || false,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.tac" />,
    validation: {
      isTrue: true,
    },
  });

  useEffect(() => {
    if (places.length > 0 && city.validation.enum.length < 1) {
      setCity(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
      setCityAdditional(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
    }
    if (places.length > 0 && zip.validation.enum.length < 1) {
      setZip(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
      setZipAdditional(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
    }
  }, [places, city, zip]);

  const tacAcceptedFn = useCallback(value => setTac(tac => ({ ...tac, value })), []);
  const tacInvalidFn = useCallback(invalid => setTac(tac => ({ ...tac, invalid })), []);

  const tacLabelRender = useCallback(
    toggleTacModal => (
      <div className="tac-text-container">
        <span>
          Potvrđujem kako je ovaj proizvod u skladu s mojim zahtjevima i potrebama te da sam temeljem
          <span onClick={toggleTacModal} className="terms-and-conditions-text">
            predugovornih dokumenata i uvjeta osiguranja
          </span>{' '}
          donio informiranu odluku o kupnji proizvoda.
        </span>
      </div>
    ),
    []
  );

  const healthStep2SubmitHandler = async event => {
    event.preventDefault();

    const fieldsArray = [setTac, setHZZONumber];

    // if (!sameContracting.value) {
    //   fieldsArray.push(
    //     setOIB,
    //     setAddress,
    //     setCity,
    //     setZip,
    //     setPhoneNumber,
    //     setEmail,
    //     setFirstName,
    //     setLastName,
    //     setDateOfBirth,
    //     setSex,
    //     setHZZONumber
    //   );
    // }

    if (
      data.productPackage === POLICY_PACKAGE_HEALTH_3 &&
      (oibAdditional.value || firstNameAdditional.value || lastNameAdditional.value)
    ) {
      fieldsArray.push(
        setOIBAdditional,
        setAddressAdditional,
        setCityAdditional,
        setZipAdditional,
        setPhoneNumberAdditional,
        setEmailAdditional,
        setFirstNameAdditional,
        setLastNameAdditional,
        setDateOfBirthAdditional,
        setSexAdditional,
        setHZZONumberAdditional
      );
    }

    if (await validateFormSubmission(fieldsArray)) {
      const insuredPeople = [
        {
          name: sameContracting.value ? user.firstName : firstName.value,
          surename: sameContracting.value ? user.lastName : lastName.value,
          dateOfBirth: sameContracting.value ? user.dateOfBirth : dateOfBirth.value,
          oib: sameContracting.value ? user.oib : oib.value,
          sex: sameContracting.value ? user.sex : sex.value,
          address: sameContracting.value ? user.address : address.value,
          city: sameContracting.value ? user.city : city.value,
          zip: sameContracting.value ? user.zip : zip.value,
          phoneNumber: sameContracting.value ? user.phoneNumber : phoneNumber.value,
          email: sameContracting.value ? user.email : email.value,
          mbHzzo: HZZONumber.value,
        },
      ];

      if (
        data.productPackage === POLICY_PACKAGE_HEALTH_3 &&
        (oibAdditional.value || firstNameAdditional.value || lastNameAdditional.value)
      ) {
        insuredPeople.push({
          name: firstNameAdditional.value,
          surename: lastNameAdditional.value,
          dateOfBirth: dateOfBirthAdditional.value,
          oib: oibAdditional.value,
          sex: sexAdditional.value,
          address: addressAdditional.value,
          city: cityAdditional.value,
          zip: zipAdditional.value,
          phoneNumber: phoneNumberAdditional.value,
          email: emailAdditional.value,
          mbHzzo: HZZONumberAdditional.value,
        });
      }

      submitForm({
        variables: {
          stateKey: data.stateKey,
          state: {
            ...data,
            step: STEP_3,
            // sameContracting: sameContracting.value,
            insuredPeople,
            acceptedTerms: tac.value,
          },
        },
      });
    }
  };

  const isAdditionalPersonInfoInvalid =
    firstNameAdditional.invalid ||
    lastNameAdditional.invalid ||
    oibAdditional.invalid ||
    dateOfBirthAdditional.invalid ||
    sexAdditional.invalid ||
    HZZONumberAdditional.invalid ||
    addressAdditional.invalid ||
    cityAdditional.invalid ||
    zipAdditional.invalid ||
    emailAdditional.invalid ||
    phoneNumberAdditional.invalid;

  return (
    <div className="step2">
      <Form onSubmit={healthStep2SubmitHandler}>
        <Row className="mt-4">
          <Col xs="12">
            <PolicyContractingPersonInfo user={user} labelId="userMyPolicies.policyData.policyContractor" />
            <PolicyInsuredPersonInfo
              user={user}
              places={places}
              loadingPlaces={loadingPlaces}
              sameContracting={sameContracting}
              setSameContracting={setSameContracting}
              invalid={HZZONumber.invalid}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              oib={oib}
              setOIB={setOIB}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              sex={sex}
              setSex={setSex}
              setHZZONumber={setHZZONumber}
              HZZONumber={HZZONumber}
              address={address}
              setAddress={setAddress}
              city={city}
              setCity={setCity}
              zip={zip}
              setZip={setZip}
              email={email}
              setEmail={setEmail}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              labelId="userMyPolicies.policyData.policyPersonInsured"
            />
            {data.productPackage === POLICY_PACKAGE_HEALTH_3 && (
              <PolicyInsuredPersonInfo
                additional={true}
                user={user}
                places={places}
                loadingPlaces={loadingPlaces}
                invalid={isAdditionalPersonInfoInvalid}
                firstName={firstNameAdditional}
                setFirstName={setFirstNameAdditional}
                lastName={lastNameAdditional}
                setLastName={setLastNameAdditional}
                oib={oibAdditional}
                setOIB={setOIBAdditional}
                dateOfBirth={dateOfBirthAdditional}
                setDateOfBirth={setDateOfBirthAdditional}
                sex={sexAdditional}
                setSex={setSexAdditional}
                setHZZONumber={setHZZONumberAdditional}
                HZZONumber={HZZONumberAdditional}
                address={addressAdditional}
                setAddress={setAddressAdditional}
                city={cityAdditional}
                setCity={setCityAdditional}
                zip={zipAdditional}
                setZip={setZipAdditional}
                email={emailAdditional}
                setEmail={setEmailAdditional}
                phoneNumber={phoneNumberAdditional}
                setPhoneNumber={setPhoneNumberAdditional}
                labelId="userMyPolicies.policyData.additionalPersonInsured"
                yearList={yearListRender(
                  moment().subtract(65, 'year').format('YYYY'),
                  moment().subtract(18, 'year').format('YYYY')
                )}
                dateOutsideRange={date =>
                  date.isAfter(moment().subtract(18, 'year'), 'day') ||
                  date.isBefore(moment().subtract(65, 'year'), 'day')
                }
              />
            )}
            <TermsAndConditionsCheckbox
              setTacAccepted={tacAcceptedFn}
              tacAccepted={tac.value}
              tacInvalid={tac.invalid}
              setTacInvalid={tacInvalidFn}
              productDocs={allTypeDocuments}
            >
              {tacLabelRender}
            </TermsAndConditionsCheckbox>
            <Row className="no-gutters mt-4">
              <Col xs="12">
                <span className="text-nowrap">
                  <span className="primary">
                    <FormattedMessage id="userMyPolicies.policyData.premium" />:
                  </span>
                  <span className="secondary ml-3">
                    {formatNumber(data.totalPremium, displayCurrencyOptions)} |{' '}
                    {currencyConversionEuro(data.totalPremium || 0)}
                  </span>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="12">
            <CustomButton
              className="hok-dashboard-btn-wide float-sm-right"
              translationId="goToPayment"
              loaderProp={loading}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
