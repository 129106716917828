import React, { Component } from 'react';
import { Row, Col, Alert, Form, FormGroup } from 'reactstrap';
import { CustomInputField, CustomButton } from '../../../containers';
import { get } from 'lodash-es';
import moment, { ISO_8601 } from 'moment';
import { validate } from '../../../utils/validation';
import { FormattedMessage } from 'react-intl';
import { LoginAndRegisterSelector } from '../../../containers';
import { clearUserErrorMessage } from '../../../redux/actions/userProfileActions';
import { userChangePassword } from '../../../redux/actions/userProfileActions';
import { connect } from 'react-redux';
import { redirectToUserDashboardPage } from '../../../redux/actions/navigationActions';

const mapStateToProps = state => ({
  intl: state.intl,
  user: state.auth.user,
  userProfile: state.userProfile,
});

const mapDispatchToProps = dispatch => ({
  userChangePassword: newPasswordData => dispatch(userChangePassword(newPasswordData)),
  clearUserErrorMessage: payload => dispatch(clearUserErrorMessage(payload)),
  redirectToUserDashboard: () => dispatch(redirectToUserDashboardPage()),
});

class PasswordExpired extends Component {
  state = {
    isFormValid: false,
    submitted: false,
    formData: {
      oldPassword: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.oldPassword" />,
        validation: {
          required: true,
        },
      },
      newPassword: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.newPassword" />,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatNewPassword: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.repeatNewPassword" />,
        validation: {
          required: true,
          matches: '',
        },
      },
    },
  };

  componentDidMount() {
    const passwordExpires = get(this.props, 'user.passwordExpires', null);
    if (!passwordExpires || (passwordExpires && moment(passwordExpires, ISO_8601, true).isAfter(moment()))) {
      this.props.redirectToUserDashboard();
    }
  }

  componentWillUnmount() {
    this.clearErrorOnValueChange();
  }

  removeFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: false } },
    });
  };

  addFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: true } },
    });
  };

  inputHandler = (field, value) => {
    this.clearErrorOnValueChange();
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: {
          ...this.state.formData[field],
          value: value,
        },
        ...(field === 'newPassword' && {
          repeatNewPassword: {
            ...this.state.formData.repeatNewPassword,
            validation: {
              ...this.state.formData.repeatNewPassword.validation,
              matches: value,
            },
          },
        }),
      },
    });
  };

  updateUserPassword = event => {
    event.preventDefault();
    let formDataObject = { ...this.state.formData };
    let isFormValid = true;
    /* eslint-disable no-unused-vars */
    for (let field in formDataObject) {
      const validation = validate(formDataObject[field]);
      formDataObject[field] = {
        ...formDataObject[field],
        invalid: validation.invalid,
        error: validation.errors[0],
      };
      isFormValid = isFormValid && !validation.invalid;
    }
    /* eslint-enable no-unused-vars */

    this.setState({ isFormValid, submitted: true, formData: formDataObject }, () => {
      if (isFormValid) {
        const payloadObject = {};
        /* eslint-disable no-unused-vars */
        for (let inputObject in this.state.formData) {
          if (inputObject === 'repeatNewPassword') continue;
          if (this.state.formData[inputObject].value) {
            payloadObject[inputObject] = this.state.formData[inputObject].value;
          }
        }
        /* eslint-enable no-unused-vars */

        // Update action
        this.props.userChangePassword(payloadObject);
        this.setState({ submitted: false });
      }
    });
  };

  clearErrorOnValueChange = () => {
    if (get(this.props, 'userProfile.changePassword.errorMessage', null)) {
      this.props.clearUserErrorMessage('changePassword');
    }
  };

  render() {
    return (
      <LoginAndRegisterSelector titleTranslationId="passwordExpiredPage.title">
        <Row className="no-gutters px-4 my-5 my-md-0">
          <Col xs={12} sm={{ offset: 2, size: 8 }} md={{ offset: 0, size: 12 }} lg={{ offset: 1, size: 10 }}>
            <Row className="mt-2 mb-0 mb-lg-3">
              <Col>
                <p className="font-weight-bold">
                  <FormattedMessage id="passwordExpiredPage.subtitle" />
                </p>
              </Col>
            </Row>
            <Row className="mb-0 mb-lg-4">
              <Col>
                <p>
                  <FormattedMessage id="passwordExpiredPage.paragraph1" />
                </p>
              </Col>
            </Row>
            {get(this.props, 'userProfile.changePassword.errorMessage', null) && (
              <Alert color="danger" role="alert">
                {this.props.userProfile.changePassword.errorMessage}
              </Alert>
            )}
            <Form onSubmit={this.updateUserPassword}>
              <FormGroup row>
                <Col xs="12">
                  <CustomInputField
                    id="oldPassword"
                    name="oldPassword"
                    fieldState={this.state.formData.oldPassword}
                    icon="lock"
                    formSubmitted={this.state.submitted}
                    type="password"
                    maxLength={55}
                    onChange={event => this.inputHandler('oldPassword', event.target.value)}
                    onFocus={() => this.addFocus('oldPassword')}
                    onBlur={() => this.removeFocus('oldPassword')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs="12">
                  <CustomInputField
                    id="newPassword"
                    name="newPassword"
                    fieldState={this.state.formData.newPassword}
                    icon="lock"
                    formSubmitted={this.state.submitted}
                    type="password"
                    maxLength={55}
                    onChange={event => this.inputHandler('newPassword', event.target.value)}
                    onFocus={() => this.addFocus('newPassword')}
                    onBlur={() => this.removeFocus('newPassword')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs="12">
                  <CustomInputField
                    id="repeatNewPassword"
                    name="repeat new password"
                    fieldState={this.state.formData.repeatNewPassword}
                    icon="lock"
                    formSubmitted={this.state.submitted}
                    type="password"
                    maxLength={55}
                    onChange={event => this.inputHandler('repeatNewPassword', event.target.value)}
                    onFocus={() => this.addFocus('repeatNewPassword')}
                    onBlur={() => this.removeFocus('repeatNewPassword')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-5">
                <Col xs="12">
                  <CustomButton
                    loaderProp={get(this.props, 'userProfile.changePassword.inProgress', false)}
                    translationId="submitNewPassword"
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </LoginAndRegisterSelector>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordExpired);
