import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { ReactComponent as HokPhoneIcon } from '../../img/icons/hok-phone.svg';
import { ReactComponent as HokFaxIcon } from '../../img/icons/hok-fax.svg';
import { ReactComponent as HokEmailIcon } from '../../img/icons/hok-email.svg';

export const SupportInfo = ({
  wrap = true,
  showPhoneNumber = true,
  showFaxNumber = false,
  showEmail = true,
  colSizes = { xs: 12 },
  className = '',
  colClassName,
}) => {
  const supportData = useSelector(state => state.support);

  const mainContentRender = () => (
    <>
      {showPhoneNumber && supportData.phoneNumber && (
        <Row className="no-gutters mb-3">
          <Col {...colSizes} className={('d-flex align-items-center ' + colClassName).trim()}>
            <HokPhoneIcon className="svg-red" />
            <a className="hok-support-icon-label" href={`tel://${supportData.phoneNumber}`}>
              {supportData.phoneNumber}
            </a>
          </Col>
        </Row>
      )}
      {showFaxNumber && supportData.faxNumber && (
        <Row className="no-gutters mb-3">
          <Col {...colSizes} className={('d-flex align-items-center ' + colClassName).trim()}>
            <HokFaxIcon className="svg-red" />
            <a className="hok-support-icon-label" href={`tel://${supportData.faxNumber}`}>
              {supportData.faxNumber}
            </a>
          </Col>
        </Row>
      )}
      {showEmail && supportData.email && (
        <Row className="no-gutters">
          <Col {...colSizes} className={('d-flex align-items-center ' + colClassName).trim()}>
            <HokEmailIcon className="svg-red" />
            <a className="hok-support-icon-label" href={`mailto://${supportData.email}`}>
              {supportData.email}
            </a>
          </Col>
        </Row>
      )}
    </>
  );

  if (wrap) {
    return (
      <Row className={('no-gutters ' + className).trim()}>
        <Col>{mainContentRender()}</Col>
      </Row>
    );
  }

  return mainContentRender();
};
