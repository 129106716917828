import gql from 'graphql-tag';

export const UserChangePasswordMutation = gql`
  mutation UserChangePassword($oldPassword: String!, $newPassword: String!) {
    userChangePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      passwordUpdated
    }
  }
`;

export default UserChangePasswordMutation;
