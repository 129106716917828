import React from 'react';
import moment from 'moment';

export const FORMAT_STANDARD_DATE = 'DD.MM.YYYY.';
export const FORMAT_STANDARD_DATE_TIME = 'DD.MM.YYYY. HH:mm:ss';
export const FORMAT_STANDARD_DATE_TIME_SHORT = 'DD.MM.YYYY. HH:mm';

export const formatDate = dateString => {
  if (moment(dateString, moment.ISO_8601, true).isValid()) {
    return moment(dateString).format(FORMAT_STANDARD_DATE);
  } else {
    return dateString;
  }
};

export const formatDateTime = (dateString, seconds = true) => {
  if (moment(dateString, moment.ISO_8601, true).isValid()) {
    return moment(dateString).format(seconds ? FORMAT_STANDARD_DATE_TIME : FORMAT_STANDARD_DATE_TIME_SHORT);
  } else {
    return dateString;
  }
};

export const yearListRender = (
  startYear = 1900,
  endYear = new Date(moment().subtract('18', 'years')).getFullYear()
) => {
  let yearList = [];

  while (endYear >= startYear) {
    yearList.push(endYear--);
  }

  return yearList.map(year => {
    return (
      <option value={year} key={year}>
        {year}
      </option>
    );
  });
};
