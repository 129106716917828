import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { ScreenTitleContainer, CustomButton } from '../../../containers';
import { MyPoliciesCardList } from '../../../components/MyPoliciesCardList';
import { FormattedMessage } from 'react-intl';
import { redirectToUserProductsPage } from '../../../redux/actions/navigationActions';

export const AssistancePage = () => {
  const dispatch = useDispatch();

  return (
    <Container className="hok-container" fluid>
      <Row className="no-gutters">
        <Col>
          <ScreenTitleContainer row titleId="routes.assistance" />
          <Row className="no-gutters">
            <MyPoliciesCardList assistance>
              <Col xs="12">
                <Row>
                  <p className="hok-text-dark pr-1">
                    <FormattedMessage id="userAssistancePage.noAssistanceFound" />
                  </p>
                </Row>
                <Row>
                  <CustomButton
                    className="hok-dashboard-btn-wide mt-3"
                    translationId="newPolicy"
                    onClick={() => dispatch(redirectToUserProductsPage())}
                  />
                </Row>
              </Col>
            </MyPoliciesCardList>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
