import gql from 'graphql-tag';

export const GetProductRiskSumsQuery = gql`
  query GetProductRiskSums(
    $riskCode: productRisksSumsRiskCodeEnum!
    $vehicleTypeCode: productRisksSumsVehicleTypeCodeEnum!
    $classCode: productRisksSumsClassCodeEnum!
  ) {
    getProductRisksSums(riskCode: $riskCode, vehicleTypeCode: $vehicleTypeCode, classCode: $classCode) {
      id
      riskCode
      vehicleTypeCode
      classCode
      sumCode
      sumEnumCode
      sumDesc
      active
      defaultSelected
    }
  }
`;
