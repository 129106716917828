import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { GetNewsForPreviewQuery } from '../../../operations/queries/GetNewsQuery';
import { formatDate } from '../../../constants/dateFormatting';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../containers';

export const MobileNewsPostPreview = () => {
  const params = useParams();
  const postId = get(params, 'postId', null);

  const { data, loading, error } = useQuery(GetNewsForPreviewQuery, {
    variables: { id: parseInt(postId, 10) },
  });

  if (loading || (!data && !error)) {
    return <Spinner className="spinner-positioning" />;
  }

  const selecedPost = get(data, 'news', [])[0];

  if (error || !selecedPost) {
    return null;
  }

  return (
    <Row className="hok-mobile-news-preview no-gutters">
      <Col xs="12">
        <Row className="no-gutters">
          <Col>
            <div
              className="hok-news-preview-card-image"
              style={{ backgroundImage: `url("${selecedPost.featuredImageUrl}")` }}>
              <img
                style={{ maxHeight: '410px' }}
                className="img-fluid invisible w-100"
                src={selecedPost.featuredImageUrl}
                alt={selecedPost.title}
              />
            </div>
          </Col>
        </Row>
        <Row className="no-gutters hok-news-preview-card-content">
          <Col>
            <Row className="no-gutters">
              <Col>
                <h2>{selecedPost.title}</h2>
              </Col>
            </Row>
            <Row className="no-gutters mb-5">
              <Col>
                <p className="hok-news-preview-card-published">
                  <FormattedMessage id="userNewsListPage.publishedAt" />: {formatDate(selecedPost.publishedAt)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div dangerouslySetInnerHTML={{ __html: selecedPost.content }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
