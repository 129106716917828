import React from 'react';
import { Row, Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { GetLegalDocumentsQuery } from '../../../operations/queries/GetLegalDocumentsQuery';
import { UVJETI_KORISTENJA } from '../../../constants/documentTypes';
import { Spinner } from '../../../containers';

export const TermsOfService = () => {
  const { data, loading, error } = useQuery(GetLegalDocumentsQuery, {
    variables: { filter: { documentId: UVJETI_KORISTENJA, active: true } },
  });

  const legalDocuments = get(data, 'getLegalDocuments.documents', []);

  if (loading || (!data && !error)) {
    return <Spinner className="spinner-positioning" />;
  }

  if (error || !legalDocuments[0]) {
    return null;
  }

  return (
    <Row className="no-gutters">
      <Col className="p-2">
        <div dangerouslySetInnerHTML={{ __html: legalDocuments[0].content }} />
      </Col>
    </Row>
  );
};
