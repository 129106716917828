import gql from 'graphql-tag';

export const GetFAQsQuery = gql`
  query GetFAQs($filter: getFAQFilterInput) {
    FAQs(filter: $filter) {
      FAQsGroupedByQuestionGroup {
        groupName
        FAQs {
          id
          subject
          description
          attachmentURI
        }
      }
    }
  }
`;

export const GetSingleFAQQuery = gql`
  query GetSingleFAQ($filter: getFAQFilterInput) {
    FAQs(filter: $filter) {
      FAQs {
        id
        subject
        description
        attachmentURI
      }
    }
  }
`;
