import { all } from 'redux-saga/effects';
import {
  redirectToAdminLoginPageWatcher,
  redirectToAdminErrorPageWatcher,
  redirectToAdminEmployeeListPageWatcher,
  redirectToAdminUserListPageWatcher,
  adminLoginSuccessWatcher,
  adminLogoutWatcher,
  adminLoginFailureWatcher,
  redirectToAdminDashboardPageWatcher,
  redirectToUserDashboardPageWatcher,
  redirectToUserAccountInfoWatcher,
  redirectToMaintenancePageWatcher,
  redirectToActivateAccountPageWatcher,
  userLoginSuccessWatcher,
  userLoginFailWatcher,
  userLogoutWatcher,
  userAccountActivationSuccessWatcher,
  userPasswordResetTokenCheckFailWatcher,
  userPasswordChangeSuccessWatcher,
  redirectToUserLoginPageWatcher,
  redirectToUserRegistrationCompletePageWatcher,
  redirectToAdminCreateUserPageWatcher,
  redirectToAdminEditPostPageWatcher,
  redirectToAdminCreatePostWatcher,
  redirectToAdminEditUserPageWatcher,
  redirectToAdminEditDeletedUserPageWatcher,
  redirectToAdminCreateEmployeePageWatcher,
  redirectToAdminEditEmployeePageWatcher,
  redirectToAdminPreviewPostPageWatcher,
  redirectToUserProductsPageWatcher,
  redirectToUserNewTravelInsurancePageWatcher,
  redirectToUserNewHealthInsurancePageWatcher,
  redirectToSpecificDamageClaimPageWatcher,
  redirectToAdminEditUserSingleClaimPageWatcher,
  redirectToAdminEditEmployeeSingleClaimPageWatcher,
  redirectToAdminSingleTicketPageWatcher,
} from './navigationSagas';
import {
  attemptAdminLoginUsernamePasswordWatcher,
  refreshTokenWatcher,
  authorizedUserLogoutWatcher,
  authorizedUserTokenExpiredWatcher,
} from './adminAuthSagas';
import {
  userRegistrationWatcher,
  loadUserProfileFromTokenWatcher,
  userLoginUsernamePasswordWatcher,
  resendAccountActivationEmailWatcher,
  activateUserAccountWatcher,
  userForgotPasswordWatcher,
  userPasswordResetWatcher,
  userPasswordResetTokenCheckWatcher,
} from './userAuthSagas';
import {
  userChangePasswordWatcher,
  userProfileForgotPasswordWatcher,
  userUpdateProfileAdvancedDataWatcher,
  userChangePhoneNumberWatcher,
  userUpdateAvatarWatcher,
  userValidateCurrentUserPasswordWatcher,
  userDeleteAccountWatcher,
} from './userProfileSagas';
import {
  loadUsersWatcher,
  createUserWatcher,
  deleteUserWatcher,
  updateUserWatcher,
  createEmployeeWatcher,
  updateEmployeeWatcher,
} from './usersSagas';
import { loadSupportDataWatcher } from './supportSagas';

export default function* rootSaga() {
  yield all([
    /** Admin Authentication Sagas **/
    attemptAdminLoginUsernamePasswordWatcher(),
    refreshTokenWatcher(),
    authorizedUserLogoutWatcher(),
    authorizedUserTokenExpiredWatcher(),
    /** User Authentication Sagas **/
    userRegistrationWatcher(),
    loadUserProfileFromTokenWatcher(),
    userLoginUsernamePasswordWatcher(),
    resendAccountActivationEmailWatcher(),
    activateUserAccountWatcher(),
    userForgotPasswordWatcher(),
    userAccountActivationSuccessWatcher(),
    userPasswordResetWatcher(),
    userPasswordResetTokenCheckWatcher(),
    /** User Profile Sagas **/
    userChangePasswordWatcher(),
    userProfileForgotPasswordWatcher(),
    userUpdateProfileAdvancedDataWatcher(),
    userChangePhoneNumberWatcher(),
    userUpdateAvatarWatcher(),
    userValidateCurrentUserPasswordWatcher(),
    userDeleteAccountWatcher(),
    /** Support Sagas **/
    loadSupportDataWatcher(),
    /** Navigation Sagas **/
    redirectToAdminLoginPageWatcher(),
    redirectToAdminErrorPageWatcher(),
    redirectToAdminEmployeeListPageWatcher(),
    redirectToAdminUserListPageWatcher(),
    redirectToAdminDashboardPageWatcher(),
    redirectToUserDashboardPageWatcher(),
    redirectToUserAccountInfoWatcher(),
    adminLoginSuccessWatcher(),
    adminLoginFailureWatcher(),
    adminLogoutWatcher(),
    redirectToMaintenancePageWatcher(),
    redirectToActivateAccountPageWatcher(),
    userLoginSuccessWatcher(),
    userLoginFailWatcher(),
    userLogoutWatcher(),
    redirectToUserNewTravelInsurancePageWatcher(),
    redirectToUserNewHealthInsurancePageWatcher(),
    redirectToSpecificDamageClaimPageWatcher(),
    redirectToUserProductsPageWatcher(),
    userPasswordResetTokenCheckFailWatcher(),
    userPasswordChangeSuccessWatcher(),
    redirectToUserLoginPageWatcher(),
    redirectToAdminCreateUserPageWatcher(),
    redirectToAdminEditPostPageWatcher(),
    redirectToAdminCreatePostWatcher(),
    redirectToAdminEditUserPageWatcher(),
    redirectToAdminEditDeletedUserPageWatcher(),
    redirectToAdminEditUserSingleClaimPageWatcher(),
    redirectToAdminEditEmployeeSingleClaimPageWatcher(),
    redirectToUserRegistrationCompletePageWatcher(),
    redirectToAdminCreateEmployeePageWatcher(),
    redirectToAdminEditEmployeePageWatcher(),
    redirectToAdminPreviewPostPageWatcher(),
    redirectToAdminSingleTicketPageWatcher(),
    /** Users Sagas **/
    loadUsersWatcher(),
    createUserWatcher(),
    updateUserWatcher(),
    deleteUserWatcher(),
    createEmployeeWatcher(),
    updateEmployeeWatcher(),
  ]);
}
