import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { CustomButton } from '../../containers';
import { ROUTE_TO_USER_DASHBOARD_PAGE } from '../../constants/routes';
import { FormattedMessage } from 'react-intl';

export const EndStep = ({ policyTranslationBase, policyPage, messageId }) => {
  const history = useHistory();

  return (
    <>
      <Row className="step4 mt-3">
        <Col>
          <h2>
            <FormattedMessage id={`${policyTranslationBase}.${messageId}`} />
          </h2>
          <p className="mt-4">
            Potvrda ugovorene police je poslana na Vašu e-mail adresu.
            <br />
            Vaša polica se trenutno obrađuje te će biti vidljiva u kraćem vremenu.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomButton
            className="hok-dashboard-btn-wide float-sm-right mt-4"
            translationId="backToHome"
            onClick={() => history.push(ROUTE_TO_USER_DASHBOARD_PAGE)}
          />
        </Col>
      </Row>
    </>
  );
};
