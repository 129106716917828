import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Label, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { userChangeAvatar } from '../../redux/actions/userProfileActions';
import { ReactComponent as DefaultPartnerAvatar } from '../../img/hok-partner-avatar.svg';

export const AvatarDisplayAndChange = ({ className, avatar, showChangeButton = true }) => {
  const dispatch = useDispatch();

  return (
    <Row className={className}>
      <Col xs="12">
        <h3>
          <FormattedMessage id="userProfilePage.userPicture" />
        </h3>
      </Col>
      <Col className="d-block d-sm-flex align-items-end mt-4">
        {avatar ? (
          <div className="img-avatar mx-0 mx-sm-5" style={{ backgroundImage: `url("${avatar}")` }} />
        ) : (
          <DefaultPartnerAvatar className="img-avatar mx-0 mx-sm-5" />
        )}
        {showChangeButton && (
          <Label className="ml-0 ml-sm-3 mt-4 mt-sm-0 mb-0">
            <div className="hok-upload-button btn btn-outline-primary">
              <FormattedMessage id="buttons.changePicture" />
            </div>
            <Input
              type="file"
              id="avatar"
              name="avatar"
              hidden
              onChange={async event => {
                if (event.target.files[0] && typeof event.target.files[0] !== 'undefined') {
                  dispatch(userChangeAvatar(event.target.files[0]));
                }
              }}
            />
          </Label>
        )}
      </Col>
    </Row>
  );
};
