import React from 'react';

export const AdFormIFrame = React.memo(({ title }) => {
  const pageName = encodeURIComponent(title);
  const adformId = process.env.REACT_APP_ADFORM_ID;

  if (adformId && title) {
    return (
      <iframe
        title={title}
        src={`https://track.adform.net/Serving/TrackPoint/?pm=${adformId}&ADFPageName=${pageName}&ADFdivider=|&ADFtpmode=4`}
        style={{ display: 'none' }}></iframe>
    );
  }
  return null;
});
