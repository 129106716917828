import React from 'react';
import { get } from 'lodash-es';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { generalError } from '../redux/actions/errorActions';
import { adminLoginFailure } from '../redux/actions/adminAuthActions';
import { renderToaster, ERROR_TOASTER } from './toaster';
import { isAdminLoggedIn } from '../utils/auth';
import { userLoginFail, userAccountEmailDeactivated } from '../redux/actions/userAuthActions';

export const INVALID_AUTHENTICATION_TOKEN = 'INVALID_AUTHENTICATION_TOKEN';
export const UNKNOWN_LOGIN_FAIL = 'UNKNOWN_LOGIN_FAIL';
export const UNKNOWN_REGISTRATION_FAIL = 'UNKNOWN_REGISTRATION_FAIL';
export const UNKNOWN_ACCOUNT_ACTIVATION_FAIL = 'UNKNOWN_ACCOUNT_ACTIVATION_FAIL';
export const UNKNOWN_RESEND_ACTIVATION_EMAIL_FAIL = 'UNKNOWN_RESEND_ACTIVATION_EMAIL_FAIL';
export const UNKNOWN_FORGOT_PASSWORD_FAIL = 'UNKNOWN_FORGOT_PASSWORD_FAIL';
export const UNKNOWN_PASSWORD_RESET_FAIL = 'UNKNOWN_PASSWORD_RESET_FAIL';
export const UNKNOWN_PHONE_NUMBER_CHANGE_FAIL = 'UNKNOWN_PHONE_NUMBER_CHANGE_FAIL';
export const UNKNOWN_PASSWORD_CHANGE_FAIL = 'UNKNOWN_PASSWORD_CHANGE_FAIL';
export const UNKNOWN_PROFILE_ADVANCED_DATA_UPDATE = 'UNKNOWN_PROFILE_ADVANCED_DATA_UPDATE';
export const UNKNOWN_USER_VALIDATE_CURRENT_PASSWORD = 'UNKNOWN_USER_VALIDATE_CURRENT_PASSWORD';
export const UNKNOWN_USER_DELETE_ACCOUNT = 'UNKNOWN_USER_DELETE_ACCOUNT';
export const UNKNOWN_CMS_FEATURED_IMAGE_UPLOAD = 'UNKNOWN_CMS_FEATURED_IMAGE_UPLOAD';
export const UNKNOWN_DOCUMENT_UPLOAD_FAIL = 'UNKNOWN_DOCUMENT_UPLOAD_FAIL';
export const INVALID_CORS_POLICY = 'INVALID_CORS_POLICY';
export const AUTHENTICATION_TOKEN_HAS_EXPIRED = 'AUTHENTICATION_TOKEN_HAS_EXPIRED';
export const AUTH_VALID_BUT_USER_DATA_MISSING = 'AUTH_VALID_BUT_USER_DATA_MISSING';
export const AUTHENTICATION_DATA_IS_MISSING = 'AUTHENTICATION_DATA_IS_MISSING';
export const INVALID_TOKEN_FORMAT = 'INVALID_TOKEN_FORMAT';
export const INVALID_INPUT_DATE_FORMAT = 'INVALID_INPUT_DATE_FORMAT';
export const WHILE_CREATING_NEW_USER = 'WHILE_CREATING_NEW_USER';
export const RESOLVER_DISABLED = 'RESOLVER_DISABLED';
export const USER_ALREADY_ACTIVATED = 'USER_ALREADY_ACTIVATED';
export const INVALID_RESOLVER_SPECIFIC_TOKEN = 'INVALID_RESOLVER_SPECIFIC_TOKEN';
export const CANT_UPDATE_DATA_TO_DATABASE = 'CANT_UPDATE_DATA_TO_DATABASE';
export const PASSWORD_IS_INVALID = 'PASSWORD_IS_INVALID';
export const CANT_CREATE_AUDIT_DATA = 'CANT_CREATE_AUDIT_DATA';
export const INVALID_PHONE_NUMBER_FORMAT = 'INVALID_PHONE_NUMBER_FORMAT';
export const INVALID_APPLICATION_ID_OR_INVALID_TOKEN = 'INVALID_APPLICATION_ID_OR_INVALID_TOKEN';
export const APPLICATION_REFERENCE_PROBLEM = 'APPLICATION_REFERENCE_PROBLEM';
export const INVALID_ACTIVATION_PIN = 'INVALID_ACTIVATION_PIN';
export const DUPLICATE_EMAIL_DETECTED = 'DUPLICATE_EMAIL_DETECTED';
export const INVALID_CAPTCHA = 'INVALID_CAPTCHA';
export const USER_HAS_NO_ROLE = 'USER_HAS_NO_ROLE';
export const RESOLVER_ONLY_FOR_ADMINS = 'RESOLVER_ONLY_FOR_ADMINS';
export const USER_HAS_NO_ACCESS = 'USER_HAS_NO_ACCESS';
export const WHILE_LOOKING_FOR_SPECIFIC_USER = 'WHILE_LOOKING_FOR_SPECIFIC_USER';
export const NO_SPECIFIC_USER_OR_PASSWORD_COMBINATION = 'NO_SPECIFIC_USER_OR_PASSWORD_COMBINATION';
export const NO_SPECIFIC_USER_FOUND = 'NO_SPECIFIC_USER_FOUND';
export const MORE_THAN_ONE_USER_OR_PASSWORD_COMBINATION_FOUND = 'MORE_THAN_ONE_USER_OR_PASSWORD_COMBINATION_FOUND';
export const ACCOUNT_IS_LOCKED = 'ACCOUNT_IS_LOCKED';
export const ACCOUNT_IS_LOCKED_TOO_MANY_FAILED_ATTEMPTS = 'ACCOUNT_IS_LOCKED_TOO_MANY_FAILED_ATTEMPTS';
export const INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT';
export const INVALID_PIN_FORMAT = 'INVALID_PIN_FORMAT';
export const WHILE_DELETING_NEW_USER = 'WHILE_DELETING_NEW_USER';
export const DUPLICATE_USER_DETECTED = 'DUPLICATE_USER_DETECTED';
export const DUPLICATE_PHONE_NUMBER_DETECTED = 'DUPLICATE_PHONE_NUMBER_DETECTED';
export const INVALID_OIB_FORMAT = 'INVALID_OIB_FORMAT';
export const RESOLVER_COOLDOWN_IS_ACTIVE = 'RESOLVER_COOLDOWN_IS_ACTIVE';
export const NO_RESULTS_FOUND = 'NO_RESULTS_FOUND';
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export const MAINTENANCE_IN_PROGRESS = 'MAINTENANCE_IN_PROGRESS';
export const FAILED_TO_FETCH = 'FAILED_TO_FETCH';
export const USER_NOT_ACTIVATED = 'USER_NOT_ACTIVATED';
export const UPLOAD_FILE_EXCEEDS_MAX_SIZE = 'UPLOAD_FILE_EXCEEDS_MAX_SIZE';
export const ACCOUNT_HAS_NO_REQUIRED_ROLE_FOR_SPECIFIC_LOGIN_DOOR =
  'ACCOUNT_HAS_NO_REQUIRED_ROLE_FOR_SPECIFIC_LOGIN_DOOR';
export const HOK_EXPRESS_CONTRACTOR_TOO_YOUNG = 'HOK_EXPRESS_CONTRACTOR_TOO_YOUNG';
export const HOK_EXPRESS_START_DATE_AFTER_FORWARD_MAX = 'HOK_EXPRESS_START_DATE_AFTER_FORWARD_MAX';
export const HOK_EXPRESS_NOT_SUPPORTED_VEHICLE_TYPE = 'HOK_EXPRESS_NOT_SUPPORTED_VEHICLE_TYPE';
export const HOK_EXPRESS_START_DATE_BEFORE_CURRENT_DATE = 'HOK_EXPRESS_START_DATE_BEFORE_CURRENT_DATE';
export const HOK_EXPRESS_PERSON_HAS_TO_HAVE_ONE_ACTIVE_AO_AK_POLICY =
  'HOK_EXPRESS_PERSON_HAS_TO_HAVE_ONE_ACTIVE_AO_AK_POLICY';
export const HOK_EXPRESS_VEHICLE_TOO_OLD = 'HOK_EXPRESS_VEHICLE_TOO_OLD';
export const HOK_EXPRESS_VEHICLE_TOO_HEAVY = 'HOK_EXPRESS_VEHICLE_TOO_HEAVY';
export const HOK_PZO_START_DATE_BEFORE_CURRENT_DATE = 'HOK_PZO_START_DATE_BEFORE_CURRENT_DATE';
export const HOK_PZO_DURATION_TOO_SHORT = 'HOK_PZO_DURATION_TOO_SHORT';
export const HOK_PZO_DURATION_TOO_LONG = 'HOK_PZO_DURATION_TOO_LONG';
export const HOK_PZO_INVALID_ACTIVITY = 'HOK_PZO_INVALID_ACTIVITY';
export const HOK_PZO_CONTRACTOR_TOO_YOUNG = 'HOK_PZO_CONTRACTOR_TOO_YOUNG';
export const HOK_PZO_NO_INSURED_PEOPLE = 'HOK_PZO_NO_INSURED_PEOPLE';
export const HOK_PZO_TOO_MANY_INSURED_PEOPLE = 'HOK_PZO_TOO_MANY_INSURED_PEOPLE';
export const HOK_PZO_START_DATE_AFTER_FORWARD_MAX = 'HOK_PZO_START_DATE_AFTER_FORWARD_MAX';
export const HOK_PZO_CONTRACTOR_CAN_ONLY_BE_FROM_PRIVATE_SECTOR = 'HOK_PZO_CONTRACTOR_CAN_ONLY_BE_FROM_PRIVATE_SECTOR';
export const HOK_EUREKA_START_DATE_BEFORE_CURRENT_DATE = 'HOK_EUREKA_START_DATE_BEFORE_CURRENT_DATE';
export const HOK_EUREKA_INSURED_PERSON_TOO_OLD = 'HOK_EUREKA_INSURED_PERSON_TOO_OLD';
export const HOK_EUREKA_TOO_MANY_INSURED_PEOPLE = 'HOK_EUREKA_TOO_MANY_INSURED_PEOPLE';
export const HOK_EUREKA_START_DATE_AFTER_FORWARD_MAX = 'HOK_EUREKA_START_DATE_AFTER_FORWARD_MAX';
export const HOK_EUREKA_CONTRACTOR_CAN_ONLY_BE_FROM_PRIVATE_SECTOR =
  'HOK_EUREKA_CONTRACTOR_CAN_ONLY_BE_FROM_PRIVATE_SECTOR';
export const HOK_EUREKA_INSURED_PERSON_HAS_INVALID_MBHZZO = 'HOK_EUREKA_INSURED_PERSON_HAS_INVALID_MBHZZO';
export const HOK_AUTO_CONTRACTOR_TOO_YOUNG = 'HOK_AUTO_CONTRACTOR_TOO_YOUNG';
export const HOK_AUTO_VEHICLE_TOO_OLD = 'HOK_AUTO_VEHICLE_TOO_OLD';
export const HOK_AUTO_NOT_SUPPORTED_VEHICLE_TYPE = 'HOK_AUTO_NOT_SUPPORTED_VEHICLE_TYPE';
export const HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE = 'HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE';
export const HOK_AUTO_START_DATE_BEFORE_BACKWARD_MAX = 'HOK_AUTO_START_DATE_BEFORE_BACKWARD_MAX';
export const HOK_AUTO_START_DATE_AFTER_FORWARD_MAX = 'HOK_AUTO_START_DATE_AFTER_FORWARD_MAX';
export const HOK_AUTO_END_DATE_AFTER_FORWARD_MAX = 'HOK_AUTO_END_DATE_AFTER_FORWARD_MAX';
export const HOK_AUTO_NOT_SUPPORTED_COVER = 'HOK_AUTO_NOT_SUPPORTED_COVER';
export const HOK_AUTO_NOT_SUPPORTED_COVER_SUM = 'HOK_AUTO_NOT_SUPPORTED_COVER_SUM';
export const HOK_AUTO_COVER_SUM_MISSING = 'HOK_AUTO_COVER_SUM_MISSING';
export const HOK_AUTO_COVER_ADDITIONAL_DATA_MISSING = 'HOK_AUTO_COVER_ADDITIONAL_DATA_MISSING';
export const HOK_AUTO_NOT_SUPPORTED_COVER_FOR_SPECIFIC_VEHICLE_TYPE =
  'HOK_AUTO_NOT_SUPPORTED_COVER_FOR_SPECIFIC_VEHICLE_TYPE';
export const HOK_AUTO_NOT_SUPPORTED_COVER_COMBINATION = 'HOK_AUTO_NOT_SUPPORTED_COVER_COMBINATION';
export const HOK_AUTO_VEHICLE_TOO_OLD_FOR_SPECIFIC_COVER = 'HOK_AUTO_VEHICLE_TOO_OLD_FOR_SPECIFIC_COVER';
export const HOK_AUTO_START_DATE_AFTER_FORWARD_MAX_DIFFERENT_OWNER =
  'HOK_AUTO_START_DATE_AFTER_FORWARD_MAX_DIFFERENT_OWNER';
export const HOK_AUTO_START_DATE_BEFORE_BACKWARD_MAX_DIFFERENT_OWNER =
  'HOK_AUTO_START_DATE_BEFORE_BACKWARD_MAX_DIFFERENT_OWNER';
export const HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE_DIFFERENT_OWNER =
  'HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE_DIFFERENT_OWNER';
export const HOK_AUTO_INVALID_REGISTRATION_PLATE = 'HOK_AUTO_INVALID_REGISTRATION_PLATE';
export const HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE_NEW = 'HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE_NEW';
export const HOK_AUTO_START_DATE_AFTER_FORWARD_MAX_NEW = 'HOK_AUTO_START_DATE_AFTER_FORWARD_MAX_NEW';
export const DUPLICATE_VIN_DETECTED = 'DUPLICATE_VIN_DETECTED';
export const WHITELIST_REGISTRATION_DISABLED = 'WHITELIST_REGISTRATION_DISABLED';
export const WHITELIST_LOGIN_DISABLED = 'WHITELIST_LOGIN_DISABLED';
export const WHILE_LOOKING_FOR_SPECIFIC_PAYMENT_ID = 'WHILE_LOOKING_FOR_SPECIFIC_PAYMENT_ID';
export const SPECIFIC_PAYMENT_ID_IS_ALREADY_PAID = 'SPECIFIC_PAYMENT_ID_IS_ALREADY_PAID';
export const INVALID_INSURANCE_POLICY_DATA = 'INVALID_INSURANCE_POLICY_DATA';
export const THIRD_PARTY_API_FAILURE = 'THIRD_PARTY_API_FAILURE';
export const MISSING_PAYMENT_REFERENCE_NUMBER = 'MISSING_PAYMENT_REFERENCE_NUMBER';
export const SPECIFIC_PAYMENT_TYPE_IS_NOT_SUPPORTED = 'SPECIFIC_PAYMENT_TYPE_IS_NOT_SUPPORTED';
export const SPECIFIC_POLICY_TYPE_IS_NOT_SUPPORTED = 'SPECIFIC_POLICY_TYPE_IS_NOT_SUPPORTED';

export const ERRORS_MAP = [
  {
    key: MAINTENANCE_IN_PROGRESS,
    code: 400,
    title: <FormattedMessage id="errors.maintenance.title" />,
    message: <FormattedMessage id="errors.maintenance.message" />,
  },
  // ##################################################################
  // Third party API error
  {
    key: THIRD_PARTY_API_FAILURE,
    code: 500,
    message: <FormattedMessage id="errors.thirdPartyError" />,
  },
  // ##################################################################
  // Payment Errors
  {
    key: WHILE_LOOKING_FOR_SPECIFIC_PAYMENT_ID,
    code: 400,
    message: <FormattedMessage id="errors.invalidPaymentId" />,
  },
  {
    key: SPECIFIC_PAYMENT_ID_IS_ALREADY_PAID,
    code: 400,
    message: <FormattedMessage id="errors.paymentAlreadyPaid" />,
  },
  // ##################################################################
  {
    key: WHITELIST_REGISTRATION_DISABLED,
    code: 802,
    message: <FormattedMessage id="errors.whitelistRegistrationDisabled" />,
  },
  {
    key: WHITELIST_LOGIN_DISABLED,
    code: 803,
    message: <FormattedMessage id="errors.whitelistLoginDisabled" />,
  },
  {
    key: ACCOUNT_HAS_NO_REQUIRED_ROLE_FOR_SPECIFIC_LOGIN_DOOR,
    code: 401,
    message: <FormattedMessage id="errors.invalidUserRole" />,
  },
  {
    key: INVALID_AUTHENTICATION_TOKEN,
    code: 401,
    message: <FormattedMessage id="errors.invalidAuthToken" />,
  },
  {
    key: UNKNOWN_LOGIN_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.loginFailed" />,
  },
  {
    key: UNKNOWN_REGISTRATION_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.registrationFailed" />,
  },
  {
    key: UNKNOWN_ACCOUNT_ACTIVATION_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.accountActivationFailed" />,
  },
  {
    key: UNKNOWN_RESEND_ACTIVATION_EMAIL_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.resendActivationEmailFailed" />,
  },
  {
    key: UNKNOWN_FORGOT_PASSWORD_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.forgotPasswordFailed" />,
  },
  {
    key: UNKNOWN_PASSWORD_RESET_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.passwordResetFailed" />,
  },
  {
    key: UNKNOWN_PHONE_NUMBER_CHANGE_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.phoneNumberChangeFailed" />,
  },
  {
    key: UNKNOWN_PASSWORD_CHANGE_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.passwordChangeFailed" />,
  },
  {
    key: UNKNOWN_PROFILE_ADVANCED_DATA_UPDATE,
    code: 401,
    message: <FormattedMessage id="errors.profileAdvancedDataUpdateFailed" />,
  },
  {
    key: UNKNOWN_CMS_FEATURED_IMAGE_UPLOAD,
    code: 401,
    message: <FormattedMessage id="errors.cmsFeaturedImageUploadFailed" />,
  },
  {
    key: UNKNOWN_DOCUMENT_UPLOAD_FAIL,
    code: 401,
    message: <FormattedMessage id="errors.documentUploadFailed" />,
  },
  // Invalid CORS policy.
  {
    key: INVALID_CORS_POLICY,
    code: 401,
    message: <FormattedMessage id="errors.invalidCors" />,
  },
  // User data is missing when using provided auth token.
  {
    key: AUTHENTICATION_DATA_IS_MISSING,
    code: 401,
    message: <FormattedMessage id="errors.authDataMissing" />,
  },
  // Token has expired
  {
    key: AUTHENTICATION_TOKEN_HAS_EXPIRED,
    code: 440,
    message: <FormattedMessage id="errors.authTokenExpired" />,
  },
  // Token is valid but unknown user
  {
    key: AUTH_VALID_BUT_USER_DATA_MISSING,
    code: 418,
    message: <FormattedMessage id="errors.userDataMissing" />,
  },
  // User has no roles set
  {
    key: USER_HAS_NO_ROLE,
    code: 401,
    message: <FormattedMessage id="errors.userHasNoRole" />,
  },
  // ##################################################################
  {
    key: RESOLVER_ONLY_FOR_ADMINS,
    code: 401,
    message: <FormattedMessage id="errors.adminsResolver" />,
  },
  {
    key: USER_HAS_NO_ACCESS,
    code: 401,
    message: <FormattedMessage id="errors.userNoAccess" />,
  },
  {
    key: USER_NOT_ACTIVATED,
    code: 401,
    message: <FormattedMessage id="errors.userEmailNotActive" />,
  },
  // ##################################################################
  {
    key: INVALID_CAPTCHA,
    code: 401,
    message: <FormattedMessage id="errors.invalidCaptcha" />,
  },
  {
    key: PASSWORD_IS_INVALID,
    code: 401,
    message: <FormattedMessage id="errors.invalidPassword" />,
  },
  // ##################################################################
  {
    key: WHILE_LOOKING_FOR_SPECIFIC_USER,
    code: 409,
    message: <FormattedMessage id="errors.lookingForUser" />,
  },
  {
    key: NO_SPECIFIC_USER_OR_PASSWORD_COMBINATION,
    code: 410,
    message: <FormattedMessage id="errors.invalidUsernameOrPassword" />,
  },
  {
    key: NO_SPECIFIC_USER_FOUND,
    code: 410,
    message: <FormattedMessage id="errors.userByEmailNotFound" />,
  },
  {
    key: MORE_THAN_ONE_USER_OR_PASSWORD_COMBINATION_FOUND,
    code: 411,
    message: <FormattedMessage id="errors.foundMultipleUsers" />,
  },
  {
    key: ACCOUNT_IS_LOCKED,
    code: 412,
    message: <FormattedMessage id="errors.accountLocked" />,
  },
  {
    key: ACCOUNT_IS_LOCKED_TOO_MANY_FAILED_ATTEMPTS,
    code: 413,
    message: <FormattedMessage id="errors.accountLockedTooManyFails" />,
  },
  {
    key: UPLOAD_FILE_EXCEEDS_MAX_SIZE,
    code: 414,
    message: <FormattedMessage id="errors.uploadFileExceedsMaxSize" />,
  },
  // ##################################################################
  {
    key: RESOLVER_DISABLED,
    code: 800,
    message: <FormattedMessage id="errors.resolverDisabled" />,
  },
  {
    key: USER_ALREADY_ACTIVATED,
    code: 804,
    message: <FormattedMessage id="errors.accountAlreadyActive" />,
  },
  {
    key: NO_RESULTS_FOUND,
    code: 807,
    message: <FormattedMessage id="errors.noResultsFound" />,
  },
  {
    key: INVALID_RESOLVER_SPECIFIC_TOKEN,
    code: 801,
    message: <FormattedMessage id="errors.invalidResolverToken" />,
  },
  {
    key: WHILE_CREATING_NEW_USER,
    code: 801,
    message: <FormattedMessage id="errors.creatingUser" />,
  },
  {
    key: WHILE_DELETING_NEW_USER,
    code: 802,
    message: <FormattedMessage id="errors.deletingUser" />,
  },

  {
    key: DUPLICATE_USER_DETECTED,
    code: 802,
    message: <FormattedMessage id="errors.duplicateUserDetected" />,
  },
  {
    key: DUPLICATE_PHONE_NUMBER_DETECTED,
    code: 802,
    message: <FormattedMessage id="errors.duplicatePhoneNumberDetected" />,
  },
  // ##################################################################
  // CONTRACTING ERRORS
  // ##################################################################
  {
    key: HOK_EXPRESS_CONTRACTOR_TOO_YOUNG,
    code: 802,
    message: <FormattedMessage id="errors.contractorNotOfAge" />,
  },
  {
    key: HOK_EXPRESS_START_DATE_AFTER_FORWARD_MAX,
    code: 802,
    message: <FormattedMessage id="errors.invalidStartDate" />,
  },
  {
    key: HOK_EXPRESS_NOT_SUPPORTED_VEHICLE_TYPE,
    code: 802,
    message: <FormattedMessage id="errors.hokExpressNotSupportedVehicleType" />,
  },
  {
    key: HOK_EXPRESS_START_DATE_BEFORE_CURRENT_DATE,
    code: 802,
    message: <FormattedMessage id="errors.startDateIsPast" />,
  },
  {
    key: HOK_EXPRESS_PERSON_HAS_TO_HAVE_ONE_ACTIVE_AO_AK_POLICY,
    code: 802,
    message: <FormattedMessage id="errors.noDependencyPolicy" />,
  },
  {
    key: HOK_EXPRESS_VEHICLE_TOO_OLD,
    code: 802,
    message: <FormattedMessage id="errors.hokExpressVehicleAge" />,
  },
  {
    key: HOK_EXPRESS_VEHICLE_TOO_HEAVY,
    code: 802,
    message: <FormattedMessage id="errors.vehicleWeight" />,
  },
  {
    key: HOK_PZO_START_DATE_BEFORE_CURRENT_DATE,
    code: 802,
    message: <FormattedMessage id="errors.startDateIsPast" />,
  },
  {
    key: HOK_PZO_DURATION_TOO_SHORT,
    code: 802,
    message: <FormattedMessage id="errors.shortDuration" />,
  },
  {
    key: HOK_PZO_DURATION_TOO_LONG,
    code: 802,
    message: <FormattedMessage id="errors.longDuration" />,
  },
  {
    key: HOK_PZO_INVALID_ACTIVITY,
    code: 802,
    message: <FormattedMessage id="errors.invalidActivity" />,
  },
  {
    key: HOK_PZO_CONTRACTOR_TOO_YOUNG,
    code: 802,
    message: <FormattedMessage id="errors.contractorNotOfAge" />,
  },
  {
    key: HOK_PZO_NO_INSURED_PEOPLE,
    code: 802,
    message: <FormattedMessage id="errors.noInsuredPeople" />,
  },
  {
    key: HOK_PZO_TOO_MANY_INSURED_PEOPLE,
    code: 802,
    message: <FormattedMessage id="errors.tooManyInsuredPeople" />,
  },
  {
    key: HOK_PZO_START_DATE_AFTER_FORWARD_MAX,
    code: 802,
    message: <FormattedMessage id="errors.invalidStartDate" />,
  },
  {
    key: HOK_PZO_CONTRACTOR_CAN_ONLY_BE_FROM_PRIVATE_SECTOR,
    code: 802,
    message: <FormattedMessage id="errors.invalidContractorSector" />,
  },
  {
    key: HOK_EUREKA_START_DATE_BEFORE_CURRENT_DATE,
    code: 802,
    message: <FormattedMessage id="errors.startDateIsPast" />,
  },
  {
    key: HOK_EUREKA_INSURED_PERSON_TOO_OLD,
    code: 802,
    message: <FormattedMessage id="errors.insuredPersonTooOld" />,
  },
  {
    key: HOK_EUREKA_TOO_MANY_INSURED_PEOPLE,
    code: 802,
    message: <FormattedMessage id="errors.tooManyInsuredPeople" />,
  },
  {
    key: HOK_EUREKA_START_DATE_AFTER_FORWARD_MAX,
    code: 802,
    message: <FormattedMessage id="errors.invalidStartDate" />,
  },
  {
    key: HOK_EUREKA_CONTRACTOR_CAN_ONLY_BE_FROM_PRIVATE_SECTOR,
    code: 802,
    message: <FormattedMessage id="errors.invalidContractorSector" />,
  },
  {
    key: HOK_EUREKA_INSURED_PERSON_HAS_INVALID_MBHZZO,
    code: 802,
    message: <FormattedMessage id="errors.invalidMBHZZO" />,
  },
  {
    key: HOK_AUTO_CONTRACTOR_TOO_YOUNG,
    code: 987,
    message: <FormattedMessage id="errors.contractorNotOfAge" />,
  },
  {
    key: HOK_AUTO_VEHICLE_TOO_OLD,
    code: 960,
    message: <FormattedMessage id="errors.vehicleAge" />,
  },
  {
    key: HOK_AUTO_NOT_SUPPORTED_VEHICLE_TYPE,
    code: 961,
    message: <FormattedMessage id="errors.notSupportedVehicleType" />,
  },
  {
    key: HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE,
    code: 962,
    message: <FormattedMessage id="errors.startDateIsPast" />,
  },
  {
    key: HOK_AUTO_START_DATE_BEFORE_BACKWARD_MAX,
    code: 963,
    message: <FormattedHTMLMessage id="errors.startDateIsPastRenewal" />,
  },
  {
    key: HOK_AUTO_START_DATE_AFTER_FORWARD_MAX,
    code: 964,
    message: <FormattedHTMLMessage id="errors.invalidAOStartDate" />,
  },
  {
    key: HOK_AUTO_END_DATE_AFTER_FORWARD_MAX,
    code: 965,
    message: <FormattedMessage id="errors.invalidEndDate" />,
  },
  {
    key: HOK_AUTO_NOT_SUPPORTED_COVER,
    code: 966,
    message: <FormattedMessage id="errors.invalidCover" />,
  },
  {
    key: HOK_AUTO_NOT_SUPPORTED_COVER_SUM,
    code: 967,
    message: <FormattedMessage id="errors.invalidCoverSum" />,
  },
  {
    key: HOK_AUTO_COVER_SUM_MISSING,
    code: 968,
    message: <FormattedMessage id="errors.coverSumMissing" />,
  },
  {
    key: HOK_AUTO_COVER_ADDITIONAL_DATA_MISSING,
    code: 969,
    message: <FormattedMessage id="errors.coverAdditionalDataMissing" />,
  },
  {
    key: HOK_AUTO_NOT_SUPPORTED_COVER_FOR_SPECIFIC_VEHICLE_TYPE,
    code: 970,
    message: <FormattedMessage id="errors.invalidCoverForVehicleType" />,
  },
  {
    key: HOK_AUTO_NOT_SUPPORTED_COVER_COMBINATION,
    code: 971,
    message: <FormattedMessage id="errors.invalidCoverCombination" />,
  },
  {
    key: HOK_AUTO_VEHICLE_TOO_OLD_FOR_SPECIFIC_COVER,
    code: 972,
    message: <FormattedMessage id="errors.vehicleTooOldForCover" />,
  },
  {
    key: HOK_AUTO_START_DATE_AFTER_FORWARD_MAX_DIFFERENT_OWNER,
    code: 972,
    message: <FormattedHTMLMessage id="errors.invalidAOStartDateDifferentOwner" />,
  },
  {
    key: HOK_AUTO_START_DATE_BEFORE_BACKWARD_MAX_DIFFERENT_OWNER,
    code: 972,
    message: <FormattedHTMLMessage id="errors.startDateIsPastRenewalDifferentOwner" />,
  },
  {
    key: HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE_DIFFERENT_OWNER,
    code: 972,
    message: <FormattedMessage id="errors.startDateIsPastDifferentOwner" />,
  },
  {
    key: HOK_AUTO_INVALID_REGISTRATION_PLATE,
    code: 972,
    message: <FormattedMessage id="errors.invalidRegistrationPlate" />,
  },
  {
    key: HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE_NEW,
    code: 972,
    message: <FormattedMessage id="errors.autoStartBeforeCurrentDateNew" />,
  },
  {
    key: HOK_AUTO_START_DATE_AFTER_FORWARD_MAX_NEW,
    code: 972,
    message: <FormattedMessage id="errors.autoStartAfterForwardMaxNew" />,
  },
  {
    key: DUPLICATE_VIN_DETECTED,
    code: 972,
    message: <FormattedMessage id="errors.duplicateVinDetected" />,
  },
  // ##################################################################
  {
    key: MISSING_PAYMENT_REFERENCE_NUMBER,
    code: 907,
    message: <FormattedMessage id="errors.missingPaymentReferenceNumber" />,
  },
  {
    key: SPECIFIC_PAYMENT_TYPE_IS_NOT_SUPPORTED,
    code: 907,
    message: <FormattedMessage id="errors.paymentTypeNotSupported" />,
  },
  {
    key: SPECIFIC_POLICY_TYPE_IS_NOT_SUPPORTED,
    code: 907,
    message: <FormattedMessage id="errors.policyTypeNotSupported" />,
  },
  // ##################################################################
  {
    key: INVALID_OIB_FORMAT,
    code: 711,
    message: <FormattedMessage id="errors.invalidOIBFormat" />,
  },
  {
    key: INVALID_TOKEN_FORMAT,
    code: 718,
    message: <FormattedMessage id="errors.invalidTokenFormat" />,
  },
  {
    key: INVALID_EMAIL_FORMAT,
    code: 718,
    message: <FormattedMessage id="errors.invalidEmailFormat" />,
  },
  {
    key: INVALID_PIN_FORMAT,
    code: 718,
    message: <FormattedMessage id="errors.invalidActivationCodeFormat" />,
  },
  {
    key: INVALID_INPUT_DATE_FORMAT,
    code: 701,
    message: <FormattedMessage id="errors.invalidDateFormat" />,
  },
  {
    key: INVALID_INSURANCE_POLICY_DATA,
    code: 904,
    message: <FormattedMessage id="errors.invalidInsurancePolicyData" />,
  },
  // ##################################################################
  {
    key: CANT_UPDATE_DATA_TO_DATABASE,
    code: 716,
    message: <FormattedMessage id="errors.cantUpdateData" />,
  },
  {
    key: CANT_CREATE_AUDIT_DATA,
    code: 715,
    message: <FormattedMessage id="errors.cantCreateAuditData" />,
  },
  {
    key: INVALID_PHONE_NUMBER_FORMAT,
    code: 720,
    message: <FormattedMessage id="errors.invalidPhoneNumberFormat" />,
  },
  {
    key: INVALID_APPLICATION_ID_OR_INVALID_TOKEN,
    code: 730,
    message: <FormattedMessage id="errors.invalidAppOrToken" />,
  },
  {
    key: APPLICATION_REFERENCE_PROBLEM,
    code: 731,
    message: <FormattedMessage id="errors.applicationReference" />,
  },
  {
    key: INVALID_ACTIVATION_PIN,
    code: 732,
    message: <FormattedMessage id="errors.invalidActivationCode" />,
  },
  {
    key: DUPLICATE_EMAIL_DETECTED,
    code: 777,
    message: <FormattedMessage id="errors.duplicateEmail" />,
  },
  // ##################################################################
  {
    key: RESOLVER_COOLDOWN_IS_ACTIVE,
    code: 901,
    message: <FormattedMessage id="errors.resolverOnCooldown" />,
  },
  // ##################################################################
  // Any error not defined in ERRORS object.
  {
    key: UNKNOWN_ERROR,
    code: 999,
    message: <FormattedMessage id="errors.unknownError" />,
  },
  {
    key: FAILED_TO_FETCH,
    code: 999,
    message: <FormattedMessage id="errors.failedToFetch" />,
  },
];

export const getErrorObject = e => {
  let gqErrors = get(e, 'graphQLErrors', []);
  const message = get(e, 'message', '');

  if (message.toLowerCase().includes('network error') && gqErrors.length === 0) {
    gqErrors.push(ERRORS_MAP.find(error => error.key === FAILED_TO_FETCH));
  }

  const reveivedError = ERRORS_MAP.find(error => {
    return gqErrors.find(element => {
      return get(element, 'key', '') === error.key ? error : null;
    });
  });

  let errorToReturn = {};
  if (reveivedError) {
    errorToReturn = reveivedError;
  } else {
    errorToReturn = ERRORS_MAP.find(error => {
      return error.key === UNKNOWN_ERROR ? error : null;
    });
  }
  return errorToReturn;
};

export const isMaintenanceInProgress = error => {
  return error.key === MAINTENANCE_IN_PROGRESS;
};

export const isAuthTokenInvalid = error => {
  return [
    INVALID_AUTHENTICATION_TOKEN,
    AUTHENTICATION_TOKEN_HAS_EXPIRED,
    AUTH_VALID_BUT_USER_DATA_MISSING,
    AUTHENTICATION_DATA_IS_MISSING,
  ].includes(error.key);
};

export const getSpecificError = key => {
  return ERRORS_MAP.find(error => error.key === key) || UNKNOWN_ERROR;
};

export const handleRequestError = (error, dispatch, config = { silent: false }, cb) => {
  const errorObject = getErrorObject(error);
  if (isAuthTokenInvalid(errorObject)) {
    if (isAdminLoggedIn() && /\/admin\//.test(window.location.href)) {
      dispatch(adminLoginFailure({ errorMessage: errorObject.message }));
    } else {
      dispatch(userLoginFail({ errorMessage: errorObject.message }));
    }
  } else if (isMaintenanceInProgress(errorObject)) {
    dispatch(generalError({ ...errorObject }));
  } else if (errorObject.key === USER_NOT_ACTIVATED) {
    dispatch(userAccountEmailDeactivated());
  } else {
    if (!config.silent) {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
    if (typeof cb === 'function') {
      cb();
    }
  }
};
