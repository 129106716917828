import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash-es';
import { useMutation } from 'react-apollo';
import { STEP_3, DOC_TYPE_ALL } from '../../../constants/business';
import { CustomButton } from '../../../containers';
import { ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3 } from '../../../constants/routes';
import { TermsAndConditionsCheckbox } from '../../../components/TermsAndConditionsCheckbox';
import { PolicyContractingPersonInfo } from '../../../components/PolicyContractingPersonInfo';
import { validateFormSubmission } from '../../../utils/validation';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { useProductDocumentsQuery } from '../../../utils/customHooks';
import { displayCurrencyOptions } from '../../../utils/currencyDisplayFormat';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import {  useSelector } from 'react-redux';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';

export const Step2Travel = ({ data, user }) => {
  const history = useHistory();
  const { formatNumber } = useIntl();

  const { allTypeDocuments } = useProductDocumentsQuery({ docType: DOC_TYPE_ALL, productCode: data.type });

  const [submitForm, { loading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data => history.push(`${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3}/${data.serverStorage.stateKey}`),
  });

  const [tac, setTac] = useState({
    value: get(data, 'acceptedTerms', false),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.tac" />,
    validation: {
      isTrue: true,
    },
  });

  const tacAcceptedFn = useCallback(value => setTac(tac => ({ ...tac, value })), []);
  const tacInvalidFn = useCallback(invalid => setTac(tac => ({ ...tac, invalid })), []);

  const tacLabelRender = useCallback(
    toggleTacModal => (
      <div className="tac-text-container">
        <span>
          Potvrđujem kako je ovaj proizvod u skladu s mojim zahtjevima i potrebama te da sam temeljem
          <span onClick={toggleTacModal} className="terms-and-conditions-text">
            predugovornih dokumenata i uvjeta osiguranja
          </span>{' '}
          donio informiranu odluku o kupnji proizvoda.
        </span>
      </div>
    ),
    []
  );

  const travelStep2SubmitHandler = async event => {
    event.preventDefault();

    const fieldsArray = [setTac];

    if (await validateFormSubmission(fieldsArray)) {
      submitForm({
        variables: {
          stateKey: data.stateKey,
          state: {
            ...data,
            step: STEP_3,
            insuredPeople: [data.insuredPeople[0], ...data.insuredPeople.slice(1)],
            acceptedTerms: tac.value,
          },
        },
      });
    }
  };

  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  return (
    <div className="step2">
      <Row className="mt-4">
        <Col xs="12">
          <PolicyContractingPersonInfo user={user} labelId="userMyPolicies.policyData.policyContractor" />
          <TermsAndConditionsCheckbox
            setTacAccepted={tacAcceptedFn}
            tacAccepted={tac.value}
            tacInvalid={tac.invalid}
            setTacInvalid={tacInvalidFn}
            productDocs={allTypeDocuments}
          >
            {tacLabelRender}
          </TermsAndConditionsCheckbox>
          <Row className="no-gutters mt-4">
            <Col xs="12">
              <span className="text-nowrap">
                <span className="primary">
                  <FormattedMessage id="userMyPolicies.policyData.premium" />:
                </span>

                {currencyDataConfig.dualCurrencyDisplay === true && (
                <>
                <span className="secondary ml-3">
                  {formatNumber(data.totalPremium, displayCurrencyOptions)}
                  {' '}|{' '}
                  {currencyConversionEuro(data.totalPremium || 0)}
                </span>
                </>)}

                {currencyDataConfig.dualCurrencyDisplay === false && (
                <>
                <span className="secondary ml-3">
                  {formatNumber(data.totalPremium, displayCurrencyOptions)}
                </span>
                </>
                )}

                {/* <span className="secondary ml-3">
                  {formatNumber(data.totalPremium, displayCurrencyOptions)} |{' '}
                  {currencyConversionEuro(data.totalPremium || 0)}
                </span> */}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <CustomButton
            className="hok-dashboard-btn-wide float-sm-right"
            translationId="goToPayment"
            onClick={travelStep2SubmitHandler}
            loaderProp={loading}
          />
        </Col>
      </Row>
    </div>
  );
};
