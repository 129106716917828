import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { TabsNavigation } from '../../containers/TabsNavigation';
import { TicketInfo } from './TicketInfo';
import { ArrowLinkButton } from '../../containers';
import { TicketDocumentation } from './TicketDocumentation';

export const SingleTicketData = ({ ticketId, ticket, baseRoute, backRoute }) => {
  const dispatch = useDispatch();
  return (
    <Container fluid>
      <ArrowLinkButton linkTo={backRoute} xlColSize={10} />
      <Row className="my-4">
        <Col xs="12" xl={{ offset: 1, size: 10 }}>
          <h3 className="mb-0">
            <span className="mr-2">{`Zahtjev #${ticket.number}: `}</span>
            <span className="ticket-title">{ticket.subject}</span>
          </h3>
        </Col>
      </Row>
      <TabsNavigation
        baseRoute={baseRoute}
        itemId={ticketId}
        styleBreakpoint="sm"
        navTabs={[
          { name: 'userMyTickets.tabs.ticketInfo', route: '/' },
          { name: 'userMyTickets.tabs.ticketDocumentation', route: '/documentation' },
        ]}>
        <Switch>
          <Route exact path={`${baseRoute}/${ticketId}`}>
            <TicketInfo ticket={ticket} ticketId={ticketId} dispatch={dispatch} />
          </Route>
          <Route exact path={`${baseRoute}/${ticketId}/documentation`}>
            <TicketDocumentation ticket={ticket} />
          </Route>
        </Switch>
      </TabsNavigation>
    </Container>
  );
};
