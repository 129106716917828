import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from '../../containers/CustomButton';

import keyboardUpIcon from '../../img/icons/hok-chevron-up.svg';
import keyboardDownIcon from '../../img/icons/hok-chevron-down.svg';
import keyboardRightIcon from '../../img/icons/hok-chevron-right.svg';
import { useIntl } from 'react-intl';
import { displayCurrencyOptions } from '../../utils/currencyDisplayFormat';

const CollapsiblePackageInfo = ({
  placeholder = 'locale',
  raw = false,
  children,
  expanded = false,
  submitFn,
  showButton = true,
  showPrice = false,
  priceString = 'price',
  price = '0,00 kn',
  hasBody = true,
  showArrow = true,
  assistance = false,
  buttonTranslationId = 'select',
  onClick,
  disableButton = false,
  titleUppercase = false,
}) => {
  const [isOpen, setOpen] = useState(expanded);
  const [exited, setExited] = useState(!expanded);
  const toggle = () => {
    if (exited) {
      setExited(false);
    }
    setOpen(!isOpen);
  };
  const onExited = () => {
    setExited(true);
  };

  const { formatNumber } = useIntl();

  return (
    <div className="collapsible-package-container">
      <Card className={!exited ? 'w-100' : 'card-collapsed w-100'}>
        <CardHeader className="p-0" onClick={typeof onClick === 'function' ? onClick : toggle}>
          <div className={`cursor-pointer d-flex ${assistance ? 'body-text-70' : 'body-text'}`}>
            <h3 className={'align-self-center flex-grow-1 text-center' + (titleUppercase ? ' uppercase' : '')}>
              {raw ? placeholder : <FormattedMessage id={placeholder} />}
            </h3>
            {showArrow && (
              <div>
                <img
                  alt="collapse-toggler"
                  src={hasBody ? (exited ? keyboardDownIcon : keyboardUpIcon) : keyboardRightIcon}
                />
              </div>
            )}
          </div>
        </CardHeader>
        {hasBody && (
          <Collapse className="collapsible position-relative hok-package-collapse" isOpen={isOpen} onExited={onExited}>
            <CardBody>
              {children}
              {showPrice && (
                <div className="price-container px-4">
                  <span className={'text-left' + (priceString !== 'price' ? ' color-red' : '')}>
                    <FormattedMessage id={priceString} />:
                  </span>
                  <span className="text-right">
                    {formatNumber(price, {style: 'currency',
                      currency: 'EUR',
                      currencySign: 'standard',
                      currencyDisplay: 'narrowSymbol'})}
                    <br />
                    {/* {formatNumber(price*7.5345, {style: 'currency',
                      currency: 'HRK',
                      currencySign: 'standard',
                      currencyDisplay: 'narrowSymbol'})} */}
                  </span>
                </div>
              )}
              {showButton && (
                <div className="submit-container">
                  <CustomButton
                    className="hok-dashboard-btn"
                    disabled={disableButton}
                    translationId={buttonTranslationId}
                    onClick={submitFn}
                  />
                </div>
              )}
            </CardBody>
          </Collapse>
        )}
      </Card>
    </div>
  );
};

export default CollapsiblePackageInfo;
