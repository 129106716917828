import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Label, InputGroup, FormFeedback } from 'reactstrap';
import { useMutation, useLazyQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { CustomButtonArrowRight, CustomButton } from '../../../containers';
import { HEALTH_INSURANCE_PACKAGES, POLICY_CODE_EU } from '../../../constants/business';
import {
  removeSessionStorageHealthInsurancePackage,
  getSessionStorageHealthInsurancePackage,
  setSessionStorageHealthInsurancePackage,
  // getSessionStorageRenewalPolicyNumber,
  // removeSessionStorageRenewalPolicyNumber,
} from '../../../constants/LocalStorageKeys';
import { ModalWithCardSelection } from '../../../components/ModalWithCardSelection';
import { validateFormSubmission } from '../../../utils/validation';
import { ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_2 } from '../../../constants/routes';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { GetEurekaPriceQuery } from '../../../operations/queries/GetPriceQuery';
import { usePrevious } from '../../../utils/customHooks';
import { handleRequestError } from '../../../constants/errorCodes';
import { displayTotalPremium } from '../../../utils/currencyDisplayFormat';
import { currencyConversionEuro } from '../../../utils/currencyConversion';

export const Step1Health = ({ data = {}, user }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [submitForm, { loading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data => history.push(`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_2}/${data.serverStorage.stateKey}`),
    onError: error => handleRequestError(error, dispatch),
  });

  const [getEurekaPrice, { data: priceData, loading: priceLoading }] = useLazyQuery(GetEurekaPriceQuery, {
    onError: error => handleRequestError(error, dispatch),
  });

  const [insurancePackage, setInsurancePackage] = useState({
    value:
      (HEALTH_INSURANCE_PACKAGES.some(healthPackage => healthPackage.id === get(data, 'productPackage', null)) &&
        get(data, 'productPackage', null)) ||
      getSessionStorageHealthInsurancePackage() ||
      null,
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.insurancePackage" />,
    validation: {
      required: true,
      enum: HEALTH_INSURANCE_PACKAGES.map(healthPackage => healthPackage.id),
    },
  });

  // const [policyNumber, setPolicyNumber] = useState({
  //   value: get(data, 'renewalPolicyNumber', '') || getSessionStorageRenewalPolicyNumber(POLICY_CODE_EU) || '',
  //   error: null,
  //   invalid: false,
  //   focused: false,
  //   displayName: <FormattedMessage id="inputs.policyNumber" />,
  //   validation: {
  //     required: true,
  //   },
  // });

  const previousPackage = usePrevious(insurancePackage.value);

  const [isPackagesModalOpen, setIsPackagesModalOpen] = useState(false);

  const togglePackagesModal = () => {
    setIsPackagesModalOpen(!isPackagesModalOpen);
  };

  useEffect(() => {
    return () => {
      removeSessionStorageHealthInsurancePackage();
      // removeSessionStorageRenewalPolicyNumber(POLICY_CODE_EU);
    };
  }, []);

  useEffect(() => {
    if (insurancePackage.value && previousPackage !== insurancePackage.value) {
      getEurekaPrice({
        variables: {
          productRelatedData: {
            productPackage: insurancePackage.value,
            insuranceStartDate: new Date().toISOString(),
            insuredPeople: [
              {
                name: user.firstName,
                surename: user.lastName,
                dateOfBirth: user.dateOfBirth,
                address: user.address,
                zip: user.zip,
                city: user.city,
                mbHzzo: user.HZZONumber || '123456789',
                sex: user.sex,
                oib: user.oib,
              },
            ],
          },
        },
      });
    }
  }, [data, getEurekaPrice, user, insurancePackage.value, previousPackage]);

  const handleStep1Submit = async event => {
    event.preventDefault();

    const fieldsArray = [setInsurancePackage];

    if (
      (await validateFormSubmission(fieldsArray)) &&
      get(priceData, 'calculatePriceHokEureka.eligibleForProduct', false)
    ) {
      const insuredPeople =
        get(data, 'insuredPeople', []).length > 0
          ? data.insuredPeople
          : [
              {
                name: user.firstName,
                surename: user.lastName,
                dateOfBirth: user.dateOfBirth,
                address: user.address,
                zip: user.zip,
                city: user.city,
                mbHzzo: user.HZZONumber || '',
                sex: user.sex,
                oib: user.oib,
              },
            ];

      submitForm({
        variables: {
          attachToUser: true,
          state: {
            ...data,
            type: POLICY_CODE_EU,
            step: 'STEP_2',
            productPackage: insurancePackage.value,
            insuredPeople,
            totalPremium: get(priceData, 'calculatePriceHokEureka.totalPremium', 0),
          },
        },
      });
    }
  };

  // const inputHandler = (setter, value) => {
  //   setter(o => ({ ...o, value, invalid: false, error: null }));
  // };

  return (
    <>
      {/* <Row className="mt-4 mb-3">
        <Col xs="12">
          <Label for="policyNumber">
            <FormattedMessage id="inputs.oldPolicy" />
          </Label>
        </Col>
        <Col sm="6" md="4">
          <InputGroup>
            <CustomDashboardInput
              fieldState={policyNumber}
              onChange={event => inputHandler(setPolicyNumber, event.target.value)}
            />
          </InputGroup>
        </Col>
      </Row> */}
      <Form onSubmit={handleStep1Submit}>
        <Row className="mt-4">
          <Col sm="6" md="4">
            <Label for="insurancePackage">
              <FormattedMessage id="userMyPolicies.policyData.insurancePackage" />
            </Label>
            <InputGroup>
              <CustomButtonArrowRight
                id="insurancePackage"
                invalid={insurancePackage.invalid}
                placeholder={
                  insurancePackage.value ? `userMyPoliciesHealthInsurance.${insurancePackage.value}` : 'notSelected'
                }
                className="custom-header-text"
                onClick={togglePackagesModal}
              />
              {insurancePackage.invalid && insurancePackage.error && (
                <FormFeedback className="d-block">{insurancePackage.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <span className="primary">
              <FormattedMessage id="userMyPolicies.policyData.premium" />:
            </span>
            <span className="secondary ml-3">
              {displayTotalPremium(
                get(priceData, 'calculatePriceHokEureka.totalPremium', undefined),
                get(data, 'totalPremium', undefined)
              )}{' '}
              |{' '}
              {currencyConversionEuro(
                get(priceData, 'calculatePriceHokEureka.totalPremium', 0),
                get(data, 'totalPremium', 0)
              )}
            </span>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CustomButton
              className="hok-dashboard-btn-wide float-sm-right"
              translationId="continue"
              disabled={!get(priceData, 'calculatePriceHokEureka.eligibleForProduct', false) || loading || priceLoading}
              loaderProp={loading || priceLoading}
            />
          </Col>
        </Row>
      </Form>
      <ModalWithCardSelection
        cardsList={HEALTH_INSURANCE_PACKAGES}
        setFunction={cardId => {
          setInsurancePackage(o => ({ ...o, value: cardId, invalid: false, error: null }));
          setSessionStorageHealthInsurancePackage(cardId);
          togglePackagesModal();
        }}
        toggle={togglePackagesModal}
        isOpen={isPackagesModalOpen}
      />
    </>
  );
};
