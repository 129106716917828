import React from 'react';
import maestro from '../../img/payment/maestro.png';
import masterCard from '../../img/payment/masterCard.png';
import mcIdcheck from '../../img/payment/mc_idcheck.png';
import visaSecure from '../../img/payment/visa-secure.png';
import visa from '../../img/payment/visa.png';
import wsPay from '../../img/payment/wsPayWebSecureLogo.png';
import diners from '../../img/payment/diners.png';
import kekspay from '../../img/payment/kekspay.png';
import aircash from '../../img/payment/logo-aircash.png';

export const WSPayCards = () => {
  return (
    <div className="hok-payment-cards">
      <div>
        <a
          href="http://www.wspay.info"
          title="WSpay - Web Secure Payment Gateway"
          target="_blank"
          rel="noopener noreferrer">
          <img src={wsPay} alt="WSpay - Web Secure Payment Gateway" />
        </a>
        <img src={mcIdcheck} alt="mc_idcheck" />
        <img src={visaSecure} alt="visa-secure" />
      </div>
      <div>
        {/* <img src={amex} alt="amex" /> */}
        <img src={masterCard} alt="masterCard" />
        <img src={maestro} alt="maestro" />
        <img src={visa} alt="visa" />
        <img src={diners} alt="diners" />
        <img src={kekspay} alt="kekspay" />
        <img src={aircash} alt="aircash" />
      </div>
    </div>
  );
};
