import React from 'react';
import { MY_POLICIES_ASSISTANCE_PAGE } from '../../../constants/business';
import { EndStep } from '../../../components/PolicyContractingSteps/EndStep';

export const Step4Express = () => {
  return (
    <EndStep
      policyTranslationBase="userMyPoliciesExpressInsurance"
      policyPage={MY_POLICIES_ASSISTANCE_PAGE}
      messageId="congratsMessage"
    />
  );
};
