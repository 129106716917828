import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { FormattedMessage, /* useIntl */ } from 'react-intl';
import { ReactComponent as HokCloseIcon } from '../../img/icons/hok-close.svg';
import { DescriptionText, CollapsiblePackageInfo } from '../../containers';
import { POLICY_COVER_NO_INCIDENT, NO_RISK_PACKAGE } from '../../constants/business';

export const ModalWithCardSelection = React.memo(
  ({ toggle, isOpen, setFunction, cardsList, showPrice, rawCardNameFn, disableButtonFn }) => {
    // const { formatNumber } = useIntl();

    const isCardNameRaw = card => {
      if (typeof rawCardNameFn === 'function') {
        return rawCardNameFn(card);
      }
      return false;
    };

    const disableButton = card => {
      if (typeof disableButtonFn === 'function') {
        return disableButtonFn(card);
      }
      return false;
    };

    return (
      <Modal className="hok-modal-wide hok-modal-policy" isOpen={isOpen} toggle={toggle}>
        <div
          className="d-flex align-items-center justify-content-between pr-3"
          style={{ borderBottom: '1px solid #707070' }}>
          <ModalHeader className="text-center">
            <FormattedMessage id="userMyPolicies.policyData.insurancePackages" />
          </ModalHeader>
          <div>
            <HokCloseIcon className="svg-table-icons svg-red" onClick={toggle} />
          </div>
        </div>
        <ModalBody>
          <Row>
            {cardsList.map(card => (
              <Col lg="6" className="mb-4" key={card.id}>
                <CollapsiblePackageInfo
                  hasBody={card.id === POLICY_COVER_NO_INCIDENT || card.id === NO_RISK_PACKAGE ? false : true}
                  onClick={
                    card.id === POLICY_COVER_NO_INCIDENT || card.id === NO_RISK_PACKAGE
                      ? () => setFunction(card.id)
                      : undefined
                  }
                  disableButton={disableButton(card)}
                  raw={isCardNameRaw(card)}
                  placeholder={isCardNameRaw(card) ? card.name.toUpperCase() : card.name}
                  showPrice={showPrice}
                  price={(card.price)}
                  submitFn={() => setFunction(card.id)}>
                  <div className="p-4">
                    <DescriptionText value={card.id} data={card} />
                  </div>
                </CollapsiblePackageInfo>
              </Col>
            ))}
          </Row>
        </ModalBody>
      </Modal>
    );
  }
);
