export const LOAD_SUPPORT_DATA = 'LOAD_SUPPORT_DATA';
export const loadSupportData = () => ({
  type: LOAD_SUPPORT_DATA,
});

export const STORE_SUPPORT_DATA = 'STORE_SUPPORT_DATA';
export const storeSupportData = payload => ({
  type: STORE_SUPPORT_DATA,
  payload,
});
