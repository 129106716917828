import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ArrowLinkButton, ScreenTitleContainer, PolicyCard } from '../../../containers';
import { ROUTE_TO_USER_POLICIES_PAGE, ROUTE_TO_USER_POLICIES_EXPRESS_ASSISTANCE_PAGE } from '../../../constants/routes';
import { ReactComponent as HokExpressIcon } from '../../../img/products/hok-express.svg';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserPoliciesAssistance = () => {
  const policyList = {
    cards: [
      {
        id: 2,
        cardName: 'express',
        Pic: HokExpressIcon,
        counter: 3,
        route: ROUTE_TO_USER_POLICIES_EXPRESS_ASSISTANCE_PAGE,
      },
    ],
  };

  const renderPolicyCards = () => {
    const cards = policyList.cards.map(card => {
      const { id, Pic, cardName, text, route } = card;
      return <PolicyCard route={route} key={id} text={text} Pic={Pic} cardName={cardName} />;
    });
    return cards;
  };

  return (
    <Container className="hok-container" fluid>
      <Row className="mt-4">
        <Col className="page-container" xs={12}>
          <ArrowLinkButton className="mb-4" linkTo={ROUTE_TO_USER_POLICIES_PAGE} />
          <ScreenTitleContainer row titleId="userAssistancePage.title" />
          <Row className="d-flex cards-container no-gutters mt-5">{renderPolicyCards()}</Row>
        </Col>
      </Row>
      <AdFormIFrame title="Proizvodi - Asistencija" />
    </Container>
  );
};
