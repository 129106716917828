import gql from 'graphql-tag';

export const RegisterUserMutation = gql`
  mutation RegisterUser($email: String!, $password: String!, $phoneNumber: String!) {
    registration(email: $email, password: $password, phoneNumber: $phoneNumber) {
      id
      token
    }
  }
`;
