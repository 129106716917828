import React from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export const ScreenTitleContainer = ({
  titleId,
  raw = false,
  maxWidthSet,
  className = '',
  childClassName = '',
  xlColSize = 12,
  xlColOffset = 0,
  row = false,
}) => {
  const titleAlignHelper = () => (
    <div className={childClassName ? childClassName : 'mx-auto title-text'}>
      <h3 className="mb-0">{raw ? titleId : <FormattedMessage id={titleId} />}</h3>
    </div>
  );

  const titleCol = () =>
    maxWidthSet ? titleAlignHelper() : <h3 className="mb-0">{raw ? titleId : <FormattedMessage id={titleId} />}</h3>;

  const wrapTitleInRow = () => (
    <Row className={'no-gutters mb-5' + (className ? ' ' + className : '')}>
      <Col xl={{ offset: xlColOffset, size: xlColSize }}>{titleCol()}</Col>
    </Row>
  );

  return row ? wrapTitleInRow() : titleCol();
};
