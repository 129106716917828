import accounting from 'accounting';

export const EURO_RATE = 7.5345;

function stringCurrencyToNumber(amount) {
  if (typeof amount === 'string') {
    amount = amount.replace('Kn', '');
    amount = amount.replace('kn', '');
    amount = amount.replace(',', '');
    amount = amount.replace('.', '');
    amount = Number(amount);
    return amount;
  }

  return amount;
}

export const currencyConversionEuro = (amountQuery = 0, amountServer = 0, rate = EURO_RATE, divider = null) => {
  let amount;

  amount = amountQuery !== undefined || amountQuery !== null ? amountQuery : amountServer;

  amount = amount.toString();
/*   let containsDecimal = amount.includes(".");
  if(containsDecimal === false){
    //amount = amount + ".00"
  } */

  if (!amount) {
    return `0,00 kn`;
  }

  /*   if (!divider) {
    divider = 10;

    if ((amount*10) % 1 === 0){
      divider = 10
    }

    if (amount === '100') {
      divider = 1;
    } else if (amount.length < 5 && !amount.includes('.') && !amount.includes(',') && !amount.endsWith('00')) {
      divider = 1;
    }
  } */

  // console.log(amount.toString().split('.')[1].length);
  if(amount.includes('.')){
    if(amount.toString().split('.')[1].length < 2){
      amount = amount + '0';
    }
  }

  amount = stringCurrencyToNumber(amount);

  amount = accounting.formatMoney((amount * rate) / 100, 'kn', 2, '.', ',').replace(/[^0-9.,-]/g, '');

  return `${amount} kn`;
};


export const currencyConversionHrk = (amountQuery = 0, amountServer = 0, rate = EURO_RATE, divider = null) => {
  let amount;

  amount = amountQuery !== undefined || amountQuery !== null ? amountQuery : amountServer;

  if (!amount) {
    return `0,00 €`;
  }

  if (!divider) {
    divider = 100;

    if ((amount*10) % 1 === 0){
      divider = 10
    }

    if (amount === '100') {
      divider = 1;
    } else if (amount.length < 5 && !amount.includes('.') && !amount.includes(',') && !amount.endsWith('00')) {
      divider = 1;
    }
  }

  amount = stringCurrencyToNumber(amount);
  amount = accounting.formatMoney(amount * rate , 'HRK', 2, '.', ',').replace(/[^0-9.,-]/g, '');

  return `${amount} kn`;
};