import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, FormGroup, Label, CustomInput, InputGroup, FormFeedback, Input } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useLazyQuery } from 'react-apollo';
import { SingleDatePicker } from 'react-dates';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';
import { get, debounce } from 'lodash-es';
import { CustomDashboardInput, CustomButton, CollapsibleContainer, Spinner } from '../../../../containers';
import { PolicyContractingPersonInfo } from '../../../../components/PolicyContractingPersonInfo';
import {
  USER_ACCOUNT_TYPES,
  USER_ACCOUNT_BUSINESS,
  USER_GENDER,
  USER_ACCOUNT_PRIVATE,
} from '../../../../constants/userDefs';
import { yearListRender, FORMAT_STANDARD_DATE } from '../../../../constants/dateFormatting';
import { validateFormSubmission } from '../../../../utils/validation';
import { ROUTE_TO_USER_REPORT_DAMAGE } from '../../../../constants/routes';
import { usePlacesQuery, useLegalDocumentsQuery, useGoogleAnalyticsPageview } from '../../../../utils/customHooks';
import { damageClaimGroup, CLAIM_CAR_LIABILITY } from '../../../../constants/claimDefs';
import { SetServerStorageMutation } from '../../../../operations/mutations/SetServerStorageMutation';
import { handleRequestError } from '../../../../constants/errorCodes';
import { CheckPolicyValidityQuery } from '../../../../operations/queries/CheckPolicyValidityQuery';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';
import { INFORMACIJE_O_OBRADI_PODATAKA } from '../../../../constants/documentTypes';
import { v4 } from 'uuid';

export const ReportDamageStep1 = ({ data = {}, damageClaimType, user }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [groupName, pageName, gaPageviewName] = damageClaimGroup(damageClaimType);

  useGoogleAnalyticsPageview(`${gaPageviewName}_Step1`);

  const { places, loading: loadingPlaces } = usePlacesQuery();
  const { legalDocuments } = useLegalDocumentsQuery(INFORMACIJE_O_OBRADI_PODATAKA);

  const [submitForm, { loading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data =>
      history.push(`${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step2/${data.serverStorage.stateKey}`, {
        ...history.location.state,
      }),
    onError: error => handleRequestError(error, dispatch),
  });

  const [reportingPolicyNumberStatus, setReportingPolicyNumberStatus] = useState({
    invalid: false,
    error: '',
    valid: false,
  });

  const [reportingPolicyOwnerOibStatus, setReportingPolicyOwnerOibStatus] = useState({
    invalid: false,
    error: '',
    valid: false,
  });

  const [getPolicyValidity, { loading: checkLoading, data: policyData }] = useLazyQuery(CheckPolicyValidityQuery, {
    onCompleted: data => {
      const policyData = get(data, 'checkIfInsurancePolicyIsValid.productDefinition', null);
      const isOibValid = get(data, 'checkIfInsurancePolicyIsValid.isOibValid', false);
      if (!policyData || !get(policyData, 'active', false) || get(policyData, 'group', '') !== groupName) {
        setReportingPolicyNumberStatus({
          invalid: true,
          error: <FormattedMessage id="errors.invalidPolicyNumber" />,
          valid: false,
        });
      } else {
        reportingPolicyNumber.current.invalid = false;
        reportingPolicyNumber.current.error = '';
        setReportingPolicyNumberStatus({
          invalid: false,
          error: '',
          valid: true,
        });

        if (reportingPolicyOwnerOib.current.value && !isOibValid) {
          setReportingPolicyOwnerOibStatus({
            invalid: true,
            error: <FormattedMessage id="errors.invalidOibNoMatch" />,
            valid: false,
          });
        }

        if (isOibValid) {
          setReportingPolicyOwnerOibStatus({
            invalid: false,
            error: '',
            valid: true,
          });
        }
      }
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    fetchPolicy: 'network-only',
  });

  const insuredPersonData = get(policyData, 'checkIfInsurancePolicyIsValid.insurancePolicyUserDataViaOib', null);
  const isOibValid = get(policyData, 'checkIfInsurancePolicyIsValid.isOibValid', false);
  const claimClass = get(policyData, 'checkIfInsurancePolicyIsValid.productDefinition.claimBook', '');
  const productDefinition = get(policyData, 'checkIfInsurancePolicyIsValid.productDefinition', null);

  const debouncedPolicyValidityCheck = debounce(variables => getPolicyValidity(variables), 750);
  const debouncedOIBValidityCheck = debounce(async variables => {
    if (await validateFormSubmission([setReportingPolicyOwnerOib])) {
      if (reportingPolicyNumberStatus.valid || (!policyData && reportingPolicyNumber.current.value)) {
        getPolicyValidity(variables);
      }
    } else {
      setReportingPolicyOwnerOibStatus({
        invalid: reportingPolicyOwnerOib.current.invalid,
        error: reportingPolicyOwnerOib.current.error,
        valid: false,
      });
    }
  }, 750);

  const [policyNumber, setPolicyNumber] = useState({
    value:
      get(data, 'insured.insurancePolicyNumberAO', '') || get(history, 'location.state.insurancePolicyNumberAO', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.damagedPolicyNumber" />,
    validation: {},
  });

  const [perilExist, setPerilExist] = useState({
    value: groupName === CLAIM_CAR_LIABILITY ? true : get(data, 'perilExist', false),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.perilExist" />,
    validation: {
      required: true,
      isTrue: groupName === CLAIM_CAR_LIABILITY,
    },
  });

  const reportingPolicyNumber = useRef({
    value: get(data, 'insurancePolicyNumber', '') || get(history, 'location.state.reportingPolicyNumber', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.policyNumber" />,
    validation: {
      required: true,
    },
  });
  const setReportingPolicyNumber = cb => {
    reportingPolicyNumber.current = cb(reportingPolicyNumber.current);
  };

  const [oib, setOIB] = useState({
    value: get(data, 'peril.oib', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: groupName !== CLAIM_CAR_LIABILITY,
      oib: true,
    },
  });

  const [tac, setTac] = useState({
    value: get(data, 'tac', false),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.tac" />,
    validation: {
      isTrue: true,
    },
  });
  const reportingPolicyOwnerOib = useRef({
    value: get(data, 'insurancePolicyOib', '') || get(history, 'location.state.reportingPolicyOwnerOib', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.reportingPolicyOwnerOib" />,
    validation: {
      required: true,
      oib: true,
    },
  });
  const setReportingPolicyOwnerOib = cb => {
    reportingPolicyOwnerOib.current = cb(reportingPolicyOwnerOib.current);
  };

  const [firstName, setFirstName] = useState({
    value: get(data, 'peril.firstName', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {
      required: true,
    },
  });
  const [lastName, setLastName] = useState({
    value: get(data, 'peril.lastName', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {
      required: true,
    },
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value: get(data, 'peril.dateOfBirth', null) ? moment(get(data, 'peril.dateOfBirth', null)) : null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.dateOfBirth" />,
    validation: {
      required: true,
    },
  });
  const [sex, setSex] = useState({
    value: get(data, 'peril.sex', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sex" />,
    validation: {
      required: true,
      enum: USER_GENDER.map(gender => gender.value),
    },
  });
  const [address, setAddress] = useState({
    value: get(data, 'peril.address', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });
  const [zip, setZip] = useState({
    value: get(data, 'peril.zip', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
      enum: places.map(place => place.pttNumber),
    },
  });
  const [city, setCity] = useState({
    value: get(data, 'peril.city', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [email, setEmail] = useState({
    value: get(data, 'peril.email', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.email" />,
    validation: {
      required: true,
      email: true,
    },
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: get(data, 'peril.phoneNumber', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {
      required: true,
    },
  });
  const [sector, setSector] = useState({
    value: get(data, 'peril.sector', USER_ACCOUNT_PRIVATE),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sector" />,
    validation: {
      required: true,
      enum: USER_ACCOUNT_TYPES.map(type => type.name),
    },
  });
  const [IBAN, setIBAN] = useState({
    value: get(data, 'reporterIBAN', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.IBAN" />,
    validation: {},
  });
  const [businessName, setBusinessName] = useState({
    value: get(data, 'peril.businessName', ''),
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.name" />,
    validation: {
      required: true,
    },
  });

  const [note, setNote] = useState({
    value: get(data, 'note', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.note" />,
    validation: {
      required: false,
    },
  });

  const [sameContracting, setSameContracting] = useState({
    value: get(data, 'sameContracting', false),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sector" />,
    validation: {},
  });

  const [dateOfBirthFocused, setDateOfBirthFocused] = useState(false);
  const [filteredPlaces, setFilteredPlaces] = useState([...places]);

  useEffect(() => {
    if (places.length > 0) {
      setFilteredPlaces([...places]);
    }
  }, [places]);

  useEffect(() => {
    if (places.length > 0 && city.validation.enum.length < 1) {
      setCity(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
    }
    if (places.length > 0 && zip.validation.enum.length < 1) {
      setZip(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
    }
  }, [places, city, zip]);

  useEffect(() => {
    if (reportingPolicyNumber.current.value) {
      getPolicyValidity({
        variables: {
          insurancePolicyNumber: reportingPolicyNumber.current.value,
          insurancePolicyOib: reportingPolicyOwnerOib.current.value,
        },
      });
    }
  }, [getPolicyValidity]);

  useEffect(() => {
    if (insuredPersonData && groupName === CLAIM_CAR_LIABILITY) {
      insuredPersonData.sector && setSector(o => ({ ...o, value: insuredPersonData.sector }));
      if (
        insuredPersonData.sector === user.sector &&
        (insuredPersonData.name === user.businessName ||
          (insuredPersonData.firstName === user.firstName && insuredPersonData.lastName === user.lastName))
      ) {
        setSameContracting(o => ({ ...o, value: true }));
      } else {
        if (insuredPersonData.sector === USER_ACCOUNT_BUSINESS) {
          insuredPersonData.name && setBusinessName(o => ({ ...o, value: insuredPersonData.name }));
        } else {
          insuredPersonData.firstName && setFirstName(o => ({ ...o, value: insuredPersonData.firstName }));
          insuredPersonData.lastName && setLastName(o => ({ ...o, value: insuredPersonData.lastName }));
          insuredPersonData.dateOfBirth &&
            setDateOfBirth(o => ({ ...o, value: moment(insuredPersonData.dateOfBirth) }));
          insuredPersonData.sex && setSex(o => ({ ...o, value: insuredPersonData.sex }));
        }
        insuredPersonData.email && setEmail(o => ({ ...o, value: insuredPersonData.email }));
        insuredPersonData.phoneNumber && setPhoneNumber(o => ({ ...o, value: insuredPersonData.phoneNumber }));
        insuredPersonData.address && setAddress(o => ({ ...o, value: insuredPersonData.address }));
        insuredPersonData.zip && setZip(o => ({ ...o, value: insuredPersonData.zip }));
        if (insuredPersonData.zip && places.length > 0) {
          const place = places.find(place => place.pttNumber === insuredPersonData.zip);
          place && setCity(o => ({ ...o, value: place.name || '' }));
        }
      }
    }
  }, [insuredPersonData, places, groupName, user.sector, user.firstName, user.lastName, user.businessName]);

  const debouncedPlacesFilter = debounce(value => {
    const matchingPlaces = places.filter(place => place.pttNumber.startsWith(value));
    setFilteredPlaces(value ? matchingPlaces : [...places]);
    if (matchingPlaces.length === 1 && matchingPlaces[0].pttNumber === value) {
      setCity(city => ({ ...city, invalid: false, value: matchingPlaces[0].name }));
    } else {
      setCity(city => ({ ...city, invalid: false, value: '' }));
    }
  }, 500);

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false, error: null }));
  };

  const reportDamangeStep1SubmitHandler = async event => {
    event.preventDefault();

    const fieldsArray = [setReportingPolicyNumber, setReportingPolicyOwnerOib, setPerilExist, setNote, setTac, setIBAN];
    if (perilExist.value) {
      if (!sameContracting.value) {
        if (groupName !== CLAIM_CAR_LIABILITY) {
          fieldsArray.push(setOIB, setAddress, setCity, setZip, setPhoneNumber, setEmail);
        }
        if (sector.value === USER_ACCOUNT_BUSINESS) {
          fieldsArray.push(setBusinessName);
        } else {
          fieldsArray.push(setFirstName, setLastName);
          if (groupName !== CLAIM_CAR_LIABILITY) {
            fieldsArray.push(setDateOfBirth, setSex);
          }
        }
      }
    }

    if (groupName === CLAIM_CAR_LIABILITY) {
      fieldsArray.push(setPolicyNumber);
    }

    if (
      (await validateFormSubmission(fieldsArray)) &&
      reportingPolicyNumberStatus.valid &&
      reportingPolicyOwnerOibStatus.valid &&
      insuredPersonData &&
      isOibValid &&
      productDefinition
    ) {
      const insuredPerson = {
        ...(user.sector === USER_ACCOUNT_BUSINESS
          ? {
              businessName: user.businessName,
            }
          : {
              firstName: user.firstName,
              lastName: user.lastName,
              dateOfBirth: user.dateOfBirth,
              sex: user.sex,
            }),
        sector: user.sector,
        oib: user.oib,
        zip: user.zip,
        address: user.address,
        city: user.city,
        phoneNumber: user.phoneNumber,
        email: user.email,
      };
      const variables = {
        reporterEmail: user.email,
        reporterIBAN: IBAN.value,
        insurancePolicyNumber: reportingPolicyNumber.current.value,
        insurancePolicyOib: reportingPolicyOwnerOib.current.value,
        claimClass,
        insured: {
          ...insuredPerson,
          ...(groupName === CLAIM_CAR_LIABILITY && { insurancePolicyNumberAO: policyNumber.value }),
        },
        ...(perilExist.value && {
          peril: sameContracting.value
            ? insuredPerson
            : {
                ...(sector.value === USER_ACCOUNT_BUSINESS
                  ? {
                      businessName: businessName.value,
                    }
                  : {
                      firstName: firstName.value,
                      lastName: lastName.value,
                      ...(groupName !== CLAIM_CAR_LIABILITY && { dateOfBirth: dateOfBirth.value, sex: sex.value }),
                    }),
                oib: groupName === CLAIM_CAR_LIABILITY ? reportingPolicyOwnerOib.current.value : oib.value,
                ...(groupName !== CLAIM_CAR_LIABILITY && {
                  sector: sector.value,
                  zip: zip.value,
                  address: address.value,
                  city: city.value,
                  phoneNumber: phoneNumber.value,
                  email: email.value,
                }),
              },
        }),
        note: note.value,
        tac: tac.value,
        sameContracting: sameContracting.value,
        perilExist: perilExist.value,
      };

      submitForm({
        variables: {
          attachToUser: true,
          state: {
            ...data,
            step: 'STEP_2',
            ...variables,
          },
        },
      });
    }
  };

  const renderPerilHeader = () => {
    if (sector.value === USER_ACCOUNT_BUSINESS) {
      return `${businessName.value}`.trim() || formatMessage({ id: 'userReportDamagePage.peril' });
    } else {
      return `${firstName.value} ${lastName.value}`.trim() || formatMessage({ id: 'userReportDamagePage.peril' });
    }
  };

  const isPerilPersonInfoInvalid =
    perilExist.value &&
    ((sector.value === USER_ACCOUNT_PRIVATE &&
      (firstName.invalid || lastName.invalid || dateOfBirth.invalid || sex.invalid)) ||
      (sector.value === USER_ACCOUNT_BUSINESS && businessName.invalid) ||
      sector.invalid ||
      oib.invalid ||
      address.invalid ||
      city.invalid ||
      zip.invalid ||
      email.invalid ||
      phoneNumber.invalid ||
      (groupName === CLAIM_CAR_LIABILITY &&
        (reportingPolicyNumber.current.invalid ||
          reportingPolicyNumberStatus.invalid ||
          reportingPolicyOwnerOib.invalid ||
          reportingPolicyOwnerOibStatus.invalid)));

  return (
    <Form onSubmit={reportDamangeStep1SubmitHandler}>
      {groupName !== CLAIM_CAR_LIABILITY && (
        <>
          <FormGroup row className="mt-4">
            <Col xs={12}>
              <Label for="reportingPolicyNumber">
                <FormattedMessage id="inputs.reportingPolicyNumber" />
              </Label>
            </Col>
            <Col sm="8" md="6" xl="4">
              <div className="w-100 position-relative">
                {checkLoading && <Spinner className="input-spinner" size="sm" />}
                <Input
                  invalid={
                    !checkLoading && (reportingPolicyNumberStatus.invalid || reportingPolicyNumber.current.invalid)
                  }
                  defaultValue={
                    get(data, 'insurancePolicyNumber', '') || get(history, 'location.state.reportingPolicyNumber', '')
                  }
                  valid={!checkLoading && reportingPolicyNumberStatus.valid}
                  onChange={event => {
                    reportingPolicyNumber.current.value = event.target.value;
                    if (event.target.value) {
                      debouncedPolicyValidityCheck({
                        variables: {
                          insurancePolicyNumber: event.target.value,
                          insurancePolicyOib: reportingPolicyOwnerOib.current.value,
                        },
                      });
                    } else {
                      setReportingPolicyNumberStatus({ invalid: false, error: '', valid: false });
                    }
                  }}
                />
              </div>
            </Col>
            {(reportingPolicyNumberStatus.invalid || reportingPolicyNumber.current.invalid) && !checkLoading && (
              <Col xs={12}>
                <FormFeedback className="d-block">
                  {reportingPolicyNumberStatus.error || reportingPolicyNumber.current.error}
                </FormFeedback>
              </Col>
            )}
          </FormGroup>
          <FormGroup row className="mt-2">
            <Col xs={12}>
              <Label for="reportingPolicyOwnerOib">
                <FormattedMessage id="inputs.reportingPolicyOwnerOib" />
              </Label>
            </Col>
            <Col sm="8" md="6" xl="4">
              <Input
                id="reportingPolicyOwnerOib"
                invalid={reportingPolicyOwnerOibStatus.invalid || reportingPolicyOwnerOib.current.invalid}
                defaultValue={
                  get(data, 'insurancePolicyOib', '') || get(history, 'location.state.reportingPolicyOwnerOib', '')
                }
                valid={reportingPolicyOwnerOibStatus.valid}
                onChange={async event => {
                  event.persist();
                  reportingPolicyOwnerOib.current.value = event.target.value;
                  if (event.target.value) {
                    debouncedOIBValidityCheck({
                      variables: {
                        insurancePolicyNumber: reportingPolicyNumber.current.value,
                        insurancePolicyOib: event.target.value,
                      },
                    });
                  } else {
                    reportingPolicyOwnerOib.current.invalid = false;
                    reportingPolicyOwnerOib.current.error = '';
                    setReportingPolicyOwnerOibStatus({ invalid: false, error: '', valid: false });
                  }
                }}
              />
            </Col>
            {(reportingPolicyOwnerOibStatus.invalid || reportingPolicyOwnerOib.current.invalid) && !checkLoading && (
              <Col xs={12}>
                <FormFeedback className="d-block">
                  {reportingPolicyOwnerOibStatus.error || reportingPolicyOwnerOib.current.error}
                </FormFeedback>
              </Col>
            )}
          </FormGroup>
        </>
      )}
      <Row className="mt-2 mb-4">
        <Col>
          <PolicyContractingPersonInfo
            expanded
            user={user}
            label
            labelId="userReportDamagePage.damagedParty"
            invalid={IBAN.invalid || (groupName === CLAIM_CAR_LIABILITY && policyNumber.invalid)}
          >
            <FormGroup row className="mt-4">
              {groupName === CLAIM_CAR_LIABILITY && (
                <Col sm="6" md="4">
                  <Label for="policyNumber">
                    <FormattedMessage id="inputs.damagedPolicyNumber" />
                  </Label>
                  <CustomDashboardInput
                    fieldState={policyNumber}
                    onChange={event => inputHandler(setPolicyNumber, event.target.value)}
                  />
                </Col>
              )}
              <Col sm="6" md="4">
                <Label for="IBAN">
                  <FormattedMessage id="inputs.IBAN" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="IBAN"
                    value={IBAN.value}
                    fieldState={IBAN}
                    onChange={event => inputHandler(setIBAN, event.target.value)}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
          </PolicyContractingPersonInfo>
        </Col>
      </Row>
      <FormGroup row>
        <Col className="d-flex flex-row" md="8">
          <CustomInput
            className="hok-tac-checkbox hok-claim-status-checkbox"
            id="reported"
            type="checkbox"
            checked={perilExist.value}
            invalid={perilExist.invalid}
            disabled={groupName === CLAIM_CAR_LIABILITY}
            onChange={event => inputHandler(setPerilExist, event.target.checked)}
            label={<span className="hok-text-dark font-weight-bold">{formatMessage({ id: 'inputs.perilExist' })}</span>}
          />
        </Col>
      </FormGroup>
      {perilExist.value && (
        <>
          <Row className="mt-4">
            <Col xs="12">
              <Label>
                <FormattedMessage id="userReportDamagePage.peril" />:
              </Label>
              <CollapsibleContainer expanded title={renderPerilHeader()} invalid={isPerilPersonInfoInvalid}>
                {groupName === CLAIM_CAR_LIABILITY && (
                  <FormGroup row className="mt-2">
                    <Col sm="6" md="4">
                      <Label for="reportingPolicyNumber" className="label-flex">
                        <FormattedMessage id="inputs.reportingPolicyNumber" />
                      </Label>
                      <InputGroup>
                        <div className="w-100 position-relative">
                          {checkLoading && <Spinner className="input-spinner" size="sm" />}
                          <Input
                            invalid={
                              !checkLoading &&
                              (reportingPolicyNumberStatus.invalid || reportingPolicyNumber.current.invalid)
                            }
                            defaultValue={
                              get(data, 'insurancePolicyNumber', '') ||
                              get(history, 'location.state.reportingPolicyNumber', '')
                            }
                            valid={!checkLoading && reportingPolicyNumberStatus.valid}
                            onChange={event => {
                              reportingPolicyNumber.current.value = event.target.value;
                              if (event.target.value) {
                                debouncedPolicyValidityCheck({
                                  variables: {
                                    insurancePolicyNumber: event.target.value,
                                    insurancePolicyOib: reportingPolicyOwnerOib.current.value,
                                  },
                                });
                              } else {
                                setReportingPolicyNumberStatus({ invalid: false, error: '', valid: false });
                              }
                            }}
                          />
                        </div>
                        {(reportingPolicyNumberStatus.invalid || reportingPolicyNumber.current.invalid) &&
                          !checkLoading && (
                            <FormFeedback className="d-block">
                              {reportingPolicyNumberStatus.error || reportingPolicyNumber.current.error}
                            </FormFeedback>
                          )}
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="reportingPolicyOwnerOib" className="label-flex">
                        <FormattedMessage id="inputs.reportingPolicyOwnerOib" />
                      </Label>
                      <InputGroup>
                        <Input
                          id="reportingPolicyOwnerOib"
                          invalid={reportingPolicyOwnerOibStatus.invalid || reportingPolicyOwnerOib.current.invalid}
                          defaultValue={
                            get(data, 'insurancePolicyOib', '') ||
                            get(history, 'location.state.reportingPolicyOwnerOib', '')
                          }
                          valid={reportingPolicyOwnerOibStatus.valid}
                          onChange={async event => {
                            event.persist();
                            reportingPolicyOwnerOib.current.value = event.target.value;
                            if (event.target.value) {
                              debouncedOIBValidityCheck({
                                variables: {
                                  insurancePolicyNumber: reportingPolicyNumber.current.value,
                                  insurancePolicyOib: event.target.value,
                                },
                              });
                            } else {
                              reportingPolicyOwnerOib.current.invalid = false;
                              reportingPolicyOwnerOib.current.error = '';
                              setReportingPolicyOwnerOibStatus({ invalid: false, error: '', valid: false });
                            }
                          }}
                        />
                        {(reportingPolicyOwnerOibStatus.invalid || reportingPolicyOwnerOib.current.invalid) &&
                          !checkLoading && (
                            <FormFeedback className="d-block">
                              {reportingPolicyOwnerOibStatus.error || reportingPolicyOwnerOib.current.error}
                            </FormFeedback>
                          )}
                      </InputGroup>
                    </Col>
                  </FormGroup>
                )}
                {groupName !== CLAIM_CAR_LIABILITY && (
                  <>
                    <FormGroup row className="mt-4">
                      <Col sm="6" md="4">
                        <Label for="perilSector">
                          <FormattedMessage id="inputs.sector" />
                        </Label>
                        <InputGroup>
                          <div className="hok-select">
                            <CustomDashboardInput
                              id="perilSector"
                              type="select"
                              value={sector.value}
                              disabled={sameContracting.value}
                              fieldState={sector}
                              onChange={event => {
                                if (sameContracting.value) {
                                  setSameContracting(o => ({ ...o, value: false }));
                                }
                                inputHandler(setSector, event.target.value);
                              }}
                            >
                              {USER_ACCOUNT_TYPES.map(type => (
                                <option key={type.name} value={type.name}>
                                  {formatMessage({ id: `accountType.${type.name}` })}
                                </option>
                              ))}
                            </CustomDashboardInput>
                          </div>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    {user.sector === sector.value && (
                      <FormGroup row className="my-4">
                        <Col className="d-flex flex-row" xs="12">
                          <CustomInput
                            className="hok-tac-checkbox font-weight-bold"
                            id="same"
                            type="checkbox"
                            checked={sameContracting.value}
                            invalid={sameContracting.invalid}
                            onChange={event => inputHandler(setSameContracting, event.target.checked)}
                            label={<span className="font-weight-bold">Štetnik je isti oštećeniku</span>}
                          />
                        </Col>
                        {sameContracting.invalid && (
                          <FormFeedback className="d-block pl-3">{sameContracting.error}</FormFeedback>
                        )}
                      </FormGroup>
                    )}
                  </>
                )}
                {(groupName !== CLAIM_CAR_LIABILITY ||
                  (groupName === CLAIM_CAR_LIABILITY && insuredPersonData && isOibValid && productDefinition)) && (
                  <FormGroup row>
                    {sector.value === USER_ACCOUNT_BUSINESS ? (
                      <Col sm="6" md="4">
                        <Label for="perilName">
                          <FormattedMessage id="inputs.name" />
                        </Label>
                        <InputGroup>
                          <CustomDashboardInput
                            id="perilName"
                            value={sameContracting.value ? user.businessName : businessName.value}
                            readOnly={sameContracting.value || groupName === CLAIM_CAR_LIABILITY}
                            fieldState={businessName}
                            onChange={event => inputHandler(setBusinessName, event.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    ) : (
                      <>
                        <Col sm="6" md="4">
                          <Label for="perilFirstName">
                            <FormattedMessage id="inputs.firstName" />
                          </Label>
                          <InputGroup>
                            <CustomDashboardInput
                              id="perilFirstName"
                              value={sameContracting.value ? user.firstName : firstName.value}
                              readOnly={sameContracting.value || groupName === CLAIM_CAR_LIABILITY}
                              fieldState={firstName}
                              onChange={event => inputHandler(setFirstName, event.target.value)}
                            />
                          </InputGroup>
                        </Col>
                        <Col sm="6" md="4">
                          <Label for="perilLastName">
                            <FormattedMessage id="inputs.lastName" />
                          </Label>
                          <InputGroup>
                            <CustomDashboardInput
                              id="perilLastName"
                              value={sameContracting.value ? user.lastName : lastName.value}
                              readOnly={sameContracting.value || groupName === CLAIM_CAR_LIABILITY}
                              fieldState={lastName}
                              onChange={event => inputHandler(setLastName, event.target.value)}
                            />
                          </InputGroup>
                        </Col>
                        {groupName !== CLAIM_CAR_LIABILITY && (
                          <>
                            <Col sm="6" md="4">
                              <Label for="perilDateOfBirth">
                                <FormattedMessage id="inputs.dateOfBirth" />
                              </Label>
                              <InputGroup className={dateOfBirth.invalid ? 'invalid-date' : ''}>
                                <SingleDatePicker
                                  hideKeyboardShortcutsPanel
                                  disabled={sameContracting.value}
                                  date={sameContracting.value ? moment(user.dateOfBirth) : dateOfBirth.value}
                                  onDateChange={date => inputHandler(setDateOfBirth, date)}
                                  focused={dateOfBirthFocused}
                                  onFocusChange={({ focused }) => setDateOfBirthFocused(focused)}
                                  id="perilDateOfBirth"
                                  showDefaultInputIcon
                                  firstDayOfWeek={1}
                                  numberOfMonths={1}
                                  small
                                  displayFormat={FORMAT_STANDARD_DATE}
                                  placeholder={''}
                                  initialVisibleMonth={() => dateOfBirth.value || moment()}
                                  isOutsideRange={date => date.isAfter(moment(), 'day')}
                                  renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                                    <div className="d-flex justify-content-center">
                                      <div className="mr-1">
                                        <select
                                          value={month.month()}
                                          onChange={e => onMonthSelect(month, e.target.value)}
                                        >
                                          {moment.months().map((label, value) => (
                                            <option value={value} key={label}>
                                              {label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="ml-1">
                                        <select
                                          value={month.year()}
                                          onChange={e => onYearSelect(month, e.target.value)}
                                        >
                                          {yearListRender(1900, moment().format('YYYY'))}
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                />
                                {dateOfBirth.invalid && (
                                  <FormFeedback className="d-block">{dateOfBirth.error}</FormFeedback>
                                )}
                              </InputGroup>
                            </Col>
                            <Col sm="6" md="4">
                              <Label for="perilSex">
                                <FormattedMessage id="inputs.sex" />
                              </Label>
                              <InputGroup>
                                <div className="hok-select">
                                  <CustomDashboardInput
                                    id="perilSex"
                                    type="select"
                                    disabled={sameContracting.value}
                                    value={sameContracting.value ? user.sex : sex.value}
                                    fieldState={sex}
                                    onChange={event => inputHandler(setSex, event.target.value)}
                                  >
                                    <option disabled value="">
                                      {formatMessage({ id: 'inputs.sex' })}
                                    </option>
                                    {USER_GENDER.map(sex => (
                                      <option key={sex.value} value={sex.value}>
                                        {formatMessage({ id: sex.name })}
                                      </option>
                                    ))}
                                  </CustomDashboardInput>
                                </div>
                              </InputGroup>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                    {groupName !== CLAIM_CAR_LIABILITY && (
                      <Col sm="6" md="4">
                        <Label for="perilOib">
                          <FormattedMessage id="inputs.OIB" />
                        </Label>
                        <InputGroup>
                          <CustomDashboardInput
                            id="perilOib"
                            value={sameContracting.value ? user.oib : oib.value}
                            readOnly={sameContracting.value}
                            fieldState={oib}
                            onChange={event => inputHandler(setOIB, event.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    )}
                  </FormGroup>
                )}
                {groupName !== CLAIM_CAR_LIABILITY && (
                  <FormGroup row>
                    <Col sm="6" md="4">
                      <Label for="perilAddress">
                        <FormattedMessage id="inputs.address" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          id="perilAddress"
                          value={sameContracting.value ? user.address : address.value}
                          readOnly={sameContracting.value}
                          fieldState={address}
                          onChange={event => inputHandler(setAddress, event.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="perilZip">
                        <FormattedMessage id="inputs.zipCode" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          className="input-uppercase"
                          id="perilZip"
                          fieldState={zip}
                          value={sameContracting.value ? user.zip : zip.value}
                          readOnly={sameContracting.value}
                          onChange={event => {
                            event.persist();
                            debouncedPlacesFilter(event.target.value);
                            setZip(zip => ({ ...zip, invalid: false, value: event.target.value }));
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="perilCity">
                        <FormattedMessage id="inputs.place" />
                      </Label>
                      <InputGroup>
                        <div className="hok-select">
                          <CustomDashboardInput
                            id="perilCity"
                            type="select"
                            fieldState={city}
                            disabled={sameContracting.value}
                            value={sameContracting.value ? user.city : city.value}
                            onChange={event => {
                              const place = places.find(place => place.name === event.target.value);
                              setCity({ ...city, invalid: false, value: event.target.value });
                              place && setZip({ ...zip, invalid: false, value: place.pttNumber });
                            }}
                          >
                            <option disabled value="">
                              {formatMessage({
                                id: 'inputs.labels.selectPlace',
                              })}
                            </option>
                            {loadingPlaces ? (
                              <option disabled value={city.value}>
                                {city.value}
                              </option>
                            ) : (
                              filteredPlaces.map(place => (
                                <option key={v4()} value={place.name}>
                                  {place.name}
                                </option>
                              ))
                            )}
                          </CustomDashboardInput>
                        </div>
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="perilPhoneNumber">
                        <FormattedMessage id="inputs.phoneNumberShort" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          id="perilPhoneNumber"
                          name="mobile phone number"
                          className={sameContracting.value ? 'disabled' : ''}
                          type="tel"
                          // If lenght is greater than 21 formatPhoneNumberIntl() function from
                          // "react-phone-number-input" goes wild and resets the value to an empty string
                          maxLength={21}
                          tag={PhoneInput}
                          defaultCountry="HR"
                          value={sameContracting.value ? user.phoneNumber : phoneNumber.value}
                          readOnly={sameContracting.value}
                          fieldState={phoneNumber}
                          numberInputProps={phoneNumber.invalid ? 'is-invalid' : ''}
                          onChange={phone => inputHandler(setPhoneNumber, formatPhoneNumber(phone))}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="perilEmail">
                        <FormattedMessage id="inputs.email" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          id="perilEmail"
                          value={sameContracting.value ? user.email : email.value}
                          readOnly={sameContracting.value}
                          fieldState={email}
                          onChange={event => inputHandler(setEmail, event.target.value)}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                )}
              </CollapsibleContainer>
            </Col>
          </Row>
        </>
      )}
      <FormGroup row className="mt-4 mb-5">
        <Col>
          <Label for="note">
            <FormattedMessage id="inputs.note" />
          </Label>
          <CustomDashboardInput
            id="note"
            className="hok-note-textarea"
            type="textarea"
            value={note.value}
            fieldState={note}
            onChange={event => setNote({ ...note, value: event.target.value, invalid: false, error: null })}
          />
        </Col>
      </FormGroup>
      <FormGroup row className="mt-4">
        <Col className="d-flex flex-row" xs="12">
          <CustomInput
            className="hok-tac-checkbox"
            id="tac"
            type="checkbox"
            checked={tac.value}
            invalid={tac.invalid}
            onChange={event => {
              event.persist();
              setTac(tac => ({ ...tac, value: event.target.checked, invalid: false }));
            }}
          />
          <div className="tac-text-container">
            <span>
              Prihvaćam pohranu i rukovanje iznad navedenim podacima sukladno
              <a
                target="_blank"
                href={legalDocuments[0] ? legalDocuments[0].link : '#'}
                rel="noopener noreferrer"
                className="terms-and-conditions-text"
              >
                informacijama o obradi podataka.
              </a>
            </span>
          </div>
        </Col>
        <FormFeedback className={'d-block pl-3' + (tac.invalid ? ' visible' : ' invisible')}>
          <FormattedMessage id="errors.invalidIOOP" />
        </FormFeedback>
      </FormGroup>
      <Row className="no-gutters mt-4">
        <Col>
          <CustomButton
            className="hok-dashboard-btn-wide float-sm-right"
            translationId="continue"
            loaderProp={loading}
          />
        </Col>
      </Row>
    </Form>
  );
};
