import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ContentContainer, ContentIconBox } from '../../../containers';
import {
  ROUTE_TO_USER_LOGIN_PAGE,
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT,
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW,
} from '../../../constants/routes';
import { ReactComponent as HokSubmitClaimIcon } from '../../../img/icons/hok-claim-submit-card.svg';
import { ReactComponent as HokClaimsIcon } from '../../../img/icons/hok-claim-preview-card.svg';
import { ReactComponent as HokBackIcon } from '../../../img/icons/hok-back.svg';

export const QuickDamageReport = () => {
  return (
    <ContentContainer titleTranslationId="quickDamageReportPage.title" footerImage>
      <Row className="no-gutters my-3">
        <Col xs="12" md={{ offset: 2, size: 8 }}>
          <Link to={ROUTE_TO_USER_LOGIN_PAGE} className="d-inline-flex align-items-center link-back ml-0">
            <HokBackIcon className="mr-2" />
            <FormattedMessage id="quickDamageReportPage.backToLogin" />
          </Link>
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col xs="12" md={{ offset: 2, size: 8 }}>
          <p>
            Putem brze prijave štete možete prijaviti i pregledati štete iz Autoodgovornosti neovisno jeste li partner
            HOK osiguranja ili partner neke druge osiguravajuće kuće.
          </p>
        </Col>
      </Row>
      <Row className="main-content mt-5">
        <Col xs="12" className="text-center">
          <ContentIconBox
            link
            Icon={HokSubmitClaimIcon}
            name="quickDamageReportPage.reportDamage"
            onClick={ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}
          />
          <ContentIconBox
            link
            Icon={HokClaimsIcon}
            name="quickDamageReportPage.previewDamageReport"
            onClick={ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW}
          />
        </Col>
      </Row>
    </ContentContainer>
  );
};
