import gql from 'graphql-tag';

export const CheckPolicyValidityQuery = gql`
  query CheckPolicyValidity($insurancePolicyNumber: String!, $insurancePolicyOib: String) {
    checkIfInsurancePolicyIsValid(
      insurancePolicyNumber: $insurancePolicyNumber
      insurancePolicyOib: $insurancePolicyOib
    ) {
      productDefinition {
        id
        code
        product
        group
        active
        claimBook
      }
      claimDefinition {
        id
        code
        book
        group
        active
      }
      isOibValid
      insurancePolicyUserDataViaOib {
        oib
        name
        firstName
        lastName
        dateOfBirth
        sector
        address
        sex
        zip
        email
        phoneNumber
      }
    }
  }
`;
