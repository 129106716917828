import gql from 'graphql-tag';

export const NewUserTicketMessageSubscription = gql`
  subscription NewUserTicketMessage($token: String!, $userId: String!) {
    userTicketUpdate(token: $token, userId: $userId) {
      ticketNumber
      countNew
      ticketMessages {
        id
        userId
        agentNickname
        additionalValues
        content
        attachments
        createdAt
      }
    }
  }
`;

export const NewAdminTicketMessageSubscription = gql`
  subscription NewAdminTicketMessage($token: String!, $userId: String!) {
    userAdminTicketUpdate(token: $token, userId: $userId) {
      ticketNumber
      countNew
      ticketMessages {
        id
        userId
        agentNickname
        additionalValues
        content
        attachments
        createdAt
      }
    }
  }
`;
