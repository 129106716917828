import gql from 'graphql-tag';

export const DeleteUserMutation = gql`
  mutation DeleteUser($id: Int!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export default DeleteUserMutation;
