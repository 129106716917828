import { take, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';
import { client } from '../../config/apolloConfig';
import {
  ADMIN_LOGIN,
  ADMIN_AUTH_LOGOUT,
  AUTH_TOKEN_EXPIRED,
  ADMIN_AUTH_TOKEN_REFRESH,
  adminLoginFailure,
  adminLoginSuccess,
  tokenRefreshSuccess,
} from '../actions/adminAuthActions';
import { redirectToMaintenancePage } from '../actions/navigationActions';
import LoginMutation from '../../operations/mutations/LoginMutation';
import MeQuery from '../../operations/queries/MeQuery';
import { removeLocalStorageToken, setLocalStorageToken } from '../../constants/LocalStorageKeys';
import {
  getErrorObject,
  isMaintenanceInProgress,
  getSpecificError,
  UNKNOWN_LOGIN_FAIL,
} from '../../constants/errorCodes';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

/**
 * Attempt Login Username Password
 **/
function* attemptAdminLoginUsernamePasswordWorker(payload) {
  try {
    const loginResult = yield call(() =>
      client.mutate({
        mutation: LoginMutation,
        variables: { ...payload, doors: 'EMPLOYEE' },
      })
    );

    const token = get(loginResult, 'data.login.token');
    if (token) {
      setLocalStorageToken(token, 'attemptAdminLoginUsernamePassword');

      const profileResult = yield call(() =>
        client.query({
          query: MeQuery,
        })
      );

      const profile = get(profileResult, 'data.myData', {});
      profile.phoneNumber = formatPhoneNumber(profile.phoneNumber);
      yield put(adminLoginSuccess({ user: profile }));
    } else {
      yield put(adminLoginFailure({ errorMessage: getSpecificError(UNKNOWN_LOGIN_FAIL).message }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      yield put(adminLoginFailure({ errorMessage: errorObject.message }));
    }
  }
}

export function* attemptAdminLoginUsernamePasswordWatcher() {
  /*
    ***THIS IS A BLOCKING CALL***
    It means that watchCheckout will ignore any ADMIN_LOGIN event until
    the current attemptAdminLoginUsernamePasswordWorker completes, either by success or by Error
  */
  while (true) {
    const { payload } = yield take(ADMIN_LOGIN);
    yield call(attemptAdminLoginUsernamePasswordWorker, payload);
  }
}

/**
 * Always update with the latest auth token
 **/
function* refreshTokenWorker(action) {
  try {
    const token = get(action, 'payload');

    if (token) {
      setLocalStorageToken(token, 'replaceOldAuthToken');
      yield put(tokenRefreshSuccess({ authToken: token }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      yield put(adminLoginFailure({ errorMessage: errorObject.message }));
    }
  }
}

export function* refreshTokenWatcher() {
  yield takeEvery(ADMIN_AUTH_TOKEN_REFRESH, refreshTokenWorker);
}

/**
 * Remove user from local storage
 **/
export function* clearAuthorizedUserFromLocalStorage() {
  removeLocalStorageToken('clearAuthorizedUserFromLocalStorage');
  yield call(() => client.cache.reset());
}

export function* authorizedUserLogoutWatcher() {
  yield takeLatest(ADMIN_AUTH_LOGOUT, clearAuthorizedUserFromLocalStorage);
}

export function* authorizedUserTokenExpiredWatcher() {
  yield takeEvery(AUTH_TOKEN_EXPIRED, clearAuthorizedUserFromLocalStorage);
}
