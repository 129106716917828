import {
  LOAD_USERS,
  CREATE_USER,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILURE,
  CREATE_USER_FEEDBACK,
  UPDATE_USER_FEEDBACK,
  DELETE_USER_SUCCESS,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  CREATE_EMPLOYEE_FEEDBACK,
  UPDATE_EMPLOYEE_FEEDBACK,
} from '../actions/usersActions';
import { isEmpty } from 'lodash-es';

const initialState = {
  userList: [],
  loadingInProgress: false,
  creatingUserInProgress: false,
  creatingEmployeeInProgress: false,
  isEmpty: true,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return { ...state, loadingInProgress: true };
    case LOAD_USERS_SUCCESS:
      return { ...state, ...action.payload, loadingInProgress: false, isEmpty: isEmpty(action.payload) };
    case LOAD_USERS_FAILURE:
      return { ...state, loadingInProgress: false };
    case CREATE_USER:
      return { ...state, creatingUserInProgress: true };
    case CREATE_USER_FEEDBACK:
      return { ...state, creatingUserInProgress: false };
    case CREATE_EMPLOYEE:
      return { ...state, creatingUserInProgress: true };
    case UPDATE_EMPLOYEE:
      return { ...state, creatingEmployeeInProgress: true };
    case CREATE_EMPLOYEE_FEEDBACK:
      return { ...state, creatingUserInProgress: false };
    case UPDATE_EMPLOYEE_FEEDBACK:
      return { ...state, creatingEmployeeInProgress: false };
    case UPDATE_USER_FEEDBACK:
      return { ...state, creatingUserInProgress: false };
    case DELETE_USER_SUCCESS:
      return { ...state, userList: [...state.userList.filter(user => user.id !== action.payload)] };
    default:
      return state;
  }
};

export default usersReducer;
