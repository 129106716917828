import { all, call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';
import { client } from '../../config/apolloConfig';
import { adminLoginFailure } from '../actions/adminAuthActions';
import { getErrorObject, isAuthTokenInvalid, isMaintenanceInProgress } from '../../constants/errorCodes';
import { renderToaster, ERROR_TOASTER, SUCCESS_TOASTER } from '../../constants/toaster';
import {
  LOAD_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  loadUsersSuccess,
  loadUsersFailure,
  loadUsers,
  createUserFeedback,
  updateUserFeedback,
  deleteUserSuccess,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  createEmployeeFeedback,
} from '../actions/usersActions';
import {
  redirectToMaintenancePage,
  redirectToAdminEditUserPage,
  redirectToAdminEditEmployeePage,
} from '../actions/navigationActions';
import { GetUsersQuery } from '../../operations/queries/GetUsersQuery';
import { DeleteUserMutation } from '../../operations/mutations/DeleteUserMutation';
import { UpdateUserMutation } from '../../operations/mutations/UpdateUserMutation';
import { RegisterUserMutation } from '../../operations/mutations/RegisterUserMutation';
import { AdminUpdateUserPersonalInfoMutation } from '../../operations/mutations/AdminUpdateUserMutation';
import { formatDate } from '../../constants/dateFormatting';

/**
 * Load Users
 **/
function* loadUsersWorker() {
  try {
    const apiResult = yield call(() =>
      client.query({
        query: GetUsersQuery,
      })
    );

    const result = get(apiResult, 'data', []);

    yield put(loadUsersSuccess(result));
  } catch (e) {
    yield put(loadUsersFailure());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(adminLoginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* loadUsersWatcher() {
  yield takeEvery(LOAD_USERS, loadUsersWorker);
}

/**
 * Create user
 **/
function* createUserWorker(action) {
  try {
    const regResult = yield call(() =>
      client.mutate({
        mutation: RegisterUserMutation,
        variables: {
          email: action.payload.email,
          password: action.payload.password,
          phoneNumber: action.payload.phoneNumber,
        },
      })
    );

    const result = get(regResult, 'data.registration', {});
    if (result.id) {
      const updateResult = yield call(() =>
        client.mutate({
          mutation: AdminUpdateUserPersonalInfoMutation,
          variables: { id: result.id, ...action.payload, passwordExpires: formatDate('07.05.1981.') },
        })
      );

      const res = get(updateResult, 'data.updateUser', {});
      if (res.id) {
        renderToaster('successMessages.userCreated', SUCCESS_TOASTER, { intlKey: true });
        yield all([put(createUserFeedback()), put(redirectToAdminEditUserPage(res.id))]);
      }
    }
  } catch (e) {
    yield put(createUserFeedback());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(adminLoginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* createUserWatcher() {
  yield takeEvery(CREATE_USER, createUserWorker);
}

/**
 * Update user
 **/
function* updateUserWorker(action) {
  try {
    const apiResult = yield call(() =>
      client.mutate({
        mutation: UpdateUserMutation,
        variables: action.payload,
      })
    );

    const result = get(apiResult, 'data.updateUser', {});
    if (result) {
      renderToaster('successMessages.userUpdated', SUCCESS_TOASTER, { intlKey: true });
      yield all([put(updateUserFeedback()), put(loadUsers())]);
    }
  } catch (e) {
    yield put(updateUserFeedback());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(adminLoginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* updateUserWatcher() {
  yield takeEvery(UPDATE_USER, updateUserWorker);
}

/**
 * Delete user
 **/
function* deleteUserWorker(action) {
  try {
    const apiResult = yield call(() =>
      client.mutate({
        mutation: DeleteUserMutation,
        variables: action.payload,
      })
    );

    const result = get(apiResult, 'data.deleteUser', {});
    renderToaster('successMessages.userDeleted', SUCCESS_TOASTER, { intlKey: true });
    yield put(deleteUserSuccess(result.id));
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(adminLoginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* deleteUserWatcher() {
  yield takeEvery(DELETE_USER, deleteUserWorker);
}

/**
 * Create HOK employee
 **/
function* createEmployeeWorker(action) {
  try {
    const regResult = yield call(() =>
      client.mutate({
        mutation: RegisterUserMutation,
        variables: {
          email: action.payload.email,
          password: action.payload.password,
          phoneNumber: action.payload.phoneNumber,
        },
      })
    );

    const result = get(regResult, 'data.registration', {});
    if (result.id) {
      const updateResult = yield call(() =>
        client.mutate({
          mutation: AdminUpdateUserPersonalInfoMutation,
          variables: { id: result.id, ...action.payload, passwordExpires: formatDate('07.05.1981.') },
        })
      );

      const res = get(updateResult, 'data.updateUser', {});
      if (res.id) {
        renderToaster('successMessages.employeeCreated', SUCCESS_TOASTER, { intlKey: true });
        yield all([put(createEmployeeFeedback()), put(redirectToAdminEditEmployeePage(res.id))]);
      }
    }
  } catch (e) {
    yield put(createEmployeeFeedback());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(adminLoginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* createEmployeeWatcher() {
  yield takeEvery(CREATE_EMPLOYEE, createEmployeeWorker);
}

/**
 * Update HOK employee
 **/
function* updateEmployeeWorker(action) {
  try {
    const apiResult = yield call(() =>
      client.mutate({
        mutation: AdminUpdateUserPersonalInfoMutation,
        variables: action.payload,
      })
    );

    const result = get(apiResult, 'data.updateUser', {});
    if (result) {
      renderToaster('successMessages.userUpdated', SUCCESS_TOASTER, { intlKey: true });
      yield all([put(updateUserFeedback()), put(loadUsers())]);
    }
  } catch (e) {
    yield put(updateUserFeedback());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(adminLoginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* updateEmployeeWatcher() {
  yield takeEvery(UPDATE_EMPLOYEE, updateEmployeeWorker);
}
