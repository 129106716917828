import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Label, InputGroup, Input, FormFeedback, Collapse, Fade } from 'reactstrap';
import { useMutation, useLazyQuery } from 'react-apollo';
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash-es';
import { CustomButton, CustomButtonArrowRight, CustomDashboardInput, DescriptionText } from '../../../containers';
import {
  handleRequestError,
  HOK_AUTO_NOT_SUPPORTED_VEHICLE_TYPE,
  getSpecificError,
  HOK_AUTO_START_DATE_AFTER_FORWARD_MAX_NEW,
  HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE_NEW,
  getErrorObject,
  DUPLICATE_VIN_DETECTED,
} from '../../../constants/errorCodes';
import {
  ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_3,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
  ROUTE_TO_USER_POLICIES_PAGE,
} from '../../../constants/routes';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { displayTotalPremium, displayCurrencyOptions } from '../../../utils/currencyDisplayFormat';
import {
  STEP_3,
  DOC_TYPE_ALL,
  NO_RISK_PACKAGE,
  HOK_AUTONEZGODA,
  VOZAC_AMATER,
  OSOBNO_VOZILO,
  HOK_RENT_A_CAR,
  HR_ASISTENCIJA,
  HOK_EXPRESS_ASISTENCIJA,
  MOTOCIKL_AM,
} from '../../../constants/business';
import { yearListRender, formatDate, FORMAT_STANDARD_DATE } from '../../../constants/dateFormatting';
import { TermsAndConditionsCheckbox } from '../../../components/TermsAndConditionsCheckbox';
import {
  useProductDocumentsQuery,
  useAORisksQuery,
  useAORiskSumsQuery,
  useAOProductPackagesQuery,
} from '../../../utils/customHooks';
import { ModalWithCardSelection } from '../../../components/ModalWithCardSelection';
import { GetAOPriceQuery } from '../../../operations/queries/GetPriceQuery';
import { UserInteractionModal } from '../../../components/UserInteractionModal';
import { RiskCheckbox } from './RiskCheckbox';
import { validateFormSubmission } from '../../../utils/validation';
import { NOT_ELIGIBLE_AO_VEHICLE_TYPE } from '../../../constants/modalsContent';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../constants/support';
import { googleAnalyticsEvent } from '../../../utils/browser';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';


export const Step2AO = React.memo(({ data, user, stateKey }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage, formatNumber } = useIntl();

  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  const { packages } = useAOProductPackagesQuery();

  const [additionalRisksPackage, setAdditionalRisksPackage] = useState({
    value:
      ([NO_RISK_PACKAGE, ...packages.map(riskPackage => riskPackage.id)].includes(
        get(data, 'productPackage', NO_RISK_PACKAGE)
      ) &&
        get(data, 'productPackage', NO_RISK_PACKAGE)) ||
      NO_RISK_PACKAGE,
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.riskPackage" />,
    validation: {
      required: true,
      enum: [NO_RISK_PACKAGE, ...packages.map(riskPackage => riskPackage.id)],
    },
  });

  const displayStartDate = () => {
    if (get(data, 'insuranceStartDate', null)) {
      if (
        get(data, 'isRenewal', false) &&
        moment(data.insuranceStartDate).isSameOrAfter(moment().subtract(30, 'days'), 'day')
      ) {
        if (get(data, 'isSameOwner', true)) {
          return moment(data.insuranceStartDate);
        } else if (!moment(data.insuranceStartDate).isBefore(moment(), 'day')) {
          return moment(data.insuranceStartDate);
        }
      } else if (moment(data.insuranceStartDate).isSameOrAfter(moment(), 'day')) {
        return moment(data.insuranceStartDate);
      }
    }
    return null;
  };

  const [policyValidFrom, setPolicyValidFrom] = useState({
    value: displayStartDate(),
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.validFrom" />,
    validation: {
      required: true,
      date: {
        valid: true,
      },
    },
  });

  const [risksSum, setRisksSum] = useState({
    value: get(data, 'coverAdditionalData_HOK_AUTONEZGODA_OSIGURANA_SVOTA', '') || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.risksSum" />,
    validation: {
      required: true,
    },
  });

  const [tac, setTac] = useState({
    value: data.acceptedTerms || false,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.tac" />,
    validation: {
      isTrue: true,
    },
  });

  const [notEligible, setNotEligible] = useState(false);
  const [notEligibleReason, setNotEligibleReason] = useState('');

  const toggleEligibilityModal = () => {
    setNotEligible(currentState => !currentState);
  };

  const driverType = get(data, 'vehicle.vehicleType', OSOBNO_VOZILO) === OSOBNO_VOZILO ? VOZAC_AMATER : MOTOCIKL_AM;

  const [totalPremium, setTotalPremium] = useState(get(data, 'totalPremium', 0));
  const [productItems, setProductItems] = useState(get(data, 'productItems', []));

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(false);

  const toggleInfoModal = () => {
    setIsInfoModalOpen(prevState => !prevState);
  };

  const [isPackagesModalOpen, setIsPackagesModalOpen] = useState(false);

  const togglePackagesModal = useCallback(() => {
    setIsPackagesModalOpen(isOpen => !isOpen);
  }, []);

  const [validFromFocused, setValidFromFocused] = useState(false);

  const { allTypeDocuments } = useProductDocumentsQuery({ docType: DOC_TYPE_ALL, productCode: data.type });

  const [submitForm, { loading: submitLoading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data => history.push(`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_3}/${data.serverStorage.stateKey}`),
    onError: error => handleRequestError(error, dispatch),
  });

  const [deleteServerStorageRecord] = useMutation(SetServerStorageMutation, {
    variables: { stateKey, shouldDelete: true },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const [calculateAOPrice, { data: priceData, loading }] = useLazyQuery(GetAOPriceQuery, {
    onCompleted: data => {
      setTotalPremium(get(data, 'calculatePriceHokAO.totalPremium', 0));
      const calculationsForPackage = get(data, 'calculatePriceHokAO.calculationsForPackage', NO_RISK_PACKAGE);
      if (
        calculationsForPackage !== additionalRisksPackage.value &&
        packages.some(risksPackage => risksPackage.id === calculationsForPackage)
      ) {
        setAdditionalRisksPackage(o => ({ ...o, value: calculationsForPackage }));
      }
      setProductItems(get(data, 'calculatePriceHokAO.calculationItems', []));
      if (!get(data, 'calculatePriceHokAO.eligibleForProduct')) {
        const eligibleError = get(data, 'calculatePriceHokAO.eligibleError.key', '');
        if (eligibleError) {
          if (eligibleError === HOK_AUTO_NOT_SUPPORTED_VEHICLE_TYPE) {
            setNotEligibleReason(NOT_ELIGIBLE_AO_VEHICLE_TYPE);
          } else {
            const error = getSpecificError(eligibleError);
            setNotEligibleReason(error.message);
          }
          setNotEligible(true);
        }
      }
    },
    onError: error =>
      handleRequestError(error, dispatch, { silent: true }, () => {
        const queryError = getErrorObject(error);
        if (queryError && queryError.key === DUPLICATE_VIN_DETECTED) {
          setNotEligibleReason(queryError.message);
          setNotEligible(true);
        }
      }),
    fetchPolicy: 'network-only',
  });

  const eligibiliyErrorKey = get(data, 'calculatePriceHokAO.eligibleError.key', '');
  const previousPolicy = React.useMemo(
    () => get(priceData, 'calculatePriceHokAO.calculationsForNumberOfPreviousInsurancePolicy'),
    [priceData]
  );

  const { risks, risksLoading /*risksError*/ } = useAORisksQuery(get(data, 'vehicle.vehicleTypeCode', '01'));
  const { riskSums, riskSumsLoading /*riskSumsError*/ } = useAORiskSumsQuery({
    vehicleTypeCode: get(data, 'vehicle.vehicleType', OSOBNO_VOZILO),
    classCode: driverType,
  });

  const [selectedRisks, setSelectedRisks] = useState({});

  useEffect(() => {
    if (packages.length > 0) {
      setAdditionalRisksPackage(o => ({
        ...o,
        validation: { ...o.validation, enum: [NO_RISK_PACKAGE, ...packages.map(riskPackage => riskPackage.id)] },
      }));
    }
    if (riskSums.length > 0 && risksSum.value === '') {
      const defaultSum = riskSums.find(sum => sum.defaultSelected);
      setRisksSum(o => ({
        ...o,
        value: defaultSum ? defaultSum.sumEnumCode : riskSums[0].sumEnumCode,
        validation: { ...o.validation, enum: riskSums.map(riskSum => riskSum.sumEnumCode) },
      }));
    }
  }, [packages, riskSums, risksSum.value]);

  useEffect(() => {
    if (risks.length > 0 && Object.keys(selectedRisks).length < 1) {
      setSelectedRisks(() => {
        const risksObject = {};
        risks.forEach(risk => {
          const isRiskPreSelected = get(data, 'covers', []).includes(risk.id);
          risksObject[risk.id] = isRiskPreSelected || risk.defaultSelected;
        });
        return risksObject;
      });
    }
  }, [risks, selectedRisks, data]);

  useEffect(() => {
    if (!risksLoading) {
      const covers = Object.keys(selectedRisks).filter(riskId => selectedRisks[riskId]);
      const coversContainsAutoNezgoda = covers.includes(HOK_AUTONEZGODA);

      const getInsuranceStartDate = () => {
        if (policyValidFrom.value && policyValidFrom.value.isValid()) {
          return moment(policyValidFrom.value).isSameOrAfter(moment(), 'day') &&
            moment(policyValidFrom.value).isBefore(moment().add(1, 'hour'))
            ? moment().add(1, 'hour').toISOString()
            : moment(policyValidFrom.value).toISOString();
        }
        return null;
      };

      if (!coversContainsAutoNezgoda || (coversContainsAutoNezgoda && risksSum.value)) {
        calculateAOPrice({
          variables: {
            productRelatedData: {
              registrationPlate: data.registrationPlate,
              vin: data.vin,
              numberOfPreviousInsurancePolicy: data.numberOfPreviousInsurancePolicy,
              ...(policyValidFrom.value &&
                policyValidFrom.value.isValid() && { insuranceStartDate: getInsuranceStartDate() }),
              covers: Object.keys(selectedRisks).filter(riskId => selectedRisks[riskId]),
              ...(coversContainsAutoNezgoda && {
                coverAdditionalData_HOK_AUTONEZGODA_OSIGURANA_SVOTA: risksSum.value,
                coverAdditionalData_HOK_AUTONEZGODA_BROJ_PUTNIKA: get(data, 'vehicle.passengerNumber', 0),
                coverAdditionalData_HOK_AUTONEZGODA_TIP_VOZACA: driverType,
              }),
            },
          },
        });
      }
    }
  }, [selectedRisks, calculateAOPrice, data, risksSum.value, driverType, policyValidFrom.value, risksLoading]);

  const tacAcceptedFn = useCallback(value => setTac(tac => ({ ...tac, value })), []);
  const tacInvalidFn = useCallback(invalid => setTac(tac => ({ ...tac, invalid })), []);

  const tacLabelRender = useCallback(
    toggleTacModal => (
      <div className="tac-text-container">
        <span>
          Potvrđujem kako je ovaj proizvod u skladu s mojim zahtjevima i potrebama te da sam temeljem
          <span onClick={toggleTacModal} className="terms-and-conditions-text">
            predugovornih dokumenata i uvjeta osiguranja
          </span>{' '}
          donio informiranu odluku o kupnji proizvoda.
        </span>
      </div>
    ),
    []
  );

  const handleStep2Submit = async event => {
    event.preventDefault();

    const fieldsArray = [setAdditionalRisksPackage, setPolicyValidFrom, setTac, setRisksSum];

    if (
      (await validateFormSubmission(fieldsArray)) &&
      get(priceData, 'calculatePriceHokAO.eligibleForProduct', false)
    ) {
      const covers = Object.keys(selectedRisks).filter(riskId => selectedRisks[riskId]);
      const coversContainsAutoNezgoda = covers.includes(HOK_AUTONEZGODA);

      submitForm({
        variables: {
          attachToUser: true,
          state: {
            ...data,
            step: STEP_3,
            ...(policyValidFrom.value &&
              policyValidFrom.value.isValid() && { insuranceStartDate: policyValidFrom.value.toISOString() }),
            covers,
            productPackage:
              get(data, 'vehicle.vehicleType', OSOBNO_VOZILO) === OSOBNO_VOZILO
                ? additionalRisksPackage.value
                : NO_RISK_PACKAGE,
            ...(coversContainsAutoNezgoda && {
              coverAdditionalData_HOK_AUTONEZGODA_OSIGURANA_SVOTA: risksSum.value,
              coverAdditionalData_HOK_AUTONEZGODA_BROJ_PUTNIKA: get(data, 'vehicle.passengerNumber', 0),
              coverAdditionalData_HOK_AUTONEZGODA_TIP_VOZACA: driverType,
            }),
            totalPremium,
            productItems,
            acceptedTerms: tac.value,
          },
        },
      });
    }
  };

  const currentPackage = packages.find(risksPackage => risksPackage.id === additionalRisksPackage.value);
  const passengerNumber = get(data, 'vehicle.passengerNumber', 0);

  const setRiskPackageSubmitFunction = useCallback(
    cardId => {
      setAdditionalRisksPackage(o => ({ ...o, value: cardId, invalid: false, error: null }));
      const selectedPackage = packages.find(productPackage => productPackage.id === cardId);
      const newSelectedRisks = { ...selectedRisks };
      const selectedRisksKeys = Object.keys(selectedRisks);
      if (selectedPackage) {
        selectedRisksKeys.forEach(key => {
          if (selectedPackage.risksDefinitions.some(risksDef => risksDef.risk === key)) {
            newSelectedRisks[key] = true;
            if (key === HOK_AUTONEZGODA) {
              const risk = selectedPackage.risksDefinitions.find(risksDef => risksDef.risk === HOK_AUTONEZGODA);
              if (risk) {
                setRisksSum(o => ({ ...o, value: risk.sumEnumCode }));
              }
            }
          } else {
            newSelectedRisks[key] = false;
          }
        });
        setSelectedRisks(newSelectedRisks);
      } else if (cardId === NO_RISK_PACKAGE) {
        selectedRisksKeys.forEach(key => {
          newSelectedRisks[key] = false;
        });
      }
      setSelectedRisks(newSelectedRisks);
      togglePackagesModal();
    },
    [togglePackagesModal, packages, selectedRisks]
  );

  const getRiskPrice = useCallback(
    riskId => {
      const riskItem = productItems.find(item => item.riskEnumCode === riskId);
      if (riskItem && parseFloat(riskItem.value) > 0) {
        // if (currentPackage && currentPackage.risksDefinitions.some(riskDef => riskDef.risk === riskId)) {
        //   return null;
        // }
        //return formatNumber(riskItem.value, displayCurrencyOptions);
        return riskItem.value;
      }
      return null;
    },
    [productItems /* formatNumber */ /*, currentPackage*/]
  );

  const disablePackageSelectionButton = useCallback(
    card => {
      if (card.id !== NO_RISK_PACKAGE) {
        const autoNezgoda = card.risksDefinitions.find(riskDef => riskDef.risk === HOK_AUTONEZGODA);
        return autoNezgoda && autoNezgoda.numberOfTravelers !== passengerNumber;
      }
      return false;
    },
    [passengerNumber]
  );


  return (
    <>
      <Row className="my-4">
        <Col>
          <h3>
            <FormattedMessage id="userMyPoliciesCarLiability.insuranceDuration" />:
          </h3>
        </Col>
      </Row>
      <Form onSubmit={handleStep2Submit}>
        <Row>
          <Col sm="6" md="5">
            <Label for="validFrom">
              <FormattedMessage id="inputs.validFrom" />
            </Label>
            <InputGroup className={policyValidFrom.invalid ? 'invalid-date' : ''}>
              <SingleDatePicker
                id="validFrom"
                readOnly
                hideKeyboardShortcutsPanel
                date={policyValidFrom.value}
                // disabled={
                //   (get(data, 'isTheSameOwner', true) && get(data, 'isRenewal', false)) ||
                //   (!get(data, 'isTheSameOwner', true) &&
                //     get(data, 'isRenewal', false) &&
                //     get(data, 'isStartDayForced', true))
                // }
                onDateChange={date => setPolicyValidFrom({ ...policyValidFrom, invalid: false, value: date })}
                showDefaultInputIcon
                firstDayOfWeek={1}
                numberOfMonths={1}
                small
                focused={validFromFocused}
                onFocusChange={({ focused }) => setValidFromFocused(focused)}
                displayFormat={FORMAT_STANDARD_DATE}
                placeholder={formatMessage({ id: 'userMyPoliciesCarLiability.validFromPlaceholder' })}
                initialVisibleMonth={() => policyValidFrom.value || moment().add(1, 'hour')}
                isOutsideRange={date =>
                  date.isBefore(moment().add(1, 'hour'), 'day') || date.isAfter(moment().add(30, 'days'), 'day')
                }
                renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                  <div className="d-flex justify-content-center">
                    <div className="mr-1">
                      <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                        {moment.months().map((label, value) => (
                          <option value={value} key={label}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="ml-1">
                      <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                        {yearListRender(moment().format('YYYY'), moment().add(2, 'year').format('YYYY'))}
                      </select>
                    </div>
                  </div>
                )}
              />
              {policyValidFrom.invalid && policyValidFrom.error && (
                <FormFeedback className="d-block">{policyValidFrom.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
          <Col sm="6" md="5">
            <Label for="validTo">
              <FormattedMessage id="inputs.validTo" />
            </Label>
            <InputGroup>
              <Input
                id="validTo"
                readOnly
                placeholder={formatMessage({ id: 'userMyPoliciesCarLiability.validToPlaceholder' })}
                value={policyValidFrom.value ? formatDate(policyValidFrom.value.clone().add(1, 'year')) : ''}
              />
            </InputGroup>
          </Col>
        </Row>
        {get(data, 'vehicle.vehicleType', OSOBNO_VOZILO) === OSOBNO_VOZILO && (
          <>
            <Row className="mt-4">
              <Col sm="6" md="5" xl="5">
                <Label for="riskPackage">
                  <FormattedMessage id="inputs.riskPackage" />
                </Label>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm="6" md="5" xl="5">
                <InputGroup>
                  <CustomButtonArrowRight
                    id="riskPackage"
                    placeholder={`userMyPoliciesCarLiability.${additionalRisksPackage.value}`}
                    className="custom-header-text text-uppercase"
                    onClick={togglePackagesModal}
                  />
                  {additionalRisksPackage.invalid && additionalRisksPackage.error && (
                    <FormFeedback className="d-block">{additionalRisksPackage.error}</FormFeedback>
                  )}
                </InputGroup>
              </Col>
              {currentPackage && (
                <Col sm="6" md="7" className="d-flex align-items-center">
                  <p className="hok-text-dark">
                    {formatNumber(currentPackage.price, {
                      style: 'currency',
                      currency: 'EUR',
                      currencySign: 'standard',
                      currencyDisplay: 'narrowSymbol',
                    })}
                  </p>
                </Col>
              )}
            </Row>
          </>
        )}
        <Row className="my-4">
          <Col>
            <h3>
              <FormattedMessage id="userMyPolicies.additionalRisks" />:
            </h3>
          </Col>
        </Row>
        <Row className="mb-4 pl-sm-4">
          {risks.map(risk => (
            <RiskCheckbox
              risk={risk}
              toggleInfoModal={toggleInfoModal}
              setModalContent={setModalContent}
              checked={selectedRisks[risk.id] || false}
              disabled={
                ((risk.id === HOK_RENT_A_CAR || risk.id === HR_ASISTENCIJA) &&
                  selectedRisks[HOK_EXPRESS_ASISTENCIJA]) ||
                (risk.id === HOK_EXPRESS_ASISTENCIJA &&
                  (selectedRisks[HOK_RENT_A_CAR] || selectedRisks[HR_ASISTENCIJA]))
              }
              onChange={event => {
                event.persist();
                const newValue = event.target.checked;
                setSelectedRisks(o => ({ ...o, [risk.id]: event.target.checked }));

                if (additionalRisksPackage.value !== NO_RISK_PACKAGE && !newValue) {
                  const riskInPackage = packages
                    .find(riskPackage => riskPackage.id === additionalRisksPackage.value)
                    .risksDefinitions.some(riskDef => riskDef.risk === risk.id);
                  if (riskInPackage) {
                    setAdditionalRisksPackage(o => ({ ...o, value: NO_RISK_PACKAGE }));
                  }
                }
              }}
              price={!loading && additionalRisksPackage.value === NO_RISK_PACKAGE ? getRiskPrice(risk.id) : null}
              key={risk.id}
            />
          ))}
        </Row>
        <Fade in={selectedRisks[HOK_AUTONEZGODA]}>
          <Collapse isOpen={selectedRisks[HOK_AUTONEZGODA]}>
            <Row>
              <Col sm="6" lg="4" className="d-flex flex-column justify-content-end">
                <Label for="driverType">
                  <FormattedMessage id="inputs.driverType" />
                </Label>
                <InputGroup>
                  <Input
                    className="text-uppercase"
                    id="driverType"
                    disabled
                    defaultValue={formatMessage({ id: `userMyPoliciesCarLiability.${driverType}` })}
                  />
                </InputGroup>
              </Col>
              <Col sm="6" lg="4" className="d-flex flex-column justify-content-end">
                <Label for="passengerNumber">
                  <FormattedMessage id="inputs.passengerNumber" />
                </Label>
                <InputGroup>
                  <Input id="passengerNumber" disabled defaultValue={get(data, 'vehicle.passengerNumber', 0)} />
                </InputGroup>
              </Col>
              <Col sm="6" lg="4">
                <Label for="risksSum" className="text-break">
                  <FormattedMessage id="inputs.risksSum" />
                </Label>
                <InputGroup>
                  <div className="hok-select">
                    <CustomDashboardInput
                      id="risksSum"
                      fieldState={risksSum}
                      type="select"
                      onChange={event => {
                        setRisksSum({ value: event.target.value, invalid: false, error: '' });
                        if (additionalRisksPackage.value !== NO_RISK_PACKAGE && currentPackage) {
                          const accidentDefinition = currentPackage.risksDefinitions.find(
                            riskDef => riskDef.risk === HOK_AUTONEZGODA
                          );
                          if (accidentDefinition && accidentDefinition.sumEnumCode !== event.target.value) {
                            setAdditionalRisksPackage(o => ({ ...o, value: NO_RISK_PACKAGE }));
                          }
                        }
                      }}>
                      {riskSumsLoading ? (
                        <option disabled value="">
                          {formatMessage({ id: 'loading' })}
                        </option>
                      ) : (
                        riskSums.length > 0 &&
                        riskSums.map(option => (
                          <option key={option.id} value={option.sumEnumCode}>
                            {option.sumDesc} €
                          </option>
                        ))
                      )}
                    </CustomDashboardInput>
                  </div>
                </InputGroup>
              </Col>
            </Row>
          </Collapse>
        </Fade>
        <Row className="no-gutters">
          <Col lg="10">
            <TermsAndConditionsCheckbox
              setTacAccepted={tacAcceptedFn}
              tacAccepted={tac.value}
              tacInvalid={tac.invalid}
              setTacInvalid={tacInvalidFn}
              productDocs={allTypeDocuments}>
              {tacLabelRender}
            </TermsAndConditionsCheckbox>
          </Col>
        </Row>
        <Row className="no-gutters mt-4">
          <Col>
            <span className="primary">
              <FormattedMessage id="userMyPolicies.policyData.premium" />:
            </span>
            <span className="secondary ml-3">
              {formatNumber(totalPremium, {
                style: 'currency',
                currency: 'EUR',
                currencySign: 'standard',
                currencyDisplay: 'narrowSymbol',
              })}
            </span>

            {/* {currencyDataConfig.dualCurrencyDisplay === true && <>
              <span className="secondary ml-3">
              {displayTotalPremium(totalPremium)} {' '}
              |{' '}
              {currencyConversionEuro(totalPremium)}
            </span>
            </>}

            {currencyDataConfig.dualCurrencyDisplay === false && <>
              <span className="secondary ml-3">
                {displayTotalPremium(totalPremium)}
              </span>
            </>} */}

            {/* <span className="secondary ml-3">
              {displayTotalPremium(totalPremium)} | {currencyConversionEuro(totalPremium)}
            </span> */}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CustomButton
              className="hok-dashboard-btn-wide float-sm-right"
              translationId="goToPayment"
              loaderProp={submitLoading || loading}
              disabled={!get(priceData, 'calculatePriceHokAO.eligibleForProduct', false) || loading || submitLoading}
            />
          </Col>
        </Row>
      </Form>
      <ModalWithCardSelection
        showPrice
        rawCardNameFn={card => card.id !== NO_RISK_PACKAGE}
        disableButtonFn={disablePackageSelectionButton}
        cardsList={[{ id: NO_RISK_PACKAGE, name: `userMyPoliciesCarLiability.${NO_RISK_PACKAGE}` }, ...packages]}
        setFunction={setRiskPackageSubmitFunction}
        toggle={togglePackagesModal}
        isOpen={isPackagesModalOpen}
      />
      <UserInteractionModal
        wide
        isOpen={isInfoModalOpen}
        toggle={toggleInfoModal}
        titleId={`userMyPoliciesCarLiability.desc_${modalContent}`}
        textComponent={
          <div className="hok-package-data">
            <div className="insurance-package text-left">
              <DescriptionText value={modalContent} />
            </div>
          </div>
        }
      />
      <UserInteractionModal
        isOpen={notEligible}
        toggle={() => {
          toggleEligibilityModal();
        }}
        titleId={
          notEligibleReason === NOT_ELIGIBLE_AO_VEHICLE_TYPE
            ? 'modalHeaders.noSupportedVehicleType'
            : 'modalHeaders.warning'
        }
        textComponent={
          notEligibleReason === NOT_ELIGIBLE_AO_VEHICLE_TYPE ? (
            <DescriptionText value={notEligibleReason} data={get(data, 'vehicle.vehicleType', '')} />
          ) : (
            <Row className="no-gutters">
              <Col sm={{ size: 10, offset: 1 }}>
                <p className="mb-0 font-weight-bold text-center">Ugovaranje police nije moguće:</p>
                <p className="text-left">{notEligibleReason}</p>
              </Col>
            </Row>
          )
        }>
        {![HOK_AUTO_START_DATE_BEFORE_CURRENT_DATE_NEW, HOK_AUTO_START_DATE_AFTER_FORWARD_MAX_NEW].includes(
          eligibiliyErrorKey
        ) ? (
          <>
            <CustomButton
              className="hok-outline-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
              outline
              block={false}
              translationId="giveUp"
              onClick={() => {
                deleteServerStorageRecord();
                toggleEligibilityModal();
                history.push(ROUTE_TO_USER_POLICIES_PAGE);
              }}
            />
            <CustomButton
              className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
              block={false}
              translationId="requestOffer"
              onClick={() => {
                googleAnalyticsEvent({ category: 'Proizvodi', action: 'Proizvodi_Autoodgovornost_Zatrazi_Ponudu' });
                history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                  subject: ENQUIRY_SUBJECT_NEW_POLICY,
                  title: 'Zahtjev za ponudom "Autoodgovornost"',
                  content: `${previousPolicy ? `Obnova` : 'Kupnja nove'} police "Autoodgovornost"${
                    previousPolicy ? `. Broj prethodne police: ${previousPolicy}.` : ''
                  }`,
                });
              }}
            />
          </>
        ) : (
          <CustomButton
            className="hok-dashboard-btn"
            block={false}
            translationId="ok"
            onClick={() => {
              setPolicyValidFrom(o => ({ ...o, value: moment() }));
              toggleEligibilityModal();
            }}
          />
        )}
      </UserInteractionModal>
    </>
  );
});
