import gql from 'graphql-tag';

export const GetUserClaimsDefinitionsQuery = gql`
  query GetUserClaimsDefinitions($order: getClaimsDefinitionSortFilterInput) {
    getClaimsDefinition(filter: { active: true }, order: $order) {
      claims {
        id
        code
        book
        group
      }
    }
  }
`;

export const GetUserClaimsGroupsQuery = gql`
  query GetUserClaimsGroups($order: getClaimsDefinitionSortFilterInput) {
    getClaimsDefinition(filter: { active: true }, order: $order) {
      claimsGroupedByClaimsGroup {
        type
        groupOrder
        claims {
          id
          code
          book
          group
        }
      }
    }
  }
`;
