import { getLocalStorageGdprConsent } from '../constants/LocalStorageKeys';
import { analytics } from '../config/firebaseConfig';

export const browserLocale = () => {
  let lang = 'hr';

  if (global.navigator) {
    if (global.navigator.languages && global.navigator.languages.length) {
      // latest versions of Chrome and Firefox set this correctly
      lang = global.navigator.languages[0];
    } else if (global.navigator.userLanguage) {
      // IE only
      lang = global.navigator.userLanguage;
    } else {
      // latest versions of Chrome, Firefox, and Safari set this correctly
      lang = global.navigator.language;
    }
  }

  return lang.split('-')[0];
};

export const isDoNotTrackEnabled = () => {
  if (typeof window === 'undefined') return false;
  const { doNotTrack, navigator } = window;

  const msTracking = () => {
    const { external } = window;
    return (
      external &&
      'msTrackingProtectionEnabled' in external &&
      typeof external.msTrackingProtectionEnabled === 'function' &&
      window.external.msTrackingProtectionEnabled()
    );
  };

  // Do Not Track Settings across browsers
  const dnt = doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || msTracking();

  if (!dnt) return false;

  if (dnt === true || dnt === 1 || dnt === 'yes' || (typeof dnt === 'string' && dnt.charAt(0) === '1')) {
    return true;
  }
  return false;
};

export const googleAnalyticsEvent = (options = {}) => {
  if (analytics && !isDoNotTrackEnabled() && getLocalStorageGdprConsent()) {
    analytics.logEvent(options.action);
  }
};
