import React, { useState, useCallback } from 'react';
import { Row, Col, FormGroup, CustomInput, FormFeedback, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import { DescriptionText, CustomButton } from '../../containers';
import { ReactComponent as HokCloseIcon } from '../../img/icons/hok-close.svg';
import { DocumentLink } from '../DocumentLink';

export const TermsAndConditionsCheckbox = React.memo(
  ({
    setTacAccepted,
    tacAccepted,
    tacInvalid,
    setTacInvalid,
    modalText,
    children,
    productDocs = null,
    legalDocs = null,
  }) => {
    const [tacModal, setTacModal] = useState(false);

    const toggleTacModal = useCallback(() => {
      setTacModal(isOpen => !isOpen);
    }, []);

    const acceptTac = useCallback(() => {
      setTacAccepted(true);
      toggleTacModal();
    }, [setTacAccepted, toggleTacModal]);

    const renderTacModalBody = () => {
      if (productDocs) {
        return (
          <Row className="mt-5">
            {productDocs.PREDUGOVORNA_DOKUMENTACIJA.map(document => (
              <Col className="d-flex align-items-center mb-3" sm="6" key={document.documentId}>
                <DocumentLink content={document.content} link={document.link} />
              </Col>
            ))}
            {productDocs.UVJETI_OSIGURANJA.map(document => (
              <Col className="d-flex align-items-center mb-3" sm="6" key={document.documentId}>
                <DocumentLink content={document.content} link={document.link} />
              </Col>
            ))}
          </Row>
        );
      } else if (legalDocs) {
        return (
          <Row className="mt-5">
            {legalDocs.PREDUGOVORNA_DOKUMENTACIJA.map(document => (
              <Col className="d-flex align-items-center mb-3" sm="6" key={document.documentId}>
                <DocumentLink content={document.content} link={document.link} />
              </Col>
            ))}
          </Row>
        );
      } else {
        return (
          <Scrollbars autoHeight autoHeightMax={450}>
            <div className="pr-2">
              <DescriptionText value={modalText} />
            </div>
          </Scrollbars>
        );
      }
    };

    const renderTacModal = () => {
      return (
        <Modal className="hok-modal-wide hok-modal-tac" isOpen={tacModal} toggle={toggleTacModal}>
          <div className="header-container d-flex flex-row justify-content-between align-items-center">
            <ModalHeader>Uvjeti osiguranja i korištenja usluge</ModalHeader>
            <div>
              <HokCloseIcon className="svg-table-icons svg-red" onClick={toggleTacModal} />
            </div>
          </div>
          <ModalBody>
            {renderTacModalBody()}
            <div className="d-flex justify-content-center">
              <CustomButton onClick={acceptTac} className="mt-5" translationId="iAccept" />
            </div>
          </ModalBody>
        </Modal>
      );
    };

    return (
      <FormGroup row className="mt-4">
        {renderTacModal()}
        <Col className="d-flex flex-row" xs="12">
          <CustomInput
            className="hok-tac-checkbox"
            id="tac"
            type="checkbox"
            checked={tacAccepted}
            invalid={tacInvalid}
            onChange={event => {
              setTacAccepted(event.target.checked);
              setTacInvalid(false);
            }}
          />
          {children(toggleTacModal)}
        </Col>
        <FormFeedback className={'d-block pl-3' + (tacInvalid ? ' visible' : ' invisible')}>
          <FormattedMessage id="errors.invalidTac" />
        </FormFeedback>
      </FormGroup>
    );
  }
);
