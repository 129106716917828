import React from 'react';
import { useParams, useHistory, Redirect, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash-es';
import { StepperWrapper } from './StepperWrapper';
import { getStepNumberAndId } from '../../../../utils/manipulateSteps';
import { ReportDamageStep1 } from './ReportDamageStep1';
import { ReportDamageStep2 } from './ReportDamageStep2';
import { DamageFormData } from './DamageFromData';
import { damageClaimGroup } from '../../../../constants/claimDefs';
import { ROUTE_TO_USER_REPORT_DAMAGE } from '../../../../constants/routes';
import { GetServerStorageQuery } from '../../../../operations/queries/GetServerStorageQuery';

const DamageReportRoute = ({ activeStep, stepIndex, children, path, stateKey, damageClaimType }) => {
  const renderStep = stepIndex => {
    switch (stepIndex) {
      case 1:
        return `${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step2/${stateKey}`;
      case 0:
      default:
        return `${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step1`;
    }
  };

  if (activeStep >= stepIndex) {
    return <Route path={`${path}/${stateKey || ''}`}>{children}</Route>;
  }
  return <Redirect to={renderStep(activeStep)} />;
};

export const ReportDamageForm = () => {
  const history = useHistory();
  const params = useParams();
  const user = useSelector(state => state.auth.user);
  const { damageClaimType, damageReportStep } = params;

  const [groupName, pageName] = damageClaimGroup(params.damageClaimType);

  if (!groupName) {
    return <Redirect to={ROUTE_TO_USER_REPORT_DAMAGE} />;
  }

  return (
    <DamageFormData query={GetServerStorageQuery}>
      {({ data, error, stateKey }) => {
        const serverStep = get(data, 'state.step', 0);
        const stepData = get(data, 'state', {});

        const stepsArray = [
          {
            title: '',
            onClick: () => history.push(`${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step1/${stateKey}`),
          },
          {
            title: '',
            onClick: () => history.push(`${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step2/${stateKey}`),
          },
        ];

        if (error) {
          return <Redirect to={`${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step1`} />;
        }

        const { activeStep } = getStepNumberAndId(serverStep, error, damageReportStep, stepsArray.length, true);
        const stepProps = { activeStep, stateKey, damageClaimType };
        return (
          <StepperWrapper
            steps={stepsArray}
            activeStep={activeStep}
            translationBase={`userReportDamagePage.${pageName}`}
            title={activeStep === 1 ? 'documentation' : ''}
            cancelButton={true}
            cancelLink={`${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}`}>
            <Switch>
              <DamageReportRoute
                path={`${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step1`}
                {...stepProps}
                stepIndex={0}>
                <ReportDamageStep1 damageClaimType={damageClaimType} data={stepData} user={user} />
              </DamageReportRoute>
              <DamageReportRoute
                path={`${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step2`}
                {...stepProps}
                stepIndex={1}>
                <ReportDamageStep2 damageClaimType={damageClaimType} data={stepData} user={user} stateKey={stateKey} />
              </DamageReportRoute>
              <Redirect to={`${ROUTE_TO_USER_REPORT_DAMAGE}/${damageClaimType}/step1/${stateKey}`} />
            </Switch>
          </StepperWrapper>
        );
      }}
    </DamageFormData>
  );
};
