import React from 'react';
import { ReactComponent as HokDownloadIcon } from '../../img/icons/hok-download.svg';

export const DocumentLink = React.memo(({ link, content, showIcon = true }) => {
  const Tag = link ? 'a' : 'div';

  const props = {};
  if (link) {
    props.href = link;
    props.target = '_blank';
    props.rel = 'noopener noreferrer';
  }

  return (
    <Tag {...props} className="hok-document-link">
      {showIcon && (
        <div className="hok-document-link-icon mr-3">
          <HokDownloadIcon />
        </div>
      )}
      <span className="hok-document-link-content">{content}</span>
    </Tag>
  );
});
