import {
  POLICY_HEALTH,
  POLICY_HEALTH_TRAVEL,
  POLICY_CAR_LIABILITY,
  POLICY_KASKO,
  POLICY_ASSISTANCE,
  POLICY_PROPERTY,
  POLICY_ATTORNEY,
  POLICY_OTHER,
  MY_POLICIES_HEALTH_INSURANCE_PAGE,
  MY_POLICIES_TRAVEL_INSURANCE_PAGE,
  MY_POLICIES_CAR_LIABILITY_PAGE,
  MY_POLICIES_KASKO_PAGE,
  MY_POLICIES_ASSISTANCE_PAGE,
  MY_POLICIES_PROPERTY_PAGE,
  MY_POLICIES_ATTORNEY_PAGE,
  MY_POLICIES_LIABILITY_PAGE,
  MY_POLICIES_VESSELS_PAGE,
  MY_POLICIES_ACCIDENT_PAGE,
  MY_POLICIES_TRANSPORT_PAGE,
  MY_POLICIES_DRIVER_LIABILITY_PAGE,
  POLICY_LIABILITY,
  POLICY_VESSELS,
  POLICY_ACCIDENT,
  POLICY_TRANSPORT,
  POLICY_DRIVER_LIABILITY,
  ASSISTANCE_TRAVEL,
  ASSISTANCE_HOME,
  ASSISTANCE_EXPRESS,
  ASSISTANCE_RENTACAR,
  ASSISTANCE_HR,
  MY_ASSISTANCE_TRAVEL_PAGE,
  MY_ASSISTANCE_HOME_PAGE,
  MY_ASSISTANCE_AUTO_ASSIST_PAGE,
  MY_ASSISTANCE_RENTACAR_PAGE,
  MY_ASSISTANCE_EXPRESS_PAGE,
} from '../constants/business';

export const policiesGroup = path => {
  switch (path) {
    case MY_POLICIES_HEALTH_INSURANCE_PAGE:
      return [POLICY_HEALTH, 'userMyPoliciesHealthInsurance'];
    case MY_POLICIES_TRAVEL_INSURANCE_PAGE:
      return [POLICY_HEALTH_TRAVEL, 'userMyPoliciesTravelInsurance'];
    case MY_POLICIES_CAR_LIABILITY_PAGE:
      return [POLICY_CAR_LIABILITY, 'userMyPoliciesCarLiability'];
    case MY_POLICIES_KASKO_PAGE:
      return [POLICY_KASKO, 'userMyPoliciesKasko'];
    case MY_POLICIES_ASSISTANCE_PAGE:
      return [POLICY_ASSISTANCE, 'userMyPoliciesAssistance'];
    case MY_POLICIES_PROPERTY_PAGE:
      return [POLICY_PROPERTY, 'userMyPoliciesProperty'];
    case MY_POLICIES_ATTORNEY_PAGE:
      return [POLICY_ATTORNEY, 'userMyPoliciesAttorney'];
    case MY_POLICIES_LIABILITY_PAGE:
      return [POLICY_LIABILITY, 'userMyPoliciesLiability'];
    case MY_POLICIES_VESSELS_PAGE:
      return [POLICY_VESSELS, 'userMyPoliciesVessel'];
    case MY_POLICIES_ACCIDENT_PAGE:
      return [POLICY_ACCIDENT, 'userMyPoliciesAccident'];
    case MY_POLICIES_TRANSPORT_PAGE:
      return [POLICY_TRANSPORT, 'userMyPoliciesTransport'];
    case MY_POLICIES_DRIVER_LIABILITY_PAGE:
      return [POLICY_DRIVER_LIABILITY, 'userMyPoliciesDriverLiability'];
    default:
      return [POLICY_OTHER, 'userMyPoliciesOther'];
  }
};

export const assistanceGroup = path => {
  switch (path) {
    case MY_ASSISTANCE_TRAVEL_PAGE:
      return [ASSISTANCE_TRAVEL, 'travelAssistance'];
    case MY_ASSISTANCE_HOME_PAGE:
      return [ASSISTANCE_HOME, 'homeAssistance'];
    case MY_ASSISTANCE_AUTO_ASSIST_PAGE:
      return [ASSISTANCE_HR, 'carAssistance'];
    case MY_ASSISTANCE_RENTACAR_PAGE:
      return [ASSISTANCE_RENTACAR, 'HRRentACarAssistanceTitle'];
    case MY_ASSISTANCE_EXPRESS_PAGE:
      return [ASSISTANCE_EXPRESS, 'ExpressAssistanceTitle'];
    default:
      return [undefined, undefined];
  }
};
