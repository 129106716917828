import gql from 'graphql-tag';

export const GetUsersClaimsQuery = gql`
  query GetUsersClaims($filter: getClaimFilterInput, $userId: String, $order: getClaimSortFilterInput) {
    getClaims(filter: $filter, userId: $userId, order: $order) {
      totalCount
      queryCount
      claims {
        claimConfirmationCode
        claimClass
        claimNo
        claimDefinition {
          book
          group
        }
        dateOfReport
        status
        userFriendlyStatus
        role
        userFriendlyRole
      }
    }
  }
`;

export const GetUserClaimCommentsQuery = gql`
  query GetUsersClaimComments(
    $filter: getClaimCommentsFilterInput
    $order: getClaimCommentsSortFilterInput
    $userId: String
    $take: Int
    $skip: Int
  ) {
    getClaimComments(filter: $filter, order: $order, userId: $userId, take: $take, skip: $skip) {
      totalCount
      queryCount
      claimComments {
        id
        key
        userId
        comment
        createdAt
      }
    }
  }
`;

export const GetUserClaimFilesQuery = gql`
  query GetUserClaimFilesQuery(
    $filter: getClaimFilesFilterInput
    $order: getClaimFilesSortFilterInput
    $userId: String
  ) {
    getClaimFiles(filter: $filter, order: $order, userId: $userId) {
      claimFiles {
        claimId
        id
        name
        fileUrl
        key
        createdAt
      }
    }
  }
`;
