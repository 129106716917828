import { POLICY_OTHER } from '../constants/business';

export const formatParamsFromLink = link => {
  if (link) {
    const linkDivided = link.split('/');

    // internal://insurancePolicy/Imovina/MD/08-9000004088
    if (link.includes('insurancePolicy')) {
      const policyNumber = linkDivided[linkDivided.length - 1];
      const policyGroup = linkDivided[linkDivided.length - 3];

      return {
        policyGroup: policyGroup === 'null' || policyGroup === 'undefined' ? POLICY_OTHER : policyGroup,
        policyNumber,
      };
    }

    // internal://claim/ST10-1905001
    // internal://claim/12194KFEM
    if (link.includes('claim')) {
      const claimNum = linkDivided[3];

      if (claimNum.includes('-')) {
        const claimClass = claimNum.split('-')[0];
        const claimNo = claimNum.split('-')[1];

        return { claimClass, claimNo, file: linkDivided.includes('file') };
      } else {
        return { claimConfirmationCode: claimNum };
      }
    }

    // internal://ticket/${ticketNumber}
    if (link.includes('ticket')) {
      const ticketNumber = linkDivided[3];
      return { ticketNumber, file: linkDivided.includes('file') };
    }
  }
  return {};
};

export const encodeStringForUrl = value => {
  const newValue = value || '';
  if (newValue) {
    return newValue.replace(/ /g, '-').replace(/[&/\\#,+()$~%.'":?!@^_*=<>{}„“]/g, '');
  }
  return newValue;
};
