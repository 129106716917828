import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, Input } from 'reactstrap';
import { ScreenTitleContainer, Spinner } from '../../../containers';
import { FormattedMessage, useIntl } from 'react-intl';
import { get, debounce } from 'lodash-es';
import { ReactComponent as LogoShort } from '../../../img/logo-mini.svg';
import { ReactComponent as HokSearchIcon } from '../../../img/icons/hok-search.svg';
import { FAQCard } from './FAQCard';
import { useQuery } from 'react-apollo';
import { GetFAQsQuery } from '../../../operations/queries/GetFAQsQuery';
import { handleRequestError } from '../../../constants/errorCodes';
import { ROUTE_TO_USER_FAQS_PAGE } from '../../../constants/routes';
import { encodeStringForUrl } from '../../../utils/internalParsing';

export const FAQPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { data, loading, error, refetch } = useQuery(GetFAQsQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    notifyOnNetworkStatusChange: true,
  });

  const debouncedFilterFAQs = debounce(variables => refetch(variables), 750);

  const faqGroups = get(data, 'FAQs.FAQsGroupedByQuestionGroup', []);

  return (
    <Container fluid className="hok-container">
      <ScreenTitleContainer row titleId="userFAQsPage.title" />
      <Row className="no-gutters">
        <Col className="text-center">
          <LogoShort className="hok-logo-red-short" />
          <h5 className="hok-faq-subtitle">
            <FormattedMessage id="userFAQsPage.howCanWeHelp" />
          </h5>
        </Col>
      </Row>

      <Row className="no-gutters my-5">
        <Col>
          <Card className="hok-faq-container">
            <CardHeader className="py-0" style={{ height: 50 }}>
              <div className="d-flex align-items-center h-100">
                <HokSearchIcon className="svg-red" height="20" width="20" />
                <Input
                  className="hok-faq-search-input"
                  placeholder={formatMessage({ id: 'userFAQsPage.searchPlaceholder' })}
                  onChange={event => {
                    debouncedFilterFAQs({ filter: { search: event.target.value } });
                  }}
                />
              </div>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      {loading || (!data && !error) ? (
        <Row className="no-gutters mt-4">
          <Col>
            <Spinner className="spinner-positioning" />
          </Col>
        </Row>
      ) : (
        faqGroups.map(group => (
          <Row className="no-gutters my-4" key={group.groupName}>
            <Col>
              <FAQCard mainTitle={group.groupName}>
                {group.FAQs.map(question => (
                  <Link
                    to={`${ROUTE_TO_USER_FAQS_PAGE}/${question.id}/${encodeStringForUrl(question.subject)}`}
                    className="hok-faq-question"
                    key={question.id}>
                    {question.subject}
                  </Link>
                ))}
              </FAQCard>
            </Col>
          </Row>
        ))
      )}
    </Container>
  );
};
