import gql from 'graphql-tag';

export const UpdateUserMutation = gql`
  mutation UpdateUser(
    $id: Int!
    $username: String!
    $password: String
    $role: String!
    $firstName: String!
    $lastName: String!
    $title: String
    $email: String!
    $avatar: String
    $accountLocked: Int
  ) {
    updateUser(
      id: $id
      username: $username
      password: $password
      role: $role
      firstName: $firstName
      lastName: $lastName
      title: $title
      email: $email
      avatar: $avatard
      accountLocked: $accountLocked
    ) {
      id
    }
  }
`;

export default UpdateUserMutation;
