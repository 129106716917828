import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { ContentContainer, ContentIconBox } from '../../../containers';
import { USER_ACCOUNT_TYPES } from '../../../constants/userDefs';
import { redirectToUserAccountInfoPage } from '../../../redux/actions/navigationActions';
import step1Img from '../../../img/stepper/step1.svg';
import { Mutation } from 'react-apollo';
import { UserUpdateSectorMutation } from '../../../operations/mutations/UserUpdateProfileMutation';
import { LogoutLinkRow } from '../../../components/LogoutLink';

const UserTypeSelection = () => {
  const dispatch = useDispatch();

  return (
    <ContentContainer titleImage={step1Img} footerImage>
      <LogoutLinkRow />
      <Row className="no-gutters mt-3">
        <Col className="text-center">
          <h2>
            <FormattedMessage id="userTypeSelectPage.title" />
          </h2>
        </Col>
      </Row>
      <Row className="main-content">
        <Mutation
          mutation={UserUpdateSectorMutation}
          onCompleted={data => {
            const sector = data.updateProfile.profile.sector;
            dispatch(redirectToUserAccountInfoPage(sector));
          }}
        >
          {setSector => {
            return (
              <Col xs="12" className="text-center">
                {USER_ACCOUNT_TYPES.map(({ Icon, name }) => (
                  <ContentIconBox
                    key={name}
                    Icon={Icon}
                    name={`icons.${name}`}
                    onClick={() => setSector({ variables: { sector: name } })}
                  />
                ))}
              </Col>
            );
          }}
        </Mutation>
      </Row>
    </ContentContainer>
  );
};

export default UserTypeSelection;
