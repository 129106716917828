const calcVersion = () => {
  // production build
  if (process.env.NODE_ENV === 'production') {
    // bitbucket build
    if (process.env.REACT_APP_BB_BUILD_NUMBER && process.env.REACT_APP_BB_COMMIT && process.env.REACT_APP_BB_BRANCH) {
      const commitShort = process.env.REACT_APP_BB_COMMIT.slice(0, 8);
      // master branch
      if (process.env.REACT_APP_BB_BRANCH === 'master') {
        return `v${process.env.REACT_APP_VERSION}-#${process.env.REACT_APP_BB_BUILD_NUMBER}`;
      }
      // dev branch
      else if (process.env.REACT_APP_BB_BRANCH === 'dev') {
        return `#${process.env.REACT_APP_BB_BUILD_NUMBER}-${commitShort}`;
      }
      // any other branch
      else {
        return `${process.env.REACT_APP_BB_BRANCH}-#${process.env.REACT_APP_BB_BUILD_NUMBER}-${commitShort}`;
      }
    }
    // non-bitbucket build
    else {
      return ``;
    }
  }
  // development build
  else {
    return `hotbuild`;
  }
};

const buildVersion = calcVersion();
module.exports = { buildVersion };
