import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash-es';
import UserProfile from './UserProfileContainer';
import { ProfileAdvancePrivate } from './ProfileAdvancedPrivate';
import { ProfileAdvanceBusiness } from './ProfileAdvancedBusiness';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';

export const UserProfileAdvanced = () => {
  const user = useSelector(state => state.auth.user);
  const changeAdvanedDataInProgress = useSelector(state => state.userProfile.changeAdvanedDataInProgress);
  const sector = get(user, 'sector', null);

  const dispatch = useDispatch();

  return (
    <UserProfile>
      {sector === USER_ACCOUNT_BUSINESS ? (
        <ProfileAdvanceBusiness
          user={user}
          changeAdvanedDataInProgress={changeAdvanedDataInProgress}
          dispatch={dispatch}
        />
      ) : (
        <ProfileAdvancePrivate
          user={user}
          changeAdvanedDataInProgress={changeAdvanedDataInProgress}
          dispatch={dispatch}
        />
      )}
    </UserProfile>
  );
};
