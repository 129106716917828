import gql from 'graphql-tag';

export const GetClaimDocumentationGroupedQuery = gql`
  query GetClaimDocumentationGrouped(
    $filter: getReportedClaimDocumentsFilterInput
    $order: getReportedClaimDocumentsSortFilterInput
  ) {
    getReportClaimDocuments(filter: $filter, order: $order) {
      documentsGroupedByClaimCode {
        type
        documents {
          reportType
          documentId
          documentName
          required
          type
          link
          content
          active
          order
          mimeType
          shasum
          status
        }
      }
    }
  }
`;
