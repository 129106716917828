import gql from 'graphql-tag';

export const SetServerStorageMutation = gql`
  mutation SetServerStorage(
    $stateKey: String
    $state: JSON
    $attachToUser: Boolean
    $userId: String
    $shouldDelete: Boolean
  ) {
    serverStorage(
      stateKey: $stateKey
      state: $state
      attachToUser: $attachToUser
      userId: $userId
      shouldDelete: $shouldDelete
    ) {
      stateKey
      newState
    }
  }
`;
