import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Label, InputGroup, Input } from 'reactstrap';
import { useMutation, useLazyQuery } from 'react-apollo';
import moment from 'moment';
import { get } from 'lodash-es';
import { PaymentStep } from '../../../components/PolicyContractingSteps/PaymentStep';
import {
  ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_4,
  ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE,
} from '../../../constants/routes';
import { BuyEurekaProductMutation } from '../../../operations/mutations/BuyProductMutation';
import { handleRequestError } from '../../../constants/errorCodes';
import { GetPaymentStatusQuery } from '../../../operations/queries/GetPaymentStatusQuery';
import { PaymentStatusSubscription } from '../../../operations/subscriptions/PaymentStatusSubscription';
import {
  PAYMENT_STATUS_CANCELED,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_ERROR,
  INSURED_PERSON_DATA,
} from '../../../constants/business';
import { FormattedMessage, useIntl } from 'react-intl';
import { CollapsibleTextInfo, DescriptionText } from '../../../containers';
import { formatDate } from '../../../constants/dateFormatting';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';

export const Step3Health = ({ data, stateKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const win = useRef(null);
  const { formatMessage } = useIntl();
  const newWindowTitle = formatMessage({ id: 'loading' });

  const currentDate = new Date().toISOString();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [getPaymentStatus, { data: paymentData, subscribeToMore }] = useLazyQuery(GetPaymentStatusQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });
  const [deleteServerStorageRecord] = useMutation(SetServerStorageMutation, {
    variables: { stateKey, shouldDelete: true },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  useEffect(() => {
    window.addEventListener('storage', () => {
      let _tabToggleState = localStorage.getItem('tabStateStorage');
      if(_tabToggleState == "true"){
        history.push('/dashboard');
      }
    })
  }, [history])

  useEffect(() => {
    if (get(paymentData, 'getPaymentData.confirmationCode', '')) {
      subscribeToMore({
        document: PaymentStatusSubscription,
        variables: { confirmationCode: paymentData.getPaymentData.confirmationCode },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return { getPaymentData: subscriptionData.data.payments };
        },
        onError: error => handleRequestError(error, dispatch, { silent: true }),
      });
    }

    if (get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_PAID) {
      deleteServerStorageRecord();
      history.push(`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_4}/${paymentData.getPaymentData.confirmationCode}`);
    }
    if (
      get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_CANCELED ||
      get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_ERROR
    ) {
      setButtonDisabled(false);
    }
  }, [dispatch, history, paymentData, subscribeToMore, deleteServerStorageRecord]);

  const [buyEureka] = useMutation(BuyEurekaProductMutation, {
    variables: {
      productRelatedData: {
        productPackage: data.productPackage,
        insuredPeople: data.insuredPeople.map(person => ({
          name: person.name,
          surename: person.surename,
          dateOfBirth: person.dateOfBirth,
          oib: person.oib,
          address: person.address,
          zip: person.zip,
          city: person.city,
          mbHzzo: person.mbHzzo,
          sex: person.sex,
        })),
        insuranceStartDate: currentDate,
      },
    },
    onError: error => {
      win.current.close();
      setButtonDisabled(false);
      handleRequestError(error, dispatch);
    },
    onCompleted: data => {
      const confirmationCode = get(data, 'buyInsurancePolicyHokEureka.confirmationCode', null);
      if (confirmationCode) {
        getPaymentStatus({ variables: { confirmationCode } });
        // Workaround to not trigger popup blocker due to window.open() not being called
        // immediately after user action (onClick event) but after an async function
        win.current.location = `${ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE}/${confirmationCode}`;
      }
    },
  });

  const onClick = useCallback(() => {
    win.current = window.open();
    win.current.document.title = newWindowTitle;
    setButtonDisabled(true);
    buyEureka();
  }, [buyEureka, newWindowTitle]);

  return (
    <PaymentStep
      policyTranslationBase="userMyPoliciesHealthInsurance"
      premiumAmount={data.totalPremium}
      nextStep={ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_4}
      data={data}
      onClick={onClick}
      loaderProp={buttonDisabled}>
      <Row className="mt-4 mb-3">
        <Col>
          <h3>
            <FormattedMessage id="userMyPolicies.policyData.title" />:
          </h3>
        </Col>
      </Row>
      <Row>
        <Col sm="6" lg="4">
          <Label for="validFrom">
            <FormattedMessage id="inputs.validFrom" />
          </Label>
          <InputGroup>
            <Input id="validFrom" disabled defaultValue={formatDate(currentDate)} />
          </InputGroup>
        </Col>
        <Col sm="6" lg="4">
          <Label for="validTo">
            <FormattedMessage id="inputs.validTo" />
          </Label>
          <InputGroup>
            <Input
              id="validTo"
              disabled
              defaultValue={formatDate(
                moment(currentDate)
                  .add(1, 'years')
                  .toISOString()
              )}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        {data.insuredPeople.map(person => (
          <Col sm="12" md="6" key={person.oib}>
            <Label>
              <FormattedMessage id="userMyPolicies.policyData.policyPersonInsured" />
            </Label>
            <CollapsibleTextInfo rawPlaceholder pushdown uppercase placeholder={`${person.name} ${person.surename}`}>
              <Container>
                <Row>
                  <Col xs={12}>
                    <DescriptionText value={INSURED_PERSON_DATA} data={person} showHzzo />
                  </Col>
                </Row>
              </Container>
            </CollapsibleTextInfo>
          </Col>
        ))}
      </Row>
    </PaymentStep>
  );
};
