import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Row, Col, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HokChevronUpIcon } from '../../img/icons/hok-chevron-up.svg';
import { ReactComponent as HokChevronDownIcon } from '../../img/icons/hok-chevron-down.svg';

export const TabsNavigation = ({ baseRoute, itemId, navTabs = [], children, styleBreakpoint = 'lg' }) => {
  const [isDropdownOpen, toggleDropdown] = useState(false);

  const toggle = () => {
    toggleDropdown(!isDropdownOpen);
  };

  const navItems = navTabs.map(tab => ({ name: tab.name, route: `${baseRoute}/${itemId}${tab.route}` }));

  const renderTabsNavigation = () => (
    <Nav tabs className={`hok-user-profile-tabs d-none d-${styleBreakpoint}-flex`}>
      {navItems.map(tab => (
        <NavItem key={tab.name}>
          <NavLink exact tag={Link} to={tab.route}>
            <FormattedMessage id={tab.name} />
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  const renderSmallScreenTabsNav = () => (
    <Nav tabs className={`hok-user-profile-tabs d-flex d-${styleBreakpoint}-none`}>
      <Dropdown nav isOpen={isDropdownOpen} toggle={toggle} className="hok-dropdown-tab-full">
        <DropdownToggle nav className="d-flex align-items-center justify-content-between">
          <FormattedMessage id={`userProfilePage.tabsNav.dropdownMenu`} />
          <div>{isDropdownOpen ? <HokChevronUpIcon /> : <HokChevronDownIcon />}</div>
        </DropdownToggle>
        <DropdownMenu>
          {navItems.map(tab => (
            <DropdownItem exact key={tab.name} tag={Link} to={tab.route}>
              <FormattedMessage id={tab.name} />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );

  return (
    <Row className="no-gutters">
      <Col xs="12" xl={{ size: 10, offset: 1 }}>
        {renderTabsNavigation()}
        {renderSmallScreenTabsNav()}
        {children}
      </Col>
    </Row>
  );
};
