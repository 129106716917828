import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, FormGroup, Label, InputGroup, FormFeedback, Input, CustomInput } from 'reactstrap';
import { useMutation, useLazyQuery } from 'react-apollo';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import PhoneInput from 'react-phone-number-input';
import { get, debounce } from 'lodash-es';
import moment from 'moment';
import { CustomDashboardInput, CustomButton, CollapsibleContainer, Spinner } from '../../../../containers';
import {
  USER_ACCOUNT_TYPES,
  USER_ACCOUNT_BUSINESS,
  USER_GENDER,
  USER_ACCOUNT_PRIVATE,
} from '../../../../constants/userDefs';
import { yearListRender, FORMAT_STANDARD_DATE } from '../../../../constants/dateFormatting';
import { validateFormSubmission } from '../../../../utils/validation';
import { ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT } from '../../../../constants/routes';
import { usePlacesQuery, useLegalDocumentsQuery, useGoogleAnalyticsPageview } from '../../../../utils/customHooks';
import { CLAIM_CAR_LIABILITY } from '../../../../constants/claimDefs';
import { SetServerStorageMutation } from '../../../../operations/mutations/SetServerStorageMutation';
import { handleRequestError } from '../../../../constants/errorCodes';
import { CheckPolicyValidityQuery } from '../../../../operations/queries/CheckPolicyValidityQuery';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';
import { INFORMACIJE_O_OBRADI_PODATAKA } from '../../../../constants/documentTypes';
import { v4 } from 'uuid';

export const QuickReportStep1 = ({ data = {} }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const history = useHistory();

  useGoogleAnalyticsPageview('/BrzaPrijavaŠtete_Autoodgovornost_Step1');

  const { places, loading: loadingPlaces } = usePlacesQuery();
  const { legalDocuments } = useLegalDocumentsQuery(INFORMACIJE_O_OBRADI_PODATAKA);

  const [submitForm, { loading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data =>
      history.push(`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step2/${data.serverStorage.stateKey}`),
    onError: error => handleRequestError(error, dispatch),
  });

  const [reportingPolicyNumberStatus, setReportingPolicyNumberStatus] = useState({
    invalid: false,
    error: '',
    valid: false,
  });

  const [reportingPolicyOwnerOibStatus, setReportingPolicyOwnerOibStatus] = useState({
    invalid: false,
    error: '',
    valid: false,
  });

  const [getPolicyValidity, { loading: checkLoading, data: policyData }] = useLazyQuery(CheckPolicyValidityQuery, {
    onCompleted: data => {
      const policyData = get(data, 'checkIfInsurancePolicyIsValid.productDefinition', null);
      const isOibValid = get(data, 'checkIfInsurancePolicyIsValid.isOibValid', false);
      if (!policyData || !get(policyData, 'active', false) || get(policyData, 'group', '') !== CLAIM_CAR_LIABILITY) {
        setReportingPolicyNumberStatus({
          invalid: true,
          error: <FormattedMessage id="errors.invalidPolicyNumber" />,
          valid: false,
        });
      } else {
        reportingPolicyNumber.current.invalid = false;
        reportingPolicyNumber.current.error = '';
        setReportingPolicyNumberStatus({
          invalid: false,
          error: '',
          valid: true,
        });

        if (reportingPolicyOwnerOib.current.value && !isOibValid) {
          setReportingPolicyOwnerOibStatus({
            invalid: true,
            error: <FormattedMessage id="errors.invalidOibNoMatch" />,
            valid: false,
          });
        }

        if (isOibValid) {
          setReportingPolicyOwnerOibStatus({
            invalid: false,
            error: '',
            valid: true,
          });
        }
      }
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    fetchPolicy: 'network-only',
  });

  const insuredPersonData = get(policyData, 'checkIfInsurancePolicyIsValid.insurancePolicyUserDataViaOib', null);
  const isOibValid = get(policyData, 'checkIfInsurancePolicyIsValid.isOibValid', false);
  const claimClass = get(policyData, 'checkIfInsurancePolicyIsValid.productDefinition.claimBook', '');
  const productDefinition = get(policyData, 'checkIfInsurancePolicyIsValid.productDefinition', null);

  const debouncedPolicyValidityCheck = debounce(variables => getPolicyValidity(variables), 750);
  const debouncedOIBValidityCheck = debounce(async variables => {
    if (await validateFormSubmission([setReportingPolicyOwnerOib])) {
      if (reportingPolicyNumberStatus.valid || (!policyData && reportingPolicyNumber.current.value)) {
        getPolicyValidity(variables);
      }
    } else {
      setReportingPolicyOwnerOibStatus({
        invalid: reportingPolicyOwnerOib.current.invalid,
        error: reportingPolicyOwnerOib.current.error,
        valid: false,
      });
    }
  }, 750);

  const [policyNumber, setPolicyNumber] = useState({
    value: get(data, 'insured.insurancePolicyNumberAO', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.damagedPolicyNumber" />,
    validation: {},
  });

  const reportingPolicyNumber = useRef({
    value: get(data, 'insurancePolicyNumber', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.policyNumber" />,
    validation: {
      required: true,
    },
  });
  const setReportingPolicyNumber = cb => {
    reportingPolicyNumber.current = cb(reportingPolicyNumber.current);
  };

  const [oib, setOIB] = useState({
    value: get(data, 'insured.oib', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: true,
      oib: true,
    },
  });

  const [tac, setTac] = useState({
    value: get(data, 'tac', false),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.tac" />,
    validation: {
      isTrue: true,
    },
  });

  const reportingPolicyOwnerOib = useRef({
    value: get(data, 'insurancePolicyOib', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.reportingPolicyOwnerOib" />,
    validation: {
      required: true,
      oib: true,
    },
  });
  const setReportingPolicyOwnerOib = cb => {
    reportingPolicyOwnerOib.current = cb(reportingPolicyOwnerOib.current);
  };

  const [firstName, setFirstName] = useState({
    value: get(data, 'insured.firstName', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {
      required: true,
    },
  });
  const [lastName, setLastName] = useState({
    value: get(data, 'insured.lastName', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {
      required: true,
    },
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value: get(data, 'insured.dateOfBirth', null) ? moment(get(data, 'insured.dateOfBirth', null)) : null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.dateOfBirth" />,
    validation: {
      required: true,
    },
  });
  const [sex, setSex] = useState({
    value: get(data, 'insured.sex', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sex" />,
    validation: {
      required: true,
      enum: USER_GENDER.map(gender => gender.value),
    },
  });
  const [address, setAddress] = useState({
    value: get(data, 'insured.address', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });
  const [zip, setZip] = useState({
    value: get(data, 'insured.zip', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
      enum: places.map(place => place.pttNumber),
    },
  });
  const [city, setCity] = useState({
    value: get(data, 'insured.city', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [email, setEmail] = useState({
    value: get(data, 'insured.email', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.email" />,
    validation: {
      required: true,
      email: true,
    },
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: get(data, 'insured.phoneNumber', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {
      required: true,
    },
  });
  const [sector, setSector] = useState({
    value: get(data, 'insured.sector', USER_ACCOUNT_PRIVATE),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sector" />,
    validation: {
      required: true,
      enum: USER_ACCOUNT_TYPES.map(type => type.name),
    },
  });
  const [IBAN, setIBAN] = useState({
    value: get(data, 'reporterIBAN', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.IBAN" />,
    validation: {},
  });
  const [businessName, setBusinessName] = useState({
    value: get(data, 'insured.businessName', ''),
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.name" />,
    validation: {
      required: true,
    },
  });

  const [note, setNote] = useState({
    value: get(data, 'note', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.note" />,
    validation: {
      required: false,
    },
  });

  const [dateOfBirthFocused, setDateOfBirthFocused] = useState(false);

  const [filteredPlaces, setFilteredPlaces] = useState([...places]);

  useEffect(() => {
    if (places.length > 0) {
      setFilteredPlaces([...places]);
    }
  }, [places]);

  const debouncedPlacesFilter = debounce(value => {
    const matchingPlaces = places.filter(place => place.pttNumber.startsWith(value));
    setFilteredPlaces(value ? matchingPlaces : [...places]);
    if (matchingPlaces.length === 1 && matchingPlaces[0].pttNumber === value) {
      setCity(city => ({ ...city, invalid: false, value: matchingPlaces[0].name }));
    } else {
      setCity(city => ({ ...city, invalid: false, value: '' }));
    }
  }, 500);

  useEffect(() => {
    if (places.length > 0 && city.validation.enum.length < 1) {
      setCity(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
    }
    if (places.length > 0 && zip.validation.enum.length < 1) {
      setZip(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
    }
  }, [places, city, zip]);

  useEffect(() => {
    if (reportingPolicyNumber.current.value) {
      getPolicyValidity({
        variables: {
          insurancePolicyNumber: reportingPolicyNumber.current.value,
          insurancePolicyOib: reportingPolicyOwnerOib.current.value,
        },
      });
    }
  }, [getPolicyValidity]);

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false, error: null }));
  };

  const reportDamangeStep1SubmitHandler = async event => {
    event.preventDefault();

    const fieldsArray = [
      setReportingPolicyNumber,
      setReportingPolicyOwnerOib,
      setNote,
      setTac,
      setOIB,
      setAddress,
      setCity,
      setZip,
      setPhoneNumber,
      setEmail,
      setPolicyNumber,
      setIBAN,
    ];

    if (sector.value === USER_ACCOUNT_BUSINESS) {
      fieldsArray.push(setBusinessName);
    } else {
      fieldsArray.push(setFirstName, setLastName, setDateOfBirth, setSex);
    }

    if (
      (await validateFormSubmission(fieldsArray)) &&
      reportingPolicyNumberStatus.valid &&
      reportingPolicyOwnerOibStatus.valid &&
      insuredPersonData &&
      isOibValid &&
      productDefinition
    ) {
      const variables = {
        reporterEmail: email.value,
        reporterIBAN: IBAN.value,
        insurancePolicyNumber: reportingPolicyNumber.current.value,
        insurancePolicyOib: reportingPolicyOwnerOib.current.value,
        claimClass,
        insured: {
          ...(sector.value === USER_ACCOUNT_BUSINESS
            ? {
                businessName: businessName.value,
              }
            : {
                firstName: firstName.value,
                lastName: lastName.value,
                dateOfBirth: dateOfBirth.value,
                sex: sex.value,
              }),
          insurancePolicyNumberAO: policyNumber.value,
          sector: sector.value,
          oib: oib.value,
          zip: zip.value,
          address: address.value,
          city: city.value,
          phoneNumber: phoneNumber.value,
          email: email.value,
        },
        peril: {
          ...(insuredPersonData.sector === USER_ACCOUNT_BUSINESS
            ? {
                businessName: insuredPersonData.name,
              }
            : {
                firstName: insuredPersonData.firstName,
                lastName: insuredPersonData.lastName,
              }),
          oib: reportingPolicyOwnerOib.current.value,
        },
        note: note.value,
        tac: tac.value,
      };

      submitForm({
        variables: {
          state: {
            ...data,
            step: 'STEP_2',
            ...variables,
          },
        },
      });
    }
  };

  const renderPerilHeader = ({ sector, businessName, firstName, lastName }, placeholder) => {
    if (sector === USER_ACCOUNT_BUSINESS) {
      return `${businessName || ''}`.trim() || formatMessage({ id: `userReportDamagePage.${placeholder}` });
    } else {
      return (
        `${firstName || ''} ${lastName || ''}`.trim() || formatMessage({ id: `userReportDamagePage.${placeholder}` })
      );
    }
  };

  const isDamagedPersonInfoInvalid =
    (sector.value === USER_ACCOUNT_PRIVATE &&
      (firstName.invalid || lastName.invalid || dateOfBirth.invalid || sex.invalid)) ||
    (sector.value === USER_ACCOUNT_BUSINESS && businessName.invalid) ||
    sector.invalid ||
    IBAN.invalid ||
    oib.invalid ||
    address.invalid ||
    city.invalid ||
    zip.invalid ||
    email.invalid ||
    phoneNumber.invalid;

  const isPerilPersonInfoInvalid =
    !insuredPersonData ||
    reportingPolicyNumber.current.invalid ||
    reportingPolicyNumberStatus.invalid ||
    reportingPolicyOwnerOib.invalid ||
    reportingPolicyOwnerOibStatus.invalid;

  return (
    <Form onSubmit={reportDamangeStep1SubmitHandler}>
      <Row className="no-gutters my-4">
        <Col>
          <p className="hok-text-dark">
            <span className="font-weight-bold text-uppercase">NAPOMENA:</span> šteta koju prijavljujete se prijavljuje
            po polici Autoodgovnornosti štetnika ugovorenoj unutar HOK osiguranja.
          </p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="12">
          <Label>
            <FormattedMessage id="userReportDamagePage.damagedParty" />
          </Label>
          <CollapsibleContainer
            expanded
            title={renderPerilHeader(
              {
                sector: sector.value,
                businessName: businessName.value,
                firstName: firstName.value,
                lastName: lastName.value,
              },
              'damagedParty'
            )}
            invalid={isDamagedPersonInfoInvalid}
          >
            <FormGroup row className="mt-4">
              <Col sm="6" md="4">
                <Label for="policyNumber">
                  <FormattedMessage id="inputs.damagedPolicyNumber" />
                </Label>
                <CustomDashboardInput
                  id="policyNumber"
                  fieldState={policyNumber}
                  onChange={event => inputHandler(setPolicyNumber, event.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="6" md="4">
                <Label for="sector">
                  <FormattedMessage id="inputs.sector" />
                </Label>
                <InputGroup>
                  <div className="hok-select">
                    <CustomDashboardInput
                      id="sector"
                      type="select"
                      value={sector.value}
                      fieldState={sector}
                      onChange={event => inputHandler(setSector, event.target.value)}
                    >
                      {USER_ACCOUNT_TYPES.map(type => (
                        <option key={type.name} value={type.name}>
                          {formatMessage({ id: `accountType.${type.name}` })}
                        </option>
                      ))}
                    </CustomDashboardInput>
                  </div>
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for="IBAN">
                  <FormattedMessage id="inputs.IBAN" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="IBAN"
                    value={IBAN.value}
                    fieldState={IBAN}
                    onChange={event => inputHandler(setIBAN, event.target.value)}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              {sector.value === USER_ACCOUNT_BUSINESS ? (
                <Col sm="6" md="4">
                  <Label for="name">
                    <FormattedMessage id="inputs.name" />
                  </Label>
                  <InputGroup>
                    <CustomDashboardInput
                      id="name"
                      value={businessName.value}
                      fieldState={businessName}
                      onChange={event => inputHandler(setBusinessName, event.target.value)}
                    />
                  </InputGroup>
                </Col>
              ) : (
                <>
                  <Col sm="6" md="4">
                    <Label for="firstName">
                      <FormattedMessage id="inputs.firstName" />
                    </Label>
                    <InputGroup>
                      <CustomDashboardInput
                        id="firstName"
                        value={firstName.value}
                        fieldState={firstName}
                        onChange={event => inputHandler(setFirstName, event.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col sm="6" md="4">
                    <Label for="lastName">
                      <FormattedMessage id="inputs.lastName" />
                    </Label>
                    <InputGroup>
                      <CustomDashboardInput
                        id="lastName"
                        value={lastName.value}
                        fieldState={lastName}
                        onChange={event => inputHandler(setLastName, event.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col sm="6" md="4">
                    <Label for="dateOfBirth">
                      <FormattedMessage id="inputs.dateOfBirth" />
                    </Label>
                    <InputGroup className={dateOfBirth.invalid ? 'invalid-date' : ''}>
                      <SingleDatePicker
                        hideKeyboardShortcutsPanel
                        date={dateOfBirth.value}
                        onDateChange={date => inputHandler(setDateOfBirth, date)}
                        focused={dateOfBirthFocused}
                        onFocusChange={({ focused }) => setDateOfBirthFocused(focused)}
                        id="dateOfBirth"
                        showDefaultInputIcon
                        firstDayOfWeek={1}
                        numberOfMonths={1}
                        small
                        displayFormat={FORMAT_STANDARD_DATE}
                        placeholder={''}
                        initialVisibleMonth={() => dateOfBirth.value || moment()}
                        isOutsideRange={date => date.isAfter(moment(), 'day')}
                        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                          <div className="d-flex justify-content-center">
                            <div className="mr-1">
                              <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                                {moment.months().map((label, value) => (
                                  <option value={value} key={label}>
                                    {label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="ml-1">
                              <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                                {yearListRender(1900, moment().format('YYYY'))}
                              </select>
                            </div>
                          </div>
                        )}
                      />
                      {dateOfBirth.invalid && <FormFeedback className="d-block">{dateOfBirth.error}</FormFeedback>}
                    </InputGroup>
                  </Col>
                  <Col sm="6" md="4">
                    <Label for="sex">
                      <FormattedMessage id="inputs.sex" />
                    </Label>
                    <InputGroup>
                      <div className="hok-select">
                        <CustomDashboardInput
                          id="sex"
                          type="select"
                          fieldState={sex}
                          onChange={event => inputHandler(setSex, event.target.value)}
                        >
                          <option disabled value="">
                            {formatMessage({ id: 'inputs.sex' })}
                          </option>
                          {USER_GENDER.map(sex => (
                            <option key={sex.value} value={sex.value}>
                              {formatMessage({ id: sex.name })}
                            </option>
                          ))}
                        </CustomDashboardInput>
                      </div>
                    </InputGroup>
                  </Col>
                </>
              )}
              <Col sm="6" md="4">
                <Label for="OIB">
                  <FormattedMessage id="inputs.OIB" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="OIB"
                    value={oib.value}
                    fieldState={oib}
                    onChange={event => inputHandler(setOIB, event.target.value)}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="6" md="4">
                <Label for="address">
                  <FormattedMessage id="inputs.address" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="address"
                    value={address.value}
                    fieldState={address}
                    onChange={event => inputHandler(setAddress, event.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for="zip">
                  <FormattedMessage id="inputs.zipCode" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    className="input-uppercase"
                    id="zip"
                    fieldState={zip}
                    value={zip.value}
                    onChange={event => {
                      event.persist();
                      debouncedPlacesFilter(event.target.value);
                      setZip(zip => ({ ...zip, invalid: false, value: event.target.value }));
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for="city">
                  <FormattedMessage id="inputs.place" />
                </Label>
                <InputGroup>
                  <div className="hok-select">
                    <CustomDashboardInput
                      id="city"
                      type="select"
                      fieldState={city}
                      value={city.value}
                      onChange={event => {
                        const place = places.find(place => place.name === event.target.value);
                        setCity({ ...city, invalid: false, value: event.target.value });
                        place && setZip({ ...zip, invalid: false, value: place.pttNumber });
                      }}
                    >
                      <option disabled value="">
                        {formatMessage({
                          id: 'inputs.labels.selectPlace',
                        })}
                      </option>
                      {loadingPlaces ? (
                        <option disabled value={city.value}>
                          {city.value}
                        </option>
                      ) : (
                        filteredPlaces.map(place => (
                          <option key={v4()} value={place.name}>
                            {place.name}
                          </option>
                        ))
                      )}
                    </CustomDashboardInput>
                  </div>
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for="phoneNumber">
                  <FormattedMessage id="inputs.phoneNumberShort" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="phoneNumber"
                    name="mobile phone number"
                    type="tel"
                    // If lenght is greater than 21 formatPhoneNumberIntl() function from
                    // "react-phone-number-input" goes wild and resets the value to an empty string
                    maxLength={21}
                    tag={PhoneInput}
                    //international={false}
                    defaultCountry="HR"
                    value={phoneNumber.value}
                    fieldState={phoneNumber}
                    numberInputProps={phoneNumber.invalid ? 'is-invalid' : ''}
                    onChange={phone => inputHandler(setPhoneNumber, formatPhoneNumber(phone))}
                  />
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for="email">
                  <FormattedMessage id="inputs.email" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="email"
                    value={email.value}
                    fieldState={email}
                    onChange={event => inputHandler(setEmail, event.target.value)}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
          </CollapsibleContainer>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs="12">
          <Label>
            <FormattedMessage id="userReportDamagePage.peril" />:
          </Label>
          <CollapsibleContainer
            expanded
            title={renderPerilHeader(
              {
                sector: get(insuredPersonData, 'sector', ''),
                businessName: get(insuredPersonData, 'name', ''),
                firstName: get(insuredPersonData, 'firstName', ''),
                lastName: get(insuredPersonData, 'lastName', ''),
              },
              'peril'
            )}
            invalid={isPerilPersonInfoInvalid}
          >
            <FormGroup row className="mt-3">
              <Col sm="6" md="4">
                <Label for="reportingPolicyNumber" className="label-flex">
                  <FormattedMessage id="inputs.reportingPolicyNumber" />
                </Label>
                <InputGroup>
                  <div className="w-100 position-relative">
                    {checkLoading && <Spinner className="input-spinner" size="sm" />}
                    <Input
                      id="reportingPolicyNumber"
                      invalid={
                        !checkLoading && (reportingPolicyNumberStatus.invalid || reportingPolicyNumber.current.invalid)
                      }
                      defaultValue={get(data, 'insurancePolicyNumber', '')}
                      valid={!checkLoading && reportingPolicyNumberStatus.valid}
                      onChange={event => {
                        reportingPolicyNumber.current.value = event.target.value;
                        if (event.target.value) {
                          debouncedPolicyValidityCheck({
                            variables: {
                              insurancePolicyNumber: event.target.value,
                              insurancePolicyOib: reportingPolicyOwnerOib.current.value,
                            },
                          });
                        } else {
                          setReportingPolicyNumberStatus({ invalid: false, error: '', valid: false });
                        }
                      }}
                    />
                  </div>
                  {(reportingPolicyNumberStatus.invalid || reportingPolicyNumber.current.invalid) && !checkLoading && (
                    <FormFeedback className="d-block">
                      {reportingPolicyNumberStatus.error || reportingPolicyNumber.current.error}
                    </FormFeedback>
                  )}
                </InputGroup>
              </Col>
              <Col sm="6" md="4">
                <Label for="reportingPolicyOwnerOib" className="label-flex">
                  <FormattedMessage id="inputs.reportingPolicyOwnerOib" />
                </Label>
                <InputGroup>
                  <Input
                    id="reportingPolicyOwnerOib"
                    invalid={reportingPolicyOwnerOibStatus.invalid || reportingPolicyOwnerOib.current.invalid}
                    defaultValue={get(data, 'insurancePolicyOib', '')}
                    valid={reportingPolicyOwnerOibStatus.valid}
                    onChange={async event => {
                      event.persist();
                      reportingPolicyOwnerOib.current.value = event.target.value;
                      if (event.target.value) {
                        debouncedOIBValidityCheck({
                          variables: {
                            insurancePolicyNumber: reportingPolicyNumber.current.value,
                            insurancePolicyOib: event.target.value,
                          },
                        });
                      } else {
                        reportingPolicyOwnerOib.current.invalid = false;
                        reportingPolicyOwnerOib.current.error = '';
                        setReportingPolicyOwnerOibStatus({ invalid: false, error: '', valid: false });
                      }
                    }}
                  />
                  {(reportingPolicyOwnerOibStatus.invalid || reportingPolicyOwnerOib.current.invalid) &&
                    !checkLoading && (
                      <FormFeedback className="d-block">
                        {reportingPolicyOwnerOibStatus.error || reportingPolicyOwnerOib.current.error}
                      </FormFeedback>
                    )}
                </InputGroup>
              </Col>
            </FormGroup>
            {insuredPersonData && isOibValid && productDefinition && (
              <FormGroup row>
                {insuredPersonData.sector === USER_ACCOUNT_BUSINESS ? (
                  <Col sm="6" md="4">
                    <Label for="perilName">
                      <FormattedMessage id="inputs.name" />
                    </Label>
                    <InputGroup>
                      <Input id="perilName" readOnly defaultValue={get(insuredPersonData, 'name', '')} />
                    </InputGroup>
                  </Col>
                ) : (
                  <>
                    <Col sm="6" md="4">
                      <Label for="perilFirstName">
                        <FormattedMessage id="inputs.firstName" />
                      </Label>
                      <InputGroup>
                        <Input id="perilFirstName" readOnly defaultValue={get(insuredPersonData, 'firstName', '')} />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="perilLastName">
                        <FormattedMessage id="inputs.lastName" />
                      </Label>
                      <InputGroup>
                        <Input id="perilLastName" readOnly defaultValue={get(insuredPersonData, 'lastName', '')} />
                      </InputGroup>
                    </Col>
                  </>
                )}
              </FormGroup>
            )}
          </CollapsibleContainer>
        </Col>
      </Row>
      <FormGroup row className="mt-4 mb-5">
        <Col>
          <Label for="note">
            <FormattedMessage id="inputs.note" />
          </Label>
          <CustomDashboardInput
            id="note"
            className="hok-note-textarea"
            type="textarea"
            value={note.value}
            fieldState={note}
            onChange={event => setNote({ ...note, value: event.target.value, invalid: false, error: null })}
          />
        </Col>
      </FormGroup>
      <FormGroup row className="mt-4">
        <Col className="d-flex flex-row" xs="12">
          <CustomInput
            className="hok-tac-checkbox"
            id="tac"
            type="checkbox"
            checked={tac.value}
            invalid={tac.invalid}
            onChange={event => {
              event.persist();
              setTac(tac => ({ ...tac, value: event.target.checked, invalid: false }));
            }}
          />
          <div className="tac-text-container">
            <span>
              Prihvaćam pohranu i rukovanje iznad navedenim podacima sukladno
              <a
                target="_blank"
                href={legalDocuments[0] ? legalDocuments[0].link : '#'}
                rel="noopener noreferrer"
                className="terms-and-conditions-text"
              >
                informacijama o obradi podataka.
              </a>
            </span>
          </div>
        </Col>
        <FormFeedback className={'d-block pl-3' + (tac.invalid ? ' visible' : ' invisible')}>
          <FormattedMessage id="errors.invalidIOOP" />
        </FormFeedback>
      </FormGroup>
      <Row className="no-gutters mt-4">
        <Col>
          <CustomButton
            className="hok-dashboard-btn-wide float-sm-right"
            translationId="continue"
            loaderProp={loading}
          />
        </Col>
      </Row>
    </Form>
  );
};
