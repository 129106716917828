import gql from 'graphql-tag';

export const GetCustomContactFormSchemaQuery = gql`
  query GetCustomContactFormSchema($type: String!) {
    getCustomContactFormSchema(type: $type) {
      customContactFormSchemaFields {
        name
        type
        list
      }
      meta
    }
  }
`;
