import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Query } from 'react-apollo';
import { get } from 'lodash-es';
import { ROUTE_TO_USER_NEWS_PAGE } from '../../../constants/routes';
import { handleRequestError } from '../../../constants/errorCodes';
import { GetNewsForPreviewQuery } from '../../../operations/queries/GetNewsQuery';
import { formatDate } from '../../../constants/dateFormatting';
import ArrowLinkButton from '../../../containers/ArrowLinkButton';
import { NewsSlider } from '../../../components/NewsSlider';
import { FormattedMessage } from 'react-intl';

export const UserNewsPreviewPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const postId = get(params, 'postId', null);

  return (
    <Query
      query={GetNewsForPreviewQuery}
      variables={{ id: parseInt(postId, 10) }}
      onError={error => handleRequestError(error, dispatch)}
    >
      {({ loading, data }) => {
        if (loading || !data) {
          return null;
        }

        const selecedPost = get(data, 'news', [])[0];

        if (!postId) {
          return <Redirect to={ROUTE_TO_USER_NEWS_PAGE} />;
        }

        if (!selecedPost) {
          return <Redirect to={ROUTE_TO_USER_NEWS_PAGE} />;
        }

        return (
          <Container fluid className="mt-4 hok-news-preview">
            <ArrowLinkButton linkTo={null} xlColSize={10} />
            <Row className="mt-4">
              <Col xs="12" xl={{ size: 10, offset: 1 }}>
                <Card className="hok-news-preview-card">
                  <CardBody>
                    <Row>
                      <Col>
                        <div
                          className="hok-news-preview-card-image"
                          style={{ backgroundImage: `url("${selecedPost.featuredImageUrl}")` }}
                        >
                          <img
                            style={{ maxHeight: '410px' }}
                            className="img-fluid invisible w-100"
                            src={selecedPost.featuredImageUrl}
                            alt={selecedPost.title}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="no-gutters hok-news-preview-card-content">
                      <Col>
                        <Row className="no-gutters">
                          <Col>
                            <h2>{selecedPost.title}</h2>
                          </Col>
                        </Row>
                        <Row className="no-gutters mb-5">
                          <Col>
                            <p className="hok-news-preview-card-published">
                              <FormattedMessage id="userNewsListPage.publishedAt" />:{' '}
                              {formatDate(selecedPost.publishedAt)}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div dangerouslySetInnerHTML={{ __html: selecedPost.content }} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ArrowLinkButton linkTo={ROUTE_TO_USER_NEWS_PAGE} xlColSize={10} />
            <Row className="my-2">
              <Col xs="12" xl={{ size: 10, offset: 1 }}>
                <hr />
              </Col>
            </Row>
            <NewsSlider fileredPostId={selecedPost.id} titleId="userNewsPreviewPage.otherNews" />
          </Container>
        );
      }}
    </Query>
  );
};
