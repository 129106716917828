import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ROUTE_TO_GUEST_CONTACT, ROUTE_TO_GUEST_TERMS_OF_SERVICE } from '../../../constants/routes';

export const SupportInfoLink = React.memo(({ unwrapped = false }) => {
  const renderSupportLink = (
    <Link to={ROUTE_TO_GUEST_CONTACT} className="hok-contact-link">
      <FormattedMessage id="links.contactSupport" />
    </Link>
  );
  const renderTermsOfUseLink = (
    <Link to={ROUTE_TO_GUEST_TERMS_OF_SERVICE} className="hok-terms-of-service-link">
      <FormattedMessage id="links.termsOfService" />
    </Link>
  );

  return unwrapped ? (
    renderSupportLink
  ) : (
    <Row className="no-gutters px-4 d-flex align-self-end w-100">
      <Col className="text-center" xs={12}>
        {renderSupportLink}
      </Col>
      <Col className="text-center" xs={12}>
        {renderTermsOfUseLink}
      </Col>
    </Row>
  );
});
