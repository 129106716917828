import { currencyDisplayUpdate } from '../../components/CurrencyDisplay';

const initialState = {
  defaultCurrency: null,
  dualCurrencyDisplay: null,
};

const currencyConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENCY_CONFIG':
      currencyDisplayUpdate(action.defaultCurrency);
      return {
        ...state,
        defaultCurrency: 'EUR',
        dualCurrencyDisplay: false,
        // defaultCurrency: action.defaultCurrency,
        // dualCurrencyDisplay: action.dualCurrencyDisplay
      };
    default:
      return state;
  }
};

export default currencyConfigReducer;
