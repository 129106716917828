import React from 'react';
import { Button } from 'reactstrap';
import { ReactComponent as HokDownloadIcon } from '../../img/icons/hok-download.svg';
import { Spinner } from '../../containers';
import { formatDateTime } from '../../constants/dateFormatting';

export const FileDownloadButton = ({ name, onClick, progress, datetime }) => {
  return (
    <Button className="hok-doc-upload-btn px-3" block onClick={onClick}>
      <div className="hok-doc-upload-content">
        <span className="hok-doc-name">{name}</span>
        {datetime && <span>{formatDateTime(datetime)}</span>}
      </div>
      <div className="d-flex align-items-center svg-red ml-auto" style={{ width: 24 }}>
        {progress ? (
          <Spinner loaderClassName="spinner-border-thin" size="sm" color="primary" />
        ) : (
          <HokDownloadIcon className="svg-red ml-auto" height="24" width="24" />
        )}
      </div>
    </Button>
  );
};
