import gql from 'graphql-tag';

export const GetProductDocumentsQuery = gql`
  query GetProductDocuments($filter: getProductDocumentsFilterInput, $order: getProductDocumentsSortFilterInput) {
    getProductDocuments(filter: $filter, order: $order) {
      documents {
        documentId
        productCode
        documentationType
        documentName
        required
        type
        link
        content
        active
        order
        mimeType
        shasum
        status
      }
    }
  }
`;

export const GetAllTypeProductDocumentsQuery = gql`
  query GetAllTypeProductDocuments($productCode: String) {
    PREDUGOVORNA_DOKUMENTACIJA: getProductDocuments(
      filter: { productCode: $productCode, type: "PREDUGOVORNA_DOKUMENTACIJA", active: true }
    ) {
      documents {
        documentId
        productCode
        documentationType
        documentName
        required
        type
        link
        content
        active
        order
        mimeType
        status
      }
    }
    UVJETI_OSIGURANJA: getProductDocuments(
      filter: { productCode: $productCode, type: "UVJETI_OSIGURANJA", active: true }
    ) {
      documents {
        documentId
        productCode
        documentationType
        documentName
        required
        type
        link
        content
        active
        order
        mimeType
        status
      }
    }
  }
`;
