import React, { useLayoutEffect } from 'react';
import lottie from 'lottie-web';
import animationData from '../../img/animation.json';

export const BackgroundAnimation = React.memo(() => {
  useLayoutEffect(() => {
    const params = {
      container: document.getElementById('lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
      resizeMode: 'cover',
    };

    lottie.loadAnimation(params);
  });

  return <div id="lottie" className="lottie" />;
});
