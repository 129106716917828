import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import keyboardRightIcon from '../../img/icons/hok-chevron-right.svg';

export const CustomButtonArrowRight = ({
  onClick,
  placeholder,
  rawPlaceholder = false,
  uppercase = false,
  invalid = false,
  className,
}) => (
  <div onClick={onClick} className={`w-100 button-arrow-right ${invalid ? 'invalid' : ''}`.trim()}>
    <Card className="w-100">
      <CardBody className="cursor-pointer">
        <div className="body-text">
          <p className={`${uppercase ? 'text-uppercase' : ''} ${className}`.trim()}>
            {rawPlaceholder ? placeholder : <FormattedMessage id={placeholder} />}
          </p>
          <img alt="arrow-right" src={keyboardRightIcon} />
        </div>
      </CardBody>
    </Card>
  </div>
);
