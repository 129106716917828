import gql from 'graphql-tag';

export const PaymentStatusSubscription = gql`
  subscription PaymentStatus($confirmationCode: String!) {
    payments(confirmationCode: $confirmationCode) {
      confirmationCode
      userId
      type
      status
      paymentStatus
      totalAmount
      # totalAmountEuro
      comment
      payload
      createdAt
      updatedAt
    }
  }
`;
