import gql from 'graphql-tag';

export const MeQuery = gql`
  query me {
    myData: profile {
      id
      username
      firstName
      lastName
      businessName
      role
      sex
      sector
      title
      email
      phoneNumber
      avatar
      partnerId
      passwordExpires
      passwordExpired
      accountLocked
      activated
      verified
      dateOfBirth
      oib
      mbo
      VATpayer
      webAddress
      businessTelephone
      businessFirstName
      businessLastName
      governmentIssuedIdNumber
      passportNumber
      HZZONumber
      ino
      address
      zip
      city
      country
      wayOfContactForInvoice
      wayOfContactForNotifications
      wayOfContactForConfirmations
      serverStorageKey
      createdAt
      updatedAt
    }
  }
`;

export default MeQuery;
