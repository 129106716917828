import { ReactComponent as HokPrivateIcon } from '../img/icons/hok-private-user.svg';
import { ReactComponent as HokBusinessIcon } from '../img/icons/hok-business-user.svg';

export const USER_ACCOUNT_PRIVATE = 'private';
export const USER_ACCOUNT_BUSINESS = 'business';
export const USER_ACCOUNT_TYPES = [
  { name: USER_ACCOUNT_PRIVATE, Icon: HokPrivateIcon },
  { name: USER_ACCOUNT_BUSINESS, Icon: HokBusinessIcon },
];

export const USER_GENDER = [
  { name: 'male', value: 'M' },
  { name: 'female', value: 'F' },
];

export const isUserAccountTypeValid = accountType => {
  return USER_ACCOUNT_TYPES.map(type => type.name).includes(accountType);
};

export const NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY =
  'NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY';
export const ONE_OF_LAST_5_INVOICES = 'ONE_OF_LAST_5_INVOICES';
