import gql from 'graphql-tag';

export const DeleteMyAccountMutation = gql`
  mutation DeleteMyAccount($id: Int!) {
    deleteMyAccount(id: $id) {
      id
    }
  }
`;

export default DeleteMyAccountMutation;
