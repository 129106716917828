export const haveArraysDiverged = (currentArray, previousArray, fieldsToCheck) => {
  /* Expects id and value keys in each array elements object
   * Only checks top-level data
   */

  if (!fieldsToCheck || fieldsToCheck.length === 0) {
    return false;
  }

  if (!currentArray || !previousArray) {
    return true;
  }

  const newArray = reduceIdValueArrayToObject(currentArray, fieldsToCheck);
  const oldArray = reduceIdValueArrayToObject(previousArray, fieldsToCheck);

  let hasDiverged = false;

  /* eslint-disable no-unused-vars */
  for (const key in newArray) {
    if (newArray[key] !== oldArray[key]) {
      hasDiverged = true;
      break;
    }
  }
  /* eslint-enable no-unused-vars */

  return hasDiverged;
};

export const reduceIdValueArrayToObject = (array, keysToAdd) => {
  return array.reduce((acc, curr) => {
    if (keysToAdd.includes(curr.id)) {
      acc[curr.id] = curr.value;
    }
    return acc;
  }, {});
};

export const convertIdValueArrayToObject = array => {
  return array.reduce((obj, item) => {
    obj[item.id] = item.value;
    return obj;
  }, {});
};

export const convertKeyValueArrayToObject = array => {
  let object = {};
  array.forEach(element => {
    object[Object.keys(element)[0]] = Object.values(element)[0];
  });
  return object;
};

export const moveArrayItemToLastIndex = (array, identifierFn) => {
  const sortedArray = [...array];
  sortedArray.push(sortedArray.splice(sortedArray.findIndex(identifierFn), 1)[0]);
  return sortedArray;
};
