import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from 'react-apollo';
import { get } from 'lodash-es';
import UserProfile from './UserProfileContainer';
import { ProfileInfoPrivate } from './ProfileInfoPrivate';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { ProfileInfoBusiness } from './ProfileInfoBusiness';
import { UserUpdateProfileBasicInfoMutation } from '../../../operations/mutations/UserUpdateProfileMutation';
import { handleRequestError } from '../../../constants/errorCodes';
import { SUCCESS_TOASTER, renderToaster } from '../../../constants/toaster';
import { usePlacesQuery } from '../../../utils/customHooks';
import { userUpdateDataInStore } from '../../../redux/actions/userAuthActions';
import { Spinner } from '../../../containers';

export const UserProfileInfo = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const sector = get(user, 'sector', null);

  const { places, loading: loadingPlaces } = usePlacesQuery();

  const [updateProfile, { loading }] = useMutation(UserUpdateProfileBasicInfoMutation, {
    onError: error => handleRequestError(error, dispatch),
    onCompleted: data => {
      dispatch(userUpdateDataInStore(data.updateProfile.profile));
      renderToaster(`successMessages.userProfileAdvancedDataUpdated`, SUCCESS_TOASTER, { intlKey: true });
    },
  });

  return loadingPlaces ? (
    <Spinner className="spinner-positioning" />
  ) : (
    <UserProfile>
      {sector === USER_ACCOUNT_BUSINESS ? (
        <ProfileInfoBusiness
          user={user}
          updateProfile={updateProfile}
          loading={loading}
          places={places}
          loadingPlaces={loadingPlaces}
        />
      ) : (
        <ProfileInfoPrivate
          user={user}
          updateProfile={updateProfile}
          loading={loading}
          places={places}
          loadingPlaces={loadingPlaces}
        />
      )}
    </UserProfile>
  );
};
