import gql from 'graphql-tag';

export const GetClaimDocumentationQuery = gql`
  query GetClaimDocumentation(
    $filter: getReportedClaimDocumentsFilterInput
    $order: getReportedClaimDocumentsSortFilterInput
  ) {
    getReportClaimDocuments(filter: $filter, order: $order) {
      documents {
        reportType
        documentId
        documentName
        required
        type
        link
        content
        active
        order
        mimeType
        shasum
        status
      }
    }
  }
`;
