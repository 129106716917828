import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { ScreenTitleContainer, CustomButton, Spinner } from '../../../containers';
import { SupportInfo } from '../../../components/SupportInfo';
import { ReactComponent as HokPhoneIcon } from '../../../img/icons/hok-phone.svg';
import { ReactComponent as HokGlobalPhoneIcon } from '../../../img/icons/hok-phone-global.svg';
import { ReactComponent as HokGlobeIcon } from '../../../img/icons/hok-globe.svg';
import { ROUTE_TO_USER_SUPPORT_PAGE } from '../../../constants/routes';
import { OfficeCards } from './OfficeCards';
import { GetOfficesQuery } from '../../../operations/queries/GetOfficesQuery';
import { handleRequestError } from '../../../constants/errorCodes';
import { OfficeSearch } from './OfficeSearch';
import { OFFICES, MAIN_OFFICES, OFFICE_CLAIMS } from '../../../constants/business';
import { FormattedMessage } from 'react-intl';

export const GeneralInfoPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const supportData = useSelector(state => state.support);

  const { data, loading } = useQuery(GetOfficesQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const allOffices = get(data, 'getOffices.offices', []);

  return (
    <Container className="hok-container-wide" fluid>
      <ScreenTitleContainer row titleId="generalInfoPage.title" />
      <Row className="mb-5">
        <Col sm="6">
          <p className="hok-text-dark mb-4">
            <span className="d-block font-weight-bold">{supportData.name}</span>
            {supportData.address &&
              supportData.address.split('\n').map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
          </p>

          {supportData.tradeCourtText && (
            <p className="hok-text-dark">
              {supportData.tradeCourtText.split('\n').map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </p>
          )}
        </Col>
        <Col sm="6">
          <SupportInfo showFaxNumber className="mb-4" />
          <CustomButton
            type="button"
            className="hok-dashboard-btn-wide"
            block={false}
            translationId="customerSupport"
            onClick={() => history.push(ROUTE_TO_USER_SUPPORT_PAGE)}
          />
        </Col>
      </Row>
      <Row className="my-5">
        <Col sm="6" className="mb-4">
          <h3 className="mb-3">
            <FormattedMessage id="generalInfoPage.contactInfo.claims" />
          </h3>
          {supportData.claimReportPhoneNumber && <ContactNumber phoneNumber={supportData.claimReportPhoneNumber} />}
          {supportData.claimReportLegacyWebUrl && (
            <Row className="no-gutters mb-3">
              <Col className="d-flex align-items-center">
                <HokGlobeIcon className="svg-red" />
                <a
                  className="hok-support-icon-label"
                  href={supportData.claimReportLegacyWebUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  {supportData.claimReportLegacyWebUrl.replace('/index.php', '').replace('https://', '')}
                </a>
              </Col>
            </Row>
          )}
        </Col>
        <Col sm="6" className="mb-4">
          <h3 className="mb-3">
            <FormattedMessage id="userAssistancePage.HRAssistanceTitle" />
          </h3>
          <p>
            <FormattedMessage id="generalInfoPage.contactInfo.roadAssistance" />
          </p>
          {supportData.HR_ASSISTANCE_phoneNumber_from_hr && (
            <ContactNumber phoneNumber={supportData.HR_ASSISTANCE_phoneNumber_from_hr} />
          )}
          {supportData.HR_ASSISTANCE_phoneNumber && (
            <ContactNumber global phoneNumber={supportData.HR_ASSISTANCE_phoneNumber} />
          )}
        </Col>
        <Col sm="6" className="mb-4">
          <h3 className="mb-3">
            <FormattedMessage id="userAssistancePage.ExpressAssistanceTitle" />
          </h3>
          <p>
            <FormattedMessage id="generalInfoPage.contactInfo.expressAssistance" />
          </p>
          <p className="font-weight-bold">Brojevi telefona za police ugovorene od 01.01.2020.:</p>
          {supportData.HE_ASSISTANCE_phoneNumber_from_hr && (
            <ContactNumber phoneNumber={supportData.HE_ASSISTANCE_phoneNumber_from_hr} />
          )}
          {supportData.HR_ASSISTANCE_phoneNumber && (
            <ContactNumber global phoneNumber={supportData.HR_ASSISTANCE_phoneNumber} />
          )}
          <p className="font-weight-bold">Brojevi telefona za police ugovorene do 31.12.2019.:</p>
          {supportData.HE_ASSISTANCE_AXA_phoneNumber && (
            <ContactNumber phoneNumber={supportData.HE_ASSISTANCE_AXA_phoneNumber} />
          )}
        </Col>
        <Col sm="6" className="mb-4">
          <h3 className="mb-3">
            <FormattedMessage id="userAssistancePage.HRRentACarAssistanceTitle" />
          </h3>
          <p>
            <FormattedMessage id="generalInfoPage.contactInfo.replacementVehicle" />
          </p>
          {supportData.HR_RENT_A_CAR_ASSISTANCE_phoneNumber_from_hr && (
            <ContactNumber phoneNumber={supportData.HR_RENT_A_CAR_ASSISTANCE_phoneNumber_from_hr} />
          )}
          {supportData.HR_RENT_A_CAR_ASSISTANCE_phoneNumber && (
            <ContactNumber global phoneNumber={supportData.HR_RENT_A_CAR_ASSISTANCE_phoneNumber} />
          )}
        </Col>
        <Col sm="6" className="mb-4">
          <h3 className="mb-3">
            <FormattedMessage id="userTravelInsurancePage.title" />
          </h3>
          <p>
            <FormattedMessage id="generalInfoPage.contactInfo.overseasAssistance" />
          </p>
          {supportData.PZO_ASSISTANCE_phoneNumber_from_hr && (
            <ContactNumber phoneNumber={supportData.PZO_ASSISTANCE_phoneNumber_from_hr} />
          )}
          {supportData.PZO_ASSISTANCE_phoneNumber && (
            <ContactNumber phoneNumber={supportData.PZO_ASSISTANCE_phoneNumber} />
          )}
        </Col>
        <Col sm="6" className="mb-4">
          <h3 className="mb-3">
            <FormattedMessage id="userAssistancePage.homeAssistance" />
          </h3>
          <p>
            <FormattedMessage id="generalInfoPage.contactInfo.houseAssistance" />
          </p>
          {supportData.KA_ASSISTANCE_phoneNumber_from_hr && (
            <ContactNumber phoneNumber={supportData.KA_ASSISTANCE_phoneNumber_from_hr} />
          )}
          {supportData.KA_ASSISTANCE_phoneNumber && (
            <ContactNumber global phoneNumber={supportData.KA_ASSISTANCE_phoneNumber} />
          )}
        </Col>
      </Row>
      {loading ? (
        <Col>
          <Spinner className="spinner-positioning" />
        </Col>
      ) : (
        allOffices.length > 0 && (
          <OfficeSearch allOffices={allOffices}>
            {({ offices, mainOffices, damageClaimOffices, officeTypeFilter }) => (
              <>
                {(!officeTypeFilter || officeTypeFilter === MAIN_OFFICES.id) && (
                  <OfficeCards titleId="generalInfoPage.mainOffices" offices={mainOffices} />
                )}
                {(!officeTypeFilter || officeTypeFilter === OFFICES.id) && (
                  <OfficeCards titleId="generalInfoPage.offices" offices={offices} />
                )}
                {(!officeTypeFilter || officeTypeFilter === OFFICE_CLAIMS.id) && (
                  <OfficeCards titleId="generalInfoPage.damageClaimOffices" offices={damageClaimOffices} />
                )}
              </>
            )}
          </OfficeSearch>
        )
      )}
    </Container>
  );
};

export const ContactNumber = ({ phoneNumber, global, className = '' }) => {
  return (
    <Row className={`no-gutters mb-3 ${className}`.trim()}>
      <Col className="d-flex align-items-center">
        {global ? <HokGlobalPhoneIcon className="svg-red" /> : <HokPhoneIcon className="svg-red" />}
        <a className="hok-support-icon-label" href={`tel://${phoneNumber}`}>
          {phoneNumber}
        </a>
      </Col>
    </Row>
  );
};
