import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ArrowLinkButton, PolicyCard, ScreenTitleContainer } from '../../../containers';
import {
  ROUTE_TO_USER_POLICIES_PAGE,
  ROUTE_TO_USER_POLICIES_PROPERTY_APARTMENT_PAGE,
  ROUTE_TO_USER_POLICIES_PROPERTY_MOJ_DOM_PAGE,
  ROUTE_TO_USER_POLICIES_PROPERTY_OTHER_PAGE,
} from '../../../constants/routes';
import { ReactComponent as HokMdIcon } from '../../../img/products/hok-md.svg';
import { ReactComponent as HokApartmentIcon } from '../../../img/products/hok-apartman.svg';
// import { ReactComponent as HokPozarIcon } from '../../../img/products/hok-pozar.svg';
// import { ReactComponent as HokUpraviteljiIcon } from '../../../img/products/hok-upravitelji-zgrada.svg';
import { ReactComponent as HokOtherIcon } from '../../../img/products/hok-other.svg';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserPoliciesPropertyInsurance = () => {
  const policyList = {
    cards: [
      { id: 1, cardName: 'mojDom', Pic: HokMdIcon, counter: 3, route: ROUTE_TO_USER_POLICIES_PROPERTY_MOJ_DOM_PAGE },
      {
        id: 2,
        cardName: 'apartman',
        Pic: HokApartmentIcon,
        counter: 3,
        route: ROUTE_TO_USER_POLICIES_PROPERTY_APARTMENT_PAGE,
      },
      // {
      //   id: 3,
      //   cardName: 'civilProtekt',
      //   Pic: HokMdIcon,
      //   counter: 3,
      //   route: {
      //     pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      //     state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "CIVIL PROTEKT"' },
      //   },
      // },
      // {
      //   id: 4,
      //   cardName: 'upravitelji',
      //   Pic: HokUpraviteljiIcon,
      //   counter: 3,
      //   route: {
      //     pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      //     state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "UPRAVITELJI ZGRADA"' },
      //   },
      // },
      // {
      //   id: 5,
      //   cardName: 'pozar',
      //   Pic: HokPozarIcon,
      //   counter: 3,
      //   route: {
      //     pathname: ROUTE_TO_USER_PRODUCT_ENQUIRY,
      //     state: { subject: ENQUIRY_SUBJECT_NEW_POLICY, title: 'Kupnja nove police "OSIGURANJE OD POŽARA I DR."' },
      //   },
      // },
      {
        id: 6,
        cardName: 'propertyOther',
        Pic: HokOtherIcon,
        counter: 3,
        route: ROUTE_TO_USER_POLICIES_PROPERTY_OTHER_PAGE,
      },
    ],
  };

  const renderPolicyCards = () => {
    const cards = policyList.cards.map(card => {
      const { id, Pic, cardName, text, route } = card;
      return <PolicyCard route={route} key={id} text={text} Pic={Pic} cardName={cardName} />;
    });
    return cards;
  };

  return (
    <Container className="hok-container" fluid>
      <Row className="mt-4">
        <Col className="page-container" xs={12}>
          <ArrowLinkButton className="mb-4" linkTo={ROUTE_TO_USER_POLICIES_PAGE} />
          <ScreenTitleContainer row titleId="routes.propertyInsurance" />
          <Row className="d-flex cards-container no-gutters mt-5">{renderPolicyCards()}</Row>
        </Col>
      </Row>
      <AdFormIFrame title="Proizvodi - Imovina" />
    </Container>
  );
};
