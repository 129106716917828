import gql from 'graphql-tag';

export const ResendActivationEmailMutation = gql`
  mutation ResendActivationEmail($email: String!) {
    resendActivationCode(email: $email) {
      id
      done
    }
  }
`;

export default ResendActivationEmailMutation;
