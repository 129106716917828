import React, { Suspense, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';

import { Layout, UserLayout, Spinner, ErrorLogging } from '../containers';
import { isUserLoggedIn, isAdminLoggedIn } from '../utils/auth';
import {
  ROUTE_TO_ADMIN_DASHBOARD_PAGE,
  ROUTE_TO_ADMIN_LOGIN_PAGE,
  ROUTE_TO_USER_LOGIN_PAGE,
  ROUTE_TO_USER_DASHBOARD_PAGE,
  routes,
  ROUTE_TO_ADMIN_TICKETING_PAGE,
  ROUTE_TO_MOBILE_TOS_PAGE,
} from '../constants/routes';
import { userLogout, authTokenLoaded } from '../redux/actions/userAuthActions';
import { authAdminLogout } from '../redux/actions/adminAuthActions';
import {
  getSessionStorageTicketsFilter,
  removeSessionStorageTicketsFilters,
  getLocalStorageToken,
  getLocalStorageGdprConsent,
  setLocalStorageGdprConsent,
} from '../constants/LocalStorageKeys';
import { useGoogleAnalyticsPageview } from '../utils/customHooks';
import { GdprConsentNotification } from '../components/GdprConsentNotification';
import { isDoNotTrackEnabled } from '../utils/browser';
import { analytics } from './firebaseConfig';
import { useCookies } from 'react-cookie';

// Custom component for wrapping of Protected Switch
// that redirect to user login page if not logged in
export const PrivateRoute = ({ children: Component, isRouteInDashboard = false, ...rest }) => {
  const dispatch = useDispatch();

  const isVisible = usePageVisibility();
  const { location } = rest;

  useEffect(() => {
    const token = getLocalStorageToken();
    if (token !== null && token !== undefined) {
      dispatch(authTokenLoaded({ authToken: getLocalStorageToken() }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isVisible) {
      if (!isUserLoggedIn()) {
        dispatch(userLogout());
      }
    }
  }, [isVisible, dispatch]);

  useEffect(() => {
    if (!isUserLoggedIn()) {
      dispatch(userLogout({ from: location }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route {...rest}>
      <div className="hok-client">
        {isRouteInDashboard ? (
          <Suspense
            fallback={
              <UserLayout>
                <Spinner className="spinner-positioning" />
              </UserLayout>
            }
          >
            <UserLayout>
              <Component />
            </UserLayout>
          </Suspense>
        ) : (
          <Suspense fallback={<Spinner className="spinner-positioning" />}>
            <ErrorLogging>
              <Component />
            </ErrorLogging>
          </Suspense>
        )}
      </div>
    </Route>
  );
};

// Custom component for wrapping of Authorization Switch
// that redirect to user dashboard index if already logged in
export const AuthRoute = ({ children: Component, isRouteInDashboard = false, ...rest }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = getLocalStorageToken();
    if (token !== null && token !== undefined) {
      dispatch(authTokenLoaded({ authToken: getLocalStorageToken() }));
    }
  }, [dispatch]);

  return (
    <Route {...rest}>
      {!isUserLoggedIn() ? (
        <div className="hok-client">
          <Suspense fallback={null}>
            <ErrorLogging>
              <Component />
            </ErrorLogging>
          </Suspense>
        </div>
      ) : (
        <Redirect to={{ pathname: ROUTE_TO_USER_DASHBOARD_PAGE }} />
      )}
    </Route>
  );
};

// Custom component for wrapping of admin Protected Switch
// that redirect to admin login page if not logged in
export const AdminRoute = ({ children: Component, isRouteInDashboard = true, ...rest }) => {
  const dispatch = useDispatch();
  if (!rest.path.includes(ROUTE_TO_ADMIN_TICKETING_PAGE) && getSessionStorageTicketsFilter()) {
    removeSessionStorageTicketsFilters();
  }

  const isVisible = usePageVisibility();

  useEffect(() => {
    const token = getLocalStorageToken();
    if (token !== null && token !== undefined) {
      dispatch(authTokenLoaded({ authToken: getLocalStorageToken() }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isVisible) {
      if (!isAdminLoggedIn()) {
        dispatch(authAdminLogout());
      }
    }
  }, [isVisible, dispatch]);

  return (
    <Route {...rest}>
      {isRouteInDashboard ? (
        <Suspense
          fallback={
            <Layout>
              <Spinner className="spinner-positioning" />
            </Layout>
          }
        >
          <Layout>
            <Component />
          </Layout>
        </Suspense>
      ) : (
        <Suspense fallback={null}>
          <ErrorLogging>
            <Component />
          </ErrorLogging>
        </Suspense>
      )}
    </Route>
  );
};

// Custom component for wrapping of admin Authorization Switch
// that redirect to admin dashboard index if already logged in
export const AdminAuthRoute = ({ children: Component, isRouteInDashboard = false, ...rest }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = getLocalStorageToken();
    if (token !== null && token !== undefined) {
      dispatch(authTokenLoaded({ authToken: getLocalStorageToken() }));
    }
  }, [dispatch]);

  return (
    <Route {...rest}>
      {!isAdminLoggedIn() ? (
        <Suspense fallback={null}>
          <ErrorLogging>
            <Component />
          </ErrorLogging>
        </Suspense>
      ) : (
        <Redirect to={{ pathname: ROUTE_TO_ADMIN_DASHBOARD_PAGE }} />
      )}
    </Route>
  );
};

export const withTracker = (WrappedComponent, page, options = {}) => {
  const HOC = props => {
    useGoogleAnalyticsPageview(page, options);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

const AppRouting = () => {
  const location = useLocation();
  const [cookies, , removeCookie] = useCookies();

  const consentLocalStorageValue = !!getLocalStorageGdprConsent();
  const [gdprConsent, setGdprConsent] = useState(consentLocalStorageValue);

  useEffect(() => {
    if (analytics && !isDoNotTrackEnabled() && gdprConsent) {
      analytics.setAnalyticsCollectionEnabled(true);
    } else {
      if (analytics) {
        analytics.setAnalyticsCollectionEnabled(false);
      }

      const gaCookies = Object.keys(cookies).filter(cookie => /^_ga/.test(cookie));
      gaCookies.forEach(gaCookie => removeCookie(gaCookie));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdprConsent]);

  useEffect(() => {
    if (gdprConsent !== consentLocalStorageValue) {
      setGdprConsent(consentLocalStorageValue);
    }
  }, [gdprConsent, consentLocalStorageValue]);

  const acceptOnClick = useCallback(() => {
    setGdprConsent(true);
    setLocalStorageGdprConsent(true);
  }, []);

  const getRouteComponent = routeType => {
    switch (routeType) {
      case 'private':
        return PrivateRoute;
      case 'auth':
        return AuthRoute;
      case 'admin':
        return AdminRoute;
      case 'adminAuth':
        return AdminAuthRoute;
      default:
        return Route;
    }
  };

  const isAdminDashboard = /^\/admin/.test(location.pathname);

  const redirectToAdminOrUserPage = () => {
    if (isAdminDashboard) {
      return ROUTE_TO_ADMIN_LOGIN_PAGE;
    }
    return ROUTE_TO_USER_LOGIN_PAGE;
  };

  const hideCookiePolicyOnRoutes = () => {
    return (
      isAdminDashboard ||
      ROUTE_TO_MOBILE_TOS_PAGE === location.pathname ||
      /^\/payment\/WSPay/.test(location.pathname) ||
      /^\/post/.test(location.pathname)
    );
  };

  return (
    <>
      <Suspense fallback={null}>
        <Switch>
          {routes.map(route => {
            const RouteComponent = getRouteComponent(route.type);
            return (
              <RouteComponent
                key={route.name}
                name={route.name}
                exact={route.exact}
                strict={route.strict}
                path={route.path}
                //component={route.component}
                isRouteInDashboard={route.isRouteInDashboard}
              >
                {route.gaPageviewName ? withTracker(route.component, route.gaPageviewName) : route.component}
              </RouteComponent>
            );
          })}
          <Redirect to={{ pathname: redirectToAdminOrUserPage() }} />
        </Switch>
      </Suspense>
      {!gdprConsent && !hideCookiePolicyOnRoutes() && <GdprConsentNotification acceptOnClick={acceptOnClick} />}
    </>
  );
};

export default AppRouting;
