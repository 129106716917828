import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { DamageClaimInfo } from '../../../components/SingleDamageClaimData/DamageClaimInfo';
import { DamageClaimDocumentation } from '../../../components/SingleDamageClaimData/DamageClaimDocumentation';
import { ArrowLinkButton, Spinner, TabsNavigation } from '../../../containers';
import { handleRequestError } from '../../../constants/errorCodes';
import { GetClaimFromCodeQuery } from '../../../operations/queries/GetClaimFromCodeQuery';

export const PublicSingleClaim = ({ baseRoute, damageClaimConfirmationCode }) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetClaimFromCodeQuery, {
    variables: { claimConfirmationCode: damageClaimConfirmationCode },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const damageClaim = get(data, 'viewReportedClaim', {});

  if (loading) {
    return <Spinner className="spinner-positioning" />;
  }

  if (!damageClaim.claimConfirmationCode || error) {
    return <Redirect to={{ pathname: baseRoute }} />;
  }

  return (
    <Container fluid>
      <ArrowLinkButton className="mb-4" linkTo={baseRoute} xlColSize={10} />
      <TabsNavigation
        baseRoute={baseRoute}
        itemId={damageClaimConfirmationCode}
        styleBreakpoint="sm"
        navTabs={[
          { name: 'userMyDamageClaims.tabs.damageClaimInfo', route: '/' },
          { name: 'userMyDamageClaims.tabs.damageClaimDocumentation', route: '/documentation' },
        ]}>
        <Switch>
          <Route exact path={`${baseRoute}/${damageClaimConfirmationCode}`}>
            <DamageClaimInfo damageClaim={damageClaim} dispatch={dispatch} anonymous />
          </Route>
          <Route exact path={`${baseRoute}/${damageClaimConfirmationCode}/documentation`}>
            <DamageClaimDocumentation damageClaim={damageClaim} anonymous />
          </Route>
        </Switch>
      </TabsNavigation>
    </Container>
  );
};
