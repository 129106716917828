// Ticket statuses
export const TICKET_STATUS_INITIATED = 'INITIATED';
export const TICKET_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const TICKET_STATUS_CLOSED = 'CLOSED';
// Ticket action statuses
export const TICKET_ACTION_STATUS_DONE = 'DONE';
export const TICKET_ACTION_STATUS_WAITING = 'WAITING';
export const TICKET_ACTION_STATUS_ACTION_REQUIRED = 'ACTION_REQUIRED';

export const TICKET_STATUS = [TICKET_STATUS_INITIATED, TICKET_STATUS_IN_PROGRESS, TICKET_STATUS_CLOSED];
