import gql from 'graphql-tag';

export const UserCreateClaimCommentMutation = gql`
  mutation UserCreateClaimComment(
    $claimClass: String
    $claimNo: String
    $claimConfirmationCode: String
    $comment: String!
  ) {
    createClaimComments(
      claimClass: $claimClass
      claimNo: $claimNo
      claimConfirmationCode: $claimConfirmationCode
      comment: $comment
    ) {
      newClaimCommentId
    }
  }
`;

export const UserUploadClaimFilesMutation = gql`
  mutation UserUploadClaimFiles(
    $claimClass: String
    $claimNo: String
    $claimConfirmationCode: String
    $comment: String!
    $files: [getClaimCommentsFilterFileInput]
  ) {
    createClaimComments(
      claimClass: $claimClass
      claimNo: $claimNo
      claimConfirmationCode: $claimConfirmationCode
      comment: $comment
      files: $files
    ) {
      newClaimCommentId
    }
  }
`;
