import { put, takeLatest, call } from 'redux-saga/effects';
import { get } from 'lodash-es';
import { LOAD_SUPPORT_DATA, storeSupportData } from '../actions/supportActions';
import { client } from '../../config/apolloConfig';
import { getErrorObject, isMaintenanceInProgress } from '../../constants/errorCodes';
import { redirectToMaintenancePage } from '../actions/navigationActions';
import { GetSupportDataQuery } from '../../operations/queries/GetSupportDataQuery';

function* loadSupportDataWorker() {
  try {
    const settingsResult = yield call(() =>
      client.query({
        query: GetSupportDataQuery,
      })
    );

    const supportData = {
      ...get(settingsResult, 'data.settings.INFORMATION', {}),
      ...get(settingsResult, 'data.settings.SUPPORT', {}),
    };

    yield put(storeSupportData(supportData));
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    }
  }
}

export function* loadSupportDataWatcher() {
  yield takeLatest(LOAD_SUPPORT_DATA, loadSupportDataWorker);
}
