import React from 'react';
import { Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash-es';
import { PolicyContracting } from '../../../containers/PolicyContracting';
import { STEP_1, POLICY_CODE_EU } from '../../../constants/business';
import { Step4Health } from './Step4Health';
import { Step3Health } from './Step3Health';
import { Step2Health } from './Step2Health';
import { Step1Health } from './Step1Health';
import { StepperWrapper } from '../../../containers';
import { getStepNumberAndId } from '../../../utils/manipulateSteps';
import { GetServerStorageQuery } from '../../../operations/queries/GetServerStorageQuery';
import {
  ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_2,
  ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_3,
  ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_4,
  ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE,
  ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE,
} from '../../../constants/routes';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { getSessionStoragePolicyEligibility } from '../../../constants/LocalStorageKeys';

const HealthStepRoute = ({ activeStep, stepIndex, children, path, stateKey }) => {
  const renderStep = stepIndex => {
    switch (stepIndex) {
      case 2:
        return `${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_3}/${stateKey}`;
      case 1:
        return `${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_2}/${stateKey}`;
      case 0:
      default:
        return ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE;
    }
  };

  if (activeStep >= stepIndex) {
    return <Route path={`${path}/${stateKey || ''}`}>{children}</Route>;
  }
  return <Redirect to={renderStep(activeStep)} />;
};

const HealthStepEndRoute = ({ path, confirmationCode, children }) => {
  if (confirmationCode) {
    return <Route path={path}>{children}</Route>;
  }
  return <Redirect to={ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE} />;
};

export const HealthStepper = ({ step = STEP_1, title, cancelButton = true }) => {
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const contractingId = get(params, 'contractingId', undefined);

  if (
    user.sector === USER_ACCOUNT_BUSINESS ||
    getSessionStoragePolicyEligibility(POLICY_CODE_EU) !== 'true' ||
    moment().diff(moment(user.dateOfBirth), 'years') > 65
  ) {
    return <Redirect to={ROUTE_TO_USER_POLICIES_HEALTH_INSURANCE_PAGE} />;
  }

  return (
    <PolicyContracting query={GetServerStorageQuery}>
      {({ data, error, stateKey, confirmationCode, paymentError }) => {
        const serverStep = get(data, 'state.step', 0);
        const stepData = get(data, 'state', {});

        if (
          (stateKey || contractingId) &&
          (paymentError || error || !stepData || (stepData.type && stepData.type !== POLICY_CODE_EU))
        ) {
          return <Redirect to={ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE} />;
        }

        const stepsArray = [
          { onClick: () => history.push(`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE}/${stateKey || ''}`) },
          { onClick: () => history.push(`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_2}/${stateKey}`) },
          { onClick: () => history.push(`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_3}/${stateKey}`) },
          { onClick: () => history.push(`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_4}/${stateKey}`) },
        ];
        const { activeStep } = getStepNumberAndId(serverStep, error, step, stepsArray.length);
        const stepProps = { activeStep, stateKey };

        return (
          <StepperWrapper
            steps={confirmationCode ? stepsArray.map(() => ({})) : stepsArray}
            activeStep={confirmationCode ? stepsArray.length : activeStep}
            policyTranslationBase="userMyPoliciesHealthInsurance"
            title={title}
            cancelButton={cancelButton}
            policyCode={POLICY_CODE_EU}
            stateKey={stateKey}>
            <Switch>
              <HealthStepEndRoute
                path={`${ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_4}/${confirmationCode}`}
                confirmationCode={confirmationCode}>
                <Step4Health data={stepData} stateKey={stateKey} />
              </HealthStepEndRoute>
              <HealthStepRoute path={ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_3} {...stepProps} stepIndex={2}>
                <Step3Health data={stepData} stateKey={stateKey} />
              </HealthStepRoute>
              <HealthStepRoute path={ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE_STEP_2} {...stepProps} stepIndex={1}>
                <Step2Health data={stepData} user={user} stateKey={stateKey} />
              </HealthStepRoute>
              <HealthStepRoute path={ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE} {...stepProps} stepIndex={0}>
                <Step1Health data={stepData} user={user} />
              </HealthStepRoute>
              <Redirect to={ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE} />
            </Switch>
          </StepperWrapper>
        );
      }}
    </PolicyContracting>
  );
};
