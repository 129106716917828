import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Label, InputGroup, Input } from 'reactstrap';
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash-es';
import { GetUserVehicleDataQuery } from '../../../operations/queries/GetUserVehicleDataQuery';
import { GetExpressPriceQuery } from '../../../operations/queries/GetPriceQuery';
import {
  VEHICLE_DATA,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_CANCELED,
  PAYMENT_STATUS_ERROR,
} from '../../../constants/business';
import { PaymentStep } from '../../../components/PolicyContractingSteps/PaymentStep';
import { CollapsibleTextInfo, DescriptionText } from '../../../containers';
import {
  ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_4,
  ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE,
} from '../../../constants/routes';
import { formatDate } from '../../../constants/dateFormatting';
import { handleRequestError } from '../../../constants/errorCodes';
import { GetPaymentStatusQuery } from '../../../operations/queries/GetPaymentStatusQuery';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { PaymentStatusSubscription } from '../../../operations/subscriptions/PaymentStatusSubscription';
import { BuyHokExpressProductMutation } from '../../../operations/mutations/BuyProductMutation';

export const Step3Express = ({ data, stateKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const newWindowTitle = formatMessage({ id: 'loading' });

  const win = useRef(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const insuranceStartDate = moment(data.insuranceStartDate).isSameOrAfter(moment(), 'day')
    ? moment(data.insuranceStartDate)
    : moment();
  const insuranceEndDate = moment(insuranceStartDate).add(1, 'year');

  const [getPaymentStatus, { data: paymentData, subscribeToMore }] = useLazyQuery(GetPaymentStatusQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });
  const [deleteServerStorageRecord] = useMutation(SetServerStorageMutation, {
    variables: { stateKey, shouldDelete: true },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const { data: calculationData, loading } = useQuery(GetExpressPriceQuery, {
    variables: {
      productRelatedData: {
        contractor: data.contractor,
        insuredPerson: data.insuredPerson,
        insuranceStartDate,
        registrationPlate: data.registrationPlate,
        vinNumber: data.vinNumber,
      },
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    fetchPolicy: 'cache-first',
  });

  const { loading: vehicleDataLoading, data: vData } = useQuery(GetUserVehicleDataQuery, {
    variables: {
      vinNumber: data.vinNumber,
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    fetchPolicy: 'cache-first',
  });

  const vehicleData = get(vData, 'vehicleData', {});

  const [buyHokExpress] = useMutation(BuyHokExpressProductMutation, {
    variables: {
      productRelatedData: {
        contractor: data.contractor,
        insuredPerson: data.insuredPerson,
        insuranceStartDate,
        registrationPlate: data.registrationPlate,
        vinNumber: data.vinNumber,
      },
    },
    onError: error => {
      win.current.close();
      setButtonDisabled(false);
      handleRequestError(error, dispatch);
    },
    onCompleted: data => {
      const confirmationCode = get(data, 'buyInsurancePolicyHokExpress.confirmationCode', null);
      if (confirmationCode) {
        getPaymentStatus({ variables: { confirmationCode } });
        // Workaround to not trigger popup blocker due to window.open() not being called
        // immediately after user action (onClick event) but after an async function
        win.current.location = `${ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE}/${confirmationCode}`;
      }
    },
  });

  const onClick = useCallback(() => {
    win.current = window.open();
    win.current.document.title = newWindowTitle;
    setButtonDisabled(true);
    buyHokExpress();
  }, [buyHokExpress, newWindowTitle]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      let _tabToggleState = localStorage.getItem('tabStateStorage');
      if(_tabToggleState == "true"){
        history.push('/dashboard');
      }
    })
  }, [history])

  useEffect(() => {
    if (get(paymentData, 'getPaymentData.confirmationCode', '')) {
      subscribeToMore({
        document: PaymentStatusSubscription,
        variables: { confirmationCode: paymentData.getPaymentData.confirmationCode },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return { getPaymentData: subscriptionData.data.payments };
        },
        onError: error => handleRequestError(error, dispatch, { silent: true }),
      });
    }

    if (get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_PAID) {
      deleteServerStorageRecord();
      history.push(`${ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_4}/${paymentData.getPaymentData.confirmationCode}`);
    }
    if (
      get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_CANCELED ||
      get(paymentData, 'getPaymentData.status', '') === PAYMENT_STATUS_ERROR
    ) {
      setButtonDisabled(false);
    }
  }, [dispatch, history, paymentData, subscribeToMore, deleteServerStorageRecord]);

  return (
    <PaymentStep
      policyTranslationBase="userMyPoliciesExpressInsurance"
      premiumAmount={get(calculationData, 'calculatePriceHokExpress.totalPremium', 0)}
      nextStep={ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_4}
      data={data}
      onClick={onClick}
      loaderProp={loading || vehicleDataLoading || buttonDisabled}>
      <Row className="mt-4 mb-3">
        <Col>
          <h3>
            <FormattedMessage id="userMyPolicies.policyData.title" />:
          </h3>
        </Col>
      </Row>
      <Row>
        <Col sm="6" md="4">
          <Label for="validFrom">
            <FormattedMessage id="inputs.validFrom" />
          </Label>
          <InputGroup>
            <Input id="validFrom" disabled defaultValue={formatDate(insuranceStartDate)} />
          </InputGroup>
        </Col>
        <Col sm="6" md="4">
          <Label for="validTo">
            <FormattedMessage id="inputs.validTo" />
          </Label>
          <InputGroup>
            <Input id="validTo" disabled defaultValue={formatDate(insuranceEndDate)} />
          </InputGroup>
        </Col>
        <Col sm="12" md="4">
          <Label for="licensePlate">
            <FormattedMessage id="inputs.licensePlate" />
          </Label>
          <CollapsibleTextInfo rawPlaceholder pushdown uppercase placeholder={data.registrationPlate}>
            <Container>
              <Row>
                <Col xs={12}>
                  <DescriptionText value={VEHICLE_DATA} data={vehicleData} />
                </Col>
              </Row>
            </Container>
          </CollapsibleTextInfo>
        </Col>
      </Row>
    </PaymentStep>
  );
};
