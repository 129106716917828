export const LOAD_USERS = 'LOAD_USERS';
export const loadUsers = () => ({
  type: LOAD_USERS,
});

export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const loadUsersSuccess = users => ({
  type: LOAD_USERS_SUCCESS,
  payload: users,
});

export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const loadUsersFailure = () => ({
  type: LOAD_USERS_FAILURE,
});

export const CREATE_USER = 'CREATE_USER';
export const createUser = user => ({
  type: CREATE_USER,
  payload: user,
});

export const CREATE_USER_FEEDBACK = 'CREATE_USER_FEEDBACK';
export const createUserFeedback = () => ({
  type: CREATE_USER_FEEDBACK,
});

export const UPDATE_USER = 'UPDATE_USER';
export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
});

export const UPDATE_USER_FEEDBACK = 'UPDATE_USER_FEEDBACK';
export const updateUserFeedback = () => ({
  type: UPDATE_USER_FEEDBACK,
});

export const DELETE_USER = 'DELETE_USER';
export const deleteUser = userId => ({
  type: DELETE_USER,
  payload: userId,
});

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const deleteUserSuccess = userId => ({
  type: DELETE_USER_SUCCESS,
  payload: userId,
});

export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const createEmployee = user => ({
  type: CREATE_EMPLOYEE,
  payload: user,
});

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const updateEmployee = user => ({
  type: UPDATE_EMPLOYEE,
  payload: user,
});

export const UPDATE_EMPLOYEE_FEEDBACK = 'UPDATE_EMPLOYEE_FEEDBACK';
export const updateEmployeeFeedback = user => ({
  type: UPDATE_EMPLOYEE_FEEDBACK,
  payload: user,
});

export const CREATE_EMPLOYEE_FEEDBACK = 'CREATE_EMPLOYEE_FEEDBACK';
export const createEmployeeFeedback = () => ({
  type: CREATE_EMPLOYEE_FEEDBACK,
});
