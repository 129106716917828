import gql from 'graphql-tag';

export const GetUsersQuery = gql`
  query GetUsers($filter: getUsersFilterInput, $order: getUsersSortFilterInput, $skip: Int, $take: Int) {
    getUsers(filter: $filter, order: $order, skip: $skip, take: $take) {
      users {
        id
        partnerId
        firstName
        lastName
        businessName
        businessFirstName
        businessLastName
        email
        phoneNumber
        oib
        sex
        sector
        verified
		    deleted
        role
        avatar
        createdAt
      }
      totalCount
      queryCount
    }
  }
`;

export const GetEmployeesForAuditFilterQuery = gql`
  query GetEmployeesForAuditFilter {
    getUsers(filter: { isEmployee: true }, order: { firstName: ASC }) {
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GetSingleUserQuery = gql`
  query GetSingleUser($id: Int) {
    getSingleUser: getUsers(filter: { id: $id, isEmployee: false }, take: 1) {
      users {
        id
        username
        firstName
        lastName
        role
        sex
        sector
        title
        email
        phoneNumber
        avatar
        activated
        verified
		    deleted
        accountLocked
        dateOfBirth
        oib
        partnerId
        businessName
        mbo
        VATpayer
        webAddress
        businessTelephone
        businessFirstName
        businessLastName
        governmentIssuedIdNumber
        verificationComment
        passportNumber
        HZZONumber
        ino
        address
        zip
        city
        country
        wayOfContactForInvoice
        wayOfContactForNotifications
        wayOfContactForConfirmations
      }
    }
  }
`;

export const GetSingleEmployeeQuery = gql`
  query GetSingleUser($id: Int) {
    getSingleUser: getUsers(filter: { id: $id, isEmployee: true }, take: 1) {
      users {
        id
        username
        firstName
        lastName
        role
        sex
        sector
        title
        email
        phoneNumber
        avatar
        activated
        verified
        deleted
        accountLocked
        dateOfBirth
        oib
        partnerId
        mbo
        address
        zip
        city
        country
      }
    }
  }
`;

export const GetUserDataQuery = gql`
  query GetUsers($id: Int) {
    getUsers(filter: { id: $id }, take: 1) {
      users {
        id
        partnerId
        firstName
        lastName
        businessName
        email
        phoneNumber
        sector
        role
      }
    }
  }
`;
