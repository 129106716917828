import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink as Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HokChevronUpIcon } from '../../../img/icons/hok-chevron-up.svg';
import { ReactComponent as HokChevronDownIcon } from '../../../img/icons/hok-chevron-down.svg';
import {
  ROUTE_TO_USER_PROFILE_PAGE,
  ROUTE_TO_USER_PROFILE_ACCOUNT_PAGE,
  ROUTE_TO_USER_PROFILE_ADDITIONAL_DATA_PAGE,
  // ROUTE_TO_USER_PROFILE_NOTIF_SETTINGS_PAGE,
  ROUTE_TO_EMPLOYEE_PROFILE_PAGE,
  ROUTE_TO_EMPLOYEE_PROFILE_ACCOUNT_PAGE,
  ROUTE_TO_EMPLOYEE_PROFILE_ADDITIONAL_DATA_PAGE,
  // ROUTE_TO_EMPLOYEE_PROFILE_NOTIF_SETTINGS_PAGE,
} from '../../../constants/routes';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';

const UserProfileContainer = props => {
  const [isDropdownOpen, toggleDropdown] = useState(false);
  const path = useSelector(state => state.router.location.pathname);
  const sector = useSelector(state => state.auth.user.sector);
  const isAdminDashboard = /^\/admin/.test(path);

  const toggle = () => {
    toggleDropdown(!isDropdownOpen);
  };

  const navItems = [
    {
      name: 'userData',
      route: !isAdminDashboard ? ROUTE_TO_USER_PROFILE_PAGE : ROUTE_TO_EMPLOYEE_PROFILE_PAGE,
    },
    {
      name: 'accountInfo',
      route: !isAdminDashboard ? ROUTE_TO_USER_PROFILE_ACCOUNT_PAGE : ROUTE_TO_EMPLOYEE_PROFILE_ACCOUNT_PAGE,
    },
    {
      name: sector === USER_ACCOUNT_BUSINESS ? 'contactInfo' : 'additionalData',
      route: !isAdminDashboard
        ? ROUTE_TO_USER_PROFILE_ADDITIONAL_DATA_PAGE
        : ROUTE_TO_EMPLOYEE_PROFILE_ADDITIONAL_DATA_PAGE,
    },
    // {
    //   name: 'notificationSettings',
    //   route: !isAdminDashboard
    //     ? ROUTE_TO_USER_PROFILE_NOTIF_SETTINGS_PAGE
    //     : ROUTE_TO_EMPLOYEE_PROFILE_NOTIF_SETTINGS_PAGE,
    // },
  ];

  const renderTabsNavigation = () => (
    <Nav tabs className="hok-user-profile-tabs d-none d-lg-flex">
      {navItems.map(tab => (
        <NavItem key={tab.name}>
          <NavLink exact tag={Link} to={tab.route}>
            <FormattedMessage id={`userProfilePage.tabsNav.${tab.name}`} />
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  const renderSmallScreenTabsNav = () => (
    <Nav tabs className="hok-user-profile-tabs d-flex d-lg-none">
      <Dropdown nav isOpen={isDropdownOpen} toggle={toggle} className="hok-dropdown-tab-full">
        <DropdownToggle nav className="d-flex align-items-center justify-content-between">
          <FormattedMessage id={`userProfilePage.tabsNav.dropdownMenu`} />
          <div>
            <div>{isDropdownOpen ? <HokChevronUpIcon /> : <HokChevronDownIcon />}</div>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {navItems.map(tab => (
            <DropdownItem exact key={tab.name} tag={Link} to={tab.route}>
              <FormattedMessage id={`userProfilePage.tabsNav.${tab.name}`} />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );

  return (
    <Container fluid>
      <Row className="no-gutters">
        <Col xs="12" xl={{ size: 10, offset: 1 }}>
          {renderTabsNavigation()}
          {renderSmallScreenTabsNav()}
          {props.children}
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfileContainer;
