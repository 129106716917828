export const REDIRECT_TO_ADMIN_LOGIN = 'REDIRECT_TO_ADMIN_LOGIN';
export const redirectToAdminLogin = () => {
  return {
    type: REDIRECT_TO_ADMIN_LOGIN,
  };
};

export const REDIRECT_TO_ADMIN_DASHBOARD = 'REDIRECT_TO_ADMIN_DASHBOARD';
export const redirectToAdminDashboard = () => {
  return {
    type: REDIRECT_TO_ADMIN_DASHBOARD,
  };
};

export const REDIRECT_TO_ADMIN_ERROR_PAGE = 'REDIRECT_TO_ADMIN_ERROR_PAGE';
export const redirectToAdminErrorPage = () => {
  return {
    type: REDIRECT_TO_ADMIN_ERROR_PAGE,
  };
};
export const REDIRECT_TO_ADMIN_EMPLOYEE_LIST_PAGE = 'REDIRECT_TO_ADMIN_EMPLOYEE_LIST_PAGE';
export const redirectToAdminEmployeeListPage = () => {
  return {
    type: REDIRECT_TO_ADMIN_EMPLOYEE_LIST_PAGE,
  };
};

export const REDIRECT_TO_ADMIN_USER_LIST_PAGE = 'REDIRECT_TO_ADMIN_USER_LIST_PAGE';
export const redirectToAdminUserListPage = () => {
  return {
    type: REDIRECT_TO_ADMIN_USER_LIST_PAGE,
  };
};

// User Navigation Actions

export const REDIRECT_TO_USER_LOGIN_PAGE = 'REDIRECT_TO_USER_LOGIN_PAGE';
export const redirectToUserLoginPage = () => {
  return {
    type: REDIRECT_TO_USER_LOGIN_PAGE,
  };
};

export const REDIRECT_TO_USER_DASHBOARD_PAGE = 'REDIRECT_TO_USER_DASHBOARD_PAGE';
export const redirectToUserDashboardPage = () => {
  return {
    type: REDIRECT_TO_USER_DASHBOARD_PAGE,
  };
};

export const REDIRECT_TO_USER_ACCOUNT_INFO_PAGE = 'REDIRECT_TO_USER_ACCOUNT_INFO_PAGE';
export const redirectToUserAccountInfoPage = accountType => {
  return {
    type: REDIRECT_TO_USER_ACCOUNT_INFO_PAGE,
    accountType,
  };
};

export const REDIRECT_TO_MAINTENANCE_PAGE = 'REDIRECT_TO_MAINTENANCE_PAGE';
export const redirectToMaintenancePage = () => {
  return {
    type: REDIRECT_TO_MAINTENANCE_PAGE,
  };
};

export const REDIRECT_TO_REGISTRATION_COMPLETE_PAGE = 'REDIRECT_TO_REGISTRATION_COMPLETE_PAGE';
export const redirectToRegistrationCompletePage = payload => {
  return {
    type: REDIRECT_TO_REGISTRATION_COMPLETE_PAGE,
    payload,
  };
};

export const REDIRECT_TO_USER_PRODUCTS_PAGE = 'REDIRECT_TO_USER_PRODUCTS_PAGE';
export const redirectToUserProductsPage = () => {
  return {
    type: REDIRECT_TO_USER_PRODUCTS_PAGE,
  };
};

export const REDIRECT_TO_USER_NEW_TRAVEL_INSURANCE_PAGE = 'REDIRECT_TO_USER_NEW_TRAVEL_INSURANCE_PAGE';
export const redirectToUserNewTravelInsuracePage = () => {
  return {
    type: REDIRECT_TO_USER_NEW_TRAVEL_INSURANCE_PAGE,
  };
};

export const REDIRECT_TO_USER_NEW_HEALTH_INSURANCE_PAGE = 'REDIRECT_TO_USER_NEW_HEALTH_INSURANCE_PAGE';
export const redirectToUserNewHealthInsuracePage = () => {
  return {
    type: REDIRECT_TO_USER_NEW_HEALTH_INSURANCE_PAGE,
  };
};

export const REDIRECT_TO_SPECIFIC_DAMAGE_CLAIM_PAGE = 'REDIRECT_TO_SPECIFIC_DAMAGE_CLAIM_PAGE';
export const redirectToSpecificDamageClaimPage = claimNumber => {
  return {
    type: REDIRECT_TO_SPECIFIC_DAMAGE_CLAIM_PAGE,
    claimNumber,
  };
};

// Admin navigation actions
export const REDIRECT_TO_ADMIN_CREATE_USER_PAGE = 'REDIRECT_TO_ADMIN_CREATE_USER_PAGE';
export const redirectToAdminCreateUserPage = () => {
  return {
    type: REDIRECT_TO_ADMIN_CREATE_USER_PAGE,
  };
};

export const REDIRECT_TO_ADMIN_PREVIEW_POST_PAGE = 'REDIRECT_TO_ADMIN_PREVIEW_POST_PAGE';
export const redirectToAdminPreviewPostPage = postId => {
  return {
    type: REDIRECT_TO_ADMIN_PREVIEW_POST_PAGE,
    payload: postId,
  };
};

export const REDIRECT_TO_ADMIN_EDIT_POST_PAGE = 'REDIRECT_TO_ADMIN_EDIT_POST_PAGE';
export const redirectToAdminEditPostPage = postId => {
  return {
    type: REDIRECT_TO_ADMIN_EDIT_POST_PAGE,
    payload: postId,
  };
};

export const REDIRECT_TO_ADMIN_CREATE_POST_PAGE = 'REDIRECT_TO_ADMIN_CREATE_POST_PAGE';
export const redirectToAdminCreatePostPage = () => {
  return {
    type: REDIRECT_TO_ADMIN_CREATE_POST_PAGE,
  };
};

export const REDIRECT_TO_ADMIN_EDIT_USER_PAGE = 'REDIRECT_TO_ADMIN_EDIT_USER_PAGE';
export const redirectToAdminEditUserPage = userId => {
  return {
    type: REDIRECT_TO_ADMIN_EDIT_USER_PAGE,
    payload: userId,
  };
};

export const REDIRECT_TO_ADMIN_EDIT_DELETED_USER_PAGE = 'REDIRECT_TO_ADMIN_EDIT_DELETED_USER_PAGE';
export const redirectToAdminEditDeletedUserPage = userId => {
  return {
    type: REDIRECT_TO_ADMIN_EDIT_DELETED_USER_PAGE,
    payload: { userId, deleted: true },
  };
};

export const REDIRECT_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_PAGE = 'REDIRECT_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_PAGE';
export const redirectToAdminEditUserSingleClaimPage = (userId, claimNumber) => {
  return {
    type: REDIRECT_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_PAGE,
    payload: { userId, claimNumber },
  };
};

export const REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_PAGE = 'REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_PAGE';
export const redirectToAdminEditEmployeeSingleClaimPage = (userId, claimNumber) => {
  return {
    type: REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_PAGE,
    payload: { userId, claimNumber },
  };
};

export const REDIRECT_TO_ADMIN_CREATE_EMPLOYEE_PAGE = 'REDIRECT_TO_ADMIN_CREATE_EMPLOYEE_PAGE';
export const redirectToAdminCreateEmployeePage = () => {
  return {
    type: REDIRECT_TO_ADMIN_CREATE_EMPLOYEE_PAGE,
  };
};

export const REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_PAGE = 'REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_PAGE';
export const redirectToAdminEditEmployeePage = employeeId => {
  return {
    type: REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_PAGE,
    payload: employeeId,
  };
};

export const REDIRECT_TO_ADMIN_SINGLE_TICKET_PAGE = 'REDIRECT_TO_ADMIN_SINGLE_TICKET_PAGE';
export const redirectToAdminSingleTicketPage = ticketId => {
  return {
    type: REDIRECT_TO_ADMIN_SINGLE_TICKET_PAGE,
    payload: ticketId,
  };
};
