import React from 'react';
import { MY_POLICIES_TRAVEL_INSURANCE_PAGE } from '../../../constants/business';
import { EndStep } from '../../../components/PolicyContractingSteps/EndStep';

export const Step4Travel = () => {
  return (
    <EndStep
      policyTranslationBase="userMyPoliciesTravelInsurance"
      policyPage={MY_POLICIES_TRAVEL_INSURANCE_PAGE}
      messageId="congratsMessage"
    />
  );
};
