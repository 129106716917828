export const CANCEL_CONTRACTING = 'CANCEL_CONTRACTING';
export const SELF_INSURANCE_CONTRACTING = 'SELF_INSURANCE_CONTRACTING';
export const TRAVEL_PARTICIPATING = 'TRAVEL_PARTICIPATING';

export const TRAVEL_PURPOSE_MODAL = 'TRAVEL_PURPOSE_MODAL';
export const TRAVEL_GROUP_TYPE_MODAL = 'TRAVEL_GROUP_TYPE_MODAL';

export const EXPRESS_ELIGIBILITY_MODAL = 'EXPRESS_ELIGIBILITY_MODAL';

export const VEHICLE_PURPOSE_RACING = 'VEHICLE_PURPOSE_RACING';
export const VEHICLE_PURPOSE = 'VEHICLE_PURPOSE';
export const VEHICLE_AGE_CHECK = 'VEHICLE_AGE_CHECK';
export const VEHICLE_OWNER_DISABILITY = 'VEHICLE_OWNER_DISABILITY';
export const VEHICLE_INSURANCE_INELIGIBILITY = 'VEHICLE_INSURANCE_INELIGIBILITY';

export const NOT_ELIGIBLE_AO_VEHICLE_TYPE = 'NOT_ELIGIBLE_AO_VEHICLE_TYPE';

export const SAVE_CHANGES = 'SAVE_CHANGES';

export const VEHICLE_INFO_ENTRY = 'VEHICLE_INFO_ENTRY';

export const CONTINUE_PREVIOUS_CONTRACTING = 'CONTINUE_PREVIOUS_CONTRACTING';
export const CONTRACTING_AGE_ELIGIBILITY = 'CONTRACTING_AGE_ELIGIBILITY';

export const DAMAGE_CLAIM_SUBMIT_SUCCESS = 'DAMAGE_CLAIM_SUBMIT_SUCCESS';
export const DAMAGE_CLAIM_SUBMIT_FAIL = 'DAMAGE_CLAIM_SUBMIT_FAIL';
