import React, { useState } from 'react';
import { Row, Col, Collapse, Card, CardHeader, CardBody } from 'reactstrap';
import { ReactComponent as HokCloseIcon } from '../../img/icons/hok-close.svg';
import { ReactComponent as HokErrorIcon } from '../../img/icons/hok-error.svg';

//Images
import { ReactComponent as KeyboardUpIcon } from '../../img/icons/hok-chevron-up.svg';
import { ReactComponent as KeyboardDownIcon } from '../../img/icons/hok-chevron-down.svg';

const CollapsibleContainer = ({
  title = 'placeholder',
  children,
  expanded = false,
  assistance = false,
  onClick,
  invalid = false,
}) => {
  const [isOpen, setOpen] = useState(expanded);
  const toggle = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="collapsible-container mt-0 mb-0">
      <Card
        style={{
          border: '1px solid #cbccce',
          borderBottom: isOpen ? '1px solid #cbccce' : '2px solid #707070',
          borderBottomLeftRadius: isOpen ? '5px' : 0,
          borderBottomRightRadius: isOpen ? '5px' : 0,
        }}
      >
        <CardHeader>
          <Row>
            <Col className="pl-0" xs="12">
              <div
                className={
                  'd-flex flex-column card-text' + (!assistance ? ' cursor-pointer' : '') + (invalid ? ' invalid' : '')
                }
                onClick={!assistance ? toggle : undefined}
              >
                <h4 className={'d-flex align-items-center ' + (assistance ? 'main-title-red' : 'main-title')}>
                  {title}
                  {invalid && <HokErrorIcon className="hok-invalid-status-icon" />}
                </h4>
                {assistance ? (
                  <HokCloseIcon
                    className="keyboard-icon align-self-end"
                    height="24"
                    width="24"
                    onClick={typeof onClick === 'function' ? onClick : undefined}
                  />
                ) : isOpen ? (
                  <KeyboardUpIcon className="keyboard-icon align-self-end" />
                ) : (
                  <KeyboardDownIcon className="keyboard-icon align-self-end" />
                )}
              </div>
            </Col>
          </Row>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>{children}</CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

export default CollapsibleContainer;
