import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { FormattedHTMLMessage } from 'react-intl';
import ReactResizeDetector from 'react-resize-detector';
import { get } from 'lodash-es';
import { PolicyCard, Spinner } from '../../containers';
import {
  ROUTE_TO_USER_POLICIES_PAGE,
  ROUTE_TO_USER_MY_POLICIES_PAGE,
  ROUTE_TO_USER_ASSISTANCE_PAGE,
} from '../../constants/routes';
import { ReactComponent as HokPlusIcon } from '../../img/icons/hok-plus.svg';
import { GetUserPolicyGroupsQuery, GetUserAssistanceQuery } from '../../operations/queries/GetUserPoliciesQuery';
import { policyGroupDefs, assistanceGroupDefs, POLICY_STATUS_ACTIVE } from '../../constants/business';
import { handleRequestError } from '../../constants/errorCodes';

export const MyPoliciesCardList = ({
  smOnly = false,
  resizableContainer = false,
  showCreateNew = false,
  children = null,
  assistance = false,
}) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(assistance ? GetUserAssistanceQuery : GetUserPolicyGroupsQuery, {
    ...(assistance && { variables: { filter: { status: POLICY_STATUS_ACTIVE } } }),
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const policyGroups = assistance
    ? get(data, 'getInsurancePolicy.insurancePoliciesGroupedByAssistance', []).sort((a, b) => {
        return a.type < b.type ? 1 : -1;
      })
    : get(data, 'getInsurancePolicy.insurancePoliciesGroupedByProductGroup', []).sort((a, b) => {
        return a.groupOrder > b.groupOrder ? 1 : -1;
      });

  if ((!data && !error) || loading) {
    return <Spinner className="mx-auto" />;
  }

  const renderCards = () => {
    if (policyGroups.length > 0) {
      return (
        <>
          {policyGroups.map(group => {
            const { type, countActive } = group;
            const groupDef = assistance ? assistanceGroupDefs[type] : policyGroupDefs[type];
            return (
              <PolicyCard
                key={groupDef.cardName}
                text={groupDef.cardName}
                Pic={groupDef.icon}
                counter={assistance ? null : countActive}
                cardName={groupDef.cardName}
                route={`${assistance ? ROUTE_TO_USER_ASSISTANCE_PAGE : ROUTE_TO_USER_MY_POLICIES_PAGE}/${
                  groupDef.route
                }`}
                smOnly={smOnly}
                translationBase={assistance ? 'userAssistancePage' : 'userPolicies'}
              />
            );
          })}
          {showCreateNew && (
            <div className="hok-card-policy-wrapper">
              <Link
                className="hok-card-policy-link"
                to={assistance ? ROUTE_TO_USER_ASSISTANCE_PAGE : ROUTE_TO_USER_POLICIES_PAGE}>
                <Card className="hok-card-policy-new cursor-pointer">
                  <CardBody>
                    <Row className="mt-4">
                      <Col xs="5" className="d-flex align-items-center justify-content-center">
                        <HokPlusIcon className="svg-white" width="72px" height="72px" />
                      </Col>
                      <Col xs="7">
                        <div className="">
                          <p className="text-center new-policy mt-3">
                            <FormattedHTMLMessage id="userMyPolicies.newPolicy" />
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Link>
              <Link
                className="hok-card-policy-link-sm"
                to={assistance ? ROUTE_TO_USER_ASSISTANCE_PAGE : ROUTE_TO_USER_POLICIES_PAGE}>
                <Card className="hok-card-policy-new-sm cursor-pointer">
                  <HokPlusIcon className="mt-2 hok-img-policy-sm svg-white" width="72px" height="72px" />
                  <CardBody className="p-0 d-flex align-items-center justify-content-center">
                    <p className="text-center m-0">
                      <FormattedHTMLMessage id="userMyPolicies.newPolicy" />
                    </p>
                  </CardBody>
                </Card>
              </Link>
            </div>
          )}
        </>
      );
    }
    return children;
  };

  const renderInResizableContainer = () => (
    <ReactResizeDetector handleWidth>
      {({ width }) => (
        <Col className="text-left">
          <div
            className="mx-auto"
            style={
              policyGroups.length > 0
                ? {
                    width:
                      policyGroups.length * 160 > width
                        ? `${Math.min(Math.floor(width / 160), Math.ceil(policyGroups.length / 2)) * 160}px`
                        : '100%',
                  }
                : {}
            }>
            {renderCards()}
          </div>
        </Col>
      )}
    </ReactResizeDetector>
  );

  return resizableContainer ? renderInResizableContainer() : renderCards();
};
