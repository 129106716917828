import React from 'react';
import { CustomButton } from '../../containers';

export const GdprConsentNotification = ({ acceptOnClick }) => {
  return (
    <div className="hok-cookie-policy">
      <div className="hok-cookie-policy-text">
        <p>
          Koristimo kolačiće kako bismo Vam mogli prikazati web stranicu, razumijeti kako je koristite i prikazati Vam
          oglase koji bi Vas mogli zanimati.
        </p>
      </div>
      <div className="hok-cookie-policy-buttons">
        {/* <CustomButton
          className="hok-outline-btn mr-0 mr-sm-2 d-block d-sm-inline-block w-xs-100"
          outline
          block={false}
          onClick={() => acceptOnClick()}
          translationId="decline"
        /> */}
        <CustomButton
          className="hok-dashboard-btn ml-0 ml-sm-2 d-block d-sm-inline-block w-xs-100"
          block={false}
          onClick={() => acceptOnClick()}
          translationId="accept"
        />
      </div>
    </div>
  );
};
