import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, CardGroup, Card, CardHeader, CardBody } from 'reactstrap';
import { BackgroundAnimation } from '../../../components/BackgroundAnimation';
import { FormattedMessage } from 'react-intl';
import { PublicSingleClaim } from './PublicSingleClaim';
import { ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW } from '../../../constants/routes';
import { useGoogleAnalyticsPageview } from '../../../utils/customHooks';

export const QuickClaimPreviewData = () => {
  const params = useParams();

  useGoogleAnalyticsPageview('Brza_Prijava_Stete_Pregled_Stete');

  const { damageClaimCode } = params;

  return (
    <div className="d-flex align-items-center quick-report">
      <BackgroundAnimation />
      <Container fluid>
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <CardGroup className="quick-report-card-group">
              <Card className="w-100">
                <CardHeader className="py-1">
                  <h2 className="d-block">
                    <FormattedMessage id="damageClaimPreviewData.title" />: {damageClaimCode}
                  </h2>
                </CardHeader>
                <CardBody className="hok-user-dashboard">
                  <PublicSingleClaim
                    baseRoute={ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW}
                    damageClaimId={null}
                    damageClaimConfirmationCode={damageClaimCode}
                  />
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
