import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ArrowLinkButton, ScreenTitleContainer } from '../../../containers';
import { FormattedMessage } from 'react-intl';

export const HealthInsuranceExaminations = () => {
  const col1ListItems = [
    'Laboratorij [KS, Se, Glukoza, kreatinin, bilirubin, urati, ukupni kolesterol, LDL, HDL, Trigliceridi, AST, ALT,GGT, Fe, TSH',
    'Kompletan nalaz urina',
    'EKG s očitanjem',
    'Pregled internista',
    'Pregled oftalmologa',
    'UZV abdomena',
    'UZV dojke',
    'Pregled ginekologa [uključivo PAPA i TV UZV]',
    'UZV štitnjače',
    'Denzitometrija [samo iznad 50 god]',
    'Mamografija [samo iznad 40 god]',
    'UZV color doppler karotida [samo iznad 50 god]',
    'RTG srca i pluća [samo na indikaciju]',
    'Haemocultni test stolice na okultno krvarenje [samo iznad 40 god]',
  ];

  const col2ListItems = [
    'Laboratorij [KS, Se, Glukoza, kreatinin, bilirubin, urati, ukupni kolesterol, LDL, HDL, Trigliceridi, AST, ALT,GGT, Fe, TSH',
    'Kompletan nalaz urina',
    'PSA',
    'Pregled urologa [na indikaciju PSA]',
    'EKG s očitanjem',
    'Pregled internista',
    'Pregled oftalmologa',
    'UZV abdomena',
    'UZV štitnjače',
    'UZV prostate',
    'Ergometrija [samo na indikaciju]',
    'UZV color doppler karotida [samo iznad 50 god]',
    'Haemocultni test stolice na okultno krvarenje [samo iznad 40 god]',
    'RTG srca i pluća [samo na indikaciju]',
  ];

  return (
    <Container fluid className="policies">
      <ArrowLinkButton linkTo={null} xlColSize={10} />
      <ScreenTitleContainer
        row
        className="mt-4"
        titleId="userMyPoliciesHealthInsurance.fullMedicalExaminationTitle"
        xlColSize="10"
        xlColOffset="1"
      />
      <Row className="mt-4 hok-health-info">
        <Col xs="12" md="6" xl={{ offset: 1, size: 5 }}>
          <h5>
            <FormattedMessage id="userMyPoliciesHealthInsurance.forWomen" />:
          </h5>
          <ol className="pl-0">
            {col1ListItems.map(item => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        </Col>
        <Col xs="12" md="6" xl={{ size: 5 }} className="mt-4 mt-md-0">
          <h5>
            <FormattedMessage id="userMyPoliciesHealthInsurance.forMan" />:
          </h5>
          <ol className="pl-0">
            {col2ListItems.map(item => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        </Col>
      </Row>
    </Container>
  );
};
