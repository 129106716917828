import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ROUTE_TO_USER_DASHBOARD_PAGE,
  ROUTE_TO_USER_POLICIES_PAGE,
  ROUTE_TO_USER_MY_POLICIES_PAGE,
  ROUTE_TO_USER_NEWS_PAGE,
  ROUTE_TO_ADMIN_DASHBOARD_PAGE,
  ROUTE_TO_USER_MY_INVOICES_PAGE,
  ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE,
  ROUTE_TO_USER_FAQS_PAGE,
  ROUTE_TO_USER_SUPPORT_PAGE,
  ROUTE_TO_USER_TERMS_OF_SERVICE_PAGE,
  ROUTE_TO_USER_ASSISTANCE_PAGE,
  ROUTE_TO_GENERAL_INFO_PAGE,
  ROUTE_TO_USER_REPORT_DAMAGE,
  ROUTE_TO_USER_TICKETING_PAGE,
} from '../../constants/routes';
import { USER, ADMIN, EMPLOYEE, SUPER_ADMIN } from '../../constants/roles';
import { ReactComponent as HokHomeIcon } from '../../img/icons/hok-home.svg';
import { ReactComponent as HokPoliciesIcon } from '../../img/icons/hok-policies.svg';
import { ReactComponent as HokInvoicesIcon } from '../../img/icons/hok-invoices.svg';
import { ReactComponent as HokClaimsIcon } from '../../img/icons/hok-claims.svg';
import { ReactComponent as HokProductsIcon } from '../../img/icons/hok-products.svg';
import { ReactComponent as HokSupportIcon } from '../../img/icons/hok-support.svg';
import { ReactComponent as HokAssistanceIcon } from '../../img/icons/hok-assistance.svg';
import { ReactComponent as HokNewsIcon } from '../../img/icons/hok-news.svg';
import { ReactComponent as HokFaqIcon } from '../../img/icons/hok-faq.svg';
import { ReactComponent as HokGeneralInfoIcon } from '../../img/icons/hok-error.svg';
import { ReactComponent as HokAdministrationIcon } from '../../img/icons/hok-administration.svg';
import { ReactComponent as HokSubmitClaimIcon } from '../../img/icons/hok-submit-claim.svg';
import { ReactComponent as HokTicketingIcon } from '../../img/icons/hok-ticketing.svg';

const userNavigation = [
  {
    name: (
      <>
        <HokHomeIcon className="svg-menu" />
        <FormattedMessage id="routes.dashboard" />
      </>
    ),
    url: ROUTE_TO_USER_DASHBOARD_PAGE,
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <HokProductsIcon className="svg-menu" />
        <FormattedMessage id="routes.policies" />
      </>
    ),
    url: ROUTE_TO_USER_POLICIES_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokSubmitClaimIcon className="svg-menu" />
        <FormattedMessage id="routes.reportDamage" />
      </>
    ),
    url: ROUTE_TO_USER_REPORT_DAMAGE,
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <HokPoliciesIcon className="svg-menu" />
        <FormattedMessage id="routes.myPolicies" />
      </>
    ),
    url: ROUTE_TO_USER_MY_POLICIES_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokInvoicesIcon className="svg-menu" />
        <FormattedMessage id="routes.myInvoices" />
      </>
    ),
    url: ROUTE_TO_USER_MY_INVOICES_PAGE,
    icon: 'd-none',
  },
  /*{
    name: (
      <>
        <img className="nav-icon" src={myVouchersIcon} alt="my vouchers" />
        <FormattedMessage id="routes.myVouchers" />
      </>
    ),
    url: 'ROUTE_TO_USER_DASHBOARD_PAGE',
    icon: 'd-none',
  },*/
  {
    name: (
      <>
        <HokClaimsIcon className="svg-menu" />
        <FormattedMessage id="routes.myDamages" />
      </>
    ),
    url: ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokTicketingIcon className="svg-menu" />
        <FormattedMessage id="routes.clientTicketing" />
      </>
    ),
    url: ROUTE_TO_USER_TICKETING_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokAssistanceIcon className="svg-menu" />
        <FormattedMessage id="routes.assistance" />
      </>
    ),
    url: ROUTE_TO_USER_ASSISTANCE_PAGE,
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <HokSupportIcon className="svg-menu" />
        <FormattedMessage id="routes.customerSupport" />
      </>
    ),
    url: ROUTE_TO_USER_SUPPORT_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokSubmitClaimIcon className="svg-menu" />
        <FormattedMessage id="routes.termsOfService" />
      </>
    ),
    url: ROUTE_TO_USER_TERMS_OF_SERVICE_PAGE,
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <HokNewsIcon className="svg-menu" />
        <FormattedMessage id="routes.news" />
      </>
    ),
    url: ROUTE_TO_USER_NEWS_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokFaqIcon className="svg-menu" />
        <FormattedMessage id="routes.faq" />
      </>
    ),
    url: ROUTE_TO_USER_FAQS_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokGeneralInfoIcon className="svg-menu" />
        <FormattedMessage id="routes.generalInfo" />
      </>
    ),
    url: ROUTE_TO_GENERAL_INFO_PAGE,
    icon: 'd-none',
  },
];

const adminNavigation = [
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <HokAdministrationIcon className="svg-menu" />
        <FormattedMessage id="routes.administration" />
      </>
    ),
    url: ROUTE_TO_ADMIN_DASHBOARD_PAGE,
    icon: 'd-none',
  },
];

export const userSidebarNavigation = userRole => {
  switch (userRole) {
    case USER:
      return { items: userNavigation };
    case ADMIN:
    case EMPLOYEE:
    case SUPER_ADMIN:
      return { items: [...userNavigation, ...adminNavigation] };
    default:
      return { items: userNavigation };
  }
};
