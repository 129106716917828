import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import { ReactComponent as HokSearchIcon } from '../../img/icons/hok-search.svg';

export const Search = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const searchColor = isOpen ? '#ed1d24' : '#ffffff';
  const toggle = () => {
    setOpen(!isOpen);
  };

  return (
    <Row className="search-component mb-4">
      <Col xs="12">
        <div className="mx-auto title-text">
          <Row className="search-button">
            <Col>
              <Card
                className="cursor-pointer"
                onClick={toggle}
                style={{
                  marginLeft: '15px',
                  width: '50px',
                  height: '50px',
                  border: 'solid 2px #ed1d24',
                  backgroundColor: searchColor,
                }}>
                <CardBody
                  className="p-0 d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: searchColor, borderRadius: 5 }}>
                  <HokSearchIcon className={isOpen ? 'svg-white' : 'svg-red'} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Collapse className="hok-card-search" isOpen={isOpen}>
            <Row className="search-form">
              <Col>
                <Card className="mb-0">
                  <CardBody>{children}</CardBody>
                </Card>
              </Col>
            </Row>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};
