import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Media } from 'reactstrap';
import { formatDateTime } from '../../constants/dateFormatting';
import hokAvatar from '../../img/hok-avatar.png';
import hokPartnerAvatar from '../../img/hok-partner-avatar.svg';

export const DamageClaimsComments = ({ loading, comments, user }) => {
  const me = useSelector(state => state.auth.user);

  const author = typeof user !== 'undefined' ? user : me;

  return (
    <div className={'hok-comments-container' + (loading ? ' loading' : '')}>
      {comments.map(comment => (
        <Row className="hok-comment-box no-gutters" key={comment.id}>
          <Col>
            <Media className="pb-3 pb-sm-4 pt-4">
              <Media left className="mr-4">
                <div
                  className="hok-comment-avatar mr-4"
                  style={{
                    backgroundImage: `url("${
                      comment.userId !== null
                        ? comment.userId === `${author.id}`
                          ? author.avatar || hokPartnerAvatar
                          : hokPartnerAvatar
                        : hokAvatar
                    }")`,
                  }}
                />
              </Media>
              <Media body>
                <Row className="no-gutters">
                  <Col>
                    <h5>
                      {comment.userId !== null
                        ? comment.userId === '-1'
                          ? 'Podnositelj zahtjeva'
                          : comment.userId !== `${author.id}` ||
                            !`${author.firstName || ''} ${author.lastName || ''}`.trim()
                          ? 'Partner'
                          : `${author.firstName || ''} ${author.lastName || ''}`.trim()
                        : 'HOK Digital'}
                    </h5>
                    <span className="hok-comment-date">{formatDateTime(comment.createdAt, false)}</span>
                  </Col>
                </Row>
                <Row className="no-gutters d-none d-sm-flex">
                  <Col>
                    <pre>{comment.comment}</pre>
                  </Col>
                </Row>
              </Media>
            </Media>
            <Row className="no-gutters d-sm-none pb-4">
              <Col>
                <pre className="mb-0">{comment.comment}</pre>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
};
