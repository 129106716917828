import gql from 'graphql-tag';

export const ActivateUserAccountMutation = gql`
  mutation ActivateUserAccount($userId: Int!, $token: String!) {
    activation(userId: $userId, token: $token) {
      id
      activated
      verified
    }
  }
`;

export default ActivateUserAccountMutation;
