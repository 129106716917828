import React, { useState } from 'react';
import { Row, Col, CardGroup, Card, CardBody, Form, FormGroup, Label, InputGroup } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
import { FormattedMessage } from 'react-intl';
import { CustomButton, CustomDashboardInput } from '../../../containers';
import { validateFormSubmission } from '../../../utils/validation';
import { userUpdateProfileAdvancedData } from '../../../redux/actions/userProfileActions';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

export const ProfileAdvanceBusiness = ({ user, dispatch, changeAdvanedDataInProgress }) => {
  const [businessFirstName, setBusinessFirstName] = useState({
    value: user.businessFirstName || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {},
  });
  const [businessLastName, setBusinessLastName] = useState({
    value: user.businessLastName || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {},
  });
  const [businessTelephone, setBusinessTelephone] = useState({
    value: user.businessTelephone || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {},
  });

  const handleAdvancedDataSubmit = async event => {
    event.preventDefault();

    const fieldsArray = [setBusinessTelephone, setBusinessFirstName, setBusinessLastName];

    if (await validateFormSubmission(fieldsArray)) {
      dispatch(
        userUpdateProfileAdvancedData({
          businessTelephone: businessTelephone.value.trim(),
          businessFirstName: businessFirstName.value.trim(),
          businessLastName: businessLastName.value.trim(),
        })
      );
    }
  };

  return (
    <Form onSubmit={handleAdvancedDataSubmit}>
      <CardGroup>
        <Card className="hok-card">
          <CardBody>
            <FormGroup row className="mt-4">
              <Col sm="6">
                <Label for="firstName">
                  <FormattedMessage id="inputs.firstName" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="firstName"
                    placeholder={true}
                    fieldState={businessFirstName}
                    onChange={event => {
                      setBusinessFirstName({
                        ...businessFirstName,
                        invalid: false,
                        value: event.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm="6">
                <Label for="lastName">
                  <FormattedMessage id="inputs.lastName" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="lastName"
                    placeholder={true}
                    fieldState={businessLastName}
                    onChange={event => {
                      setBusinessLastName({ ...businessLastName, invalid: false, value: event.target.value });
                    }}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="6">
                <Label for="businessTelephone">
                  <FormattedMessage id="inputs.businessTelephone" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="businessTelephone"
                    name="business telephone"
                    type="tel"
                    // If lenght is greater than 21 formatPhoneNumberIntl() function from
                    // "react-phone-number-input" goes wild and resets the value to an empty string
                    maxLength={21}
                    tag={PhoneInput}
                    addInternationalOption={false}
                    fieldState={businessTelephone}
                    numberInputProps={businessTelephone.invalid ? 'is-invalid' : ''}
                    onChange={phone =>
                      setBusinessTelephone({ ...businessTelephone, invalid: false, value: formatPhoneNumber(phone) })
                    }
                  />
                </InputGroup>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </CardGroup>
      <Row className="no-gutters mt-5">
        <Col className="text-right" xs="12">
          <CustomButton
            className="hok-dashboard-btn"
            block={false}
            translationId="save"
            loaderProp={changeAdvanedDataInProgress}
          />
        </Col>
      </Row>
    </Form>
  );
};
