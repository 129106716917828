import gql from 'graphql-tag';

export const UserPasswordResetMutation = gql`
  mutation UserPasswordReset($passwordResetToken: String!, $newPassword: String!) {
    userPasswordRecovery(passwordResetToken: $passwordResetToken, newPassword: $newPassword) {
      passwordUpdated
    }
  }
`;

export default UserPasswordResetMutation;
