import React, { useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Collapse, Fade, Form, Input, Label } from 'reactstrap';
import { IconButton } from '../IconButton';
import { ReactComponent as HokSendIcon } from '../../img/icons/hok-send.svg';
import { ReactComponent as HokCancelIcon } from '../../img/icons/hok-close.svg';
import { ReactComponent as HokAttachmentIcon } from '../../img/icons/hok-attachment.svg';
import { FormattedMessage } from 'react-intl';
import { validateFormSubmission } from '../../utils/validation';
import { useMutation } from 'react-apollo';
import { renderToaster, SUCCESS_TOASTER, ERROR_TOASTER } from '../../constants/toaster';
import { handleRequestError, getSpecificError, UNKNOWN_DOCUMENT_UPLOAD_FAIL } from '../../constants/errorCodes';
import { WriteTicketMessageMutation } from '../../operations/mutations/WriteTicketMessageMutation';
import { Attachment } from '../Attachment';
import { getFormData, uploadFileToS3bucket } from '../../utils/imageUpload';
import { RequestUploadAccessMutation } from '../../operations/mutations/RequestUploadAccessMutation';
import { UPLOAD_TYPE_DOCUMENT } from '../../constants/uploadFileTypes';
import { ROUTE_TO_ADMIN_TICKETING_PAGE } from '../../constants/routes';

export const TicketWriteComment = ({ isOpen, close, ticketNumber, paginate }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialCommentValue = {
    value: '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.comment" />,
    validation: {
      required: true,
    },
  };
  const [comment, setComment] = useState(initialCommentValue);
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);

  const filesToSubmit = useRef([]);

  const isAdminDashboard = location.pathname.includes(ROUTE_TO_ADMIN_TICKETING_PAGE);

  const [createComment] = useMutation(WriteTicketMessageMutation, {
    variables: {
      ticketNumber,
      content: comment.value,
      files: filesToSubmit.current,
      ...(isAdminDashboard && { agentNickname: 'HOK Digital' }),
    },
    onCompleted: () => {
      setFiles([]);
      close();
      setLoading(false);
      paginate(1);
      renderToaster(`successMessages.ticketMessageCreated`, SUCCESS_TOASTER, { intlKey: true });
    },
    onError: error => handleRequestError(error, dispatch, () => setLoading(false)),
  });

  const [requestUpload] = useMutation(RequestUploadAccessMutation, {
    onError: error => handleRequestError(error, dispatch, { silent: false }),
  });

  const handleCommentSubmit = async event => {
    event.preventDefault();

    if (await validateFormSubmission([setComment])) {
      for await (const [, document] of files.entries()) {
        try {
          const { data } = await requestUpload({ variables: { uploadType: UPLOAD_TYPE_DOCUMENT } });
          const signedUrlsData = JSON.parse(data.requestUploadAccess.json);
          const formData = getFormData(document, signedUrlsData);
          const fileUploadRes = await uploadFileToS3bucket(signedUrlsData.subdomainUrl, formData);

          if (fileUploadRes.status !== 204) {
            renderToaster(getSpecificError(UNKNOWN_DOCUMENT_UPLOAD_FAIL).message, ERROR_TOASTER);
          } else {
            filesToSubmit.current.push({ name: document.name, key: signedUrlsData.resourceUrl });
          }
        } catch (e) {
          setLoading(false);
        }
      }
      createComment();
      setComment(initialCommentValue);
    }
  };

  const removeFile = useCallback(index => {
    setFiles(f => [...f.filter((_, i) => i !== index)]);
  }, []);

  return (
    <Fade in={isOpen}>
      <Collapse isOpen={isOpen}>
        <Form onSubmit={handleCommentSubmit}>
          <Row className="no-gutters">
            <Col>
              <div className="hok-ticket-comment-box">
                <div className="hok-ticket-textarea-container">
                  <Input
                    className="hok-ticket-textarea"
                    type="textarea"
                    value={comment.value}
                    onChange={event =>
                      setComment({ ...comment, value: event.target.value, invalid: false, error: null })
                    }
                  />
                </div>
                {files.length > 0 && (
                  <>
                    <div className="px-3">
                      <hr />
                    </div>
                    <div className="hok-ticket-attachment-container">
                      {files.map((file, index) => (
                        <Attachment
                          inverted
                          download={false}
                          showCancel
                          file={file}
                          key={index}
                          onClick={() => removeFile(index)}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row className="no-gutters mt-3">
            <Col>
              <div className="float-right">
                <IconButton
                  type="button"
                  size="38"
                  className="mr-3"
                  Icon={HokCancelIcon}
                  outline
                  onClick={close}
                  disabled={loading}
                />
                <Label className="hok-attachement-add-button hok-button btn btn-outline-primary mr-3">
                  <Input
                    id="uploadDocument"
                    type="file"
                    onChange={event => {
                      const newFile = event.target.files[0];
                      if (newFile) {
                        setFiles(f => [...f, newFile]);
                      }
                    }}
                  />
                  <HokAttachmentIcon className="svg-red" height="24" width="24" />
                </Label>
                <IconButton size="38" Icon={HokSendIcon} disabled={loading} />
              </div>
            </Col>
          </Row>
        </Form>
      </Collapse>
    </Fade>
  );
};
