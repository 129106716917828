import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Input } from 'reactstrap';
import { get } from 'lodash-es';
import { useGetWSPayLink } from '../../../utils/customHooks';
import logoShort from '../../../img/logo-short.png';
import { ROUTE_TO_USER_PAYMENT_PROCESSING_STATUS_PAGE } from '../../../constants/routes';

export const PaymentProcessing = () => {
  const params = useParams();
  const { data, error } = useGetWSPayLink(get(params, 'confirmationCode', ''));
  const formRef = useRef(null);
  useEffect(() => {
    if (formRef.current && data.FormURL) {
      formRef.current.submit();
    }
  }, [data]);

  return (
    <>
      <div className="hok-payment-page">
        <div className="text-center">
          <img className="img-fluid" src={logoShort} alt="HOK logo" />
          {error ? (
            <p className="color-red">{error}</p>
          ) : (
            <>
              <p>preusmjeravamo Vas na obrazac za plaćanje...</p>
              <p className="preloader-text">
                <span>
                  <i></i>
                  <i></i>
                  <i></i>
                </span>
              </p>
            </>
          )}
        </div>
      </div>
      <div className="d-none">
        <form ref={formRef} action={data.FormURL} method="POST">
          <Input type="text" name="ShopID" defaultValue={data.ShopID || ''} />
          <Input type="text" name="ShoppingCartID" defaultValue={data.ShoppingCartID || ''} />
          <Input type="text" name="TotalAmount" defaultValue={data.TotalAmount || ''} />
          <Input type="text" name="IntAmount" defaultValue={data.IntAmount || ''} />
          <Input type="text" name="IntCurrency" defaultValue={data.IntCurrency || ''} />
          <Input type="text" name="Signature" defaultValue={data.Signature || ''} />
          <Input type="text" name="Version" defaultValue={data.Version || ''} />
          <Input
            type="text"
            name="ReturnURL"
            defaultValue={`${process.env.REACT_APP_URL}${ROUTE_TO_USER_PAYMENT_PROCESSING_STATUS_PAGE}/success`}
          />
          <Input
            type="text"
            name="CancelURL"
            defaultValue={`${process.env.REACT_APP_URL}${ROUTE_TO_USER_PAYMENT_PROCESSING_STATUS_PAGE}/cancel`}
          />
          <Input
            type="text"
            name="ReturnErrorURL"
            defaultValue={`${process.env.REACT_APP_URL}${ROUTE_TO_USER_PAYMENT_PROCESSING_STATUS_PAGE}/error`}
          />
          <Input type="text" name="CustomerFirstName" defaultValue={data.CustomerFirstName || ''} />
          <Input type="text" name="CustomerLastName" defaultValue={data.CustomerLastName || ''} />
          <Input type="text" name="CustomerAddress" defaultValue={data.CustomerAddress || ''} />
          <Input type="text" name="CustomerCity" defaultValue={data.CustomerCity || ''} />
          <Input type="text" name="CustomerZIP" defaultValue={data.CustomerZIP || ''} />
          <Input type="text" name="CustomerCountry" defaultValue={data.CustomerCountry || ''} />
          <Input type="text" name="CustomerPhone" defaultValue={data.CustomerPhone || ''} />
          <Input type="text" name="CustomerEmail" defaultValue={data.CustomerEmail || ''} />
        </form>
      </div>
    </>
  );
};
