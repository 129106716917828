import * as firebase from 'firebase/app';
import 'firebase/analytics';
import * as Sentry from '@sentry/browser';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const analytics = (() => {
  try {
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    const firebaseAppAnalytics = firebaseApp.analytics();
    firebaseAppAnalytics.setAnalyticsCollectionEnabled(false);
    return firebaseAppAnalytics;
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
})();

export { analytics };
