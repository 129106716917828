import gql from 'graphql-tag';

export const UserChangePhoneNumberMutation = gql`
  mutation UserChangePhoneNumber($phoneNumber: String) {
    changePassword: updateProfile(phoneNumber: $phoneNumber) {
      id
    }
  }
`;

export const UserChangeAvatarMutation = gql`
  mutation UserChangeAvatar($avatar: String) {
    changeAvatar: updateProfile(avatar: $avatar) {
      id
      profile {
        avatar
      }
    }
  }
`;

export const UserUpdatePrivateUserMutation = gql`
  mutation UserUpdatePrivateUser(
    $oib: String
    $firstName: String
    $lastName: String
    $dateOfBirth: Date
    $sex: userSexesTypeEnum
    $address: String
    $city: String
    $zip: String
    $sector: userSectorsTypeEnum
    $country: String
  ) {
    updateProfile(
      oib: $oib
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      sex: $sex
      address: $address
      city: $city
      zip: $zip
      sector: $sector
      country: $country
    ) {
      id
      profile {
        oib
        firstName
        lastName
        dateOfBirth
        sex
        address
        city
        zip
        sector
        country
      }
    }
  }
`;

export const UserUpdateSectorMutation = gql`
  mutation UserUpdateSector($sector: userSectorsTypeEnum) {
    updateProfile(sector: $sector) {
      id
      profile {
        sector
      }
    }
  }
`;

export const UserUpdateBusinessUserMutation = gql`
  mutation UserUpdateBusinessUser(
    $mbo: String
    $oib: String
    $businessName: String
    $VATpayer: Boolean
    $address: String
    $city: String
    $zip: String
    $country: String
    $sector: userSectorsTypeEnum
    $businessFirstName: String
    $businessLastName: String
    $businessTelephone: String
    $webAddress: String
  ) {
    updateProfile(
      mbo: $mbo
      oib: $oib
      businessName: $businessName
      VATpayer: $VATpayer
      address: $address
      city: $city
      zip: $zip
      country: $country
      sector: $sector
      businessFirstName: $businessFirstName
      businessLastName: $businessLastName
      businessTelephone: $businessTelephone
      webAddress: $webAddress
    ) {
      id
      profile {
        mbo
        oib
        businessName
        VATpayer
        address
        city
        zip
        country
        sector
        businessFirstName
        businessLastName
        businessTelephone
        webAddress
      }
    }
  }
`;

export const UserUpdateProfileAdvancedDataMutation = gql`
  mutation UserUpdateProfileAdvancedData(
    $email: String
    $phoneNumber: String
    $passportNumber: String
    $governmentIssuedIdNumber: String
    $HZZONumber: String
    $ino: String
    $businessFirstName: String
    $businessLastName: String
    $businessTelephone: String
    $webAddress: String
  ) {
    updateProfile(
      email: $email
      phoneNumber: $phoneNumber
      passportNumber: $passportNumber
      governmentIssuedIdNumber: $governmentIssuedIdNumber
      HZZONumber: $HZZONumber
      ino: $ino
      businessFirstName: $businessFirstName
      businessLastName: $businessLastName
      businessTelephone: $businessTelephone
      webAddress: $webAddress
    ) {
      id
    }
  }
`;

export const UserUpdateProfileBasicInfoMutation = gql`
  mutation UserUpdateProfileBasicInfo($address: String, $city: String, $zip: String, $webAddress: String) {
    updateProfile(address: $address, city: $city, zip: $zip, webAddress: $webAddress) {
      id
      profile {
        address
        city
        zip
        webAddress
      }
    }
  }
`;
