import React from 'react';
import { Spinner as ReactstrapSpinner } from 'reactstrap';

export const Spinner = React.memo(({ size = 'lg', color = '', className = '', loaderClassName = '' }) => {
  className = ('text-center spinner-container ' + className).trim();
  loaderClassName = loaderClassName ? loaderClassName + ' ' : '';

  return (
    <div className={className}>
      <ReactstrapSpinner size={size} color={color} className={loaderClassName} />
    </div>
  );
});
