import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HokCloseIcon } from '../../img/icons/hok-close.svg';
import { DescriptionText } from '../../containers';

export const UserInteractionModal = React.memo(
  ({ isOpen, toggle, titleId, textId, titleComponent, textComponent = null, wide = false, children }) => {
    return (
      <Modal
        className={(wide ? 'hok-modal-wide' : 'hok-modal') + ' dashboard-modal'}
        isOpen={isOpen}
        toggle={toggle}
        centered>
        <div className="d-flex flex-row justify-content-between align-items-center px-3">
          <ModalHeader className="mx-auto text-center">
            {titleId ? <FormattedMessage id={titleId} /> : titleComponent}
          </ModalHeader>
          {typeof toggle === 'function' && (
            <div>
              <HokCloseIcon className="svg-table-icons svg-red" onClick={toggle} />
            </div>
          )}
        </div>
        <ModalBody className="text-center">{textId ? <DescriptionText value={textId} /> : textComponent}</ModalBody>
        {children && <ModalFooter className="flex-wrap justify-content-center">{children}</ModalFooter>}
      </Modal>
    );
  }
);
