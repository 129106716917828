import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Container, CardGroup, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { buildVersion } from '../../config/buildVersion';
import { BackgroundAnimation } from '../../components/BackgroundAnimation/index.js';
import { SupportInfoLink } from '../../views/client/SupportInfo/SupportInfoLink';
import logoShort from '../../img/logo-short.png';

const ContentContainer = ({
  titleTranslationId = '',
  titleImage = null,
  showFooter = true,
  footerImage = false,
  children,
}) => {
  return (
    <div className="d-flex align-items-center auth-container">
      <BackgroundAnimation />
      <Container fluid>
        <Row>
          <Col xs="12">
            <CardGroup className="auth-card-group fixed-width-card-group">
              <Card className="auth-card w-100">
                <CardHeader>
                  <Row className="no-gutters w-100">
                    <Col className="text-center" xs={12}>
                      {titleImage ? (
                        <img className="img-fluid card-header-image" src={titleImage} alt="HOK logo" />
                      ) : (
                        titleTranslationId && (
                          <h2>
                            <FormattedMessage id={titleTranslationId} />
                          </h2>
                        )
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <main>{children}</main>
                </CardBody>
                {showFooter && (
                  <CardFooter>
                    <Row className="no-gutters">
                      <Col className="text-center" xs={12}>
                        {footerImage ? (
                          <img className="card-footer-image" src={logoShort} alt="HOK logo" />
                        ) : (
                          <p className="hok-support">
                            <SupportInfoLink unwrapped />
                          </p>
                        )}
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col className="text-center" xs={12}>
                        <span className="hok-version">{buildVersion}</span>
                      </Col>
                    </Row>
                  </CardFooter>
                )}
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContentContainer;
