import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardGroup, Card, CardBody, Row, Col } from 'reactstrap';
import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import FileSaver from 'file-saver';
import { FilesDropzone } from '../FilesDropzone';
import { FileDownloadButton } from '../FileDownloadButton';
import { useQuery } from 'react-apollo';
import { GetUserClaimFilesQuery } from '../../operations/queries/GetUserClaimsQuery';
import { handleRequestError } from '../../constants/errorCodes';
import { Spinner } from '../../containers';
import { renderToaster, ERROR_TOASTER } from '../../constants/toaster';

export const DamageClaimDocumentation = ({ damageClaim, anonymous = false, userId, user }) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetUserClaimFilesQuery, {
    variables: {
      anonymous,
      order: { createdAt: 'ASC' },
      filter:
        damageClaim.claimNo && !anonymous
          ? { claimNo: damageClaim.claimNo, claimClass: damageClaim.claimClass }
          : { claimConfirmationCode: damageClaim.claimConfirmationCode },
      ...(userId && { userId: `${userId}` }),
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const [newFiles, setNewFiles] = useState([]);

  const files = get(data, 'getClaimFiles.claimFiles', []);

  return (
    <CardGroup>
      <Card className="hok-card">
        <CardBody>
          <Row className="my-4">
            {(loading || (!data && !error)) && (
              <Col xs="12">
                <Spinner className="spinner-positioning" />
              </Col>
            )}
            {((files.length < 1 && !loading) || error) && (
              <Col xs="12">
                <p className="hok-text-dark-500 mb-0">
                  <FormattedMessage id="userMyDamageClaims.noClaimFiles" />
                </p>
              </Col>
            )}
            {files.map((file, index) => (
              <Col className="mb-2" xs="12" sm="6" key={index}>
                <FileDownloadButton
                  name={file.name || `dokument-${file.id}`}
                  datetime={file.createdAt}
                  onClick={async () => {
                    try {
                      const response = await fetch(file.fileUrl);
                      const blob = await response.blob();
                      FileSaver.saveAs(blob, file.name || `dokument-${file.id}`);
                    } catch (e) {
                      renderToaster(`errors.errorWhileDownloadingFile`, ERROR_TOASTER, { intlKey: true });
                    }
                  }}
                />
              </Col>
            ))}
            {newFiles.map((file, index) => (
              <Col className="mb-2" xs="12" sm="6" key={index}>
                <FileDownloadButton name={file.name || `dokument-${index}`} progress={file.progress} />
              </Col>
            ))}
          </Row>
          {typeof user === 'undefined' && (
            <Row className="no-gutters">
              <Col>
                <FilesDropzone damageClaim={damageClaim} setNewFiles={setNewFiles} anonymous={anonymous} />
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </CardGroup>
  );
};
