import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { NewsSlider } from '../../../components/NewsSlider';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from '../../../containers';
import { MyPoliciesCardList } from '../../../components/MyPoliciesCardList';
import { redirectToUserProductsPage } from '../../../redux/actions/navigationActions';
import { AdFormIFrame } from '../../../components/AdFormIframe';
import { handleRequestError } from '../../../constants/errorCodes';
import { useQuery } from 'react-apollo';
import { GetCurrencyConfigQuery } from '../../../operations/queries/GetCurrencyConfigQuery';
import { useEffect } from 'react';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';
import { useState } from 'react';

const Dashboard = () => {

  const dispatch = useDispatch();

  const { data, /* loading, error  */} = useQuery(GetCurrencyConfigQuery, {
    variables: { configId: 1 },
    onError: error => handleRequestError(error, dispatch),
  });

  let appCurrencyConfiguration = {
    defaultCurrency: null,
    dualCurrencyDisplay: null
  };

  if(data !== undefined && data !== null){
     appCurrencyConfiguration = data.getCurrencyConfig;
  }

  const [tabState, setTabState] = useState(false);

  useEffect(() => {

    dispatch({
      type: 'SET_CURRENCY_CONFIG',
      defaultCurrency: appCurrencyConfiguration.defaultCurrency,
      dualCurrencyDisplay: appCurrencyConfiguration.dualCurrencyDisplay
    });

  },[
    appCurrencyConfiguration.defaultCurrency,
    appCurrencyConfiguration.dualCurrencyDisplay,
    dispatch
  ]);

  useEffect(() => {
    window.localStorage.setItem("tabStateStorage", "false");
    window.dispatchEvent(new Event("storage"));
    window.addEventListener('storage', () => {
      let _tabToggleState = localStorage.getItem('tabStateStorage');
      setTabState(_tabToggleState);
    })
  },[])

  const currencyDataConfig = useSelector(state => state.currencyConfig);
  if(currencyDataConfig.dualCurrencyDisplay !== null){
    currencyDisplayUpdate(currencyDataConfig.defaultCurrency);
  }
  //const tabState = localStorage.getItem('tabStateStorage');

  /* const handleLocalStorage = () => {
    window.localStorage.setItem("tabStateStorage", "false");
    window.dispatchEvent(new Event("storage"));
  }; */


  return (
    <Container fluid>
      <AdFormIFrame title="URL Dashboard" />
      <div className="mx-auto" style={{ maxWidth: '1416px' }}>
        <NewsSlider titleId="userNewsPreviewPage.news" showMoreLink />
        <Row className="mt-4 mb-2">
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <Card className="hok-card hok-my-policies-card">
              <CardHeader>
                <h5 className="hok-card-heading">
                  <FormattedMessage id="dashboardHome.myPolicies" />
                </h5>
                <CustomButton
                  type="button"
                  block={false}
                  className="ml-auto"
                  translationId="newPolicyShort"
                  onClick={() => dispatch(redirectToUserProductsPage())}
                />
              </CardHeader>
              <CardBody>
                <Row className="no-gutters">
                  <MyPoliciesCardList smOnly resizableContainer>
                    <h5 className="text-center my-3">
                      <FormattedMessage id="userMyPolicies.noPoliciesFound" />
                    </h5>
                  </MyPoliciesCardList>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Dashboard;
