import React from 'react';
import { Button } from 'reactstrap';

export const IconButton = ({ className, Icon, onClick, size, outline = false, ...rest }) => {
  return (
    <Button
      className={`hok-square-icon-btn cursor pointer ${className}`.trim()}
      color="primary"
      outline={outline}
      onClick={onClick}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
      {...rest}>
      <Icon />
    </Button>
  );
};
