import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  InputGroup,
  FormFeedback,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import PhoneInput from 'react-phone-number-input';
import moment, { ISO_8601 } from 'moment';
import { get } from 'lodash-es';
import { CustomDashboardInput, CustomButton } from '../../../containers';
import { usePlacesQuery } from '../../../utils/customHooks';
import { yearListRender, FORMAT_STANDARD_DATE } from '../../../constants/dateFormatting';
import { USER_GENDER } from '../../../constants/userDefs';
import { validateFormSubmission } from '../../../utils/validation';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { PlaceAndZipInput } from '../../../components/PlaceAndZipInput';

export const ModalInsuredPerson = React.memo(({ action = 'NEW', isOpen, toggle, insuredPerson = {}, submitFn }) => {
  const { formatMessage } = useIntl();
  const { places, loading } = usePlacesQuery();

  const [oib, setOIB] = useState({
    value: insuredPerson.oib || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: true,
      oib: true,
    },
  });
  const [firstName, setFirstName] = useState({
    value: insuredPerson.firstName || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {
      required: true,
    },
  });
  const [lastName, setLastName] = useState({
    value: insuredPerson.lastName || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {
      required: true,
    },
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value:
      (moment(get(insuredPerson, 'dateOfBirth', null), ISO_8601, true).isValid() &&
        moment(insuredPerson.dateOfBirth, ISO_8601)) ||
      null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.dateOfBirth" />,
    validation: {
      required: true,
    },
  });
  const [sex, setSex] = useState({
    value: insuredPerson.sex || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sex" />,
    validation: {
      required: true,
      enum: USER_GENDER.map(gender => gender.value),
    },
  });
  const [address, setAddress] = useState({
    value: insuredPerson.address || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {},
  });
  const [zip, setZip] = useState({
    value: insuredPerson.zip || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      enum: places.map(place => place.pttNumber),
    },
  });
  const [city, setCity] = useState({
    value: insuredPerson.city || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      enum: places.map(place => place.name),
    },
  });
  const [email, setEmail] = useState({
    value: insuredPerson.email || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.email" />,
    validation: {
      email: true,
    },
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: insuredPerson.phoneNumber || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {},
  });
  // const [HZZONumber, setHZZONumber] = useState({
  //   value: insuredPerson.HZZONumber || '',
  //   error: null,
  //   invalid: false,
  //   displayName: <FormattedMessage id="inputs.HZZONumber" />,
  //   validation: {
  //     required: true,
  //     minLength: 9,
  //   },
  // });

  const [dateOfBirthFocused, setDateOfBirthFocused] = useState(false);

  useEffect(() => {
    if (places.length > 0 && city.validation.enum.length < 1) {
      setCity(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
    }
    if (places.length > 0 && city.validation.enum.length < 1) {
      setZip(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
    }
  }, [places, city, setCity, zip, setZip]);

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false, error: null }));
  };

  const submitHandler = async event => {
    event.preventDefault();

    const fieldsArray = [
      setOIB,
      // setHZZONumber,
      setAddress,
      setCity,
      setZip,
      setPhoneNumber,
      setEmail,
      setFirstName,
      setLastName,
      setDateOfBirth,
      setSex,
    ];
    if (await validateFormSubmission(fieldsArray)) {
      submitFn({
        firstName: firstName.value,
        lastName: lastName.value,
        dateOfBirth: dateOfBirth.value.toISOString(),
        sex: sex.value,
        oib: oib.value,
        // HZZONumber: HZZONumber.value,
        address: address.value,
        city: city.value,
        zip: zip.value,
        phoneNumber: phoneNumber.value,
        email: email.value,
      });
      toggle();
    }
  };

  return (
    <Modal
      className="hok-modal hok-modal-wide dashboard-modal"
      centered
      backdrop="static"
      isOpen={isOpen}
      toggle={toggle}>
      <ModalHeader className="text-center">
        <FormattedMessage
          id={`userMyPoliciesTravelInsurance.${action !== 'EDIT' ? 'newInsuredPerson' : 'editInsuredPerson'}`}
        />
      </ModalHeader>
      <Form onSubmit={submitHandler}>
        <ModalBody>
          <Row className="no-gutters mb-3">
            <Col>
              <h3 className="label-text">
                <FormattedMessage id="userMyPolicies.policyData.requiredData" />
              </h3>
            </Col>
          </Row>
          <FormGroup row>
            <Col sm="6" lg="4">
              <Label for="firstName">
                <FormattedMessage id="inputs.firstName" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  value={firstName.value}
                  fieldState={firstName}
                  onChange={event => inputHandler(setFirstName, event.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm="6" lg="4">
              <Label for="lastName">
                <FormattedMessage id="inputs.lastName" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  value={lastName.value}
                  fieldState={lastName}
                  onChange={event => inputHandler(setLastName, event.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm="6" lg="4">
              <Label for="dateOfBirth">
                <FormattedMessage id="inputs.dateOfBirth" />
              </Label>
              <InputGroup className={dateOfBirth.invalid ? 'invalid-date' : ''}>
                <SingleDatePicker
                  hideKeyboardShortcutsPanel
                  date={dateOfBirth.value}
                  onDateChange={date => inputHandler(setDateOfBirth, date)}
                  focused={dateOfBirthFocused}
                  onFocusChange={({ focused }) => setDateOfBirthFocused(focused)}
                  id="dateOfBirth"
                  showDefaultInputIcon
                  firstDayOfWeek={1}
                  numberOfMonths={1}
                  small
                  displayFormat={FORMAT_STANDARD_DATE}
                  placeholder={''}
                  initialVisibleMonth={() => dateOfBirth.value || moment()}
                  isOutsideRange={date => date.isAfter(moment(), 'day')}
                  renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                    <div className="d-flex justify-content-center">
                      <div className="mr-1">
                        <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                          {moment.months().map((label, value) => (
                            <option value={value} key={label}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="ml-1">
                        <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                          {yearListRender(1900, moment().format('YYYY'))}
                        </select>
                      </div>
                    </div>
                  )}
                />
                {dateOfBirth.invalid && <FormFeedback className="d-block">{dateOfBirth.error}</FormFeedback>}
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm="6" lg="4">
              <Label for="sex">
                <FormattedMessage id="inputs.sex" />
              </Label>
              <InputGroup>
                <div className="hok-select">
                  <CustomDashboardInput
                    type="select"
                    fieldState={sex}
                    onChange={event => inputHandler(setSex, event.target.value)}>
                    <option disabled value="">
                      {formatMessage({ id: 'inputs.sex' })}
                    </option>
                    {USER_GENDER.map(sex => (
                      <option key={sex.value} value={sex.value}>
                        {formatMessage({ id: sex.name })}
                      </option>
                    ))}
                  </CustomDashboardInput>
                </div>
              </InputGroup>
            </Col>
            <Col sm="6" lg="4">
              <Label for="OIB">
                <FormattedMessage id="inputs.OIB" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  value={oib.value}
                  fieldState={oib}
                  onChange={event => inputHandler(setOIB, event.target.value)}
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <Row className="no-gutters mb-3">
            <Col>
              <h3 className="label-text">
                <FormattedMessage id="userMyPolicies.policyData.optionalData" />
              </h3>
            </Col>
          </Row>
          <FormGroup row>
            <Col sm="6" lg="4">
              <Label for="address">
                <FormattedMessage id="inputs.address" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  value={address.value}
                  fieldState={address}
                  onChange={event => inputHandler(setAddress, event.target.value)}
                />
              </InputGroup>
            </Col>
            <PlaceAndZipInput
              sizes={{ sm: '6', lg: '4' }}
              places={places}
              loadingPlaces={loading}
              zip={zip}
              setZip={setZip}
              city={city}
              setCity={setCity}
            />
            <Col sm="6" lg="4">
              <Label for="phoneNumber">
                <FormattedMessage id="inputs.phoneNumberShort" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  id="phoneNumber"
                  name="mobile phone number"
                  type="tel"
                  maxLength={21}
                  tag={PhoneInput}
                  defaultCountry="HR"
                  addInternationalOption={false}
                  fieldState={phoneNumber}
                  numberInputProps={phoneNumber.invalid ? 'is-invalid' : ''}
                  onChange={phone => inputHandler(setPhoneNumber, formatPhoneNumber(phone))}
                />
              </InputGroup>
            </Col>
            <Col sm="6" lg="4">
              <Label for="email">
                <FormattedMessage id="inputs.email" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  value={email.value}
                  fieldState={email}
                  onChange={event => inputHandler(setEmail, event.target.value)}
                />
              </InputGroup>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="flex-wrap">
          <CustomButton
            type="button"
            outline
            className="hok-outline-dashboard-btn-wide d-block d-sm-inline-block"
            block={false}
            translationId="cancel"
            onClick={toggle}
          />
          <CustomButton
            className="hok-dashboard-btn-wide d-block d-sm-inline-block mt-3 mt-lg-0"
            block={false}
            translationId={action !== 'EDIT' ? 'addInsuredPerson' : 'editInsuredPerson'}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
});
