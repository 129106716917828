import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import { CustomButton } from '../../../containers';
import logoShort from '../../../img/logo-short.png';

export const IOPModal = ({ isOpen, toggle, setTacAccepted, content }) => {
  return (
    <Modal className="hok-modal hok-modal-wide" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader>
        <Row className="no-gutters auth-nav-links">
          <Col xs={12}>
            <h2 className="d-flex align-items-center justify-content-center">
              <FormattedMessage id="modal.iop" />
            </h2>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <div className="hok-fixed-register-tos-container">
          <Scrollbars height={450}>
            <iframe title="iop" src={content} width="100%" height="100%" frameBorder="0"></iframe>
          </Scrollbars>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-center flex-wrap">
        <CustomButton className="hok-outline-btn-wide mb-4" block={false} onClick={setTacAccepted} translationId="ok" />
        <div className="w-100 text-center">
          <img className="img-fluid" src={logoShort} alt="HOK logo" />
        </div>
      </ModalFooter>
    </Modal>
  );
};
