import {
  USER_REGISTRATION,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAIL,
  USER_CLEAR_ERROR_MESSAGE,
  USER_CLEAR_SUCCESS_MESSAGE,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_ACCOUNT_ACTIVATION,
  USER_ACCOUNT_ACTIVATION_SUCCESS,
  USER_ACCOUNT_ACTIVATION_FAIL,
  USER_RESEND_ACTIVATION_EMAIL,
  USER_RESEND_ACTIVATION_EMAIL_SUCCESS,
  USER_RESEND_ACTIVATION_EMAIL_FAIL,
  USER_FORGOT_PASSWORD,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  AUTH_TOKEN_LOADED_SUCCESS,
  USER_PASSWORD_RESET,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_SUCCESS,
  USER_UPDATE_DATA_IN_STORE,
  USER_ACCOUNT_EMAIL_DEACTIVATED,
  USER_LOGOUT,
} from '../actions/userAuthActions';
import { USER_CHANGE_AVATAR_SUCCESS } from '../actions/userProfileActions';
import {
  ADMIN_LOGIN,
  AUTH_TOKEN_EXPIRED,
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_TOKEN_REFRESH_SUCCESS,
  ADMIN_CLEAR_ERROR_MESSAGE,
  ADMIN_AUTH_LOGOUT,
} from '../actions/adminAuthActions';

import { removeLocalStorageToken } from '../../constants/LocalStorageKeys';

const initialState = {
  user: {
    id: null,
    email: null,
    username: null,
    firstName: null,
    lastName: null,
    role: null,
    avatar: null,
    title: null,
    activated: false,
    verified: false,
    accountLocked: false,
    passwordExpires: null,
    passwordExpired: false,
    createdAt: null,
    updatedAt: null,
    sector: null,
  },
  register: {
    inProgress: false,
    errorMessage: null,
  },
  login: {
    inProgress: false,
    errorMessage: null,
  },
  passwordRecovery: {
    done: false,
    success: false,
    inProgress: false,
    errorMessage: null,
  },
  activation: {
    inProgress: false,
    errorMessage: null,
  },
  resendActivationEmail: {
    inProgress: false,
    errorMessage: null,
    done: false,
    success: false,
  },
  forgotPassword: {
    inProgress: false,
    errorMessage: null,
    done: false,
    success: false,
  },
  // Admin state, TODO passwordRecovery
  // currently uses same state as recovery for users
  loginInProgress: false,
  errorMessage: null,
  authToken: null,
  lastAuth: new Date(),
  passwordReset: {
    errorMessage: null,
  },
};

const userAuthActions = (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTRATION:
      return { ...state, register: { ...state.register, inProgress: true } };

    case USER_REGISTRATION_SUCCESS:
      return { ...state, ...action.payload, register: { ...state.register, errorMessage: null, inProgress: false } };

    case USER_REGISTRATION_FAIL:
      return { ...state, register: { ...state.register, ...action.payload, inProgress: false } };

    case USER_CLEAR_ERROR_MESSAGE:
      return { ...state, [action.payload]: { ...state[action.payload], errorMessage: null } };

    case USER_CLEAR_SUCCESS_MESSAGE:
      return { ...state, [action.payload]: { ...state[action.payload], success: false, done: false } };

    case USER_LOGIN:
      return { ...state, login: { ...state.login, inProgress: true } };

    case ADMIN_AUTH_LOGOUT:
    case USER_LOGOUT:
      return { ...state, user: { ...initialState.user } };

    case USER_LOGIN_SUCCESS:
      return { ...state, ...action.payload, login: { ...state.login, errorMessage: null, inProgress: false } };

    case AUTH_TOKEN_LOADED_SUCCESS:
      return { ...state, ...action.payload };

    case USER_LOGIN_FAIL:
      removeLocalStorageToken();
      return { ...state, login: { ...state.login, ...action.payload, inProgress: false } };

    case USER_ACCOUNT_ACTIVATION:
      return { ...state, activation: { ...state.activation, inProgress: true } };

    case USER_ACCOUNT_EMAIL_DEACTIVATED:
      return { ...state, user: { ...state.user, activated: false } };

    case USER_ACCOUNT_ACTIVATION_SUCCESS:
      return {
        ...state,
        user: { ...state.user, activated: true },
        activation: { ...state.activation, errorMessage: null, inProgress: false },
      };

    case USER_ACCOUNT_ACTIVATION_FAIL:
      return {
        ...state,
        user: { ...state.user },
        activation: { ...state.activation, ...action.payload, inProgress: false },
      };

    case USER_RESEND_ACTIVATION_EMAIL:
      return {
        ...state,
        resendActivationEmail: { ...state.resendActivationEmail, inProgress: true, done: false, success: false },
      };

    case USER_RESEND_ACTIVATION_EMAIL_SUCCESS:
      return {
        ...state,
        resendActivationEmail: {
          ...state.resendActivationEmail,
          inProgress: false,
          errorMessage: null,
          done: true,
          success: true,
        },
      };

    case USER_RESEND_ACTIVATION_EMAIL_FAIL:
      return {
        ...state,
        resendActivationEmail: {
          ...state.resendActivationEmail,
          ...action.payload,
          inProgress: false,
          done: true,
          success: false,
        },
      };

    case USER_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, inProgress: true, done: false, success: false, errorMessage: null },
      };

    case USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, inProgress: false, done: true, success: true, errorMessage: null },
      };

    case USER_FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, ...action.payload, inProgress: false, done: true, success: false },
      };

    case USER_PASSWORD_RESET:
      return {
        ...state,
        passwordRecovery: {
          ...state.passwordRecovery,
          errorMessage: null,
          inProgress: true,
          done: false,
          success: false,
        },
      };

    case USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordRecovery: {
          ...state.passwordRecovery,
          errorMessage: null,
          inProgress: false,
          done: true,
          success: true,
        },
      };

    case USER_PASSWORD_RESET_FAIL:
      return {
        ...state,
        passwordRecovery: {
          ...state.passwordRecovery,
          ...action.payload,
          inProgress: false,
          done: true,
          success: false,
        },
      };

    case USER_UPDATE_DATA_IN_STORE:
      return { ...state, user: { ...state.user, ...action.payload } };

    // Admin actions
    case ADMIN_LOGIN:
      return { ...state, loginInProgress: true };

    case AUTH_TOKEN_EXPIRED:
      removeLocalStorageToken();
      return { ...state, ...action.payload, authToken: null };

    case ADMIN_LOGIN_SUCCESS:
      return { ...state, ...action.payload, errorMessage: null, loginInProgress: false };

    case ADMIN_LOGIN_FAILURE:
      removeLocalStorageToken();
      return { ...state, ...action.payload, authToken: null, loginInProgress: false };

    case ADMIN_TOKEN_REFRESH_SUCCESS:
      return { ...state, ...action.payload };

    case ADMIN_CLEAR_ERROR_MESSAGE:
      return { ...state, ...action.payload };

    case USER_CHANGE_AVATAR_SUCCESS:
      return { ...state, user: { ...state.user, ...action.payload } };

    default:
      return state;
  }
};

export default userAuthActions;
