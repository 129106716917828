import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { ScreenTitleContainer, ArrowLinkButton } from '../../../containers';
import { ROUTE_TO_USER_POLICIES_PAGE } from '../../../constants/routes';
import { EnquiryForm } from '../../../components/EnquiryForm';

export const ProductEnquiry = () => {
  return (
    <Container className="hok-container" fluid>
      <ArrowLinkButton text="buttons.giveUp" linkTo={ROUTE_TO_USER_POLICIES_PAGE} />
      <ScreenTitleContainer row className="mt-4" titleId="productEnquiry.title" />
      <Row className="no-gutters">
        <Col>
          <Card className="label-red">
            <CardBody>
              <EnquiryForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
