import gql from 'graphql-tag';

export const GetProductPackagesQuery = gql`
  query GetProductPackages($productGroup: productGroupTypeEnum) {
    getProductPackages(productGroup: $productGroup) {
      id
      productCode
      productGroup
      packageCode
      packageEnumCode
      risksDefinitions
      price
      value: name
      name: desc
      active
    }
  }
`;
