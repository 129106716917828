import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { ContentContainer, CustomButton } from '../../../containers';
import { redirectToUserDashboardPage, redirectToUserLoginPage } from '../../../redux/actions/navigationActions';
import stepsDoneImg from '../../../img/stepper/steps-done.svg';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { LogoutLinkRow } from '../../../components/LogoutLink';
import { SupportInfo } from '../../../components/SupportInfo';

const RegistrationComplete = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    if (!state || !state.completed) dispatch(redirectToUserLoginPage());
  }, [state, dispatch]);

  const businessUser = get(state, 'sector', null) === USER_ACCOUNT_BUSINESS || false;

  return (
    <ContentContainer titleImage={stepsDoneImg} footerImage>
      {businessUser && <LogoutLinkRow />}
      <Row className={'main-content' + (businessUser ? ' narrow' : '')}>
        <Col xs="12" sm={{ offset: 2, size: 8 }}>
          <Row className="no-gutters">
            <Col xs="12" md={{ offset: 2, size: 8 }} className="text-center">
              <h2 className="mb-4">
                <FormattedMessage id="registrationCompletePage.title" />
              </h2>
            </Col>
          </Row>
          <Row className="no-gutters mt-5">
            <Col xs="12" md={{ offset: 1, size: 10 }}>
              {businessUser ? (
                <>
                  <p className="font-weight-bold">
                    <FormattedMessage id="registrationCompletePage.businessParagraph1" />
                  </p>
                  <p className="font-weight-bold">
                    <FormattedMessage id="registrationCompletePage.businessParagraph2" />
                  </p>
                  <p>
                    <FormattedMessage id="registrationCompletePage.businessParagraph3" />
                  </p>
                </>
              ) : (
                <p className="text-center">
                  <FormattedMessage id="registrationCompletePage.paragraph1" />
                </p>
              )}
            </Col>
          </Row>
          {businessUser ? (
            <SupportInfo className="px-3 mt-4" colSizes={{ xs: 12, md: { size: 10, offset: 1 } }} />
          ) : (
            <Row className="no-gutters mt-5">
              <Col xs={{ offset: 2, size: 8 }} sm={{ offset: 3, size: 6 }} className="text-center">
                <CustomButton translationId="continue" onClick={() => dispatch(redirectToUserDashboardPage())} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default RegistrationComplete;
