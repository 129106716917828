import gql from 'graphql-tag';

export const GetUserInvoicesQuery = gql`
  query GetUserInvoices($filter: getInvoicesFilterInput, $order: getInvoicesSortFilterInput, $userId: String) {
    getInvoices(filter: $filter, order: $order, userId: $userId) {
      totalCount
      queryCount
      invoices {
        invoiceNumber
        amount
        date
        maturityDate
        status
        debt
        description
        paidOnline
      }
    }
  }
`;

export const GetUserInvoiceRawQuery = gql`
  query GetUserInvoiceRaw($invoiceNumber: String) {
    printInvoice(invoiceNumber: $invoiceNumber) {
      filename
      base64
    }
  }
`;
