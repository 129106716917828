import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { EnquiryForm } from '../../../components/EnquiryForm';
import { ScreenTitleContainer } from '../../../containers';

export const CustomerSupportFormPage = () => {
  return (
    <Container className="hok-container" fluid>
      <ScreenTitleContainer row className="mt-4" titleId="productEnquiry.title" />
      <Row className="no-gutters">
        <Col>
          <Card className="label-red">
            <CardBody>
              <EnquiryForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
