import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const ContentIconBox = ({ Icon = null, name = '', link = false, onClick }) => {
  const cardContent = () => (
    <>
      <div className="content-icon d-flex align-items-end justify-content-center pb-2">
        <Icon className="content-box-icon" />
      </div>
      <div className="content-title d-flex align-items-center justify-content-center">
        <h2 className="text-center">
          <FormattedMessage id={name} />
        </h2>
      </div>
    </>
  );

  return link ? (
    <Link className="content-box p-2" to={onClick}>
      {cardContent()}
    </Link>
  ) : (
    <div className="content-box p-2" onClick={onClick}>
      {cardContent()}
    </div>
  );
};

export default ContentIconBox;
