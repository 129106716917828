import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  intl: state.intl,
});

const IntlWrapper = props => (
  <IntlProvider {...props.intl} key={props.intl.locale}>
    {props.children}
  </IntlProvider>
);

export default connect(mapStateToProps)(IntlWrapper);
