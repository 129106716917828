import gql from 'graphql-tag';

export const UserPasswordRecoveryRequestMutation = gql`
  mutation UserPasswordRecoveryRequest($username: String!) {
    userPasswordRecoveryRequest(username: $username) {
      requestCompleted
    }
  }
`;

export default UserPasswordRecoveryRequestMutation;
