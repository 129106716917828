import { get } from 'lodash-es';

export const SUPER_ADMIN = 'superadmin';
export const ADMIN = 'admin';
export const EMPLOYEE = 'employee';
export const USER = 'user';

export const HOK_ROLES = [ADMIN, EMPLOYEE];
export const ALL_ROLES = [ADMIN, EMPLOYEE, USER];

export const userHasAtLeastOneRole = (userRole, validRoles) => {
  return validRoles.includes(userRole);
};

export const isAdmin = user => get(user, 'role', '') === ADMIN || get(user, 'role', '') === SUPER_ADMIN;

export const isHOKEmployee = user =>
  get(user, 'role', '') === ADMIN || get(user, 'role', '') === SUPER_ADMIN || get(user, 'role', '') === EMPLOYEE;

export const isUser = user => get(user, 'role', '') === null;

export const anyValidRole = user => isHOKEmployee(user) || isUser(user);

export const userHasRequiredRole = (user, role) => get(user, 'role', '') === role;
