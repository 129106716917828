import { enabledLanguages, localizationData } from '../../config/intlConfig';
import { SWITCH_LANGUAGE } from '../actions/intlActions';
import { setLocalStorageLocale, getLocalStorageLocale } from '../../constants/LocalStorageKeys';
import { browserLocale } from '../../utils/browser';

const initLocale = getLocalStorageLocale() || browserLocale();

const initialState = {
  locale: enabledLanguages.includes(initLocale) ? initLocale : 'hr',
  enabledLanguages,
  ...(localizationData[enabledLanguages.includes(initLocale) ? initLocale : 'hr'] || {}),
};

const intlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE: {
      setLocalStorageLocale(action.payload.locale);
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export default intlReducer;
