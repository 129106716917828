import React from 'react';
import { Row, Col, Media } from 'reactstrap';
import { formatDateTime } from '../../constants/dateFormatting';
import hokAvatar from '../../img/hok-avatar.png';
import hokPartnerAvatar from '../../img/hok-partner-avatar.svg';
import { Attachment } from '../Attachment';

export const TicketComments = ({ loading, comments, userId, userDisplayName, userAvatar }) => {
  const renderName = (commentUserId, commentAgentNickname) => {
    return commentUserId === userId || !commentAgentNickname ? userDisplayName : commentAgentNickname || 'HOK Digital';
  };

  const renderAvatar = (commentUserId, commentAgentNickname) => {
    return commentUserId === userId || !commentAgentNickname ? userAvatar || hokPartnerAvatar : hokAvatar;
  };

  return (
    <div className={'hok-comments-container' + (loading ? ' loading' : '')}>
      {comments.map(comment => {
        const { files } = JSON.parse(comment.attachments);

        return (
          <Row className="hok-comment-box no-gutters" key={comment.id}>
            <Col>
              <Media className="pb-3 pb-sm-4 pt-4">
                <Media left className="mr-4">
                  <div
                    className="hok-comment-avatar mr-4"
                    style={{
                      backgroundImage: `url("${renderAvatar(comment.userId, comment.agentNickname)}")`,
                    }}
                  />
                </Media>
                <Media body>
                  <Row className="no-gutters">
                    <Col>
                      <h5>{renderName(comment.userId, comment.agentNickname)}</h5>
                      <span className="hok-comment-date">{formatDateTime(comment.createdAt, false)}</span>
                    </Col>
                  </Row>
                  <Row className="no-gutters d-none d-sm-flex">
                    <Col xs="12">
                      <pre>{comment.content}</pre>
                    </Col>
                    {files.length > 0 && (
                      <Col xs="12">
                        {files.map(file => (
                          <Attachment file={file} key={file.key} />
                        ))}
                      </Col>
                    )}
                  </Row>
                </Media>
              </Media>
              <Row className="no-gutters d-sm-none pb-4">
                <Col>
                  <pre className="mb-0">{comment.content}</pre>
                </Col>
              </Row>
              {files.length > 0 && (
                <Row className="no-gutters d-sm-none pb-4">
                  <Col>
                    {files.map(file => (
                      <Attachment file={file} key={file.key} />
                    ))}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        );
      })}
    </div>
  );
};
