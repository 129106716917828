import React, { useState } from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import keyboardUpIcon from '../../img/icons/hok-chevron-up.svg';
import keyboardDownIcon from '../../img/icons/hok-chevron-down.svg';

const CollapsibleTextInfo = ({ placeholder, rawPlaceholder = false, pushdown = false, children, uppercase, bold }) => {
  const [isOpen, setOpen] = useState(false);
  /*
  useEffect(() => {
    const hokDashboardContainer = document.getElementsByClassName('hok-user-dashboard')[0];
    if (isOpen) {
      hokDashboardContainer.style.height = '2080px';
    } else {
      hokDashboardContainer.style.height = 'auto';
    }
  });
  */
  const toggle = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="w-100 collapsible-text-info-container">
      <Card className="w-100">
        <CardBody className="cursor-pointer" onClick={toggle}>
          <div className={isOpen ? `body-text-border` : `body-text`}>
            <p className={((uppercase ? 'text-uppercase' : '') + (bold ? ' font-weight-bold' : '')).trim()}>
              {rawPlaceholder ? placeholder : <FormattedMessage id={placeholder} />}
            </p>
            <img alt="collapse-toggler" src={isOpen ? keyboardUpIcon : keyboardDownIcon} />
          </div>
          <Collapse className={pushdown ? 'collapsible-pushdown' : 'collapsible'} isOpen={isOpen}>
            {children}
          </Collapse>
        </CardBody>
      </Card>
    </div>
  );
};

export default CollapsibleTextInfo;
