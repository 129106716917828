import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormGroup, InputGroup, Input, Label, CardGroup, Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash-es';
import { CustomButton, CustomDashboardInput } from '../../../containers';
import { validateFormSubmission } from '../../../utils/validation';
import { ROUTE_TO_USER_SUPPORT_PAGE } from '../../../constants/routes';
import { PlaceAndZipInput } from '../../../components/PlaceAndZipInput';

export const ProfileInfoBusiness = ({ user, updateProfile, loading, places, loadingPlaces }) => {
  const { formatMessage } = useIntl();
  const VATpayer = get(user, 'VATpayer', null);

  const [address, setAddress] = useState({
    value: get(user, 'address', '') || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });

  const [zip, setZip] = useState({
    value: get(user, 'zip', '') || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
      enum: places.map(place => place.pttNumber),
    },
  });
  const [city, setCity] = useState({
    value: get(user, 'city', '') || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [webAddress, setWebAddress] = useState({
    value: user.webAddress || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.webAddress" />,
    validation: {},
  });

  const handleSubmit = async event => {
    event.preventDefault();

    const fieldsArray = [setAddress, setCity, setZip, setWebAddress];

    if (await validateFormSubmission(fieldsArray)) {
      updateProfile({
        variables: {
          address: address.value,
          city: city.value,
          zip: zip.value,
          webAddress: webAddress.value.trim(),
        },
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CardGroup>
        <Card className="hok-card">
          <CardBody>
            <FormGroup row className="my-4">
              <Col sm="6">
                <Label for="MBO">
                  <FormattedMessage id="inputs.MBO" />
                </Label>
                <InputGroup>
                  <Input className="input-uppercase" id="MBO" readOnly defaultValue={get(user, 'mbo', '')} />
                </InputGroup>
              </Col>
              <Col sm="6">
                <Label for="OIB">
                  <FormattedMessage id="inputs.OIB" />
                </Label>
                <InputGroup>
                  <Input className="input-uppercase" id="OIB" readOnly defaultValue={get(user, 'oib', '')} />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="6">
                <Label for="VATpayer">
                  <FormattedMessage id="inputs.VATpayer" />
                </Label>
                <InputGroup>
                  <Input
                    className="input-uppercase"
                    id="VATpayer"
                    readOnly
                    defaultValue={VATpayer !== null ? formatMessage({ id: VATpayer ? 'yes' : 'no' }) : ''}
                  />
                </InputGroup>
              </Col>
              <Col sm="6">
                <Label for="address">
                  <FormattedMessage id="inputs.address" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="address"
                    name="address"
                    placeholder={true}
                    fieldState={address}
                    onChange={event => {
                      setAddress({ ...address, invalid: false, value: event.target.value });
                    }}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <PlaceAndZipInput
                places={places}
                loadingPlaces={loadingPlaces}
                zip={zip}
                setZip={setZip}
                city={city}
                setCity={setCity}
              />
            </FormGroup>
            <FormGroup row>
              <Col sm="6">
                <Label for="sector">
                  <FormattedMessage id="inputs.sector" />
                </Label>
                <InputGroup>
                  <Input
                    className="input-uppercase"
                    id="sector"
                    readOnly
                    defaultValue={formatMessage({ id: `accountType.${get(user, 'sector', '')}` })}
                  />
                </InputGroup>
              </Col>
              <Col sm="6">
                <Label for="webAddress">
                  <FormattedMessage id="inputs.webAddress" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="webAddress"
                    placeholder={true}
                    fieldState={webAddress}
                    onChange={event => {
                      setWebAddress({ ...webAddress, invalid: false, value: event.target.value });
                    }}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <Row className="mt-5">
              <Col xs="12" className="d-flex align-items-end">
                <p>
                  <FormattedMessage id="userProfilePage.changeDataSupport" />{' '}
                  <Link className="font-weight-bold" to={ROUTE_TO_USER_SUPPORT_PAGE}>
                    <FormattedMessage id="userProfilePage.support" />
                  </Link>
                  .
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CardGroup>
      <Row className="no-gutters mt-5">
        <Col className="text-right" xs="12">
          <CustomButton
            className="hok-dashboard-btn"
            loaderProp={loading}
            disabled={
              loading ||
              ((get(user, 'address', '') || '') === address.value &&
                (get(user, 'city', '') || '') === city.value &&
                (get(user, 'zip', '') || '') === zip.value &&
                (get(user, 'webAddress', '') || '') === webAddress.value)
            }
            block={false}
            translationId="save"
          />
        </Col>
      </Row>
    </Form>
  );
};
