import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Input } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { GetUserInvoicesQuery } from '../../../operations/queries/GetUserInvoicesQuery';
import { Spinner, ScreenTitleContainer } from '../../../containers';
import { InvoiceCard } from '../../../components/InvoiceCard';
import { handleRequestError } from '../../../constants/errorCodes';
import { INVOICE_STATUS_PAID, INVOICE_STATUS_NOT_PAID } from '../../../constants/business';

export const UserMyInvoices = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { data, loading, error, fetchMore } = useQuery(GetUserInvoicesQuery, {
    variables: { filter: { paid: false }, order: { date: 'DESC' } },
    error: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const [filterValue, setFilterValue] = useState(INVOICE_STATUS_NOT_PAID);

  const invoices = get(data, 'getInvoices.invoices', []);
  const invoicesTotalCount = get(data, 'getInvoices.totalCount', 0);

  return (
    <Container className="hok-container" fluid>
      <Row className="no-gutters mb-5">
        <Col>
          <div className="mx-auto title-text w-100 d-flex align-items-center flex-wrap">
            <ScreenTitleContainer
              titleId="userMyInvoices.title"
              childClassName="hok-inline-title title-text"
              maxWidthSet
            />
            {invoicesTotalCount > 0 && (
              <div className="hok-inline-title-filter ml-0 ml-sm-auto mt-3 mt-sm-0">
                <Input
                  className="hok-inline-title-filter-select"
                  type="select"
                  id="invoiceFilter"
                  value={filterValue}
                  onChange={event => {
                    const value = event.target.value;

                    const filter = {};
                    if (value) {
                      filter.paid = value === INVOICE_STATUS_PAID;
                    }
                    setFilterValue(value);

                    // Workaround for refetch() not updating retured data when initial query has
                    // variables and onError props.
                    // Setting fetchPolicy to "network-only" or "cache-and-network" causes 2 request
                    // being fired where the latter has the initial query variables and overrides the refetch()
                    // https://github.com/apollographql/react-apollo/issues/1929
                    // https://github.com/apollographql/apollo-client/issues/3573
                    fetchMore({
                      variables: { order: { date: 'DESC' }, filter },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        return {
                          getInvoices: {
                            ...fetchMoreResult.getInvoices,
                          },
                        };
                      },
                    });
                  }}>
                  <option value="">{formatMessage({ id: 'all' }).toUpperCase()}</option>
                  <option value={INVOICE_STATUS_PAID}>
                    {formatMessage({ id: `invoiceStatus.${INVOICE_STATUS_PAID}` }).toUpperCase()}
                  </option>
                  <option value={INVOICE_STATUS_NOT_PAID}>
                    {formatMessage({ id: `invoiceStatus.${INVOICE_STATUS_NOT_PAID}` }).toUpperCase()}
                  </option>
                </Input>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row className="no-gutters mt-5">
        <Col>
          {(!data && !error) || loading ? (
            <Spinner className="spinner-positioning" />
          ) : invoices.length < 1 || error ? (
            <div className="mx-auto content-text">
              <p className="hok-text-dark pr-1">
                <FormattedMessage id="userMyInvoices.noInvoicesFound" />
              </p>
            </div>
          ) : (
            invoices.map(invoice => <InvoiceCard key={invoice.invoiceNumber} invoice={invoice} />)
          )}
        </Col>
      </Row>
    </Container>
  );
};
