import gql from 'graphql-tag';

export const GetClaimFromCodeQuery = gql`
  query GetClaimFromCode($claimConfirmationCode: String!) {
    viewReportedClaim(claimConfirmationCode: $claimConfirmationCode) {
      claimConfirmationCode
      claimClass
      claimNo
      claimDefinition {
        book
        group
      }
      dateOfReport
      status
      userFriendlyStatus
      role
      userFriendlyRole
    }
  }
`;
