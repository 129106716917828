import React, { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { ScreenTitleContainer } from '../../../containers/ScreenTitleContainer';
import { ArrowLinkButton, CollapsiblePackageInfo, DescriptionText, CustomButton } from '../../../containers';
import {
  ROUTE_TO_USER_POLICIES_PAGE,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
  ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE,
} from '../../../constants/routes';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  TRAVEL_INSURANCE_PACKAGES,
  TRAVEL_INSURANCE_COVERS,
  POLICY_CODE_PZ,
  TRAVEL_INSURANCE_PURPOSE,
  TRAVEL_PURPOSE_PLEASURE,
  POLICY_COVER_NO_INCIDENT,
} from '../../../constants/business';
import {
  setSessionStorageTravelInsurancePackage,
  setSessionStorageTravelInsuranceCover,
  setSessionStoragePolicyEligibility,
} from '../../../constants/LocalStorageKeys';
import { PreContractualDocumentation } from '../../../components/PreContractualDocumentation';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../constants/support';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { UserInteractionModal } from '../../../components/UserInteractionModal';
import {
  TRAVEL_PURPOSE_MODAL,
  TRAVEL_PARTICIPATING,
  CONTINUE_PREVIOUS_CONTRACTING,
} from '../../../constants/modalsContent';
import { ModalWithCardSelection } from '../../../components/ModalWithCardSelection';
import { useServerStorageFromProfileKey } from '../../../utils/customHooks';
import { googleAnalyticsEvent } from '../../../utils/browser';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserPoliciesTravelInsurance = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const sector = useSelector(state => state.auth.user.sector);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSelfContracting, setIsOpenSelfContracting] = useState(false);
  const [isOpenContinue, setIsOpenContinue] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const packageId = useRef(null);

  const [additionalCoversModal, setAdditionalCoversModal] = useState(false);
  const toggleAdditionalCoversModal = useCallback(() => {
    setAdditionalCoversModal(isOpen => !isOpen);
  }, []);

  const { serverStorageData, stateKey } = useServerStorageFromProfileKey();

  const startContracting = useCallback(
    coverId => {
      setSessionStorageTravelInsurancePackage(packageId.current.id);
      setSessionStorageTravelInsuranceCover(coverId);
      setSessionStoragePolicyEligibility(POLICY_CODE_PZ);
      history.push(ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE);
    },
    [history]
  );

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const toggleContinueModal = () => {
    setIsOpenContinue(prevState => !prevState);
  };

  const toggleSelfContracting = () => {
    setIsOpenSelfContracting(prevState => !prevState);
  };

  return (
    <Container fluid className="policies">
      <AdFormIFrame title="Proizvodi - Putno" />
      <ArrowLinkButton xlColSize={10} linkTo={ROUTE_TO_USER_POLICIES_PAGE} />
      <ScreenTitleContainer
        row
        xlColSize={10}
        xlColOffset={1}
        className="mt-4"
        titleId="userTravelInsurancePage.title"
      />
      <Row className="no-gutters">
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <Row className="no-gutters mx-3">
            <Col>
              <h5 className="mb-4">Putujte bezbrižno i sigurno</h5>
              <p>
                <FormattedMessage id="userTravelInsurancePage.paragraph1" />
              </p>
              <p>
                <FormattedMessage id="userTravelInsurancePage.paragraph2" />
              </p>
              <p className="hok-text-small">
                <span className="font-weight-bold">NAPOMENA:</span> Policu Putnog zdravstvenog osiguranja je moguće
                ugovoriti u trajanju od godinu dana (365 dana), ali pojedinačni boravak izvan Republike Hrvatske ne
                smije trajati dulje od 92 dana.
              </p>
            </Col>
          </Row>
          <Row className="m-3">
            {TRAVEL_INSURANCE_PACKAGES.map(travelPackage => (
              <Col md="6" xl="4" className="mb-4 mb-xl-0" key={travelPackage.id}>
                <CollapsiblePackageInfo
                  placeholder={travelPackage.name}
                  buttonTranslationId={sector === USER_ACCOUNT_BUSINESS ? 'requestOffer' : 'new'}
                  submitFn={() => {
                    if (sector === USER_ACCOUNT_BUSINESS) {
                      googleAnalyticsEvent({
                        category: 'Proizvodi',
                        action: 'Proizvodi_Putno_Zatrazi_Ponudu',
                      });
                      history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                        subject: ENQUIRY_SUBJECT_NEW_POLICY,
                        title: `Kupnja nove "${formatMessage({ id: travelPackage.name })}" police`,
                      });
                    } else {
                      packageId.current = travelPackage;
                      setIsOpenSelfContracting(true);
                    }
                  }}>
                  <div className="hok-package-data">
                    <DescriptionText value={travelPackage.id} />
                  </div>
                </CollapsiblePackageInfo>
              </Col>
            ))}
          </Row>
          <Row className="no-gutters m-3">
            <Col>
              <h5>
                <FormattedMessage id="userMyPolicies.policyData.additionalCovers" />:
              </h5>
            </Col>
          </Row>
          <Row className="m-3">
            {TRAVEL_INSURANCE_COVERS.map(cover => (
              <Col md="6" xl="4" className="mb-4 mb-xl-0" key={cover.id}>
                <CollapsiblePackageInfo placeholder={cover.name} showButton={false}>
                  <div className="hok-package-data">
                    <DescriptionText value={cover.id} />
                  </div>
                </CollapsiblePackageInfo>
              </Col>
            ))}
          </Row>
          <PreContractualDocumentation productCode={POLICY_CODE_PZ} />
        </Col>
      </Row>
      <UserInteractionModal
        isOpen={isOpenSelfContracting}
        toggle={toggleSelfContracting}
        titleId="modalHeaders.warning"
        textId={TRAVEL_PARTICIPATING}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="notParticipating"
          onClick={() => {
            googleAnalyticsEvent({
              category: 'Proizvodi',
              action: 'Proizvodi_Putno_Zatrazi_Ponudu',
            });
            history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
              subject: ENQUIRY_SUBJECT_NEW_POLICY,
              title: `Kupnja nove "${formatMessage({ id: packageId.current.name })}" police`,
            });
          }}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="participating"
          onClick={() => {
            toggleSelfContracting();
            if (serverStorageData && serverStorageData.type === POLICY_CODE_PZ) {
              toggleContinueModal();
            } else {
              toggle();
            }
          }}
        />
      </UserInteractionModal>
      <UserInteractionModal
        isOpen={isOpenContinue}
        toggle={toggleContinueModal}
        titleId="modalHeaders.warning"
        textId={CONTINUE_PREVIOUS_CONTRACTING}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="no"
          onClick={() => {
            toggleContinueModal();
            toggle();
          }}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="yes"
          onClick={() => {
            setSessionStoragePolicyEligibility(POLICY_CODE_PZ);
            history.push(`${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE}/${stateKey}`);
          }}
        />
      </UserInteractionModal>
      <UserInteractionModal
        isOpen={isOpen}
        toggle={toggle}
        titleId={`modalHeaders.${showWarning ? 'warning' : 'tripPurpose'}`}
        textId={showWarning ? TRAVEL_PURPOSE_MODAL : null}
        textComponent={
          <>
            <Row className="no-gutters">
              <Col sm={{ size: 10, offset: 1 }} className="text-center">
                <p className="mb-0">Odaberite koja je svrha vašeg putovanja:</p>
              </Col>
            </Row>
            <Row className="no-gutters mt-4">
              <Col sm={{ size: 10, offset: 1 }}>
                {TRAVEL_INSURANCE_PURPOSE.map(travelPurpose => (
                  <Button
                    block
                    className="hok-ghost-button"
                    key={travelPurpose.id}
                    onClick={() => {
                      if (travelPurpose.id === TRAVEL_PURPOSE_PLEASURE) {
                        toggle();
                        toggleAdditionalCoversModal();
                      } else {
                        setShowWarning(true);
                      }
                    }}>
                    <FormattedMessage id={travelPurpose.name} />
                  </Button>
                ))}
              </Col>
            </Row>
          </>
        }>
        {showWarning && (
          <>
            <CustomButton
              type="button"
              outline
              className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
              block={false}
              translationId="back"
              onClick={() => {
                toggle();
                setShowWarning(false);
              }}
            />
            <CustomButton
              className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
              block={false}
              translationId="form"
              onClick={() => {
                googleAnalyticsEvent({
                  category: 'Proizvodi',
                  action: 'Proizvodi_Putno_Zatrazi_Ponudu',
                });
                history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                  subject: ENQUIRY_SUBJECT_NEW_POLICY,
                  title: `Kupnja nove "${formatMessage({ id: packageId.current.name })}" police`,
                });
              }}
            />
          </>
        )}
      </UserInteractionModal>
      <ModalWithCardSelection
        cardsList={[
          ...TRAVEL_INSURANCE_COVERS,
          { id: POLICY_COVER_NO_INCIDENT, name: `userMyPoliciesTravelInsurance.${POLICY_COVER_NO_INCIDENT}` },
        ]}
        toggle={toggleAdditionalCoversModal}
        isOpen={additionalCoversModal}
        setFunction={startContracting}
      />
    </Container>
  );
};
