import gql from 'graphql-tag';

export const LoginMutation = gql`
  mutation Login($username: String!, $password: String!, $doors: LoginDoorsEnum) {
    login(username: $username, password: $password, doors: $doors) {
      token
    }
  }
`;

export default LoginMutation;
