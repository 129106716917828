import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import 'react-dates/initialize';
import moment from 'moment';
import hrLocale from 'moment/locale/hr';

// Styles
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-table/react-table.css';
// import './scss/react-table.css';
import 'react-dates/lib/css/_datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-phone-number-input/style.css';

// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Svg Core
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faLock,
  faCog,
  faUser,
  faMobileAlt,
  faArrowLeft,
  faHome,
  faBell,
  faCheckCircle,
  faExclamationCircle,
  faList,
  faQuestionCircle,
  faExclamationTriangle,
  faPowerOff,
  faChevronLeft,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle as farCheckCircle, faEdit, faTrashAlt, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';

// Import Main styles for this application
import './scss/style.scss';

import AppRouting from './config/RoutesConfig';
import { history } from './config/historyConfig';
import { store } from './config/storeConfig';
import { client } from './config/apolloConfig';
import ScrollToTop from './containers/ScrollToTop';
import IntlWrapper from './containers/IntlWrapper';
import { loadSupportData } from './redux/actions/supportActions';

library.add([
  faSpinner,
  faLock,
  faCog,
  faUser,
  faMobileAlt,
  faArrowLeft,
  faHome,
  faBell,
  faCheckCircle,
  faExclamationCircle,
  faList,
  faQuestionCircle,
  faExclamationTriangle,
  faPowerOff,
  faChevronLeft,
  farCheckCircle,
  faEdit,
  faTrashAlt,
  faFolderOpen,
  faPowerOff,
  faEllipsisV,
]);

moment.updateLocale('hr', hrLocale);

const App = () => {
  useEffect(() => {
    store.dispatch(loadSupportData());
  }, []);

  return (
    <Provider store={store}>
      <IntlWrapper>
        <>
          <ApolloProvider client={client}>
            <ConnectedRouter history={history}>
              <CookiesProvider>
                <ScrollToTop>
                  <AppRouting />
                </ScrollToTop>
              </CookiesProvider>
            </ConnectedRouter>
          </ApolloProvider>
          <ToastContainer className="hok-toast-container" newestOnTop />
        </>
      </IntlWrapper>
    </Provider>
  );
};

export default App;
