import React from 'react';
import { FormattedNumber } from 'react-intl';



export let displayCurrencyOptions = {
  style: 'currency',
  currency: 'EUR',
  currencySign: 'standard',
  currencyDisplay: 'narrowSymbol',
};

export const displayTotalPremium = (fromQuery, fromServer, defaultCurrency = "EUR") => {

  if (fromQuery !== undefined && fromQuery !== null) {
    return <FormattedNumber value={fromQuery} {...displayCurrencyOptions} />;
  } else if (fromServer !== undefined && fromServer !== null) {
    return <FormattedNumber value={fromServer} {...displayCurrencyOptions} />;
  } else {
    return <FormattedNumber value={0} {...displayCurrencyOptions} />;
  }
};
