import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ArrowLinkButton, CustomButton, CollapsiblePackageInfo, DescriptionText } from '../../../containers';
import { ROUTE_TO_USER_POLICIES_PAGE, ROUTE_TO_USER_NEW_AO_POLICY_PAGE } from '../../../constants/routes';
import { PreContractualDocumentation } from '../../../components/PreContractualDocumentation';
import { POLICY_CODE_AO, CAR_LIABILITY_COVERS } from '../../../constants/business';
import { CarInsuranceEligibilityModal } from './CarInsuranceEligibilityModal';
import { useServerStorageFromProfileKey } from '../../../utils/customHooks';
import { setSessionStoragePolicyEligibility } from '../../../constants/LocalStorageKeys';
import { UserInteractionModal } from '../../../components/UserInteractionModal';
import { CONTINUE_PREVIOUS_CONTRACTING } from '../../../constants/modalsContent';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserPoliciesCarInsurance = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenContinue, setIsOpenContinue] = useState(false);

  const { serverStorageData, stateKey } = useServerStorageFromProfileKey();

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const toggleContinueModal = () => {
    setIsOpenContinue(prevState => !prevState);
  };

  return (
    <Container fluid className="policies">
      <AdFormIFrame title="Proizvodi - Autoodgovornost" />
      <ArrowLinkButton xlColSize={10} linkTo={ROUTE_TO_USER_POLICIES_PAGE} />
      <Row className="mt-4">
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <h3>
            <FormattedMessage id="userMyPoliciesCarLiability.title" />
          </h3>
          <Row className="no-gutters mt-5 mx-3">
            <Col xs="12">
              <p>
                Osiguranje od autodgovornosti je obvezno osiguranje s kojim se svi vlasnici motornih vozila susreću
                barem jednom godišnje. Ugovaranjem police obveznog auto osiguranja, koje je između ostalog i zakonski
                propisano, osiguravate svoju odgovornost prema trećim osobama za štete koje ste prouzročili uporabom
                svog vozila. Ovom policom ne štitite samo sudionike u prometu, već i svoj ekonomski interes.
              </p>
            </Col>
          </Row>
          <Row className="no-gutters m-3">
            <Col>
              <h5>
                <FormattedMessage id="userMyPolicies.policyData.additionalCovers" />:
              </h5>
            </Col>
          </Row>
          <Row className="m-3">
            {CAR_LIABILITY_COVERS.map(cover => (
              <Col md="6" className="mb-4 mb-xl-0" key={cover}>
                <CollapsiblePackageInfo placeholder={`userMyPoliciesCarLiability.${cover}`} showButton={false}>
                  <div className="hok-package-data">
                    <div className="insurance-package">
                      <h3 className="text-dark mb-4">Opis pokrića</h3>
                      <DescriptionText value={cover} />
                    </div>
                  </div>
                </CollapsiblePackageInfo>
              </Col>
            ))}
          </Row>
          <PreContractualDocumentation productCode={POLICY_CODE_AO} />
          <Row className="no-gutters mx-3 mt-5">
            <Col className="text-center">
              <CustomButton
                className="hok-dashboard-btn-wide"
                block={false}
                translationId="newPolicyShort"
                onClick={toggle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <CarInsuranceEligibilityModal
        isOpen={isOpen}
        toggle={toggle}
        contractingFn={() => {
          toggle();
          setSessionStoragePolicyEligibility(POLICY_CODE_AO);
          if (serverStorageData && serverStorageData.type === POLICY_CODE_AO) {
            toggleContinueModal();
          } else {
            history.push(ROUTE_TO_USER_NEW_AO_POLICY_PAGE);
          }
        }}
      />
      <UserInteractionModal
        isOpen={isOpenContinue}
        toggle={toggleContinueModal}
        titleId="modalHeaders.warning"
        textId={CONTINUE_PREVIOUS_CONTRACTING}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="no"
          onClick={() => history.push(ROUTE_TO_USER_NEW_AO_POLICY_PAGE)}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="yes"
          onClick={() => {
            toggleContinueModal();
            history.push(`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE}/${stateKey}`);
          }}
        />
      </UserInteractionModal>
    </Container>
  );
};
