import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as HokUploadIcon } from '../../img/icons/hok-upload.svg';
import { useMutation } from 'react-apollo';
import { UserUploadClaimFilesMutation } from '../../operations/mutations/UserCreateClaimCommentMutation';
import { claimDocumentUpload } from '../../utils/imageUpload';
import { GetUserClaimFilesQuery } from '../../operations/queries/GetUserClaimsQuery';
import { handleRequestError } from '../../constants/errorCodes';
import { RequestUploadAccessMutation } from '../../operations/mutations/RequestUploadAccessMutation';
import { UPLOAD_TYPE_CLAIM_DOCUMENT } from '../../constants/uploadFileTypes';

export const FilesDropzone = ({ damageClaim, setNewFiles, anonymous }) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const claimIdVariables =
    damageClaim.claimNo && !anonymous
      ? { claimNo: damageClaim.claimNo, claimClass: damageClaim.claimClass }
      : { claimConfirmationCode: damageClaim.claimConfirmationCode };

  const [uploadFiles] = useMutation(UserUploadClaimFilesMutation, {
    refetchQueries: () => [
      {
        query: GetUserClaimFilesQuery,
        variables: { filter: claimIdVariables, order: { createdAt: 'ASC' }, anonymous },
      },
    ],
    onError: error => {
      setNewFiles([]);
      handleRequestError(error, dispatch);
    },
    onCompleted: () => {
      setNewFiles([]);
    },
  });

  const [requestUpload] = useMutation(RequestUploadAccessMutation, {
    variables: { uploadType: UPLOAD_TYPE_CLAIM_DOCUMENT },
    onError: error => handleRequestError(error, dispatch),
  });

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(async file => {
        try {
          setNewFiles(f => [...f, { name: file.name, progress: true }]);
          const { data } = await requestUpload();
          const signedUrlsData = JSON.parse(data.requestUploadAccess.json);
          claimDocumentUpload({
            document: file,
            signedUrlsData,
            // progressFunction: e => {
            //   const done = e.position || e.loaded;
            //   const total = e.totalSize || e.total;
            //   const perc = Math.floor((done / total) * 1000) / 10;
            //   setNewFiles(f => {
            //     const idx = f.findIndex(stateFile => stateFile.path === file.name);
            //     const newFilesArray = [...f];
            //     newFilesArray[idx] = { ...f[idx], progress: perc };
            //     return newFilesArray;
            //   });
            //   if (perc >= 100) {
            //     console.log('Done');
            //   } else {
            //     console.log(`${perc}%`);
            //   }
            // },
            linkFunction: uploadFiles,
            linkVars: { ...claimIdVariables, anonymous },
            onError: setNewFiles,
          });
        } catch (e) {
          setNewFiles(f => [...f.filter(newFile => newFile.name !== file.name)]);
        }
      });
    },
    [setNewFiles, claimIdVariables, uploadFiles, anonymous, requestUpload]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: 10 * 1024 * 1024, // 10 MB in bytes
  });

  // eslint-disable-next-line
  const acceptStyle = {
    borderColor: '#00e676',
  };

  // eslint-disable-next-line
  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept, acceptStyle, rejectStyle]
  );

  return (
    <div {...getRootProps({ className: 'hok-docs-dropzone', style })}>
      <input {...getInputProps()} />
      <div className="text-center">
        <HokUploadIcon className="svg-red mb-2" height="64" width="64" />
        <p className="mb-0">
          <span>Povucite datoteku ovdje</span>
          <span>ili</span>
          <span className="emphasize">kliknite za prijenos</span>
        </p>
      </div>
    </div>
  );
};
