import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ScreenTitleContainer } from '../../containers/ScreenTitleContainer';
import { CustomButton } from '../../containers';

export const ErrorBoundary = ({ errorMessage, redirectLocation }) => {
  return (
    <Container fluid className="hok-container">
      <Row>
        <Col>
          <ScreenTitleContainer row titleId="errorBoundary.title" />
          <Row className="no-gutters">
            <Col>
              <h5>
                {process.env.NODE_ENV !== 'development' || !errorMessage ? (
                  <FormattedMessage id="errorBoundary.message" />
                ) : (
                  errorMessage
                )}
              </h5>
            </Col>
          </Row>
          <Row className="no-gutters mt-3">
            <Col>
              <CustomButton
                className="hok-dashboard-btn"
                translationId="back"
                block={false}
                onClick={() => (window.location = redirectLocation)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
