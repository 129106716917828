import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { ArrowLinkButton, CollapsiblePackageInfo, DescriptionText, CustomButton } from '../../../../containers';
import {
  ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
} from '../../../../constants/routes';
import { MOJ_DOM, MOJ_DOM_KUCANSTVO, POLICY_CODE_MD, HOK_KUCNA_ASISTENCIJA } from '../../../../constants/business';
import { PreContractualDocumentation } from '../../../../components/PreContractualDocumentation';
import { ENQUIRY_SUBJECT_MOJ_DOM } from '../../../../constants/support';
import { useLegalDocumentsQuery } from '../../../../utils/customHooks';
import { useSelector } from 'react-redux';
import { googleAnalyticsEvent } from '../../../../utils/browser';
import { AdFormIFrame } from '../../../../components/AdFormIframe';

export const MojDomInsurance = () => {
  const history = useHistory();
  const supportData = useSelector(state => state.support);

  const { legalDocuments: docRisksMD } = useLegalDocumentsQuery('TABLICA_OSIGURANJE_IMOVINE_RIZICI_MD');
  const { legalDocuments: docRisksMDKucanstvo } = useLegalDocumentsQuery(
    'TABLICA_OSIGURANJE_IMOVINE_RIZICI_MD_KUCANSTVO'
  );

  const risksMD = get(docRisksMD, '[0].link', null);
  const risksMDKucanstvo = get(docRisksMDKucanstvo, '[0].link', null);

  return (
    <Container fluid className="policies">
      <AdFormIFrame title="Proizvodi - Imovina - Moj Dom" />
      <ArrowLinkButton xlColSize={10} linkTo={ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE} />
      <Row className="mt-4">
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <h3>
            <FormattedMessage id="routes.mojDomInsurance" />
          </h3>
          <Row className="no-gutters mt-5 mx-3">
            <Col xs={12}>
              <h5 className="mb-4">
                Sačuvajte sigurnost Vašeg doma i odaberite zaštitu koja najviše odgovara Vašim potrebama!
              </h5>
              <p>
                Samo jedno mjesto na svijetu nazivate MOJ DOM. U njemu počinje i završava vaš dan, nastaju najvrijednije
                emocije, proživljavate najiskrenije trenutke. Sačuvajmo sigurnost našeg doma i odaberimo zaštitu koja
                najviše odgovara našim potrebama.
              </p>
              <p>
                Ovisno o odabiru, policom možemo zaštiti građevinski dio stana/kuće i stvari u njima, a ujedno i pokriti
                i izdatke za štete koje možemo nanijeti trećim osobama.
              </p>
            </Col>
          </Row>
          <Row className="m-3">
            <Col md="6" className="mb-4 mb-xl-0">
              <CollapsiblePackageInfo placeholder={'userPropertyInsurancePage.mojDom.packageMojDom'} showButton={false}>
                <div className="hok-package-data">
                  <DescriptionText value={MOJ_DOM} data={risksMD} />
                </div>
              </CollapsiblePackageInfo>
            </Col>
            <Col md="6" className="mb-4 mb-xl-0">
              <CollapsiblePackageInfo
                placeholder={'userPropertyInsurancePage.mojDom.packageMojDomKucanstvo'}
                showButton={false}>
                <div className="hok-package-data">
                  <DescriptionText value={MOJ_DOM_KUCANSTVO} data={risksMDKucanstvo} />
                </div>
              </CollapsiblePackageInfo>
            </Col>
          </Row>
          <Row className="no-gutters mt-3 mx-3">
            <Col xs={12}>
              <h5 className="text-dark">ASISTENCIJA:</h5>
            </Col>
          </Row>
          <Row className="m-3">
            <Col md="6" className="mb-4 mb-xl-0">
              <CollapsiblePackageInfo
                placeholder={'userPropertyInsurancePage.mojDom.HOK_KUCNA_ASISTENCIJA'}
                showButton={false}>
                <div className="hok-package-data">
                  <DescriptionText value={HOK_KUCNA_ASISTENCIJA} data={supportData} spaced />
                </div>
              </CollapsiblePackageInfo>
            </Col>
          </Row>
          <PreContractualDocumentation productCode={POLICY_CODE_MD} />
          <Row className="no-gutters mx-3 mt-5">
            <Col className="text-center">
              <CustomButton
                className="hok-dashboard-btn-wide"
                block={false}
                translationId="requestOffer"
                onClick={() => {
                  googleAnalyticsEvent({
                    category: 'Proizvodi',
                    action: 'Proizvodi_Imovina_Moj_Dom_Zatrazi_Ponudu',
                  });
                  history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                    subject: ENQUIRY_SUBJECT_MOJ_DOM,
                    title: 'Kupnja nove police "MOJ DOM"',
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
