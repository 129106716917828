import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useMutation } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { Stepper } from 'react-form-stepper';
import { ReactComponent as HokBackIcon } from '../../img/icons/hok-back.svg';
import { ROUTE_TO_USER_POLICIES_PAGE } from '../../constants/routes';
import { UserInteractionModal } from '../../components/UserInteractionModal';
import { CANCEL_CONTRACTING } from '../../constants/modalsContent';
import { CustomButton } from '..';
import { removeSessionStoragePolicyEligibility } from '../../constants/LocalStorageKeys';
import { handleRequestError } from '../../constants/errorCodes';
import { SetServerStorageMutation } from '../../operations/mutations/SetServerStorageMutation';

export const StepperWrapper = ({
  cancelButton = true,
  title = 'locale',
  policyTranslationBase,
  children,
  steps,
  activeStep,
  policyCode,
  stateKey,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const [deleteServerStorageRecord] = useMutation(SetServerStorageMutation, {
    variables: { stateKey, shouldDelete: true },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <Container className="hok-container new-policy" fluid>
      <Row className="no-gutters">
        <Col>
          {cancelButton && (
            <Row className={`no-gutters`}>
              <Col xs="12">
                <div className="hok-cancel-link d-flex align-items-center" onClick={() => setIsOpen(true)} to={'#'}>
                  <HokBackIcon />
                  <span className="ml-2">
                    <FormattedMessage id="buttons.giveUp" />
                  </span>
                </div>
              </Col>
            </Row>
          )}
          <Row className="mt-4 ml-0">
            <h3 className="mr-2">
              <FormattedMessage id="userMyPoliciesNewPolicy.title" />
            </h3>
          </Row>
          <Row>
            <Col>
              <Row className="mt-3">
                <Col>
                  <Card>
                    <div className="card-header">
                      <Row>
                        <Col className="mt-4 step-counter-container align-items-center">
                          <div className="react-stepper-container w-100">
                            <Stepper steps={steps} activeStep={activeStep} styleConfig={{ size: '36px' }} />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4 mb-2 ml-1">
                        <h3>
                          <FormattedMessage id={title} />
                        </h3>
                      </Row>
                    </div>
                    <CardBody>{children}</CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <UserInteractionModal
        isOpen={isOpen}
        toggle={toggle}
        titleId="modalHeaders.cancelContracting"
        textId={CANCEL_CONTRACTING}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="no"
          onClick={toggle}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="yes"
          onClick={() => {
            if (policyCode) {
              removeSessionStoragePolicyEligibility(policyCode);
            }
            if (stateKey) {
              deleteServerStorageRecord();
            }
            history.push(ROUTE_TO_USER_POLICIES_PAGE);
          }}
        />
      </UserInteractionModal>
    </Container>
  );
};
