import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { ArrowLinkButton, ScreenTitleContainer, DescriptionText, CustomButton } from '../../../containers';
import { ROUTE_TO_USER_REPORT_DAMAGE } from '../../../constants/routes';
import {
  damageClaimGroup,
  CLAIM_ACCIDENT,
  REPORT_NESRETNI_SLUCAJ,
  REPORT_SMRTNI_SLUCAJ,
} from '../../../constants/claimDefs';
import { GetClaimDocumentationQuery } from '../../../operations/queries/GetClaimDocumentationQuery';
import { handleRequestError } from '../../../constants/errorCodes';
import { FormattedMessage } from 'react-intl';
import { DocumentLink } from '../../../components/DocumentLink';
import { MAIN_ATTACHMENT } from '../../../constants/documentTypes';
import { useGoogleAnalyticsPageview } from '../../../utils/customHooks';

export const GenericDamageClaimReportPage = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [groupName, pageName, gaPageviewName] = damageClaimGroup(params.damageClaimType);

  useGoogleAnalyticsPageview(gaPageviewName);

  const { data } = useQuery(GetClaimDocumentationQuery, {
    variables: {
      filter: {
        active: true,
        required: true,
        ...(groupName !== CLAIM_ACCIDENT && { type: MAIN_ATTACHMENT }),
        claimGroup: groupName,
      },
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const documents = get(data, 'getReportClaimDocuments.documents', []).filter(doc => !!doc.link);

  if (!groupName) {
    return <Redirect to={ROUTE_TO_USER_REPORT_DAMAGE} />;
  }

  const renderDocumentation = () => {
    if (groupName === CLAIM_ACCIDENT) {
      const documentsAccident = documents.filter(doc => doc.reportType === REPORT_NESRETNI_SLUCAJ);
      const documentsDeath = documents.filter(doc => doc.reportType === REPORT_SMRTNI_SLUCAJ);

      return (
        <>
          {documentsAccident.length > 0 && (
            <>
              <Row className="no-gutters my-3">
                <Col>
                  <h5>
                    <FormattedMessage id={`userReportDamagePage.${pageName}.accident`} />:
                  </h5>
                </Col>
              </Row>
              <p>
                Preuzmite obrazac, ispunite ga ručno ukoliko je pdf datoteka ili preko računala ukoliko je excel
                datoteka, prijavite štetu unutar aplikacije pritiskom na gumb "prijavi štetu" te kada Vas aplikacija
                zatraži prenesite ispunjeni obrazac.
              </p>
              <Row>
                {documentsAccident.map(document => (
                  <Col className="d-flex align-items-center mb-3" xs="12" key={document.documentId}>
                    <DocumentLink
                      content={document.content + (document.mimeType ? `.${document.mimeType}` : '')}
                      link={document.link}
                    />
                  </Col>
                ))}
              </Row>
              <CustomButton
                className="hok-dashboard-btn-wide mt-5"
                block={false}
                translationId="reportDamage"
                onClick={() =>
                  history.push(`${ROUTE_TO_USER_REPORT_DAMAGE}/${params.damageClaimType}/step1`, {
                    ...history.location.state,
                    reportType: REPORT_NESRETNI_SLUCAJ,
                    groupName,
                  })
                }
              />
            </>
          )}

          {documentsDeath.length > 0 && (
            <>
              <Row className="no-gutters my-3">
                <Col>
                  <h5>
                    <FormattedMessage id={`userReportDamagePage.${pageName}.death`} />:
                  </h5>
                </Col>
              </Row>
              <p>
                Preuzmite obrazac, ispunite ga ručno ukoliko je pdf datoteka ili preko računala ukoliko je excel
                datoteka, prijavite štetu unutar aplikacije pritiskom na gumb "prijavi štetu" te kada Vas aplikacija
                zatraži prenesite ispunjeni obrazac.
              </p>
              <Row>
                {documentsDeath.map(document => (
                  <Col className="d-flex align-items-center mb-3" xs="12" key={document.documentId}>
                    <DocumentLink
                      content={document.content + (document.mimeType ? `.${document.mimeType}` : '')}
                      link={document.link}
                    />
                  </Col>
                ))}
              </Row>
              <CustomButton
                className="hok-dashboard-btn-wide mt-5"
                block={false}
                translationId="reportDamage"
                onClick={() =>
                  history.push(`${ROUTE_TO_USER_REPORT_DAMAGE}/${params.damageClaimType}/step1`, {
                    ...history.location.state,
                    reportType: REPORT_SMRTNI_SLUCAJ,
                    groupName,
                  })
                }
              />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {documents.length > 0 && (
            <>
              <p>
                Preuzmite obrazac, ispunite ga ručno ukoliko je pdf datoteka ili preko računala ukoliko je excel
                datoteka, prijavite štetu unutar aplikacije pritiskom na gumb "prijavi štetu" te kada Vas aplikacija
                zatraži prenesite ispunjeni obrazac.
              </p>
              <Row className="no-gutters mt-5">
                {documents.map(document => (
                  <Col className="d-flex align-items-center mb-3" xs="12" key={document.documentId}>
                    <DocumentLink
                      content={document.content + (document.mimeType ? `.${document.mimeType}` : '')}
                      link={document.link}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
          <CustomButton
            className="hok-dashboard-btn-wide mt-5"
            block={false}
            translationId="reportDamage"
            onClick={() =>
              history.push(`${ROUTE_TO_USER_REPORT_DAMAGE}/${params.damageClaimType}/step1`, {
                ...history.location.state,
                groupName,
              })
            }
          />
        </>
      );
    }
  };

  return (
    <Container className="hok-container" fluid>
      <Row className="no-gutters">
        <Col>
          <ArrowLinkButton className="mb-4" linkTo={ROUTE_TO_USER_REPORT_DAMAGE} />
          <Row className="no-gutters mb-4">
            <ScreenTitleContainer titleId={`userReportDamagePage.${pageName}.title`} />
          </Row>
          <Row>
            <Col className="hok-description-text indent">
              <DescriptionText value={groupName} />
              {renderDocumentation()}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
