import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { get } from 'lodash-es';
import { TabsNavigation } from '../../containers/TabsNavigation';
import { DamageClaimInfo } from './DamageClaimInfo';
import { DamageClaimDocumentation } from './DamageClaimDocumentation';
import { ArrowLinkButton, Spinner } from '../../containers';
import { handleRequestError } from '../../constants/errorCodes';
import { useQuery } from 'react-apollo';
import { GetUsersClaimsQuery } from '../../operations/queries/GetUserClaimsQuery';

export const SingleDamageClaimData = ({
  baseRoute,
  damageClaimId,
  damageClaimClassOrCode,
  userId = undefined,
  user = undefined,
}) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetUsersClaimsQuery, {
    variables: {
      filter: {
        ...(damageClaimId
          ? { claimClass: damageClaimClassOrCode, claimNo: damageClaimId }
          : { claimConfirmationCode: damageClaimClassOrCode }),
      },
      ...(userId && { userId: `${userId}` }),
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    fetchPolicy: 'no-cache',
  });

  const damageClaims = get(data, 'getClaims.claims', []);

  if (loading || (!data && !error)) {
    return <Spinner className="spinner-positioning" />;
  }

  if (damageClaims < 1 || error) {
    return <Redirect to={{ pathname: baseRoute }} />;
  }

  const damageClaim = damageClaims[0];

  const itemId = damageClaimId ? `${damageClaimClassOrCode}-${damageClaimId}` : damageClaimClassOrCode;

  return (
    <Container fluid>
      <ArrowLinkButton linkTo={baseRoute} xlColSize={10} />
      <Row className="my-4">
        <Col xs="12" xl={{ offset: 1, size: 10 }}>
          <h3 className="mb-0">{damageClaimId || damageClaimClassOrCode}</h3>
        </Col>
      </Row>
      <TabsNavigation
        baseRoute={baseRoute}
        itemId={itemId}
        styleBreakpoint="sm"
        navTabs={[
          { name: 'userMyDamageClaims.tabs.damageClaimInfo', route: '/' },
          { name: 'userMyDamageClaims.tabs.damageClaimDocumentation', route: '/documentation' },
        ]}>
        <Switch>
          <Route exact path={`${baseRoute}/${itemId}`}>
            <DamageClaimInfo damageClaim={damageClaim} userId={userId} user={user} dispatch={dispatch} />
          </Route>
          <Route exact path={`${baseRoute}/${itemId}/documentation`}>
            <DamageClaimDocumentation damageClaim={damageClaim} userId={userId} user={user} />
          </Route>
        </Switch>
      </TabsNavigation>
    </Container>
  );
};
