import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { validateOIB } from 'oiblib';

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ACTIVATION_CODE_PATTERN = /^[0-9]{6}$/;
const PHONE_NUMBER_PATTERN = /^(\+)(\d{1,3})\s(\d{2,3}?)(\d{6,7})$/;

export const validate = fieldObject => {
  let validation = { invalid: false, errors: [] };
  const rules = get(fieldObject, 'validation', {});
  const value = get(fieldObject, 'value', '');
  const displayName = get(fieldObject, 'displayName', '');

  if (rules.required && (value == null || value === undefined || !value.toString().trim().length)) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.empty" values={{ fieldName: displayName }} />);
  }

  if (rules.isTrue && value !== true) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.notTrue" values={{ fieldName: displayName }} />);
  }

  if (rules.maxLength && value && value.toString().trim().length > rules.maxLength) {
    validation.invalid = true;
    validation.errors.push(
      <FormattedMessage id="validation.maxLength" values={{ fieldName: displayName, max: rules.maxLength }} />
    );
  }

  if (rules.minLength && value && value.toString().trim().length < rules.minLength) {
    validation.invalid = true;
    validation.errors.push(
      <FormattedMessage id="validation.minLength" values={{ fieldName: displayName, min: rules.minLength }} />
    );
  }

  if (rules.maxValue && value && value > rules.maxValue) {
    validation.invalid = true;
    validation.errors.push(
      <FormattedMessage
        id="validation.maxValue"
        values={{ fieldName: displayName, max: rules.maxValueFormatted || rules.maxValue }}
      />
    );
  }

  if (rules.minValue && value && value < rules.minValue) {
    validation.invalid = true;
    validation.errors.push(
      <FormattedMessage
        id="validation.minValue"
        values={{ fieldName: displayName, min: rules.minValueFormatted || rules.minValue }}
      />
    );
  }

  if (rules.matches && value !== rules.matches) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.noMatch" />);
  }

  if (rules.required && rules.email && !EMAIL_PATTERN.test(value)) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.invalidValue" values={{ fieldName: displayName }} />);
  }

  if (rules.required && rules.activationCode && !ACTIVATION_CODE_PATTERN.test(value)) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.invalidValue" values={{ fieldName: displayName }} />);
  }

  if (rules.phoneNumber && !PHONE_NUMBER_PATTERN.test(value)) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.invalidValue" values={{ fieldName: displayName }} />);
  }

  if (rules.enum && value && !rules.enum.includes(value)) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.invalidValue" values={{ fieldName: displayName }} />);
  }

  if (get(rules, 'date.valid', false) && value && !value.isValid()) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.invalidValue" values={{ fieldName: displayName }} />);
  }

  if (get(rules, 'date.maxValue', false) && value && value.isAfter(rules.date.maxValue)) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="validation.invalidValue" values={{ fieldName: displayName }} />);
  }

  if (rules.required && rules.oib && !validateOIB(value)) {
    validation.invalid = true;
    validation.errors.push(<FormattedMessage id="errors.invalidOIBFormat" />);
  }

  return validation;
};

export const validateFormSubmission = async fieldsArray => {
  let isFormValid = true;
  await fieldsArray.forEach(setField => {
    setField(field => {
      const { invalid, errors } = validate(field);
      isFormValid = isFormValid && !invalid;
      const value = typeof field.value === 'string' ? field.value.trim() : field.value;
      return { ...field, value, invalid, error: errors[0] };
    });
  });
  return isFormValid;
};
