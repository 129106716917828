import gql from 'graphql-tag';

export const NotificationsSubscription = gql`
  subscription Notifications($userId: String!, $token: String!) {
    notifications(userId: $userId, token: $token) {
      countNew
      notifications {
        id
        subject
        content
        link
        seen
        createdAt
      }
    }
  }
`;
