import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import { Query } from 'react-apollo';
import { get } from 'lodash-es';
import { ROUTE_TO_USER_NEWS_PAGE } from '../../constants/routes';
import { GetNewsQuery } from '../../operations/queries/GetNewsQuery';
import { handleRequestError } from '../../constants/errorCodes';
import { ReactComponent as HokBackIcon } from '../../img/icons/hok-back.svg';
import { encodeStringForUrl } from '../../utils/internalParsing';

export const NewsSlider = ({ fileredPostId, titleId, showMoreLink = false }) => {
  const dispatch = useDispatch();

  return (
    <Query query={GetNewsQuery} onError={error => handleRequestError(error, dispatch)}>
      {({ loading, data }) => {
        if (get(data, 'news', []).length < 1) {
          return null;
        }

        let news = loading || !data ? [] : get(data, 'news', []);

        if (news.length > 0 && fileredPostId) {
          news = news.filter(post => post.id !== fileredPostId);
        }

        const sliderSettings = {
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: Math.min(2, news.length),
                variableWidth: true,
              },
            },
            {
              breakpoint: 1307,
              settings: {
                slidesToShow: 2,
                variableWidth: false,
              },
            },
            {
              breakpoint: 1275,
              settings: {
                slidesToShow: 1,
                variableWidth: true,
              },
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 1,
                variableWidth: true,
              },
            },
          ],
        };

        return (
          <>
            <Row>
              <Col xs="12" xl={{ size: 10, offset: 1 }}>
                <h3 className="mb-0">
                  <FormattedMessage id={titleId} />
                </h3>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="12" xl={{ size: 10, offset: 1 }}>
                <Slider {...sliderSettings}>
                  {news.map(post => (
                    <div className="hok-news-fixed-width" key={post.id}>
                      <Link to={`${ROUTE_TO_USER_NEWS_PAGE}/${post.id}/${encodeStringForUrl(post.title)}`}>
                        <Card className="hok-news-card">
                          <CardBody className="p-0 pb-2">
                            <Row>
                              <Col>
                                <div
                                  className="hok-news-card-image"
                                  style={{ backgroundImage: `url("${post.featuredImageUrl}")` }}
                                />
                              </Col>
                            </Row>
                            <Row className="no-gutters mt-2 px-2 px-sm-3">
                              <Col>
                                <h3>{post.title}</h3>
                              </Col>
                            </Row>
                            <Row className="no-gutters px-2 px-sm-3">
                              <Col className="mt-1">
                                <p className="hok-news-subtitle">
                                  {post.subtitle && post.subtitle.length > 75
                                    ? `${post.subtitle.substring(0, 75).trim()}...`
                                    : post.subtitle}
                                </p>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Link>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
            {showMoreLink && (
              <>
                <Row className="mt-3">
                  <Col xs="12" xl={{ size: 10, offset: 1 }}>
                    <Link
                      className="d-flex align-items-center float-right"
                      style={{ marginRight: '35px' }}
                      to={ROUTE_TO_USER_NEWS_PAGE}>
                      <span className="mr-2">
                        <FormattedMessage id="userNewsPreviewPage.showMore" />
                      </span>
                      <HokBackIcon style={{ transform: 'rotate(180deg)' }} />
                    </Link>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="12" xl={{ size: 10, offset: 1 }}>
                    <hr />
                  </Col>
                </Row>
              </>
            )}
          </>
        );
      }}
    </Query>
  );
};
