import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Label,
  CustomInput,
  FormFeedback,
  UncontrolledTooltip,
  Input,
} from 'reactstrap';
import { useMutation } from 'react-apollo';
import { FormattedMessage, useIntl, FormattedHTMLMessage, FormattedNumber } from 'react-intl';
import NumberFormat from 'react-number-format';
import { get, debounce } from 'lodash-es';
import { ReactComponent as HokInfoIcon } from '../../img/icons/hok-info.svg';
import { CustomDashboardInput, CustomButton } from '../../containers';
import { PolicyContractingPersonInfo } from '../../components/PolicyContractingPersonInfo';
import { validateFormSubmission } from '../../utils/validation';
import {
  useContactFormSubjects,
  useLegalDocumentsQuery,
  usePlacesQuery,
  useGetCustomFormSchema,
} from '../../utils/customHooks';
import { UserSubmitContactFormMutation } from '../../operations/mutations/UserSubmitContactFormMutation';
import { INFORMACIJE_O_OBRADI_PODATAKA } from '../../constants/documentTypes';
import { ROUTE_TO_USER_TICKETING_PAGE } from '../../constants/routes';
import { handleRequestError } from '../../constants/errorCodes';
import { renderToaster, SUCCESS_TOASTER } from '../../constants/toaster';
import {
  MOJ_DOM_BUILDING_TYPES,
  HOK_APARTMAN_BUILDING_TYPES,
  INSURED_ITEM_TYPES,
  CONSTRUCTION_METHOD_TYPES,
  POPULATION_CATEGORIZATION_TYPES,
  INSURED_ITEM_BUILDING,
  INSURED_ITEM_MOVABLE,
  INSURED_ITEM_BUILDING_AND_MOVABLE,
  BUILDING_TYPE_HOUSE,
  BUILDING_TYPE_APARTMENT,
  BUILDING_TYPE_FLAT,
} from '../../constants/propertyEnquiry';
import { ENQUIRY_SUBJECT_APARTMENT, ENQUIRY_SUBJECT_MOJ_DOM } from '../../constants/support';
import { googleAnalyticsEvent } from '../../utils/browser';
import { displayCurrencyOptions } from '../../utils/currencyDisplayFormat';
import { v4 } from 'uuid';

export const EnquiryForm = React.memo(() => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const { formatMessage, formatNumber } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const { subjects, loading } = useContactFormSubjects({ variables: { filter: { active: true } } });
  const { legalDocuments } = useLegalDocumentsQuery(INFORMACIJE_O_OBRADI_PODATAKA);

  const [submitContactForm, { loading: submitting }] = useMutation(UserSubmitContactFormMutation, {
    onError: error => handleRequestError(error, dispatch),
    onCompleted: data => {
      const subject = subjects.find(sub => sub.id === enquiryType.value);
      googleAnalyticsEvent({
        category: 'Korisnicka_Podrska_Poslan_Upit',
        action: subject ? subject.subject : enquiryType.value,
      });
      renderToaster(`successMessages.ticketSubmitted`, SUCCESS_TOASTER, { intlKey: true });
      const ticketId = get(data, 'contactForm.issueId');
      history.push(`${ROUTE_TO_USER_TICKETING_PAGE}/${ticketId}`);
    },
  });

  const { places, loading: loadingPlaces } = usePlacesQuery();

  const [enquiryType, setEnquiryType] = useState({
    value: get(location, 'state.subject', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.enquiryType" />,
    validation: {
      required: true,
    },
  });

  const [enquiryTitle, setEnquiryTitle] = useState({
    value: get(location, 'state.title', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.enquiryTitle" />,
    validation: {
      required: true,
    },
  });

  // const [HZZONumber, setHZZONumber] = useState({
  //   value: user.HZZONumber || '',
  //   error: null,
  //   invalid: false,
  //   displayName: <FormattedMessage id="inputs.HZZONumber" />,
  //   validation: {
  //     required: true,
  //     minLength: 9,
  //   },
  // });

  const [enquiry, setEnquiry] = useState({
    value: get(location, 'state.content', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.enquiry" />,
    validation: {
      required: true,
    },
  });

  const [contactMe, setContactMe] = useState({
    value: 'byApp',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.contactMe" />,
    validation: {
      required: true,
      enum: ['byPhone', 'byEmail', 'byApp'],
    },
  });

  const [buildingType, setBuildingType] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.buildingType" />,
    validation: {
      required: true,
      //enum: BUILDING_TYPES.map(type => type.id),
    },
  });

  const [buildingSurface, setBuildingSurface] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.labels.surface" />,
    validation: {
      required: true,
    },
  });

  const [insuredItem, setInsuredItem] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.labels.insuredItem" />,
    validation: {
      enum: INSURED_ITEM_TYPES.map(type => type.id),
    },
  });

  const [squareMeterPrice, setSquareMeterPrice] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedHTMLMessage id="inputs.squareMeterPrice" />,
    validation: {},
  });

  const [poolPrice, setPoolPrice] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.poolPrice" />,
    validation: {},
  });

  const [movablePrice, setMovablePrice] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedHTMLMessage id="inputs.squareMeterPrice" />,
    validation: {},
  });

  const [numberOfBeds, setNumberOfBeds] = useState({
    value: 1,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.numberOfBeds" />,
    validation: {
      minValue: 1,
    },
  });

  const [yearOfConstruction, setYearOfConstruction] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.yearOfConstruction" />,
    validation: {
      maxValue: new Date().getFullYear(),
    },
  });

  const [constructionMethod, setConstructionMethod] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.constructionMethod" />,
    validation: {
      enum: CONSTRUCTION_METHOD_TYPES.map(type => type.id),
    },
  });

  const [populationCategorization, setPopulationCategorization] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.populationCategorization" />,
    validation: {
      enum: POPULATION_CATEGORIZATION_TYPES.map(type => type.id),
    },
  });

  const [insurancePeriod, setInsurancePeriod] = useState({
    value: 1,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.insurancePeriod" />,
    validation: {
      minValue: 1,
    },
  });

  const [zip, setZip] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zip" />,
    validation: {
      enum: places.map(place => place.pttNumber),
    },
  });

  const [city, setCity] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.place" />,
    validation: {
      enum: places.map(place => place.name),
    },
  });

  const [address, setAddress] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {},
  });

  const [homeAssistance, setHomeAssistance] = useState({
    value: false,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.homeAssistance" />,
    validation: {},
  });

  const [hasPool, setHasPool] = useState({
    value: false,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.hasPool" />,
    validation: {},
  });

  const [tacAccepted, setTacAccepted] = useState(false);
  const [tacInvalid, setTacInvalid] = useState(false);

  const [filteredPlaces, setFilteredPlaces] = useState([...places]);

  useEffect(() => {
    if (places.length > 0) {
      setFilteredPlaces([...places]);
    }
  }, [places]);

  const debouncedPlacesFilter = debounce(value => {
    const matchingPlaces = places.filter(place => place.pttNumber.startsWith(value));
    setFilteredPlaces(value ? matchingPlaces : [...places]);
    if (matchingPlaces.length === 1 && matchingPlaces[0].pttNumber === value) {
      setCity(city => ({ ...city, invalid: false, value: matchingPlaces[0].name }));
    } else {
      setCity(city => ({ ...city, invalid: false, value: '' }));
    }
  }, 500);

  const formTypeProperty = [ENQUIRY_SUBJECT_APARTMENT, ENQUIRY_SUBJECT_MOJ_DOM].includes(enquiryType.value);

  const { meta } = useGetCustomFormSchema(formTypeProperty, enquiryType.value);

  const getPropertyPriceRange = type => {
    if (!buildingType.value || !insuredItem.value || !meta) return null;
    const definitionsObject = type === INSURED_ITEM_BUILDING ? get(meta, 'OBJEKTI', {}) : get(meta, 'POKRETNINE', {});
    switch (buildingType.value) {
      case BUILDING_TYPE_HOUSE:
        return definitionsObject.KUCA;
      case BUILDING_TYPE_APARTMENT:
        return definitionsObject.APARTMAN;
      case BUILDING_TYPE_FLAT:
        return definitionsObject.STAN;
      default:
        return null;
    }
  };

  const propertyPriceRange = getPropertyPriceRange(INSURED_ITEM_BUILDING);
  const movablePriceRange = getPropertyPriceRange(INSURED_ITEM_MOVABLE);
  const minPoolPrice = get(meta, 'OBJEKTI.BAZEN.min', null);

  const formatCurrencyValue = useCallback(
    value => {
      return formatNumber(value, displayCurrencyOptions);
    },
    [formatNumber]
  );

  useEffect(() => {
    if (places.length > 0 && city.validation.enum.length < 1) {
      setCity(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
    }
    if (places.length > 0 && zip.validation.enum.length < 1) {
      setZip(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
    }
  }, [places, city, zip]);

  useEffect(() => {
    if ([ENQUIRY_SUBJECT_APARTMENT, ENQUIRY_SUBJECT_MOJ_DOM].includes(enquiryType.value)) {
      setEnquiry(o => ({ ...o, validation: { ...o.validation, required: false } }));
    } else {
      setEnquiry(o => ({ ...o, validation: { ...o.validation, required: true } }));
    }
  }, [enquiryType.value, enquiry.validation.required]);

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false, error: null }));
  };

  useEffect(() => {
    let enumValues = [];
    switch (enquiryType.value) {
      case ENQUIRY_SUBJECT_MOJ_DOM:
        enumValues = MOJ_DOM_BUILDING_TYPES.map(type => type.id);
        break;
      case ENQUIRY_SUBJECT_APARTMENT:
        enumValues = HOK_APARTMAN_BUILDING_TYPES.map(type => type.id);
        break;
      default:
        break;
    }
    setBuildingType(o => ({ ...o, validation: { ...o.validation, enum: enumValues } }));
  }, [enquiryType.value]);

  useEffect(() => {
    if (meta && buildingType.value && insuredItem.value) {
      if (propertyPriceRange) {
        setSquareMeterPrice(o => ({
          ...o,
          validation: {
            ...o.validation,
            minValue: propertyPriceRange.min,
            maxValue: propertyPriceRange.max,
            minValueFormatted: formatCurrencyValue(propertyPriceRange.min),
            maxValueFormatted: formatCurrencyValue(propertyPriceRange.max),
          },
        }));
      }
      if (movablePriceRange) {
        setMovablePrice(o => ({
          ...o,
          validation: {
            ...o.validation,
            minValue: movablePriceRange.min,
            maxValue: movablePriceRange.max,
            minValueFormatted: formatCurrencyValue(movablePriceRange.min),
            maxValueFormatted: formatCurrencyValue(movablePriceRange.max),
          },
        }));
      }
      if (minPoolPrice) {
        setPoolPrice(o => ({
          ...o,
          validation: {
            ...o.validation,
            minValue: minPoolPrice,
            minValueFormatted: formatCurrencyValue(minPoolPrice),
          },
        }));
      }
    }
  }, [
    meta,
    buildingType.value,
    insuredItem.value,
    propertyPriceRange,
    movablePriceRange,
    minPoolPrice,
    formatCurrencyValue,
  ]);

  const enquirySubmitHandler = async event => {
    event.preventDefault();

    const fieldsArray = [setEnquiryType, setEnquiryTitle, setEnquiry, setContactMe];
    if (formTypeProperty) {
      fieldsArray.push(
        setBuildingType,
        setBuildingSurface,
        setInsuredItem,
        setSquareMeterPrice,
        setYearOfConstruction,
        setConstructionMethod,
        setPopulationCategorization,
        setInsurancePeriod,
        setZip,
        setCity,
        setAddress,
        setHomeAssistance
      );

      if ([INSURED_ITEM_BUILDING, INSURED_ITEM_BUILDING_AND_MOVABLE].includes(insuredItem.value) && hasPool.value) {
        fieldsArray.push(setPoolPrice);
      }

      if ([INSURED_ITEM_MOVABLE, INSURED_ITEM_BUILDING_AND_MOVABLE].includes(insuredItem.value)) {
        fieldsArray.push(setMovablePrice);
      }

      if (enquiryType.value === ENQUIRY_SUBJECT_APARTMENT) {
        fieldsArray.push(setNumberOfBeds);
      }
    }

    if (!tacAccepted) {
      setTacInvalid(true);
    }

    if ((await validateFormSubmission(fieldsArray)) && tacAccepted) {
      const propertyVariables = {
        VRSTA_OBJEKTA: buildingType.value,
        POVRSINA_U_M2: buildingSurface.value,
        STO_ZELITE_OSIGURATI: insuredItem.value,
        ...([INSURED_ITEM_BUILDING, INSURED_ITEM_BUILDING_AND_MOVABLE].includes(insuredItem.value) && {
          VRIJEDNOST_PO_M2: squareMeterPrice.value,
          ...(hasPool.value && { BAZEN: poolPrice.value }),
        }),
        ...([INSURED_ITEM_MOVABLE, INSURED_ITEM_BUILDING_AND_MOVABLE].includes(insuredItem.value) && {
          VRIJEDNOST_POKRETNINE: movablePrice.value,
        }),
        ...(enquiryType.value === ENQUIRY_SUBJECT_APARTMENT && { BROJ_LEZAJEVA: numberOfBeds.value }),
        GODINA_GRADNJE_OBJEKTA: yearOfConstruction.value,
        VRSTA_GRADNJE: constructionMethod.value,
        KATEGORIJA_NASTANJENOSTI: populationCategorization.value,
        ZELJENO_TRAJANJE_OSIGURANJA_U_GODINAMA: insurancePeriod.value,
        MJESTO_OSIGURANJA_ZIP: zip.value,
        MJESTO_OSIGURANJA_CITY: city.value,
        ADRESA: address.value,
        KUCNA_ASISTENCIJA: homeAssistance.value,
      };

      submitContactForm({
        variables: {
          type: enquiryType.value,
          subject: enquiryTitle.value,
          content: enquiry.value,
          email: user.email,
          phoneNumber: user.phoneNumber,
          additionalData: {
            contactMethod: contactMe.value,
            ...(formTypeProperty && propertyVariables),
          },
        },
      });
    }
  };

  return (
    <Form onSubmit={enquirySubmitHandler}>
      <FormGroup row>
        <Col sm="8" md="7">
          <Label for="enquiryType">
            <FormattedMessage id="inputs.enquiryType" />
          </Label>
          <InputGroup>
            <div className="hok-select">
              <CustomDashboardInput
                id="enquiryType"
                type="select"
                style={!enquiryType.value ? { color: 'rgba(92, 104, 115, 0.55)' } : null}
                fieldState={enquiryType}
                onChange={event => {
                  inputHandler(setEnquiryType, event.target.value);
                  if (get(location, 'state.subject', '')) {
                    history.push({
                      ...location.pathname,
                      state: {},
                    });
                  }
                }}
              >
                <option disabled value="">
                  {formatMessage({ id: loading ? 'loading' : 'inputs.enquiryType' }).toUpperCase()}
                </option>
                {loading && (
                  <option disabled value="">
                    {formatMessage({ id: 'loading' })}
                  </option>
                )}
                {subjects.map(subject => (
                  <option key={subject.id} value={subject.id}>
                    {subject.subject}
                  </option>
                ))}
              </CustomDashboardInput>
            </div>
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm="8" md="7">
          <Label for="enquiryTitle">
            <FormattedMessage id="inputs.enquiryTitle" />
          </Label>
          <InputGroup>
            <CustomDashboardInput
              id="enquiryTitle"
              placeholder
              placeholderId="input"
              fieldState={enquiryTitle}
              onChange={event => inputHandler(setEnquiryTitle, event.target.value)}
            />
          </InputGroup>
        </Col>
      </FormGroup>
      <PolicyContractingPersonInfo user={user} labelId="productEnquiry.applicant" label />
      {/* <Col sm="6" md="4">
          <Label for="HZZONumber">
            <FormattedMessage id="inputs.HZZONumber" />
          </Label>
          <InputGroup>
            <CustomDashboardInput
              id="HZZONumber"
              fieldState={HZZONumber}
              onChange={event => inputHandler(setHZZONumber, event.target.value)}
            />
          </InputGroup>
        </Col>
      </PolicyContractingPersonInfo> */}

      {formTypeProperty && (
        <>
          <FormGroup row className="mb-0">
            <Col sm="6" md="4">
              <Label for="buildingType" className="label-flex">
                <div>
                  <FormattedMessage id="inputs.buildingType" />{' '}
                  <span className="hok-font-small">
                    (<FormattedMessage id="required" />)
                  </span>
                </div>
              </Label>
              <InputGroup>
                <div className="hok-select">
                  <CustomDashboardInput
                    id="buildingType"
                    type="select"
                    style={!buildingType.value ? { color: 'rgba(92, 104, 115, 0.55)' } : null}
                    fieldState={buildingType}
                    onChange={event => inputHandler(setBuildingType, event.target.value)}
                  >
                    <option disabled value="">
                      {formatMessage({ id: 'inputs.labels.selectType' })}
                    </option>
                    {(enquiryType.value === ENQUIRY_SUBJECT_MOJ_DOM
                      ? MOJ_DOM_BUILDING_TYPES
                      : HOK_APARTMAN_BUILDING_TYPES
                    ).map(type => (
                      <option value={type.id} key={type.id}>
                        {type.id}
                      </option>
                    ))}
                  </CustomDashboardInput>
                </div>
              </InputGroup>
            </Col>
            <Col sm="6" md="4">
              <Label for="buildingSurface" className="label-flex">
                <div>
                  <FormattedHTMLMessage id="inputs.buildingSurface" />{' '}
                  <span className="hok-font-small">
                    (<FormattedMessage id="required" />)
                  </span>
                </div>
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  id="buildingSurface"
                  placeholder
                  placeholderId="labels.buildingSurface"
                  fieldState={buildingSurface}
                  onChange={event => inputHandler(setBuildingSurface, event.target.value)}
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col sm="6" md="4">
              <Label for="insuredItem" className="label-flex">
                <FormattedMessage id="inputs.insuredItem" />
              </Label>
              <InputGroup>
                <div className="hok-select">
                  <CustomDashboardInput
                    id="insuredItem"
                    type="select"
                    style={!insuredItem.value ? { color: 'rgba(92, 104, 115, 0.55)' } : null}
                    fieldState={insuredItem}
                    onChange={event => inputHandler(setInsuredItem, event.target.value)}
                  >
                    <option disabled value="">
                      {formatMessage({ id: 'inputs.select' })}
                    </option>
                    {INSURED_ITEM_TYPES.map(type => (
                      <option value={type.id} key={type.id}>
                        {type.id}
                      </option>
                    ))}
                  </CustomDashboardInput>
                </div>
              </InputGroup>
            </Col>
          </FormGroup>
          {buildingType.value &&
            (insuredItem.value === INSURED_ITEM_BUILDING ||
              insuredItem.value === INSURED_ITEM_BUILDING_AND_MOVABLE) && (
              <FormGroup row className="mt-3 mb-0">
                <Col xs="12">
                  <h5 className="font-weight-bold">
                    <FormattedMessage id="inputs.building" />:
                  </h5>
                </Col>
                <Col sm="6" md="4">
                  <Label for="squareMeterPrice" className="label-flex">
                    <div>
                      <FormattedHTMLMessage id="inputs.squareMeterPrice" />{' '}
                      {propertyPriceRange && (
                        <span className="hok-font-small d-inline-block">
                          (
                          <FormattedNumber value={propertyPriceRange.min} {...displayCurrencyOptions} /> -{' '}
                          <FormattedNumber value={propertyPriceRange.max} {...displayCurrencyOptions} />)
                        </span>
                      )}
                    </div>
                  </Label>
                  <InputGroup>
                    <CustomDashboardInput
                      id="squareMeterPrice"
                      placeholder
                      placeholderId="labels.inputValue"
                      customComponent={NumberFormat}
                      customInput={Input}
                      decimalScale={2}
                      fixedDecimalScale
                      decimalSeparator=","
                      thousandSeparator="."
                      allowNegative={false}
                      suffix=" kn"
                      autoComplete="off"
                      isNumericString
                      fieldState={squareMeterPrice}
                      onValueChange={values => inputHandler(setSquareMeterPrice, values.value)}
                    />
                  </InputGroup>
                </Col>
                <Col sm="6" md="4" className="d-flex">
                  <InputGroup className="d-flex align-items-end">
                    <CustomInput
                      className="hok-tac-checkbox"
                      id="hasPool"
                      type="checkbox"
                      checked={hasPool.value}
                      invalid={hasPool.invalid}
                      label={
                        <span className="font-weight-500 hok-text-dark">
                          <FormattedMessage id="inputs.hasPool" />
                        </span>
                      }
                      onChange={event => {
                        inputHandler(setHasPool, event.target.checked);
                      }}
                    />
                  </InputGroup>
                </Col>
                {hasPool.value && (
                  <Col sm="6" md="4">
                    <Label for="poolPrice" className="label-flex">
                      <div>
                        <FormattedHTMLMessage id="inputs.poolPrice" />{' '}
                        {minPoolPrice && (
                          <span className="hok-font-small d-inline-block">
                            (min. <FormattedNumber value={minPoolPrice} {...displayCurrencyOptions} />)
                          </span>
                        )}
                      </div>
                    </Label>
                    <InputGroup>
                      <CustomDashboardInput
                        id="poolPrice"
                        placeholder
                        placeholderId="labels.inputValue"
                        customComponent={NumberFormat}
                        customInput={Input}
                        decimalScale={2}
                        fixedDecimalScale
                        decimalSeparator=","
                        thousandSeparator="."
                        allowNegative={false}
                        suffix=" kn"
                        autoComplete="off"
                        isNumericString
                        fieldState={poolPrice}
                        onValueChange={values => inputHandler(setPoolPrice, values.value)}
                      />
                    </InputGroup>
                  </Col>
                )}
              </FormGroup>
            )}
          {buildingType.value &&
            (insuredItem.value === INSURED_ITEM_MOVABLE || insuredItem.value === INSURED_ITEM_BUILDING_AND_MOVABLE) && (
              <FormGroup row className="mb-0 mt-3">
                <Col xs="12">
                  <h5 className="font-weight-bold">
                    <FormattedMessage id="inputs.movable" />:
                  </h5>
                </Col>
                <Col sm="6" md="4">
                  <Label for="squareMeterPrice" className="label-flex">
                    <div>
                      <FormattedHTMLMessage id="inputs.squareMeterPrice" />{' '}
                      {movablePriceRange && (
                        <span className="hok-font-small d-inline-block">
                          (
                          <FormattedNumber value={movablePriceRange.min} {...displayCurrencyOptions} /> -{' '}
                          <FormattedNumber value={movablePriceRange.max} {...displayCurrencyOptions} />)
                        </span>
                      )}
                    </div>
                  </Label>
                  <InputGroup>
                    <CustomDashboardInput
                      id="movablePrice"
                      placeholder
                      placeholderId="labels.inputValue"
                      customComponent={NumberFormat}
                      customInput={Input}
                      decimalScale={2}
                      fixedDecimalScale
                      decimalSeparator=","
                      thousandSeparator="."
                      allowNegative={false}
                      suffix=" kn"
                      autoComplete="off"
                      isNumericString
                      fieldState={movablePrice}
                      onValueChange={values => inputHandler(setMovablePrice, values.value)}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
            )}
          <FormGroup row className="mb-0">
            {enquiryType.value === ENQUIRY_SUBJECT_APARTMENT && (
              <Col sm="6" md="4">
                <Label for="numberOfBeds" className="label-flex">
                  <FormattedMessage id="inputs.numberOfBeds" /> (min. 1)
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="numberOfBeds"
                    customComponent={NumberFormat}
                    customInput={Input}
                    allowNegative={false}
                    decimalScale={0}
                    fieldState={numberOfBeds}
                    onValueChange={values => inputHandler(setNumberOfBeds, values.value)}
                  />
                </InputGroup>
              </Col>
            )}
            <Col sm="6" md="4">
              <Label for="yearOfConstruction" className="label-flex">
                <FormattedMessage id="inputs.yearOfConstruction" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  id="yearOfConstruction"
                  placeholder
                  placeholderId="labels.yearOfConstruction"
                  customComponent={NumberFormat}
                  customInput={Input}
                  allowNegative={false}
                  decimalScale={0}
                  isAllowed={values => values.value <= new Date().getFullYear()}
                  fieldState={yearOfConstruction}
                  onValueChange={values => inputHandler(setYearOfConstruction, values.value)}
                />
              </InputGroup>
            </Col>
            <Col sm="6" md="4">
              <Label for="constructionMethod" className="label-flex">
                <FormattedMessage id="inputs.constructionMethod" />
              </Label>
              <InputGroup>
                <div className="hok-select">
                  <CustomDashboardInput
                    id="constructionMethod"
                    type="select"
                    style={!constructionMethod.value ? { color: 'rgba(92, 104, 115, 0.55)' } : null}
                    fieldState={constructionMethod}
                    onChange={event => inputHandler(setConstructionMethod, event.target.value)}
                  >
                    <option disabled value="">
                      {formatMessage({ id: 'inputs.labels.selectConstructionMethod' })}
                    </option>
                    {CONSTRUCTION_METHOD_TYPES.map(type => (
                      <option value={type.id} key={type.id}>
                        {type.id}
                      </option>
                    ))}
                  </CustomDashboardInput>
                </div>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col sm="6" md="4">
              <Label for="populationCategorization" className="label-flex">
                <div className="d-flex align-items-center justify-content-start">
                  <span>
                    <FormattedMessage id="inputs.populationCategorization" />
                  </span>
                  <div className="ml-3">
                    <HokInfoIcon id="infoIcon" className="svg-red svg-pad-bottom-half cursor-pointer" />
                  </div>
                </div>
              </Label>
              <UncontrolledTooltip
                innerClassName="hok-input-tooltip"
                arrowClassName="hok-input-tooltip-arrow"
                placement="top"
                target="infoIcon"
                autohide={false}
              >
                <p>
                  <span className="font-weight-bold">Stalno naseljen</span> - nastanjen više od 270 dana u godini.
                </p>
                <p className="mb-0">
                  <span className="font-weight-bold">Nenastanjen</span> - objekt je nenastanjen više od 90 dana u
                  godini.
                </p>
              </UncontrolledTooltip>
              <InputGroup>
                <div className="hok-select">
                  <CustomDashboardInput
                    id="populationCategorization"
                    type="select"
                    style={!populationCategorization.value ? { color: 'rgba(92, 104, 115, 0.55)' } : null}
                    fieldState={populationCategorization}
                    onChange={event => inputHandler(setPopulationCategorization, event.target.value)}
                  >
                    <option disabled value="">
                      {formatMessage({ id: 'inputs.select' })}
                    </option>
                    {POPULATION_CATEGORIZATION_TYPES.map(type => (
                      <option value={type.id} key={type.id}>
                        {type.id}
                      </option>
                    ))}
                  </CustomDashboardInput>
                </div>
              </InputGroup>
            </Col>
            <Col sm="6" md="4">
              <Label for="insurancePeriod" className="label-flex">
                <div>
                  <FormattedMessage id="inputs.insurancePeriod" /> (<FormattedMessage id="years" />)
                </div>
              </Label>
              <InputGroup>
                <div className="hok-select">
                  <CustomDashboardInput
                    id="insurancePeriod"
                    customComponent={NumberFormat}
                    customInput={Input}
                    allowNegative={false}
                    decimalScale={0}
                    onValueChange={values => inputHandler(setInsurancePeriod, values.value)}
                    fieldState={insurancePeriod}
                  />
                </div>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm="6" md="4">
              <Label for="zipCode" className="label-flex">
                <FormattedMessage id="inputs.insuredZip" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  id="zipCode"
                  placeholder
                  placeholderId="input"
                  fieldState={zip}
                  value={zip.value}
                  onChange={event => {
                    event.persist();
                    debouncedPlacesFilter(event.target.value);
                    setZip(zip => ({ ...zip, invalid: false, value: event.target.value }));
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm="6" md="4">
              <Label for="city" className="label-flex">
                <FormattedMessage id="inputs.insuredPlace" />
              </Label>
              <InputGroup>
                <div className="hok-select">
                  <CustomDashboardInput
                    id="city"
                    type="select"
                    fieldState={city}
                    value={city.value}
                    style={!city.value ? { color: 'rgba(92, 104, 115, 0.55)' } : null}
                    onChange={event => {
                      event.persist();
                      const place = places.find(place => place.name === event.target.value);
                      setCity(o => ({ ...o, invalid: false, value: event.target.value }));
                      place && setZip(o => ({ ...o, invalid: false, value: place.pttNumber }));
                    }}
                  >
                    <option disabled value="">
                      {formatMessage({
                        id: 'inputs.labels.selectPlace',
                      })}
                    </option>
                    {loadingPlaces ? (
                      <option disabled value={city.value}>
                        {city.value}
                      </option>
                    ) : (
                      filteredPlaces.map(place => (
                        <option key={v4()} value={place.name}>
                          {place.name}
                        </option>
                      ))
                    )}
                  </CustomDashboardInput>
                </div>
              </InputGroup>
            </Col>
            <Col sm="6" md="4">
              <Label for="address" className="label-flex">
                <FormattedMessage id="inputs.insuredAddress" />
              </Label>
              <InputGroup>
                <CustomDashboardInput
                  id="address"
                  fieldState={address}
                  onChange={event => inputHandler(setAddress, event.target.value)}
                  placeholder
                  placeholderId="labels.address"
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row className="my-4">
            <Col className="d-flex flex-row" xs="12">
              <CustomInput
                className="hok-tac-checkbox"
                id="homeAssistance"
                type="checkbox"
                checked={homeAssistance.value}
                invalid={homeAssistance.invalid}
                label={
                  <span className="font-weight-500 hok-text-dark">
                    <FormattedMessage id="inputs.homeAssistance" />
                  </span>
                }
                onChange={event => {
                  inputHandler(setHomeAssistance, event.target.checked);
                }}
              />
            </Col>
          </FormGroup>
        </>
      )}

      <FormGroup row>
        <Col md="7">
          <Label for="enquiry">
            <FormattedMessage id={formTypeProperty ? 'inputs.remark' : 'inputs.enquiry'} />
          </Label>
          <InputGroup>
            <CustomDashboardInput
              id="enquiry"
              className="hok-enquiry-textarea"
              type="textarea"
              placeholder
              placeholderId="requestDescription"
              fieldState={enquiry}
              onChange={event => inputHandler(setEnquiry, event.target.value)}
            />
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label className="d-block d-sm-inline-block">
            <FormattedMessage id="inputs.contactMe" />:
          </Label>
          <CustomInput
            inline
            type="radio"
            id="byApp"
            checked={contactMe.value === 'byApp'}
            name="contactMe"
            label={formatMessage({ id: 'buttons.byApp' })}
            onChange={() => inputHandler(setContactMe, 'byApp')}
          />
          <CustomInput
            inline
            type="radio"
            id="byPhone"
            checked={contactMe.value === 'byPhone'}
            name="contactMe"
            label={formatMessage({ id: 'buttons.byPhone' })}
            onChange={() => inputHandler(setContactMe, 'byPhone')}
          />
          <CustomInput
            inline
            type="radio"
            id="byEmail"
            checked={contactMe.value === 'byEmail'}
            name="contactMe"
            label={formatMessage({ id: 'buttons.byEmail' })}
            onChange={() => inputHandler(setContactMe, 'byEmail')}
          />
          {contactMe.invalid && (
            <FormFeedback className={contactMe.invalid ? 'd-block' : ''}>{contactMe.error}</FormFeedback>
          )}
        </Col>
      </FormGroup>
      <FormGroup row className="mt-4">
        <Col className="d-flex flex-row" xs="12">
          <CustomInput
            className="hok-tac-checkbox"
            id="tac"
            type="checkbox"
            checked={tacAccepted}
            invalid={tacInvalid}
            onChange={event => {
              setTacAccepted(event.target.checked);
              setTacInvalid(false);
            }}
          />
          <div className="tac-text-container">
            <span>
              Prihvaćam pohranu i rukovanje iznad navedenim podacima sukladno
              <a
                target="_blank"
                href={legalDocuments[0] ? legalDocuments[0].link : '#'}
                rel="noopener noreferrer"
                className="terms-and-conditions-text"
              >
                informacijama o obradi podataka.
              </a>
            </span>
          </div>
        </Col>
        <FormFeedback className={'d-block pl-3' + (tacInvalid ? ' visible' : ' invisible')}>
          <FormattedMessage id="errors.invalidIOOP" />
        </FormFeedback>
      </FormGroup>
      <Row className="no-gutters mt-5">
        <Col>
          <CustomButton
            className="hok-dashboard-btn-wide float-sm-right"
            translationId="submit"
            loaderProp={submitting}
          />
        </Col>
      </Row>
    </Form>
  );
});
