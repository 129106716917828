import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { LoginAndRegisterSelector } from '../../../containers';
import logoShort from '../../../img/logo-short.png';
import { SupportInfo } from '../../../components/SupportInfo';
import { ReactComponent as HokCloseIcon } from '../../../img/icons/hok-close.svg';

export const SupportInfoPage = () => {
  const history = useHistory();

  return (
    <div className="hok-client">
      <LoginAndRegisterSelector
        negativeBotMargin
        hideHeaderBorder={false}
        customHeader={
          <Col xs={12}>
            <h2 className="d-flex align-items-center justify-content-center position-relative">
              <FormattedMessage id="routes.customerSupport" />
            </h2>
            <HokCloseIcon className="hok-back-icon" onClick={() => history.goBack()} />
          </Col>
        }>
        <Row className="no-gutters px-4 my-5 my-md-4">
          <Col xs={12} sm={{ offset: 2, size: 8 }} md={{ offset: 1, size: 10 }} lg={{ offset: 2, size: 8 }}>
            <Row className="mb-4 mb-md-0 mb-lg-4">
              <Col className="text-center">
                <img className="img-fluid logo-short" src={logoShort} alt="HOK logo" />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <p>Za kontaktiranje korisničke podrške pošaljite upit na e-mail adresu:</p>
              </Col>
            </Row>
            <SupportInfo className="mt-4 mb-5" showPhoneNumber={false} />
          </Col>
        </Row>
      </LoginAndRegisterSelector>
    </div>
  );
};
