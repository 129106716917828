import gql from 'graphql-tag';

export const AdminTicketStatusMutation = gql`
  mutation AdminTicketUpdated($id: Int!, $status: ticketStatusTypeEnum!, $silent: Boolean) {
    updateTicket(id: $id, payload: { status: $status }, silent: $silent) {
      id
      number
      type
      status
      actionStatus
      email
      phoneNumber
      userId
      firstName
      lastName
      businessName
      subject
      content
      attachments
      startedAt
      closedAt
      createdAt
      updatedAt
      dueDate
      note
    }
  }
`;

export const AdminTicketDateNoteMutation = gql`
  mutation AdminTicketUpdated($id: Int!, $dueDate: Date, $note: String) {
    updateTicket(id: $id, payload: { dueDate: $dueDate, note: $note }, silent: true) {
      id
      number
      type
      status
      actionStatus
      email
      phoneNumber
      userId
      firstName
      lastName
      businessName
      subject
      content
      attachments
      startedAt
      closedAt
      createdAt
      updatedAt
      dueDate
      note
    }
  }
`;
