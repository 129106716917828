import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { userLogout } from '../../../redux/actions/userAuthActions';
import { ContentContainer, Spinner } from '../../../containers';

export const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(userLogout());
    }, 1618 /* GOLDEN RATIO */);
  }, [dispatch]);

  return (
    <ContentContainer titleTranslationId="logoutPage.title" showFooter={false}>
      <p className="text-muted text-center">
        <FormattedMessage id="logoutPage.message" />
      </p>
      <Spinner className="my-5 d-flex justify-content-center" />
    </ContentContainer>
  );
};
