import React from 'react';
import { EndStep } from '../../../components/PolicyContractingSteps/EndStep';
import { MY_POLICIES_CAR_LIABILITY_PAGE } from '../../../constants/business';

export const Step4AO = () => {
  return (
    <EndStep
      policyTranslationBase="userMyPoliciesCarLiability"
      policyPage={MY_POLICIES_CAR_LIABILITY_PAGE}
      messageId="congratsMessage"
    />
  );
};
