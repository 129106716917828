import React from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { DocumentLink } from '../DocumentLink';
import { useProductDocumentsQuery } from '../../utils/customHooks';
import { DOC_TYPE_PRE_CONTRACT } from '../../constants/business';

export const PreContractualDocumentation = React.memo(({ productCode }) => {
  const { productDocuments } = useProductDocumentsQuery({ docType: DOC_TYPE_PRE_CONTRACT, productCode });

  return (
    <>
      {productDocuments.length > 0 && (
        <Row className="no-gutters mx-3 mt-4 mb-3">
          <Col>
            <h5>
              <FormattedMessage id="userMyPolicies.preContractualDocumentation" />:
            </h5>
          </Col>
        </Row>
      )}
      <Row className="mx-3">
        {productDocuments.map(document => (
          <Col className="d-flex align-items-center mb-3" sm="6" key={document.documentId}>
            <DocumentLink content={document.content} link={document.link} />
          </Col>
        ))}
      </Row>
    </>
  );
});
