import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Query } from 'react-apollo';
import { get } from 'lodash-es';
import { GetNewsQuery } from '../../../operations/queries/GetNewsQuery';
import { formatDate } from '../../../constants/dateFormatting';
import { ROUTE_TO_USER_NEWS_PAGE } from '../../../constants/routes';
import { ScreenTitleContainer } from '../../../containers';
import { encodeStringForUrl } from '../../../utils/internalParsing';

export const UserNewsListPage = () => {
  return (
    <Container className="hok-container" fluid>
      <Row>
        <Col xs="12">
          <Query query={GetNewsQuery}>
            {({ data, loading }) => {
              const news = get(data, 'news', []);

              if (loading || !data) {
                return null;
              }

              if (news.length < 1) {
                return (
                  <Container className="hok-container" fluid>
                    <Row className="no-gutters mb-5">
                      <Col>
                        <ScreenTitleContainer
                          titleId="userNewsListPage.title"
                          childClassName="hok-inline-title title-text"
                          maxWidthSet
                        />
                      </Col>
                    </Row>
                    <Row className="no-gutters mt-5">
                      <Col>
                        <p className="hok-text-dark">
                          <FormattedMessage id="userNewsListPage.noNewsFound" />
                        </p>
                      </Col>
                    </Row>
                  </Container>
                );
              }

              return (
                <div className="mx-auto text-center text-md-left" style={{ width: 'fit-content' }}>
                  <Row className="mb-5">
                    <Col className="text-left" xs={12}>
                      <h3 className="mb-0 pl-3">
                        <FormattedMessage id="userNewsListPage.title" />
                      </h3>
                    </Col>
                  </Row>
                  {news.map(post => (
                    <div className="hok-news-container px-3" key={post.id}>
                      <div className="hok-news-fixed-width">
                        <Link to={`${ROUTE_TO_USER_NEWS_PAGE}/${post.id}/${encodeStringForUrl(post.title)}`}>
                          <Card className="hok-news-card">
                            <CardBody className="p-0 pb-2">
                              <Row>
                                <Col>
                                  <div
                                    className="hok-news-card-image"
                                    style={{ backgroundImage: `url("${post.featuredImageUrl}")` }}
                                  />
                                </Col>
                              </Row>
                              <Row className="no-gutters mt-2 px-2 px-sm-3">
                                <Col>
                                  <h3>{post.title}</h3>
                                </Col>
                              </Row>
                              <Row className="no-gutters px-2 px-sm-3">
                                <Col className="mt-1">
                                  <p className="hok-news-subtitle">
                                    {post.subtitle && post.subtitle.length > 75
                                      ? `${post.subtitle.substring(0, 75).trim()}...`
                                      : post.subtitle}
                                  </p>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Link>
                      </div>
                      <div className="hok-news-fixed-width hok-published-at text-left">
                        <FormattedMessage id="userNewsListPage.publishedAt" />: {formatDate(post.publishedAt)}
                      </div>
                    </div>
                  ))}
                </div>
              );
            }}
          </Query>
        </Col>
      </Row>
    </Container>
  );
};
