import React, { useState } from 'react';
import { Row, Col, Input, Label, InputGroup } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import { debounce } from 'lodash-es';
import { ScreenTitleContainer } from '../../../containers';
import { OFFICE_TYPES, OFFICES, MAIN_OFFICES, OFFICE_CLAIMS } from '../../../constants/business';

export const OfficeSearch = ({ allOffices, children }) => {
  const { formatMessage } = useIntl();

  const cities = [...new Set(allOffices.map(office => office.city))].sort((a, b) => a.localeCompare(b, 'hr'));

  const [search, setSearch] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [officeTypeFilter, setOfficeTypeFilter] = useState(null);

  const debouncedSearchFilter = debounce(variables => setSearch(variables), 750);

  let offices = allOffices.filter(office => office.categoryId === OFFICES.id);
  let mainOffices = allOffices.filter(office => office.categoryId === MAIN_OFFICES.id);
  let damageClaimOffices = allOffices.filter(office => office.categoryId === OFFICE_CLAIMS.id);

  if (cityFilter) {
    offices = offices.filter(office => office.city === cityFilter);
    mainOffices = mainOffices.filter(mainOffice => mainOffice.city === cityFilter);
    damageClaimOffices = damageClaimOffices.filter(damageClaimOffice => damageClaimOffice.city === cityFilter);
  }

  if (search) {
    const searchValueLowerCase = search.toLowerCase();
    offices = offices.filter(
      office =>
        office.name.toLowerCase().indexOf(searchValueLowerCase) > -1 ||
        office.address.toLowerCase().indexOf(searchValueLowerCase) > -1
    );
    mainOffices = mainOffices.filter(
      mainOffice =>
        mainOffice.name.toLowerCase().indexOf(searchValueLowerCase) > -1 ||
        mainOffice.address.toLowerCase().indexOf(searchValueLowerCase) > -1
    );
    damageClaimOffices = damageClaimOffices.filter(
      damageClaimOffice =>
        damageClaimOffice.name.toLowerCase().indexOf(searchValueLowerCase) > -1 ||
        damageClaimOffice.address.toLowerCase().indexOf(searchValueLowerCase) > -1
    );
  }

  return (
    <>
      <ScreenTitleContainer row titleId="generalInfoPage.officeList" />
      <Row className="mb-4">
        <Col sm="6" xl="4">
          <Input
            className="hok-search-input"
            placeholder={formatMessage({ id: 'inputs.search' })}
            onChange={event => debouncedSearchFilter(event.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6" xl="4">
          <Label for="city">
            <FormattedMessage id="inputs.city" />
          </Label>
          <InputGroup>
            <Input type="select" id="city" onChange={event => setCityFilter(event.target.value)}>
              <option value="">{formatMessage({ id: 'officeFilters.allCities' }).toUpperCase()}</option>
              {cities.map(city => (
                <option key={city} value={city}>
                  {city.toUpperCase()}
                </option>
              ))}
            </Input>
          </InputGroup>
        </Col>
        <Col sm="6" xl="4">
          <Label for="officeType">
            <FormattedMessage id="inputs.officeType" />
          </Label>
          <InputGroup>
            <Input type="select" id="officeType" onChange={event => setOfficeTypeFilter(event.target.value)}>
              <option value="">{formatMessage({ id: 'officeFilters.allOffices' }).toUpperCase()}</option>
              {OFFICE_TYPES.map(type => (
                <option key={type.name} value={type.id}>
                  {formatMessage({ id: `officeFilters.${type.name}` }).toUpperCase()}
                </option>
              ))}
            </Input>
          </InputGroup>
        </Col>
      </Row>
      {children({ offices, mainOffices, damageClaimOffices, officeTypeFilter })}
    </>
  );
};
