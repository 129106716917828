import gql from 'graphql-tag';

export const GetTicketMessagesQuery = gql`
  query GetTicketMessages($ticketNumber: String, $order: getTicketMessagesSortFilterInput, $take: Int, $skip: Int) {
    getTicketMessages(filter: { ticketNumber: $ticketNumber }, order: $order, take: $take, skip: $skip) {
      totalCount
      queryCount
      ticketMessages {
        id
        userId
        agentNickname
        additionalValues
        content
        attachments
        createdAt
      }
    }
  }
`;

export const GetTicketFilesQuery = gql`
  query GetTicketMessages($ticketNumber: String) {
    getTicketFiles(filter: { ticketNumber: $ticketNumber }) {
      totalCount
      queryCount
      ticketFiles {
        id
        key
        name
        fileUrl
        createdAt
        updatedAt
      }
    }
  }
`;
