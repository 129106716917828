import React from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash-es';
import { ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE } from '../../../constants/routes';
import { SingleDamageClaimData } from '../../../components/SingleDamageClaimData';

export const SingleDamageClaim = () => {
  const params = useParams();
  const damageClaimParam = get(params, 'claimNumber', null);

  const [damageClaimClassOrCode, damageClaimId] = damageClaimParam.split(/-(.+)/);

  return (
    <SingleDamageClaimData
      baseRoute={ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE}
      damageClaimId={damageClaimId === 'null' ? null : damageClaimId}
      damageClaimClassOrCode={damageClaimClassOrCode === 'null' ? null : damageClaimClassOrCode}
    />
  );
};
