import gql from 'graphql-tag';

export const UserVerifyProfileMutation = gql`
  mutation UserVerifyProfile(
    $verifyProcedure: verifyProcedureEnum
    $ONE_OF_LAST_5_INVOICES: String
    $NUMBER_OF_VALID_INSURANCE_POLICY: String
    $DATE_OF_EXPIRY: String
  ) {
    verifyProfile(
      verifyProcedure: $verifyProcedure
      ONE_OF_LAST_5_INVOICES: $ONE_OF_LAST_5_INVOICES
      NUMBER_OF_VALID_INSURANCE_POLICY: $NUMBER_OF_VALID_INSURANCE_POLICY
      DATE_OF_EXPIRY: $DATE_OF_EXPIRY
    ) {
      id
      verified
    }
  }
`;
