import gql from 'graphql-tag';

export const GetPzoPriceQuery = gql`
  query GetPzoPrice(
    $travelType: HokPzoTravelTypeEnum!
    $productType: HokPzoProductTypeEnum!
    $productPackage: HokPzoProductPackageEnum!
    $additionalProductPackage: HokPzoAdditionalProductPackageEnum!
    $insuranceStartDate: Date!
    $insuranceEndDate: Date!
    $insuredPeople: [HokPzoProductInsuredPeopleDataInput!]!
  ) {
    calculatePriceHokPzo(
      productRelatedData: {
        travelType: $travelType
        productType: $productType
        productPackage: $productPackage
        additionalProductPackage: $additionalProductPackage
        insuranceStartDate: $insuranceStartDate
        insuranceEndDate: $insuranceEndDate
        insuredPeople: $insuredPeople
      }
    ) {
      totalPremium
      eligibleForProduct
    }
  }
`;
