import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Row, Col, FormGroup, InputGroup, Input, Label, Card, CardHeader, CardBody } from 'reactstrap';
import { get } from 'lodash-es';
import { ReactComponent as HokCloseIcon } from '../../../img/icons/hok-close.svg';
import { CollapsiblePackageInfo } from '../../../containers';
import { FormattedMessage } from 'react-intl';
import { formatDate } from '../../../constants/dateFormatting';
import { assistanceGroupDefs, POLICY_CODE_PZ, POLICY_HEALTH_TRAVEL } from '../../../constants/business';
import { ROUTE_TO_USER_ASSISTANCE_PAGE } from '../../../constants/routes';
import { USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { PolicyInsuredPeople } from '../../../components/PolicyForm/PolicyInsuredPeople';

export const AssistanceCards = ({ assistance, assistanceGroup, assistancePolicyId = null }) => {
  const history = useHistory();

  const isCardOpen = assistance.find(policy => policy.number === assistancePolicyId);
  const idx = assistance.findIndex(policy => policy.number === assistancePolicyId);

  if (assistancePolicyId && !assistance.find(policy => policy.number === assistancePolicyId)) {
    return <Redirect to={`${ROUTE_TO_USER_ASSISTANCE_PAGE}/${assistanceGroupDefs[assistanceGroup].route}`} />;
  }

  return isCardOpen ? (
    <Col>
      <Card className="hok-assistance-card">
        <CardHeader>
          <Row className="no-gutters">
            <Col className="pl-0" xs="12">
              <div className="d-flex flex-column card-text">
                <h4 className={'d-flex align-items-center main-title-red'}>
                  {get(assistance[idx], 'insuredItems[0].vehicleRegistrationPlate', assistance[idx].number) ||
                    assistance[idx].number}
                </h4>
                <HokCloseIcon
                  className="keyboard-icon align-self-end"
                  height="24"
                  width="24"
                  onClick={() =>
                    history.push(`${ROUTE_TO_USER_ASSISTANCE_PAGE}/${assistanceGroupDefs[assistanceGroup].route}`)
                  }
                />
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="no-gutters mb-2">
            <Col>
              <h3>
                <FormattedMessage id="userAssistancePage.policyValidity" />:
              </h3>
            </Col>
          </Row>
          <FormGroup row>
            <Col sm="6" md="4">
              <Label>
                <FormattedMessage id="inputs.validFrom" />
              </Label>
              <InputGroup>
                <Input id="validFrom" readOnly value={formatDate(assistance[idx].insuranceStart)} />
              </InputGroup>
            </Col>
            <Col sm="6" md="4">
              <Label>
                <FormattedMessage id="inputs.validTo" />
              </Label>
              <InputGroup>
                <Input id="validTo" readOnly value={formatDate(assistance[idx].insuranceEnd)} />
              </InputGroup>
            </Col>
          </FormGroup>
          <Row className="no-gutters mb-2">
            <Col>
              <h3>
                <FormattedMessage id="userAssistancePage.policyData" />:
              </h3>
            </Col>
          </Row>
          <FormGroup row>
            <Col sm="6" md="4">
              <Label>
                <FormattedMessage id="inputs.policyNumber" />
              </Label>
              <InputGroup>
                <Input id="policyNumber" readOnly value={formatDate(assistance[idx].number)} />
              </InputGroup>
            </Col>
          </FormGroup>
          {assistance[idx].insuredItems.length > 0 && (
            <>
              <Row className="no-gutters mb-2">
                <Col xs={12}>
                  <h3>
                    <FormattedMessage id="userMyPolicies.policyData.subjectOfInsurance" />:
                  </h3>
                </Col>
              </Row>
              <FormGroup row>
                {assistance[idx].insuredItems.map((item, index) => {
                  if (item.insuredLocation) {
                    return (
                      <Col xs={12} sm={6} key={index}>
                        <Label>
                          <FormattedMessage id="inputs.address" />:
                        </Label>
                        <InputGroup>
                          <Input readOnly defaultValue={item.insuredLocation}></Input>
                        </InputGroup>
                      </Col>
                    );
                  } else {
                    return (
                      <React.Fragment key={index}>
                        <Col xs={12} sm={6} xl={4}>
                          <Label>
                            <FormattedMessage id="inputs.vehicleMake" />:
                          </Label>
                          <InputGroup>
                            <Input readOnly defaultValue={item.vehicleMake}></Input>
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                          <Label>
                            <FormattedMessage id="inputs.vehicleModel" />:
                          </Label>
                          <InputGroup>
                            <Input readOnly defaultValue={item.vehicleModel}></Input>
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                          <Label>
                            <FormattedMessage id="inputs.licensePlate" />:
                          </Label>
                          <InputGroup>
                            <Input readOnly defaultValue={item.vehicleRegistrationPlate}></Input>
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                          <Label>
                            <FormattedMessage id="inputs.vin" />:
                          </Label>
                          <InputGroup>
                            <Input readOnly defaultValue={item.vin}></Input>
                          </InputGroup>
                        </Col>
                      </React.Fragment>
                    );
                  }
                })}
              </FormGroup>
            </>
          )}
          <Row className="no-gutters mb-2">
            <Col>
              <h3>
                <FormattedMessage id="userAssistancePage.insuredPersonData" />:
              </h3>
            </Col>
          </Row>
          {assistanceGroup === POLICY_CODE_PZ ? (
            <FormGroup row className="mb-0">
              <PolicyInsuredPeople
                data={assistance[idx].insuredPeople}
                policyType={POLICY_HEALTH_TRAVEL}
                personType="policyPersonInsured"
              />
            </FormGroup>
          ) : (
            <FormGroup row>
              {assistance[idx].insuredPeople.map((person, index) => (
                <React.Fragment key={index}>
                  {person.sector === USER_ACCOUNT_BUSINESS ? (
                    <Col sm="6" md="4">
                      <Label>
                        <FormattedMessage id="inputs.name" />
                      </Label>
                      <InputGroup>
                        <Input id="name" readOnly value={person.name} />
                      </InputGroup>
                    </Col>
                  ) : (
                    <>
                      <Col sm="6" md="4">
                        <Label>
                          <FormattedMessage id="inputs.firstName" />
                        </Label>
                        <InputGroup>
                          <Input id="firstName" readOnly value={person.firstName} />
                        </InputGroup>
                      </Col>
                      <Col sm="6" md="4">
                        <Label>
                          <FormattedMessage id="inputs.lastName" />
                        </Label>
                        <InputGroup>
                          <Input id="lastName" readOnly value={person.lastName} />
                        </InputGroup>
                      </Col>
                    </>
                  )}
                  <Col sm="6" md="4">
                    <Label>
                      <FormattedMessage id="inputs.OIB" />
                    </Label>
                    <InputGroup>
                      <Input id="OIB" readOnly value={person.oib} />
                    </InputGroup>
                  </Col>
                </React.Fragment>
              ))}
            </FormGroup>
          )}
        </CardBody>
      </Card>
    </Col>
  ) : (
    assistance.map(record => (
      <Col sm="6" key={record.number}>
        <CollapsiblePackageInfo
          raw
          placeholder={get(record, 'insuredItems[0].vehicleRegistrationPlate', record.number) || record.number}
          showButton={false}
          hasBody={false}
          showArrow={false}
          assistance
          onClick={() =>
            history.push(
              `${ROUTE_TO_USER_ASSISTANCE_PAGE}/${assistanceGroupDefs[assistanceGroup].route}/${record.number}`
            )
          }
        />
      </Col>
    ))
  );
};
