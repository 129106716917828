import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, CardGroup, Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from '../../../containers';
import { PartnerSelectCard } from '../../../components/PartnerSelectCard';

export const SelectPartner = ({ partners, searchFunc, loaderProp, partnerSelected, setPartnerSelected }) => {
  const [selectedPartnerId, setSelectedPartnerId] = useState(123);

  const handleSubmission = () => {
    if (selectedPartnerId) {
      searchFunc({ variables: { partnerId: selectedPartnerId } });
      setPartnerSelected(true);
    }
  };

  return (
    <div className="mt-4">
      <CardGroup>
        <Card className="content-card">
          <CardHeader className="border-0">
            <h2 className="mx-auto">
              <FormattedMessage id="userTypeSelectPage.selectPartnerAccount.title" />
            </h2>
            {partnerSelected && <FontAwesomeIcon className="color-green" icon="check-circle" size="lg" />}
          </CardHeader>
          <Collapse isOpen={!partnerSelected}>
            <CardBody>
              <p className="text-left">
                <FormattedMessage id="userTypeSelectPage.selectPartnerAccount.paragraph1" />
                <br />
                <FormattedMessage id="userTypeSelectPage.selectPartnerAccount.paragraph2" />
              </p>
              <Row className="mt-4">
                <Col sx="12" sm={{ offset: 2, size: 8 }}>
                  <Form>
                    <FormGroup row>
                      <Col xs="12">
                        {partners.map(partner => (
                          <PartnerSelectCard
                            key={partner.jmbg}
                            partner={partner}
                            selectedPartnerId={selectedPartnerId}
                            selectPartnerCard={setSelectedPartnerId}
                          />
                        ))}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </CardGroup>
      {!partnerSelected && (
        <Row className="no-gutters mt-5">
          <Col sm={{ offset: 2, size: 8 }} className="px-3">
            <CustomButton translationId="verify" onClick={handleSubmission} loaderProp={loaderProp} />
          </Col>
        </Row>
      )}
    </div>
  );
};
