import React, { Component } from 'react';
import { FormGroup, Row, Col, Form, Alert, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import NumberFormat from 'react-number-format';
import { LoginAndRegisterSelector, CustomButton, CustomInputField } from '../../../containers';
import { validate } from '../../../utils/validation';
import {
  userResendActivationEmail,
  userAccountActivation,
  clearUserErrorMessage,
  clearUserSuccessMessage,
} from '../../../redux/actions/userAuthActions';
import { LogoutLink } from '../../../components/LogoutLink';

const mapStateToProps = state => ({
  intl: state.intl,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  userResendActivationEmail: email => dispatch(userResendActivationEmail(email)),
  userAccountActivation: userData => dispatch(userAccountActivation(userData)),
  clearUserErrorMessage: payload => dispatch(clearUserErrorMessage(payload)),
  clearUserSuccessMessage: payload => dispatch(clearUserSuccessMessage(payload)),
});

class ActivateAccount extends Component {
  state = {
    isFormValid: false,
    submitted: false,
    formData: {
      activationCode: {
        value: '',
        error: null,
        invalid: true,
        focused: false,
        displayName: <FormattedMessage id="inputs.activationCode" />,
        validation: {
          required: true,
          activationCode: true,
        },
      },
    },
  };

  componentDidMount() {
    const userId = parseInt(get(this.props, 'match.params.userId', null), 10);
    const activationCode = get(this.props, 'match.params.activationCode', null);

    if (userId && activationCode) {
      this.setState({
        ...this.state,
        formData: { activationCode: { ...this.state.formData.activationCode, value: activationCode } },
      });
      this.props.userAccountActivation({ userId, token: activationCode });
    }
  }

  removeFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: false } },
    });
  };

  addFocus = field => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [field]: { ...this.state.formData[field], focused: true } },
    });
  };

  inputHandler = (field, value) => {
    if (value !== this.state.formData[field].value) {
      this.clearErrorOnValueChange();
      this.clearResendEmailMessageOnValueChange();
      const validation = validate({ ...this.state.formData.activationCode, value: value });

      this.setState(
        {
          formData: {
            ...this.state.formData,
            [field]: {
              ...this.state.formData[field],
              value: value,
              invalid: validation.invalid,
              error: validation.errors[0],
            },
          },
        },
        () => {
          if (!this.state.formData.activationCode.invalid) {
            this.runActivateAccount();
          }
        }
      );
    }
  };

  runActivateAccount = event => {
    event && event.preventDefault();
    if (!get(this.props, 'auth.activation.inProgress', false)) {
      const validation = validate(this.state.formData.activationCode);
      const activationCode = {
        ...this.state.formData.activationCode,
        invalid: validation.invalid,
        error: validation.errors[0],
      };

      this.setState(
        { isFormValid: !activationCode.invalid, submitted: true, formData: { ...this.state.formData, activationCode } },
        () => {
          if (!activationCode.invalid) {
            // Activate account action
            const userId = parseInt(get(this.props, 'match.params.userId', null), 10) || this.props.auth.user.id;
            this.props.userAccountActivation({ userId, token: activationCode.value });
            this.setState({ submitted: false });
          }
        }
      );
    }
  };

  clearErrorOnValueChange = () => {
    if (get(this.props, 'auth.activation.errorMessage', null)) {
      this.props.clearUserErrorMessage('activation');
    }
  };

  clearResendEmailMessageOnValueChange = () => {
    if (get(this.props, 'auth.resendActivationEmail.done', false)) {
      this.props.clearUserErrorMessage('resendActivationEmail');
      this.props.clearUserSuccessMessage('resendActivationEmail');
    }
  };

  componentDidUpdate() {
    const serverErrormessage = get(this.props, 'auth.activation.errorMessage', null);
    if (
      !this.state.formData.activationCode.error &&
      serverErrormessage &&
      this.state.formData.activationCode.error !== serverErrormessage
    ) {
      this.setState({
        ...this.state,
        submitted: true,
        formData: {
          activationCode: {
            ...this.state.formData.activationCode,
            error: serverErrormessage,
            invalid: true,
          },
        },
      });
    }
  }

  render() {
    return (
      <LoginAndRegisterSelector titleTranslationId="activateAccountPage.title" showContactSupportLink={true}>
        <Row className="no-gutters px-4 my-5 my-md-0">
          <Col xs={12} sm={{ offset: 2, size: 8 }} md={{ offset: 0, size: 12 }} lg={{ offset: 1, size: 10 }}>
            <Row className="no-gutters mb-5">
              <Col className="text-right">
                <LogoutLink className="mr-0" />
              </Col>
            </Row>
            <Row className="no-gutters mt-2 mb-0 mb-lg-3">
              <Col>
                <p className="font-weight-bold">
                  <FormattedMessage id="activateAccountPage.subtitle" />
                </p>
              </Col>
            </Row>

            <Row className="no-gutters mb-0 mb-lg-4">
              <Col>
                <p>
                  <FormattedMessage id="activateAccountPage.paragraph1" />
                </p>
              </Col>
            </Row>

            {this.props.auth.user.email && (
              <Row className="no-gutters mb-0 mb-lg-4">
                <Col>
                  <p className="font-weight-bold resend-code">
                    <FormattedMessage id="activateAccountPage.paragraph2" />{' '}
                    <span
                      className="resend-link text-underline"
                      onClick={() => this.props.userResendActivationEmail(this.props.auth.user.email)}
                    >
                      <FormattedMessage id="activateAccountPage.resendLink" />
                    </span>
                  </p>
                </Col>
              </Row>
            )}
            {get(this.props, 'auth.resendActivationEmail.done', false) && (
              <>
                {get(this.props, 'auth.resendActivationEmail.success', false) && (
                  <Alert color="success" role="alert">
                    <FormattedMessage id="resendActivationEmail.success" />
                  </Alert>
                )}
                {get(this.props, 'auth.resendActivationEmail.errorMessage', null) && (
                  <Alert color="danger" role="alert">
                    {this.props.auth.resendActivationEmail.errorMessage}
                  </Alert>
                )}
              </>
            )}
            <Form onSubmit={this.runActivateAccount}>
              <FormGroup row>
                <Col xs="12">
                  <CustomInputField
                    id="activationCode"
                    name="activation code"
                    value={this.state.formData.activationCode.value}
                    customComponent={NumberFormat}
                    customInput={Input}
                    fieldState={this.state.formData.activationCode}
                    formSubmitted={this.state.submitted}
                    autoComplete="off"
                    format="###-###"
                    mask="_"
                    isNumericString={true}
                    onValueChange={values => this.inputHandler('activationCode', values.value)}
                    onFocus={() => this.addFocus('activationCode')}
                    onBlur={() => this.removeFocus('activationCode')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="my-5">
                <Col xs="12">
                  <CustomButton
                    loaderProp={get(this.props, 'auth.activation.inProgress', false)}
                    translationId="activateAccount"
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </LoginAndRegisterSelector>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
