import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
// import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { buildVersion } from './config/buildVersion';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  ignoreErrors: ['ChunkLoadError'],
  release: buildVersion,
});

const container = document.getElementById('root');

// Create a root.
// const root = ReactDOM.createRoot(container);
// root.render(<App />);
ReactDOM.render(<App />, container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
