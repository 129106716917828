import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { MyPoliciesCardList } from '../../../components/MyPoliciesCardList';
import { CustomButton } from '../../../containers';
import { redirectToUserProductsPage } from '../../../redux/actions/navigationActions';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserMyPolicies = () => {
  const dispatch = useDispatch();

  return (
    <Container className="hok-container" fluid>
      <AdFormIFrame title="Moje Police" />
      <Row>
        <Col className="mb-4">
          <Row className="mb-3">
            <Col className="page-container" xs="12">
              <h3 className="title-text mb-0">
                <FormattedMessage id="userMyPolicies.title" />
              </h3>
              <Row className="d-flex cards-container no-gutters mt-5">
                <MyPoliciesCardList showCreateNew>
                  <Col xs="12">
                    <Row className="no-gutters">
                      <p className="hok-text-dark pr-1">
                        <FormattedMessage id="userMyPolicies.noPoliciesFound" />
                      </p>
                    </Row>
                    <Row className="no-gutters">
                      <CustomButton
                        className="hok-dashboard-btn-wide mt-3"
                        translationId="newPolicy"
                        onClick={() => dispatch(redirectToUserProductsPage())}
                      />
                    </Row>
                  </Col>
                </MyPoliciesCardList>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
