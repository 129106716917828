import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Row, Col, FormGroup, Label, InputGroup, Input } from 'reactstrap';
import { get, uniqBy } from 'lodash-es';
import moment, { ISO_8601 } from 'moment';
import { CustomButton } from '../../containers/CustomButton';
import { formatDate } from '../../constants/dateFormatting';
import { PolicyInsuredPeople } from './PolicyInsuredPeople';
import {
  POLICY_STATUS_ACTIVE,
  POLICY_CODE_PZ,
  assistanceGroupDefs,
  POLICY_CODE_AO,
  POLICY_CAR_LIABILITY,
  POLICY_CODE_HE,
  POLICY_PROPERTY,
  // POLICY_CODE_HE,
  POLICY_CODE_EU,
} from '../../constants/business';
import {
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
  ROUTE_TO_USER_REPORT_DAMAGE,
  ROUTE_TO_USER_ASSISTANCE_PAGE,
  ROUTE_TO_USER_NEW_AO_POLICY_PAGE,
  ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE,
  // ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE,
  // ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE,
} from '../../constants/routes';
import { ENQUIRY_SUBJECT_OTHER, ENQUIRY_SUBJECT_RENEWAL } from '../../constants/support';
import { claimGroupDefs, CLAIM_CAR_LIABILITY } from '../../constants/claimDefs';
import { setSessionStoragePolicyEligibility } from '../../constants/LocalStorageKeys';

export default React.memo(({ policyData, policyClaimType, user, admin = false }) => {
  const history = useHistory();

  const availableForRenewTimeFrame =
    Math.abs(
      moment()
        .endOf('day')
        .diff(moment(policyData.insuranceEnd, ISO_8601).endOf('day'), 'days', true)
    ) <= 30;

  const policyOlderThan30Days = moment(policyData.insuranceEnd, ISO_8601).isBefore(moment().subtract(30, 'd'), 'd');

  return (
    <div className="form-container">
      <Row className="no-gutters">
        <Col xs={12}>
          <h3>
            <FormattedMessage id="userMyPolicies.policyData.title" />:
          </h3>
        </Col>
      </Row>
      <FormGroup row>
        <Col xs={12} sm={6} xl={4}>
          <Label for="policyNumber">
            <FormattedMessage id="userMyPolicies.policyData.policyNumber" />
          </Label>
          <InputGroup>
            <Input className="input-uppercase" id="policyNumber" readOnly defaultValue={policyData.number || ''} />
          </InputGroup>
        </Col>
        <Col xs={12} sm={6} xl={4}>
          <Label for="policyValidFrom">
            <FormattedMessage id="inputs.validFrom" />
          </Label>
          <InputGroup>
            <Input
              className="input-uppercase"
              id="policyValidFrom"
              readOnly
              defaultValue={formatDate(policyData.insuranceStart) || ''}
            />
          </InputGroup>
        </Col>
        <Col xs={12} sm={6} xl={4}>
          <Label for="policyValidTo">
            <FormattedMessage id="inputs.validTo" />
          </Label>
          <InputGroup>
            <Input
              className="input-uppercase"
              id="policyValidTo"
              readOnly
              defaultValue={formatDate(policyData.insuranceEnd) || ''}
            />
          </InputGroup>
        </Col>
      </FormGroup>
      {get(policyData, 'insuredItems.length', []) > 0 && (
        <>
          <Row className="no-gutters">
            <Col xs={12}>
              <h3>
                <FormattedMessage id="userMyPolicies.policyData.subjectOfInsurance" />:
              </h3>
            </Col>
          </Row>
          <FormGroup row>
            {uniqBy(policyData.insuredItems, 'insuredLocation').map((item, index) => {
              if (item.insuredLocation) {
                return (
                  <Col xs={12} sm={6} key={index}>
                    {get(policyData, 'productDefinition.group', '') === POLICY_PROPERTY && (
                      <Label>
                        <FormattedMessage id="inputs.address" />
                        {uniqBy(policyData.insuredItems, 'insuredLocation') > 1 ? ` ${index + 1}` : ''}:
                      </Label>
                    )}
                    <InputGroup>
                      <Input readOnly defaultValue={item.insuredLocation}></Input>
                    </InputGroup>
                  </Col>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    {item.vehicleMake && (
                      <Col xs={12} sm={6} xl={4}>
                        <Label>
                          <FormattedMessage id="inputs.vehicleMake" />:
                        </Label>
                        <InputGroup>
                          <Input readOnly defaultValue={item.vehicleMake}></Input>
                        </InputGroup>
                      </Col>
                    )}
                    {item.vehicleModel && (
                      <Col xs={12} sm={6} xl={4}>
                        <Label>
                          <FormattedMessage id="inputs.vehicleModel" />:
                        </Label>
                        <InputGroup>
                          <Input readOnly defaultValue={item.vehicleModel}></Input>
                        </InputGroup>
                      </Col>
                    )}
                    {item.vehicleRegistrationPlate && (
                      <Col xs={12} sm={6} xl={4}>
                        <Label>
                          <FormattedMessage id="inputs.licensePlate" />:
                        </Label>
                        <InputGroup>
                          <Input readOnly defaultValue={item.vehicleRegistrationPlate}></Input>
                        </InputGroup>
                      </Col>
                    )}
                    {item.vin && (
                      <Col xs={12} sm={6} xl={4}>
                        <Label>
                          <FormattedMessage id="inputs.vin" />:
                        </Label>
                        <InputGroup>
                          <Input readOnly defaultValue={item.vin}></Input>
                        </InputGroup>
                      </Col>
                    )}
                  </React.Fragment>
                );
              }
            })}
          </FormGroup>
        </>
      )}
      <Row>
        {(policyData.role === 'U' || policyData.role === 'UO') && (
          <PolicyInsuredPeople data={[user]} personType="policyContractor" />
        )}
        <PolicyInsuredPeople
          data={policyData.insuredPeople}
          policyType={get(policyData, 'productDefinition.group', '')}
          personType="policyPersonInsured"
        />
      </Row>
      {!admin && (
        <Row className="no-gutters">
          <Col xs={12} className="text-right">
            {policyData.status === POLICY_STATUS_ACTIVE && policyData.product !== POLICY_CODE_PZ && (
              <CustomButton
                className="hok-outline-btn-wide hok-policy-action-btn hok-outline-dark"
                outline
                color="secondary"
                block={false}
                translationId="requestPolicyChange"
                onClick={() => {
                  history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                    subject: ENQUIRY_SUBJECT_OTHER,
                    title: 'Zahtjev za promjenom police',
                    content: `Zahtjev za promjenom police ${policyData.number}`,
                  });
                }}
              />
            )}
            {policyData.status === POLICY_STATUS_ACTIVE && assistanceGroupDefs[policyData.assistance] && (
              <CustomButton
                className="hok-outline-btn hok-policy-action-btn ml-0 ml-sm-3"
                outline
                block={false}
                translationId="requestAssistance"
                onClick={() => {
                  history.push(
                    `${ROUTE_TO_USER_ASSISTANCE_PAGE}/${assistanceGroupDefs[policyData.assistance].route}/${
                      policyData.number
                    }`
                  );
                }}
              />
            )}
            {get(policyClaimType, 'id', null) && claimGroupDefs[get(policyClaimType, 'group', '')] && (
              <CustomButton
                className="hok-outline-btn hok-policy-action-btn ml-0 ml-sm-3"
                outline
                block={false}
                translationId="reportDamage"
                onClick={() => {
                  if (claimGroupDefs[get(policyClaimType, 'group', '')]) {
                    history.push(
                      `${ROUTE_TO_USER_REPORT_DAMAGE}/${claimGroupDefs[get(policyClaimType, 'group', '')].route}`,
                      get(policyClaimType, 'group', '') === CLAIM_CAR_LIABILITY
                        ? { insurancePolicyNumberAO: policyData.number }
                        : { reportingPolicyNumber: policyData.number, reportingPolicyOwnerOib: user.oib }
                    );
                  }
                }}
              />
            )}
            {((get(policyData, 'productDefinition.group', '') === POLICY_CAR_LIABILITY && policyOlderThan30Days) ||
              availableForRenewTimeFrame) &&
              policyData.product !== POLICY_CODE_PZ && policyData.product !== POLICY_CODE_EU && (
                <CustomButton
                  type="submit"
                  className="hok-dashboard-btn hok-policy-action-btn ml-0 ml-sm-3"
                  block={false}
                  translationId={
                    get(policyData, 'productDefinition.group', '') === POLICY_CAR_LIABILITY && policyOlderThan30Days
                      ? 'requestRenewal'
                      : 'renew'
                  }
                  onClick={() => {
                    if (
                      get(policyData, 'productDefinition.group', '') === POLICY_CAR_LIABILITY &&
                      !policyOlderThan30Days
                    ) {
                      setSessionStoragePolicyEligibility(POLICY_CODE_AO);
                      history.push(ROUTE_TO_USER_NEW_AO_POLICY_PAGE, {
                        policyNumber: get(policyData, 'number', ''),
                      });
                    } else if (policyData.product === POLICY_CODE_HE) {
                      history.push(ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE, {
                        registrationPlate: get(policyData, 'insuredItems[0].vehicleRegistrationPlate', ''),
                        vin: get(policyData, 'insuredItems[0].vin', ''),
                        insuranceStartDate: policyData.insuranceEnd,
                      });
                    } else if (policyData.product !== POLICY_CODE_EU){
                      history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                        subject: ENQUIRY_SUBJECT_RENEWAL,
                        title: 'Zahtjev za obnovom police',
                        content: `Zahtjev za obnovom police ${policyData.number}`,
                      });
                    }
                  }}
                />
              )}
          </Col>
        </Row>
      )}
    </div>
  );
});
