import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userAuthReducer from './userAuthReducer';
import userProfileReducer from './userProfileReducer';
import errorReducer from './errorReducer';
import usersReducer from './usersReducer';
import intlReducer from './intlReducer';
import supportReducer from './supportReducer';
import currencyConfigReducer from './currencyConfigReducer';

const reducers = history =>
  combineReducers({
    auth: userAuthReducer,
    userProfile: userProfileReducer,
    intl: intlReducer,
    users: usersReducer,
    error: errorReducer,
    support: supportReducer,
    router: connectRouter(history),
    currencyConfig: currencyConfigReducer,
  });

export default reducers;
