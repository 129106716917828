import gql from 'graphql-tag';

export const BuyEurekaProductMutation = gql`
  mutation BuyEurekaProduct($productRelatedData: HokEurekaProductRelatedDataInput!) {
    buyInsurancePolicyHokEureka(shop: APPLICATION_TYPE_WEB, productRelatedData: $productRelatedData) {
      confirmationCode
    }
  }
`;

export const BuyPzoProductMutation = gql`
  mutation BuyPzoProduct($productRelatedData: HokPzoProductRelatedDataInput!) {
    buyInsurancePolicyHokPzo(shop: APPLICATION_TYPE_WEB, productRelatedData: $productRelatedData) {
      confirmationCode
    }
  }
`;

export const BuyHokExpressProductMutation = gql`
  mutation($productRelatedData: HokExpressProductRelatedDataInput!) {
    buyInsurancePolicyHokExpress(shop: APPLICATION_TYPE_WEB, productRelatedData: $productRelatedData) {
      confirmationCode
    }
  }
`;

export const BuyHokAOProductMutation = gql`
  mutation($productRelatedData: HokAOProductRelatedDataInput!) {
    buyInsurancePolicyHokAO(shop: APPLICATION_TYPE_WEB, productRelatedData: $productRelatedData) {
      confirmationCode
    }
  }
`;
