import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { ContentContainer, CustomInputField, CustomButton } from '../../../containers';
import {
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT,
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW,
} from '../../../constants/routes';
import { validateFormSubmission } from '../../../utils/validation';
import { useLazyQuery } from 'react-apollo';
import { GetClaimFromCodeQuery } from '../../../operations/queries/GetClaimFromCodeQuery';
import { handleRequestError } from '../../../constants/errorCodes';
import { ReactComponent as HokBackIcon } from '../../../img/icons/hok-back.svg';

export const QuickClaimPreview = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [confirmationCode, setConfirmationCode] = useState({
    value: '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.claimConfirmationCode" />,
    validation: {
      required: true,
    },
  });

  const removeFocus = () => {
    setConfirmationCode({ ...confirmationCode, focused: false });
  };

  const addFocus = () => {
    setConfirmationCode({ ...confirmationCode, focused: true });
  };

  const [getClaimData, { loading }] = useLazyQuery(GetClaimFromCodeQuery, {
    onError: error => {
      handleRequestError(error, dispatch, { silent: true }, () => {
        setConfirmationCode(o => ({
          ...o,
          invalid: true,
          error: <FormattedMessage id="errors.unknownError" />,
        }));
      });
    },
    onCompleted: data => {
      if (get(data, 'viewReportedClaim.claimConfirmationCode', null) === null) {
        setConfirmationCode(o => ({
          ...o,
          invalid: true,
          error: <FormattedMessage id="errors.invalidConfirmationCode" />,
        }));
      } else {
        history.push(
          `${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW}/${get(data, 'viewReportedClaim.claimConfirmationCode', '')}`
        );
      }
    },
  });

  const verifyClaimCode = async event => {
    event.preventDefault();
    if (await validateFormSubmission([setConfirmationCode])) {
      getClaimData({ variables: { claimConfirmationCode: confirmationCode.value.replace(/-/g, '') } });
    }
  };

  return (
    <ContentContainer titleTranslationId="quickDamageReportPage.title" footerImage>
      <Row className="no-gutters my-3">
        <Col xs="12" md={{ offset: 2, size: 8 }}>
          <Link to={ROUTE_TO_USER_QUICK_DAMAGE_REPORT} className="d-inline-flex align-items-center link-back ml-0">
            <HokBackIcon className="mr-2" />
            <FormattedMessage id="back" />
          </Link>
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col xs="12" md={{ offset: 2, size: 8 }}>
          <p>Unesite kod štete kako biste mogli pregledati status i podatke prijavljene štete.</p>
        </Col>
      </Row>
      <Row className="main-content my-5">
        <Col xs="12" sm={{ offset: 2, size: 8 }} md={{ offset: 3, size: 6 }} className="text-center">
          <Form onSubmit={verifyClaimCode}>
            <FormGroup row>
              <Col xs="12" className="text-left">
                <CustomInputField
                  className="hok-confirmation-code-input"
                  id="confirmationCode"
                  name="confirmation code"
                  placeholderId="claimConfirmationCode"
                  fieldState={confirmationCode}
                  formSubmitted={true}
                  type="text"
                  onChange={event =>
                    setConfirmationCode({ ...confirmationCode, value: event.target.value, invalid: false, error: '' })
                  }
                  onFocus={addFocus}
                  onBlur={removeFocus}
                />
              </Col>
            </FormGroup>
            <Row className="mt-5">
              <Col className="text-center">
                <CustomButton className="hok-dashboard-btn w-100" translationId="preview" loaderProp={loading} />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </ContentContainer>
  );
};
