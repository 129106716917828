import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, FormGroup, InputGroup, Input, Label } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLazyQuery, useMutation } from 'react-apollo';
import moment, { ISO_8601 } from 'moment';
import { get } from 'lodash-es';
import { formatDate } from '../../constants/dateFormatting';
import {
  INVOICE_STATUS_PAID,
  POLICY_STATUS_EXPIRED,
  INVOICE_STATUS_NOT_PAID,
  INVOICE_STATUS_HALF_PAID,
  INVOICE_PAYMENT,
} from '../../constants/business';
import { CustomButton, ProductContainer } from '../../containers';
import { handleRequestError } from '../../constants/errorCodes';
import { GetUserInvoiceRawQuery } from '../../operations/queries/GetUserInvoicesQuery';
import { saveFileToDevice } from '../../utils/generateFile';
import { UserInitiateNewPaymentMutation } from '../../operations/mutations/UserInitiateNewPaymentMutation';
import { ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE } from '../../constants/routes';
import { WSPayCards } from '../WSPayCards';
import { displayCurrencyOptions } from '../../utils/currencyDisplayFormat';
import { currencyDisplayUpdate } from '../CurrencyDisplay';


export const InvoiceCard = ({ invoice, admin = false }) => {
  const dispatch = useDispatch();
  const { formatMessage, formatNumber } = useIntl();
  const win = useRef(null);

  const [printInvoice, { loading: printLoading }] = useLazyQuery(GetUserInvoiceRawQuery, {
    onError: error => handleRequestError(error, dispatch),
    onCompleted: data => {
      const invoiceData = get(data, 'printInvoice', {});
      if (invoiceData.filename && invoiceData.base64) {
        saveFileToDevice(invoiceData.filename, invoiceData.base64);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [initPayment, { loading: initPaymentLoading }] = useMutation(UserInitiateNewPaymentMutation, {
    onError: error => handleRequestError(error, dispatch),
    onCompleted: data => {
      const confirmationCode = get(data, 'initNewPayment.confirmationCode', null);
      if (confirmationCode) {
        // Workaround to not trigger popup blocker due to window.open() not being called
        // immediately after user action (onClick event) but after an async function
        win.current.location = `${ROUTE_TO_USER_PAYMENT_PROCESSING_PAGE}/${confirmationCode}`;
      }
    },
  });

  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  return (
    <ProductContainer
      mainTitle={moment(invoice.date, ISO_8601).format('MMMM YYYY')}
      subtitle={`${formatMessage({ id: 'userMyInvoices.maturityDate' })}: ${formatDate(invoice.maturityDate)}`}
      showIsActive={!!moment(invoice.maturityDate).isBefore(moment(), 'day') && invoice.status !== INVOICE_STATUS_PAID}
      showActiveText={false}
      activeStatus={POLICY_STATUS_EXPIRED}
      key={invoice.invoiceNumber}>
      <div className="form-container">
        <Row>
          <Col>
            <h3>
              <FormattedMessage id="userMyInvoices.basicInvoiceInfo" />
            </h3>
          </Col>
        </Row>
        <FormGroup row>
          <Col className="mb-3" xs={12} sm={6} xl={4}>
            <Label for="invoiceNumber">
              <FormattedMessage id="inputs.invoiceNumber" />
            </Label>
            <InputGroup>
              <Input className="input-uppercase" id="invoiceNumber" readOnly defaultValue={invoice.invoiceNumber} />
            </InputGroup>
          </Col>
          <Col className="mb-3" xs={12} sm={6} xl={4}>
            <Label for="maturityDate">
              <FormattedMessage id="inputs.maturityDate" />
            </Label>
            <InputGroup>
              <Input
                className="input-uppercase"
                id="maturityDate"
                readOnly
                defaultValue={formatDate(invoice.maturityDate)}
              />
            </InputGroup>
          </Col>
          <Col className="mb-3" xs={12} sm={6} xl={4}>
            <Label for="status">
              <FormattedMessage id="inputs.status" />
            </Label>
            <InputGroup>
              <Input
                className="hok-invoice-status"
                id="status"
                invalid={invoice.status !== INVOICE_STATUS_PAID}
                readOnly
                defaultValue={formatMessage({ id: `invoiceStatus.${invoice.status}` }).toUpperCase()}
              />
            </InputGroup>
          </Col>
          <Col className="mb-3" xs={12} sm={6} xl={4}>
            <Label for="invoiceAmount">
              <FormattedMessage id="inputs.invoiceAmount" />
            </Label>
            <InputGroup>
              <Input id="invoiceAmount" readOnly defaultValue={formatNumber(invoice.amount, displayCurrencyOptions)} />
            </InputGroup>
          </Col>
          <Col className="mb-3" xs={12} sm={6} xl={4}>
            <Label for="debtAmount">
              <FormattedMessage id="inputs.debtAmount" />
            </Label>
            <InputGroup>
              <Input id="debtAmount" readOnly defaultValue={formatNumber(invoice.debt, displayCurrencyOptions)} />
            </InputGroup>
          </Col>
        </FormGroup>
        {invoice.paidOnline && invoice.status !== INVOICE_STATUS_PAID && (
          <Row className="no-gutters mb-3 text-left text-md-right color-red">
            <Col>
              <FormattedMessage id="userMyInvoices.processingNotice" />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="text-center text-md-right">
            <CustomButton
              className="hok-outline-dashboard-btn-wide d-block d-md-inline-block mx-auto mb-3"
              outline
              block={false}
              translationId="downloadInvoice"
              onClick={() => printInvoice({ variables: { invoiceNumber: invoice.invoiceNumber } })}
              loaderProp={printLoading}
            />
            {!admin && (
              <>
                <CustomButton
                  className="hok-dashboard-btn-wide d-block d-md-inline-block mx-auto ml-md-3 mb-md-3"
                  block={false}
                  translationId="payInvoice"
                  onClick={() => {
                    win.current = window.open();
                    initPayment({
                      variables: { paymentType: INVOICE_PAYMENT, invoiceNumber: invoice.invoiceNumber },
                    });
                  }}
                  disabled={
                    !invoice.debt ||
                    invoice.debt <= 0 ||
                    invoice.paidOnline ||
                    (invoice.status !== INVOICE_STATUS_NOT_PAID && invoice.status !== INVOICE_STATUS_HALF_PAID) ||
                    initPaymentLoading
                  }
                />
                <WSPayCards />
              </>
            )}
          </Col>
        </Row>
      </div>
    </ProductContainer>
  );
};
