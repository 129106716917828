import gql from 'graphql-tag';

export const GetServerStorageQuery = gql`
  query GetServerStorage($stateKey: String) {
    serverStorage(stateKey: $stateKey) {
      stateKey
      state
    }
  }
`;
