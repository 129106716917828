import gql from 'graphql-tag';

export const GetProductRisksQuery = gql`
  query GetProductRisks($productGroup: productGroupTypeEnum) {
    getProductRisks(productGroup: $productGroup) {
      id
      productGroup
      productCode
      name
      riskCode
      riskEnumCode
      defaultSelected
      active
      defaultSelected
      vehicles {
        vehicleTypeCode
      }
    }
  }
`;

export const GetAORisksDefinitionsQuery = gql`
  query GetProductRisks {
    getProductRisks {
      id
      name
      riskCode
    }
  }
`;
