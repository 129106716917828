import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row,
  Col,
  CardGroup,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Form,
  CustomInput,
  Fade,
  FormFeedback,
} from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Mutation } from 'react-apollo';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { CustomInputField, CustomButton } from '../../../containers';
import { VerificationFailedModal } from './VerificationFailedModal';
import {
  redirectToRegistrationCompletePage,
  redirectToMaintenancePage,
} from '../../../redux/actions/navigationActions';
import { UserVerifyProfileMutation } from '../../../operations/mutations/UserVerifyProfileMutation';
import {
  ONE_OF_LAST_5_INVOICES,
  NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY,
} from '../../../constants/userDefs';
import { validateFormSubmission } from '../../../utils/validation';
import { userUpdateDataInStore, userLoginFail } from '../../../redux/actions/userAuthActions';
import { getErrorObject, isAuthTokenInvalid, isMaintenanceInProgress } from '../../../constants/errorCodes';
import { FORMAT_STANDARD_DATE, yearListRender } from '../../../constants/dateFormatting';

const AccountVerificationForm = injectIntl(props => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [isVerificationFailedModalOpen, setIsVerificationFailedModalOpen] = useState(false);
  const [dateInputFocused, setDateInputFocused] = useState(false);

  const [verifyProcedure, setVerifyProcedure] = useState({
    value: '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.invoice" />,
    validation: {
      required: true,
      enum: [ONE_OF_LAST_5_INVOICES, NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY],
    },
  });

  const [invoice, setInvoice] = useState({
    value: '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.invoice" />,
    validation: {
      required: true,
    },
  });

  const [policy, setPolicy] = useState({
    value: '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.policy" />,
    validation: {
      required: true,
    },
  });

  const [expiryDate, setExpiryDate] = useState({
    value: null,
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.invoice" />,
    validation: {
      required: true,
    },
  });

  const toggle = () => {
    setIsVerificationFailedModalOpen(!isVerificationFailedModalOpen);
  };

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false }));
  };

  return (
    <Mutation
      mutation={UserVerifyProfileMutation}
      onCompleted={() => {
        dispatch(userUpdateDataInStore({ verified: true, partnerId: props.partnerId }));
        dispatch(redirectToRegistrationCompletePage());
      }}
      onError={error => {
        const errorObject = getErrorObject(error);
        if (isAuthTokenInvalid(errorObject)) {
          dispatch(userLoginFail({ errorMessage: errorObject.message }));
        } else if (isMaintenanceInProgress(errorObject)) {
          dispatch(redirectToMaintenancePage());
        } else {
          toggle();
        }
      }}>
      {(verifyUser, { loading, error, data }) => {
        const handleVerifySubmit = async event => {
          event.preventDefault();

          const fieldsArray = [setVerifyProcedure];

          if (verifyProcedure.value === ONE_OF_LAST_5_INVOICES) {
            fieldsArray.push(setInvoice);
          } else if (verifyProcedure.value === NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY) {
            fieldsArray.push(...[setPolicy, setExpiryDate]);
          }

          setSubmitted(true);
          if (await validateFormSubmission(fieldsArray)) {
            let variables = { verifyProcedure: verifyProcedure.value };
            if (verifyProcedure === ONE_OF_LAST_5_INVOICES) {
              variables.ONE_OF_LAST_5_INVOICES = invoice.value;
            } else if (verifyProcedure.value === NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY) {
              variables.NUMBER_OF_VALID_INSURANCE_POLICY = policy.value;
              variables.DATE_OF_EXPIRY = expiryDate.value;
            }
            verifyUser({ variables });
          }
        };

        return (
          <>
            <Form onSubmit={handleVerifySubmit}>
              <CardGroup className="mt-4">
                <Card className="content-card">
                  <CardHeader className="border-0">
                    <h2 className="mx-auto">
                      <FormattedMessage id="userTypeSelectPage.verifyAccount.title" />
                    </h2>
                  </CardHeader>
                  <Collapse isOpen>
                    <CardBody>
                      <p className="text-left font-weight-bold">
                        <FormattedMessage id="userTypeSelectPage.verifyAccount.subtitle" />
                      </p>
                      <p className="text-left">
                        <FormattedMessage id="userTypeSelectPage.verifyAccount.paragraph1" />
                      </p>
                      <Row className="mt-4">
                        <Col sx="12" sm={{ offset: 2, size: 8 }}>
                          <Row className="mb-3">
                            <Col xs="12" className="text-center font-weight-bold">
                              <CustomInput
                                className="hok-verify-radio"
                                inline
                                type="radio"
                                id={ONE_OF_LAST_5_INVOICES}
                                checked={verifyProcedure.value === ONE_OF_LAST_5_INVOICES}
                                name="verification procedure"
                                label={<FormattedMessage id="buttons.verifyWithInvoice" />}
                                onChange={() => inputHandler(setVerifyProcedure, ONE_OF_LAST_5_INVOICES)}
                              />
                              <CustomInput
                                className="hok-verify-radio"
                                inline
                                type="radio"
                                id={NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY}
                                checked={verifyProcedure.value === NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY}
                                name="verification procedure"
                                label={<FormattedMessage id="buttons.verifyWithPolicyAndExpiryDate" />}
                                onChange={() =>
                                  inputHandler(setVerifyProcedure, NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY)
                                }
                              />
                            </Col>
                          </Row>
                          {verifyProcedure.value === ONE_OF_LAST_5_INVOICES && (
                            <Fade>
                              <Row>
                                <Col xs="12">
                                  <CustomInputField
                                    id="invoice"
                                    name="invoice"
                                    fieldState={invoice}
                                    formSubmitted={submitted}
                                    onChange={event => inputHandler(setInvoice, event.target.value)}
                                  />
                                </Col>
                              </Row>
                            </Fade>
                          )}
                          {verifyProcedure.value === NUMBER_OF_VALID_INSURANCE_POLICY_AND_DATE_OF_EXPIRY && (
                            <Fade>
                              <Row className="mb-3">
                                <Col xs="12">
                                  <CustomInputField
                                    id="policy"
                                    name="policy"
                                    fieldState={policy}
                                    formSubmitted={submitted}
                                    onChange={event => inputHandler(setPolicy, event.target.value)}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="12">
                                  <SingleDatePicker
                                    id="expiryDate"
                                    readOnly
                                    hideKeyboardShortcutsPanel
                                    date={expiryDate.value}
                                    onDateChange={date => setExpiryDate({ ...expiryDate, value: date })}
                                    firstDayOfWeek={1}
                                    numberOfMonths={1}
                                    small
                                    openDirection="up"
                                    focused={dateInputFocused}
                                    onFocusChange={({ focused }) => setDateInputFocused(focused)}
                                    displayFormat={FORMAT_STANDARD_DATE}
                                    placeholder={props.intl.formatMessage({ id: 'inputs.expiryDate' })}
                                    isOutsideRange={date =>
                                      date.isBefore(moment().set({ date: 1, month: 1, year: 2016 }), 'day')
                                    }
                                    renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                                      <div className="d-flex justify-content-center">
                                        <div className="mr-1">
                                          <select
                                            value={month.month()}
                                            onChange={e => onMonthSelect(month, e.target.value)}>
                                            {moment.months().map((label, value) => (
                                              <option value={value} key={label}>
                                                {label}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="ml-1">
                                          <select
                                            value={month.year()}
                                            onChange={e => onYearSelect(month, e.target.value)}>
                                            {yearListRender(1991, new Date(moment().add('2', 'years')).getFullYear())}
                                          </select>
                                        </div>
                                      </div>
                                    )}
                                  />
                                  {expiryDate.error && submitted && (
                                    <FormFeedback className="d-block">{expiryDate.error}</FormFeedback>
                                  )}
                                </Col>
                              </Row>
                            </Fade>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </CardGroup>
              <Row className="no-gutters mt-5">
                <Col sm={{ offset: 2, size: 8 }} className="px-3">
                  <CustomButton translationId="verify" loaderProp={loading} />
                </Col>
              </Row>
            </Form>
            <VerificationFailedModal isOpen={isVerificationFailedModalOpen} toggle={toggle} />
          </>
        );
      }}
    </Mutation>
  );
});

export default AccountVerificationForm;
