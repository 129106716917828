import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ArrowLinkButton, CustomButton } from '../../../containers';
import { ROUTE_TO_USER_POLICIES_PAGE, ROUTE_TO_USER_PRODUCT_ENQUIRY } from '../../../constants/routes';
import { PreContractualDocumentation } from '../../../components/PreContractualDocumentation';
import { POLICY_CODE_AK } from '../../../constants/business';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../constants/support';
import { googleAnalyticsEvent } from '../../../utils/browser';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserPoliciesKaskoInsurance = () => {
  const history = useHistory();

  return (
    <Container fluid className="policies">
      <AdFormIFrame title="Proizvodi - Kasko" />
      <ArrowLinkButton xlColSize={10} linkTo={ROUTE_TO_USER_POLICIES_PAGE} />
      <Row className="mt-4">
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <h3>
            <FormattedMessage id="routes.kaskoInsurance" />
          </h3>
          <Row className="no-gutters mt-5 mx-3">
            <Col xs="12">
              <p>
                Potpuno kasko osiguranje vašeg vozila omogućuje vam da dobijete naknadu za materijalnu štetu na vašem
                vozilu nastalu u prometnoj nezgodi i onda kada ste vi uzrokovali prometnu nezgodu. Pokriva i mnoge druge
                materijalne štete: provalu, krađu vozila, elementarne nepogode, lom stakla.
              </p>
              <p>
                Hok osiguranje vam nudi mogućnost ugovaranja autokaska s franšizom, odnosno uz sudjelovanje osiguranika
                u šteti. Franšiza se može ugovoriti u iznosima od 1,2,3,4,5% od novonabavne vrijednosti vozila. [Npr.
                novonabavna cijena vozila = 20.000,00 €; Ugovorena franšiza 1% iznosila bi 200,00 € što
                podrazumijeva da se naknada iz osiguranja umanjuje za iznos od 200,00 € koliko je ugovoreni udio
                osiguranika u šteti].
              </p>
            </Col>
          </Row>
          <PreContractualDocumentation productCode={POLICY_CODE_AK} />
          <Row className="no-gutters mx-3 mt-5">
            <Col className="text-center">
              <CustomButton
                className="hok-dashboard-btn-wide"
                block={false}
                translationId="requestOffer"
                onClick={() => {
                  googleAnalyticsEvent({ category: 'Proizvodi', action: 'Proizvodi_Kasko_Zatrazi_Ponudu' });
                  history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                    subject: ENQUIRY_SUBJECT_NEW_POLICY,
                    title: 'Kupnja nove police "Kasko osiguranje"',
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
