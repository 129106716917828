import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { DamageClaimCard } from '../../../components/DamageClaimCard';
import { DamageClaimsSearch } from './DamageClaimsSearch';
import { Spinner, ScreenTitleContainer } from '../../../containers';
import { redirectToSpecificDamageClaimPage } from '../../../redux/actions/navigationActions';
import { AdFormIFrame } from '../../../components/AdFormIframe';

export const UserMyDamageClaims = () => {
  return (
    <Container className="hok-container" fluid>
      <AdFormIFrame title="Moje Štete" />
      <Row className="no-gutters mt-3">
        <Col>
          <ScreenTitleContainer row titleId="userMyDamageClaims.title" maxWidthSet />
          <DamageClaimsSearch>
            {({ data: claims, error, loading }) => {
              if ((!claims && !error) || loading) {
                return <Spinner className="spinner-positioning" />;
              }

              if (claims.length < 1 || error) {
                return (
                  <div className="mx-auto title-text">
                    <p className="pr-1 hok-text-dark">
                      <FormattedMessage id="userMyDamageClaims.noDamageClaimsFound" />
                    </p>
                  </div>
                );
              }

              return claims.map(claim => (
                <DamageClaimCard
                  damageClaim={claim}
                  key={claim.claimNo || claim.claimConfirmationCode}
                  redirectAction={redirectToSpecificDamageClaimPage}
                />
              ));
            }}
          </DamageClaimsSearch>
        </Col>
      </Row>
    </Container>
  );
};
