const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';
const LOCALE_KEY = 'lang';
const USER_LIST_FILTER = 'userListFilter';
const TRAVEL_INSURANCE_PACKAGE_KEY = 'travelInsurancePackage';
const TRAVEL_INSURANCE_COVER_KEY = 'travelInsuranceCover';
const HEALTH_INSURANCE_PACKAGE_KEY = 'healthInsurancePackage';
const RENEWAL_POLICY = 'renewal_policy_';
const ELIGIBILITY = 'eligibility_';
const TICKETS_FILTERS = 'ticketsFilters';
const TRANSACTIONS_FILTERS = 'transactionsFilters';
const USERS_FILTERS = 'usersFilters';
const EMPLOYEES_FILTERS = 'employeesFilters';
const GDPR_CONSENT = 'consent';

// User Token Management
export const getLocalStorageToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  return token;
};

export const setLocalStorageToken = token => {
  return localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const removeLocalStorageToken = () => {
  return localStorage.removeItem(AUTH_TOKEN_KEY);
};

export const getLocalStorageLocale = () => {
  const locale = localStorage.getItem(LOCALE_KEY);
  return locale;
};

export const setLocalStorageLocale = locale => {
  return localStorage.setItem(LOCALE_KEY, locale);
};

export const setLocalStorageGdprConsent = value => {
  return localStorage.setItem(GDPR_CONSENT, !!value);
};

export const getLocalStorageGdprConsent = () => {
  return localStorage.getItem(GDPR_CONSENT);
};

export const setLocalStorageUsersFilter = filter => {
  return localStorage.setItem(USER_LIST_FILTER, filter);
};

export const removeLocalStorageUsersFilter = () => {
  return localStorage.removeItem(USER_LIST_FILTER);
};

export const getLocalStorageUsersFilter = () => {
  const filter = localStorage.getItem(USER_LIST_FILTER);
  return filter;
};

export const setSessionStorageTravelInsurancePackage = packageKey => {
  sessionStorage.setItem(TRAVEL_INSURANCE_PACKAGE_KEY, packageKey);
};

export const removeSessionStorageTravelInsurancePackage = () => {
  sessionStorage.removeItem(TRAVEL_INSURANCE_PACKAGE_KEY);
};

export const getSessionStorageTravelInsurancePackage = () => {
  return sessionStorage.getItem(TRAVEL_INSURANCE_PACKAGE_KEY);
};

export const setSessionStorageTravelInsuranceCover = coverKey => {
  sessionStorage.setItem(TRAVEL_INSURANCE_COVER_KEY, coverKey);
};

export const removeSessionStorageTravelInsuranceCover = () => {
  sessionStorage.removeItem(TRAVEL_INSURANCE_COVER_KEY);
};

export const getSessionStorageTravelInsuranceCover = () => {
  return sessionStorage.getItem(TRAVEL_INSURANCE_COVER_KEY);
};

export const setSessionStorageHealthInsurancePackage = packageKey => {
  sessionStorage.setItem(HEALTH_INSURANCE_PACKAGE_KEY, packageKey);
};

export const removeSessionStorageHealthInsurancePackage = () => {
  sessionStorage.removeItem(HEALTH_INSURANCE_PACKAGE_KEY);
};

export const getSessionStorageHealthInsurancePackage = () => {
  return sessionStorage.getItem(HEALTH_INSURANCE_PACKAGE_KEY);
};

export const setSessionStorageRenewalPolicyNumber = (productCode, policyNumber) => {
  sessionStorage.setItem(`${RENEWAL_POLICY}${productCode}`, policyNumber);
};

export const removeSessionStorageRenewalPolicyNumber = productCode => {
  sessionStorage.removeItem(`${RENEWAL_POLICY}${productCode}`);
};

export const getSessionStorageRenewalPolicyNumber = productCode => {
  return sessionStorage.getItem(`${RENEWAL_POLICY}${productCode}`);
};

export const setSessionStoragePolicyEligibility = productCode => {
  sessionStorage.setItem(`${ELIGIBILITY}${productCode}`, true);
};

export const removeSessionStoragePolicyEligibility = productCode => {
  sessionStorage.removeItem(`${ELIGIBILITY}${productCode}`);
};

export const getSessionStoragePolicyEligibility = productCode => {
  return sessionStorage.getItem(`${ELIGIBILITY}${productCode}`);
};

export const setSessionStorageTicketsFilter = variables => {
  sessionStorage.setItem(TICKETS_FILTERS, JSON.stringify(variables));
};

export const removeSessionStorageTicketsFilters = () => {
  sessionStorage.removeItem(TICKETS_FILTERS);
};

export const getSessionStorageTicketsFilter = () => {
  const variables = sessionStorage.getItem(TICKETS_FILTERS);
  if (variables) {
    return JSON.parse(variables);
  }
  return variables;
};

export const setSessionStorageTransactionsFilter = variables => {
  sessionStorage.setItem(TRANSACTIONS_FILTERS, JSON.stringify(variables));
};

export const removeSessionStorageTransactionsFilters = () => {
  sessionStorage.removeItem(TRANSACTIONS_FILTERS);
};

export const getSessionStorageTransactionsFilter = () => {
  const variables = sessionStorage.getItem(TRANSACTIONS_FILTERS);
  if (variables) {
    return JSON.parse(variables);
  }
  return variables;
};

export const setSessionStorageEmployeesFilter = variables => {
  sessionStorage.setItem(EMPLOYEES_FILTERS, JSON.stringify(variables));
};

export const removeSessionStorageEmployeesFilters = () => {
  sessionStorage.removeItem(EMPLOYEES_FILTERS);
};

export const getSessionStorageEmployeesFilter = () => {
  const variables = sessionStorage.getItem(EMPLOYEES_FILTERS);
  if (variables) {
    return JSON.parse(variables);
  }
  return variables;
};

export const setSessionStorageUsersFilter = variables => {
  sessionStorage.setItem(USERS_FILTERS, JSON.stringify(variables));
};

export const removeSessionStorageUsersFilters = () => {
  sessionStorage.removeItem(USERS_FILTERS);
};

export const getSessionStorageUsersFilter = () => {
  const variables = sessionStorage.getItem(USERS_FILTERS);

  if (variables === 'null' || variables === 'undefined') {
    return null;
  }

  if (variables) {
    return JSON.parse(variables);
  }
  return variables;
};
