import React, { useState } from 'react';
import { Row, Col, Collapse, Fade } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash-es';
import { displayCurrencyOptions } from '../../../utils/currencyDisplayFormat';
import keyboardUpIcon from '../../../img/icons/hok-chevron-up.svg';
import keyboardDownIcon from '../../../img/icons/hok-chevron-down.svg';
import { RISK_HOK_AUTONEZGODA } from '../../../constants/business';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import { useSelector } from 'react-redux';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';

export const PackageDropdown = ({ currentPackage, productPackageId }) => {
  const { formatNumber } = useIntl();
  const [isPackageDropDownOpen, setIsPackageDropDownOpen] = useState(false);

  const togglePackageDropDown = () => {
    setIsPackageDropDownOpen(prevState => !prevState);
  };


  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  return (
    <Col sm="9" md="8" lg="6">
      <Row>
        <Col className="d-flex flex-row" sm="8" md="9" lg="8">
          <div className="hok-risks-checkbox-container cursor-pointer" onClick={togglePackageDropDown}>
            <div className="d-flex justify-content-between flex-wrap font-weight-bold">
              {currentPackage.name.toUpperCase()}
              <img alt="collapse-toggler" src={isPackageDropDownOpen ? keyboardUpIcon : keyboardDownIcon} />
            </div>
          </div>
        </Col>
        <Col className="d-flex justify-content-end" sm="4" md="3" lg="4">
          <div className="hok-price-box">{formatNumber(currentPackage.price, {style: 'currency',
                      currency: 'EUR',
                      currencySign: 'standard',
                      currencyDisplay: 'narrowSymbol',
                    })}
          </div>
        </Col>
        <Col xs="12">
          <Fade in={isPackageDropDownOpen}>
            <Collapse isOpen={isPackageDropDownOpen}>
              <Row>
                <Col className="d-flex flex-row" sm="8" md="9" lg="8">
                  <div className="hok-risks-checkbox-container">
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="insurance-package">
                        {get(currentPackage, 'risksDefinitions', []).map((riskDefinition, index) => (
                          <React.Fragment key={riskDefinition.risk}>
                            <p className="d-block text-center hok-text-dark">
                              <FormattedMessage id={`userMyPoliciesCarLiability.${riskDefinition.risk}`} />
                              <br />
                              {riskDefinition.risk === RISK_HOK_AUTONEZGODA.id &&
                                `(${riskDefinition.sum} kn, vozač i ${
                                  riskDefinition.numberOfTravelers
                                } putnika)`}
                            </p>
                            {index < currentPackage.risksDefinitions.length - 1 && <hr />}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Collapse>
          </Fade>
        </Col>
      </Row>
    </Col>
  );
};
