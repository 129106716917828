import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import { CustomButton } from '../../../containers';
import logoShort from '../../../img/logo-short.png';

export const RegisterTacModal = ({ isOpen, toggle, setTacAccepted, content }) => {
  return (
    <Modal className="hok-modal hok-modal-wide" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader>
        <Row className="no-gutters auth-nav-links">
          <Col xs={12}>
            <h2 className="d-flex align-items-center justify-content-center">
              <FormattedMessage id="acceptTacModal.title" />
            </h2>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row className="no-gutters pt-5 pb-3">
          <Col xs="12" sm={{ size: 10, offset: 1 }}>
            <div className="hok-fixed-register-tos-container">
              <Scrollbars
                height={450}
                renderTrackHorizontal={props => (
                  <div {...props} className="track-horizontal" style={{ display: 'none' }} />
                )}
                renderThumbHorizontal={props => (
                  <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
                )}>
                <div className="pr-2 pt-2" dangerouslySetInnerHTML={{ __html: content }} />
              </Scrollbars>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="justify-content-center flex-wrap">
        <CustomButton
          className="hok-outline-btn-wide mb-4"
          block={false}
          onClick={setTacAccepted}
          translationId="iAccept"
        />
        <div className="w-100 text-center">
          <img className="img-fluid" src={logoShort} alt="HOK logo" />
        </div>
      </ModalFooter>
    </Modal>
  );
};
