import React, { useState } from 'react';
import { Row, Col, CardGroup, Card, CardBody, Form, FormGroup, Label, InputGroup } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { CustomButton, CustomDashboardInput } from '../../../containers';
import { validateFormSubmission } from '../../../utils/validation';
import { userUpdateProfileAdvancedData } from '../../../redux/actions/userProfileActions';

export const ProfileAdvancePrivate = ({ user, dispatch, changeAdvanedDataInProgress }) => {
  // const [passportNumber, setPassportNumber] = useState({
  //   value: user.passportNumber || '',
  //   error: null,
  //   invalid: false,
  //   displayName: <FormattedMessage id="inputs.passportNumber" />,
  //   validation: {},
  // });
  // const [governmentIssuedIdNumber, setGovernmentIssuedIdNumber] = useState({
  //   value: user.governmentIssuedIdNumber || '',
  //   error: null,
  //   invalid: false,
  //   displayName: <FormattedMessage id="inputs.governmentIssuedIdNumber" />,
  //   validation: {},
  // });
  const [HZZONumber, setHZZONumber] = useState({
    value: user.HZZONumber || '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.HZZONumber" />,
    validation: {
      minLength: 9,
    },
  });
  // const [ino, setIno] = useState({
  //   value: user.ino || '',
  //   error: null,
  //   invalid: false,
  //   displayName: <FormattedMessage id="inputs.ino" />,
  //   validation: {},
  // });

  const handleAdvancedDataSubmit = async event => {
    event.preventDefault();

    const fieldsArray = [setHZZONumber /*setPassportNumber, setGovernmentIssuedIdNumber, setIno*/];

    if (await validateFormSubmission(fieldsArray)) {
      dispatch(
        userUpdateProfileAdvancedData({
          HZZONumber: HZZONumber.value.trim(),
          // passportNumber: passportNumber.value.trim(),
          // governmentIssuedIdNumber: governmentIssuedIdNumber.value.trim(),
          // ino: ino.value.trim(),
        })
      );
    }
  };

  return (
    <Form onSubmit={handleAdvancedDataSubmit}>
      <CardGroup>
        <Card className="hok-card">
          <CardBody>
            <Row className="my-4">
              <Col xs="12">
                <h3>
                  <FormattedMessage id="userProfilePage.tabsNav.additionalData" />
                </h3>
              </Col>
            </Row>
            {/* <FormGroup row>
              <Col sm="6">
                <Label for="passportNumber">
                  <FormattedMessage id="inputs.passportNumber" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="passportNumber"
                    name="passport number"
                    placeholder={true}
                    fieldState={passportNumber}
                    onChange={event => {
                      setPassportNumber({ ...passportNumber, invalid: false, value: event.target.value });
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm="6">
                <Label for="IdNumber">
                  <FormattedMessage id="inputs.governmentIssuedIdNumber" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="IdNumber"
                    name="government issued id number"
                    placeholder={true}
                    placeholderId="governmentIssuedIdNumber"
                    fieldState={governmentIssuedIdNumber}
                    onChange={event => {
                      setGovernmentIssuedIdNumber({
                        ...governmentIssuedIdNumber,
                        invalid: false,
                        value: event.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Col>
            </FormGroup> */}
            <FormGroup row>
              <Col sm="6">
                <Label for="HZZONumber">
                  <FormattedMessage id="inputs.HZZONumber" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="HZZONumber"
                    name="HZZO number"
                    placeholder={true}
                    fieldState={HZZONumber}
                    onChange={event => {
                      setHZZONumber({ ...HZZONumber, invalid: false, value: event.target.value });
                    }}
                  />
                </InputGroup>
              </Col>
              {/* <Col sm="6">
                <Label for="ino">
                  <FormattedMessage id="inputs.ino" />
                </Label>
                <InputGroup>
                  <CustomDashboardInput
                    id="ino"
                    placeholder={true}
                    fieldState={ino}
                    onChange={event => {
                      setIno({ ...ino, invalid: false, value: event.target.value });
                    }}
                  />
                </InputGroup>
              </Col> */}
            </FormGroup>
          </CardBody>
        </Card>
      </CardGroup>
      <Row className="no-gutters mt-5">
        <Col className="text-right" xs="12">
          <CustomButton
            className="hok-dashboard-btn"
            block={false}
            translationId="save"
            loaderProp={changeAdvanedDataInProgress}
          />
        </Col>
      </Row>
    </Form>
  );
};
