import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { ArrowLinkButton, ScreenTitleContainer, Spinner } from '../../../containers';
import { ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3 } from '../../../constants/routes';
import { SearchResultCard } from '../../../components/SearchResultCard';
import { handleRequestError } from '../../../constants/errorCodes';
import { GetServerStorageQuery } from '../../../operations/queries/GetServerStorageQuery';
import { getStepNumberAndId } from '../../../utils/manipulateSteps';
import { STEP_3, POLICY_CODE_PZ } from '../../../constants/business';

export const Step3InsuredPeople = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetServerStorageQuery, {
    variables: { stateKey: params.contractingId },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const stateKey = get(data, 'serverStorage.stateKey', null);
  const serverStep = get(data, 'serverStorage.state.step', null);
  const insuredPeople = get(data, 'serverStorage.state.insuredPeople', []);
  const type = get(data, 'serverStorage.state.type', '');

  const { activeStep } = getStepNumberAndId(serverStep, error, STEP_3, 4);

  if ((!stateKey && !loading) || (stateKey && activeStep < 2) || (stateKey && type !== POLICY_CODE_PZ)) {
    return <Redirect to={`${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3}/${stateKey}`} />;
  }

  const renderCards = () => {
    if (loading) {
      return (
        <Col>
          <Spinner className="spinner-positioning" />
        </Col>
      );
    } else {
      return insuredPeople.map((person, index) => (
        <Col xs="12" md="10" lg="8" xl="6" key={index}>
          <SearchResultCard
            fixedWidth={false}
            firstName={person.name}
            lastName={person.surename}
            dateOfBirth={person.dateOfBirth}
            disabled={true}
            fixedDoBWidth={false}
          />
        </Col>
      ));
    }
  };

  return (
    <Container fluid className="hok-container">
      <Row className="no-gutters">
        <Col>
          <ArrowLinkButton linkTo={`${ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE_STEP_3}/${stateKey}`} />
          <ScreenTitleContainer row className="mt-4" titleId="userTravelInsurancePage.insuredPeoplePage" />
          <Row className="no-gutters mx-3">
            <Col>
              <Row>{renderCards()}</Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
