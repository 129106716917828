import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, InputGroup, Input, FormFeedback } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { get, debounce } from 'lodash-es';
import { CustomButton, Spinner } from '../../../containers';
import { FormattedMessage, useIntl } from 'react-intl';
import { ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_2 } from '../../../constants/routes';
import { GetUserVehicleDataQuery } from '../../../operations/queries/GetUserVehicleDataQuery';
import {
  handleRequestError,
  getErrorObject,
  getSpecificError,
  DUPLICATE_VIN_DETECTED,
} from '../../../constants/errorCodes';
import { useLazyQuery, useMutation } from 'react-apollo';
import { POLICY_CODE_HE, STEP_2 } from '../../../constants/business';
import { GetExpressPriceQuery } from '../../../operations/queries/GetPriceQuery';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { validateFormSubmission } from '../../../utils/validation';
import { UserInteractionModal } from '../../../components/UserInteractionModal';
import { USER_ACCOUNT_PRIVATE } from '../../../constants/userDefs';
import { displayTotalPremium } from '../../../utils/currencyDisplayFormat';
import { FORMAT_STANDARD_DATE } from '../../../constants/dateFormatting';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';

export const Step1Express = React.memo(({ data, user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const getValidInsuranceStartDate = (fromStorage, fromRouteState) => {
    if (fromStorage && moment(fromStorage).isSameOrAfter(moment(), 'day')) {
      return moment(fromStorage);
    } else if (fromRouteState && moment(fromRouteState).isSameOrAfter(moment(), 'day')) {
      return moment(fromRouteState);
    } else {
      return moment();
    }
  };

  const [insuranceStartDate, setInsuranceStartDate] = useState({
    value: getValidInsuranceStartDate(
      get(data, 'insuranceStartDate', null),
      get(history, 'location.state.insuranceStartDate', null)
    ),
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.insuranceStartDate" />,
    validation: {
      required: true,
      date: {
        valid: true,
      },
    },
  });
  const [insuranceStartDateFocused, setInsuranceStartDateFocused] = useState(false);

  const [notEligible, setNotEligible] = useState(false);
  const [notEligibleReason, setNotEligibleReason] = useState('');

  const toggleEligibilityModal = () => {
    setNotEligible(currentState => !currentState);
  };

  const [regPlateInvalid, setRegPlateInvalid] = useState({ invalid: false, error: '' });
  const [vinNumberInvalid, setVinNumberInvalid] = useState({ invalid: false, error: '' });

  const vinNumberRef = useRef();
  const registrationPlateRef = useRef();

  const insuredPerson = {
    sector: user.sector,
    ...(user.sector === USER_ACCOUNT_PRIVATE
      ? { name: user.firstName, surename: user.lastName, dateOfBirth: user.dateOfBirth, sex: user.sex }
      : { name: user.businessName, surename: '' }),
    oib: user.oib,
    address: user.address,
    zip: user.zip,
    city: user.city,
    phoneNumber: user.phoneNumber,
    email: user.email,
  };

  const [submitForm, { loading: submitStep1ExpressLoading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data => history.push(`${ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_2}/${data.serverStorage.stateKey}`),
    onError: error => handleRequestError(error, dispatch),
  });

  const [getVehicleData, { loading: vehicleDataLoading, data: vehicleQueryData, updateQuery }] = useLazyQuery(
    GetUserVehicleDataQuery,
    {
      onCompleted: data => {
        const vinNumber = get(data, 'vehicleData.brojSasije');
        if (vinNumber) {
          vinNumberRef.current.value = vinNumber;
          registrationPlateRef.current.value = get(data, 'vehicleData.regOznaka');
          if (get(data, 'vehicleData.regOznaka') !== data.registrationPlate) {
            setRegPlateInvalid({ invalid: false, error: '' });
          }
        }
      },
      onError: error => handleRequestError(error, dispatch, { silent: true }),
    }
  );

  const vehicleData = get(vehicleQueryData, 'vehicleData', null);

  const [calculateHEPrice, { loading, data: priceData }] = useLazyQuery(GetExpressPriceQuery, {
    onCompleted: data => {
      const vinNumber = get(data, 'calculatePriceHokExpress.calculationsForVin');
      if (vinNumber) {
        vinNumberRef.current.value = vinNumber;
        setVinNumberInvalid({ invalid: false, error: '' });
        getVehicleData({ variables: { vinNumber } });
        if (!get(data, 'calculatePriceHokExpress.eligibleForProduct')) {
          const eligibleError = get(data, 'calculatePriceHokExpress.eligibleError.key', '');
          if (eligibleError) {
            const error = getSpecificError(eligibleError);
            setNotEligibleReason(error.message);
            setNotEligible(true);
          }
        }
      }
    },
    onError: error =>
      handleRequestError(error, dispatch, { silent: true }, () => {
        const queryError = getErrorObject(error);
        if (queryError && queryError.key === DUPLICATE_VIN_DETECTED) {
          setNotEligibleReason(queryError.message);
          setNotEligible(true);
        }
      }),
  });

  const delayRegistrationPlateOnChange = debounce(value => {
    calculateHEPrice({
      variables: {
        productRelatedData: {
          contractor: data.contractor || insuredPerson,
          insuredPerson: data.insuredPerson || insuredPerson,
          insuranceStartDate: insuranceStartDate.value,
          registrationPlate: value,
          vinNumber: '',
        },
      },
    });
  }, 500);

  const delayedVinNumberOnChange = debounce(value => {
    getVehicleData({ variables: { vinNumber: value } });
  }, 500);

  useEffect(() => {
    if (get(registrationPlateRef, 'current.value', '')) {
      calculateHEPrice({
        variables: {
          productRelatedData: {
            contractor: data.contractor || insuredPerson,
            insuredPerson: data.insuredPerson || insuredPerson,
            insuranceStartDate: insuranceStartDate.value,
            registrationPlate: get(registrationPlateRef, 'current.value', '').toUpperCase(),
            vinNumber: get(vinNumberRef, 'current.value', ''),
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceStartDate.value, get(registrationPlateRef, 'current.value', '')]);

  const handleStep1Submit = async event => {
    event.preventDefault();

    let isVinNumberValid = false;
    let isRegPlateValid = false;
    if (vinNumberRef.current.value) {
      if (get(vehicleData, 'brojSasije', '') === vinNumberRef.current.value) {
        isVinNumberValid = true;
      } else {
        setVinNumberInvalid({ invalid: true, error: 'Upišite valjani broj šasije' });
      }
    } else {
      setVinNumberInvalid({ invalid: true, error: 'Potrebno je upisati broj šasije' });
    }

    if (registrationPlateRef.current.value) {
      if (
        get(vehicleData, 'regOznaka', '') === registrationPlateRef.current.value ||
        (registrationPlateRef.current.value && vinNumberRef.current.value)
      ) {
        isRegPlateValid = true;
      } else {
        setRegPlateInvalid({ invalid: true, error: 'Upišite valjani broj registracijske tablice' });
      }
    } else {
      setRegPlateInvalid({ invalid: true, error: 'Potrebno je upisati broj registracijske tablice' });
    }

    if (
      (await validateFormSubmission([setInsuranceStartDate])) &&
      isRegPlateValid &&
      isVinNumberValid &&
      get(priceData, 'calculatePriceHokExpress.eligibleForProduct', false)
    ) {
      submitForm({
        variables: {
          attachToUser: true,
          state: {
            ...data,
            type: POLICY_CODE_HE,
            step: STEP_2,
            contractor: data.contractor || insuredPerson,
            insuredPerson: data.insuredPerson || insuredPerson,
            insuranceStartDate: insuranceStartDate.value,
            registrationPlate: registrationPlateRef.current.value,
            vinNumber: vinNumberRef.current.value,
            totalPremium: get(priceData, 'calculatePriceHokExpress.totalPremium', 0),
          },
        },
      });
    }
  };

  const resetAllVehicleInfo = () => {
    vinNumberRef.current.value = '';
    registrationPlateRef.current.value = '';
    if (vehicleData && typeof updateQuery === 'function') {
      updateQuery(() => {
        return { vehicleData: null };
      });
    }
  };

  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  return (
    <>
      <Form onSubmit={handleStep1Submit}>
        <FormGroup className="my-4" row>
          <Col xs="12" sm="6" lg="4">
            <Label for="insuranceStartDate">
              <FormattedMessage id="inputs.insuranceStartDate" />
            </Label>
            <InputGroup className={insuranceStartDate.invalid ? 'invalid-date' : ''}>
              <SingleDatePicker
                id="insuranceStartDate"
                readOnly
                hideKeyboardShortcutsPanel
                date={insuranceStartDate.value}
                onDateChange={date => setInsuranceStartDate({ ...insuranceStartDate, invalid: false, value: date })}
                showDefaultInputIcon
                firstDayOfWeek={1}
                numberOfMonths={1}
                small
                focused={insuranceStartDateFocused}
                onFocusChange={({ focused }) => setInsuranceStartDateFocused(focused)}
                displayFormat={FORMAT_STANDARD_DATE}
                placeholder={formatMessage({ id: 'inputs.insuranceStartDate' })}
                initialVisibleMonth={() => insuranceStartDate.value || moment()}
                isOutsideRange={date => date.isBefore(moment(), 'day') || date.isAfter(moment().add(30, 'days'), 'day')}
                renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                  <div className="d-flex justify-content-center">
                    <div className="mr-1">
                      <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                        {moment.months().map((label, value) => (
                          <option value={value} key={label}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              />
              {insuranceStartDate.invalid && insuranceStartDate.error && (
                <FormFeedback className="d-block">{insuranceStartDate.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
        </FormGroup>
        <Row className="mb-4">
          <Col>
            <h3>
              <FormattedMessage id="userMyPoliciesExpressInsurance.vehicleInfo" />:
            </h3>
          </Col>
        </Row>
        <Row>
          <Col sm="6" md="4">
            <Label for="licensePlate">
              <FormattedMessage id="inputs.licensePlate" />
            </Label>
            <InputGroup>
              <div className="w-100 position-relative">
                {(loading || vehicleDataLoading) && <Spinner className="input-spinner" size="sm" />}
                <Input
                  className="text-uppercase hok-input-loading"
                  id="licensePlate"
                  innerRef={registrationPlateRef}
                  defaultValue={data.registrationPlate || get(history, 'location.state.registrationPlate', '')}
                  invalid={regPlateInvalid.invalid}
                  onChange={event => {
                    const value = event.target.value;
                    const registrationPlate = value.toUpperCase();
                    delayRegistrationPlateOnChange(registrationPlate);
                    if (regPlateInvalid.invalid) {
                      setRegPlateInvalid({ invalid: false, error: '' });
                    }
                  }}
                />
              </div>
              {regPlateInvalid.invalid && regPlateInvalid.error && (
                <FormFeedback className="d-block">{regPlateInvalid.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
          <Col sm="6" md="4">
            <Label for="vin">
              <FormattedMessage id="inputs.vin" />
            </Label>
            <InputGroup>
              <div className="w-100 position-relative d-flex">
                {(loading || vehicleDataLoading) && <Spinner className="input-spinner" size="sm" />}
                <Input
                  className="text-uppercase"
                  id="vin"
                  innerRef={vinNumberRef}
                  defaultValue={data.vinNumber || get(history, 'location.state.vin', '')}
                  invalid={vinNumberInvalid.invalid}
                  onChange={event => {
                    const value = event.target.value;
                    const vinNumber = value.toUpperCase();
                    delayedVinNumberOnChange(vinNumber);
                    if (vinNumberInvalid.invalid) {
                      setVinNumberInvalid({ invalid: false, error: '' });
                    }
                  }}
                />
              </div>
              {vinNumberInvalid.invalid && vinNumberInvalid.error && (
                <FormFeedback className="d-block">{vinNumberInvalid.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
          <Col sm="6" md="4">
            <Label for="vehicleType">
              <FormattedMessage id="inputs.vehicleType" />
            </Label>
            <InputGroup>
              <Input
                className="text-uppercase"
                readOnly
                defaultValue={
                  get(vehicleData, 'vrstaEnumCode', '')
                    ? formatMessage({ id: `userMyPoliciesCarLiability.${vehicleData.vrstaEnumCode}` })
                    : ''
                }
              />
            </InputGroup>
          </Col>
          <Col sm="6" md="4">
            <Label for="vehicleMake">
              <FormattedMessage id="inputs.vehicleMake" />
            </Label>
            <InputGroup>
              <Input readOnly defaultValue={get(vehicleData, 'markaVozilaNaziv', '')} />
            </InputGroup>
          </Col>
          <Col sm="6" md="4">
            <Label for="vehicleModel">
              <FormattedMessage id="inputs.vehicleModel" />
            </Label>
            <InputGroup>
              <Input readOnly defaultValue={get(vehicleData, 'tipVozilaNaziv', '')} />
            </InputGroup>
          </Col>
          <Col sm="6" md="4">
            <Label for="vehicleYear">
              <FormattedMessage id="inputs.vehicleYear" />
            </Label>
            <InputGroup>
              <Input readOnly defaultValue={get(vehicleData, 'godinaProizvodnje', '')} />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <span className="primary">
              <FormattedMessage id="userMyPolicies.policyData.premium" />:
            </span>
            {currencyDataConfig.dualCurrencyDisplay === true && (
            <>
              <span className="secondary ml-3">
              {displayTotalPremium(
                get(priceData, 'calculatePriceHokExpress.totalPremium', undefined),
                get(data, 'totalPremium', undefined)
              )}{' '}
              |{' '}
              {currencyConversionEuro(
                (get(priceData, 'calculatePriceHokExpress.totalPremium', 0))*100,
                (get(data, 'totalPremium', 0))*100
              )}
              </span>
            </>)}

            {currencyDataConfig.dualCurrencyDisplay === false && (
            <>
              <span className="secondary ml-3">
              {displayTotalPremium(
                get(priceData, 'calculatePriceHokExpress.totalPremium', undefined),
                get(data, 'totalPremium', undefined)
              )}
              </span>
            </>
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CustomButton
              className="hok-dashboard-btn-wide float-sm-right"
              translationId="continue"
              disabled={
                !get(priceData, 'calculatePriceHokExpress.eligibleForProduct', false) ||
                loading ||
                vehicleDataLoading ||
                submitStep1ExpressLoading
              }
              loaderProp={loading || vehicleDataLoading || submitStep1ExpressLoading}
            />
          </Col>
        </Row>
      </Form>
      <UserInteractionModal
        isOpen={notEligible}
        toggle={() => {
          resetAllVehicleInfo();
          toggleEligibilityModal();
        }}
        titleId="modalHeaders.warning"
        textComponent={
          <Row className="no-gutters">
            <Col sm={{ size: 10, offset: 1 }}>
              <p className="mb-0 font-weight-bold text-center">Ugovaranje police nije moguće:</p>
              <p className="text-left">{notEligibleReason}</p>
            </Col>
          </Row>
        }
      >
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="back"
          onClick={() => {
            resetAllVehicleInfo();
            toggleEligibilityModal();
          }}
        />
      </UserInteractionModal>
    </>
  );
});
