import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { /*useIntl,*/ FormattedMessage } from 'react-intl';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import moment from 'moment';
import { ScreenTitleContainer, ArrowLinkButton, Spinner, DescriptionText } from '../../../containers';
import { ROUTE_TO_USER_ASSISTANCE_PAGE } from '../../../constants/routes';
import { GetUserAssistanceQuery } from '../../../operations/queries/GetUserPoliciesQuery';
import { assistanceGroup } from '../../../utils/policyGroupFromPath';
import { POLICY_STATUS_ACTIVE } from '../../../constants/business';
import { AssistanceCards } from './AssistanceCards';

export const GenericAssistancePage = () => {
  const params = useParams();
  const supportData = useSelector(state => state.support);
  // const { formatMessage } = useIntl();
  const [groupName, pageName] = assistanceGroup(params.assistanceType);
  const selectedAssistance = params.assistancePolicyId;

  const { data, loading, error } = useQuery(GetUserAssistanceQuery, {
    variables: { filter: { assistance: groupName, status: POLICY_STATUS_ACTIVE }, order: { date: 'DESC' } },
  });

  const policiesInGroup = get(data, 'getInsurancePolicy.insurancePoliciesGroupedByAssistance[0]', []);

  if (!groupName || (data && policiesInGroup.length < 1)) {
    return <Redirect to={ROUTE_TO_USER_ASSISTANCE_PAGE} />;
  }

  const assistance = get(policiesInGroup, 'insurancePolicies', []).sort((a, b) =>
    moment(a.insuranceEnd).isBefore(moment(b.insuranceEnd)) ? 1 : -1
  );

  return (
    <Container className="hok-container" fluid>
      <Row className="no-gutters">
        <Col>
          <ArrowLinkButton className="mb-4" linkTo={ROUTE_TO_USER_ASSISTANCE_PAGE} />
          <Row className="no-gutters mb-4">
            <ScreenTitleContainer titleId={`userAssistancePage.${pageName}`} />
          </Row>
          <Row className="no-gutters">
            <DescriptionText value={groupName} data={supportData} />
          </Row>
          <Row className="mt-3">
            {loading || (!data && !error) ? (
              <Col>
                <Spinner className="spinner-positioning" />
              </Col>
            ) : assistance.length < 1 ? (
              <Col className="hok-description-text">
                <p>
                  <FormattedMessage id="userAssistancePage.noAssistanceFound" />
                </p>
              </Col>
            ) : (
              <AssistanceCards
                assistance={assistance}
                assistanceGroup={groupName}
                assistancePolicyId={selectedAssistance}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
