import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { ScreenTitleContainer } from '../../../containers';
import { INFORMACIJE_O_OBRADI_PODATAKA, UVJETI_KORISTENJA } from '../../../constants/documentTypes';
import { ReactComponent as HokDownloadIcon } from '../../../img/icons/hok-download.svg';
import { get } from 'lodash-es';
import { TermsOfServiceModal } from './TermsOfServiceModal';
import { useQuery } from 'react-apollo';
import { GetLegalDocumentsQuery } from '../../../operations/queries/GetLegalDocumentsQuery';
import { IOPModal } from './IOPModal';

export const TermsOfServicePrivatePage = () => {
  const [isTosModalOpen, setIsTosModalOpen] = useState(false);
  const [isIopModalOpen, setIsIopModalOpen] = useState(false);
  const LegalDocumentsQuery1 = useQuery(GetLegalDocumentsQuery, {
    variables: { filter: { documentId: UVJETI_KORISTENJA, active: true } },
  });
  const LegalDocumentsQuery2 = useQuery(GetLegalDocumentsQuery, {
    variables: { filter: { documentId: INFORMACIJE_O_OBRADI_PODATAKA, active: true } },
  });
  const legalDocument1 = get(LegalDocumentsQuery1, 'data.getLegalDocuments.documents[0]', null);
  const legalDocument2 = get(LegalDocumentsQuery2, 'data.getLegalDocuments.documents[0]', null);

  if (LegalDocumentsQuery1.loading || (!LegalDocumentsQuery1.data && !LegalDocumentsQuery1.error)) {
    // return <Spinner className="spinner-positioning" />;
  }

  if (LegalDocumentsQuery1.error || !legalDocument1) {
    return null;
  }

  const toggleTosModal = event => {
    setIsTosModalOpen(!isTosModalOpen);
  };

  const toggleIopModal = event => {
    setIsIopModalOpen(!isIopModalOpen);
  };

  return (
    <Container className="hok-container-wide" fluid>
      <ScreenTitleContainer row className="mt-4" titleId="routes.termsOfService" />
      {(() => {
        if (legalDocument1 && legalDocument2) {
          return (
            <Row className="mx-3">
              <Col className="d-flex align-items-center mb-3" sm="12" key={1}>
                <p
                  className="hok-document-link-content"
                  style={{ cursor: 'pointer', color: '#ed1d24', textDecoration: 'none' }}
                  onClick={toggleTosModal}
                >
                  <HokDownloadIcon className="svg-red ml-auto" height="24" width="24" /> &nbsp;{' '}
                  <strong>Uvjeti korištenja</strong>
                </p>
              </Col>

              <Col className="d-flex align-items-center mb-3" sm="12" key={2}>
                <p
                  className="hok-document-link-content"
                  style={{ cursor: 'pointer', color: '#ed1d24' }}
                  onClick={toggleIopModal}
                >
                  <HokDownloadIcon height="24" width="24" /> &nbsp;&nbsp;
                  <strong>Informacije o obradi podataka</strong>
                </p>
              </Col>
            </Row>
          );
        }
      })()}
      <TermsOfServiceModal
        isOpen={isTosModalOpen}
        toggle={toggleTosModal}
        setTacAccepted={() => {
          toggleTosModal();
        }}
        content={legalDocument1 ? legalDocument1.content : null}
      />
      <IOPModal
        isOpen={isIopModalOpen}
        toggle={toggleIopModal}
        setTacAccepted={() => {
          toggleIopModal();
        }}
        content={legalDocument2 ? legalDocument2.link : null}
      />
    </Container>
  );
};
