import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Label, Form, CustomInput, InputGroup } from 'reactstrap';
import Select from 'react-select';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { get } from 'lodash-es';
import { useQuery } from 'react-apollo';
import { handleRequestError } from '../../../constants/errorCodes';
import { CustomDashboardInput, CustomButton } from '../../../containers';
import { GetUsersClaimsQuery } from '../../../operations/queries/GetUserClaimsQuery';
import { Search } from '../../../components/Search';
import { yearListRender, FORMAT_STANDARD_DATE } from '../../../constants/dateFormatting';
import { validateFormSubmission } from '../../../utils/validation';
import { GetUserClaimsDefinitionsQuery } from '../../../operations/queries/GetUserClaimsDefinitionsQuery';

export const DamageClaimsSearch = ({ children, userId = undefined }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [damageClaimType, setDamageClaimType] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.damageClaimType" />,
    validation: {
      required: false,
    },
  });

  const [damageClaimNumber, setDamageClaimNumber] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.damageClaimNumber" />,
    validation: {
      required: false,
    },
  });

  const [partnerRole, setPartnerRole] = useState({
    value: [],
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.partnerRole" />,
    validation: {},
  });

  const [fromDate, setFromDate] = useState({
    value: null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.fromDate" />,
    validation: {
      required: false,
    },
  });

  const [damageClaimStatusReported, setDamageClaimStatusReported] = useState({
    value: false,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.damageClaimStatus" />,
    validation: {},
  });

  const [damageClaimStatusInProgress, setDamageClaimStatusInProgress] = useState({
    value: false,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.damageClaimStatus" />,
    validation: {},
  });

  const [damageClaimStatusSolved, setDamageClaimStatusSolved] = useState({
    value: false,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.damageClaimStatus" />,
    validation: {},
  });

  const [fromDateFocused, setFromDateFocused] = useState(false);

  const { data: claimTypesData, loading: loadingTypes } = useQuery(GetUserClaimsDefinitionsQuery, {
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const { data, loading, error, fetchMore } = useQuery(GetUsersClaimsQuery, {
    variables: { ...(userId && { userId: `${userId}` }), order: { createdAt: 'DESC' } },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    notifyOnNetworkStatusChange: true,
  });

  const claimTypes = get(claimTypesData, 'getClaimsDefinition.claims', []);
  const claims = get(data, 'getClaims.claims', []);
  const claimsTotalCount = get(data, 'getClaims.totalCount', 0);

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false, error: null }));
  };

  const roleOptions = [
    { value: 'OS', label: formatMessage({ id: 'userMyDamageClaims.filter.allRoles' }) },
    { value: 'O', label: formatMessage({ id: 'userMyDamageClaims.filter.insuredPerson' }) },
    { value: 'S', label: formatMessage({ id: 'userMyDamageClaims.filter.thirdParty' }) },
  ];

  const applyDamageClaimsFilter = async event => {
    event.preventDefault();

    const fieldsArray = [
      setDamageClaimType,
      setDamageClaimNumber,
      setPartnerRole,
      setFromDate,
      setDamageClaimStatusReported,
      setDamageClaimStatusInProgress,
      setDamageClaimStatusSolved,
    ];

    if (await validateFormSubmission(fieldsArray)) {
      const userFriendlyStatusOr = [];
      damageClaimStatusReported.value &&
        userFriendlyStatusOr.push(formatMessage({ id: 'userMyDamageClaims.filter.statusReported' }));
      damageClaimStatusInProgress.value &&
        userFriendlyStatusOr.push(formatMessage({ id: 'userMyDamageClaims.filter.statusInProgress' }));
      damageClaimStatusSolved.value &&
        userFriendlyStatusOr.push(formatMessage({ id: 'userMyDamageClaims.filter.statusSolved' }));

      // Workaround for refetch() not updating retured data when initial query has
      // variables and onError props.
      // Setting fetchPolicy to "network-only" or "cache-and-network" causes 2 request
      // being fired where the latter has the initial query variables and overrides the refetch()
      // https://github.com/apollographql/react-apollo/issues/1929
      // https://github.com/apollographql/apollo-client/issues/3573
      fetchMore({
        variables: {
          ...(userId && { userId: `${userId}` }),
          filter: {
            ...(damageClaimType.value && { claimClass: damageClaimType.value }),
            ...(damageClaimNumber.value && { claimNo: damageClaimNumber.value }),
            roleOr: partnerRole.value,
            ...(fromDate.value && { dateOfReportLessThanOrEqual: fromDate.value }),
            userFriendlyStatusOr,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getClaims: {
              ...fetchMoreResult.getClaims,
            },
          };
        },
      });
    }
  };

  return (
    <>
      {claimsTotalCount > 0 && (
        <Search>
          <Form onSubmit={applyDamageClaimsFilter}>
            <Row>
              <Col md="4">
                <InputGroup>
                  <Label for="damageClaimType">
                    <FormattedMessage id="inputs.damageClaimType" />
                  </Label>
                  <div className="hok-select">
                    <CustomDashboardInput
                      id="damageClaimType"
                      type="select"
                      fieldState={damageClaimType}
                      onChange={event => inputHandler(setDamageClaimType, event.target.value)}>
                      <option value="">{formatMessage({ id: 'userMyDamageClaims.filter.allClaimTypes' })}</option>
                      {loadingTypes && (
                        <option disabled value="">
                          {formatMessage({ id: 'loading' })}
                        </option>
                      )}
                      {claimTypes.length > 0 &&
                        claimTypes.map(type => (
                          <option key={type.id} value={type.code}>
                            {type.book}
                          </option>
                        ))}
                    </CustomDashboardInput>
                  </div>
                </InputGroup>
              </Col>
              <Col md="4">
                <InputGroup>
                  <Label for="damageClaimNumber">
                    <FormattedMessage id="inputs.damageClaimNumber" />
                  </Label>
                  <CustomDashboardInput
                    id="damageClaimNumber"
                    placeholder={true}
                    fieldState={damageClaimNumber}
                    onChange={event => inputHandler(setDamageClaimNumber, event.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col md="4">
                <InputGroup>
                  <Label for="fromDate">
                    <FormattedMessage id="inputs.fromDate" />
                  </Label>
                  <SingleDatePicker
                    id="fromDate"
                    readOnly
                    hideKeyboardShortcutsPanel
                    date={fromDate.value}
                    onDateChange={date => setFromDate({ ...fromDate, invalid: false, value: date })}
                    showDefaultInputIcon
                    firstDayOfWeek={1}
                    numberOfMonths={1}
                    small
                    focused={fromDateFocused}
                    onFocusChange={({ focused }) => setFromDateFocused(focused)}
                    displayFormat={FORMAT_STANDARD_DATE}
                    placeholder={formatMessage({ id: 'inputs.select' })}
                    isOutsideRange={date => date.isAfter(moment(), 'day')}
                    renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                      <div className="d-flex justify-content-center">
                        <div className="mr-1">
                          <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                            {moment.months().map((label, value) => (
                              <option value={value} key={label}>
                                {label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="ml-1">
                          <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                            {yearListRender(
                              moment().format('YYYY'),
                              moment()
                                .add(2, 'year')
                                .format('YYYY')
                            )}
                          </select>
                        </div>
                      </div>
                    )}
                  />
                </InputGroup>
              </Col>
              <Col className="mb-3" md="4">
                <Label for="partnerRole">
                  <FormattedMessage id="inputs.partnerRole" />
                </Label>
                <Select
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minHeight: 50,
                      borderColor: state.isFocused ? '#f69396' : '#cbccce',
                      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(237, 29, 36, 0.25)' : 'none',
                      '&:hover': {
                        borderColor: state.isFocused ? '#f69396' : '#cbccce',
                      },
                    }),
                    placeholder: () => ({
                      fontSize: '16px',
                      color: '#707070',
                      opacity: '0.7',
                    }),
                    group: () => ({
                      minHeight: 50,
                    }),
                    indicatorContainer: () => ({
                      minHeight: 50,
                    }),
                    option: provided => ({
                      ...provided,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: '#f0f3f5',
                      },
                      '&:active': {
                        backgroundColor: '#f0f3f5',
                      },
                    }),
                  }}
                  placeholder={formatMessage({ id: 'inputs.select' })}
                  isMulti
                  isClearable={false}
                  className="hok-multi-select"
                  id="partnerRole"
                  options={roleOptions}
                  onChange={values => inputHandler(setPartnerRole, values ? values.map(entry => entry.value) : [])}
                />
              </Col>
              <Col className="mb-3 mb-md-0" md="8">
                <Label for="damageClaimStatus">
                  <FormattedMessage id="inputs.damageClaimStatus" />
                </Label>
                <div className="d-flex flex-wrap mt-2 mb-3">
                  <CustomInput
                    className="hok-tac-checkbox hok-claim-status-checkbox mr-4"
                    id="reported"
                    type="checkbox"
                    checked={damageClaimStatusReported.value}
                    invalid={damageClaimStatusReported.invalid}
                    onChange={event => inputHandler(setDamageClaimStatusReported, event.target.checked)}
                    label={formatMessage({ id: 'userMyDamageClaims.filter.statusReported' })}
                  />
                  <CustomInput
                    className="hok-tac-checkbox hok-claim-status-checkbox mr-4"
                    id="inProgress"
                    type="checkbox"
                    checked={damageClaimStatusInProgress.value}
                    invalid={damageClaimStatusInProgress.invalid}
                    onChange={event => inputHandler(setDamageClaimStatusInProgress, event.target.checked)}
                    label={formatMessage({ id: 'userMyDamageClaims.filter.statusInProgress' })}
                  />
                  <CustomInput
                    className="hok-tac-checkbox hok-claim-status-checkbox"
                    id="solved"
                    type="checkbox"
                    checked={damageClaimStatusSolved.value}
                    invalid={damageClaimStatusSolved.invalid}
                    onChange={event => inputHandler(setDamageClaimStatusSolved, event.target.checked)}
                    label={formatMessage({ id: 'userMyDamageClaims.filter.statusSolved' })}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-md-3">
              <Col md={{ size: 4, offset: 8 }}>
                <CustomButton block className="hok-dashboard-btn w-100" translationId="search" />
              </Col>
            </Row>
          </Form>
        </Search>
      )}
      <Row className="no-gutters mt-4">
        <Col>{children({ data: claims, error, loading })}</Col>
      </Row>
    </>
  );
};
