export const ADMIN_REQUEST_LOGIN = 'ADMIN_REQUEST_LOGIN';
export const requestAdminLogin = payload => ({
  type: ADMIN_REQUEST_LOGIN,
  payload: payload,
});

export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const authAttemptAdminLogin = payload => ({
  type: ADMIN_LOGIN,
  payload: payload,
});

export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const adminLoginSuccess = payload => ({
  type: ADMIN_LOGIN_SUCCESS,
  payload: payload,
});

export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';
export const adminLoginFailure = payload => ({
  type: ADMIN_LOGIN_FAILURE,
  payload: payload,
});

export const ADMIN_AUTH_LOGOUT = 'ADMIN_AUTH_LOGOUT';
export const authAdminLogout = () => ({
  type: ADMIN_AUTH_LOGOUT,
});

export const AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED';
export const authTokenExpired = payload => {
  return {
    type: AUTH_TOKEN_EXPIRED,
    payload: payload,
  };
};

export const ADMIN_AUTH_TOKEN_REFRESH = 'ADMIN_AUTH_TOKEN_REFRESH';
export const authTokenRefresh = payload => ({
  type: ADMIN_AUTH_TOKEN_REFRESH,
  payload: payload,
});

export const ADMIN_TOKEN_REFRESH_SUCCESS = 'ADMIN_TOKEN_REFRESH_SUCCESS';
export const tokenRefreshSuccess = payload => ({
  type: ADMIN_TOKEN_REFRESH_SUCCESS,
  payload: payload,
});

export const ADMIN_CLEAR_ERROR_MESSAGE = 'ADMIN_CLEAR_ERROR_MESSAGE';
export const clearAdminErrorMessage = payload => ({
  type: ADMIN_CLEAR_ERROR_MESSAGE,
  payload: payload,
});
