import { STORE_SUPPORT_DATA } from '../actions/supportActions';

const initialState = {
  name: '',
  address: '',
  oib: '',
  tradeCourtText: '',
  email: '',
  phoneNumber: '',
  faxNumber: '',
  claimReportPhoneNumber: '',
  claimReportLegacyWebUrl: '',
  HR_ASSISTANCE_phoneNumber: '',
  HR_ASSISTANCE_phoneNumber_from_hr: '',
  HE_ASSISTANCE_phoneNumber: '',
  HE_ASSISTANCE_phoneNumber_from_hr: '',
  HE_ASSISTANCE_AXA_phoneNumber: '',
  HR_RENT_A_CAR_ASSISTANCE_phoneNumber: '',
  HR_RENT_A_CAR_ASSISTANCE_phoneNumber_from_hr: '',
  PZO_ASSISTANCE_phoneNumber: '',
  PZO_ASSISTANCE_phoneNumber_from_hr: '',
  KA_ASSISTANCE_phoneNumber: '',
  KA_ASSISTANCE_phoneNumber_from_hr: '',
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SUPPORT_DATA:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default supportReducer;
