import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';

import enData from '../locales/en';
import hrData from '../locales/hr';

// list of available languages
export const enabledLanguages = [/*'en',*/ 'hr'];

export const localizationData = {};

if (typeof Intl.NumberFormat.__addLocaleData === 'function') {
  Intl.NumberFormat.__addLocaleData(
    require('@formatjs/intl-numberformat/dist/locale-data/hr') // locale-data for hr
  );
  // Intl.NumberFormat.__addLocaleData(
  //   require('@formatjs/intl-numberformat/dist/locale-data/en.json') // locale-data for en
  // );
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/hr');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/hr');
}

if (!Intl.DisplayNames) {
  require('@formatjs/intl-displaynames/polyfill');
  require('@formatjs/intl-displaynames/dist/locale-data/hr'); // Add locale data for de
}

// use this to allow nested messages, taken from docs:
// https://github.com/yahoo/react-intl/wiki/Upgrade-Guide#flatten-messages-object
function flattenMessages(nestedMessages = {}, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value; // eslint-disable-line no-param-reassign
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

// addLocaleData(en);
localizationData.en = enData;
localizationData.en.messages = flattenMessages(localizationData.en.messages);

// addLocaleData(hr);
localizationData.hr = hrData;
localizationData.hr.messages = flattenMessages(localizationData.hr.messages);
