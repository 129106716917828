import React from 'react';
import { Col, CustomInput } from 'reactstrap';
import { ReactComponent as HokInfoIcon } from '../../../img/icons/hok-info.svg';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import { displayTotalPremium } from '../../../utils/currencyDisplayFormat';
import { FormattedNumber } from 'react-intl';

export const RiskCheckbox = React.memo(
  ({ risk, toggleInfoModal, setModalContent, checked, disabled, onChange, price }) => {
    return (
      <Col className="d-flex flex-row" xs="12" key={risk.id}>
        <div className="hok-risks-checkbox-container">
          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              className="hok-tac-checkbox"
              id={risk.id}
              type="checkbox"
              checked={checked}
              disabled={disabled}
              onChange={event => (!disabled ? onChange(event) : undefined)}
              label={
                <span className="font-weight-bold text-left ml-3">
                  {risk.name}
                  <HokInfoIcon
                    className={'svg-red svg-pad-bottom-half ml-3' + (!disabled ? ' cursor-pointer' : '')}
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      if (!disabled) {
                        setModalContent(risk.id);
                        toggleInfoModal();
                      }
                    }}
                  />
                </span>
              }
            />
            {price && (
              <div className="hok-risk-price">
                <FormattedNumber
                  value={price}
                  {...{
                    style: 'currency',
                    currency: 'EUR',
                    currencySign: 'standard',
                    currencyDisplay: 'narrowSymbol',
                  }}
                />
                {/* {displayTotalPremium(price,price)} 
              | {currencyConversionEuro(price, price, 7.5345)} */}
              </div>
            )}
          </div>
          <hr className="w-100" />
        </div>
      </Col>
    );
  }
);
