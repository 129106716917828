import React from 'react';
import { Card, CardHeader } from 'reactstrap';
import { useIntl } from 'react-intl';
import { TICKET_ACTION_STATUS_ACTION_REQUIRED } from '../../constants/ticketing';

//Images
import { ReactComponent as HokErrorIcon } from '../../img/icons/hok-error.svg';
import keyboardRightIcon from '../../img/icons/hok-chevron-right.svg';
import { useHistory } from 'react-router-dom';
import { ROUTE_TO_USER_TICKETING_PAGE } from '../../constants/routes';

export const TicketCard = ({ ticket }) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const actionRequired = ticket.actionStatus && ticket.actionStatus === TICKET_ACTION_STATUS_ACTION_REQUIRED;

  const renderStatus = className => (
    <p className={className}>
      <span>{formatMessage({ id: 'inputs.status' })}:</span>{' '}
      <span className="font-weight-bold text-uppercase">
        {formatMessage({ id: `userMyTickets.ticketCardStatus.${ticket.status}` })}
      </span>
    </p>
  );

  return (
    <Card className="insurance-policy-container">
      <CardHeader
        className="hok-ticket-card-header cursor-pointer"
        onClick={() => history.push(`${ROUTE_TO_USER_TICKETING_PAGE}/${ticket.number}`)}>
        <div className="d-flex align-items-center">
          <h4 className="main-title mr-sm-4">
            <span className="hok-ticket-number">
              {'#'}
              {ticket.number || ticket.id}
            </span>
            <span className="hok-ticket-title subtitle-text ml-sm-2 ml-md-4">{ticket.subject}</span>
          </h4>
          {renderStatus('desktop-container subtitle-text')}
          {actionRequired && (
            <HokErrorIcon className="d-none d-md-block ml-auto mr-4 desktop-container svg-product-card svg-red" />
          )}
        </div>
        {actionRequired && <HokErrorIcon className="hok-ticket-mobile-warning svg-product-card svg-red" />}
        <img
          alt="collapse-toggler"
          src={keyboardRightIcon}
          className={'collapse-toggler' + (actionRequired ? '' : ' ml-auto')}
        />
      </CardHeader>
    </Card>
  );
};
