import gql from 'graphql-tag';

export const SearchForExistInHokMutation = gql`
  mutation SearchForExistInHok($partnerId: String) {
    searchProfileForExistingHokAccount(partnerId: $partnerId) {
      count
      multipleMatch
      partners {
        IME
        PREZIME
        partnerId
        adresa
        naziv
        dat_1kont
        jmbg
      }
    }
  }
`;
