import gql from 'graphql-tag';

export const AdminUpdateUserEmailMutation = gql`
  mutation AdminUpdateUserEmail($id: Int!, $email: String!, $phoneNumber: String!) {
    updateUser(id: $id, email: $email, phoneNumber: $phoneNumber) {
      id
    }
  }
`;

export const AdminUpdateUserPhoneNumberMutation = gql`
  mutation AdminUpdateUserPhoneNumber($id: Int!, $email: String!, $phoneNumber: String!) {
    updateUser(id: $id, email: $email, phoneNumber: $phoneNumber) {
      id
    }
  }
`;

export const AdminVerifyUserMutation = gql`
  mutation AdminVerifyUser($userId: String!, $verificationComment: String) {
    verifyUser(userId: $userId, verificationComment: $verificationComment) {
      id
      verified
      verificationComment
    }
  }
`;

export const AdminUpdateUserActiveStatusMutation = gql`
  mutation AdminUpdateUserActiveStatus(
    $id: Int!
    $accountLocked: Boolean
    $email: String!
    $phoneNumber: String!
    $activated: Boolean
  ) {
    updateUser(
      id: $id
      email: $email
      phoneNumber: $phoneNumber
      accountLocked: $accountLocked
      activated: $activated
    ) {
      id
    }
  }
`;

export const AdminUpdateUserRoleMutation = gql`
  mutation AdminUpdateUserRole($id: Int!, $email: String!, $phoneNumber: String!, $role: String) {
    updateUser(id: $id, email: $email, phoneNumber: $phoneNumber, role: $role) {
      id
    }
  }
`;

export const AdminUpdateUserPersonalInfoMutation = gql`
  mutation AdminUpdateUserPersonalInfo(
    $id: Int!
    $email: String!
    $phoneNumber: String!
    $password: String
    $sex: userSexesTypeEnum
    $sector: userSectorsTypeEnum
    $firstName: String
    $lastName: String
    $oib: String
    $dateOfBirth: Date
    $address: String
    $city: String
    $zip: String
    $passportNumber: String
    $HZZONumber: String
    $ino: String
    $governmentIssuedIdNumber: String
    $verified: Boolean
    $verificationComment: String
    $passwordExpires: Date
    $role: String
    $mbo: String
    $VATpayer: Boolean
    $country: String
    $businessName: String
    $businessFirstName: String
    $businessLastName: String
    $businessTelephone: String
    $webAddress: String
  ) {
    updateUser(
      id: $id
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      sex: $sex
      sector: $sector
      firstName: $firstName
      lastName: $lastName
      oib: $oib
      dateOfBirth: $dateOfBirth
      address: $address
      city: $city
      zip: $zip
      passportNumber: $passportNumber
      HZZONumber: $HZZONumber
      ino: $ino
      governmentIssuedIdNumber: $governmentIssuedIdNumber
      verified: $verified
      verificationComment: $verificationComment
      passwordExpires: $passwordExpires
      role: $role
      mbo: $mbo
      VATpayer: $VATpayer
      country: $country
      businessName: $businessName
      businessFirstName: $businessFirstName
      businessLastName: $businessLastName
      businessTelephone: $businessTelephone
      webAddress: $webAddress
    ) {
      id
    }
  }
`;
