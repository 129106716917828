import React from 'react';
// import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Stepper } from 'react-form-stepper';
import ArrowLinkButton from '../../../../containers/ArrowLinkButton';
import { ROUTE_TO_USER_REPORT_DAMAGE } from '../../../../constants/routes';

export const StepperWrapper = ({
  cancelButton = true,
  cancelLink = ROUTE_TO_USER_REPORT_DAMAGE,
  title = 'locale',
  translationBase,
  children,
  steps = [{}, {}],
  activeStep = 0,
}) => {
  return (
    <Container className="hok-container label-red" fluid>
      <Row className="no-gutters">
        <Col>
          {cancelButton && <ArrowLinkButton text="buttons.giveUp" linkTo={cancelLink} />}
          <Row className="mt-4 ml-0">
            <h3 className="mr-2">
              <FormattedMessage id={`${translationBase}.title`} />
            </h3>
          </Row>
          <Row>
            <Col>
              <Row className="mt-3">
                <Col>
                  <Card>
                    <div className="card-header">
                      <Row>
                        <Col className="mt-4" sm={{ size: 6, offset: 3 }}>
                          <Stepper
                            steps={steps}
                            activeStep={activeStep}
                            styleConfig={{ size: '36px' }}
                            hideConnectors
                          />
                        </Col>
                      </Row>
                      {title && (
                        <Row className="mt-4 mb-2 ml-1">
                          <h3>
                            <FormattedMessage id={`userReportDamagePage.${title}`} />:
                          </h3>
                        </Row>
                      )}
                    </div>
                    <CardBody>{children}</CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
