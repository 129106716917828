import gql from 'graphql-tag';

export const UserInitiateNewPaymentMutation = gql`
  mutation UserInitiateNewPayment(
    $paymentType: paymentTypeEnum
    $invoiceNumber: String
    $insurancePolicyNumber: String
  ) {
    initNewPayment(
      paymentType: $paymentType
      invoiceNumber: $invoiceNumber
      insurancePolicyNumber: $insurancePolicyNumber
      shop: APPLICATION_TYPE_WEB
    ) {
      confirmationCode
    }
  }
`;
