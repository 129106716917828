import gql from 'graphql-tag';

export const GetUserNotificationsQuery = gql`
  query GetUserNotifications {
    notifications {
      countNew
      notifications {
        id
        subject
        content
        link
        seen
        createdAt
      }
    }
  }
`;
