import React, { useState, useEffect } from 'react';
import { Col, Label, InputGroup } from 'reactstrap';
import { CustomDashboardInput } from '../../containers';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'lodash-es';
import { v4 } from 'uuid';

export const PlaceAndZipInput = ({
  sizes = { sm: '6' },
  places,
  loadingPlaces,
  zip,
  setZip,
  city,
  setCity,
  disabled,
  formSubmitted = true,
  placeLabel = 'inputs.place',
  zipLabel = 'inputs.zipCode',
}) => {
  const { formatMessage } = useIntl();
  const [filteredPlaces, setFilteredPlaces] = useState([...places]);

  useEffect(() => {
    if (places.length > 0) {
      setFilteredPlaces([...places]);
    }
  }, [places]);

  const debouncedPlacesFilter = debounce(value => {
    const matchingPlaces = places.filter(place => place.pttNumber.startsWith(value));
    setFilteredPlaces(value ? matchingPlaces : [...places]);
    if (matchingPlaces.length === 1 && matchingPlaces[0].pttNumber === value) {
      setCity(city => ({ ...city, invalid: false, value: matchingPlaces[0].name }));
    } else {
      setCity(city => ({ ...city, invalid: false, value: '' }));
    }
  }, 500);

  return (
    <>
      <Col {...sizes}>
        <Label for="zipCode">
          <FormattedMessage id={zipLabel} />
        </Label>
        <InputGroup>
          <CustomDashboardInput
            className="input-uppercase"
            id="zipCode"
            disabled={disabled}
            formSubmitted={formSubmitted}
            fieldState={zip}
            value={zip.value}
            onChange={event => {
              event.persist();
              debouncedPlacesFilter(event.target.value);
              setZip(zip => ({ ...zip, invalid: false, value: event.target.value }));
            }}
          />
        </InputGroup>
      </Col>
      <Col {...sizes}>
        <Label for="city">
          <FormattedMessage id={placeLabel} />
        </Label>
        <InputGroup>
          <div className="hok-select">
            <CustomDashboardInput
              id="city"
              type="select"
              disabled={disabled}
              formSubmitted={formSubmitted}
              fieldState={city}
              value={city.value}
              onChange={event => {
                const place = places.find(place => place.name === event.target.value);
                setCity({ ...city, invalid: false, value: event.target.value });
                place && setZip({ ...zip, invalid: false, value: place.pttNumber });
              }}
            >
              <option disabled value="">
                {formatMessage({
                  id: 'inputs.labels.selectPlace',
                })}
              </option>
              {loadingPlaces ? (
                <option disabled value={city.value}>
                  {city.value}
                </option>
              ) : (
                filteredPlaces.map(place => (
                  <option key={v4()} value={place.name}>
                    {place.name}
                  </option>
                ))
              )}
            </CustomDashboardInput>
          </div>
        </InputGroup>
      </Col>
    </>
  );
};
