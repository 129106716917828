import React from 'react';
import { Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash-es';
import { PolicyContracting } from '../../../containers/PolicyContracting';
import { STEP_1, POLICY_CODE_AO } from '../../../constants/business';
import { Step4AO } from './Step4AO';
import { Step3AO } from './Step3AO';
import { Step2AO } from './Step2AO';
import { Step1AO } from './Step1AO';
import { StepperWrapper } from '../../../containers';
import { getStepNumberAndId } from '../../../utils/manipulateSteps';
import {
  ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE,
  ROUTE_TO_USER_NEW_AO_POLICY_PAGE,
  ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_2,
  ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_3,
  ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_4,
} from '../../../constants/routes';
import { GetServerStorageQuery } from '../../../operations/queries/GetServerStorageQuery';
import { getSessionStoragePolicyEligibility } from '../../../constants/LocalStorageKeys';

const AOStepRoute = ({ activeStep, stepIndex, children, path, stateKey }) => {
  const renderStep = stepIndex => {
    switch (stepIndex) {
      case 2:
        return `${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_3}/${stateKey}`;
      case 1:
        return `${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_2}/${stateKey}`;
      case 0:
      default:
        return ROUTE_TO_USER_NEW_AO_POLICY_PAGE;
    }
  };

  if (activeStep >= stepIndex) {
    return <Route path={`${path}/${stateKey || ''}`}>{children}</Route>;
  }
  return <Redirect to={renderStep(activeStep)} />;
};

const AOStepEndRoute = ({ path, confirmationCode, children }) => {
  if (confirmationCode) {
    return <Route path={path}>{children}</Route>;
  }
  return <Redirect to={ROUTE_TO_USER_NEW_AO_POLICY_PAGE} />;
};

export const AOStepper = ({ step = STEP_1, title, cancelButton = true }) => {
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const contractingId = get(params, 'contractingId', undefined);

  if (getSessionStoragePolicyEligibility(POLICY_CODE_AO) !== 'true') {
    return <Redirect to={ROUTE_TO_USER_POLICIES_CAR_INSURANCE_PAGE} />;
  }

  return (
    <PolicyContracting query={GetServerStorageQuery}>
      {({ data, error, stateKey, confirmationCode, paymentError }) => {
        const serverStep = get(data, 'state.step', 0);
        const stepData = get(data, 'state', {});
        if (
          (stateKey || contractingId) &&
          (paymentError || error || (stepData.type && stepData.type !== POLICY_CODE_AO))
        ) {
          return <Redirect to={ROUTE_TO_USER_NEW_AO_POLICY_PAGE} />;
        }

        const stepsArray = [
          { onClick: () => history.push(`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE}/${stateKey || ''}`) },
          { onClick: () => history.push(`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_2}/${stateKey}`) },
          { onClick: () => history.push(`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_3}/${stateKey}`) },
          { onClick: () => history.push(`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_4}/${stateKey}`) },
        ];

        const { activeStep } = getStepNumberAndId(serverStep, error, step, stepsArray.length);
        const stepProps = { activeStep, stateKey };

        return (
          <StepperWrapper
            steps={confirmationCode ? stepsArray.map(() => ({})) : stepsArray}
            activeStep={confirmationCode ? stepsArray.length : activeStep}
            policyTranslationBase="userMyPoliciesCarLiability"
            title={title}
            cancelButton={cancelButton}
            policyCode={POLICY_CODE_AO}
            stateKey={stateKey}>
            <Switch>
              <AOStepEndRoute
                path={`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_4}/${confirmationCode}`}
                confirmationCode={confirmationCode}>
                <Step4AO data={stepData} />
              </AOStepEndRoute>
              <AOStepRoute path={ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_3} {...stepProps} stepIndex={2}>
                <Step3AO data={stepData} stateKey={stateKey} />
              </AOStepRoute>
              <AOStepRoute path={ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_2} {...stepProps} stepIndex={1}>
                <Step2AO data={stepData} user={user} stateKey={stateKey} />
              </AOStepRoute>
              <AOStepRoute path={ROUTE_TO_USER_NEW_AO_POLICY_PAGE} {...stepProps} stepIndex={0}>
                <Step1AO data={stepData} user={user} stateKey={stateKey} />
              </AOStepRoute>
              <Redirect to={ROUTE_TO_USER_NEW_AO_POLICY_PAGE} />
            </Switch>
          </StepperWrapper>
        );
      }}
    </PolicyContracting>
  );
};
