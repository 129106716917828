import React, { Component } from 'react';
import { Row, Col, Container, Card, CardGroup, CardBody } from 'reactstrap';
import logo from '../../img/logo-short.png';

class BoxContainer extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col sm="10" md="7" lg="5">
              <CardGroup>
                <Card className="p-2 p-sm-4">
                  <CardBody>
                    <p className="text-center">
                      <img className="img-fluid" src={logo} alt="logo" />
                    </p>
                    {this.props.children}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BoxContainer;
