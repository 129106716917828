import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as router from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { AppHeader, AppSidebar, AppSidebarFooter, AppSidebarNav2 as AppSidebarNav } from '@coreui/react';
import { get } from 'lodash-es';
import Header from './Header';
// sidebar nav config
import { adminSidebarNavigation } from './sidebarNavConfig';
import { buildVersion } from '../../config/buildVersion';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { ROUTE_TO_ADMIN_DASHBOARD_PAGE } from '../../constants/routes';
import { Query } from 'react-apollo';
import { GetStatusQuery } from '../../operations/queries/GetStatusQuery';

const mapStateToProps = state => ({
  user: state.auth.user,
});

class AdminLayout extends Component {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      scope.setUser({ id: this.props.user.id, email: this.props.user.email });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <Header />
        </AppHeader>
        <div className="app-body">
          <AppSidebar className="hok-sidebar" fixed display="lg">
            <AppSidebarNav
              navConfig={adminSidebarNavigation(this.props.user.role)}
              {...this.props.children.props}
              router={router}
            />
            <AppSidebarFooter className="hok-sidebar-footer">
              {buildVersion}
              <Query query={GetStatusQuery}>
                {({ data }) => {
                  const versionData = get(data, 'status', {});
                  const {
                    version,
                    insuranceSystemAPIVersion,
                    insuranceDocumentManagmentServiceAPIVersion,
                  } = versionData;

                  return (
                    <>
                      {version && <div>{version}</div>}
                      {insuranceSystemAPIVersion && <div>{insuranceSystemAPIVersion}</div>}
                      {insuranceDocumentManagmentServiceAPIVersion && (
                        <div>{insuranceDocumentManagmentServiceAPIVersion}</div>
                      )}
                    </>
                  );
                }}
              </Query>
            </AppSidebarFooter>
          </AppSidebar>
          <main className="hok-user-dashboard main">
            {this.state.hasError ? (
              <ErrorBoundary errorMessage={this.state.error} redirectLocation={ROUTE_TO_ADMIN_DASHBOARD_PAGE} />
            ) : (
              this.props.children
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AdminLayout);
