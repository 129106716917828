import React from 'react';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';
import { displayCurrencyOptions } from '../../../utils/currencyDisplayFormat';
import { useAORisksQuery } from '../../../utils/customHooks';
import { HOK_AUTONEZGODA } from '../../../constants/business';
import { moveArrayItemToLastIndex } from '../../../utils/arrayOperations';
import { currencyConversionEuro} from '../../../utils/currencyConversion';

export const RiskList = ({ covers, currentPackage, productItems, vehicleTypeCode }) => {
  const { formatNumber } = useIntl();
  const { risks } = useAORisksQuery(vehicleTypeCode);

  const itemRender = (riskDef, item, index, currentPackage) => (
    <Row className="hok-text-dark">
      <Col className="d-flex flex-row" sm="9" md="8" lg="6">
        <div className="hok-risk-list-container">
          {index === 0 && !currentPackage ? null : <hr className="w-100" />}
          <div className="d-flex justify-content-between flex-wrap">
            <div className="font-weight-bold">{riskDef && riskDef.name}</div>
            {item && <div className="hok-price-box">{formatNumber(item.value, {style: 'currency',
                      currency: 'EUR',
                      currencySign: 'standard',
                      currencyDisplay: 'narrowSymbol',
                      })} 
                      {/* <br /> {currencyConversionEuro(item.value)} */}
                      </div>}
          </div>
        </div>
      </Col>
    </Row>
  );

  const coversArray = moveArrayItemToLastIndex(covers, item => item === HOK_AUTONEZGODA);

  return coversArray.map((cover, index) => {
    const item = productItems.find(item => item.riskEnumCode === cover);

    const riskDef = risks.find(risk => risk.id === cover);

    if (currentPackage) {
      if (!currentPackage.risksDefinitions.some(riskDef => riskDef.risk === cover)) {
        return <React.Fragment key={cover}>{itemRender(riskDef, item, index, currentPackage)}</React.Fragment>;
      }
      return null;
    }
    return <React.Fragment key={cover}>{itemRender(riskDef, item)}</React.Fragment>;
  });
};
