import React, { useEffect } from 'react';
import { useLocation, useParams, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import logoShort from '../../../img/logo-short.png';
import { ROUTE_TO_USER_DASHBOARD_PAGE } from '../../../constants/routes';
import { CustomButton } from '../../../containers';
import { useDispatch } from 'react-redux';

export const PaymentCompleteStatus = () => {
  const queryString = useLocation().search;
  const { status } = useParams();
  const dispatch = useDispatch();

  window.APP_CAN_CLOSE_THIS_WINDOW = true;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/payment/WSPay/${status}${queryString}`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    }).catch(error => {
      const ShoppingCartID = new URLSearchParams(queryString).get('ShoppingCartID');
      Sentry.withScope(scope => {
        if (ShoppingCartID) {
          scope.setExtra({ ShoppingCartID });
        }
        Sentry.captureException(error);
      });
    });
  }, [queryString, status]);


  // const tabToggle = useSelector(state => state.tabToggle);

  const updateToggleToTrue = () => {
    window.localStorage.setItem("tabStateStorage", "true");
  }

  if (!queryString) {
    return <Redirect to={ROUTE_TO_USER_DASHBOARD_PAGE} />;
  }

  const renderStatus = () => {
    switch (status) {
      case 'success':
        return (
          <>
          {updateToggleToTrue()}
            <img
              className="status-img"
              alt="success"
              width="60px"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6bm9uZTt9LmJ7Y2xpcC1wYXRoOnVybCgjYSk7fS5je2ZpbGw6cmdiYSgwLDAsMCwwKTt9LmR7ZmlsbDojZmZmO29wYWNpdHk6MC4zO30uZXtmaWxsOiMwMGEzNDQ7fTwvc3R5bGU+PGNsaXBQYXRoIGlkPSJhIj48cmVjdCBjbGFzcz0iYSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+PC9jbGlwUGF0aD48L2RlZnM+PGcgY2xhc3M9ImIiPjxyZWN0IGNsYXNzPSJjIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz48Y2lyY2xlIGNsYXNzPSJkIiBjeD0iOCIgY3k9IjgiIHI9IjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQgNCkiLz48cGF0aCBjbGFzcz0iZSIgZD0iTTEwLDIwQTEwLDEwLDAsMSwxLDIwLDEwLDEwLjAxMSwxMC4wMTEsMCwwLDEsMTAsMjBabS4wMS0xOGE4LDgsMCwxLDAsOCw4QTguMDA5LDguMDA5LDAsMCwwLDEwLjAxLDJaTTkuNjM2LDEzLjY3NWgwTDguMTQ5LDEyLjMzNmgwbDAsMEw2LjMwNiwxMC42NjksNy42NDUsOS4xODNsMS44NDcsMS42NjIsMy41NzYtMy45NzMsMS40ODgsMS4zMzlMOS42MzcsMTMuNjc0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS45OSAyKSIvPjwvZz48L3N2Zz4="
            />
            <p className="mt-2">Vaša uplata je uspješno provedena!</p>
            <p>Vaša polica je uspješno ugovorena i bit će vidljiva u 'Moje police'.</p>
          </>
        );
      case 'error':
        const errorMessage = new URLSearchParams(queryString).get('ErrorMessage');
        return (
          <>
             {updateToggleToTrue()}
            <img
              className="status-img"
              alt="success"
              width="60px"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDpyZ2JhKDAsMCwwLDApO30uYntmaWxsOiNmZmY7b3BhY2l0eTowLjM7fS5je2ZpbGw6I2VkMWQyNDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNCAtNzI4KSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM0IDcyOCkiLz48cGF0aCBjbGFzcz0iYiIgZD0iTTE0Niw3NDhhOCw4LDAsMSwxLDgtOEE4LjAwOSw4LjAwOSwwLDAsMSwxNDYsNzQ4Wm0tMS01djJoMnYtMlptMC04djZoMnYtNloiLz48cGF0aCBjbGFzcz0iYyIgZD0iTTE0NS45OSw3NTBhMTAsMTAsMCwxLDEsMTAtMTBBMTAuMDExLDEwLjAxMSwwLDAsMSwxNDUuOTksNzUwWm0uMDEtMThhOCw4LDAsMSwwLDgsOEE4LjAwOSw4LjAwOSwwLDAsMCwxNDYsNzMyWm0xLDEzaC0ydi0yaDJ2MlptMC00aC0ydi02aDJ2NloiLz48L2c+PC9zdmc+"
            />
            <p className="mt-2">{errorMessage || 'Dogodila se pogreška prilikom plaćanja.'}</p>
          </>
        );
      default:
        return (
          <>
             {updateToggleToTrue()}
            <img
              className="status-img"
              alt="success"
              width="60px"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDpyZ2JhKDAsMCwwLDApO30uYntmaWxsOiNmZmY7b3BhY2l0eTowLjM7fS5je2ZpbGw6I2Y2YzUwMDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNCAtNzI4KSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM0IDcyOCkiLz48cGF0aCBjbGFzcz0iYiIgZD0iTTE0Niw3NDhhOCw4LDAsMSwxLDgtOEE4LjAwOSw4LjAwOSwwLDAsMSwxNDYsNzQ4Wm0tMS01djJoMnYtMlptMC04djZoMnYtNloiLz48cGF0aCBjbGFzcz0iYyIgZD0iTTE0NS45OSw3NTBhMTAsMTAsMCwxLDEsMTAtMTBBMTAuMDExLDEwLjAxMSwwLDAsMSwxNDUuOTksNzUwWm0uMDEtMThhOCw4LDAsMSwwLDgsOEE4LjAwOSw4LjAwOSwwLDAsMCwxNDYsNzMyWm0xLDEzaC0ydi0yaDJ2MlptMC00aC0ydi02aDJ2NloiLz48L2c+PC9zdmc+"
            />
            <p className="mt-2">Nalog za plaćanje je otkazan.</p>
          </>
        );
    }
  };

  return (
    <div className="hok-payment-page">
      <div className="text-center">
        <img className="img-fluid" src={logoShort} alt="HOK logo" />
        {renderStatus()}
        <CustomButton
          id="btnClose"
          block={false}
          className="hok-dashboard-btn"
          onClick={() => {
            window.USER_WANTS_TO_CLOSE_THIS_WINDOW = true;
            window.close();
          }}
          translationId="closeWindow"
        />
      </div>
    </div>
  );
};
