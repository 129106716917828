import React, { useState } from 'react';
import { Collapse, Card, CardHeader, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

//Images
import { ReactComponent as HokExpiredIcon } from '../../img/icons/hok-error.svg';
import { ReactComponent as HokWarningIcon } from '../../img/icons/hok-warning.svg';
import { ReactComponent as HokActiveIcon } from '../../img/icons/hok-success.svg';
import keyboardUpIcon from '../../img/icons/hok-chevron-up.svg';
import keyboardDownIcon from '../../img/icons/hok-chevron-down.svg';
import { POLICY_STATUS_RUNNING_OUT, POLICY_STATUS_ACTIVE, POLICY_STATUS_EXPIRED } from '../../constants/business';

const ProductContainer = ({
  mainTitle = '',
  subtitle = '',
  item = '',
  showIsActive = true,
  showActiveText = true,
  activeStatus = false,
  isCardOpen = false,
  toggleCallback = undefined,
  children,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(isCardOpen);
  const toggle = () => {
    typeof toggleCallback === 'function' && toggleCallback(isOpen);
    setOpen(!isOpen);
  };

  const productStatus = () => {
    switch (activeStatus) {
      case POLICY_STATUS_RUNNING_OUT:
        return { text: 'insurancePolicyContainer.isRunningOut', Icon: HokWarningIcon, color: 'warning' };
      case POLICY_STATUS_EXPIRED:
        return { text: 'insurancePolicyContainer.expired', Icon: HokExpiredIcon, color: 'red' };
      case POLICY_STATUS_ACTIVE:
      default:
        return { text: 'insurancePolicyContainer.isActive', Icon: HokActiveIcon, color: 'green' };
    }
  };

  const { text, Icon, color } = productStatus();

  return (
    <Card className="insurance-policy-container" {...rest}>
      <CardHeader className="cursor-pointer" onClick={toggle}>
        <div className="d-flex align-items-center">
          <div className="mr-sm-4">
            <h4 className="main-title">{mainTitle}</h4>
            {item && <p className="hok-text-dark-500">{item}</p>}
          </div>
          <p className={'desktop-container subtitle-text' + (item ? ' ml-auto text-center' : '')}>{subtitle}</p>
          {showIsActive && (
            <>
              {showActiveText && (
                <span className={`${item ? 'ml-3' : 'ml-auto'} status-text color-${color}`}>
                  {<FormattedMessage id={text} />}
                </span>
              )}
              <Icon
                className={`${item ? 'ml-3' : 'ml-auto'} mr-2 desktop-container svg-product-card svg-${color} ${
                  showActiveText ? 'ml-md-2' : ''
                }`.trim()}
              />
            </>
          )}
          <img
            alt="collapse-toggler"
            src={isOpen ? keyboardUpIcon : keyboardDownIcon}
            className={'collapse-toggler' + (showIsActive ? '' : ' ml-auto') + (item ? ' align-top' : '')}
          />
        </div>
        <div className="mobile-container mt-2">
          <p className="subtitle-text d-inline-block">{subtitle}</p>
          {showIsActive && <Icon className={`ml-auto svg-product-card svg-${color}`} />}
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="p-0">{children}</CardBody>
      </Collapse>
    </Card>
  );
};

export default ProductContainer;