import React from 'react';
import { ReactComponent as HokAttachmentIcon } from '../../img/icons/hok-attachment.svg';
import { ReactComponent as HokCancelIcon } from '../../img/icons/hok-close.svg';
import { renderToaster, ERROR_TOASTER } from '../../constants/toaster';
import FileSaver from 'file-saver';

export const Attachment = ({ file, inverted = false, download = true, showCancel = false, onClick }) => (
  <div
    className={'hok-comment-file' + (download ? ' cursor-pointer' : '')}
    onClick={
      !download
        ? null
        : async () => {
            try {
              const response = await fetch(file.key);
              const blob = await response.blob();
              FileSaver.saveAs(blob, file.name);
            } catch (e) {
              renderToaster(`errors.errorWhileDownloadingFile`, ERROR_TOASTER, { intlKey: true });
            }
          }
    }>
    <div>
      <HokAttachmentIcon className={inverted ? 'svg-red' : 'svg-dark'} height="24" width="24" />
    </div>
    <span className={'file-label' + (!inverted ? ' red' : '')}>{file.name}</span>
    {showCancel && (
      <HokCancelIcon
        onClick={typeof onClick === 'function' ? onClick : null}
        className="svg-red ml-2 cursor-pointer"
        height="12"
        width="12"
      />
    )}
  </div>
);
