import React from 'react';
import { useParams, useHistory, Redirect, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, CardGroup, Card, CardHeader, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { StepperWrapper } from './StepperWrapper';
import { QuickClaimFormData } from './QuickClaimFormData';
import { QuickReportStep1 } from './QuickReportStep1';
import { QuickReportStep2 } from './QuickReportStep2';
import { getStepNumberAndId } from '../../../../utils/manipulateSteps';
import { damageClaimGroup, CLAIM_CAR_LIABILITY_PAGE } from '../../../../constants/claimDefs';
import { GetServerStorageQuery } from '../../../../operations/queries/GetServerStorageQuery';
import {
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT,
  ROUTE_TO_USER_QUICK_DAMAGE_REPORT,
} from '../../../../constants/routes';
import { BackgroundAnimation } from '../../../../components/BackgroundAnimation';

const QuickClaimRoute = ({ activeStep, stepIndex, children, path, stateKey }) => {
  const renderStep = stepIndex => {
    switch (stepIndex) {
      case 1:
        return `${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step2/${stateKey}`;
      case 0:
      default:
        return `${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step1`;
    }
  };

  if (activeStep >= stepIndex) {
    return <Route path={`${path}/${stateKey || ''}`}>{children}</Route>;
  }
  return <Redirect to={renderStep(activeStep)} />;
};

export const QuickClaimSubmitForm = () => {
  const history = useHistory();
  const params = useParams();
  const user = useSelector(state => state.auth.user);
  const { damageReportStep } = params;

  const [groupName, pageName] = damageClaimGroup(CLAIM_CAR_LIABILITY_PAGE);

  if (!groupName) {
    return <Redirect to={ROUTE_TO_USER_QUICK_DAMAGE_REPORT} />;
  }

  return (
    <div className="d-flex align-items-center quick-report">
      <BackgroundAnimation />
      <Container fluid>
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <CardGroup className="quick-report-card-group">
              <Card className="label-red w-100">
                <CardHeader className="py-1">
                  <h2 className="d-block">
                    <FormattedMessage id="quickSubmitClaim.title" />: <FormattedMessage id="routes.carLiability" />
                  </h2>
                </CardHeader>
                <CardBody className="hok-user-dashboard pt-5 px-lg-5">
                  <QuickClaimFormData query={GetServerStorageQuery}>
                    {({ data, error, stateKey }) => {
                      const serverStep = get(data, 'state.step', 0);
                      const stepData = get(data, 'state', {});

                      const stepsArray = [
                        {
                          title: '',
                          onClick: () => history.push(`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step1/${stateKey}`),
                        },
                        {
                          title: '',
                          onClick: () => history.push(`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step2/${stateKey}`),
                        },
                      ];

                      if (error) {
                        return <Redirect to={`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step1`} />;
                      }

                      const { activeStep } = getStepNumberAndId(
                        serverStep,
                        error,
                        damageReportStep,
                        stepsArray.length,
                        true
                      );
                      const stepProps = { activeStep, stateKey };
                      return (
                        <StepperWrapper
                          steps={stepsArray}
                          activeStep={activeStep}
                          translationBase={`userReportDamagePage.${pageName}`}
                          title={activeStep === 1 ? 'documentation' : ''}
                          cancelLink={`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}`}>
                          <Switch>
                            <QuickClaimRoute
                              path={`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step1`}
                              {...stepProps}
                              stepIndex={0}>
                              <QuickReportStep1 data={stepData} user={user} />
                            </QuickClaimRoute>
                            <QuickClaimRoute
                              path={`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step2`}
                              {...stepProps}
                              stepIndex={1}>
                              <QuickReportStep2 data={stepData} user={user} stateKey={stateKey} />
                            </QuickClaimRoute>
                            <Redirect to={`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_SUBMIT}/step1/${stateKey}`} />
                          </Switch>
                        </StepperWrapper>
                      );
                    }}
                  </QuickClaimFormData>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
