import React, { useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { get } from 'lodash-es';
import { ContentContainer, Spinner } from '../../../containers';
import { USER_ACCOUNT_PRIVATE, USER_ACCOUNT_BUSINESS } from '../../../constants/userDefs';
import { PrivateUserForm } from './PrivateUserForm';
import { BusinessUserForm } from './BusinessUserForm';
import { ROUTE_TO_USER_SELECTION_PAGE } from '../../../constants/routes';
import step2Img from '../../../img/stepper/step2.svg';
import step3Img from '../../../img/stepper/step3.svg';
import { useQuery } from 'react-apollo';
import { GetCountriesQuery } from '../../../operations/queries/GetLocationsQuery';
import { LogoutLink } from '../../../components/LogoutLink';
import { ReactComponent as HokBackIcon } from '../../../img/icons/hok-back.svg';
import { usePlacesQuery } from '../../../utils/customHooks';

export const UserAccountInfo = () => {
  const params = useParams();
  const accountType = get(params, 'accountType', undefined);
  const user = useSelector(state => state.auth.user);
  const [isStep3, setIsStep3] = useState(false);
  const { data: dataCountries, loading: loadingCountries } = useQuery(GetCountriesQuery);
  const { places, loading: loadingPlaces } = usePlacesQuery();

  const countries = get(dataCountries, 'countries.countries', []);

  const renderTypeSelectionBasedComponent = () => {
    switch (accountType) {
      case USER_ACCOUNT_PRIVATE:
        return (
          <PrivateUserForm
            setIsStep3={setIsStep3}
            countries={countries}
            loadingCountries={loadingCountries}
            places={places}
            loadingPlaces={loadingPlaces}
            user={user}
          />
        );
      case USER_ACCOUNT_BUSINESS:
        return (
          <BusinessUserForm
            setIsStep3={setIsStep3}
            countries={countries}
            loadingCountries={loadingCountries}
            places={places}
            loadingPlaces={loadingPlaces}
            user={user}
          />
        );
      default:
        return <Redirect to={ROUTE_TO_USER_SELECTION_PAGE} />;
    }
  };

  return (
    <ContentContainer titleImage={isStep3 ? step3Img : step2Img}>
      <Row className="no-gutters mt-3">
        <Col xs="12" md={{ offset: 2, size: 8 }}>
          <Link to={ROUTE_TO_USER_SELECTION_PAGE} className="d-inline-flex align-items-center link-back">
            <HokBackIcon className="mr-2" />
            <FormattedMessage id="userTypeSelectPage.backLink" />
          </Link>
          <LogoutLink className="d-inline-block float-right" />
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col xs="12" className="text-left">
          {places ? renderTypeSelectionBasedComponent() : <Spinner className="spinner-positioning" />}
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default UserAccountInfo;
