import React, { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Label, Input, Button } from 'reactstrap';
import { useQuery, useMutation } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash-es';
import { ClaimDocumentation } from '../../../../components/ClaimDocumentation';
import { handleRequestError } from '../../../../constants/errorCodes';
import { GetClaimDocumentationGroupedQuery } from '../../../../operations/queries/GetClaimDocumentationGroupedQuery';
import { ReactComponent as HokCloseIcon } from '../../../../img/icons/hok-close.svg';
import { ReactComponent as HokPlusIcon } from '../../../../img/icons/hok-plus.svg';
import { CustomButton } from '../../../../containers';
import { UserSubmitDamageClaimMutation } from '../../../../operations/mutations/UserSubmitDamageClaimMutation';
import { claimDocumentUpload, checkFileSize } from '../../../../utils/imageUpload';
import { UserUploadClaimFilesMutation } from '../../../../operations/mutations/UserCreateClaimCommentMutation';
import { SetServerStorageMutation } from '../../../../operations/mutations/SetServerStorageMutation';
import { ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW, ROUTE_TO_USER_LOGIN_PAGE } from '../../../../constants/routes';
import { RequestUploadAccessMutation } from '../../../../operations/mutations/RequestUploadAccessMutation';
import { UPLOAD_TYPE_CLAIM_DOCUMENT } from '../../../../constants/uploadFileTypes';
import { DocumentLink } from '../../../../components/DocumentLink';
import { GetClaimDocumentationQuery } from '../../../../operations/queries/GetClaimDocumentationQuery';
import { CLAIM_CAR_LIABILITY } from '../../../../constants/claimDefs';
import { MAIN_ATTACHMENT } from '../../../../constants/documentTypes';
import { UserInteractionModal } from '../../../../components/UserInteractionModal';
import { DAMAGE_CLAIM_SUBMIT_SUCCESS, DAMAGE_CLAIM_SUBMIT_FAIL } from '../../../../constants/modalsContent';
import { useGoogleAnalyticsPageview } from '../../../../utils/customHooks';
import { UPLOAD_FILE_SIZE_LIMIT } from '../../../../constants/business';

export const QuickReportStep2 = ({ data = {}, stateKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useGoogleAnalyticsPageview('/BrzaPrijavaŠtete_Autoodgovornost_Step2');

  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const toggleSuccessModal = useCallback(() => {
    setIsSuccessModalOpen(open => !open);
  }, []);

  const toggleErrorModal = useCallback(() => {
    setIsErrorModalOpen(open => !open);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen(open => !open);
  }, []);

  const [loading, setLoading] = useState(false);

  const { data: requiredDocsData, loading: loadingDocs } = useQuery(GetClaimDocumentationGroupedQuery, {
    variables: { filter: { claimCode: data.claimClass, active: true, required: true } },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const [uploadFiles] = useMutation(UserUploadClaimFilesMutation, {
    onError: error => handleRequestError(error, dispatch),
  });

  const confirmationCode = useRef(null);

  const [deleteServerStorageRecord] = useMutation(SetServerStorageMutation, {
    variables: { stateKey, shouldDelete: true },
    onError: error => {
      handleRequestError(error, dispatch, { silent: true });
    },
  });

  const [requestUpload] = useMutation(RequestUploadAccessMutation, {
    variables: { uploadType: UPLOAD_TYPE_CLAIM_DOCUMENT },
    onError: error => handleRequestError(error, dispatch),
  });

  const [submitClaim] = useMutation(UserSubmitDamageClaimMutation, {
    variables: {
      claimClass: data.claimClass,
      insurancePolicyNumber: data.insurancePolicyNumber,
      insurancePolicyOib: data.insurancePolicyOib,
      insured: data.insured,
      note: data.note,
      peril: data.peril,
      reporterEmail: data.reporterEmail,
      ...(data.reporterIBAN && { reporterIBAN: data.reporterIBAN }),
    },
    onError: error => {
      handleRequestError(error, dispatch, { silent: true }, () => {
        toggleErrorModal();
        setLoading(false);
      });
    },
    onCompleted: data => {
      const claimIdVariables = { claimConfirmationCode: data.initNewClaim.confirmationCode, anonymous: true };

      claimDocuments.forEach(async document => {
        try {
          if (document.file) {
            const { data } = await requestUpload();
            const signedUrlsData = JSON.parse(data.requestUploadAccess.json);
            claimDocumentUpload({
              document: document.file,
              documentType: document.type,
              signedUrlsData,
              linkFunction: uploadFiles,
              linkVars: { ...claimIdVariables },
              onError: () => {
                setLoading(false);
              },
            });
          }
        } catch (e) {
          setLoading(false);
        }
      });
      confirmationCode.current = data.initNewClaim.confirmationCode;
      deleteServerStorageRecord();
      toggleSuccessModal();
      setLoading(false);
    },
  });

  const documentation = [
    {
      documentId: `${data.claimClass}_OBRAZAC_PDF`,
      content: 'Obrazac za prijavu štete (obavezno)',
    },
    ...get(requiredDocsData, 'getReportClaimDocuments.documentsGroupedByClaimCode[0].documents', []).filter(
      ({ link }) => !link
    ),
  ];

  const { data: docData } = useQuery(GetClaimDocumentationQuery, {
    variables: {
      filter: {
        active: true,
        required: true,
        type: MAIN_ATTACHMENT,
        claimGroup: CLAIM_CAR_LIABILITY,
      },
    },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const documents = get(docData, 'getReportClaimDocuments.documents', []).filter(doc => !!doc.link);

  const [claimDocuments, setClaimDocuments] = useState([{}]);

  const removeDocument = index => {
    if (index > 0) {
      setClaimDocuments([...claimDocuments.filter((_, i) => i !== index)]);
    } else {
      setClaimDocuments([{}, ...claimDocuments.filter((_, i) => i !== 0)]);
    }
  };

  const addDocument = () => {
    setClaimDocuments([...claimDocuments, {}]);
  };

  const editDocumentType = (index, type) => {
    const documents = [...claimDocuments];
    documents[index].type = type;
    setClaimDocuments(documents);
  };

  const editDocument = (index, file) => {
    const documents = [...claimDocuments];
    documents[index].file = file;
    setClaimDocuments(documents);
  };

  return (
    <Row className="no-gutters">
      <Col>
        <Row className="mt-4">
          <Col>
            <h5 className="color-red font-weight-bold mb-3">
              <FormattedMessage id="userReportDamagePage.reportForm" />:
            </h5>
          </Col>
        </Row>
        <Row className="no-gutters">
          {documents.map(document => (
            <Col className="d-flex align-items-center mb-3" xs="12" key={document.documentId}>
              <DocumentLink
                content={document.content + (document.mimeType ? `.${document.mimeType}` : '')}
                link={document.link}
              />
            </Col>
          ))}
        </Row>
        {!loadingDocs && <ClaimDocumentation documentation={documentation} isOpen={isOpen} toggle={toggle} />}
        <Row className="no-gutters mt-4">
          <Col xs="12">
            <Label className="d-block mb-0">
              <FormattedMessage id="inputs.documentType" />
            </Label>
          </Col>
          <span>
            (Dokument ne smije biti veći od{' '}
            {(parseInt(process.env.REACT_APP_UPLOAD_FILE_SIZE_LIMIT, 10) || UPLOAD_FILE_SIZE_LIMIT) / 1000000} MB!)
          </span>
          {claimDocuments.map((document, index) => (
            <Col xs="12" className="d-block d-md-flex align-items-center" key={index}>
              <div className="hok-select hok-upload-select mr-4 mt-3">
                <Input
                  type="select"
                  bsSize="sm"
                  value={index === 0 ? `${data.claimClass}_OBRAZAC_PDF` : document.type}
                  disabled={index === 0}
                  onChange={event => {
                    editDocumentType(index, event.target.value);
                  }}>
                  {documentation.map(doc => (
                    <option key={doc.documentId} value={doc.documentId}>
                      {doc.content}
                    </option>
                  ))}
                </Input>
              </div>
              <div className="d-flex align-items-center mt-2 mt-md-3">
                {document.file ? (
                  <span className="hok-doc-name">{document.file.name}</span>
                ) : (
                  <Label className="hok-sm-outline-btn hok-button btn btn-outline-primary mx-0 mb-0">
                    <Input
                      id="uploadDocument"
                      type="file"
                      onChange={event => {
                        if (checkFileSize(event.target.files[0].size)) {
                          if (index === 0) {
                            const { name } = event.target.files[0];
                            const extension = name.split('.')[name.split('.').length - 1];
                            if (extension === 'xlsx') {
                              editDocumentType(index, `${data.claimClass}_OBRAZAC_XLSX`);
                            } else {
                              editDocumentType(index, `${data.claimClass}_OBRAZAC_PDF`);
                            }
                          }
                          editDocument(index, event.target.files[0]);
                        }
                      }}
                    />
                    <FormattedMessage id="buttons.uploadDocument" />
                  </Label>
                )}
                <div className="d-inline-block ml-3">
                  <HokCloseIcon
                    className={'svg-table-icons svg-red' + (index === 0 && !claimDocuments[0].file ? ' invisible' : '')}
                    onClick={() => removeDocument(index)}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <Button className="hok-add-doc-btn mt-4" onClick={addDocument} block={false}>
              <div className="d-flex align-items-center">
                <HokPlusIcon />
                <FormattedMessage id="buttons.addDocument" />
              </div>
            </Button>
          </Col>
        </Row>
        <Row className="no-gutters mt-4">
          <Col>
            <CustomButton
              className="hok-dashboard-btn-wide float-sm-right"
              translationId="reportDamage"
              disabled={loading || !get(claimDocuments, '[0].file', null) || !get(claimDocuments, '[0].type', null)}
              loaderProp={loading}
              onClick={() => {
                setLoading(true);
                submitClaim();
              }}
            />
          </Col>
        </Row>
      </Col>
      <UserInteractionModal
        isOpen={isSuccessModalOpen}
        titleId="userReportDamagePage.successModal"
        textId={DAMAGE_CLAIM_SUBMIT_SUCCESS}>
        <CustomButton
          className="hok-dashboard-btn"
          block={false}
          translationId="ok"
          onClick={() => {
            toggleSuccessModal();
            if (confirmationCode.current) {
              history.push(`${ROUTE_TO_USER_QUICK_DAMAGE_REPORT_PREVIEW}/${confirmationCode.current}`);
            }
          }}
        />
      </UserInteractionModal>
      <UserInteractionModal
        isOpen={isErrorModalOpen}
        titleId="userReportDamagePage.errorModal"
        textId={DAMAGE_CLAIM_SUBMIT_FAIL}>
        <CustomButton
          className="hok-outline-btn"
          outline
          block={false}
          translationId="giveUp"
          onClick={() => {
            toggleErrorModal();
            history.push(ROUTE_TO_USER_LOGIN_PAGE);
          }}
        />
        <CustomButton className="hok-dashboard-btn" block={false} translationId="ok" onClick={toggleErrorModal} />
      </UserInteractionModal>
    </Row>
  );
};
