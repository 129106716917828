import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DOC_TYPE_ALL, STEP_3 } from '../../../constants/business';
import { Row, Col, Form, FormGroup, Label, InputGroup, FormFeedback } from 'reactstrap';
import { CustomButton, CollapsibleContainer, CustomDashboardInput } from '../../../containers';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import moment, { ISO_8601 } from 'moment';
import { get, debounce } from 'lodash-es';
import { ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_3 } from '../../../constants/routes';
import { TermsAndConditionsCheckbox } from '../../../components/TermsAndConditionsCheckbox';
import { PolicyContractingPersonInfo } from '../../../components/PolicyContractingPersonInfo';
import { useMutation } from 'react-apollo';
import { yearListRender, FORMAT_STANDARD_DATE } from '../../../constants/dateFormatting';
import {
  USER_GENDER,
  USER_ACCOUNT_TYPES,
  USER_ACCOUNT_BUSINESS,
  USER_ACCOUNT_PRIVATE,
} from '../../../constants/userDefs';
import { validateFormSubmission } from '../../../utils/validation';
import { useProductDocumentsQuery, usePlacesQuery } from '../../../utils/customHooks';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import { displayCurrencyOptions } from '../../../utils/currencyDisplayFormat';
import { handleRequestError } from '../../../constants/errorCodes';
import { v4 } from 'uuid';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';

export const Step2Express = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage, formatNumber } = useIntl();

  const { allTypeDocuments } = useProductDocumentsQuery({ docType: DOC_TYPE_ALL, productCode: data.type });

  const [submitForm, { loading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data => history.push(`${ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE_STEP_3}/${data.serverStorage.stateKey}`),
    onError: error => handleRequestError(error, dispatch, { silent: true }),
  });

  const { places, loading: loadingPlaces } = usePlacesQuery();

  const user = useSelector(state => state.auth.user);

  const [oib, setOIB] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: true,
      maxLength: 11,
      minLength: 11,
      oib: true,
    },
  });
  const [firstName, setFirstName] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {
      required: true,
    },
  });
  const [lastName, setLastName] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {
      required: true,
    },
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value: null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.dateOfBirth" />,
    validation: {
      required: true,
    },
  });
  const [sex, setSex] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sex" />,
    validation: {
      required: true,
      enum: USER_GENDER.map(gender => gender.value),
    },
  });
  const [address, setAddress] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });
  const [zip, setZip] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
      enum: places.map(place => place.pttNumber),
    },
  });
  const [city, setCity] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [email, setEmail] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.email" />,
    validation: {
      required: true,
      email: true,
    },
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {
      required: true,
    },
  });
  const [sameContracting /*setSameContracting*/] = useState({
    value: true,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sameContracting" />,
    validation: {
      required: true,
    },
  });
  const [sector, setSector] = useState({
    value: '',
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sector" />,
    validation: {
      required: true,
      enum: USER_ACCOUNT_TYPES.map(type => type.name),
    },
  });
  const [businessName, setBusinessName] = useState({
    value: '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.name" />,
    validation: {
      required: true,
    },
  });

  const [tac, setTac] = useState({
    value: data.acceptedTerms || false,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.tac" />,
    validation: {
      isTrue: true,
    },
  });

  const [dateOfBirthFocused, setDateOfBirthFocused] = useState(false);
  const userDOB = moment(user.dateOfBirth, ISO_8601, true).isValid ? moment(user.dateOfBirth, ISO_8601) : null;

  useEffect(() => {
    if (places.length > 0 && city.validation.enum.length < 1) {
      setCity(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
    }
    if (places.length > 0 && zip.validation.enum.length < 1) {
      setZip(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
    }
  }, [places, city, zip]);

  const [filteredPlaces, setFilteredPlaces] = useState([...places]);

  useEffect(() => {
    if (places.length > 0) {
      setFilteredPlaces([...places]);
    }
  }, [places]);

  const debouncedPlacesFilter = debounce(value => {
    const matchingPlaces = places.filter(place => place.pttNumber.startsWith(value));
    setFilteredPlaces(value ? matchingPlaces : [...places]);
    if (matchingPlaces.length === 1 && matchingPlaces[0].pttNumber === value) {
      setCity(city => ({ ...city, invalid: false, value: matchingPlaces[0].name }));
    } else {
      setCity(city => ({ ...city, invalid: false, value: '' }));
    }
  }, 500);

  const tacAcceptedFn = useCallback(value => setTac(tac => ({ ...tac, value })), []);
  const tacInvalidFn = useCallback(invalid => setTac(tac => ({ ...tac, invalid })), []);

  const tacLabelRender = useCallback(
    toggleTacModal => (
      <div className="tac-text-container">
        <span>
          Potvrđujem kako je ovaj proizvod u skladu s mojim zahtjevima i potrebama te da sam temeljem
          <span onClick={toggleTacModal} className="terms-and-conditions-text">
            predugovornih dokumenata i uvjeta osiguranja
          </span>{' '}
          donio informiranu odluku o kupnji proizvoda.
        </span>
      </div>
    ),
    []
  );

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false }));
  };

  const expressStep2SubmitHandler = async event => {
    event.preventDefault();
    const fieldsArray = [setTac];

    if (!sameContracting.value) {
      fieldsArray.push(setOIB, setAddress, setCity, setZip, setPhoneNumber, setEmail);

      if (sector.value === USER_ACCOUNT_BUSINESS) {
        fieldsArray.push(setBusinessName);
      } else {
        fieldsArray.push(setFirstName, setLastName, setDateOfBirth, setSex);
      }
    }

    if (await validateFormSubmission(fieldsArray)) {
      submitForm({
        variables: {
          stateKey: data.stateKey,
          state: {
            ...data,
            step: STEP_3,
            // sameContracting: sameContracting.value,
            acceptedTerms: tac.value,
          },
        },
      });
    }
  };

  const renderHeader = () => {
    if (sameContracting.value) {
      return user.sector === USER_ACCOUNT_BUSINESS ? user.businessName : `${user.firstName} ${user.lastName}`;
    } else {
      return get(sector, 'value', USER_ACCOUNT_PRIVATE) === USER_ACCOUNT_BUSINESS
        ? businessName.value
        : `${firstName.value} ${lastName.value}`.trim() ||
            formatMessage({ id: 'userMyPolicies.policyData.policyPersonInsured' });
    }
  };

  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  return (
    <div className="step2">
      <Form onSubmit={expressStep2SubmitHandler}>
        <Row className="mt-4">
          <Col xs="12">
            <PolicyContractingPersonInfo user={user} labelId="userMyPolicies.policyData.policyContractor" />
            <h3 className="subtitle pl-4">
              <FormattedMessage id="userMyPolicies.policyData.policyPersonInsured" />:
            </h3>
            <Row className="mt-2">
              <Col xs="12">
                <CollapsibleContainer title={renderHeader()}>
                  <FormGroup row className="mt-4">
                    <Col sm="6" md="4">
                      <Label for="sector">
                        <FormattedMessage id="inputs.sector" />
                      </Label>
                      <InputGroup>
                        <div className="hok-select">
                          <CustomDashboardInput
                            type="select"
                            value={sameContracting.value ? user.sector : sector.value}
                            fieldState={sector}
                            disabled={sameContracting.value}
                            onChange={event => inputHandler(setSector, event.target.value)}
                          >
                            {USER_ACCOUNT_TYPES.map(type => (
                              <option key={type.name} value={type.name}>
                                {formatMessage({ id: `accountType.${type.name}` })}
                              </option>
                            ))}
                          </CustomDashboardInput>
                        </div>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    {(!sameContracting.value && sector.value === USER_ACCOUNT_BUSINESS) ||
                    (sameContracting.value && user.sector === USER_ACCOUNT_BUSINESS) ? (
                      <Col sm="6" md="4">
                        <Label for="name">
                          <FormattedMessage id="inputs.name" />
                        </Label>
                        <InputGroup>
                          <CustomDashboardInput
                            id="name"
                            value={sameContracting.value ? user.businessName : businessName.value}
                            fieldState={businessName}
                            disabled={sameContracting.value}
                            onChange={event => inputHandler(setBusinessName, event.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    ) : (
                      <>
                        <Col sm="6" md="4">
                          <Label for="firstName">
                            <FormattedMessage id="inputs.firstName" />
                          </Label>
                          <InputGroup>
                            <CustomDashboardInput
                              value={sameContracting.value ? user.firstName : firstName.value}
                              fieldState={firstName}
                              disabled={sameContracting.value}
                              onChange={event => inputHandler(setFirstName, event.target.value)}
                            />
                          </InputGroup>
                        </Col>
                        <Col sm="6" md="4">
                          <Label for="lastName">
                            <FormattedMessage id="inputs.lastName" />
                          </Label>
                          <InputGroup>
                            <CustomDashboardInput
                              value={sameContracting.value ? user.lastName : lastName.value}
                              fieldState={lastName}
                              disabled={sameContracting.value}
                              onChange={event => inputHandler(setLastName, event.target.value)}
                            />
                          </InputGroup>
                        </Col>
                        <Col sm="6" md="4">
                          <Label for="dateOfBirth">
                            <FormattedMessage id="inputs.dateOfBirth" />
                          </Label>
                          <InputGroup className={dateOfBirth.invalid ? 'invalid-date' : ''}>
                            <SingleDatePicker
                              hideKeyboardShortcutsPanel
                              date={sameContracting.value ? userDOB : dateOfBirth.value}
                              disabled={sameContracting.value}
                              onDateChange={date => inputHandler(setDateOfBirth, date)}
                              focused={dateOfBirthFocused}
                              onFocusChange={({ focused }) => setDateOfBirthFocused(focused)}
                              id="dateOfBirth"
                              showDefaultInputIcon
                              firstDayOfWeek={1}
                              numberOfMonths={1}
                              small
                              displayFormat={FORMAT_STANDARD_DATE}
                              placeholder={''}
                              initialVisibleMonth={() => dateOfBirth.value || moment()}
                              isOutsideRange={date => date.isAfter(moment(), 'day')}
                              renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                                <div className="d-flex justify-content-center">
                                  <div className="mr-1">
                                    <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                                      {moment.months().map((label, value) => (
                                        <option value={value} key={label}>
                                          {label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="ml-1">
                                    <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                                      {yearListRender(1900, moment().format('YYYY'))}
                                    </select>
                                  </div>
                                </div>
                              )}
                            />
                            {dateOfBirth.invalid && (
                              <FormFeedback className="d-block">{dateOfBirth.error}</FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm="6" md="4">
                          <Label for="sex">
                            <FormattedMessage id="inputs.sex" />
                          </Label>
                          <InputGroup>
                            <div className="hok-select">
                              <CustomDashboardInput
                                type="select"
                                fieldState={sex}
                                disabled={sameContracting.value}
                                onChange={event => inputHandler(setSex, event.target.value)}
                              >
                                <option disabled value="">
                                  {formatMessage({ id: 'inputs.sex' })}
                                </option>
                                {USER_GENDER.map(sex => (
                                  <option key={sex.value} value={sex.value}>
                                    {formatMessage({ id: sex.name })}
                                  </option>
                                ))}
                              </CustomDashboardInput>
                            </div>
                          </InputGroup>
                        </Col>
                      </>
                    )}
                    <Col sm="6" md="4">
                      <Label for="OIB">
                        <FormattedMessage id="inputs.OIB" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          value={sameContracting.value ? user.oib : oib.value}
                          fieldState={oib}
                          disabled={sameContracting.value}
                          onChange={event => inputHandler(setOIB, event.target.value)}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm="6" md="4">
                      <Label for="address">
                        <FormattedMessage id="inputs.address" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          value={sameContracting.value ? user.address : address.value}
                          fieldState={address}
                          disabled={sameContracting.value}
                          onChange={event => inputHandler(setAddress, event.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="zipCode">
                        <FormattedMessage id="inputs.zipCode" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          id="zipCode"
                          value={sameContracting.value ? user.zip : zip.value}
                          fieldState={zip}
                          readOnly={sameContracting.value}
                          onChange={event => {
                            event.persist();
                            debouncedPlacesFilter(event.target.value);
                            setZip(zip => ({ ...zip, invalid: false, value: event.target.value }));
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="city">
                        <FormattedMessage id="inputs.place" />
                      </Label>
                      <InputGroup>
                        <div className="hok-select">
                          <CustomDashboardInput
                            id="city"
                            type="select"
                            value={sameContracting.value ? user.city : city.value}
                            fieldState={city}
                            disabled={sameContracting.value}
                            onChange={event => {
                              const place = places.find(place => place.name === event.target.value);
                              setCity({ ...city, invalid: false, value: event.target.value });
                              place && setZip({ ...zip, invalid: false, value: place.pttNumber });
                            }}
                          >
                            <option disabled value="">
                              {formatMessage({
                                id: 'inputs.labels.selectPlace',
                              })}
                            </option>
                            {loadingPlaces ? (
                              <option disabled value={city.value}>
                                {city.value}
                              </option>
                            ) : (
                              filteredPlaces.map(place => (
                                <option key={v4()} value={place.name}>
                                  {place.name}
                                </option>
                              ))
                            )}
                          </CustomDashboardInput>
                        </div>
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="zip">
                        <FormattedMessage id="inputs.zipCode" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          value={sameContracting.value ? user.zip : zip.value}
                          fieldState={zip}
                          disabled={sameContracting.value}
                          onChange={event => inputHandler(setZip, event.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="phoneNumber">
                        <FormattedMessage id="inputs.phoneNumberShort" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          value={sameContracting.value ? user.phoneNumber : phoneNumber.value}
                          fieldState={phoneNumber}
                          disabled={sameContracting.value}
                          onChange={event => inputHandler(setPhoneNumber, event.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Label for="email">
                        <FormattedMessage id="inputs.email" />
                      </Label>
                      <InputGroup>
                        <CustomDashboardInput
                          value={sameContracting.value ? user.email : email.value}
                          fieldState={email}
                          disabled={sameContracting.value}
                          onChange={event => inputHandler(setEmail, event.target.value)}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                </CollapsibleContainer>
              </Col>
            </Row>
            <TermsAndConditionsCheckbox
              setTacAccepted={tacAcceptedFn}
              tacAccepted={tac.value}
              tacInvalid={tac.invalid}
              setTacInvalid={tacInvalidFn}
              productDocs={allTypeDocuments}
            >
              {tacLabelRender}
            </TermsAndConditionsCheckbox>
            <Row className="no-gutters mt-5">
              <Col xs="12">
                <span className="text-nowrap">
                  <span className="primary">
                    <FormattedMessage id="userMyPolicies.policyData.premium" />:
                  </span>
                  {currencyDataConfig.dualCurrencyDisplay === true && (
            <>
              <span className="secondary ml-3">
              {formatNumber(data.totalPremium, displayCurrencyOptions)}
              {' '}|{' '}
               {currencyConversionEuro(data.totalPremium*100)}
              </span>
            </>)}

            {currencyDataConfig.dualCurrencyDisplay === false && (
            <>
              <span className="secondary ml-3">
              {formatNumber(data.totalPremium, displayCurrencyOptions)}
              </span>
            </>
            )}
            {/*<span className="secondary ml-3">
                    {formatNumber(data.totalPremium, displayCurrencyOptions)} |{' '}
                    {currencyConversionEuro(data.totalPremium*100)}
                  </span> */}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="12">
            <CustomButton
              className="hok-dashboard-btn-wide float-sm-right"
              translationId="goToPayment"
              loaderProp={loading}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
