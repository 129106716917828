import { call, put, takeLatest } from 'redux-saga/effects';
import { client } from '../../config/apolloConfig';
import { get } from 'lodash-es';
import {
  USER_CHANGE_PASSWORD,
  userChangePasswordSuccess,
  userChangePasswordFail,
  USER_PROFILE_FORGOT_PASSWORD,
  userProfileForgotPasswordSuccess,
  userProfileForgotPasswordFail,
  USER_UPDATE_PROFILE_ADVANCED_DATA,
  userUpdateProfileAdvancedDataFeedback,
  USER_CHANGE_PHONE_NUMBER,
  userChangePhoneNumberFeedback,
  USER_CHANGE_AVATAR,
  userChangeAvatarSuccess,
  userDeleteAccountFail,
  USER_DELETE_ACCOUNT,
  USER_VALIDATE_CURRENT_PASSWORD,
  userValidateCurrentPasswordFail,
  userDeleteAccountSuccess,
  userValidateCurrentPasswordSuccess,
} from '../actions/userProfileActions';
import { redirectToMaintenancePage } from '../actions/navigationActions';
import {
  getErrorObject,
  isMaintenanceInProgress,
  getSpecificError,
  PASSWORD_IS_INVALID,
  UNKNOWN_PHONE_NUMBER_CHANGE_FAIL,
  UNKNOWN_PASSWORD_CHANGE_FAIL,
  UNKNOWN_FORGOT_PASSWORD_FAIL,
  UNKNOWN_PROFILE_ADVANCED_DATA_UPDATE,
  isAuthTokenInvalid,
  UPLOAD_FILE_EXCEEDS_MAX_SIZE,
  UNKNOWN_USER_VALIDATE_CURRENT_PASSWORD,
  UNKNOWN_USER_DELETE_ACCOUNT,
} from '../../constants/errorCodes';
import UserChangePasswordMutation from '../../operations/mutations/UserChangePasswordMutation';
import UserPasswordRecoveryRequestMutation from '../../operations/mutations/UserPasswordRecoveryRequestMutation';
import {
  UserUpdateProfileAdvancedDataMutation,
  UserChangePhoneNumberMutation,
  UserChangeAvatarMutation,
} from '../../operations/mutations/UserUpdateProfileMutation';
import DeleteMyAccountMutation from '../../operations/mutations/DeleteMyAccountMutation';
import { renderToaster, ERROR_TOASTER, SUCCESS_TOASTER } from '../../constants/toaster';
import { userLoginFail, userUpdateDataInStore } from '../actions/userAuthActions';
import { getFormData, uploadFileToS3bucket } from '../../utils/imageUpload';
import { RequestUploadAccessMutation } from '../../operations/mutations/RequestUploadAccessMutation';
import { UPLOAD_TYPE_AVATAR } from '../../constants/uploadFileTypes';
import ValidateCurrentUserPassword from '../../operations/mutations/VaildateCurrentUserPassword';

/**
 * User profile phone number change
 */
function* userChangePhoneNumberWorker(action) {
  try {
    const phoneNumberChangeResult = yield call(() =>
      client.mutate({
        mutation: UserChangePhoneNumberMutation,
        variables: action.payload,
      })
    );

    const result = get(phoneNumberChangeResult, 'data.changePassword', null);
    if (result.id) {
      renderToaster('successMessages.phoneNumberChange', SUCCESS_TOASTER, { intlKey: true });
      yield put(userChangePhoneNumberFeedback());
    } else {
      renderToaster(getSpecificError(UNKNOWN_PHONE_NUMBER_CHANGE_FAIL).message, ERROR_TOASTER);
      yield put(userChangePhoneNumberFeedback());
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(userLoginFail({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
      yield put(userChangePhoneNumberFeedback());
    }
  }
}

export function* userChangePhoneNumberWatcher() {
  yield takeLatest(USER_CHANGE_PHONE_NUMBER, userChangePhoneNumberWorker);
}

/**
 * User profile password change
 */
function* userChangePasswordWorker(action) {
  try {
    const passwordChangeResult = yield call(() =>
      client.mutate({
        mutation: UserChangePasswordMutation,
        variables: action.payload,
      })
    );

    const result = get(passwordChangeResult, 'data.userChangePassword', null);
    if (result.passwordUpdated) {
      yield put(userChangePasswordSuccess());
    } else {
      renderToaster(getSpecificError(UNKNOWN_PASSWORD_CHANGE_FAIL).message, ERROR_TOASTER);
      yield put(userChangePasswordFail({ errorMessage: null }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    let errorMessage = errorObject.message;
    if (isAuthTokenInvalid(errorObject)) {
      yield put(userLoginFail({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      if (errorObject.key !== getSpecificError(PASSWORD_IS_INVALID).key) {
        errorMessage = null;
        renderToaster(errorObject.message, ERROR_TOASTER);
      }
      yield put(userChangePasswordFail({ errorMessage }));
    }
  }
}

export function* userChangePasswordWatcher() {
  yield takeLatest(USER_CHANGE_PASSWORD, userChangePasswordWorker);
}

/**
 * Send the user an email with a password reset link
 */
function* userProfileForgotPasswordWorker(action) {
  try {
    const recoverRequestResult = yield call(() =>
      client.mutate({
        mutation: UserPasswordRecoveryRequestMutation,
        variables: { username: action.payload.email },
      })
    );

    const result = get(recoverRequestResult, 'data.userPasswordRecoveryRequest.requestCompleted', false);

    if (result) {
      renderToaster('successMessages.passwordResetRequested', SUCCESS_TOASTER, { intlKey: true });
      yield put(userProfileForgotPasswordSuccess());
    } else {
      renderToaster(getSpecificError(UNKNOWN_FORGOT_PASSWORD_FAIL).message, ERROR_TOASTER);
      yield put(userProfileForgotPasswordFail());
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
      yield put(userProfileForgotPasswordFail());
    }
  }
}

export function* userProfileForgotPasswordWatcher() {
  yield takeLatest(USER_PROFILE_FORGOT_PASSWORD, userProfileForgotPasswordWorker);
}

/**
 * Update user profile advanced data
 */
function* userUpdateProfileAdvancedDataWorker(action) {
  try {
    const recoverRequestResult = yield call(() =>
      client.mutate({
        mutation: UserUpdateProfileAdvancedDataMutation,
        variables: action.payload,
      })
    );

    const result = get(recoverRequestResult, 'data.updateProfile.id', null);

    if (result) {
      renderToaster('successMessages.userProfileAdvancedDataUpdated', SUCCESS_TOASTER, { intlKey: true });
      yield put(userUpdateDataInStore(action.payload));
      yield put(userUpdateProfileAdvancedDataFeedback());
    } else {
      renderToaster(getSpecificError(UNKNOWN_PROFILE_ADVANCED_DATA_UPDATE).message, ERROR_TOASTER);
      yield put(userUpdateProfileAdvancedDataFeedback());
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(userLoginFail({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
      yield put(userUpdateProfileAdvancedDataFeedback());
    }
  }
}

export function* userUpdateProfileAdvancedDataWatcher() {
  yield takeLatest(USER_UPDATE_PROFILE_ADVANCED_DATA, userUpdateProfileAdvancedDataWorker);
}

/**
 * Update user profile avatar
 */
function* userUpdateAvatarWorker(action) {
  try {
    const signedUrlsResult = yield call(() =>
      client.mutate({
        mutation: RequestUploadAccessMutation,
        variables: {
          uploadType: UPLOAD_TYPE_AVATAR,
        },
      })
    );

    const avatar = action.image;
    const signedUrlsData = JSON.parse(signedUrlsResult.data.requestUploadAccess.json);

    if (signedUrlsData.maxFileSize < avatar.size) {
      renderToaster(getSpecificError(UPLOAD_FILE_EXCEEDS_MAX_SIZE).message, ERROR_TOASTER);
      yield put(userUpdateProfileAdvancedDataFeedback());
    } else {
      const data = getFormData(avatar, signedUrlsData);

      const fileUploadRes = yield call(() => uploadFileToS3bucket(signedUrlsData.subdomainUrl, data));

      if (fileUploadRes.status !== 204) {
        renderToaster(getSpecificError(UNKNOWN_PROFILE_ADVANCED_DATA_UPDATE).message, ERROR_TOASTER);
        yield put(userUpdateProfileAdvancedDataFeedback());
      } else {
        const profileUpdateResult = yield call(() =>
          client.mutate({
            mutation: UserChangeAvatarMutation,
            variables: {
              avatar: signedUrlsData.resourceUrl,
            },
          })
        );

        const result = get(profileUpdateResult, 'data.changeAvatar.id', null);

        if (result) {
          renderToaster('successMessages.userProfileAdvancedDataUpdated', SUCCESS_TOASTER, { intlKey: true });
          yield put(userChangeAvatarSuccess({ avatar: signedUrlsData.resourceUrl }));
        } else {
          renderToaster(getSpecificError(UNKNOWN_PROFILE_ADVANCED_DATA_UPDATE).message, ERROR_TOASTER);
          yield put(userUpdateProfileAdvancedDataFeedback());
        }
      }
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(userLoginFail({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage());
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
      yield put(userUpdateProfileAdvancedDataFeedback());
    }
  }
}

export function* userUpdateAvatarWatcher() {
  yield takeLatest(USER_CHANGE_AVATAR, userUpdateAvatarWorker);
}

/**
 * Validate current user password
 */
function* userValidateCurrentUserPasswordWorker(action) {
  try {
    const recoverRequestResult = yield call(() =>
      client.mutate({
        mutation: ValidateCurrentUserPassword,
        variables: action.payload,
      })
    );

    const result = get(recoverRequestResult, 'data.userValidateCurrentPassword.passwordMatch', null);

    if (result) {
      yield put(userValidateCurrentPasswordSuccess());
    } else {
      yield put(userValidateCurrentPasswordFail({ errorMessage: UNKNOWN_USER_VALIDATE_CURRENT_PASSWORD }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(userValidateCurrentPasswordFail({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage({ errorMessage: errorObject.message }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
      yield put(userValidateCurrentPasswordFail({ errorMessage: errorObject.message }));
    }
  }
}

export function* userValidateCurrentUserPasswordWatcher() {
  yield takeLatest(USER_VALIDATE_CURRENT_PASSWORD, userValidateCurrentUserPasswordWorker);
}

/**
 * Delete user account
 */
function* userDeleteAccountWorker(action) {
  try {
    const recoverRequestResult = yield call(() =>
      client.mutate({
        mutation: DeleteMyAccountMutation,
        variables: action.payload,
      })
    );

    const result = get(recoverRequestResult, 'data.deleteMyAccount.id', null);

    if (result) {
      renderToaster('successMessages.userAccountDeleted', SUCCESS_TOASTER, { intlKey: true });
      yield put(userDeleteAccountSuccess());
    } else {
      renderToaster(getSpecificError(UNKNOWN_USER_DELETE_ACCOUNT).message, ERROR_TOASTER);
      yield put(userDeleteAccountFail());
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(userDeleteAccountFail({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(redirectToMaintenancePage({ errorMessage: errorObject.message }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
      yield put(userDeleteAccountFail({ errorMessage: errorObject.message }));
    }
  }
}

export function* userDeleteAccountWatcher() {
  yield takeLatest(USER_DELETE_ACCOUNT, userDeleteAccountWorker);
}
