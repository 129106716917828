// User Registration
export const USER_REGISTRATION = 'USER_REGISTRATION';
export const userRegistration = newUserData => ({
  type: USER_REGISTRATION,
  payload: newUserData,
});

export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const userRegistrationSuccess = payload => ({
  type: USER_REGISTRATION_SUCCESS,
  payload: payload,
});

export const USER_REGISTRATION_FAIL = 'USER_REGISTRATION_FAIL';
export const userRegistrationFail = errorMessage => ({
  type: USER_REGISTRATION_FAIL,
  payload: errorMessage,
});

// User account activation
export const USER_ACCOUNT_ACTIVATION = 'USER_ACCOUNT_ACTIVATION';
export const userAccountActivation = userData => ({
  type: USER_ACCOUNT_ACTIVATION,
  payload: userData,
});

export const USER_ACCOUNT_ACTIVATION_SUCCESS = 'USER_ACCOUNT_ACTIVATION_SUCCESS';
export const userAccountActivationSuccess = () => ({
  type: USER_ACCOUNT_ACTIVATION_SUCCESS,
});

export const USER_ACCOUNT_EMAIL_DEACTIVATED = 'USER_ACCOUNT_EMAIL_DEACTIVATED';
export const userAccountEmailDeactivated = () => {
  return {
    type: USER_ACCOUNT_EMAIL_DEACTIVATED,
  };
};

export const USER_ACCOUNT_ACTIVATION_FAIL = 'USER_ACCOUNT_ACTIVATION_FAIL';
export const userAccountActivationFail = errorMessage => ({
  type: USER_ACCOUNT_ACTIVATION_FAIL,
  payload: errorMessage,
});

// Resend user account activation email

export const USER_RESEND_ACTIVATION_EMAIL = 'USER_RESEND_ACTIVATION_EMAIL';
export const userResendActivationEmail = email => ({
  type: USER_RESEND_ACTIVATION_EMAIL,
  payload: email,
});

export const USER_RESEND_ACTIVATION_EMAIL_SUCCESS = 'USER_RESEND_ACTIVATION_EMAIL_SUCCESS';
export const userResendActivationEmailSuccess = () => ({
  type: USER_RESEND_ACTIVATION_EMAIL_SUCCESS,
});

export const USER_RESEND_ACTIVATION_EMAIL_FAIL = 'USER_RESEND_ACTIVATION_EMAIL_FAIL';
export const userResendActivationEmailFail = errorMessage => ({
  type: USER_RESEND_ACTIVATION_EMAIL_FAIL,
  payload: errorMessage,
});

// User Login
export const USER_LOGIN = 'USER_LOGIN';
export const userLogin = payload => ({
  type: USER_LOGIN,
  payload: payload,
});

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const userLoginSuccess = payload => ({
  type: USER_LOGIN_SUCCESS,
  payload: payload,
});

export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const userLoginFail = payload => ({
  type: USER_LOGIN_FAIL,
  payload: payload,
});

// User logout
export const USER_LOGOUT = 'USER_LOGOUT';
export const userLogout = payload => ({
  type: USER_LOGOUT,
  payload,
});

// Load data from auth JWT token on page refresh
export const AUTH_TOKEN_LOADED = 'AUTH_TOKEN_LOADED';
export const authTokenLoaded = payload => ({
  type: AUTH_TOKEN_LOADED,
  payload: payload,
});

export const AUTH_TOKEN_LOADED_SUCCESS = 'AUTH_TOKEN_LOADED_SUCCESS';
export const authTokenLoadedSuccess = payload => ({
  type: AUTH_TOKEN_LOADED_SUCCESS,
  payload: payload,
});

// Forgot password
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const userForgotPassword = email => ({
  type: USER_FORGOT_PASSWORD,
  payload: email,
});

export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const userForgotPasswordSuccess = () => ({
  type: USER_FORGOT_PASSWORD_SUCCESS,
});

export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL';
export const userForgotPasswordFail = payload => ({
  type: USER_FORGOT_PASSWORD_FAIL,
  payload: payload,
});

// Check user password reset token
export const USER_PASSWORD_RESET_TOKEN_CHECK = 'USER_PASSWORD_RESET_TOKEN_CHECK';
export const userPasswordResetTokenCheck = passwordResetToken => ({
  type: USER_PASSWORD_RESET_TOKEN_CHECK,
  payload: passwordResetToken,
});

export const USER_PASSWORD_RESET_TOKEN_CHECK_FAIL = 'USER_PASSWORD_RESET_TOKEN_CHECK_FAIL';
export const userPasswordResetTokenCheckFail = () => ({
  type: USER_PASSWORD_RESET_TOKEN_CHECK_FAIL,
});

// Reset password
export const USER_PASSWORD_RESET = 'USER_PASSWORD_RESET';
export const userPasswordReset = payload => ({
  type: USER_PASSWORD_RESET,
  payload: payload,
});

export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const userPasswordResetSuccess = payload => ({
  type: USER_PASSWORD_RESET_SUCCESS,
  payload: payload,
});

export const USER_PASSWORD_RESET_FAIL = 'USER_PASSWORD_RESET_FAIL';
export const userPasswordResetFail = payload => ({
  type: USER_PASSWORD_RESET_FAIL,
  payload: payload,
});

// Clear authentication errors for user
export const USER_CLEAR_ERROR_MESSAGE = 'USER_CLEAR_ERROR_MESSAGE';
export const clearUserErrorMessage = objectName => ({
  type: USER_CLEAR_ERROR_MESSAGE,
  payload: objectName,
});

export const USER_CLEAR_SUCCESS_MESSAGE = 'USER_CLEAR_SUCCESS_MESSAGE';
export const clearUserSuccessMessage = objectName => ({
  type: USER_CLEAR_SUCCESS_MESSAGE,
  payload: objectName,
});

// Update user data from apollo Mutation
export const USER_UPDATE_DATA_IN_STORE = 'USER_UPDATE_DATA_IN_STORE';
export const userUpdateDataInStore = payload => ({
  type: USER_UPDATE_DATA_IN_STORE,
  payload,
});
