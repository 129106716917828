import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ROUTE_TO_ADMIN_DASHBOARD_PAGE,
  ROUTE_TO_ADMIN_USERS_LIST_PAGE,
  ROUTE_TO_ADMIN_NEWS_PAGE,
  ROUTE_TO_ADMIN_EMPLOYEES_LIST_PAGE,
  ROUTE_TO_USER_DASHBOARD_PAGE,
  ROUTE_TO_ADMIN_TICKETING_PAGE,
  ROUTE_TO_ADMIN_TRANSACTIONS_PAGE,
} from '../../constants/routes';
import { SUPER_ADMIN, EMPLOYEE, ADMIN } from '../../constants/roles';
import { ReactComponent as HokHomeIcon } from '../../img/icons/hok-home.svg';
import { ReactComponent as HokCMSIcon } from '../../img/icons/hok-cms.svg';
import { ReactComponent as HokUsersIcon } from '../../img/icons/hok-users.svg';
import { ReactComponent as HokEmployeesIcon } from '../../img/icons/hok-employees.svg';
import { ReactComponent as HokUserDashboardIcon } from '../../img/icons/hok-private-user.svg';
import { ReactComponent as HokTicketingIcon } from '../../img/icons/hok-ticketing.svg';
import { ReactComponent as HokUserTransactionsIcon } from '../../img/icons/hok-transactions.svg';

const clientDashboardLink = {
  name: (
    <>
      <HokUserDashboardIcon className="svg-menu" stroke="white" />
      <FormattedMessage id="routes.userDashboard" />
    </>
  ),
  url: ROUTE_TO_USER_DASHBOARD_PAGE,
  icon: 'd-none',
};

const adminNavigation = [
  {
    name: (
      <>
        <HokHomeIcon className="svg-menu" />
        <FormattedMessage id="routes.adminDashboard" />
      </>
    ),
    url: ROUTE_TO_ADMIN_DASHBOARD_PAGE,
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <HokUsersIcon className="svg-menu" />
        <FormattedMessage id="routes.adminUsersList" />
      </>
    ),
    url: ROUTE_TO_ADMIN_USERS_LIST_PAGE,
    icon: 'd-none',
  },
  /*{
    name: (
      <>
        <img className="nav-icon" src={myVouchersIcon} alt="my vouchers" />
        <FormattedMessage id="routes.userTransactions" />
      </>
    ),
    url: 'ROUTE_TO_USER_DASHBOARD_PAGE',
    icon: 'd-none',
  },
  {
    name: (
      <>
        <img className="nav-icon" src={myDamagesIcon} alt="my damages" />
        <FormattedMessage id="routes.reportedDamages" />
      </>
    ),
    url: 'ROUTE_TO_USER_DASHBOARD_PAGE',
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <img className="nav-icon" src={commentsIcon} alt="assistance" />
        <FormattedMessage id="routes.digitalAssistance" />
      </>
    ),
    url: 'ROUTE_TO_USER_DASHBOARD_PAGE',
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <img className="nav-icon" src={boxesIcon} alt="policies" />
        <FormattedMessage id="routes.insurancePolicies" />
      </>
    ),
    url: 'ROUTE_TO_USER_DASHBOARD_PAGE',
    icon: 'd-none',
  },*/
  {
    name: (
      <>
        <HokEmployeesIcon className="svg-menu" />
        <FormattedMessage id="routes.employeesList" />
      </>
    ),
    url: ROUTE_TO_ADMIN_EMPLOYEES_LIST_PAGE,
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <HokCMSIcon className="svg-menu" />
        <FormattedMessage id="routes.CMS" />
      </>
    ),
    url: ROUTE_TO_ADMIN_NEWS_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokTicketingIcon className="svg-menu" />
        <FormattedMessage id="routes.ticketing" />
      </>
    ),
    url: ROUTE_TO_ADMIN_TICKETING_PAGE,
    icon: 'd-none',
  },
  {
    name: (
      <>
        <HokUserTransactionsIcon className="svg-menu" />
        <FormattedMessage id="routes.userTransactions" />
      </>
    ),
    url: ROUTE_TO_ADMIN_TRANSACTIONS_PAGE,
    icon: 'd-none',
  },
  /*{
    name: (
      <>
        <FontAwesomeIcon className="nav-icon" icon="exclamation-triangle" />
        <FormattedMessage id="routes.employeesRevisions" />
      </>
    ),
    url: 'ROUTE_TO_USER_DASHBOARD_PAGE',
    icon: 'd-none',
  },
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  {
    name: (
      <>
        <FontAwesomeIcon className="nav-icon" icon="question-circle" />
        <FormattedMessage id="routes.faq" />
      </>
    ),
    url: 'ROUTE_TO_USER_DASHBOARD_PAGE',
    icon: 'd-none',
  },*/
  {
    title: true,
    wrapper: {
      element: 'hr',
    },
    class: 'nav-title-divider',
  },
  clientDashboardLink,
];

export const adminSidebarNavigation = userRole => {
  switch (userRole) {
    case ADMIN:
    case SUPER_ADMIN:
    case EMPLOYEE:
      return { items: adminNavigation };
    default:
      return { items: [clientDashboardLink] };
  }
};
