import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { ReactComponent as HokBackIcon } from '../../img/icons/hok-back.svg';
import { ROUTE_TO_USER_DASHBOARD_PAGE } from '../../constants/routes';

const ArrowLinkButton = ({
  className = '',
  text = 'back',
  linkTo = ROUTE_TO_USER_DASHBOARD_PAGE,
  xsSize = 12,
  smSize = xsSize,
  mdSize = smSize,
  lgSize = mdSize,
  xlColSize = lgSize,
  colOffset = (12 - xlColSize) / 2,
  maxWidthSet = false,
  row = true,
}) => {
  const history = useHistory();

  const titleAlignHelper = () => (
    <div className="mx-auto title-text">
      <LinkComponent>
        <HokBackIcon />
        <span className="ml-2">
          <FormattedMessage id={text} />
        </span>
      </LinkComponent>
    </div>
  );

  const LinkComponent = ({ children }) => {
    if (linkTo) {
      return (
        <Link className="d-flex align-items-center" to={linkTo}>
          {children}
        </Link>
      );
    }
    return (
      <div className="hok-back-link d-flex align-items-center" onClick={() => history.goBack()}>
        {children}
      </div>
    );
  };

  const ColComponent = () => (
    <Col xs={xsSize} sm={smSize} md={mdSize} lg={lgSize} xl={{ size: xlColSize, offset: colOffset }}>
      {maxWidthSet ? (
        titleAlignHelper()
      ) : (
        <LinkComponent>
          <HokBackIcon />
          <span className="ml-2">
            <FormattedMessage id={text} />
          </span>
        </LinkComponent>
      )}
    </Col>
  );

  return row ? (
    <Row className={`no-gutters ${className}`.trim()}>
      <ColComponent />
    </Row>
  ) : (
    <ColComponent />
  );
};

export default ArrowLinkButton;
