import gql from 'graphql-tag';

export const UserSubmitContactFormMutation = gql`
  mutation UserSubmitContactForm(
    $type: String!
    $subject: String!
    $content: String!
    $email: String!
    $phoneNumber: String
    $additionalData: JSON
  ) {
    contactForm(
      type: $type
      subject: $subject
      content: $content
      email: $email
      phoneNumber: $phoneNumber
      additionalData: $additionalData
    ) {
      issueId
    }
  }
`;
