import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Label, InputGroup, Input, FormFeedback, Container } from 'reactstrap';
import { useMutation, useLazyQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { get, debounce } from 'lodash-es';
import { ReactComponent as HokInfoIcon } from '../../../img/icons/hok-info.svg';
import { SetServerStorageMutation } from '../../../operations/mutations/SetServerStorageMutation';
import {
  handleRequestError,
  HOK_AUTO_NOT_SUPPORTED_VEHICLE_TYPE,
  getSpecificError,
  getErrorObject,
  DUPLICATE_VIN_DETECTED,
} from '../../../constants/errorCodes';
import { ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_2, ROUTE_TO_USER_PRODUCT_ENQUIRY } from '../../../constants/routes';
import { POLICY_CODE_AO, STEP_2, VEHICLE_DATA_AO_SHORT, OSOBNO_VOZILO } from '../../../constants/business';
import { CustomButton, CollapsibleTextInfo, DescriptionText, Spinner } from '../../../containers';
import { PolicyContractingPersonInfo } from '../../../components/PolicyContractingPersonInfo';
import { PolicyInsuredPersonInfo } from '../../../components/PolicyInsuredPersonInfo';
import { USER_ACCOUNT_TYPES, USER_GENDER, USER_ACCOUNT_PRIVATE } from '../../../constants/userDefs';
import { usePlacesQuery, useGoogleAnalyticsPageview } from '../../../utils/customHooks';
import { validateFormSubmission } from '../../../utils/validation';
import { displayTotalPremium } from '../../../utils/currencyDisplayFormat';
import { UserInteractionModal } from '../../../components/UserInteractionModal';
import { VEHICLE_INFO_ENTRY, NOT_ELIGIBLE_AO_VEHICLE_TYPE } from '../../../constants/modalsContent';
import { GetAOPriceQuery } from '../../../operations/queries/GetPriceQuery';
import { GetUserVehicleDataQuery } from '../../../operations/queries/GetUserVehicleDataQuery';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../constants/support';
import { googleAnalyticsEvent } from '../../../utils/browser';
import { currencyConversionEuro } from '../../../utils/currencyConversion';
import { currencyDisplayUpdate } from '../../../components/CurrencyDisplay';

export const Step1AO = React.memo(({ data = {}, user, stateKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useGoogleAnalyticsPageview(
    stateKey ? '/Proizvodi_Autoodgovornost_UgovoriPolicu_Step1' : '/Proizvodi_Autoodgovornost_UgovoriPolicu'
  );


  const currencyDataConfig = useSelector(state => state.currencyConfig);
  currencyDisplayUpdate(currencyDataConfig.defaultCurrency);

  const [notEligible, setNotEligible] = useState(false);
  const [notEligibleReason, setNotEligibleReason] = useState('');

  const toggleEligibilityModal = () => {
    setNotEligible(currentState => !currentState);
  };

  const [submitForm, { loading: submitStep1AOLoading }] = useMutation(SetServerStorageMutation, {
    onCompleted: data =>
      history.push(`${ROUTE_TO_USER_NEW_AO_POLICY_PAGE_STEP_2}/${get(data, 'serverStorage.stateKey', '')}`),
    onError: error => handleRequestError(error, dispatch),
  });

  const [getVehicleData, { loading: vehicleDataLoading, data: vehicleQueryData, updateQuery }] = useLazyQuery(
    GetUserVehicleDataQuery,
    {
      onCompleted: data => {
        const vinNumber = get(data, 'vehicleData.brojSasije');
        if (vinNumber) {
          if (get(data, 'vehicleData.regOznaka') !== get(data, 'registrationPlate', '')) {
            setRegPlateInvalid({ invalid: false, error: '' });
          }
        }
      },
      onError: error => handleRequestError(error, dispatch, { silent: true }),
      fetchPolicy: 'network-only',
    }
  );

  const vehicleData = get(vehicleQueryData, 'vehicleData', null);

  const [calculateAOPrice, { data: priceData, loading, updateQuery: updateCalculationsQuery }] = useLazyQuery(
    GetAOPriceQuery,
    {
      onCompleted: data => {
        const vinNumber = get(data, 'calculatePriceHokAO.calculationsForVin', null);
        if (vinNumber) {
          const policyNumber = get(data, 'calculatePriceHokAO.calculationsForNumberOfPreviousInsurancePolicy', null);
          if (policyNumber) {
            policyNumberRef.current.value = policyNumber;
          }
          vinNumberRef.current.value = vinNumber;
          const registrationPlate = get(data, 'calculatePriceHokAO.calculationsForRegistrationPlate', null);
          if (get(registrationPlateRef, 'current.value', '') !== registrationPlate) {
            registrationPlateRef.current.value = registrationPlate;
          }
          setVinNumberInvalid({ invalid: false, error: '' });
          getVehicleData({ variables: { vinNumber } });
          if (!get(data, 'calculatePriceHokAO.eligibleForProduct')) {
            const eligibleError = get(data, 'calculatePriceHokAO.eligibleError.key', '');
            if (eligibleError) {
              if (eligibleError === HOK_AUTO_NOT_SUPPORTED_VEHICLE_TYPE) {
                setNotEligibleReason(NOT_ELIGIBLE_AO_VEHICLE_TYPE);
              } else {
                const error = getSpecificError(eligibleError);
                setNotEligibleReason(error.message);
              }
              setNotEligible(true);
            }
          }
        }
      },
      onError: error => {
        handleRequestError(error, dispatch, { silent: true }, () => {
          if (vehicleData && typeof updateQuery === 'function') {
            updateQuery(() => {
              return { vehicleData: null };
            });
          }
          if (priceData && typeof updateCalculationsQuery === 'function') {
            updateCalculationsQuery(() => {
              return { calculatePriceHokAO: null };
            });
          }
          const queryError = getErrorObject(error);
          if (queryError && queryError.key === DUPLICATE_VIN_DETECTED) {
            setNotEligibleReason(queryError.message);
            setNotEligible(true);
          }
        });
      },
      fetchPolicy: 'network-only',
    }
  );

  const { places, loading: loadingPlaces } = usePlacesQuery();

  const [regPlateInvalid, setRegPlateInvalid] = useState({ invalid: false, error: '' });
  const [vinNumberInvalid, setVinNumberInvalid] = useState({ invalid: false, error: '' });
  const [policyNumberInvalid, setPolicyNumberInvalid] = useState({ invalid: false, error: '' });
  const previousPolicy = React.useMemo(
    () => get(priceData, 'calculatePriceHokAO.calculationsForNumberOfPreviousInsurancePolicy'),
    [priceData]
  );

  const vinNumberRef = useRef();
  const registrationPlateRef = useRef();
  const policyNumberRef = useRef();

  const [oib, setOIB] = useState({
    value: get(data, 'insuredPeople[0].oib', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: true,
      oib: true,
    },
  });
  const [firstName, setFirstName] = useState({
    value: get(data, 'insuredPeople[0].name', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {
      required: true,
    },
  });
  const [lastName, setLastName] = useState({
    value: get(data, 'insuredPeople[0].surename', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {
      required: true,
    },
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value: get(data, 'insuredPeople[0].dateOfBirth', null) ? moment(data.insuredPeople[0].dateOfBirth) : null,
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.dateOfBirth" />,
    validation: {
      required: true,
    },
  });
  const [sex, setSex] = useState({
    value: get(data, 'insuredPeople[0].sex', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sex" />,
    validation: {
      required: true,
      enum: USER_GENDER.map(sex => sex.value),
    },
  });
  const [address, setAddress] = useState({
    value: get(data, 'insuredPeople[0].address', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });
  const [zip, setZip] = useState({
    value: get(data, 'insuredPeople[0].zip', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
    },
  });
  const [city, setCity] = useState({
    value: get(data, 'insuredPeople[0].city', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [email, setEmail] = useState({
    value: get(data, 'insuredPeople[0].email', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.email" />,
    validation: {
      required: true,
      email: true,
    },
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: get(data, 'insuredPeople[0].phoneNumber', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.phoneNumber" />,
    validation: {
      required: true,
    },
  });
  const [sameContracting, setSameContracting] = useState({
    value: get(data, 'sameContracting', true),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sameContracting" />,
    validation: {
      required: true,
      enum: USER_ACCOUNT_TYPES.map(type => type.name),
    },
  });
  const [sector, setSector] = useState({
    value: get(data, 'insuredPeople[0].sector', USER_ACCOUNT_PRIVATE),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.sector" />,
    validation: {
      required: true,
      enum: USER_ACCOUNT_TYPES.map(type => type.name),
    },
  });
  const [businessName, setBusinessName] = useState({
    value: get(data, 'insuredPeople[0].businessName', ''),
    error: null,
    invalid: false,
    displayName: <FormattedMessage id="inputs.businessName" />,
    validation: {
      required: true,
    },
  });

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const toggleInfoModal = () => {
    setIsInfoModalOpen(prevState => !prevState);
  };

  const delayedRegistrationPlateOnChange = debounce(value => {
    if (!vehicleData) {
      calculateAOPrice({
        variables: {
          productRelatedData: {
            registrationPlate: value,
            vin: '',
            covers: [],
          },
        },
      });
    }
  }, 500);

  const delayedVinNumberOnChange = debounce(value => {
    calculateAOPrice({
      variables: {
        productRelatedData: {
          registrationPlate: get(registrationPlateRef, 'current.value', ''),
          vin: value,
          covers: [],
        },
      },
    });
  }, 500);

  const delayedPolicyNumberOnChange = debounce(value => {
    calculateAOPrice({
      variables: {
        productRelatedData: {
          numberOfPreviousInsurancePolicy: value,
          vin: vehicleData ? get(vinNumberRef, 'current.value', '') : '',
          registrationPlate: '',
          covers: [],
        },
      },
    });
  }, 500);

  useEffect(() => {
    if (get(registrationPlateRef, 'current.value', '') || get(policyNumberRef, 'current.value', '')) {
      calculateAOPrice({
        variables: {
          productRelatedData: {
            registrationPlate: get(registrationPlateRef, 'current.value', '').toUpperCase(),
            numberOfPreviousInsurancePolicy: get(policyNumberRef, 'current.value', ''),
            vin: get(vinNumberRef, 'current.value', ''),
            covers: [],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStep1Submit = async event => {
    event.preventDefault();
    let isVinNumberValid = false;
    let isRegPlateValid = false;
    if (vinNumberRef.current.value) {
      if (get(vehicleData, 'brojSasije', '') === vinNumberRef.current.value) {
        isVinNumberValid = true;
      } else {
        setVinNumberInvalid({ invalid: true, error: 'Upišite valjani broj šasije' });
      }
    } else {
      setVinNumberInvalid({ invalid: true, error: 'Potrebno je upisati broj šasije' });
    }

    if (registrationPlateRef.current.value) {
      if (
        get(vehicleData, 'regOznaka', '') === registrationPlateRef.current.value ||
        (registrationPlateRef.current.value && vinNumberRef.current.value)
      ) {
        isRegPlateValid = true;
      } else {
        setRegPlateInvalid({ invalid: true, error: 'Upišite valjani broj registracijske tablice' });
      }
    } else {
      setRegPlateInvalid({ invalid: true, error: 'Potrebno je upisati broj registracijske tablice' });
    }

    const fieldsArray = [];

    if (
      (await validateFormSubmission(fieldsArray)) &&
      isRegPlateValid &&
      isVinNumberValid &&
      get(priceData, 'calculatePriceHokAO.eligibleForProduct', false)
    ) {
      const contractor = get(data, 'contractor', null) || {
        name: user.firstName,
        surename: user.lastName,
        sector: user.sector,
        businessName: user.businessName,
        mbo: user.mbo,
        dateOfBirth: user.dateOfBirth,
        sex: user.sex,
        oib: user.oib,
        address: user.address,
        zip: user.zip,
        city: user.city,
        phoneNumber: user.phoneNumber,
        email: user.email,
      };

      submitForm({
        variables: {
          attachToUser: true,
          state: {
            ...data,
            type: POLICY_CODE_AO,
            step: STEP_2,
            registrationPlate: registrationPlateRef.current.value,
            vin: vinNumberRef.current.value,
            numberOfPreviousInsurancePolicy: policyNumberRef.current.value,
            contractor: get(data, 'contractor', '') || contractor,
            insuredPerson: get(data, 'insuredPerson', '') || contractor,
            isRenewal: get(priceData, 'calculatePriceHokAO.isRenewal', null),
            covers:
              get(data, 'covers', null) || get(priceData, 'calculatePriceHokAO.coversOnPreviousInsurancePolicy', []),
            insuranceStartDate: get(priceData, 'calculatePriceHokAO.isRenewal', null)
              ? get(priceData, 'calculatePriceHokAO.insuranceStartDate', null)
              : null,
            totalPremium: get(priceData, 'calculatePriceHokAO.totalPremium', 0),
            productItems: get(priceData, 'calculatePriceHokAO.calculationItems', []),
            coverAdditionalData_HOK_AUTONEZGODA_OSIGURANA_SVOTA: get(
              priceData,
              'calculatePriceHokAO.coversOnPreviousInsurancePolicyAdditionalData.HOK_AUTONEZGODA_OSIGURANA_SVOTA',
              ''
            ),
            vehicle: {
              passengerNumber:
                get(
                  priceData,
                  'calculatePriceHokAO.coversOnPreviousInsurancePolicyAdditionalData.HOK_AUTONEZGODA_BROJ_PUTNIKA',
                  0
                ) || parseInt(get(vehicleData, 'brojMjestaSjedenje', 0), 10) - 1,
              vehicleType: get(vehicleData, 'vrstaEnumCode', OSOBNO_VOZILO),
              vehicleTypeCode: get(vehicleData, 'vrstaCode', '01'),
            },
            isTheSameOwner: get(priceData, 'calculatePriceHokAO.isTheSameOwner', true),
            isStartDayForced: get(priceData, 'calculatePriceHokAO.isStartDayForced', false),
            previousInsurancePolicyExpirationDate: get(
              priceData,
              'calculatePriceHokAO.previousInsurancePolicyExpirationDate',
              false
            ),
          },
        },
      });
    }
  };

  const resetAllVehicleInfo = useCallback(() => {
    vinNumberRef.current.value = '';
    setVinNumberInvalid({ invalid: false, error: '' });
    registrationPlateRef.current.value = '';
    setRegPlateInvalid({ invalid: false, error: '' });
    policyNumberRef.current.value = '';
    setPolicyNumberInvalid({ invalid: false, error: '' });
    if (vehicleData && typeof updateQuery === 'function') {
      updateQuery(() => {
        return { vehicleData: null };
      });
    }
    if (priceData && typeof updateCalculationsQuery === 'function') {
      updateCalculationsQuery(() => {
        return { calculatePriceHokAO: null };
      });
    }
  }, [updateQuery, vehicleData, priceData, updateCalculationsQuery]);

  return (
    <>
      <Form onSubmit={handleStep1Submit}>
        <Row className="my-4">
          <Col md="8" className="d-sm-flex align-items-center justify-content-between">
            <h3 className="mb-0">
              <FormattedMessage id="userMyPoliciesCarLiability.vehicleInfo" />:{' '}
              <HokInfoIcon className="svg-red svg-pad-bottom-half cursor-pointer" onClick={toggleInfoModal} />
            </h3>
            <CustomButton
              type="button"
              className="hok-button hok-sm-outline-btn mt-4 mt-sm-0"
              block={false}
              outline
              translationId="resetForm"
              disabled={loading || vehicleDataLoading || submitStep1AOLoading}
              onClick={resetAllVehicleInfo}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6" md="4">
            <Label for="licensePlate">
              <FormattedMessage id="inputs.licensePlate" />
            </Label>
            <InputGroup>
              <div className="w-100 position-relative">
                {(loading || vehicleDataLoading) && <Spinner className="input-spinner" size="sm" />}
                <Input
                  className="text-uppercase hok-input-loading"
                  id="licensePlate"
                  innerRef={registrationPlateRef}
                  defaultValue={get(data, 'registrationPlate', '')}
                  invalid={regPlateInvalid.invalid}
                  onChange={event => {
                    const value = event.target.value;
                    const registrationPlate = value.toUpperCase();
                    delayedRegistrationPlateOnChange(registrationPlate);
                    if (regPlateInvalid.invalid) {
                      setRegPlateInvalid({ invalid: false, error: '' });
                    }
                  }}
                />
              </div>
              {regPlateInvalid.invalid && regPlateInvalid.error && (
                <FormFeedback className="d-block">{regPlateInvalid.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
          <Col sm="6" md="4" className="d-flex justify-content-end flex-column">
            <Label for="vin">
              <FormattedMessage id="inputs.vin" />
            </Label>
            <InputGroup>
              <div className="w-100 position-relative">
                {(loading || vehicleDataLoading) && <Spinner className="input-spinner" size="sm" />}
                <Input
                  className="text-uppercase"
                  id="vin"
                  innerRef={vinNumberRef}
                  defaultValue={get(data, 'vin', '')}
                  invalid={vinNumberInvalid.invalid}
                  onChange={event => {
                    const value = event.target.value;
                    const vinNumber = value.toUpperCase();
                    delayedVinNumberOnChange(vinNumber);
                    if (vinNumberInvalid.invalid) {
                      setVinNumberInvalid({ invalid: false, error: '' });
                    }
                  }}
                />
              </div>
              {vinNumberInvalid.invalid && vinNumberInvalid.error && (
                <FormFeedback className="d-block">{vinNumberInvalid.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="6" md="4">
            <Label for="policyNumber">
              <FormattedMessage id="inputs.oldPolicy" />
            </Label>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm="6" md="4">
            <InputGroup>
              <div className="w-100 position-relative">
                {(loading || vehicleDataLoading) && policyNumberRef.current.value && (
                  <Spinner className="input-spinner" size="sm" />
                )}
                <Input
                  className="text-uppercase"
                  id="policyNumber"
                  innerRef={policyNumberRef}
                  defaultValue={
                    get(data, 'numberOfPreviousInsurancePolicy', '') || get(history, 'location.state.policyNumber', '')
                  }
                  invalid={policyNumberInvalid.invalid}
                  onChange={event => {
                    const value = event.target.value;
                    delayedPolicyNumberOnChange(value);
                    if (policyNumberInvalid.invalid) {
                      setPolicyNumberInvalid({ invalid: false, error: '' });
                    }
                  }}
                />
              </div>
              {policyNumberInvalid.invalid && policyNumberInvalid.error && (
                <FormFeedback className="d-block">{policyNumberInvalid.error}</FormFeedback>
              )}
            </InputGroup>
          </Col>
          {vehicleData && (
            <Col sm="6" md="4" className="d-flex justify-content-end flex-column">
              <CollapsibleTextInfo uppercase bold placeholder="userMyPoliciesCarLiability.vehicleData">
                <Container>
                  <Row>
                    <Col xs={12}>
                      <DescriptionText value={VEHICLE_DATA_AO_SHORT} data={vehicleData} />
                    </Col>
                  </Row>
                </Container>
              </CollapsibleTextInfo>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col xs="12">
            <PolicyContractingPersonInfo user={user} labelId="userMyPolicies.policyData.policyContractor" />
            <PolicyInsuredPersonInfo
              user={user}
              places={places}
              loadingPlaces={loadingPlaces}
              sameContracting={sameContracting}
              setSameContracting={setSameContracting}
              showHzzo={false}
              sector={sector}
              setSector={setSector}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              businessName={businessName}
              setBusinessName={setBusinessName}
              oib={oib}
              setOIB={setOIB}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              sex={sex}
              setSex={setSex}
              address={address}
              setAddress={setAddress}
              city={city}
              setCity={setCity}
              zip={zip}
              setZip={setZip}
              email={email}
              setEmail={setEmail}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              labelId="userMyPolicies.policyData.policyPersonInsured"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <span className="primary">
              <FormattedMessage id="userMyPolicies.policyData.premium" />:
            </span>

            {currencyDataConfig.dualCurrencyDisplay === true && (
              <>
                <span className="secondary ml-3">
                  {displayTotalPremium(
                    get(priceData, 'calculatePriceHokAO.totalPremium', undefined),
                    get(data, 'totalPremium', undefined)
                  )}{' '}
                  {/* |{' '}
              {currencyConversionEuro(
                get(priceData, 'calculatePriceHokAO.totalPremium', 0),
                get(data, 'totalPremium', 0)
              )} */}
                </span>
              </>
            )}

            {currencyDataConfig.dualCurrencyDisplay === false && (
              <>
                <span className="secondary ml-3">
                  {displayTotalPremium(
                    get(priceData, 'calculatePriceHokAO.totalPremium', undefined),
                    get(data, 'totalPremium', undefined)
                  )}
                </span>
              </>
            )}
            {/* <span className="secondary ml-3">
              {currencyDataConfig.dualCurrencyDisplay === true && <></>}
              {displayTotalPremium(
                get(priceData, 'calculatePriceHokAO.totalPremium', undefined),
                get(data, 'totalPremium', undefined)
              )} {' '}
              |{' '}
              {currencyConversionEuro(
                get(priceData, 'calculatePriceHokAO.totalPremium', 0),
                get(data, 'totalPremium', 0)
              )}
            </span> */}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="12">
            <CustomButton
              className="hok-dashboard-btn-wide float-sm-right"
              translationId="continue"
              disabled={
                !get(priceData, 'calculatePriceHokAO.eligibleForProduct', false) ||
                loading ||
                vehicleDataLoading ||
                submitStep1AOLoading
              }
              loaderProp={loading || vehicleDataLoading || submitStep1AOLoading}
            />
          </Col>
        </Row>
      </Form>
      <UserInteractionModal
        wide
        isOpen={isInfoModalOpen}
        toggle={toggleInfoModal}
        titleId={`modalHeaders.vehicleInfoEntry`}
        textId={VEHICLE_INFO_ENTRY}
      />
      <UserInteractionModal
        isOpen={notEligible}
        toggle={() => {
          resetAllVehicleInfo();
          toggleEligibilityModal();
        }}
        titleId={
          notEligibleReason === NOT_ELIGIBLE_AO_VEHICLE_TYPE
            ? 'modalHeaders.noSupportedVehicleType'
            : 'modalHeaders.warning'
        }
        textComponent={
          notEligibleReason === NOT_ELIGIBLE_AO_VEHICLE_TYPE ? (
            <DescriptionText value={notEligibleReason} data={get(vehicleData, 'vrstaEnumCode', '')} />
          ) : (
            <Row className="no-gutters">
              <Col sm={{ size: 10, offset: 1 }}>
                <p className="mb-0 font-weight-bold text-center">Ugovaranje police nije moguće:</p>
                <p className="text-left">{notEligibleReason}</p>
              </Col>
            </Row>
          )
        }>
        <CustomButton
          className="hok-outline-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          outline
          block={false}
          translationId="close"
          onClick={() => {
            resetAllVehicleInfo();
            toggleEligibilityModal();
          }}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="requestOffer"
          onClick={() => {
            googleAnalyticsEvent({ category: 'Proizvodi', action: 'Proizvodi_Autoodgovornost_Zatrazi_Ponudu' });
            history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
              subject: ENQUIRY_SUBJECT_NEW_POLICY,
              title: 'Zahtjev za ponudom "Autoodgovornost"',
              content: `${previousPolicy ? `Obnova` : 'Kupnja nove'} police "Autoodgovornost"${
                previousPolicy ? `. Broj prethodne police: ${previousPolicy}.` : ''
              }`,
            });
          }}
        />
      </UserInteractionModal>
    </>
  );
});
