import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { get } from 'lodash-es';
import { ROUTE_TO_USER_TICKETING_PAGE } from '../../../constants/routes';
import { Query } from 'react-apollo';
import { handleRequestError } from '../../../constants/errorCodes';
import { SingleTicketData } from '../../../components/SingleTicketData';
import { Spinner } from '../../../containers';
import { GetSingleUserTicketQuery } from '../../../operations/queries/GetUserTicketsQuery';

export const SingleTicket = () => {
  const params = useParams();
  const ticketId = get(params, 'ticketId', null);
  const dispatch = useDispatch();
  return (
    <Query
      query={GetSingleUserTicketQuery}
      variables={{ number: ticketId }}
      onError={error => handleRequestError(error, dispatch)}
    >
      {({ loading, data, error }) => {
        if (loading || (!data && !error)) {
          return <Spinner className="spinner-positioning" />;
        }
        if (get(data, 'getSingleTicket.tickets.length', 0) < 1) {
          return <Redirect to={ROUTE_TO_USER_TICKETING_PAGE} />;
        }
        const ticket = get(data, 'getSingleTicket.tickets[0]', null);
        return (
          <SingleTicketData
            ticketId={ticketId}
            ticket={ticket}
            baseRoute={ROUTE_TO_USER_TICKETING_PAGE}
            backRoute={ROUTE_TO_USER_TICKETING_PAGE}
          />
        );
      }}
    </Query>
  );
};
