import React, { useState /*useEffect*/, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row,
  Col,
  CardGroup,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Form,
  FormGroup,
  FormFeedback,
  Alert,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import moment, { ISO_8601 } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomInputField, CustomButton } from '../../../containers';
import AccountVerificationForm from './AccountVerificationForm';
import { validateFormSubmission } from '../../../utils/validation';
import { Mutation } from 'react-apollo';
import { get, debounce } from 'lodash-es';
import { UserUpdatePrivateUserMutation } from '../../../operations/mutations/UserUpdateProfileMutation';
import {
  getErrorObject,
  isAuthTokenInvalid,
  isMaintenanceInProgress,
  INVALID_OIB_FORMAT,
} from '../../../constants/errorCodes';
import {
  redirectToMaintenancePage,
  redirectToRegistrationCompletePage,
} from '../../../redux/actions/navigationActions';
import { userLoginFail, userUpdateDataInStore } from '../../../redux/actions/userAuthActions';
import { SearchForExistInHokMutation } from '../../../operations/mutations/SearchForExistInHokMutation';
import { SelectPartner } from './SelectPartner';
import { USER_ACCOUNT_PRIVATE, USER_GENDER } from '../../../constants/userDefs';
import { FORMAT_STANDARD_DATE, yearListRender } from '../../../constants/dateFormatting';
import { v4 } from 'uuid';

export const PrivateUserForm = ({ countries, loadingCountries, places, loadingPlaces, user, ...props }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [dateInputFocused, setDateInputFocused] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [selectPartner, setSelectPartner] = useState(false);
  const [partnerSelected, setPartnerSelected] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [OIB, setOIB] = useState({
    value: user.oib || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.OIB" />,
    validation: {
      required: true,
      maxLength: 11,
      minLength: 11,
      oib: true,
    },
  });
  const [firstName, setFirstName] = useState({
    value: user.firstName || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.firstName" />,
    validation: {
      required: true,
    },
  });
  const [lastName, setLastName] = useState({
    value: user.lastName || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.lastName" />,
    validation: {
      required: true,
    },
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value: moment(user.dateOfBirth, ISO_8601, true).isValid() ? moment(user.dateOfBirth, ISO_8601, true) : null,
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.dateOfBirth" />,
    validation: {
      required: true,
      date: {
        valid: true,
        maxValue: moment().subtract(18, 'year'),
      },
    },
  });
  const [sex, setSex] = useState({
    value: user.sex || '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.sex" />,
    validation: {
      required: true,
      enum: USER_GENDER.map(gender => gender.value),
    },
  });
  const [address, setAddress] = useState({
    value: user.address || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.address" />,
    validation: {
      required: true,
    },
  });
  const [zip, setZip] = useState({
    value: user.zip || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.zipCode" />,
    validation: {
      required: true,
      enum: places.map(place => place.pttNumber),
    },
  });
  const [city, setCity] = useState({
    value: user.city || '',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.city" />,
    validation: {
      required: true,
      enum: places.map(place => place.name),
    },
  });
  const [country, setCountry] = useState({
    value: user.country || 'HR',
    error: null,
    invalid: true,
    focused: false,
    displayName: <FormattedMessage id="inputs.country" />,
    validation: {
      required: true,
      enum: countries.map(country => country.codeLegacy),
    },
  });

  const [filteredPlaces, setFilteredPlaces] = useState([...places]);

  useEffect(() => {
    if (places.length > 0) {
      setFilteredPlaces([...places]);
    }
  }, [places]);

  const debouncedPlacesFilter = debounce(value => {
    const matchingPlaces = places.filter(place => place.pttNumber.startsWith(value));
    setFilteredPlaces(value ? matchingPlaces : [...places]);
    if (matchingPlaces.length === 1 && matchingPlaces[0].pttNumber === value) {
      setCity(city => ({ ...city, invalid: false, value: matchingPlaces[0].name }));
    } else {
      setCity(city => ({ ...city, invalid: false, value: '' }));
    }
  }, 500);

  useEffect(() => {
    if (places.length > 0 && city.validation.enum.length < 1) {
      setCity(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.name) } }));
    }
    if (places.length > 0 && zip.validation.enum.length < 1) {
      setZip(o => ({ ...o, validation: { ...o.validation, enum: places.map(place => place.pttNumber) } }));
    }

    if (countries.length > 0 && country.validation.enum.length < 1) {
      setCountry(o => ({
        ...o,
        validation: { ...o.validation, enum: countries.map(country => country.codeLegacy) },
      }));
    }
  }, [places, city, countries, country, zip]);

  const inputHandler = (setter, value) => {
    setter(o => ({ ...o, value, invalid: false }));
  };

  const checkIfInvalidOibError = error => {
    const errorObject = getErrorObject(error);
    if (errorObject.key === INVALID_OIB_FORMAT) {
      OIB.error !== errorObject.message && setOIB(o => ({ ...o, invalid: true, error: errorObject.message }));
    } else {
      return errorObject.message;
    }
  };

  return (
    <Row className="no-gutters">
      <Mutation
        mutation={SearchForExistInHokMutation}
        onCompleted={data => {
          const count = get(data, 'searchProfileForExistingHokAccount.count', 0);
          if (!count) {
            dispatch(
              userUpdateDataInStore({
                verified: true,
                partnerId: get(data, 'searchProfileForExistingHokAccount.partnerId', null),
              })
            );
            dispatch(redirectToRegistrationCompletePage());
          } else {
            setProfileUpdated(true);
          }
          count > 1 && setSelectPartner(true);
        }}
        onError={error => {
          const errorObject = getErrorObject(error);
          if (isAuthTokenInvalid(errorObject)) {
            dispatch(userLoginFail({ errorMessage: errorObject.message }));
          } else if (isMaintenanceInProgress(errorObject)) {
            dispatch(redirectToMaintenancePage());
          }
        }}
      >
        {(searchForProfileInCubis, { loading: searchLoading, data }) => {
          const count = get(data, 'searchProfileForExistingHokAccount.count', 0);

          const partners = get(data, 'searchProfileForExistingHokAccount.partners', []);

          return (
            <Col xs="12" md={{ offset: 2, size: 8 }}>
              <Mutation
                mutation={UserUpdatePrivateUserMutation}
                onCompleted={data => {
                  dispatch(userUpdateDataInStore({ id: data.updateProfile.id, ...data.updateProfile.profile }));
                  searchForProfileInCubis();
                  props.setIsStep3(true);
                }}
                onError={error => {
                  const errorObject = getErrorObject(error);
                  if (isAuthTokenInvalid(errorObject)) {
                    dispatch(userLoginFail({ errorMessage: errorObject.message }));
                  } else if (isMaintenanceInProgress(errorObject)) {
                    dispatch(redirectToMaintenancePage());
                  }
                }}
              >
                {(updateProfile, { loading, error }) => {
                  const handleUserDataSubmit = async event => {
                    event.preventDefault();

                    const fieldsArray = [
                      setOIB,
                      setFirstName,
                      setLastName,
                      setDateOfBirth,
                      setSex,
                      setAddress,
                      setZip,
                      setCity,
                      setCountry,
                    ];

                    setSubmitted(true);
                    if (await validateFormSubmission(fieldsArray)) {
                      updateProfile({
                        variables: {
                          oib: OIB.value,
                          firstName: firstName.value,
                          lastName: lastName.value,
                          dateOfBirth: dateOfBirth.value,
                          sex: sex.value,
                          address: address.value,
                          zip: zip.value,
                          city: city.value,
                          sector: USER_ACCOUNT_PRIVATE,
                          country: country.value,
                        },
                      });
                    }
                  };

                  let displayError = null;
                  if (error) {
                    displayError = checkIfInvalidOibError(error);
                  }

                  return (
                    <Form onSubmit={handleUserDataSubmit}>
                      <CardGroup>
                        <Card className="content-card">
                          <CardHeader className="border-0">
                            <h2 className="mx-auto">
                              <FormattedMessage id="userTypeSelectPage.privateUser.title" />
                            </h2>
                            {profileUpdated && (
                              <FontAwesomeIcon className="color-green" icon="check-circle" size="lg" />
                            )}
                          </CardHeader>
                          <Collapse isOpen={!profileUpdated}>
                            <CardBody>
                              <p className="text-left">
                                <FormattedMessage id="userTypeSelectPage.privateUser.paragraph1" />
                              </p>
                              <Row className="mt-4">
                                <Col>
                                  {displayError && (
                                    <Alert className="mb-0" color="danger" fade={false}>
                                      {displayError}
                                    </Alert>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col xs="12" sm={{ offset: 2, size: 8 }}>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <CustomInputField
                                        id="OIB"
                                        name="OIB"
                                        value={OIB.value}
                                        fieldState={OIB}
                                        formSubmitted={submitted}
                                        pattern="[0-9]*"
                                        onChange={event => {
                                          if (event.target.validity.valid) {
                                            inputHandler(setOIB, event.target.value);
                                          }
                                        }}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <CustomInputField
                                        id="firstName"
                                        name="first name"
                                        value={firstName.value}
                                        fieldState={firstName}
                                        formSubmitted={submitted}
                                        onChange={event => inputHandler(setFirstName, event.target.value)}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <CustomInputField
                                        id="lastName"
                                        name="last name"
                                        value={lastName.value}
                                        fieldState={lastName}
                                        formSubmitted={submitted}
                                        onChange={event => inputHandler(setLastName, event.target.value)}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <SingleDatePicker
                                        id="dateOfBirth"
                                        hideKeyboardShortcutsPanel
                                        date={dateOfBirth.value}
                                        onDateChange={date => setDateOfBirth({ ...dateOfBirth, value: date })}
                                        firstDayOfWeek={1}
                                        numberOfMonths={1}
                                        small
                                        focused={dateInputFocused}
                                        onFocusChange={({ focused }) => setDateInputFocused(focused)}
                                        displayFormat={FORMAT_STANDARD_DATE}
                                        placeholder={formatMessage({ id: 'inputs.dateOfBirth' })}
                                        isOutsideRange={date => date.isAfter(moment().subtract(18, 'year'), 'day')}
                                        initialVisibleMonth={() => dateOfBirth.value || moment().subtract(18, 'year')}
                                        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                                          <div className="d-flex justify-content-center">
                                            <div className="mr-1">
                                              <select
                                                value={month.month()}
                                                onChange={e => onMonthSelect(month, e.target.value)}
                                              >
                                                {moment.months().map((label, value) => (
                                                  <option value={value} key={label}>
                                                    {label}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                            <div className="ml-1">
                                              <select
                                                value={month.year()}
                                                onChange={e => onYearSelect(month, e.target.value)}
                                              >
                                                {yearListRender()}
                                              </select>
                                            </div>
                                          </div>
                                        )}
                                      />
                                      {dateOfBirth.error && submitted && (
                                        <FormFeedback className="d-block">{dateOfBirth.error}</FormFeedback>
                                      )}
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <CustomInputField
                                        id="sex"
                                        name="sex"
                                        type="select"
                                        value={sex.value}
                                        fieldState={sex}
                                        formSubmitted={submitted}
                                        onChange={event => {
                                          inputHandler(setSex, event.target.value);
                                        }}
                                      >
                                        <option disabled value="">
                                          {formatMessage({ id: 'inputs.sex' })}
                                        </option>
                                        {USER_GENDER.map(gender => (
                                          <option key={gender.value} value={gender.value}>
                                            {formatMessage({ id: gender.name })}
                                          </option>
                                        ))}
                                      </CustomInputField>
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <CustomInputField
                                        id="address"
                                        name="address"
                                        value={address.value}
                                        fieldState={address}
                                        formSubmitted={submitted}
                                        onChange={event => inputHandler(setAddress, event.target.value)}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <CustomInputField
                                        id="zipCode"
                                        name="zip code"
                                        value={zip.value}
                                        fieldState={zip}
                                        formSubmitted={submitted}
                                        pattern="[0-9]*"
                                        onChange={event => {
                                          event.persist();
                                          debouncedPlacesFilter(event.target.value);
                                          setZip(zip => ({ ...zip, invalid: false, value: event.target.value }));
                                        }}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <CustomInputField
                                        id="city"
                                        name="city"
                                        type="select"
                                        value={city.value}
                                        fieldState={city}
                                        formSubmitted={submitted}
                                        onChange={event => {
                                          const place = places.find(place => place.name === event.target.value);
                                          inputHandler(setCity, event.target.value);
                                          place && inputHandler(setZip, place.pttNumber);
                                        }}
                                      >
                                        <option disabled value="">
                                          {formatMessage({
                                            id: 'inputs.labels.selectPlace',
                                          })}
                                        </option>
                                        {loadingPlaces ? (
                                          <option disabled value={city.value}>
                                            {city.value}
                                          </option>
                                        ) : (
                                          filteredPlaces.map(place => (
                                            <option key={v4()} value={place.name}>
                                              {place.name}
                                            </option>
                                          ))
                                        )}
                                      </CustomInputField>
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col xs="12">
                                      <CustomInputField
                                        id="country"
                                        name="country"
                                        type="select"
                                        value={country.value}
                                        fieldState={country}
                                        formSubmitted={submitted}
                                        disabled
                                        // onChange={event => inputHandler(setCountry, event.target.value)}
                                      >
                                        <option disabled value="">
                                          {formatMessage({
                                            id: 'inputs.country',
                                          })}
                                        </option>
                                        {loadingCountries ? (
                                          <option disabled value="">
                                            {formatMessage({
                                              id: loadingCountries ? 'loading' : 'inputs.country',
                                            })}
                                          </option>
                                        ) : (
                                          countries.map(country => (
                                            <option key={country.codeLegacy} value={country.codeLegacy}>
                                              {country.nameCro}
                                            </option>
                                          ))
                                        )}
                                      </CustomInputField>
                                    </Col>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </CardGroup>
                      {!profileUpdated && (
                        <Row className="no-gutters mt-5">
                          <Col sm={{ offset: 2, size: 8 }} className="px-3">
                            <CustomButton translationId="check" loaderProp={loading || searchLoading} />
                          </Col>
                        </Row>
                      )}
                    </Form>
                  );
                }}
              </Mutation>
              {profileUpdated && selectPartner && (
                <SelectPartner
                  partners={partners}
                  searchFunc={searchForProfileInCubis}
                  loaderProp={searchLoading}
                  setPartnerSelected={setPartnerSelected}
                  partnerSelected={partnerSelected}
                />
              )}
              {profileUpdated && (count === 1 || partnerSelected) && (
                <AccountVerificationForm partnerId={selectPartner} />
              )}
            </Col>
          );
        }}
      </Mutation>
    </Row>
  );
};
