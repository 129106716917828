import gql from 'graphql-tag';

export const WriteTicketMessageMutation = gql`
  mutation WriteTicketMessage(
    $ticketNumber: String
    $content: String!
    $files: [getTicketMessageFilterFileInput]
    $userId: String
    $agentNickname: String
  ) {
    createTicketMessage(
      ticketNumber: $ticketNumber
      content: $content
      files: $files
      userId: $userId
      agentNickname: $agentNickname
    ) {
      ticketNumber
      content
      userId
    }
  }
`;
