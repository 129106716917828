import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader } from 'reactstrap';
import { useIntl } from 'react-intl';

//Images
import { ReactComponent as HokWarningIcon } from '../../img/icons/hok-warning.svg';
import keyboardRightIcon from '../../img/icons/hok-chevron-right.svg';

export const DamageClaimCard = ({ damageClaim, isClaimNew = false, redirectAction }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const renderStatus = className => (
    <p className={className}>
      <span>{formatMessage({ id: 'inputs.status' })}:</span>{' '}
      <span className="font-weight-bold text-uppercase">{damageClaim.userFriendlyStatus}</span>
    </p>
  );

  return (
    <Card className="insurance-policy-container">
      <CardHeader
        className="hok-damage-claim-card-header cursor-pointer"
        onClick={() => {
          if (damageClaim.claimNo) {
            dispatch(redirectAction(`${damageClaim.claimClass}-${damageClaim.claimNo}`));
          } else {
            dispatch(redirectAction(damageClaim.claimConfirmationCode));
          }
        }}>
        <div className="d-flex align-items-center">
          <h4 className="main-title mr-sm-4">
            {damageClaim.claimNo || damageClaim.claimConfirmationCode}{' '}
            <span className="hok-claim-type">{damageClaim.claimDefinition.group}</span>
          </h4>
          {renderStatus('desktop-container subtitle-text ml-0')}
          {isClaimNew && (
            <HokWarningIcon className="d-none d-md-block ml-auto mr-4 desktop-container svg-product-card svg-warning" />
          )}
        </div>
        {isClaimNew && <HokWarningIcon className="hok-claim-mobile-warning svg-product-card svg-warning" />}
        <img
          alt="collapse-toggler"
          src={keyboardRightIcon}
          className={'collapse-toggler' + (isClaimNew ? '' : ' ml-auto')}
        />
      </CardHeader>
    </Card>
  );
};
