import React from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useIntl } from 'react-intl';
import { range } from 'lodash-es';
import { ReactComponent as HokChevronLeftIcon } from '../../img/icons/hok-chevron-left.svg';
import { ReactComponent as HokChevronRightIcon } from '../../img/icons/hok-chevron-right.svg';

export const CustomPagination = ({ loading, currentPage, pages, paginate }) => {
  const { formatMessage } = useIntl();

  const previusPages =
    currentPage - 1 > 5
      ? pages - currentPage < 5
        ? Math.min(pages, 10) - (pages - currentPage + 1)
        : 5
      : currentPage - 1;
  const nextPages =
    pages - currentPage > 5 ? (currentPage - 1 < 5 ? Math.min(pages, 10) - currentPage : 5) : pages - currentPage;
  return (
    <Row className="no-gutters">
      <Col>
        <Pagination>
          <PaginationItem disabled={currentPage === 1 || loading}>
            <PaginationLink className="arrow" onClick={() => paginate(1)}>
              <HokChevronLeftIcon />
            </PaginationLink>
          </PaginationItem>
          {currentPage > 1 &&
            range(currentPage - previusPages, currentPage).map(page => (
              <PaginationItem disabled={loading} key={page}>
                <PaginationLink
                  aria-label={formatMessage({ id: 'userMyDamageClaims.pagination.firstPage' })}
                  onClick={() => paginate(page)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            ))}

          <PaginationItem disabled={loading} active={true}>
            <PaginationLink
              aria-label={formatMessage({ id: 'userMyDamageClaims.pagination.firstPage' })}
              onClick={null}>
              {currentPage}
            </PaginationLink>
          </PaginationItem>

          {currentPage < pages &&
            range(currentPage + 1, currentPage + nextPages + 1).map(page => (
              <PaginationItem disabled={loading} key={page}>
                <PaginationLink
                  aria-label={formatMessage({ id: 'userMyDamageClaims.pagination.firstPage' })}
                  onClick={() => paginate(page)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            ))}
          <PaginationItem disabled={currentPage === pages || loading}>
            <PaginationLink
              aria-label={formatMessage({ id: 'userMyDamageClaims.pagination.lastPage' })}
              className="arrow"
              onClick={() => paginate(pages)}>
              <HokChevronRightIcon />
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </Col>
    </Row>
  );
};
