import React from 'react';
import { useDispatch } from 'react-redux';
import { CardGroup, Card, CardBody, Row, Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import FileSaver from 'file-saver';
import { FileDownloadButton } from '../FileDownloadButton';
import { handleRequestError } from '../../constants/errorCodes';
import { Spinner } from '../../containers';
import { renderToaster, ERROR_TOASTER } from '../../constants/toaster';
import { GetTicketFilesQuery } from '../../operations/queries/GetTicketMessages';

export const TicketDocumentation = ({ ticket }) => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GetTicketFilesQuery, {
    variables: { ticketNumber: ticket.number },
    onError: error => handleRequestError(error, dispatch, { silent: true }),
    fetchPolicy: 'network-only',
  });

  const files = get(data, 'getTicketFiles.ticketFiles', []);

  return (
    <CardGroup>
      <Card className="hok-card">
        <CardBody>
          <Row className="my-4">
            {(loading || (!data && !error)) && (
              <Col xs="12">
                <Spinner className="spinner-positioning" />
              </Col>
            )}
            {((files.length < 1 && !loading) || error) && (
              <Col xs="12">
                <p className="hok-text-dark-500 mb-0">
                  <FormattedMessage id="userMyTickets.noTicketFiles" />
                </p>
              </Col>
            )}
            {files.map((file, index) => (
              <Col className="mb-2" xs="12" sm="6" key={index}>
                <FileDownloadButton
                  name={file.name || `dokument-${file.id}`}
                  datetime={file.createdAt}
                  onClick={async () => {
                    try {
                      const response = await fetch(file.fileUrl);
                      const blob = await response.blob();
                      FileSaver.saveAs(blob, file.name || `dokument-${file.id}`);
                    } catch (e) {
                      renderToaster(`errors.errorWhileDownloadingFile`, ERROR_TOASTER, { intlKey: true });
                    }
                  }}
                />
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </CardGroup>
  );
};
