import { select, put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { client } from '../../config/apolloConfig';
import {
  REDIRECT_TO_ADMIN_LOGIN,
  REDIRECT_TO_ADMIN_DASHBOARD,
  REDIRECT_TO_USER_DASHBOARD_PAGE,
  REDIRECT_TO_USER_ACCOUNT_INFO_PAGE,
  REDIRECT_TO_MAINTENANCE_PAGE,
  REDIRECT_TO_USER_LOGIN_PAGE,
  REDIRECT_TO_USER_PRODUCTS_PAGE,
  REDIRECT_TO_ADMIN_CREATE_USER_PAGE,
  REDIRECT_TO_ADMIN_EDIT_POST_PAGE,
  REDIRECT_TO_ADMIN_CREATE_POST_PAGE,
  REDIRECT_TO_ADMIN_EDIT_USER_PAGE,
  REDIRECT_TO_ADMIN_EDIT_DELETED_USER_PAGE,
  REDIRECT_TO_REGISTRATION_COMPLETE_PAGE,
  REDIRECT_TO_ADMIN_CREATE_EMPLOYEE_PAGE,
  REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_PAGE,
  REDIRECT_TO_ADMIN_EMPLOYEE_LIST_PAGE,
  REDIRECT_TO_ADMIN_USER_LIST_PAGE,
  REDIRECT_TO_ADMIN_PREVIEW_POST_PAGE,
  REDIRECT_TO_USER_NEW_TRAVEL_INSURANCE_PAGE,
  REDIRECT_TO_USER_NEW_HEALTH_INSURANCE_PAGE,
  REDIRECT_TO_SPECIFIC_DAMAGE_CLAIM_PAGE,
  REDIRECT_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_PAGE,
  REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_PAGE,
  REDIRECT_TO_ADMIN_SINGLE_TICKET_PAGE,
} from '../actions/navigationActions';
import { GENERAL_ERROR } from '../actions/errorActions';
import { ADMIN_LOGIN_SUCCESS, ADMIN_LOGIN_FAILURE, ADMIN_AUTH_LOGOUT } from '../actions/adminAuthActions';
import {
  USER_REGISTRATION_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_ACCOUNT_ACTIVATION_SUCCESS,
  USER_PASSWORD_RESET_TOKEN_CHECK_FAIL,
  USER_LOGOUT,
  USER_ACCOUNT_EMAIL_DEACTIVATED,
} from '../actions/userAuthActions';
import {
  ROUTE_TO_USER_DASHBOARD_PAGE,
  ROUTE_TO_USER_SELECTION_PAGE,
  ROUTE_TO_USER_LOGIN_PAGE,
  ROUTE_TO_USER_POLICIES_PAGE,
  ROUTE_TO_ADMIN_DASHBOARD_PAGE,
  ROUTE_TO_ADMIN_LOGIN_PAGE,
  ROUTE_TO_ADMIN_ERROR_PAGE,
  ROUTE_TO_ADMIN_CREATE_USER_PAGE,
  ROUTE_TO_MAINTENANCE_PAGE,
  ROUTE_TO_ACTIVATE_ACCOUNT_PAGE,
  ROUTE_TO_ADMIN_EDIT_POST,
  ROUTE_TO_ADMIN_CREATE_POST,
  ROUTE_TO_ADMIN_EDIT_USER_PAGE,
  ROUTE_TO_ADMIN_EMPLOYEE_CREATE_PAGE,
  ROUTE_TO_ADMIN_EMPLOYEES_LIST_PAGE,
  ROUTE_TO_ADMIN_USERS_LIST_PAGE,
  ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE,
  ROUTE_TO_USER_PASSWORD_EXPIRED,
  ROUTE_TO_ADMIN_NEWS_PAGE,
  ROUTE_TO_ADMIN_EDIT_EMPLOYEE_PAGE,
  ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE,
  ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE,
  ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE,
  ROUTE_TO_ADMIN_TICKETING_PAGE,
} from '../../constants/routes';
import { ADMIN } from '../../constants/roles';
import { removeLocalStorageToken } from '../../constants/LocalStorageKeys';
import { USER_CHANGE_PASSWORD_SUCCESS } from '../actions/userProfileActions';

/** Worker Sagas **/

function* redirectToAdminLoginPageWorker() {
  const path = yield select(state => state.router.location.pathname);
  yield call(() => client.cache.reset());
  if (path !== ROUTE_TO_ADMIN_LOGIN_PAGE) {
    yield put(push(ROUTE_TO_ADMIN_LOGIN_PAGE));
  }
}

function* redirectOnSuccessfulAdminLoginWorker() {
  const path = yield select(state => state.router.location.pathname);
  const today = new Date();
  const passwordExpireDate = new Date(yield select(state => state.auth.user.passwordExpires));
  if (passwordExpireDate <= today) {
    yield put(push(ROUTE_TO_USER_PASSWORD_EXPIRED));
  } else if (path === ROUTE_TO_ADMIN_LOGIN_PAGE) {
    yield put(push(ROUTE_TO_ADMIN_DASHBOARD_PAGE));
  } else {
    yield put(push(path));
  }
}

function* redirectToAdminDashboardPageWorker() {
  yield put(push(ROUTE_TO_ADMIN_DASHBOARD_PAGE));
}

function* redirectToAdminCreateUserPageWorker() {
  yield put(push(ROUTE_TO_ADMIN_CREATE_USER_PAGE));
}

function* redirectToUserDashboardPageWorker() {
  yield put(push(ROUTE_TO_USER_DASHBOARD_PAGE));
}

function* redirectToUserAccountInfoWorker(action) {
  yield put(push(`${ROUTE_TO_USER_SELECTION_PAGE}/${action.accountType}`));
}

function* redirectToAdminErrorPageWorker() {
  yield put(push(ROUTE_TO_ADMIN_ERROR_PAGE));
}

function* redirectToAdminEmployeeListPageWorker() {
  yield put(push(ROUTE_TO_ADMIN_EMPLOYEES_LIST_PAGE));
}

function* redirectToAdminUserListPageWorker() {
  yield put(push(ROUTE_TO_ADMIN_USERS_LIST_PAGE));
}

function* redirectToMaintenancePageWorker() {
  yield put(push(ROUTE_TO_MAINTENANCE_PAGE));
}

function* redirectToActivateAccountPageWorker() {
  yield put(push(ROUTE_TO_ACTIVATE_ACCOUNT_PAGE));
}

function* userLoginSuccessWorker(action) {
  const userAccountStatus = yield select(state => state.auth.user);
  const today = new Date();
  const passwordExpireDate = new Date(yield select(state => state.auth.user.passwordExpires));
  if (!userAccountStatus.activated) {
    yield put(push(ROUTE_TO_ACTIVATE_ACCOUNT_PAGE));
  } else if (!userAccountStatus.sector) {
    yield put(push(ROUTE_TO_USER_SELECTION_PAGE));
  } else if (!userAccountStatus.verified) {
    yield put(push(`${ROUTE_TO_USER_SELECTION_PAGE}/${userAccountStatus.sector}`));
  } else {
    if (passwordExpireDate <= today) {
      yield put(push(ROUTE_TO_USER_PASSWORD_EXPIRED));
    } else {
      if (action.payload.from) {
        yield put(push(action.payload.from.pathname, action.payload.from.state));
      } else {
        yield put(push(ROUTE_TO_USER_DASHBOARD_PAGE));
      }
    }
  }
}

function* userLoginFailWorker() {
  const path = yield select(state => state.router.location.pathname);
  yield call(() => client.cache.reset());
  if (path !== ROUTE_TO_USER_LOGIN_PAGE) {
    yield put(push(ROUTE_TO_USER_LOGIN_PAGE));
  }
}

function* userPasswordChangeSuccessWorker() {
  const path = yield select(state => state.router.location.pathname);
  const userRole = yield select(state => state.auth.user.role);
  if (path === ROUTE_TO_USER_PASSWORD_EXPIRED) {
    if (userRole === ADMIN) {
      yield put(push(ROUTE_TO_ADMIN_DASHBOARD_PAGE));
    } else {
      yield put(push(ROUTE_TO_USER_DASHBOARD_PAGE));
    }
  }
}

function* userAccountActivationSuccessWorker() {
  const partnerId = yield select(state => state.auth.user.partnerId);
  if (partnerId) {
    yield put(push(ROUTE_TO_USER_DASHBOARD_PAGE));
  } else {
    yield put(push(ROUTE_TO_USER_SELECTION_PAGE));
  }
}

function* redirectToUserLoginPageWorker() {
  yield put(push(ROUTE_TO_USER_LOGIN_PAGE));
}

function* redirectToUserRegistrationCompletePageWorker(action) {
  yield put(push(ROUTE_TO_USER_REGISTRATION_COMPLETE_PAGE, { completed: true, ...action.payload }));
}

function* userLogoutWorker(action) {
  removeLocalStorageToken('clearAuthorizedUserFromLocalStorage');
  yield call(() => client.cache.reset());
  yield put(push(ROUTE_TO_USER_LOGIN_PAGE, action.payload));
}

function* redirectToUserProductsPageWorker() {
  yield put(push(ROUTE_TO_USER_POLICIES_PAGE));
}

function* redirectToUserNewTravelInsurancePageWorker() {
  yield put(push(ROUTE_TO_USER_NEW_TRAVEL_POLICY_PAGE));
}

function* redirectToUserNewHealthInsurancePageWorker() {
  yield put(push(ROUTE_TO_USER_NEW_HEALTH_POLICY_PAGE));
}

function* redirectToSpecificDamageClaimPageWorker(action) {
  yield put(push(`${ROUTE_TO_USER_MY_DAMAGE_CLAIMS_PAGE}/${action.claimNumber}`));
}

function* redirectToAdminPreviewPostPageWorker(action) {
  yield put(push(`${ROUTE_TO_ADMIN_NEWS_PAGE}/${action.payload}`));
}

function* redirectToAdminEditPostPageWorker(action) {
  yield put(push(`${ROUTE_TO_ADMIN_EDIT_POST}/${action.payload}`));
}

function* redirectToAdminCreatePostWorker() {
  yield put(push(ROUTE_TO_ADMIN_CREATE_POST));
}

function* redirectToAdminEditUserPageWorker(action) {
  yield put(push(`${ROUTE_TO_ADMIN_EDIT_USER_PAGE}/${action.payload}`));
}

function* redirectToAdminEditDeletedUserPageWorker(action) {
  yield put(push(`${ROUTE_TO_ADMIN_EDIT_USER_PAGE}/${action.payload.userId}?deleted=${action.payload.deleted}`));
}

function* redirectToAdminEditUserSingleClaimPageWorker(action) {
  yield put(
    push(`${ROUTE_TO_ADMIN_EDIT_USER_PAGE}/${action.payload.userId}/damage-claims/${action.payload.claimNumber}`)
  );
}

function* redirectToAdminEditEmployeeSingleClaimPageWorker(action) {
  yield put(
    push(`${ROUTE_TO_ADMIN_EDIT_EMPLOYEE_PAGE}/${action.payload.userId}/damage-claims/${action.payload.claimNumber}`)
  );
}

function* redirectToAdminCreateEmployeePageWorker() {
  yield put(push(ROUTE_TO_ADMIN_EMPLOYEE_CREATE_PAGE));
}

function* redirectToAdminEditEmployeePageWorker(action) {
  yield put(push(`${ROUTE_TO_ADMIN_EDIT_EMPLOYEE_PAGE}/${action.payload}`));
}

function* redirectToAdminSingleTicketPageWorker(action) {
  yield put(push(`${ROUTE_TO_ADMIN_TICKETING_PAGE}/${action.payload}`));
}

/** Watcher Sagas **/

export function* redirectToAdminLoginPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_LOGIN, redirectToAdminLoginPageWorker);
}

export function* redirectToAdminDashboardPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_DASHBOARD, redirectToAdminDashboardPageWorker);
}

export function* redirectToUserDashboardPageWatcher() {
  yield takeLatest(REDIRECT_TO_USER_DASHBOARD_PAGE, redirectToUserDashboardPageWorker);
}

export function* redirectToUserAccountInfoWatcher() {
  yield takeLatest(REDIRECT_TO_USER_ACCOUNT_INFO_PAGE, redirectToUserAccountInfoWorker);
}

export function* adminLoginSuccessWatcher() {
  yield takeLatest(ADMIN_LOGIN_SUCCESS, redirectOnSuccessfulAdminLoginWorker);
}

export function* adminLoginFailureWatcher() {
  yield takeLatest(ADMIN_LOGIN_FAILURE, redirectToAdminLoginPageWorker);
}

export function* adminLogoutWatcher() {
  yield takeLatest(ADMIN_AUTH_LOGOUT, redirectToAdminLoginPageWorker);
}

export function* redirectToAdminErrorPageWatcher() {
  yield takeLatest(GENERAL_ERROR, redirectToAdminErrorPageWorker);
}

export function* redirectToAdminEmployeeListPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_EMPLOYEE_LIST_PAGE, redirectToAdminEmployeeListPageWorker);
}

export function* redirectToAdminUserListPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_USER_LIST_PAGE, redirectToAdminUserListPageWorker);
}

export function* redirectToMaintenancePageWatcher() {
  yield takeLatest(REDIRECT_TO_MAINTENANCE_PAGE, redirectToMaintenancePageWorker);
}

export function* redirectToActivateAccountPageWatcher() {
  yield takeLatest(USER_REGISTRATION_SUCCESS, redirectToActivateAccountPageWorker);
  yield takeLatest(USER_ACCOUNT_EMAIL_DEACTIVATED, redirectToActivateAccountPageWorker);
}

export function* userLoginSuccessWatcher() {
  yield takeLatest(USER_LOGIN_SUCCESS, userLoginSuccessWorker);
}

export function* userLoginFailWatcher() {
  yield takeLatest(USER_LOGIN_FAIL, userLoginFailWorker);
}

export function* userLogoutWatcher() {
  yield takeLatest(USER_LOGOUT, userLogoutWorker);
}

export function* userPasswordChangeSuccessWatcher() {
  yield takeLatest(USER_CHANGE_PASSWORD_SUCCESS, userPasswordChangeSuccessWorker);
}

export function* userAccountActivationSuccessWatcher() {
  yield takeLatest(USER_ACCOUNT_ACTIVATION_SUCCESS, userAccountActivationSuccessWorker);
}

export function* userPasswordResetTokenCheckFailWatcher() {
  yield takeLatest(USER_PASSWORD_RESET_TOKEN_CHECK_FAIL, redirectToUserLoginPageWorker);
}

export function* redirectToUserLoginPageWatcher() {
  yield takeLatest(REDIRECT_TO_USER_LOGIN_PAGE, redirectToUserLoginPageWorker);
}

export function* redirectToUserRegistrationCompletePageWatcher() {
  yield takeLatest(REDIRECT_TO_REGISTRATION_COMPLETE_PAGE, redirectToUserRegistrationCompletePageWorker);
}

export function* redirectToUserProductsPageWatcher() {
  yield takeLatest(REDIRECT_TO_USER_PRODUCTS_PAGE, redirectToUserProductsPageWorker);
}

export function* redirectToUserNewTravelInsurancePageWatcher() {
  yield takeLatest(REDIRECT_TO_USER_NEW_TRAVEL_INSURANCE_PAGE, redirectToUserNewTravelInsurancePageWorker);
}

export function* redirectToUserNewHealthInsurancePageWatcher() {
  yield takeLatest(REDIRECT_TO_USER_NEW_HEALTH_INSURANCE_PAGE, redirectToUserNewHealthInsurancePageWorker);
}

export function* redirectToSpecificDamageClaimPageWatcher() {
  yield takeLatest(REDIRECT_TO_SPECIFIC_DAMAGE_CLAIM_PAGE, redirectToSpecificDamageClaimPageWorker);
}

export function* redirectToAdminCreateUserPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_CREATE_USER_PAGE, redirectToAdminCreateUserPageWorker);
}

export function* redirectToAdminEditPostPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_EDIT_POST_PAGE, redirectToAdminEditPostPageWorker);
}

export function* redirectToAdminPreviewPostPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_PREVIEW_POST_PAGE, redirectToAdminPreviewPostPageWorker);
}

export function* redirectToAdminCreatePostWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_CREATE_POST_PAGE, redirectToAdminCreatePostWorker);
}

export function* redirectToAdminEditUserPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_EDIT_USER_PAGE, redirectToAdminEditUserPageWorker);
}

export function* redirectToAdminEditDeletedUserPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_EDIT_DELETED_USER_PAGE, redirectToAdminEditDeletedUserPageWorker);
}

export function* redirectToAdminEditUserSingleClaimPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_EDIT_USER_SINGLE_CLAIM_PAGE, redirectToAdminEditUserSingleClaimPageWorker);
}

export function* redirectToAdminEditEmployeeSingleClaimPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_SINGLE_CLAIM_PAGE, redirectToAdminEditEmployeeSingleClaimPageWorker);
}

export function* redirectToAdminCreateEmployeePageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_CREATE_EMPLOYEE_PAGE, redirectToAdminCreateEmployeePageWorker);
}

export function* redirectToAdminEditEmployeePageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_EDIT_EMPLOYEE_PAGE, redirectToAdminEditEmployeePageWorker);
}

export function* redirectToAdminSingleTicketPageWatcher() {
  yield takeLatest(REDIRECT_TO_ADMIN_SINGLE_TICKET_PAGE, redirectToAdminSingleTicketPageWorker);
}
