import gql from 'graphql-tag';

export const ValidateCurrentUserPassword = gql`
  mutation userValidateCurrentPassword($password: String!) {
    userValidateCurrentPassword(password: $password) {
      id
      passwordMatch
    }
  }
`;

export default ValidateCurrentUserPassword;
