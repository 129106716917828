import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from '../../../containers';
import { SupportInfoLink } from '../SupportInfo/SupportInfoLink';

export const VerificationFailedModal = ({ isOpen, toggle }) => {
  return (
    <Modal modalClassName="hok-modal" centered toggle={toggle} isOpen={isOpen}>
      <ModalHeader>
        <Row className="no-gutters auth-nav-links">
          <Col xs={12}>
            <h2 className="d-flex align-items-center justify-content-center">
              <FormattedMessage id="userTypeSelectPage.verificationFailedModal.title" />
            </h2>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row className="no-gutters px-0 px-sm-4">
          <Col xs={12}>
            <Row className="no-gutters my-5">
              <Col>
                <p className="modal-text-small">
                  <FormattedMessage id="userTypeSelectPage.verificationFailedModal.message" />
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={{ offset: 2, size: 8 }}>
                <CustomButton translationId="back" onClick={toggle} />
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <SupportInfoLink />
      </ModalFooter>
    </Modal>
  );
};
