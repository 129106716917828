import React from 'react';
import { Container, Card, CardGroup, CardBody, Col, Row } from 'reactstrap';
import { BackgroundAnimation } from '../../components/BackgroundAnimation';

const UserAuthContainer = props => {
  return (
    <div className="d-flex align-items-center auth-container">
      <BackgroundAnimation />
      <Container fluid>
        <Row className="no-gutters">
          <Col
            xs={12}
            sm={{ offset: 1, size: 10 }}
            md={{ offset: 0, size: 12 }}
            className="d-flex justify-content-center"
          >
            <CardGroup className="auth-card-group">
              <Card className="auth-card w-100">
                <CardBody className="p-0">
                  <div className="d-block d-md-flex align-items-center">{props.children}</div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserAuthContainer;
