import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ArrowLinkButton, CustomButton } from '../../../../containers';
import {
  ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
} from '../../../../constants/routes';
import { PreContractualDocumentation } from '../../../../components/PreContractualDocumentation';
import { POLICY_CODE_PROPERTY_OTHER } from '../../../../constants/business';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../../constants/support';
import { googleAnalyticsEvent } from '../../../../utils/browser';
import { AdFormIFrame } from '../../../../components/AdFormIframe';

export const OtherPropertyInsurance = () => {
  const history = useHistory();

  return (
    <Container fluid className="policies">
      <AdFormIFrame title="Proizvodi - Imovina - Ostalo" />
      <ArrowLinkButton xlColSize={10} linkTo={ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE} />
      <Row className="mt-4">
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <h3>
            <FormattedMessage id="routes.otherPropertyInsurance" />
          </h3>
          <Row className="no-gutters mt-5 mx-3">
            <Col xs={12}>
              <h3 className="mb-4">Požar i neki drugi rizici</h3>
              <p className="font-weight-bold">Koje rizike pokriva?</p>
              <p>Šteta na imovini bit će Vam nadoknađena u slučaju ostvarenja slijedećih rizika:</p>
              <ul>
                <li>požara i udar groma</li>
                <li>eksplozije osim nuklearne</li>
                <li>oluje</li>
                <li>tuče</li>
                <li>manifestacija i demonstracija</li>
                <li>pada i udara zračne letjelice</li>
                <li>
                  udara vlastitog motornog vozila, vlastitog pokretnog radnog stroja i nepoznatog motornog vozila u
                  osigurani građevinski objekt.
                </li>
              </ul>
              <p>Uz ove osnovne rizike dopunskim ugovaranjem pokriće možete proširiti i na rizik:</p>
              <ul>
                <li>izlijevanja vode iz vodovodnih i kanalizacijskih cijevi</li>
                <li>poplave i bujice</li>
                <li>klizanja tla i odronjavanja zemljišta</li>
                <li>istjecanja tekućine [lekaža]</li>
                <li>samozapaljenja zaliha</li>
              </ul>
              <p className="font-weight-bold">Što se sve može osigurati?</p>
              <ul>
                <li>Građevinski objekti</li>
                <li>pokretnine, namještaj, uređaji i strojevi, razni alati</li>
                <li>zalihe robe, sirovina, poluproizvoda, pomoćnog i potrošnog materijala</li>
                <li>ograde, potporni zidovi, ceste, putovi, pločnici, popločena dvorišta</li>
                <li>niske i slične gradnje</li>
                <li>novac i vrijednosni papiri</li>
                <li>stvari na izložbama i sajmovima</li>
              </ul>
            </Col>
          </Row>
          <PreContractualDocumentation productCode={POLICY_CODE_PROPERTY_OTHER} />
          <Row className="no-gutters mx-3 mt-5">
            <Col className="text-center">
              <CustomButton
                className="hok-dashboard-btn-wide"
                block={false}
                translationId="requestOffer"
                onClick={() => {
                  googleAnalyticsEvent({
                    category: 'Proizvodi',
                    action: 'Proizvodi_Imovina_Ostala_Osiguranja_Zatrazi_Ponudu',
                  });
                  history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                    subject: ENQUIRY_SUBJECT_NEW_POLICY,
                    title: 'Kupnja nove police "OSTALA OSIGURANJA IMOVINE"',
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
