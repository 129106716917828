import gql from 'graphql-tag';

export const GetSupportDataQuery = gql`
  query GetSupportData {
    settings {
      INFORMATION {
        name
        address
        oib
        tradeCourtText
      }
      SUPPORT {
        email
        phoneNumber
        faxNumber
        claimReportPhoneNumber
        claimReportLegacyWebUrl
        HR_ASSISTANCE_phoneNumber
        HR_ASSISTANCE_phoneNumber_from_hr
        HE_ASSISTANCE_phoneNumber
        HE_ASSISTANCE_phoneNumber_from_hr
        HE_ASSISTANCE_AXA_phoneNumber
        HR_RENT_A_CAR_ASSISTANCE_phoneNumber
        HR_RENT_A_CAR_ASSISTANCE_phoneNumber_from_hr
        PZO_ASSISTANCE_phoneNumber
        PZO_ASSISTANCE_phoneNumber_from_hr
        KA_ASSISTANCE_phoneNumber
        KA_ASSISTANCE_phoneNumber_from_hr
        KA_ASSISTANCE_email
      }
    }
  }
`;
