import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import logoOriginalImg from '../../img/logo-original.svg';
import maintenanceImg from '../../img/maintenance.svg';

const Maintenance = () => {
  return (
    <Container fluid className="maintenance">
      <div className="my-5">
        <Row className="no-gutters mb-5 mb-lg-0">
          <Col xs={10} sm={6} md={4}>
            <img className="img-fluid" src={logoOriginalImg} alt="HOK Osiguranje" />
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col sm={12} lg={6} className="d-flex align-items-center justify-content-center">
            <div className="ml-0 ml-sm-3 ml-lg-4 ml-xl-5">
              <Row className="no-gutters">
                <Col xs={12} sm={10} md={8} lg={11}>
                  <h1>
                    <FormattedMessage id="maintenancePage.title" />
                  </h1>
                </Col>
              </Row>
              <Row className="no-gutters">
                <Col xs={12} sm={10} lg={12}>
                  <h2>
                    <FormattedMessage id="maintenancePage.message" />
                  </h2>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={{ offset: 2, size: 8 }} lg={{ offset: 0, size: 6 }} className="text-center mt-5 mt-lg-0">
            <img className="img-fluid" src={maintenanceImg} alt="Maintenance" />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Maintenance;
