import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Collapse, Fade, Form } from 'reactstrap';
import { CustomDashboardInput } from '../../containers';
import { IconButton } from '../IconButton';
import { ReactComponent as HokSendIcon } from '../../img/icons/hok-send.svg';
import { ReactComponent as HokCancelIcon } from '../../img/icons/hok-close.svg';
import { FormattedMessage } from 'react-intl';
import { validateFormSubmission } from '../../utils/validation';
import { UserCreateClaimCommentMutation } from '../../operations/mutations/UserCreateClaimCommentMutation';
import { useMutation } from 'react-apollo';
import { renderToaster, SUCCESS_TOASTER } from '../../constants/toaster';
import { handleRequestError } from '../../constants/errorCodes';

export const DamageClaimWriteComment = ({ isOpen, close, claimParams, paginate }) => {
  const dispatch = useDispatch();
  const initialCommentValue = {
    value: '',
    error: null,
    invalid: false,
    focused: false,
    displayName: <FormattedMessage id="inputs.comment" />,
    validation: {
      required: true,
    },
  };
  const [comment, setComment] = useState(initialCommentValue);

  const [createComment, { loading }] = useMutation(UserCreateClaimCommentMutation, {
    variables: { ...claimParams, comment: comment.value },
    onCompleted: () => {
      close();
      paginate(1);
      renderToaster(`successMessages.claimCommentCreated`, SUCCESS_TOASTER, { intlKey: true });
    },
    onError: error => handleRequestError(error, dispatch),
  });

  const handleCommentSubmit = async event => {
    event.preventDefault();

    if (await validateFormSubmission([setComment])) {
      createComment();
      setComment(initialCommentValue);
    }
  };

  return (
    <Fade in={isOpen}>
      <Collapse isOpen={isOpen}>
        <Form onSubmit={handleCommentSubmit}>
          <Row className="no-gutters">
            <Col>
              <CustomDashboardInput
                className="hok-comment-textarea"
                type="textarea"
                value={comment.value}
                fieldState={comment}
                onChange={event => setComment({ ...comment, value: event.target.value, invalid: false, error: null })}
              />
            </Col>
          </Row>
          <Row className="no-gutters mt-3">
            <Col>
              <div className="float-right">
                <IconButton
                  type="button"
                  size="38"
                  className="mr-3"
                  Icon={HokCancelIcon}
                  outline
                  onClick={close}
                  disabled={loading}
                />
                <IconButton size="38" Icon={HokSendIcon} disabled={loading} />
              </div>
            </Col>
          </Row>
        </Form>
      </Collapse>
    </Fade>
  );
};
