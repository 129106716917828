import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ArrowLinkButton, CustomButton } from '../../../../containers';
import {
  ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE,
  ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
} from '../../../../constants/routes';
import { PreContractualDocumentation } from '../../../../components/PreContractualDocumentation';
import { POLICY_CODE_HE } from '../../../../constants/business';
import { UserInteractionModal } from '../../../../components/UserInteractionModal';
import { SELF_INSURANCE_CONTRACTING, CONTINUE_PREVIOUS_CONTRACTING } from '../../../../constants/modalsContent';
import { ENQUIRY_SUBJECT_NEW_POLICY } from '../../../../constants/support';
import { useServerStorageFromProfileKey } from '../../../../utils/customHooks';
import { googleAnalyticsEvent } from '../../../../utils/browser';
import { AdFormIFrame } from '../../../../components/AdFormIframe';

export const ExpressAssistance = () => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenContinue, setIsOpenContinue] = useState(false);

  const { serverStorageData, stateKey } = useServerStorageFromProfileKey();

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const toggleContinueModal = () => {
    setIsOpenContinue(prevState => !prevState);
  };

  return (
    <Container fluid className="policies">
      <AdFormIFrame title="Proizvodi - Asistencija - HOK Express" />
      <ArrowLinkButton xlColSize={10} linkTo={ROUTE_TO_USER_POLICIES_ASSISTANCE_PAGE} />
      <Row className="mt-4">
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <h3>
            <FormattedMessage id="routes.expressAssistance" />
          </h3>
          <Row className="no-gutters mt-5 mx-3">
            <Col xs="12">
              <p>
                HOK Express najbrža je asistencija koja Vam, u Hrvatskoj i inozemstvu bez obzira na krivnju za prometnu
                nezgodu, omogućava:
              </p>
              <Row className="mt-4" style={{ margin: 'auto', maxWidth: '1150px' }}>
                <Col sm="6">
                  <ul>
                    <li>Popravak vozila ili vuču vozila u Hrvatskog i inozemstvu u slučaju nesreće ili kvara</li>
                    <li>Zamjensko vozilo</li>
                    <li>Otključavanje ili odvoženje vozila u slučaju zaključanog ili izgubljenog ključa</li>
                    <li>Vuču vozila u slučaju punjenja spremnika neodgovarajućim gorivom</li>
                    <li>Pomoć u slučaju nedostatka goriva</li>
                  </ul>
                </Col>
                <Col sm="6">
                  <ul>
                    <li>
                      Pokriće troškova povratka i smještaja u slučaju nezgode ili kvara u Hrvatskoj i u inozemstvu
                    </li>
                    <li>Dodatno pokriće različitih troškova u slučaju nezgode u inozemstvu</li>
                    <li>
                      Osiguranje osoba od posljedica nesretnog slučaja (nezgode) za korisnika kartice bez obzira je li u
                      trenutku nesretnog slučaja upravljao vozilom ili ne
                    </li>
                    <li>Osiguranje pravne zaštite</li>
                    <li>Dostavljanje rezervnih dijelova</li>
                  </ul>
                </Col>
              </Row>
              <p>
                Ovo pokriće mogu ugovoriti fizičke ili pravne osobe koje imaju zaključenu policu obveznog osiguranja od
                automobilske odgovornosti ili policu osiguranja automobilskog kaska s HOK osiguranjem d.d.
              </p>
            </Col>
          </Row>
          <PreContractualDocumentation productCode={POLICY_CODE_HE} />
          <Row className="no-gutters mx-3 mt-5">
            <Col className="text-center">
              <CustomButton
                className="hok-dashboard-btn-wide"
                block={false}
                translationId="newPolicyShort"
                onClick={() => {
                  setIsOpen(true);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <UserInteractionModal
        isOpen={isOpen}
        toggle={toggle}
        titleId="modalHeaders.warning"
        textId={SELF_INSURANCE_CONTRACTING}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="forSomeoneElse"
          onClick={() => {
            googleAnalyticsEvent({ category: 'Proizvodi', action: 'Proizvodi_Asistencije_Hok_Express_Zatrazi_Ponudu' });
            history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
              subject: ENQUIRY_SUBJECT_NEW_POLICY,
              title: 'Kupnja nove police "HOK Express"',
            });
          }}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="forMyself"
          onClick={() => {
            toggle();
            if (serverStorageData && serverStorageData.type === POLICY_CODE_HE) {
              toggleContinueModal();
            } else {
              history.push(ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE);
            }
          }}
        />
      </UserInteractionModal>
      <UserInteractionModal
        isOpen={isOpenContinue}
        toggle={toggleContinueModal}
        titleId="modalHeaders.warning"
        textId={CONTINUE_PREVIOUS_CONTRACTING}>
        <CustomButton
          type="button"
          outline
          className="hok-outline-btn d-block d-sm-inline-block mx-0 mr-sm-1 w-xs-100"
          block={false}
          translationId="no"
          onClick={() => {
            toggleContinueModal();
            history.push(ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE);
          }}
        />
        <CustomButton
          className="hok-dashboard-btn d-block d-sm-inline-block mx-0 ml-sm-1 mt-2 mt-sm-0 w-xs-100"
          block={false}
          translationId="yes"
          onClick={() => {
            history.push(`${ROUTE_TO_USER_NEW_EXPRESS_POLICY_PAGE}/${stateKey}`);
          }}
        />
      </UserInteractionModal>
    </Container>
  );
};
