import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ArrowLinkButton, DescriptionText, CollapsiblePackageInfo, CustomButton } from '../../../../containers';
import {
  ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE,
  ROUTE_TO_USER_PRODUCT_ENQUIRY,
} from '../../../../constants/routes';
import { PreContractualDocumentation } from '../../../../components/PreContractualDocumentation';
import { POLICY_CODE_HOK_APARTMAN, HOK_APARTMAN_COVERS, HOK_KUCNA_ASISTENCIJA } from '../../../../constants/business';
import { ENQUIRY_SUBJECT_APARTMENT } from '../../../../constants/support';
import { googleAnalyticsEvent } from '../../../../utils/browser';
import { AdFormIFrame } from '../../../../components/AdFormIframe';

export const ApartmentInsurance = () => {
  const history = useHistory();
  const supportData = useSelector(state => state.support);

  return (
    <Container fluid className="policies">
      <AdFormIFrame title="Proizvodi - Imovina - HOK Apartman" />
      <ArrowLinkButton xlColSize={10} linkTo={ROUTE_TO_USER_POLICIES_PROPERTY_INSURANCE_PAGE} />
      <Row className="mt-4">
        <Col xs={12} xl={{ size: 10, offset: 1 }}>
          <h3>
            <FormattedMessage id="routes.apartmentInsurance" />
          </h3>
          <Row className="no-gutters mt-5 mx-3">
            <Col xs={12}>
              <p>
                Paket osiguranja imovine <span className="font-weight-bold">HOK APARTMAN</span> odnosi se na osiguranje
                imovine i interesa prilagođeno potrebama iznajmljivača sa smještajnim kapacitetom do 30 ležajeva.
              </p>
              <p>
                Osiguranje se s primjenjuje na <span className="font-weight-bold">nastanjene</span> i{' '}
                <span className="font-weight-bold">nenastanjene</span> stambene objekte i stvari kućanstva{' '}
                <span className="font-weight-bold">namijenjene iznajmljivanju</span>.
              </p>
              <p>
                Objekti i pokretnine osigurani su na <span className="font-weight-bold">NOVU</span> vrijednost osim
                objekata i pokretnina čija je vrijednost manja od 40% nove vrijednosti pa se smatra da su osigurani na
                sadašnju vrijednost.
              </p>
              <h5 className="text-dark">SKUPINE OSIGURANJA:</h5>
            </Col>
          </Row>
          <Row className="m-3">
            {HOK_APARTMAN_COVERS.map(cover => (
              <Col md="6" className="mb-4 mb-xl-0" key={cover}>
                <CollapsiblePackageInfo
                  placeholder={`userPropertyInsurancePage.hokApartman.${cover}`}
                  showButton={false}>
                  <div className="hok-package-data">
                    <DescriptionText value={cover} data={supportData} />
                  </div>
                </CollapsiblePackageInfo>
              </Col>
            ))}
          </Row>
          <Row className="no-gutters mt-3 mx-3">
            <Col xs={12}>
              <h5 className="text-dark">ASISTENCIJA:</h5>
            </Col>
          </Row>
          <Row className="m-3">
            <Col md="6" className="mb-4 mb-xl-0">
              <CollapsiblePackageInfo
                placeholder={`userPropertyInsurancePage.hokApartman.${HOK_KUCNA_ASISTENCIJA}`}
                showButton={false}>
                <div className="hok-package-data">
                  <DescriptionText value={HOK_KUCNA_ASISTENCIJA} data={supportData} />
                </div>
              </CollapsiblePackageInfo>
            </Col>
          </Row>
          <PreContractualDocumentation productCode={POLICY_CODE_HOK_APARTMAN} />
          <Row className="no-gutters mx-3 mt-5">
            <Col className="text-center">
              <CustomButton
                className="hok-dashboard-btn-wide"
                block={false}
                translationId="requestOffer"
                onClick={() => {
                  googleAnalyticsEvent({
                    category: 'Proizvodi',
                    action: 'Proizvodi_Imovina_Hok_Apartman_Zatrazi_Ponudu',
                  });
                  history.push(ROUTE_TO_USER_PRODUCT_ENQUIRY, {
                    subject: ENQUIRY_SUBJECT_APARTMENT,
                    title: 'Kupnja nove police "HOK APARTMAN"',
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
