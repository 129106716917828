import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export const PartnerSelectCard = ({ partner, selectedPartnerId, selectPartnerCard }) => {
  return (
    <Card
      className={
        'content-card hok-partners-card' + (selectedPartnerId === partner.jmbg ? ' hok-partners-border-red' : '')
      }
      onClick={() => selectPartnerCard(partner.jmbg)}>
      <CardBody>
        <Row>
          <Col>
            <h4>{partner.naziv || `${partner.IME} ${partner.PREZIME}`}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="mb-0">
              <span className="font-weight-bold">
                <FormattedMessage id="userTypeSelectPage.selectPartnerAccount.partnerId" />:
              </span>{' '}
              {partner.jmbg}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="mb-0">
              <span className="font-weight-bold">
                <FormattedMessage id="inputs.address" />:
              </span>{' '}
              {partner.adresa}
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
