import gql from 'graphql-tag';

export const GetPaymentStatusQuery = gql`
  query GetPaymentStatus($confirmationCode: String!) {
    getPaymentData(confirmationCode: $confirmationCode) {
      confirmationCode
      userId
      type
      status
      paymentStatus
      totalAmount
      # totalAmountEuro
      comment
      payload
      createdAt
      updatedAt
    }
  }
`;
